import QRCode from 'qrcode.react';
import React, { useState } from 'react';

function QRCodeGenerator({ url }) {

  const data = url // El dato que quieres codificar en el QR

  const downloadQRCode = () => {
    const canvas = document.getElementById('qr-code'); // Obtén el elemento canvas del código QR
    const url = canvas.toDataURL('image/png'); // Convierte el contenido del canvas a una URL de datos (data URL)
    const link = document.createElement('a'); // Crea un elemento <a> para descargar
    link.href = url; // Establece la URL de descarga
    link.download = 'codigo_qr.png'; // Nombre del archivo a descargar
    link.click(); // Simula un click en el enlace para iniciar la descarga
  };

  const printQRCode = () => {
    const canvas = document.getElementById('qr-code'); // Obtén el elemento canvas del código QR
    const url = canvas.toDataURL('image/png'); // Convierte el contenido del canvas a una URL de datos (data URL)

    const windowContent = `<img src="${url}">`;
    const printWindow = window.open('', '', 'width=600,height=600');
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Código QR</title>
        </head>
        <body>
          ${windowContent}
        </body>
      </html>`
    );
    printWindow.document.close();
    printWindow.print();
  };

  const [copySuccess, setCopySuccess] = useState(false);
  const copyHtmlCode = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url)
        .then(() => {
          setCopySuccess(true);
          setTimeout(() => {
            setCopySuccess(false);
          }, 1500);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }
  };


  return (
    <div className=' '>
      <div className='flex  flex-col items-center gap-4'>
        {/* <h1>Generador e Impresor de Código QR</h1> */}
        <QRCode id="qr-code" value={data} />

      </div>

      <div className='flex flex-wrap justify-center mt-4 gap-2'>
        <button className='btn-green rounded-lg px-4 py-2 flex-grow' onClick={downloadQRCode} style={{flexBasis:"30%"}}>Descargar</button>
        <button className='btn-green rounded-lg px-4 py-2 flex-grow' onClick={printQRCode} style={{flexBasis:"30%"}}>Imprimir</button>
        <button className='btn-green rounded-lg px-4 py-2 flex-grow' onClick={copyHtmlCode} style={{flexBasis:"30%"}}> URL</button>
        {copySuccess && <div className="font-bold text-base text-center">URL copiada 👍</div>}


      </div>

    </div>
  );
}

export default QRCodeGenerator;