import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import { IdiomContext } from "../../../../../context/createcontext"
import activityIndex from '../../../../../images/activityIndexImg.svg'
import { formatThousands } from '../../../utils/Utils'

export default function ActivityIndex({ calculation }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");




    return (
        <div className='grid-report-hightlights rounded-sm border border-gray-200 px-2 py-6 mb-4 '>

            <div className='flex flex-col items-center'>
                <img src={activityIndex} className=" flex flex-col items-center" />
            </div>






            <div className='flex flex-col text-center items-center justify-center'>
                <div>
                    {t("footPrint.indiceActividad")}
                </div>

            </div>

            <div className='flex flex-col  w-full   justify-center'>

                {!calculation.isFetching && <div className='text-center'>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" />
                </div>}


                {calculation.isFetching && <>



                    <div className='text-center mt-1 text-md '>
                        <span><span className='font-bold'> {formatThousands(calculation.data.iaNumber)}  </span> <span className='text-xs'>{calculation.data.iaUnit}</span> </span>

                        <div className='w-full'>{`(${calculation.data.iaDescription})`}</div>
                    </div>







                </>

                }





            </div>


        </div>



    )
}