import { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Banner from "../../../../components/utils/Banner";
import UserService from "../../../../services/UserService";

export default function ModalUpdateCalculation({
  modalCalculationUpdateReport,
  setModalCalculationUpdateReport,
  updateCalculationData,
}) {
  const originalClipboardIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-clipboard"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="var(--airco2-orange-color)"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
      <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
    </svg>
  );
  const [t, i18n] = useTranslation("global");

  const [urlReport, setUrlReport] = useState("");
  const [urlReportReductionCommitment, setUrlReportReductionCommitment] =
    useState("");
  const [urlExcelReport, setUrlExcelReport] = useState("");
  const [viewResults, setViewResults] = useState("");
  const [employees, setEmployees] = useState("");
  const [annualTurnover, setAnnualTurnover] = useState("");
  const [aiDescription, setAiDescription] = useState();
  const [number, setNumber] = useState();
  const [unit, setUnit] = useState();
  const [hasGlobalPactTrackingProtocol, setHasGlobalPactTrackingProtocol] =
    useState(undefined);
  const [
    hasCommittedGlobalPactInfringements,
    setHasCommittedGlobalPactInfringements,
  ] = useState(undefined);
  const [
    hasRelationWithWeaponManufacturingSelling,
    setHasRelationWithWeaponManufacturingSelling,
  ] = useState(undefined);
  const [averageGrossHourlyWageWomen, setAverageGrossHourlyWageWomen] =
    useState();
  const [averageGrossHourlyWageMen, setAverageGrossHourlyWageMen] = useState();
  const [
    numberInManagementCommitteeWomen,
    setNumberInManagementCommitteeWomen,
  ] = useState();
  const [numberInManagementCommitteeMen, setNumberInManagementCommitteeMen] =
    useState();
  const [
    hasConductsActivitiesInSensitiveBiodiversityAreas,
    setHasConductsActivitiesInSensitiveBiodiversityAreas,
  ] = useState(undefined);
  const [
    amountOfDischargesEmittedToWaterBodies,
    setAmountOfDischargesEmittedToWaterBodies,
  ] = useState();

  const [clipboardIcon1, setClipboardIcon1] = useState(originalClipboardIcon);
  const [clipboardIcon2, setClipboardIcon2] = useState(originalClipboardIcon);
  const [clipboardIcon3, setClipboardIcon3] = useState(originalClipboardIcon);
  const [saving, isSaving] = useState(false);

  const [message, setMessage] = useState({
    type: "",
    message: "",
    open: false,
  });

  useEffect(() => {
    if (
      modalCalculationUpdateReport &&
      modalCalculationUpdateReport.calculation
    ) {
      const {
        urlReport,
        urlReportReductionCommitment,
        urlExcelReport,
        viewResults,
        employees,
        annualTurnover,
        hasGlobalPactTrackingProtocol,
        hasCommittedGlobalPactInfringements,
        hasRelationWithWeaponManufacturingSelling,
        averageGrossHourlyWageWomen,
        averageGrossHourlyWageMen,
        numberInManagementCommitteeWomen,
        numberInManagementCommitteeMen,
        hasConductsActivitiesInSensitiveBiodiversityAreas,
        amountOfDischargesEmittedToWaterBodies,
        iaDescription,
        iaNumber,
        iaUnit,
      } = modalCalculationUpdateReport.calculation;
      setUrlReport(urlReport || "");
      setUrlReportReductionCommitment(urlReportReductionCommitment || "");
      setUrlExcelReport(urlExcelReport || "");
      setViewResults(viewResults || false);
      setEmployees(employees);
      setAnnualTurnover(annualTurnover);
      setAiDescription(iaDescription || "");
      setNumber(iaNumber || "");
      setUnit(iaUnit || "");

      setHasCommittedGlobalPactInfringements(
        hasCommittedGlobalPactInfringements
      );
      setHasGlobalPactTrackingProtocol(hasGlobalPactTrackingProtocol);
      setHasRelationWithWeaponManufacturingSelling(
        hasRelationWithWeaponManufacturingSelling
      );
      setAverageGrossHourlyWageWomen(averageGrossHourlyWageWomen);
      setAverageGrossHourlyWageMen(averageGrossHourlyWageMen);
      setNumberInManagementCommitteeWomen(numberInManagementCommitteeWomen);
      setNumberInManagementCommitteeMen(numberInManagementCommitteeMen);
      setHasConductsActivitiesInSensitiveBiodiversityAreas(
        hasConductsActivitiesInSensitiveBiodiversityAreas
      );
      setAmountOfDischargesEmittedToWaterBodies(
        amountOfDischargesEmittedToWaterBodies
      );
    }
  }, [modalCalculationUpdateReport]);

  const handleSubmitPut = async (e) => {
    e.preventDefault();
    isSaving(true);
    const url = `${process.env.REACT_APP_CH_CALCULATION}`;
    let data = {
      id: modalCalculationUpdateReport?.calculation?.id,
      urlReport: urlReport,
      urlReportReductionCommitment: urlReportReductionCommitment,
      urlExcelReport: urlExcelReport,
      viewResults: viewResults,
      employees: employees,
      iaDescription: aiDescription,
      iaNumber: number,
      iaUnit: unit,
    };

    if (annualTurnover === "") {
      data = {
        ...data,
        annualTurnover: 0,
      };
    } else {
      data = {
        ...data,
        annualTurnover: annualTurnover,
      };
    }

    if (hasGlobalPactTrackingProtocol !== undefined) {
      data = {
        ...data,
        hasGlobalPactTrackingProtocol: hasGlobalPactTrackingProtocol,
      };
    }
    if (hasCommittedGlobalPactInfringements !== undefined) {
      data = {
        ...data,
        hasCommittedGlobalPactInfringements:
          hasCommittedGlobalPactInfringements,
      };
    }
    if (hasRelationWithWeaponManufacturingSelling !== undefined) {
      data = {
        ...data,
        hasRelationWithWeaponManufacturingSelling:
          hasRelationWithWeaponManufacturingSelling,
      };
    }
    if (averageGrossHourlyWageWomen) {
      data = {
        ...data,
        averageGrossHourlyWageWomen: averageGrossHourlyWageWomen,
      };
    }
    if (averageGrossHourlyWageMen) {
      data = {
        ...data,
        averageGrossHourlyWageMen: averageGrossHourlyWageMen,
      };
    }
    if (numberInManagementCommitteeWomen) {
      data = {
        ...data,
        numberInManagementCommitteeWomen: numberInManagementCommitteeWomen,
      };
    }
    if (numberInManagementCommitteeMen) {
      data = {
        ...data,
        numberInManagementCommitteeMen: numberInManagementCommitteeMen,
      };
    }
    if (hasConductsActivitiesInSensitiveBiodiversityAreas !== undefined) {
      data = {
        ...data,
        hasConductsActivitiesInSensitiveBiodiversityAreas:
          hasConductsActivitiesInSensitiveBiodiversityAreas,
      };
    }
    if (amountOfDischargesEmittedToWaterBodies) {
      data = {
        ...data,
        amountOfDischargesEmittedToWaterBodies:
          amountOfDischargesEmittedToWaterBodies,
      };
    }

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserService.getToken()}`,
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      updateCalculationData(responseData.data);

      setMessage({
        ...message,
        type: "success",
        message: t("account.actualizacion-ok"),
      });
    } catch (error) {
      console.error("Error:", error.message);
      setMessage({
        ...message,
        type: "error",
        message: t("account.actualizacion-ko"),
      });
    }

    isSaving(false);
  };

  const handleCloseReport = () => {
    setModalCalculationUpdateReport({ view: false, id: null });
    setMessage({
      type: "",
      message: "",
      open: false,
    });
  };

  async function copyContent(content, setClipboardIcon) {
    try {
      await navigator.clipboard.writeText(content);
      setClipboardIcon(
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-clipboard-check"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="var(--airco2-orange-color)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
          <path d="M9 14l2 2l4 -4" />
        </svg>
      );
    } catch (err) {
      setClipboardIcon(
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-clipboard-x"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="var(--airco2-orange-color)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
          <path d="M10 12l4 4m0 -4l-4 4" />
        </svg>
      );
    }
  }

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const handleClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  return (
    <Modal
      show={modalCalculationUpdateReport?.view}
      animation={false}
      data-aos="fade-up"
    >
      <Modal.Header className="sec-green-background">
        <Modal.Title>
          Actualizar cálculo {modalCalculationUpdateReport?.calculation?.id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message.type !== "" && (
          <Banner className={`mb-2`} type={message.type} open setOpen={true}>
            {message.message}
          </Banner>
        )}
        <Row className="font-bold  mb-2">
          <Col xs md="12" className="text-center">
            Cálculo{" "}
            {modalCalculationUpdateReport?.calculation?.questionnaire.name}{" "}
            {modalCalculationUpdateReport?.calculation?.year}
          </Col>

          <Col xs md="6">
            {modalCalculationUpdateReport?.calculation?.organization.name}
          </Col>
          <Col xs md="6" className="text-right">
            {modalCalculationUpdateReport?.calculation?.organization.userEmail}
          </Col>
        </Row>
        <Row>
          <Col xs md="12">
            ¿Ha solicitado certificado?{" "}
            {modalCalculationUpdateReport?.calculation
              ?.hasRequestedCertification
              ? "Sí"
              : "NO"}
          </Col>
        </Row>
        <Row>
          <Col xs md="6">
            {" "}
            ¿Factores actualizados?
            {modalCalculationUpdateReport?.calculation?.hasUpdatedFactors
              ? "Sí"
              : "NO"}
          </Col>
          {!modalCalculationUpdateReport?.calculation?.hasUpdatedFactors && (
            <Col xs md="6">
              ¿Se puede actualizar?{" "}
              {modalCalculationUpdateReport?.calculation?.updatable
                ? "Sí"
                : "NO"}
            </Col>
          )}
        </Row>

        <Form onSubmit={(e) => handleSubmitPut(e)} className="space-y-4 mt-4">
          <Row>
            <Col xs md="5" className="flex justify-between items-end">
              <label className="ml-2 text-sm">Reporte PDF</label>
            </Col>
            <Col xs md="5">
              <input
                id="urlReport"
                name="urlReport"
                type="text"
                value={urlReport}
                onChange={(e) => setUrlReport(e.target.value)}
              ></input>
            </Col>
            <Col xs md="2" className="flex justify-center items-center">
              <button
                type="button"
                onClick={() => copyContent(urlReport, setClipboardIcon1)}
              >
                {clipboardIcon1}
              </button>
            </Col>
          </Row>
          <Row>
            <Col xs md="5" className="flex justify-between items-end">
              <label className="ml-2 mb-0 text-sm items-center">
                Reporte PDF con Compromisos
              </label>
            </Col>
            <Col xs md="5">
              <input
                id="urlReportReductionCommitment"
                name="urlReportReductionCommitment"
                type="text"
                value={urlReportReductionCommitment}
                onChange={(e) =>
                  setUrlReportReductionCommitment(e.target.value)
                }
              ></input>
            </Col>
            <Col xs md="2" className="flex justify-center items-center">
              <button
                type="button"
                onClick={() =>
                  copyContent(urlReportReductionCommitment, setClipboardIcon2)
                }
              >
                {clipboardIcon2}
              </button>
            </Col>
          </Row>
          <Row>
            <Col xs md="5" className="flex justify-between items-end">
              <label className="ml-2 text-sm">Reporte Excel:</label>
            </Col>
            <Col xs md="5">
              <input
                id="urlExcelReport"
                name="urlExcelReport"
                type="text"
                value={urlExcelReport}
                onChange={(e) => setUrlExcelReport(e.target.value)}
              ></input>
            </Col>
            <Col xs md="2" className="flex justify-center items-center">
              <button
                type="button"
                onClick={() => copyContent(urlExcelReport, setClipboardIcon3)}
              >
                {clipboardIcon3}
              </button>
            </Col>
          </Row>

          <Row>
            <Col xs md="6" className="flex justify-between items-end">
              <label className="ml-2 text-sm">
                Empleados <span className="text-red-500">*</span>
              </label>
            </Col>
            <Col xs md="6">
              <input
                id="employees"
                name="employees"
                type="number"
                min="0"
                step="1"
                value={employees}
                onChange={(e) => setEmployees(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col xs md="6" className="flex justify-between items-end">
              <label className="ml-2 text-sm">Beneficios anuales</label>
            </Col>
            <Col xs md="6">
              <input
                id="annualTurnover"
                name="annualTurnover"
                type="number"
                min="0"
                step="0.01"
                value={annualTurnover}
                onChange={(e) => setAnnualTurnover(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col xs md="6" className="flex justify-between items-end">
              <label className="ml-2 text-sm">
                Indice actividad - Descripción{" "}
              </label>
            </Col>
            <Col xs md="6">
              <input
                style={{ paddingRight: "1.8rem" }}
                className="w-full"
                id="ia-description"
                name="ia-description"
                value={aiDescription}
                onChange={(e) => setAiDescription(e.target.value)}
                type="text"
              ></input>
            </Col>
          </Row>
          <Row>
            <Col xs md="6" className="flex justify-between items-end">
              <label className="ml-2 text-sm">Indice actividad - Cifra</label>
            </Col>
            <Col xs md="6">
              <input
                id="ia-number"
                name="ia-number"
                type="number"
                min="0"
                step="0.01"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col xs md="6" className="flex justify-between items-end">
              <label className="ml-2 text-sm">Indice actividad - Unidad</label>
            </Col>
            <Col xs md="6">
              <input
                id="ia-unit"
                name="ia-unit"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                type="text"
              ></input>
            </Col>
          </Row>
          <Row>
            <Col xs md="6" className="flex justify-between items-end">
              <label className="ml-2 text-sm">¿Puede ver resultados?</label>
            </Col>
            <Col xs md="5">
              <input
                id="viewResults"
                name="viewResults"
                type="checkbox"
                checked={viewResults}
                onChange={(e) => setViewResults(e.target.checked)}
              ></input>
            </Col>
          </Row>
          <div className="text-right m-4">
            <button type="button" onClick={handleClick} className="text-sm">
              <div className="flex flex-inline">
                {showAdvancedOptions
                  ? t("footPrint.hideAvancedOption")
                  : t("footPrint.showAvancedOption")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`icon icon-tabler icon-tabler-chevron-up transition-transform duration-200  ${
                    showAdvancedOptions ? "transform rotate-180" : ""
                  }`}
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#545a6d"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M6 15l6 -6l6 6" />
                </svg>
              </div>
            </button>
          </div>

          <div
            id="advancedOptions"
            className={`${!showAdvancedOptions ? "hidden" : ""}`}
          >
            <p className="mb-3 mt-5 font-bold">
              {t("footPrint.socialandwork")}
            </p>
            <Row>
              <Col xs md="12" className="flex justify-between items-end">
                <div className="flex flex-inline">
                  <input
                    name="hasGlobalPactTrackingProtocol"
                    onChange={(e) =>
                      setHasGlobalPactTrackingProtocol(e.target.checked)
                    }
                    checked={hasGlobalPactTrackingProtocol}
                    className="custom-width-md"
                    style={{ borderColor: "hsl(0, 0%, 80%)" }}
                    type="checkbox"
                  />
                  <label className="ml-2 text-sm">
                    {t("footPrint.hasGlobalPactTrackingProtocol")}
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs md="12" className="flex justify-between items-end">
                <div className="flex flex-inline ">
                  <input
                    name="hasCommittedGlobalPactInfringements"
                    onChange={(e) =>
                      setHasCommittedGlobalPactInfringements(e.target.checked)
                    }
                    checked={hasCommittedGlobalPactInfringements}
                    className="custom-width-md"
                    style={{ borderColor: "hsl(0, 0%, 80%)" }}
                    type="checkbox"
                  />
                  <label className="ml-2 text-sm">
                    {t("footPrint.hasCommittedGlobalPactInfringements")}
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs md="12" className="flex justify-between items-end">
                <div className="flex flex-inline">
                  <input
                    name="hasRelationWithWeaponManufacturingSelling"
                    onChange={(e) =>
                      setHasRelationWithWeaponManufacturingSelling(
                        e.target.checked
                      )
                    }
                    checked={hasRelationWithWeaponManufacturingSelling}
                    className="custom-width-md"
                    style={{ borderColor: "hsl(0, 0%, 80%)" }}
                    type="checkbox"
                  />
                  <label className="ml-2 text-sm">
                    {t("footPrint.hasRelationWithWeaponManufacturingSelling")}
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs md="6">
                <label className="text-sm">
                  {t("footPrint.averageGrossHourlyWageWomen")}
                </label>
                <input
                  value={averageGrossHourlyWageWomen}
                  onChange={(e) =>
                    setAverageGrossHourlyWageWomen(e.target.value)
                  }
                  type="number"
                  min={0.01}
                  step={0.01}
                  id="averageGrossHourlyWageWomen"
                  className=""
                ></input>
              </Col>
              <Col xs md="6">
                <label className="text-sm">
                  {t("footPrint.averageGrossHourlyWageMen")}
                </label>
                <input
                  value={averageGrossHourlyWageMen}
                  onChange={(e) => setAverageGrossHourlyWageMen(e.target.value)}
                  type="number"
                  min={0.01}
                  step={0.01}
                  id="averageGrossHourlyWageMen"
                  className=""
                ></input>
              </Col>
            </Row>
            <Row>
              <Col xs md="6">
                <label className="text-sm">
                  {t("footPrint.numberInManagementCommitteeWomen")}
                </label>
                <input
                  value={numberInManagementCommitteeWomen}
                  onChange={(e) =>
                    setNumberInManagementCommitteeWomen(e.target.value)
                  }
                  type="number"
                  min={0}
                  step={1}
                  id="numberInManagementCommitteeWomen"
                  className=""
                ></input>
              </Col>
              <Col xs md="6">
                <label className="text-sm">
                  {t("footPrint.numberInManagementCommitteeMen")}
                </label>
                <input
                  value={numberInManagementCommitteeMen}
                  onChange={(e) =>
                    setNumberInManagementCommitteeMen(e.target.value)
                  }
                  type="number"
                  min={0}
                  step={1}
                  id="numberInManagementCommitteeMen"
                  className=""
                ></input>
              </Col>
            </Row>
            <p className="font-bold mb-3 mt-5">{t("footPrint.environment")}</p>

            <Row>
              <Col xs md="6">
                <div className="flex flex-inline items-center my-4">
                  <input
                    name="hasConductsActivitiesInSensitiveBiodiversityAreas"
                    onChange={(e) =>
                      setHasConductsActivitiesInSensitiveBiodiversityAreas(
                        e.target.checked
                      )
                    }
                    checked={hasConductsActivitiesInSensitiveBiodiversityAreas}
                    className="custom-width-md"
                    style={{ borderColor: "hsl(0, 0%, 80%)" }}
                    type="checkbox"
                  />
                  <label className="ml-2 text-sm">
                    {t(
                      "footPrint.hasConductsActivitiesInSensitiveBiodiversityAreas"
                    )}
                  </label>
                </div>
              </Col>
              <Col xs md="6">
                <label className="text-sm">
                  {t("footPrint.amountOfDischargesEmittedToWaterBodies")}
                </label>
                <input
                  value={amountOfDischargesEmittedToWaterBodies}
                  onChange={(e) =>
                    setAmountOfDischargesEmittedToWaterBodies(e.target.value)
                  }
                  type="number"
                  min={0.01}
                  step={0.01}
                  id="amountOfDischargesEmittedToWaterBodies"
                  className=""
                ></input>
              </Col>
            </Row>
          </div>

          <div className="flex space-x-4 justify-center mt-4">
            <div className={`btn-green rounded-lg p-2 `}>
              <button type="submit" disabled={saving}>
                {!saving ? (
                  t("footPrint.guardar")
                ) : (
                  <Spinner animation="border" variant="light" />
                )}
              </button>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => handleCloseReport()}
          className="btn-cancel rounded-lg p-2"
        >
          {t("footPrint.cerrarCerrar")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
