import axios from "axios";
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';
import { IdiomContext } from "../../../../context/createcontext";
import { Spinner } from 'reactstrap';

Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);
export default function EvolutionTable({

}) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [calculationQuesitonEvolutions, setCalculationQuestionEvolutions] = useState({ data: undefined, scopea1and2: undefined, scope3: undefined, isFetching: false });
    const [alcanceActivo, setAlcanceActivo] = useState(1);

    useEffect(() => {
        const fetchCalculationEvolutionQuestions = async () => {
            const url = `${process.env.REACT_APP_CH_EVOLUTION_QUESTIONS}`;

            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });
                const tablaScope1y2 = response?.data?.data?.calculationQuestionEvolutionItemDtos.filter(item => item.scope === 1 || item.scope === 2);
                const tablaScope3 = response?.data?.data?.calculationQuestionEvolutionItemDtos.filter(item => item.scope === 3);
                setCalculationQuestionEvolutions({ data: response?.data?.data, scopea1and2: tablaScope1y2, scope3: tablaScope3, isFetching: true })
            } catch (exception) {
                setCalculationQuestionEvolutions({ data: undefined, scopea1and2: undefined, scope3: undefined, isFetching: true })
                console.error(exception);
            }
        };

        fetchCalculationEvolutionQuestions();
    }, []);


    const cambiarAlcance = (alcance) => {
        setAlcanceActivo(alcance);
    };

    return (
        <React.Fragment>
            {!calculationQuesitonEvolutions?.isFetching && <div className="text-center"><Spinner className='' /></div>}
            {calculationQuesitonEvolutions?.isFetching && calculationQuesitonEvolutions?.data && <>
                <div className="w-full bg-white rounded-sm border border-gray-200 p-4 pb-2 flex flex-col justify-content-center" >
                    <h3 className="text-center font-bold mb-4">{t("evolution.infoActividad")}</h3>
                    <div className="mb-3">
                        <ul className="flex flex-wrap -m-1">
                            <li className="m-1">
                                <button onClick={() => cambiarAlcance(1)} className={`${alcanceActivo === 1 ? 'sec-green-background text-white' : ' text-gray-500'}
    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                    {t("evolution.scopes1and2")}
                                    <span className={`ml-2 px-2 py-1 rounded-full font-bold text-xs text-airco2-general
                                     ${alcanceActivo === 1 ? 'bg-white' : ' sec-green-background text-white'}`}>
                                        {calculationQuesitonEvolutions?.scopea1and2?.length}
                                    </span>
                                </button>
                            </li>
                            <li className="m-1">
                                <button onClick={() => cambiarAlcance(3)} className={`${alcanceActivo === 3 ? 'sec-green-background text-white' : ' text-gray-500'}
    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                    {t("evolution.scope3")}
                                    <span className={`ml-2 px-2 py-1 rounded-full font-bold text-xs text-airco2-general
                                     ${alcanceActivo === 3 ? 'bg-white' : ' sec-green-background text-white'}`}>
                                        {calculationQuesitonEvolutions?.scope3?.length}
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="container-simple-table">
                        <table className="simple-table">
                            <thead className="">
                                <tr>
                                    <th> <div className="text-left">{t("evolution.source")}</div></th>
                                    <th> <div className="text-right">{t("evolution.baseYear")} <span >({calculationQuesitonEvolutions?.data?.baseYear})</span></div></th>
                                    <th> <div className="text-right">{t("evolution.lastYear")} <span >({calculationQuesitonEvolutions?.data?.lastYear})</span></div></th>
                                    <th> <div className="text-right">{calculationQuesitonEvolutions?.data?.lastYear} vs {calculationQuesitonEvolutions?.data?.baseYear} kgCO<sub>2</sub> </div></th>
                                    <th> <div className="text-right">{calculationQuesitonEvolutions?.data?.lastYear} vs {calculationQuesitonEvolutions?.data?.baseYear} %</div></th>
                                    <th> <div className="text-right">{t("evolution.currentYear")} <span >({calculationQuesitonEvolutions?.data?.currentYear})</span></div></th>
                                    <th> <div className="text-right">{calculationQuesitonEvolutions?.data?.currentYear} vs {calculationQuesitonEvolutions?.data?.baseYear} kgCO<sub>2</sub></div></th>
                                    <th> <div className="text-right">{calculationQuesitonEvolutions?.data?.currentYear} vs {calculationQuesitonEvolutions?.data?.baseYear} %</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {alcanceActivo === 1 &&
                                    calculationQuesitonEvolutions?.scopea1and2?.map((item) => <FilaTabla key={item.questionId} item={item} scope={alcanceActivo} />)}
                                {alcanceActivo === 3 &&
                                    calculationQuesitonEvolutions?.scope3?.map((item) => <FilaTabla key={item.questionId} item={item} scope={alcanceActivo} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>}
        </React.Fragment>
    );


}

function FilaTabla({ item, scope }) {
    const [t, i18n] = useTranslation("global");
    let lastVsBase = null;
    let lastVsBasePercent = null;
    if (item.lastKgCO2e && item.baseKgCO2e) {
        lastVsBase = item.lastKgCO2e - item.baseKgCO2e;
        lastVsBasePercent = ((item.lastKgCO2e - item.baseKgCO2e) / item.baseKgCO2e) * 100;
    }

    let currentVsBase = null;
    let currentVsBasePercent = null;

    if (item.currentKgCO2e && item.baseKgCO2e) {
        currentVsBase = item.currentKgCO2e - item.baseKgCO2e;
        currentVsBasePercent = ((item.currentKgCO2e - item.baseKgCO2e) / item.baseKgCO2e) * 100;
    }
    return <tr key={item.questionId}>
        <td className="text-left">{item.questionTitle} {scope !== 3 && <span className="ml-2 text-xs text-airco2-general"> {t("dash.alcance")} {item.scope}</span>}</td>
        <td className="text-right">{item.baseKgCO2e ? <> {formatThousands(item.baseKgCO2e)}<span className="text-xs">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className="text-right">{item.lastKgCO2e ? <>{formatThousands(item.lastKgCO2e)}<span className="text-xs">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className="text-right">{lastVsBase ? <span className={`${lastVsBase < 0 ? "down" : "up"}`}>{formatThousands(lastVsBase)}<span className="text-xs">kgCO<sub>2</sub></span></span> : "-"} </td>
        <td className="text-right ">{lastVsBasePercent ? <span className={`${lastVsBasePercent < 0 ? "down" : "up"}`}>{formatThousands(lastVsBasePercent)}<span className="text-xs">%</span></span> : "-"} </td>
        <td className="text-right ">{item.currentKgCO2e ? <>{formatThousands(item.currentKgCO2e)}<span className="text-xs">kgCO<sub>2</sub></span></> : "-"} </td>
        <td className="text-right">{currentVsBase ? <span className={`${currentVsBase < 0 ? "down" : "up"}`}>{formatThousands(currentVsBase)}<span className="text-xs">kgCO<sub>2</sub></span></span> : "-"} </td>
        <td className="text-right">{currentVsBasePercent ? <span className={`${currentVsBasePercent < 0 ? "down" : "up"}`}>{formatThousands(currentVsBasePercent)}<span className="text-xs">%</span></span> : "-"} </td>
    </tr>
}