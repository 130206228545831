import React, { useState,useContext, useEffect} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import UserService from '../../../services/UserService';
import { MarketplaceProduct } from './MarketplaceProduct';
import axios from 'axios';
import MarketplaceProvider from './MarketplaceProvider';
import MarketplaceOrders from './MarketplaceOrders';


function MarketPlaceAdmin() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [infoProvider,setInfoProvider] = useState("");
  const [pestaña, setPestaña]= useState("productos");
 
 


  return (
      <div className=" sec-neutro-background flex h-screen overflow-hidden">

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

              {/* Page header */}
              <div className="mb-2">
                {/* Title */}
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Marketplace</h1>
              </div>
             
                
                  
               
                {/* Content */}
                <div className="bg-white rounded-sm mb-8">
                  <div className="flex flex-col xxl:flex-row xxl:-mr-px">
  
                    {/* <MarketplaceSidebar /> */}
                    <div className="sec-neutro-background flex flex-nowrap overflow-x-auto no-scrollbar xxl:block xxl:overflow-auto px-3 py-2 border-b xxl:border-b-0 xxl:border-r border-gray-200 min-w-60 xxl:space-y-3">
                      {/* Group 1 */}
                      <div>
                        {/* <div className="text-xs font-semibold text-gray-400 uppercase mb-3">{t("account.configuracion")}</div> */}
                        <ul className="flex flex-nowrap xxl:block mr-3 xxl:mr-0 space-x-2">
                          <li className="mr-0.5 xxl:mr-0 xxl:mb-0.5">
                            <button onClick={()=>setPestaña("productos")} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pestaña.includes('productos') && 'sec-green-background'}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pestaña.includes('productos') && 'text-white'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                                <line x1="15" y1="21" x2="15" y2="18" />
                                <path d="M8 13l-2 -2" />
                                <path d="M8 12l2 -2" />
                                <path d="M8 21v-13" />
                                <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                              </svg>
                              <span className={`text-md ml-2 font-bold ${pestaña.includes('productos') ? 'text-white' : 'hover:text-gray-700'}`}>Productos</span>
                            </button>
                          </li>
                          <li className="mr-0.5 xxl:mr-0 xxl:mb-0.5">
                            <button onClick={()=>setPestaña("provider")} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pestaña.includes('provider') && 'sec-green-background'}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pestaña.includes('provider') && 'text-white'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                              </svg>
                              <span className={`text-md ml-2 font-bold ${pestaña.includes('provider') ? 'text-white' : 'hover:text-gray-700'}`}>Providers</span>
                            </button>
                          </li>
                          <li className="mr-0.5 xxl:mr-0 xxl:mb-0.5">
                            <button onClick={()=>setPestaña("orders")} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pestaña.includes('orders') && 'sec-green-background'}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={`fill-current text-gray-400 ${pestaña.includes('orders') && 'text-white'} shrink-0 h-6 w-6`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                <path d="M17 17h-11v-14h-2" />
                                <path d="M6 5l14 1l-1 7h-13" />
                              </svg>
                              <span className={`text-md ml-2 font-bold ${pestaña.includes('orders') ? 'text-white' : 'hover:text-gray-700'}`}>Orders</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>    
                    {
                      pestaña == "productos" ?
                    <MarketplaceProduct></MarketplaceProduct>
                    
                      :
                      pestaña == "provider" ?
                     <MarketplaceProvider></MarketplaceProvider>
                     :
                     <MarketplaceOrders></MarketplaceOrders>
                      
                    }
  
                  </div>
  
                </div>
               
              
            </div>
          </main>

        </div>

      </div>
  );
}

export default MarketPlaceAdmin;