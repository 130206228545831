import React, { useState,useContext, useEffect} from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Input } from 'reactstrap';
import Banner from '../../../components/utils/Banner';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import UserService from '../../../services/UserService';
import Euskadi_NavarraData from "../data/municipiosEuskNav.json";
import ReactTooltip from "react-tooltip";
import Translations from '../../../services/Translations';

function FomNavarraPV() {

const [lng, updateLng] = useContext(IdiomContext)
const [t, i18n] = useTranslation("global");
const [sidebarOpen, setSidebarOpen] = useState(false);
const [responseSaveMonte, setResponseSaveMonte] = useState("");
const [disableButton, setDisableButton] = useState(false);
const [showButtons, setShowButtons] = useState("");
const [showNew, setShowNew] = useState("hidden");
const [showForm, setShowForm] = useState("");
const [show, setShow] = useState(false);
const [modalHa, setModalHa] = useState(false);
const [modalMixto, setModalMixto] = useState(false);
const [specieAlert, setSpecieAlert] = useState("");


const [forestType, setForestType] = useState({
  forestTypeData: [],
  isFetching: false,
});

useEffect(() => {
  const fetchForestType = async () => {
    try {
      setForestType({ forestTypeData: forestType.forestTypeData, isFetching: true });
      await axios.get(`${process.env.REACT_APP_FORESTTYPE}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
      }).then(response => {
        setForestType({ forestTypeData: response.data.sort((x, y) => x.commonName.localeCompare(y.commonName)), isFetching: false });
        // setItems(response.data);
      });
    } catch (exception) {
      console.error(exception);
      setForestType({ forestTypeData: forestType.forestTypeData, isFetching: false });
    }
  };
  fetchForestType();
}, []);

const [formMontesMessage, setFormMontesMessage] = useState({
  type: '',
  message: ''
})


const [name, setName]=useState();
const [owner, setOwner]=useState();
const [province, setProvince]=useState();
const [town, setTown]=useState(null);
const [polygon, setPolygon]=useState();
const [parcel, setParcel]=useState();
const [species, setSpecies]=useState();
const [ha, setHa]=useState(null);
const [age, setAge]=useState();

useEffect(() => {
  if(species==="Mixto "){
    setModalMixto(true);
  }
  }, [species]);
useEffect(() => {
  if(specieAlert ==="Mixto "){
    setModalMixto(true);
  }
  }, [specieAlert]);

const [spinner,setSpinner] = useState(false);
const [newMonte,setNewMonte] = useState([]);
const [parcelasHijas, setParcelasHijas]= useState([]);
const [forestUploaded, setForestUploaded] = useState({
  forestUploadedData: [],
  isFetching: false,
});
const [forestUpdated, setForestUpdated] = useState({
  forestUpdatedData: [],
  isFetching: false,
});

  const handlerModalSubmit = (eModal) => {
    eModal.preventDefault();

    setParcelasHijas([...parcelasHijas, {
      "name": name,
      "owner": owner,
      "province": province + "," + town.split("-")[2].toUpperCase(),
      "cadastre": town.split("-")[0] + "-" + town.split("-")[1] + "-" + eModal.target.poligono.value + "-" + eModal.target.parcela.value,
      "forestType": eModal.target.especie.value,
      "size": eModal.target.hectareas.value,
      "age": eModal.target.edad.value,
      "creationDate": new Date(),
      "userId": UserService.getUserId(),
      "state": "CREATED"
    }]);
    setShow(false);
}


  const handlerSubmit = (e) => {
    e.preventDefault();
    // let montesHijos=parcelasHijas;
    if(totalHa>=2){

    setFormMontesMessage({ ...formMontesMessage, type: "warning", message: "Actualizando datos..." });
    document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
    let arrayPost=[];
    let montePadre={
      "name": name,
      "owner": owner,
      "province": province,
      "cadastre": town.split("-")[0] + "-" + town.split("-")[1] + "-" + polygon + "-" + parcel,
      "forestType": species,
      "size": ha,
      "age": age,
      "creationDate": new Date(),
      "userId": UserService.getUserId(),
      "state": "CREATED",
      "idPadre": null
    }
    setNewMonte(montePadre);
    arrayPost.push(montePadre);
    
    parcelasHijas.map((v)=>{
      arrayPost.push(v);
    })
    // arrayPost.push(parcelasHijas);

    const fetchForestUploaded = async () => {
      try {
        setForestUploaded({ forestUploadedData: forestUploaded.forestUploadedData, isFetching: true });
        await axios.post(`${process.env.REACT_APP_PVNAVARRA_FOREST}?email=${UserService.getUsername()}`, arrayPost ,{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
          // body:JSON.stringify(monte)
        }).then(response => {
          if (response.status === 200) {
              setFormMontesMessage({ ...formMontesMessage, type: "success", message: t("account.actualizacion-ok") });
              document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
            } else {
              setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
              document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
            }
            setForestUploaded({ forestUploadedData: response.data, isFetching: false });
          });
        } catch (exception) {
        // setLoading(false)
        setForestUploaded({ forestUploadedData: forestUploaded.forestUploadedData, isFetching: false });
        setSpinner(true);
      }
    };
    fetchForestUploaded();

    e.target.reset()
    setShowButtons("hidden");
    setShowForm("hidden");
    setShowNew("block");
  }else{
    setModalHa(true);
  }
}

  const totalHa = parcelasHijas.reduce((accumulator, currentObj) => {
    return accumulator + parseFloat(currentObj.size);} ,parseFloat(ha));//0 tiene que ser variable con superficie de ha del monte padre

  const municipios = Euskadi_NavarraData.map((v) => {
    let lista=null;
    if(v.name==province){
      switch (province) { 
        case "GIPUZKOA":
          lista = v.municipios.map((municipios) => {
            return(
            <option  name="municipio" className='w-full' 
              value={municipios.codProvincia+"-"+municipios.codMunicipio+"-"+municipios.name}>{municipios.name.toUpperCase()}</option>
            );
          })
          break;
        case "BIZKAIA":
          lista = v.municipios.map((municipios) => {
            return(
            <option  name="municipio" className='w-full' 
              value={municipios.codProvincia+"-"+municipios.codMunicipio+"-"+municipios.name}>{municipios.name.toUpperCase()}</option>
            );
          })
          break;
        case "ÁLAVA":
          lista = v.municipios.map((municipios) => {
            return(
            <option  name="municipio" className='w-full' 
              value={municipios.codProvincia+"-"+municipios.codMunicipio+"-"+municipios.name}>{municipios.name.toUpperCase()}</option>
            );
          })
          break;
        case "NAVARRA":
          lista = v.municipios.map((municipios) => {
            return(
            <option  name="municipio" className='w-full' 
              value={municipios.codProvincia+"-"+municipios.codMunicipio+"-"+municipios.name}>{municipios.name.toUpperCase()}</option>
            );
          })
        break;
      }
    }
    return lista;
  })

  const handleDelete = (props) => { 
      const modifiedArray =parcelasHijas.filter(parcel => parcel.cadastre!=props.cadastre);
      setParcelasHijas(modifiedArray);
  };

  const reload = () => {
    setShowButtons("block")
    setShowNew("hidden")
    setShowForm("block")
    setFormMontesMessage({ ...formMontesMessage, type: "", message: "" })

  }
  const goList = () => { window.location.href = "/forest/forestlist" }
  const reloadPage = () => { window.location.href = "/forest/newmonte" }



  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
         {/* Content */}
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="mb-2 sm:mb-0">
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold"> {t("formMonte.regismonte")} ✨</h1>
            </div>
            <Modal show={modalMixto}>
              <Modal.Header className='sec-green-background text-white text-lg'><b>{t("formMonte.alert_mixto_title")}</b></Modal.Header>
              <Modal.Body className='text-left'>
              {t("formMonte.alert_mixto0")}<a href="mailto:forestal@airco2.earth" className='font-semibold' >{t("formMonte.alert_mixto1")}</a>{t("formMonte.alert_mixto2")}<br/>{t("formMonte.alert_mixto3")}
              </Modal.Body>
              <div className='flex justify-center pb-2'>
                <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={()=>setModalMixto(false)}>
                {t("formMonte.alert_cerrar")}
                </Button>
              </div> 
            </Modal>
            <Modal show={modalHa}>
              <Modal.Header className='sec-green-background text-white'>{t("formMonte.alert_error")}</Modal.Header>
              <Modal.Body className='text-center'>
              {t("formMonte.ha_insuficientes")}
              </Modal.Body>
              <div className='flex justify-center pb-2'>
                <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => setModalHa(false)}>
                {t("formMonte.alert_cerrar")}
                </Button>
              </div> 
            </Modal>
            <Modal show={show} >
                <Modal.Header className='sec-green-background'>
                  <Modal.Title className='text-white'>{t("formMonte.new_parcel")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form className='text-xs' onSubmit={handlerModalSubmit} >
                  <div className="space-y-4">
                    <div className='flex  space-x-4'>
                      {/* <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="especie">{t("formMonte.provincia")} <span className="text-red-500">*</span></label>
                      <input id="provincia" name="provincia" className="form-input w-full" type="text" required />
                      </div> */}
                      <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="municipio">{t("formMonte.municipio")}</label>
                      <input id="municipio" name="municipio" className="form-input w-full" type="text" disabled value={town==null ? null : (town.split("-")[2])}/>
                      </div>
                      <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="poligono">{t("formMonte.poligono")}<span className="text-red-500">*</span></label>
                      <input id="poligono" name="poligono" className="form-input w-full" width="10px" type="number" required />
                    </div>
                    </div>
                    <div className='flex  space-x-4'>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="parcela">{t("formMonte.parcela")}<span className="text-red-500">*</span></label>
                      <input id="parcela" name="parcela" className="form-input w-full" type="number" required />
                    </div>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="especie">{t("formMonte.especie")} <span className="text-red-500">*</span></label>
                      <Input id="especie" name="especie" data-tip data-for="especiesTip" className="form-input w-full" type="select" onChange={e => setSpecieAlert(e.target.value)} required defaultValue="">
                      <option name="Selecciona" className='w-full' value="">{t("formMonte.selectespecie")}</option>
                        {
                        forestType.forestTypeData.map((v) => {
                          return (
                            <option key={v.id} name={v.commonName} className='w-full' value={v.commonName}>{t(Translations.translateForestTypeName(v.commonName)) + " (" + v.scientificName + ")"}</option>
                          );
                        })
                        }
                      </Input>
                      <ReactTooltip id="especiesTip" place="top" effect="solid">{t("formMonte.tooltip_mixto")}<br/>{t("formMonte.alert_mixto3")}</ReactTooltip>
                    </div>
                    </div>
                    <div className='flex  space-x-4'>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="hectareas">{t("forestList.hectareas")} <span className="text-red-500">*</span></label>
                      <input id="hectareas" name="hectareas" className="form-input w-full" type='number' step=".01" required />
                    </div>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="edad">{t("formMonte.edadplan")} <span className="text-red-500">*</span></label>
                      <input id="edad" name="edad" placeholder='Edad aproximada' className="form-input w-full" type='number' min="0" step=".01" required />
                    </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-4">
                    <Button className="text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" type="submit">{t("formMonte.añadir")}</Button>
                    <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" type="reset" variant="secondary" onClick={()=>setShow(false)}>{t("formMonte.cancelar")} </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
            <Form className={`sec-neutro-background`} style={{ width: '100%' }} onSubmit={handlerSubmit}>

            <div className={`md:flex` }>

            {/* <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"> */}

            {/* <FormMontes/> */}
            {/* <FormMontesSinCatastro childToParent={childToParent}/> */}
            {/* <React.Fragment> */}
            {/* {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>} */}



            {/* <div className=" flex row xl:flex col px-4 py-2 md:w-full" style={{ width: '50%' }}> */}

            <div className="w-full md:w-3/6">

              {/* {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>} */}
              {/* Form */}
              {/* <Form className={`sec-neutro-background ${showForm}`} style={{ width: '100%' }} onSubmit={handlerSubmit}> */}
              <div className={`sec-neutro-background ${showForm}`} style={{ width: '100%' }}>
                <div className="space-y-4">
                  <div className='flex flex-inline space-x-4 w-full'>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="nombre">{t("formMonte.nombre")} <span className="text-red-500">*</span></label>
                      {/* <input id="nombre" name="nombre" className="form-input w-full" type="text" required /> */}
                      <input className="form-input w-full" type="text" id="nombre" name="nombre" value={name==null ? "" : name} onInput={e => setName(e.target.value)} required />
                    </div>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="titular">{t("formMonte.titular")} <span className="text-red-500">*</span></label>
                      {/* <input id="titular" name="titular" className="form-input w-full" type="text" required /> */}
                      <input className="form-input w-full" type="text" id="titular" name="titular" value={owner==null ? "" : owner} onInput={e => setOwner(e.target.value)} required />
                    </div>
                  </div>
                  <div className='flex  space-x-4'>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="provincia">{t("formMonte.provincia")} <span className="text-red-500">*</span></label>
                      {/* <input className="form-input w-full" type="text" id="provincia" name="provincia" value={province==null ? "" : province} onInput={e => setProvince(e.target.value)} required /> */}
                      <select className="form-input w-full" id="provincia" name="provincia"
                                value={province==null ? "" : province}
                                onChange={e => setProvince(e.target.value)}>
                        <option name="Selecciona" className='w-full' value="">{t("formMonte.select_province")}</option>
                        {
                          Euskadi_NavarraData.map((v) => {
                            return (
                              <option  name={v} className='w-full' value={v.name}>{v.name}</option>
                            );
                          })
                        }
                      </select>
                    </div>
                    {/* <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="municipio">Municipio<span className="text-red-500">*</span></label>
                      <input className="form-input w-full" type="text" id="municipio" name="municipio" value={town==null ? "" : town} onInput={e => setTown(e.target.value)} required />
                    </div> */}
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="municipio">{t("formMonte.municipio")}<span className="text-red-500">*</span></label>
                      {/* <input id="municipio" name="municipio" className="form-input w-full" type="text" required /> */}
                      {/* <input className="form-input w-full" type="text" id="municipio" name="municipio" value={town==null ? "" : town} onInput={e => setTown(e.target.value)} required /> */}
                      <select className="form-input w-full" id="municipio" name="municipio"
                                value={town==null ? "" : town.name}
                                onChange={e => setTown(e.target.value)}>
                        <option name="Selecciona" className='w-full' value="">{t("formMonte.select_municipio")}</option>
                       {municipios}
                      </select>
                    </div>
                  </div>
                  <div className='flex  space-x-4'>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="poligono">{t("formMonte.poligono")}<span className="text-red-500">*</span></label>
                      {/* <input id="poligono" name="poligono" className="form-input w-full" width="10px" type="text" required /> */}
                      <input className="form-input w-full" type="number" id="poligono" name="poligono" value={polygon==null ? "" : polygon} onInput={e => setPolygon(e.target.value)} required />

                    </div>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="parcela">{t("formMonte.parcela")}<span className="text-red-500">*</span></label>
                      {/* <input id="parcela" name="parcela" className="form-input w-full" type="text" required /> */}
                      <input className="form-input w-full" type="number" id="parcela" name="parcela" value={parcel==null ? "" : parcel} onInput={e => setParcel(e.target.value)} required />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="especie">{t("formMonte.especie")} <span className="text-red-500">*</span></label>
                    {/* <select id="especie" name="especie" className="form-input w-full" type="text" required defaultValue=""> */}
                    <select className="form-input w-full" id="especie" name="especie"
                                value={species==null ? "" : species}
                                onChange={e => setSpecies(e.target.value)}
                                data-tip data-for="especiesTip"
                                required>
                      <option name="Selecciona" className='w-full' value="">{t("formMonte.selectespecie")}</option>
                        {
                          forestType.forestTypeData.map((v) => {
                            return (
                              <option key={v.id} name={v.commonName} className='w-full' value={v.commonName}>{t(Translations.translateForestTypeName(v.commonName)) + " (" + v.scientificName + ")"}</option>
                            );
                          })
                        }
                    </select>
                    <ReactTooltip id="especiesTip" place="top" effect="solid">{t("formMonte.tooltip_mixto")}<br/>{t("formMonte.alert_mixto3")}</ReactTooltip>
                  </div>
                  <div className='flex  space-x-4'>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="hectareas">{t("forestList.hectareas")} <span className="text-red-500">*</span></label>
                      {/* <input id="hectareas" name="hectareas" className="form-input w-full" type='number' min="2" step=".01" required /> */}
                      <input className="form-input w-full" type="number" id="hectareas" name="hectareas" min="0" step=".01"  value={ha==null ? "" : ha} onInput={e => setHa(e.target.value)} required />
                    </div>
                    <div className='w-full'>
                      <label className="block text-sm font-medium mb-1" htmlFor="edad">{t("formMonte.edadplan")} <span className="text-red-500">*</span></label>
                      {/* <input id="edad" name="edad" placeholder='Edad aproximada' className="form-input w-full" type='number' min="0" step=".01" required /> */}
                      <input className="form-input w-full" placeholder='Edad aproximada' type="number" id="edad" name="edad" min="1" step=".5" value={age==null ? "" : age} onInput={e => setAge(e.target.value)} required />

                    </div>
                  </div>
                </div>
              </div>
            </div >
{/* </React.Fragment > */}

            {/* <div className={` ${showForm} `} style={{ width: '50%' }}> */}
            <div className={`w-full md:w-3/6 ${showForm} `}>

              <div className=' ml-4 mt-2'>
                <span className='h5'>{t("formMonte.añadir_colindantes")}</span>
                <p className='text-sm text-justify'>{t("formMonte.explicacion_colindantes")}</p>
                <div className='flex justify-end my-2 ml-2'>
                  <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" type="button" disabled={town == null || town== "" || ha==null || ha=="" ? true :false} onClick={()=>setShow(true)}>{t("formMonte.add_parcel")}</button>
                </div>
                <table className='table-responsive divide-y divide-gray-200'>
                  <thead>
                    <tr>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">#</th>
                      {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">{t("formMonte.municipio")}</th> */}
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">{t("formMonte.poligono")}</th>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">{t("formMonte.parcela")}</th>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">{t("formMonte.especie")}</th>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">{t("forestList.hectareas")}</th>
                      <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">{t("forestList.años")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parcelasHijas.length > 0 ?
                    parcelasHijas.map((v, i) => {
                      let split=v.cadastre.split("-");
                      return(
                      <tr key={i}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center">{i+1}</div>
                      </td>
                      {/* <td className="¡" data-name="municipio" >
                        {/* <input type="text" id={"municipio-" + i} width="50%"name="municipio"></input> 
                        <div className="text-center">{split[0]}</div>
                      </td> */}
                      <td className="¡" data-name="poligono">
                        {/* <input type="number" id={"poligono-" + i} name="poligono" className=""></input> */}
                        <div className="text-center">{split[1]}</div>
                      </td>
                      <td className="" data-name="parcela">
                        {/* <input type="number" id={"parcela-" + i} name="parcela" className=""></input> */}
                        <div className="text-center">{split[2]}</div>
                      </td>

                      <td className="" data-name="forestType">
                        <div className="text-center">{v.forestType}
                        {/* <select defaultValue="" id={"especie-" + i} name="especie" className="form-input" type="text" required >
                          <option name="Selecciona" disabled className='w-full' value="">{t("formMonte.selectespecie")}</option>
                          {
                            forestType.forestTypeData.map((x) => {
                              return (
                                <option key={x.id} name={x.commonName} className='w-full' value={x.commonName} >{forestTypeName(x.commonName)}</option>
                              );
                            })
                          }
                        </select> */}
                        </div>
                      </td>
                      <td className="" data-name="size">
                        {/* <input type="number" id={"hectareas-" + i} name="hectareas"  className=""></input> */}
                        <div className="text-center">{v.size}</div>
                      </td>
                      <td className="" data-name="age">
                        <div className="text-center">
                        {/* <input id={"edad-" + i} name="edad" className="" type='number' min="1" step=".5" placeholder="Edad aproximada" required /> */}
                        <div className="text-center">{v.age}</div>
                        </div>
                      </td>
                      <td className="" data-name="delete">
                        <div>
                          <button className="align-center" onClick={()=>handleDelete(v)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                              <line x1="4" y1="7" x2="20" y2="7" />
                              <line x1="10" y1="11" x2="10" y2="17" />
                              <line x1="14" y1="11" x2="14" y2="17" />
                              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                            </svg>
                          </button>
                        </div>
                      </td>
                      </tr>)
                    })
                    :""
                    }
                    <tr className="border-t border-gray-200">
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"></td>
                      <td colSpan={3} className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-right">TOTAL HA :</td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><b>{parcelasHijas.length>0 ? totalHa.toFixed(2) : ha}</b></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            </div>
            
            <div className={`flex row mt-2 `}>
              <div className={`inline-flex justify-center text-center pl-4 pr-4 ${showForm}`}>
                  <input id="checkbox" name="checkbox" className="form-input " type='checkbox' required />
                  <label className="block text-xs font-medium mb-1 ml-2" htmlFor="marco">{t("formMonte.checkbox")}<span className="text-red-500">*</span></label>
              </div>
              <div className='inline-flex justify-center text-center pl-4 max-w-lg'>
                {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} className="w-auto" open setOpen={true}>{formMontesMessage.message}</Banner>}
              </div>
              <div className={`flex items-center justify-center mt-2 ${showForm}`}>
                <Button arial-label="btn-regristrar" data-tip data-for="registerTip" type="submit" disabled={totalHa>=2 ? false : true} className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap`}>
                  {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
                  {t("formMonte.registrar")}
                </Button>
                <Button arial-label="btn-cancelar" type="reset" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showButtons}`}  onClick={() => goList()}>
                  {t("formMonte.cancelar")}
                </Button>
              </div>
              <div>
                <div className="flex items-center justify-center mt-6 ">
                  <Button arial-label="btn-nuevo" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showNew}`} onClick={() => reloadPage()}>
                    {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
                    {t("forestList.newMonte")}
                  </Button>
                  <Button arial-label="btn-nuevo" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showNew}`} onClick={() => goList()}>
                    {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
                    {t("forestList.mis-montes")}
                  </Button>
                </div>
                <div className="flex justify-center mt-6 ml-4">
                  <Link className={`border-t border-gray-200font-medium text-indigo-500 hover:text-indigo-600 ${showNew}`} to="/forest/dashboardmontes">{t("formMonte.volver")}</Link>
                </div>
              </div>
            </div>
            </Form>

          </div>
          
        </main>
      </div>
    </div>
  );
}

export default FomNavarraPV;