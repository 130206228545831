import { Translation } from "react-i18next"

const translateCategories = (value) => {
    if (value === 'Electricidad') {
        return "sidebar.eelectricidad"

    } else if (value === 'Automovil y transporte') {
        return "sidebar.eautomovil"
    } else if (value === 'Refrigerantes') {
        return "sidebar.erefrigerantes"
    } else if (value === 'Gastos hogar') {
        return "sidebar.egastoshogar"
    } else if (value === 'Gastos empresa y profesionales') {
        return "sidebar.egastosempresa"

    } else if (value === 'Factura agua') {
        return "sidebar.efacturaagua"
    } else if (value === 'Factura gas') {
        return "sidebar.efacturagas"
    } else if (value === 'Factura electricidad') {
        return "sidebar.efacturaelectricidad"
    } else if (value === 'Transporte publico') {
        return "sidebar.etransportepu"
    } else if (value === 'Gasolineras') {
        return "sidebar.egasolineras"
    } else if (value === 'Transporte privado') {
        return "sidebar.etransportepr"
    } else if (value === 'Paqueteria y mensajeria') {
        return "sidebar.epaqueteria"
    } else if (value === 'Vuelos') {
        return "sidebar.evuelos"
    } else if (value === 'factura luz') {
        return "sidebar.efacturaelectricidad"
    } else if (value === 'sin categoría') {
        return "sidebar.nocategory"
    } else if (value === '') {
        return "sidebar.nocategory"
    } else if (value === 'nóminas') {
        return "sidebar.enominas"
    } else if (value === 'ocio') {
        return "sidebar.eocio"
    } else if (value === 'viajes') {
        return "sidebar.eviajes"
    } else if (value === 'bancos') {
        return "sidebar.ebancos"
    } else if (value === 'salud deporte y educación') {
        return "sidebar.edeporte"
    } else if (value === 'seguros') {
        return "sidebar.eseguros"
    } else if (value === 'servicios sociales ayudas y pensiones') {
        return "sidebar.esociales"
    } else if(value === 'compras'){
        return "sidebar.ecompras"

    }else if(value === 'librería'){
        return "sidebar.elibreria"

    }else{
        return "sidebar.eotros"
}}

const transalteStatusCart = (value) => {
    switch (value) {
        case "CART":
            return "marketplace.panel_orders_status_cart";
        case "CONFIRMED":
            return "marketplace.panel_orders_status_confirmed";
        case "WAITING_PAYMENT":
            return "marketplace.panel_orders_status_waiting_payment";
        case "EXPIRED":
            return "marketplace.panel_orders_status_expired";
        case "FINALIZED":
            return "marketplace.panel_orders_status_finalized";
    }
}


const translateForestTypeName = (props) => {
    switch (props) {
        case 'Castaño':
            return "forestList.eCastaño";
        case 'Encina':
            return "forestList.eEncina";
        case 'Eucalipto':
            return "forestList.eEucalipto";
        case 'Pino rodeno o resinero':
            return "forestList.ePinoPinaster";
        case 'Pino radiata':
            return "forestList.ePinoRadiata";
        case 'Roble':
            return "forestList.eRoble";
        case 'Mixto':
            return "forestList.eMixto";
        case 'Pino piñonero':
            return "forestList.ePinoPiñonero";
        case 'Pino silvestre':
            return "forestList.ePiñoSilvestre";
        case 'Haya':
            return "forestList.eHaya";
        case 'Pino carrasco':
            return "forestList.ePinoCarrasco";
        case 'Pino negro':
            return "forestList.ePinoNegro";
        case 'Pino laricio':
            return "forestList.ePinoLaricio";
        case 'Pino canario':
            return "forestList.ePinoCanario";
        case 'Abeto común':
            return "forestList.eAbetoComun";
        case 'Abeto de Douglas':
            return "forestList.eAbetoDouglas";
        case 'Sabina albar':
            return "forestList.eSabinaAlbar";
        case 'Roble albar':
            return "forestList.eRobleAlbar";
        case 'Melojo':
            return "forestList.eMelojo";
        case 'Quejigo':
            return "forestList.eQuejigo";
        default:
            return (props);
    };
}

const transalteStatusCalculations = (value) => {
    switch (value) {
        case "DRAFT":
            return "Borrador";
        case "FINALIZED":
            return "Finalizado";
        case "EXTERNAL":
            return "Externo";
        case "CURRENTYEAR":
            return "Borrador";
    }
}

const transalteStatusCertifications = (value) => {
    switch (value) {
        case "CREATED":
            return "Creada";
        case "DOCUMENTATION":
            return "Proceso de documentación";
        case "SENT":
            return "Enviada";
        case "ACCEPTED":
            return "Aceptada";
        case "COMPLETED":
            return "Completada";
    }
}

export const translationsTourTooltip = (value) =>  {
    switch (value) {
        case "en":
    return {
      back: 'Back',
      close: 'Close',
      last: 'Last',
      next: 'Next',
      skip: 'Skip',
    }
    case "es":
    return {
      back: 'Atrás',
      close: 'Cerrar',
      last: 'Último',
      next: 'Siguiente',
      skip: 'Saltar',
    }
   }
}
   

const Translations = {
    translateCategories,
    transalteStatusCart,
    translateForestTypeName,
    transalteStatusCalculations,
    transalteStatusCertifications,
    translationsTourTooltip
};

export default Translations;