import React, { useState, useEffect, useContext } from 'react';
import UserService from '../../../services/UserService';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import SubscriptionPlans from  '../calculo-huella/SubscriptionPlans.jsx';

function PlansPanel() {

  const [t, i18n] = useTranslation("global");


  return (
    <div className="sec-neutro-background grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">{t("account.planes")}</h2>
        <SubscriptionPlans />
      </div>
    </div>
  );
}

export default PlansPanel;