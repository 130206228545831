import React, {useContext} from 'react';
import { Spinner } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import pino from '../../../images/shop-4.webp';
import roble from '../../../images/shop-3.webp';
import eucalipto from '../../../images/shop-2.webp';
import castaño from '../../../images/castaños.webp';
import cedro from '../../../images/cedro.webp';
import ReactTooltip from "react-tooltip";
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from 'react-i18next';
import Translations from '../../../services/Translations'

function CardMonte(data) {

  const activeForest= data.data;
  
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  
  const selectImage =(props) => {
    switch (props.forestType) {
      case 'Pino pinaster':
        return pino;
      case 'Pino radiata':
        return pino;
      case 'Eucalipto':
        return eucalipto;
      case 'Roble':
        return roble;
      case 'Castaño':
        return castaño;
      case 'Cedro':
        return cedro;
    };
  }
  
  return (

    activeForest.length == 0 ? <Spinner /> :
    
    activeForest.map((forest) => {
      
      return(
      <div className="col-span-full md:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200 overflow-hidden">
         <div className="flex flex-col h-full">
           {/* Image */}
           <div className="relative">
             <img className="w-full" src={selectImage(forest)} width="301" height="226" alt="Application 21" />
             <div className="absolute bottom-0 right-0 mb-4 mr-4">
               <div className="inline-flex items-center text-xs font-medium text-gray-100 bg-gray-900 bg-opacity-60 rounded-full text-center px-2 py-0.5">
                 <svg className="w-3 h-3 shrink-0 fill-current text-yellow-500 mr-1" viewBox="0 0 12 12">
                   <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                 </svg>
                 <span className='text-base' data-tip data-for="registerTip">{forest.availableCO2.toFixed(2)} tCO₂</span>
                 <ReactTooltip id="registerTip" place="top" effect="solid">{t("forestList.co2Disponible")}</ReactTooltip>
               </div>
             </div>
           </div>
           {/* Card Content */}
           <div className="grow flex flex-col p-5 ">
             {/* Card body */}
             <div className="grow">
               <header className="mb-2">
                {/* <a href="./product" data={forest}>
                   <h3 className="text-lg text-gray-800 font-semibold mb-1">{forest.name}</h3>
                 </a>  */}

                 {/* <Link className="font-medium text-indigo-500 hover:text-indigo-600" to={{pathname:"/product",state:{hola:true}}}>
                  <h3 className="text-lg text-gray-800 font-semibold mb-1">{forest.name}</h3>
                 </Link> */}

                <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/company/product" state={forest}>
                  <h3 className="text-lg text-gray-800 font-semibold mb-3">{forest.name}</h3>
                 </Link>
                  <div className="text-sm text-slate-500">
                    <ul>
                      <li className='flex inline mb-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dimensions" width="22" height="22" viewBox="0 0 24 24"    stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none"   >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M3 5h11" />
                        <path d="M12 7l2 -2l-2 -2" />
                        <path d="M5 3l-2 2l2 2" />
                        <path d="M19 10v11" />
                        <path d="M17 19l2 2l2 -2" />
                        <path d="M21 12l-2 -2l-2 2" />
                        <rect x="3" y="10" width="11" height="11" rx="2" />
                      </svg>
                      <span className='ml-2'>{forest.size} ha</span>
                      </li>
                      <li className='flex inline mb-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-resize" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M4 11v8a1 1 0 0 0 1 1h8m-9 -14v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1" />
                        <path d="M4 12h7a1 1 0 0 1 1 1v7" />
                      </svg>
                      <span className='ml-2'>{forest.marco} {t("forestList.marco")}</span>
                      </li>
                      <li className='flex inline mb-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                        <line x1="15" y1="21" x2="15" y2="18" />
                        <path d="M8 13l-2 -2" />
                        <path d="M8 12l2 -2" />
                        <path d="M8 21v-13" />
                        <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                      </svg>
                      <span className='ml-2'>{t(Translations.translateForestTypeName(forest.forestType))} </span>
                      </li>
                      <li className='flex inline mb-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-stack-3" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 2l-8 4l8 4l8 -4l-8 -4" />
                        <path d="M4 10l8 4l8 -4" />
                        <path d="M4 18l8 4l8 -4" />
                        <path d="M4 14l8 4l8 -4" />
                      </svg>
                      <span className='ml-2'>{forest.anualCO2} {t("proyectos.anual")}</span>
                      </li>
                      <li className='flex inline mb-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-time" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" />
                        <circle cx="18" cy="18" r="4" />
                        <path d="M15 3v4" />
                        <path d="M7 3v4" />
                        <path d="M3 11h16" />
                        <path d="M18 16.496v1.504l1 1" />
                      </svg>
                      <span className='ml-2'>{forest.age} {t("proyectos.años")} </span>
                      </li>
                    </ul>                   
                  </div>
               </header>
             </div>
             {/* Rating and price */}
             <div className='mt-4 no-underline'>
             <button arial-label='btn-contratar' className="btn-sm w-full sec-green-background hover:border-gray-600 text-gray-500 hover:text-gray-600 no-underline" >Contratar</button>
             {/* <a className="btn-sm w-full bg-indigo-500 hover:bg-indigo-600 text-white" href="#0">Contratar</a> */}

             </div>
             <div className="flex flex-wrap justify-between items-center">
               {/* Rating */}
               <div className="flex items-center space-x-2 mr-2">
               </div>
               {/* Price */}
               <div>
                 {/* <div className="inline-flex text-sm font-medium sec-green-background rounded-full text-center px-2 py-0.5"> {forest.size} ha </div> */}
               </div>
             </div>
           </div>
         </div>
       </div>

      )
    })

  );
}

export default CardMonte;