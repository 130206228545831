import React from 'react';
import Anonymous from './Anonymous';
import ByEmail from './ByEmail';



export default function GetContantInformation({ externalQuestionnaire, handleSetContactData, t }) {
    return <div className="w-full max-w-9xl mx-auto  text-justify">
        {!externalQuestionnaire.isAnonymous && <>
            <ByEmail
                externalQuestionnaire={externalQuestionnaire}
                handleSetContactData={handleSetContactData}
                t={t}
            />
        </>}

        {externalQuestionnaire.isAnonymous && <>
            <Anonymous
                externalQuestionnaire={externalQuestionnaire}
                handleSetContactData={handleSetContactData}
                t={t} />
        </>}
    </div>
}