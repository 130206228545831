import axios from "axios";
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalTitle, ModalHeader, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Banner from "../../../components/utils/Banner";
import { IdiomContext } from "../../../context/createcontext";
import UserService from "../../../services/UserService";
import DocumentsListTable from "../subirDoc/DocumentsListTable";
import { formatDate, formatThousands } from '../utils/Utils';


function UndocumentedAnswersTabler({ idCalculation, show, updateShow, saveDocumentationFromCalculationDoc, answers }) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [modalDocument, setModalDocument] = useState({ view: false, id: "" });
    
    const [uploadSpinner, setUploadSpiner] = useState(false);

    const [file, setFile] = useState(null);
    const [fileMsg, setFileMsg] = useState({ state: "", text: "" });

    const handleUploadFileAnswer = async (e, idCalculationAnswer, item) => {
        setUploadSpiner(true);
        e.preventDefault();
        let formData = new FormData();
        formData.append('files', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/documentation?idQuestionAnswer=${idCalculationAnswer}`,
                formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            item.calculationDocumentation = response.data.data;
            modalDocument.item.calculationDocumentation = response.data.data[0];
            saveDocumentationFromCalculationDoc(response.data.data[0]);
            setFileMsg({ state: "success", text: t("exceltransactions.fileUpload") });
            setFile(null);

        } catch (exception) {
            console.error(exception);
        }
        setUploadSpiner(false);

    }

    const setDocumentList = (item, documentList) => {
        item.calculationDocumentation = documentList;
        modalDocument.item.calculationDocumentation = documentList[0];
    }

    const cleanMessage = () => {
        setFileMsg({ state: "", text: "" });
    }

    const handleFileChange = (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];

        const maxFileSize = process.env.REACT_APP_CH_CALCULATION;
        if (selectedFile && selectedFile.size > maxFileSize) {
            setFileMsg({ state: "warning", text: t("footPrint.archivoGrande") });
        } else {
            setFile(selectedFile);
            setFileMsg({ state: "", text: "" });
        }

        const file = e.target.files[0];
        const fileName = file.name;
        let fileOk = true;
        let errorMsg = "";
        if (file) {

            if (/\s/.test(fileName) || /[^\w.-]/.test(fileName)) {
                fileOk = false;
                errorMsg = t("marketplace.errImgNombre")
            }

            const extension = fileName.split(".").pop();
            if (extension !== extension.toLowerCase()) {
                fileOk = false;
                errorMsg = t("marketplace.errImgExtensionMayusculas");
            }

            const fileExtension = fileName.split('.').pop().toLowerCase();
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
            if (!allowedExtensions.includes(fileExtension)) {
                fileOk = false;
                errorMsg = t("footPrint.logo-extension");
            }

            if (fileOk) {
                setFile(e.target.files[0]);
                setFileMsg({ state: "", text: "" });
            } else {
                setFileMsg({ state: "warning", text: errorMsg });
                console.error('Error: ' + errorMsg);
                e.target.value = null;
                // setuploadLogoValue([])
            }

        }


    };


    return (
        <>
            <Modal show={modalDocument.view} className="xl-secondary-modal" animation={false} data-aos="fade-up" backdropClassName="xl-secondary-modal-backshadow">
                <ModalHeader className='sec-neutro-background sec-grey-text text-base font-semibold'>
                    {t("forestList.NombreDoc")}
                </ModalHeader>
                <ModalBody>

                    <div className="mb-4 flex justify-between items-center gap-1">
                        {modalDocument.item?.questionSubtitle !== undefined && <div>{modalDocument.item?.questionSubtitle}</div>}
                        <div>{modalDocument.item?.organizationBuildingName !== undefined ? modalDocument.item?.organizationBuildingName : ""}</div>
                        <div>{modalDocument.item?.factorName}</div>
                        <div>{modalDocument.item?.quantity && formatThousands(modalDocument.item?.quantity)}</div>
                    </div>

                    {fileMsg.state !== "" && <div className="mb-4"> <Banner type={fileMsg.state} setOpen={true} open={fileMsg.state !== "" ? "open" : ""}>
                        {fileMsg.text}</Banner>
                    </div>}

                    {modalDocument.item?.calculationDocumentation != undefined && <>
                        <DocumentsListTable
                            documents={[modalDocument.item.calculationDocumentation]}
                            enableDelete={true}
                            idCalculation={idCalculation}
                            handleUpdateDocuments={setDocumentList}
                            idCuestionAnswer={modalDocument.item}
                        /></>}
                    {modalDocument.item?.calculationDocumentation === undefined &&
                        <form className=" flex justify-center items-center"
                            onSubmit={(e) => handleUploadFileAnswer(e, modalDocument.item.id, modalDocument.item)}>
                            <input className=''
                                type="file" id="subir_archivo"
                                required
                                onChange={(e) => { cleanMessage(); handleFileChange(e); }}
                                title={t("footPrint.seleccionaArchivo")} />
                            {file && <button className="btn-sm btn-banner-o " type="submit" disabled={uploadSpinner ? "disabled" : ""}>
                                <div className="mr-1">
                                    {!uploadSpinner && <svg xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-file-upload"
                                        width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                        <path d="M12 11v6" />
                                        <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
                                    </svg>}
                                    {uploadSpinner && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" />}
                                </div>
                                {t("lectorfacturas.subirArchivos")}</button>}
                        </form>}
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className=" text-xs btn-sm btn-cancel" onClick={() => {
                        setModalDocument({ view: false, id: "" });
                        setFileMsg({ state: "", text: "" });
                    }}> {t("footPrint.cerrarCerrar")}</button>
                </div>
            </Modal >

            <Modal show={show} className='xl-modal' animation={false} data-aos="fade-up" fullscreen backdropClassName="xl-modal-backshadow">
                <ModalHeader className='xl-modal-header sec-neutro-background sec-grey-text text-lg flex justify-between '>
                    <ModalTitle className=''>Información desagregada de consumos- documentación por adjuntar</ModalTitle>
                    <button onClick={() => {updateShow({state: false, modal: "CALCANSWERS"}); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 6l-12 12" />
                        <path d="M6 6l12 12" />
                        </svg>
                    </button>
                </ModalHeader>
                <ModalBody className='xl-modal-body'>

                    {answers.isFetching ?
                        <div className="flex justify-center">
                            <Spinner animation="border" variant="dark" className="m-4" />
                        </div> :
                        (answers.data?.data != undefined && answers.data?.data?.length > 0 ?
                            <div className="mt-4 mb-4">

                                <div className="table-responsive">

                                    {answers.data?.data.map((bloquePreguntas, indexTabla) => {
                                        return (
                                            <AnswerTableResult bloquePreguntas={bloquePreguntas}
                                                modalDocument={modalDocument}
                                                setModalDocument={setModalDocument}
                                            ></AnswerTableResult>
                                        )
                                    })}
                                </div>
                            </div >
                            :
                            <div className="my-4 footprint-seccion-respuestas">
                                <p className="text-sm font-bold">{t("footPrint.noDatos")}</p>
                            </div>
                        )
                }

                  
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className=" text-xs btn-sm btn-cancel" onClick={() => {
                        updateShow({state: false, modal: "CALCANSWERS"});
                    }}> {t("footPrint.cerrarCerrar")}</button>
                </div>
            </Modal >
        </>
    )
}

function AnswerTableResult({ bloquePreguntas, modalDocument, setModalDocument }) {
    const [t, i18n] = useTranslation("global");

    const renderTooltipWarningFile = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.uploadExternalCertification")}
        </Tooltip>
    );

    const [editRowData, setEditRowData] = useState(undefined);
    const [editRowDataId, setEditRowDataId] = useState(undefined);

    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    const valueA = a[sortConfig.key];
                    const valueB = b[sortConfig.key];

                    // Handle undefined, null, and empty strings 
                    const aIsEmpty = valueA === undefined || valueA === null || valueA === '';
                    const bIsEmpty = valueB === undefined || valueB === null || valueB === '';

                    if (aIsEmpty && !bIsEmpty) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (bIsEmpty && !aIsEmpty) return sortConfig.direction === 'ascending' ? 1 : -1;
                    if (aIsEmpty && bIsEmpty) return 0; // Both empty, treat as equal

                    // Numerical comparison
                    if (!isNaN(valueA) && !isNaN(valueB)) {
                        return sortConfig.direction === 'ascending' ? valueA - valueB : valueB - valueA;
                    }

                    // Date comparison
                    if (moment(valueA).isValid() && moment(valueB).isValid()) {
                        return sortConfig.direction === 'ascending'
                            ? moment(valueA).diff(moment(valueB))
                            : moment(valueB).diff(moment(valueA));
                    }

                    // String comparison (case-insensitive)
                    return sortConfig.direction === 'ascending'
                        ? valueA.toString().localeCompare(valueB.toString(), undefined, { sensitivity: 'base' })
                        : valueB.toString().localeCompare(valueA.toString(), undefined, { sensitivity: 'base' });
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(bloquePreguntas.answerBodyOutputDtos);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };


    return (<div className={`${bloquePreguntas.length > 1 ? "" : ""} mb-4`}>

        <div className="">
            <div className="font-bold">
                {bloquePreguntas.title}
                <span className="text-xs ml-2">
                    {bloquePreguntas.subtitle}</span>
            </div>
        </div>

        <div className="container-simple-table mt-2">
            <table className="simple-table">
                <thead className="text-center text-white sec-green-background">
                    <tr key={bloquePreguntas.id}>
                        {Object.keys(bloquePreguntas.answerHeaderOutputDto).map((key) => (
                            <th key={key} >
                                <button type="button" onClick={() => requestSort(key)}
                                    className={`${getClassNamesFor(key)} `}>
                                    {bloquePreguntas.answerHeaderOutputDto[key]}
                                </button>
                            </th>
                        ))}
                        <>
                            <th>{t("footPrint.subirArchivos")}</th>
                        </>

                    </tr>
                </thead>
                <tbody className="text-center ">
                    {items.map((item, index) => (
                        <>
                            <tr key={index}
                                className={`${modalDocument?.view && modalDocument?.item.id == item.id ? "sec-green-background-tr" : ''}`}>
                                {Object.keys(bloquePreguntas.answerHeaderOutputDto).map((key) => (
                                    <td key={key}>
                                        {item[key] === undefined || item[key] === "" ?
                                            "" : ((isNaN(item[key])
                                                ? (key === "registrationDate"
                                                    ? formatDate(item[key])
                                                    : item[key])
                                                : formatThousands(item[key])))}</td>
                                ))}
                                <td >
                                    <button onClick={() => setModalDocument({ view: true, item: item })}
                                        className="relative">
                                        {item.calculationDocumentation === undefined ? <>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                className="icon icon-tabler icon-tabler-clipboard-plus" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                                <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                <path d="M10 14h4" />
                                                <path d="M12 12v4" />
                                            </svg>
                                            {bloquePreguntas.questionType === "EXTERNAL_CALCULATION" &&
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipWarningFile}>
                                                    <span className="absolute rounded-full bg-orange " style={{ left: "15px", bottom: "12px", padding: "4px" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-triangle" width="13" height="13" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff " fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M12 9v4" />
                                                            <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
                                                            <path d="M12 16h.01" />
                                                        </svg>
                                                    </span>
                                                </OverlayTrigger>
                                            }
                                        </>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                className="icon icon-tabler icon-tabler-file-check" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                <path d="M9 15l2 2l4 -4" />
                                            </svg>}
                                    </button>
                                </td>
                            </tr >

                            {(item?.associatedAnswerOutputDto != undefined && item?.associatedAnswerOutputDto.length > 0) &&
                                <>
                                    <tr>
                                        <td colSpan={Object.keys(bloquePreguntas.answerHeaderOutputDto).length}>
                                            <table className=" text-sm table-auto w-full divide-y divide-gray-200 table-associated-footprint mb-4">
                                                <thead style={{ backgroundColor: "#d9d9d9" }}>
                                                    <th>Dato de consumo</th>
                                                    <th>gCH₄</th>
                                                    <th>gN₂O</th>
                                                    <th>kgCO₂</th>
                                                    <th>Total KgCO₂e</th>
                                                </thead>
                                                <tbody >
                                                    {item.associatedAnswerOutputDto.map((x) =>
                                                        <>
                                                            <tr style={{ backgroundColor: "#e5e1d7" }}>
                                                                <td>{x.factorName}</td>
                                                                <td>{x.gch4 != undefined ? formatThousands(x.gch4) : ""}</td>
                                                                <td>{x.gn2O != undefined ? formatThousands(x.gn2O) : ""}</td>
                                                                <td>{x.kgCO2 != undefined ? formatThousands(x.kgCO2) : ""}</td>
                                                                <td>{formatThousands(x.totalKgCO2e)}</td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </>}

                        </>
                    ))}

                </tbody>
            </table>
        </div>
    </div>
    )

}

export default UndocumentedAnswersTabler;