import pagoKo from '../../../images/pagoKo.svg'
import { useTranslation } from 'react-i18next';

export default function BannerNoData() {
    const [t, i18n] = useTranslation("global");

    return <div className='grid-report-nodata  sec-blue-background text-white rounded-sm border border-gray-200 px-2 py-3'>

        <div className='flex flex-col items-center '>
            <img src={pagoKo} />
        </div>

        <div className='flex flex-col text-center items-center justify-center'>
            <h1 className="text-xl mb-2">{t("footPrint.nodata.title")}</h1>
            {t("footPrint.nodata.subtitle")}
            <br />
            {t("footPrint.nodata.text")}
        </div>

        <div className='flex flex-col justify-center text-center'>
            <button onClick={() => window.location.href = "/footprint/plans"} className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none align-items-center ">
                <span className="h-100 btn btn-banner hover:bg-white shadow"  >{t("footPrint.planes.ver")}</span>
            </button>
        </div>
    </div>
}