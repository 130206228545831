import React from "react";
import { useState, useContext } from "react";
import axios from "axios";
import UserService from "../../../services/UserService";
import { Modal } from "react-bootstrap";
import processing from '../../../images/undraw_processing.svg';
import { Spinner } from "react-bootstrap";
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { HashLink } from "react-router-hash-link";


export default function ModalFinishCalculation({ view, calculationId, updateViewFromParent,idOrg }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [isChecked, setIsChecked] = useState(false);
    const [modalFinishingCalculationInfo, setModalFinishingCalculationInfo] = useState(false);
 


    const handleFinishCalulation = (idCalculation) => {
        setModalFinishingCalculationInfo(true);

        const fetchFinishCalculation = async () => {
            const response = await axios.patch(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/finish`, {}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            }).then((response) => {
                window.location.href = `/footprint/myfootprint/report/${idCalculation}`;
            }).catch((error) => {
                console.error(error);
            })
        }

        if (isChecked) {
            fetchFinishCalculation();
            // setViewFinishCalculation(false);
        }
    }



    return (
        <Modal show={view} animation={false} data-aos="fade-up">
            <Modal.Header className='sec-neutro-background sec-grey-text'>
                <Modal.Title>{t("footPrint.finalizarCalculo")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalFinishingCalculationInfo ?
                    <>
                        <p className='text-center'>{t("footPrint.finalizarCalculoTxt")}</p>
                        <div className='flex justify-center'>
                            <Spinner variant="dark" animation="border" />
                        </div>
                        <img src={processing} className='my-4' />
                        <p>{t("footPrint.finResultados")}</p>
                    </>
                    :
                    <>
                        <p>{t("footPrint.finalizarCalcTxt")}</p><br />
                        <p>{t("footPrint.finCalidadDato")}</p><br />
                        <p>{t("footPrint.borradorCalc")}.</p><br />

                       
                            <p>{t("footPrint.antesFinalizar")}</p>
                      

                        <ul className='mb-2 font-bold text-xs'>


                            <HashLink aria-label='flota' className='linkhover' to={`/footprint/organization/${idOrg}/#idFlota`} smooth={true} offset={50} duration={500}>
                                <li>{t("footPrint.flota")}</li>

                            </HashLink>

                            <HashLink aria-label='sources' className='linkhover' to={`/footprint/organization/${idOrg}/#idSources`} smooth={true} offset={50} duration={500}>
                                <li>
                                    {t("footPrint.fuentesEmisoras")}
                                </li>
                            </HashLink>
                        </ul><br />
                        <p>{t("footPrint.seguroFinalizar")}</p>
                        <div className='flex flex-inline'>
                            <input type='checkbox' id="checkFinalizarCalculo" className='mt-1' onChange={() => setIsChecked(!isChecked)}></input>
                            <label className='ml-2 text-sm'>{t("footPrint.finalizarRevisado")}</label>
                        </div>
                        <div className='flex space-x-4 justify-center mt-2'>
                            <button className='btn-cancel rounded-md btn-sm text-xs' onClick={() => { setIsChecked(false), updateViewFromParent(false) }}>{t("footPrint.cancelar")}</button>
                            <button className={`btn-green rounded-md btn-sm text-xs ${isChecked ? '' : 'sec-green-background opacity-50 cursor-not-allowed'}`} disabled={!isChecked} onClick={() => handleFinishCalulation(calculationId)}>{t("footPrint.Finalizar")}</button>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}