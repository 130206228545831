import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Navigate } from "react-router-dom";
import UserService from "../../../services/UserService";
import { IdiomContext } from "./../../../context/createcontext";
import { Countries } from '../../intranet/data/countries';

function Dialog({ onDialog, message, amount, co2Tons, unitPrice, discount, userId, customerId, co2Total, co2Percent }) {
  
  const [respuestacompesateco2, setRespuestacompesateco2] = useState("");
  const [loadingStripe, setLoadingStripe] = useState(false)
  // const handlerStripe = e => {
  //   setLoadingStripe('true')
  // }
  // cambiar el idioma
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [line1, setLine1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [cif, setCif] = useState("");

  let dataPost = {

    "amount": Number((amount.replace(",", ".") * 100).toFixed(2)),
    "co2Tons": Number(co2Tons),
    "unitPrice": unitPrice,
    "discount": discount,
    "userId": userId,
    "customerId": customerId,
    "co2Total": co2Total,
    "co2Percent": co2Percent / 100
  };



  function dataStripe() {


    if (UserService.getCustomerId() == "" && (line1 == "" || city == "" || state == "" || postalCode == "" || country == "" || cif == "")) {
      alert(t("dash.stripe-fillbilling"))
      return;
    }

    if (UserService.getCustomerId() == "") {
      const userPost = {
        "line1": line1,
        "city": city,
        "state": state,
        "postalCode": postalCode,
        "country": country,
        "cif": cif
      }

      dataPost = { ...dataPost, customer: userPost };

    }

    setLoadingStripe(true);


    fetch(`${process.env.REACT_APP_PAYMENTS_COMPENSATE}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${UserService.getToken()}`
      },
      body: JSON.stringify(dataPost)
    }).then((response) => {
      return response.json()
    })
      .then((respuestacompesateco2) => {
        if (respuestacompesateco2.url != undefined) {
          window.open(respuestacompesateco2.url, '_blank');
          if (!respuestacompesateco2.error) {
            setRespuestacompesateco2(respuestacompesateco2)
          }
        } else {
          alert(t("dash.stripe-compensationerror"))
        }

        setLoadingStripe(false);
      }).catch(() => {
        alert(t("dash.stripe-compensationerror"))
        setLoadingStripe(false);

      });
  }



  return (
    <div
      className="z-40"
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(0,0,0,0.5)"
      }}
    // onClick={() => onDialog(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          background: "#F8F3EA",
          padding: "20px",
          borderRadius: "10px",
          maxWidth: "600px"
        }}
      >
        <h3 className="h4 text-center">{message}</h3>
        <h3 className="text-center mb-4">{t("dash.stripe_redirigirstripe")} {amount} € +IVA</h3>
        <div style={{ display: "flex", alignItems: "center" }}>


          <div className="flex flex-wrap  gap-3 items-center justify-center">

            {UserService.getCustomerId() == "" && <div> {t("dash.stripe-billingdata")}

              <div className="row">

                <div className="col-12 col-md-6 mb-2">
                  <label className="block text-sm font-medium mb-1" htmlFor="cif">{t("dash.stripe-cif")} <span className="text-red">*</span></label>
                  <input id="cif" className="form-input w-full" type="text" value={cif} onChange={event => setCif(event.target.value)} />
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <label className="block text-sm font-medium mb-1" htmlFor="line1">{t("dash.stripe-line1")} <span className="text-red">*</span></label>
                  <input id="line1" className="form-input w-full" type="text" value={line1} onChange={event => setLine1(event.target.value)} />
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <label className="block text-sm font-medium mb-1" htmlFor="city">{t("dash.stripe-city")} <span className="text-red">*</span></label>
                  <input id="city" className="form-input w-full" type="text" value={city} onChange={event => setCity(event.target.value)} />
                </div>
                <div className="col-12 col-md-6 mb-2" >
                  <label className="block text-sm font-medium mb-1" htmlFor="state">{t("dash.stripe-state")} <span className="text-red">*</span></label>
                  <input id="state" className="form-input w-full" type="text" value={state} onChange={event => setState(event.target.value)} />
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <label className="block text-sm font-medium mb-1" htmlFor="postalCode">{t("dash.stripe-postalCode")} <span className="text-red">*</span></label>
                  <input id="postalCode" className="form-input w-full" type="number" value={postalCode} onChange={event => setPostalCode(event.target.value)} />
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <label className="block text-sm font-medium mb-1" htmlFor="country">{t("dash.stripe-country")} <span className="text-red">*</span> </label>

                  <select defaultValue="" className='form-input  w-full' onChange={(e) => { setCountry(e.target.value); }} >
                    <option value={""} disabled="disabled"> - </option>

                    {Countries.options.map(x => {
                      return <option value={x.value}>{x.label} </option>

                    })}

                  </select>

                </div>
              </div>
            </div>}

            <button
              id="btncompensate" disabled={!loadingStripe ? "" : "disabled"}
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={() => {
                // onDialog(true);
                dataStripe();
              }}>


              {/* <a href={respuestacompesateco2.url} onClick={handlerStripe}>{t("dash.stripe_continuar")}</a>
            </button>
            <button
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-2"
              onClick={() => {
                onDialog(false); */}
              {t("dash.stripe_continuar")}
            </button>
            <button disabled={!loadingStripe ? "" : "disabled"}
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={() => {
                onDialog(false);

              }}>
              {t("dash.stripe_cancelar")}
            </button>
          </div>






        </div>
        <div>
          {
            !loadingStripe ?
              <>
                {(respuestacompesateco2.url != undefined ?
                  <>

                    <Navigate
                      to={`/company/compensateco2/payment`}
                      replace
                      state={{ url: respuestacompesateco2.url }}
                    />
                  </>

                  : "")}

              </>


            
              :
              <div className="flex">
                <Spinner animation="border" variant="dark" className=" hidden md:flex md:grow mr-2" />
                <p className="mt-2">

                  {t("dash.stripe_preparardatos")}
                </p>
              </div>
          }


        </div>
      </div>
    </div>
  );
}
export default Dialog;
