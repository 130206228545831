import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserService from '../../../services/UserService';
import CardServices from './CardServices'
import servicios from './servicios.json'
import Header from '../Header'
import Sidebar from '../Sidebar';

export default function CatalogoServicios() {
  const [t, i18n] = useTranslation("global");
  const [sidebarOpen, setSidebarOpen] = useState(false);
 


  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <div className="mb-2 sm:mb-0">
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("forestryService.catalogo")} 🍃</h1>
            </div>

            <div className="flex flex-wrap gap-1 container pt-4" style={{justifyContent:'space-evenly'}}>
              <CardServices
                servicios={servicios}
              />
            </div>
          </div>









        </main>
      </div>
    </div>


  );
}