import React, { useContext, useEffect, useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar'
import UserService from '../../../services/UserService';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import Form from "react-bootstrap/Form";
import Banner from '../../../components/utils/Banner';
import toast, { Toaster } from 'react-hot-toast';
import ReactTooltip from "react-tooltip";
import { Spinner } from 'react-bootstrap';


function UploadSingleExcel() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [fileToUpload, setFileToUpload] = useState();
  const [statusExcelFetch, setStatusExcelFetch] = useState();
  const [sendBtn, setSendBtn] = useState(false);

  const [uploadExcelMessage, setuploadExcelMessage] = useState({
    type: '',
    message: ''
  })

  //Evento que recoge el archivo y guarda si el formato es correcto
  const handleFiles = e => {
    e.preventDefault();
    let file = e.target.files[0];
    if (validateFile(file)) {
      setFileToUpload(file);
    } else {
      e.target.value = null;
      toast.error(t("dashboardExcel.invalido"));
    } 
  }

  //VALIDAR EL TIPO DE ARCHIVO
  const validateFile = (file) => {
    const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/vnd.ms-excel'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (fileToUpload != undefined ){
      setSendBtn(true);

      const formData = new FormData();
      formData.append("file", fileToUpload);

      fetch(`${process.env.REACT_APP_EMISSIONS_EMAIL_EXCEL}`,
      {
        method: 'POST',
        headers: {
          // 'Accept': 'application/file',
          // 'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        },
        body : formData
      }).then((response) => {

        if (response.status == 200){
          setuploadExcelMessage({ ...uploadExcelMessage, type: "success", message: t("account.actualizacion-ok") });
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        }else {
          setuploadExcelMessage({ ...uploadExcelMessage, type: "error", message: t("account.actualizacion-ko") })
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        }
        setSendBtn(false);
        return response.json()
      })
      .then((statusExcelFetch) => {
        if (!statusExcelFetch.error) {
        }
      })
     }else{
      toast.error(t("dashboardExcel.noarchivo"));

     }

  }


  return (

    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden sec-neutro-background">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>

            <div className="">
              <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto sec-neutro-background '>
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("exceltransactions.title")}</h1>
              </div>                
              <div className='text-rig ml-4'><br/>
                  <div className='mb-2'>
                    {uploadExcelMessage.type != "" && <><Banner className="text-center" width="50%" type={uploadExcelMessage.type} open setOpen={true}>{uploadExcelMessage.message}</Banner><br/></>}
                    <Form className='text-left' onSubmit={handleSubmit}>
                      <h1>{t("dashboardExcel.ficheros1")}<span data-tip data-for="typefile">{t("dashboardExcel.ficheros2")}</span>
                        <ReactTooltip id="typefile" place="top" effect="solid" clickable>.xlsx .xls .csv</ReactTooltip>
                      </h1>
                      <br/>
                      <div className='flex'>
                        <div className='w-1/2'>
                          <input type="file" id="uploadfile" accept='.csv, .xls, .xlsx' className='form-control form-input' onChange={(e) => handleFiles(e)}/>
                        </div>
                        <div className='w-1/2'/>
                      </div><br/><br/>
                        {sendBtn ? 
                          <button className='btn sec-green-background' type="submit" >
                            <Spinner animation="border" variant="light" className='flex justify-center' disabled/>
                          </button>
                        :
                          <button className='btn sec-green-background' type="submit" >{t("dashboardExcel.enviar")}</button>
                        }
                    </Form>
                  </div>
                </div >
              </div>
              <div className='float-right'>
                <img src="/arbol3.svg" />
              </div>
            </div>
            {/* PopUp para archivos invalidos */}
            <Toaster position="bottom-center"reverseOrder={false}/>
          </main>
      </div>
    </div>
  );

}

export default UploadSingleExcel;