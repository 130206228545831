import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

export default function DownloadReportModal({ calculation, showExcel, handleExcelReport, loadingExcel, handleCloseExcel }) {
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    return (<Modal show={showExcel} animation={false} data-aos="fade-up" >
        <Modal.Header className='sec-green-background'>
            <Modal.Title>{t("footPrint.downReporte")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='p-4'>
                <table>
                    <tr className='flex justify-between items-center mb-4'>
                        <td className='col-8 text-lg'>{t("footPrint.excelEmisiones")}</td>
                        <td className='flex justify-center col-4'>
                            <button onClick={() => (calculation?.data?.urlExcelReport === undefined || calculation?.data?.urlExcelReport === '' ? handleExcelReport(false) : window.open(calculation?.data?.urlExcelReport, '_blank'))}
                                style={{ pointerEvents: loadingExcel.excel ? 'none' : 'auto' }}
                                className='p-2 flex flex-row items-center btn-sm btn-green rounded-lg'
                            >
                                <div className='mb-1'>
                                    {loadingExcel.excel ? (
                                        <div className="spinner-border" role="status">
                                        </div>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-spreadsheet" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                            <path d="M8 11h8v7h-8z" />
                                            <path d="M8 15h8" />
                                            <path d="M11 11v7" />
                                        </svg>
                                    )}
                                </div>
                                {t("sidebar.descargar")}
                            </button>
                        </td>
                    </tr>
                    <tr className='flex justify-between items-center '>
                        <td className='col-8 text-lg'>{t("footPrint.zipEmisiones")}</td>
                        <td className='flex justify-center col-4'>
                            <button onClick={() =>(calculation?.data?.urlExcelReportZipFiles === undefined || calculation?.data?.urlExcelReportZipFiles === '' ? handleExcelReport(true) : window.open(calculation?.data?.urlExcelReportZipFiles, '_blank'))}
                                style={{ pointerEvents: loadingExcel.zip ? 'none' : 'auto' }}
                                className='p-2 flex flex-row items-center btn-sm btn-banner-o rounded-lg'
                            >
                                <div className='mb-1'>
                                    {loadingExcel.zip ? (
                                        <div className="spinner-border" role="status">
                                        </div>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-type-zip" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                            <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                                            <path d="M16 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
                                            <path d="M12 15v6" />
                                            <path d="M5 15h3l-3 6h3" />
                                        </svg>
                                    )}
                                </div>
                                {t("sidebar.descargar")}
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div className='flex space-x-4 justify-center mt-4'>
                <button onClick={() => handleCloseExcel()} className='btn-cancel rounded-lg p-2'>{t("footPrint.cerrar")}</button>
            </div>
        </Modal.Body>
    </Modal>)
}