import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "./../../../context/createcontext";
import DoughnutChart from './../charts/DoughnutChart';
import { tailwindConfig } from './../utils/Utils';

function GraficaAlcance(data) {
  
  const dataAlcance=data.data;


  // Array con Alcances para comprobar qeu han llegado datos antes de pintar la grafica
  const alcanceList = dataAlcance.map((x) => {
    return x.category;
  })

    // cambiar idioma
    
 const [lng, updateLng] = useContext(IdiomContext)
 const [t, i18n] = useTranslation("global");

  const arrayLabelsStatic = ["Alcance1", "Alcance 2", "Alcance 3"];
  const arrayAlcance = [t("dash.alcance1"), t("dash.alcance2"),t("dash.alcance3")];
  // {t("dash.emisiones")}

  // useEffect(() => {
  //   arrayAlcance
  // }, [lng]);
  
  
  const arrayCo2=new Array(arrayAlcance.length).fill(0); 
  

  dataAlcance.map((emision)=>{
    switch (emision.category) {
      case "1":
        arrayCo2[0] += emision.co2 / 1000;
        break;
      case "2":
        arrayCo2[1] += emision.co2 / 1000;
        break;
      case "3":
        arrayCo2[2] += emision.co2 / 1000;
        break;
    }
  })
  // var labels =  arrayAlcance

  //   useEffect(() => {
  //  labels
  // }, [lng]);

  const chartData = {
  
 
    labels: 
        arrayAlcance
    ,
   
   


    datasets: [
      {
        label: 'Toneladas CO₂ por actividad',
        data: arrayCo2,
        backgroundColor: [
          tailwindConfig().theme.colors.yellow[400],
          tailwindConfig().theme.colors.indigo[500],
          tailwindConfig().theme.colors.orange[400],
          tailwindConfig().theme.colors.gray[500],
        ],
        hoverBackgroundColor: [
          tailwindConfig().theme.colors.yellow[500],
          tailwindConfig().theme.colors.indigo[600],
          tailwindConfig().theme.colors.orange[500],
          tailwindConfig().theme.colors.gray[600],
        ],
        hoverBorderColor: tailwindConfig().theme.colors.white,
      },
    ],
  };
 
  return (
    <div id='galcance' className="flex flex-col col-span-full sm:col-span-6 bg-white  rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">{t("dash.huellaalcance")}</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}

      {
        alcanceList.length === 0 ? <Spinner className='flex justify-center' /> : <DoughnutChart data={chartData} width={389} height={260} unit={" t"} />
      }
    </div>
  );
}
export default GraficaAlcance;