import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import OrganizacionesComponent from './OganizacionesComponent';





export default function Organizaciones() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const clasDiv = `flex flex-wrap gap-8 org-justify`;
    let [total,updateTotal]= useState('')
    let [idOrg, updateIdOrg] = useState('');
    const childTotal = (v) =>{
        updateTotal(v)
      }
      const childData = (v) => {
        updateIdOrg(v)

      }


    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden ">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="mb-8">
                            {/* Title */}
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("footPrint.organizations")}</h1>
                        </div>

                        {/* Content */}
                        <div className=''>
                        <OrganizacionesComponent
                        clase={clasDiv}
                        numberOrg={'all'}
                        childTotal={childTotal}
                        childData={childData}
                        
                        
                        
                        
                        ></OrganizacionesComponent>
                        </div>
                       
                       




                    </div>
                </main>

            </div>

        </div>
    )
}
