
import { useTranslation } from 'react-i18next';

export default function OptionForm({ options, sendDataToParent, selectedOption }) {
    const [t, i18n] = useTranslation("global");

    const handleOnButtonClick = (id) => {
        sendDataToParent(id);
    }

    return <>
        {options && options.length > 0 && <div className='mt-2 mb-2'>
            <p className='text-xs'>{t("footPrint.selectRadiobuttonOptions")}</p>
            <div className="flex flex-wrap gap-1 w-full mb-1">
                {options?.map((opt, index) =>
                    <button className={` btn-sm    p-1  rounded-0 h-full flex-grow md:grow-1
                            ${selectedOption === opt.value ? "btn-option-selected font-bold" : "btn-option border text-airco2-general "}`}
                        onClick={() => handleOnButtonClick(opt.value)}
                        disabled={selectedOption === opt.value}
                        key={index}>
                        <div className="flex flex-col">
                          <div>{opt.text}</div>  
                            {opt.description && (
                                    <div className="text-xs">{opt.description}</div>
                            )}
                        </div>
                    </button>
                )} </div >
        </div>}
    </ >;
}