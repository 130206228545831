import de from "date-fns/esm/locale/de/index.js";
import { fil } from "date-fns/locale";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { read, utils, writeFile, write, readFile } from 'xlsx';
import UserService from "../../../services/UserService";
import Banner from './../../../components/utils/Banner';
import Header from './../Header';
import Sidebar from './../Sidebar';
import GetTransactions00 from "./GetTransactions00";
import GetTransactions01 from "./GetTransactions01";
import GetTransactions02 from "./GetTransactions02";
import GetTransactions03 from "./GetTransactions03";
import GetTransactions04 from "./GetTransactions04";
import moment from "moment/moment";
import { useTranslation } from 'react-i18next';

export const ParseExcel = () => {
    const [t, i18n] = useTranslation("global");

    const coinscrapExcelEments = [
        { key: "valuedate", value: "Fecha contable", type: "date", required: true },
        { key: "accountingdate", value: "Fecha valor", type: "date", required: true },
        { key: "desc", value: "Descripción", type: "text", required: true },
        { key: "amount", value: "Importe transacción", type: "number", required: true },
        { key: "balance", value: "Saldo cuenta", type: "number", required: true },
        { key: "currency", value: "Moneda", type: "", required: false }// Ningín tipo, entero o String?
    ]



    const coinscrapHeaders = coinscrapExcelEments.map(c => { return c.key });


    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [step, setStep] = useState(0);

    const [errorMessage, setErrorMessage] = useState({
        type: '',
        message: ''
    })
    const [fileAttributes, setFileAttributes] = useState({});
    const [fileName, setFileName] = useState(null);
    const [fileColumns, setFileColumns] = useState([]);
    const [fileData, setFileData] = useState({});

    const [selectedColumns, setSelectedColumns] = useState([]);
    const [finalDataToProcess, setFinalDataToProcess] = useState([]);

    const [tipoCuenta, setTipoCuenta] = useState("");
    const [ultimosDigitos, setUltimosDigitos] = useState("");
    const [cuentaCoinscrap, setCuentaCoinscrap] = useState(false);

    const periodoCoinscrap = moment(UserService.getCoinscrapDate()).subtract(`${process.env.REACT_APP_COINSCRAP_DAYS_OPEN_BANKING}`, 'd').format("yyyy-MM-DD");

    const handleFile = async (e) => {
        let file = e.target.files[0];
        setErrorMessage({ ...errorMessage, type: "", message: "" });

        if (file.name.split('.').pop() != fileAttributes.format) {
            setErrorMessage({ ...errorMessage, type: "error", message: t("exceltransactions.checkFile") + " '" + fileAttributes.format + "'" });
            return;
        }

        setFileName(file.name);

        try {
            var fr = new FileReader();
            if (fileAttributes.format == "csv") {
                fr.readAsText(file);
            } else {
                fr.readAsArrayBuffer(file);
            }
            fr.onload = await function (ev) {
                let wb = null;
                let fileContent = "";
                // debugger;

                const readOpts = { // <--- need these settings in readFile options
                    cellText: false,
                    cellDates: true
                };



                if (fileAttributes.format == "csv") {
                    if (fileAttributes.separator == ";") {
                        fileContent = str2ab(ev.target.result.toString().replaceAll(",", ".")); //Replace para formato decimal
                    } else {
                        fileContent = str2ab(ev.target.result.toString());
                    }
                    wb = read(fileContent, { cellText: false, cellDates: true });
                } else {
                    wb = read(ev.target.result, { type: "buffer", cellText: false, cellDates: true });
                }

                const ws = wb.Sheets[wb.SheetNames[0]];

                if (fileAttributes.header > 1) {
                    /* skip  rows */
                    var range = utils.decode_range(ws['!ref']);
                    range.s.r = fileAttributes.header - 1; // <-- zero-indexed, so setting to 1 will skip row 0
                    ws['!ref'] = utils.encode_range(range);
                }
                const jsonOpts = {
                    header: 1,
                    defval: '',
                    blankrows: true,
                    raw: false,
                    dateNF: 'dd"/"MM"/"yyyy' // <--- need dateNF in sheet_to_json options (note the escape chars)
                }
                const dataJSON = utils.sheet_to_json(ws, jsonOpts); // generate objects
                setFileColumns(dataJSON[0])
                setFileData(dataJSON)


                document.getElementById('content')?.scrollIntoView({ behavior: 'smooth' });
                setErrorMessage({ ...errorMessage, type: "success", message: t("exceltransactions.fileUpload") });

            };

        }
        catch (err) {
            //  debugger;
            setErrorMessage({ ...errorMessage, type: "error", message: t("exceltransactions.checkFile") + err });

        }





    }

    const updateSelectedColumns = (selected) => {
        setSelectedColumns(selected)
    }

    function str2ab(str) {
        var buf = new ArrayBuffer(str.length); // 2 bytes for each char
        var bufView = new Uint8Array(buf);
        for (var i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }


    const createNewDocument = () => {
        //  debugger;

        //BUILD NEW JSON
        const selectedData = [];
        fileData.map((data, index) => {
            const selectedColumnsArray = [];
            let insert = true;
            selectedColumns.sort((a, b) => coinscrapHeaders.indexOf(a.type) - coinscrapHeaders.indexOf(b.type)).map(column => {

                // if (coinscrapExcelEments[coinscrapHeaders.indexOf(column.type)].type == "number" && !Number(data[column.columnIndex])) {
                //     insert = false;
                // }
                if (coinscrapExcelEments[coinscrapHeaders.indexOf(column.type)].type == "date") {
                    let transactionDate = data[column.columnIndex].replaceAll("'", "").replaceAll("/", "-").split("-");

                    //Filtrar datos anteriores a 2018
                    let begginingDate = process.env.REACT_APP_DATE_START_TR.toString().split("-");
                    if (new Date(transactionDate[2], transactionDate[1], transactionDate[0]).getTime() < new Date(begginingDate[0], begginingDate[1], begginingDate[2])) {
                        insert = false;
                        return;
                    }

                    //Filtrar datos si es cuenta con Open Banking
                    if (UserService.getCoinscrapDate() != "") {
                        if (cuentaCoinscrap) {
                            let periodoCoinscrapDate = periodoCoinscrap.split("-");
                            if (new Date(transactionDate[2], transactionDate[1], transactionDate[0]).getTime() > new Date(periodoCoinscrapDate[0], periodoCoinscrapDate[1], periodoCoinscrapDate[2]).getTime()) {

                                insert = false;
                                return;
                            }
                        }
                    }


                    selectedColumnsArray.push(data[column.columnIndex].replaceAll("'", "").replaceAll("-", "/"))

                } else {
                    selectedColumnsArray.push(data[column.columnIndex])
                }

            })
            if (insert) {
                selectedColumnsArray.push(fileAttributes["name"])
                selectedColumnsArray.push(tipoCuenta)
                selectedColumnsArray.push(ultimosDigitos)
                selectedColumnsArray.push(cuentaCoinscrap)

                selectedData.push(selectedColumnsArray)
            }
        })
        setFinalDataToProcess(selectedData);
    }


    const exportNewDocument = () => {
        //CREAR EXCELS
        var header = coinscrapHeaders;
        header.push("bank")
        header.push("type")
        header.push("lastDigits")
        header.push("openBankin")
        const worksheet = utils.json_to_sheet([]);
        const workbook = utils.book_new();
        utils.sheet_add_aoa(worksheet, [header]);

        utils.sheet_add_json(worksheet, finalDataToProcess.slice(1), { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        writeFile(workbook, "DataSheet.xlsx");

        //TODO: Enviar coinscrap
        // var wopts = { bookType: "xlsx", bookSST: false, type: "base64" };
        // var wbout = XLSX.write(workbook, wopts);
        // var req = new XMLHttpRequest();
        // req.open("POST", "/upload", true);
        // var formdata = new FormData();
        // formdata.append("file", "test.xlsx"); // <-- server expects `file` to hold name
        // formdata.append("data", wbout); // <-- `data` holds the base64-encoded data
        // req.send(formdata)
    }


    const updateStep = (step) => {
        setErrorMessage({ ...errorMessage, type: "", message: "" });
        document.getElementById('content')?.scrollIntoView({ behavior: 'smooth' });

        switch (step) {
            case 0:
                setFileAttributes({})
                setFileName(undefined);
                setStep(0);
                break;
            case 1:
                // debugger;
                if (tipoCuenta == "") {
                    setErrorMessage({ ...errorMessage, type: "error", message: t("exceltransactions.typeAccountEmpty") });
                    return;
                }

                if (ultimosDigitos == "") {
                    setErrorMessage({ ...errorMessage, type: "error", message: t("exceltransactions.lastDigitsEmpty") });
                    return;
                }
                if (ultimosDigitos.length != 4) {
                    setErrorMessage({ ...errorMessage, type: "error", message: t("exceltransactions.lastDigitsIncompleted") });
                    return;
                }
                if (!Object.entries(fileAttributes).length == 0) {
                    //document.getElementById('content')?.scrollIntoView({ behavior: 'smooth' });
                    setStep(1);
                } else {
                    setErrorMessage({ ...errorMessage, type: "error", message: t("exceltransactions.bankingEntityEmpty") });
                    //  document.getElementById('content')?.scrollIntoView({ behavior: 'smooth' });
                }


                break;
            case 2:
                if (fileName != undefined) {
                    setStep(2);
                } else {
                    //   document.getElementById('content')?.scrollIntoView({ behavior: 'smooth' });
                    setErrorMessage({ ...errorMessage, type: "error", message: t("exceltransactions.noFileSelected") })
                }
                break;
            case 3:
                let checkeRequiredFields = (arr, target) => target.every(v => arr.includes(v));
                if (checkeRequiredFields(selectedColumns.map(x => x.type), coinscrapExcelEments.filter(x => x.required).map(x => x.key))) {
                    setStep(3);
                    createNewDocument();
                } else {
                    //   document.getElementById('content')?.scrollIntoView({ behavior: 'smooth' });
                    setErrorMessage({ ...errorMessage, type: "error", message: t("exceltransactions.missingInformation") })
                }
                break;
            case 4:
                exportNewDocument()
                setStep(4);


            default:
                break;
        }
    }

    const stepColor = (position) => {
        if (position <= step) {
            return "sec-green-background text-white"
        } else {
            return "bg-gray-100 text-gray-500"
        }
    }


    return (<div className="flex h-screen overflow-hidden">

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
                <div id="content" className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                    {/* Page header */}
                    <div className="sm:flex sm:justify-between sm:items-center mb-8">

                        {/* Left: Title */}
                        <div className="mb-4 sm:mb-0">
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("exceltransactions.title")} </h1>
                            {/* <h2 className='text-xl md:text-2xl text-gray-800 font-bold pl-2'>$47,347.09</h2> */}
                        </div>

                        {/* Right: Actions */}
                        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                        </div>

                    </div>


                    <div className="flex-1">

                        {/* Header */}


                        {/* Progress bar */}
                        <div className="pb-8">
                            <div className="max-w-6xl mx-auto w-full">
                                <div className="relative">
                                    <div className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-gray-200" aria-hidden="true"></div>
                                    <ul className="relative flex justify-between w-full">
                                        <li>
                                            <Button arial-label="btn-step1" className={`flex items-center justify-center w-6 h-6 rounded-full text font-semibold btn-xs ${stepColor(0)}`} onClick={() => { updateStep(0) }}>1</Button>
                                        </li>
                                        <li>
                                            <Button className={`flex items-center justify-center w-6 h-6 rounded-full text font-semibold  ${stepColor(1)}`} onClick={() => { updateStep(1) }}>2</Button>
                                        </li>
                                        <li>
                                            <Button className={`flex items-center justify-center w-6 h-6 rounded-full text font-semibold  ${stepColor(2)}`} onClick={() => { updateStep(2) }}>3</Button>
                                        </li>
                                        <li>
                                            <Button className={`flex items-center justify-center w-6 h-6 rounded-full text font-semibold  ${stepColor(3)}`} onClick={() => { updateStep(3) }}>4</Button>
                                        </li>
                                        <li>
                                            <Button className={`flex items-center justify-center w-6 h-6 rounded-full text font-semibold  ${stepColor(4)}`} onClick={() => { updateStep(4) }}>5</Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="max-w-6xl mx-auto w-full">
                        {errorMessage.type && <div className='pt-3'><Banner type={errorMessage.type} open setOpen={true}> {errorMessage.message}</Banner> </div>}
                    </div>

                    {/* CONTENT  */}
                    {step == 0 && <GetTransactions00 fileAttributes={fileAttributes} setFileAttributes={setFileAttributes} periodoCoinscrap={periodoCoinscrap} updateStep={updateStep} setTipoCuenta={setTipoCuenta} setUltimosDigitos={setUltimosDigitos} setCuentaCoinscrap={setCuentaCoinscrap} />}
                    {step == 1 && <GetTransactions01 handleFile={handleFile} updateStep={updateStep} fileName={fileName} fileFormat={fileAttributes.format} fileAttributes={fileAttributes}/>}
                    {step == 2 && <GetTransactions02 columns={fileColumns} fileData={fileData} coinscrapExcelEments={coinscrapExcelEments} updateStep={updateStep} updateSelectedColumns={updateSelectedColumns} />}
                    {step == 3 && <GetTransactions03 finalDataToProcess={finalDataToProcess} columns={coinscrapExcelEments.map(c => { return c.key })} updateStep={updateStep} coinscrapAccount={cuentaCoinscrap} periodoCoinscrap={periodoCoinscrap} />}
                    {step == 4 && <GetTransactions04 updateStep={updateStep} />}
                </div>
            </main>

        </div>

    </div>

    )
}