import React from 'react'
import Footer from '../../../partials/Footer'
import Header from '../../../partials/Header'

import BloqueBienvenida from './components/BloqueBienvenida'
import BloqueDescrip from './components/BloqueDescrip'
import BloqueNews from './components/BloqueNews'
import BloquePuntos from './components/BloquePuntos'

import BloqueElegir from './components/BloqueElegir'
import BloqueFooter from './components/BloqueFooter'
import BloqueLogos from './components/BloqueLogos'


export default function CarbonManager() {
  return (
    <div>
      <Header />
      <BloqueBienvenida />
      <BloquePuntos />
      <BloqueLogos />
      <BloqueDescrip />
      <BloqueElegir />
      {/* <BloqueDemo/> */}
      {/* <BloquePlanes/> */}
      <BloqueNews />
      {/* <BloqueMoreRead/> */}
      <BloqueFooter />
      <Footer />
    </div>
  )
}
