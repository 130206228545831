import { useEffect, useState } from "react";
export default function QuestionButtons({ handleGoQuestions, currentQuestion, externalQuestionsDto, showFinish, externalQuestionnaire, t, handleFinishContactQuestionnaire }) {

    const [showFinishButton, setShowFinishButton] = useState();
    useEffect(() => {
        setShowFinishButton(showFinish)
    }, [showFinish]);


    const handleNextPreviousButtons = (direction) => {
        if (externalQuestionsDto && externalQuestionsDto?.length > 0) {
            if (direction === "next" && currentQuestion.currentIndex < externalQuestionsDto.length - 1) {
                const nextQuestion = externalQuestionsDto[currentQuestion.currentIndex + 1];
                handleGoQuestions(nextQuestion, true, currentQuestion.currentIndex + 1)
            } else if (direction === "previous" && currentQuestion.currentIndex > 0) {
                const previousQuestion = externalQuestionsDto[currentQuestion.currentIndex - 1];
                handleGoQuestions(previousQuestion, true, currentQuestion.currentIndex - 1)

            }
        }
    };


    return (
        <div>
            <div className='flex items-center  justify-between gap-4 flex-wrap pt-4 border-t border-gray-300' style={{ marginTop: '4rem', alignItems: 'stretch' }}>
                {currentQuestion.currentIndex > 0 && (
                    <button type='button' className='btn-sm btn-green rounded-lg flex-1 ' onClick={() => handleNextPreviousButtons("previous")}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right rotate-180	" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 6l6 6l-6 6" />
                        </svg>
                        {currentQuestion.currentIndex}. {externalQuestionsDto[currentQuestion.currentIndex - 1].name}
                    </button>
                )}

                {externalQuestionsDto && (currentQuestion.currentIndex < externalQuestionsDto.length - 1) && (
                    <button type='button' className='btn-sm btn-green rounded-lg flex-1 text-right ' onClick={() => handleNextPreviousButtons("next")}>
                        {currentQuestion.currentIndex + 2}. {externalQuestionsDto[currentQuestion.currentIndex + 1].name}
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right " width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 6l6 6l-6 6" />
                        </svg> </button>
                )}
            </div>

            {showFinishButton &&
                <div className='mt-4  flex justify-center'>
                    <button className="btn-sm  btn-banner-o rounded-lg px-8 py-2 flex-1" type="submit"
                        onClick={() => (handleFinishContactQuestionnaire())}>
                        {t("fpThird.finalizar")}
                    </button>
                </div>
            }
        </div>
    )
}
