import axios from 'axios';
import { default as React, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import UserService from './../../../services/UserService';
import BarChart from './../charts/BarChart03';
import LineChart from './../charts/LineChart01';
import { formatThousands, hexToRGB, tailwindConfig } from './../utils/Utils';
import { useTranslation } from 'react-i18next';

function GraficaDineroGenerado() {

  const [t, i18n] = useTranslation("global");

  const [transactions, setTransactions] = useState({ data: [], isFetching: false });
  const [uniqueLabelsMonths, setUniqueLabelsMonths] = useState([]);
  const [totalGanado, setTotalGanado] = useState(0);
  const [lineChartData, setLineChartData] = useState({})
  const [barChartData, setBarChartData] = useState({})

  // const [chartData, setChartData] = useState({});
  useEffect(() => {
    const fetchTransactionsUser = async () => {

      try {
        const inicio = new Date(new Date().getFullYear(), 0, 1);
        const fin = new Date(new Date().getFullYear(), 11, 31);

        // Get year, month, and day part from the date
        var inicioFormated = inicio.toLocaleString("default", { year: "numeric" }) + "-" + inicio.toLocaleString("default", { month: "2-digit" }) + "-" + inicio.toLocaleString("default", { day: "2-digit" });
        var finFormated = fin.toLocaleString("default", { year: "numeric" }) + "-" + fin.toLocaleString("default", { month: "2-digit" }) + "-" + fin.toLocaleString("default", { day: "2-digit" });

        await axios.get(`${process.env.REACT_APP_TRANSACTIONS_USER_ALL}/forestOwner/${UserService.getUserId()}/${inicioFormated}/${finFormated}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
     
          setTransactions({ data: response.data, isFetching: true });




        });
      } catch (exception) {
   
        setTransactions({ data: [], isFetching: true });
      }
    };

    fetchTransactionsUser();
  }, []);




  useEffect(() => {

    if (transactions.data.length > 0) {
      const uniqueMonths = ([...new Set(transactions.data.map((x) => {
        return new Date(x.date).toLocaleString("default", { month: "2-digit" });
      }))]);
    
      setUniqueLabelsMonths(uniqueMonths)

      let acumuladoPorMes = []
      let totalPorMes = []

      for (let i = 0; i < uniqueMonths.length; i++) {
        let sumaMes = transactions.data.filter(t =>
          new Date(t.date).toLocaleString("default", { month: "2-digit" }) == uniqueMonths[i]).reduce((accumulated, currentValue) => {
            return accumulated + currentValue.totalPrice;
          }, 0);

        acumuladoPorMes.push(sumaMes + (acumuladoPorMes.length > 0 ? acumuladoPorMes[acumuladoPorMes.length - 1] : 0));
        totalPorMes.push(sumaMes)
      }

      setTotalGanado(transactions.data.reduce((accumulated, currentValue) => {
        return accumulated + currentValue.totalPrice;
      }, 0));


      setLineChartData({
        labels: uniqueMonths,
        datasets: [
          // Indigo line
          {
            data: acumuladoPorMes,
            label: "ganancias",
            fill: true,
            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
            borderColor: tailwindConfig().theme.colors.indigo[500],
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
            clip: 20,
          },
        ],
      })


      setBarChartData({
        labels: uniqueMonths,
        datasets: [
          // Indigo line
          {
            data: totalPorMes,
            label: t("dash.profits"),
            fill: true,
            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
            borderColor: tailwindConfig().theme.colors.indigo[500],
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
            clip: 20,
          },
        ],
      });
    }


  }, [transactions]);


  return (
    <div className="flex flex-col col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center justify-between">
        <h2 className="text-2xl font-semibold text-gray-800">{t("dash.dashChartsTitle")}</h2>
        <div className="text-2xl font-bold text-gray-800 ml-4 mr-2">{formatThousands(totalGanado)} €</div>
      </header>
      {/* Card content */}
      <div className="flex flex-col h-full">
        {!transactions.isFetching && <Spinner />}

        {transactions.isFetching && <div id="divgraficasdashboard" className="grid grid-cols-12 gap-6">



          {/* Change the height attribute to adjust the chart height */}
          <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white rounded-sm border border-gray-200 m-2">
            <header className="px-4 py-3 border-b border-gray-100 flex items-center justify-between">
              <h3 className="text-xl font-semibold text-gray-800">{t("dash.cumulativeAnnualEarnings")}</h3> </header>

            {transactions.isFetching &&
              <div>
                {uniqueLabelsMonths.length == 0 ? <div className='px-4 py-2'>{t("dash.noTransactions")}</div> :
                  <LineChart data={lineChartData} width={595} height={318} unit={" €"} />
                }
              </div>
            }
          </div>


          <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white rounded-sm border border-gray-200 m-2">
            <header className="px-4 py-3 border-b border-gray-100 flex items-center justify-between">
              <h3 className="text-xl font-semibold text-gray-800">{t("dash.cumulativeMonthlyEarnings")}</h3> </header>

            {transactions.isFetching &&
              <div>
                {uniqueLabelsMonths.length == 0 ? <div className='px-4 py-2'>{t("dash.noTransactions")}</div> :
                  <BarChart data={barChartData} width={595} height={248} unit={" €"} />
                }
              </div>
            }

          </div>
        </div>}

      </div>



    </div>
  );
}

export default GraficaDineroGenerado;
