import React, { useState, useEffect } from 'react'

export default function FilterYear({
  emisionYear,
  actualizar
}) {

  const years = emisionYear

  let maxYears = Math.max(...years)
  const [yearClick, setyearClick] = useState(maxYears)

  const handleChangeYear = (v) => {
    setyearClick(v)
    handlerUpdate(v)
  }

  const handlerUpdate = (v) => {
    actualizar(v)
  }

  
  return (

    <div>
      <ul className="flex flex-wrap -m-1">
        {years.map((v, i) =>


          <li className="m-1" key={i}>
            {v === yearClick ?
              <button arial-label="btn-ageClick" onClick={() => handleChangeYear(v)} className='inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out'

              >
                {v}<span className="ml-1 text-indigo-200"></span>
              </button>
              :
              <button arial-label="btn-ageNoClick" onClick={() => handleChangeYear(v)} className='inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-gray-600 bg-opacity-60 text-white duration-150 ease-in-out'

              >
                {v}<span className="ml-1 text-indigo-200"></span>
              </button>

            }
          </li>

        )}
      </ul>

    </div>
  )
}
