import React, { useState, useRef, useEffect,useContext } from 'react';
import Transition from './Transition.js';
import { IdiomContext } from "../../../context/createcontext.js"
import { useTranslation } from 'react-i18next';

function DropdownFilter({
  statusPaymentUniq,
  stateCheck,
  applyStatePaymentFilter,
  nombreFiltro
}) {
 
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(statusPaymentUniq);
 
  }, [data]);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  const [check, setcheck] = useState(false)

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  //actualiar el check del filtro:
  const [checked, setChecked] = useState([]);
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  //Aplicar cambios en la tabla
  const handleApply = () => {
    setDropdownOpen(false);
   
    const newState = stateCheck.map(obj => {
      // 👇️ if id equals 2, update country property
      if (checked.some(v => (v === obj.state))) {
       
        return { ...obj, check: true };
      }
     
      return { ...obj, check: false };

    });
    applyStatePaymentFilter(newState);
  };

  // Borrar filtros seleccionados
  const handleClear = () => {
    setDropdownOpen(false);
   

    const newState = stateCheck.map(obj => {
      return { ...obj, check: false };
    });
    applyStatePaymentFilter(newState);
    stateCheck = newState;

    document.querySelectorAll('input[type=checkbox]').forEach(function(checkElement) {
      checkElement.checked = false;
  });
  setChecked([]);
 

  };

  useEffect(() => {
 
    setDropdownOpen(false);


    const newState = stateCheck.map(obj => {
      return { ...obj, check: false };
    });
    applyStatePaymentFilter(newState);
    stateCheck = newState;

    document.querySelectorAll('input[type=checkbox]').forEach(function(checkElement) {
      checkElement.checked = false;
  });
  setChecked([]);

  }

    , []);

  


  
    // cambiar el idioma

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
  return (
    <div className="relative inline-flex">
      <button arial-label="btn-openFilter"
        ref={trigger}
        className=" btn justify-between  bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className={`${nombreFiltro && " mr-2"}`}>{nombreFiltro}</span>
        <span className="sr-only">{t("sidebar.filtro")}</span>
        <svg className="w-4 h-4 fill-current mp-2" viewBox="0 0 16 16">
          <path d="M9 15H7a1 1 0 010-2h2a1 1 0 010 2zM11 11H5a1 1 0 010-2h6a1 1 0 010 2zM13 7H3a1 1 0 010-2h10a1 1 0 010 2zM15 3H1a1 1 0 010-2h14a1 1 0 010 2z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`origin-top-left z-30 absolute top-full min-w-56 bg-white border border-gray-200 pt-1.5 rounded shadow-lg overflow-hidden mt-1 align-left`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown}>
          <div className="text-xs font-semibold text-gray-400 uppercase pt-1.5 pb-2 px-4">{t("sidebar.filtro")}</div>

          <ul className="mb-4">

            {/* map para categorias */}
            {stateCheck.length === 0 ?
              <li>
             {t("sidebar.cargando")}
              </li>
              :
              stateCheck.map((item, index) => (
                <li className="py-1 px-3" key={index}>
                  <label className="flex items-center">
                    <input value={item.state} type="checkbox" onChange={handleCheck} defaultChecked={item.check} />
                    <span className="text-sm font-medium ml-2">{item.state}</span>
                  </label>
                </li>
              ))

            }
          </ul>
          <div className="py-2 px-3 border-t border-gray-200 bg-gray-50">
            <ul className="flex items-center justify-between">
              <li>
                <button arial-label="btn-cleanFilter" className="btn-sm bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600" onClick={handleClear} >{t("sidebar.limpiar")}</button>
              </li>
              <li>
                <button arial-label="btn-applyFilter" className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white" onClick={handleApply} onBlur={() => setDropdownOpen(false)}>{t("sidebar.aplicar")}</button>
              </li>
            </ul>
          </div>
        </div>
      </Transition >
    </div >
  );
}

export default DropdownFilter;
