import { React, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import { formatThousands } from '../utils/Utils';
import { IdiomContext } from "./../../../context/createcontext";
import UserService from './../../../services/UserService';
import Dialog from './Dialog';


const Styles = styled.div`
  align-items: center;
  color: black;
  margin: 1rem; 

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 9px;
    border-radius: 5px;
    background: white;
    outline: none;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border-radius: 50%;
      appearance: none;
      width: 25px;
      height: 25px;
      background: white;
      cursor: pointer;
      outline: 3px solid var(--airco2-green-color);

    }
    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      background: white;
      cursor: pointer;
      outline: 5px solid #333;

    }
  }
`;


function RangeSlider({ co2price, discount }) {

  const d = new Date();
  const  currentYear = d.getFullYear();

  //Alert dialog
  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
  });

  const handleDialog = (message, isLoading) => {
    setDialog({
      message,
      isLoading,
    });
  };

  const handleDelete = () => {
    handleDialog(`${t("dash.stripe_continuarpago")}`, true);
  };

  const areUSure = (choose) => {
    if (choose) {
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };

  const [slider, setSlider] = useState(0);

  const [emisionstotal, setEmisionstotal] = useState("");



  // handleOnChange = (e) => setSlider({ value: e.target.value })
  const handleOnChange = (event) => {
    setSlider(event.target.value);
   
  };

  //Obtiene la huella total generada de una empresa por año /emissions/v1/user/total
  useEffect(() => {
    fetch(`${process.env.REACT_APP_EMISSIONS_USER_TOTAL}/${UserService.getUserId()}/${currentYear}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        },
      }).then((response) => {
        return response.json()
      })
      .then((emisionstotal) => {
      
        if (!emisionstotal.error) {
          setEmisionstotal(emisionstotal)
        }
      })
  }, [])



  const [co2CompensadoTotal, setCo2CompensadoTotal] = useState("");

  //Obtiene la huella total generada de una empresa por año /emissions/v1/user/total
  useEffect(() => {
    fetch(`${process.env.REACT_APP_TRANSACTIONS_USER_TOTAL_CO2}${UserService.getUserId()}/${currentYear}-01-01/${currentYear}-12-31`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }).then((response) => {
        return response.json()
      })
      .then((emisionstotal) => {
      
        if (!emisionstotal.error) {
          setCo2CompensadoTotal(emisionstotal.co2)
        }
      })
  }, [])




  const co2TotalToneladas = (emisionstotal.co2 / 1000)
  const co2PorCOmpensar = co2TotalToneladas - co2CompensadoTotal;
  const [co2tonsSeleccionadas, setCo2tonsSeleccionadas] = useState(0);
 
  const [eurtopay, setEurtopay] = useState(0);
  useEffect(() => {
   const toneladasParaCompensar = (co2PorCOmpensar * slider) / 100 ;
    setCo2tonsSeleccionadas(toneladasParaCompensar);

    if (co2price != undefined) {
      setEurtopay(toneladasParaCompensar * co2price);
      if (discount != undefined) {
        const co2PriceDiscount = Number(formatThousands(co2price - (co2price * discount)).replace(",", "."));
    
        setEurtopay(toneladasParaCompensar * co2PriceDiscount);

      }
    }

  }, [slider])

  // if (!Number.isNaN(slider.value)) {
  // }

  // cambiar el idioma

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <Row className='hidden  lg:flex lg:grow'>
        <Col className='col-12 col-sm-6 col-md-6 col-xl-6 text-xl'>
          <span>{t("dash.compensar")} <b>{slider} %</b> {t("dash.de")} <b>{emisionstotal.length === 0 ? <Spinner animation="border" size="sm" /> : formatThousands(co2PorCOmpensar)} t</b> {t("dash.equivale")} <b>{emisionstotal.length === 0 ? <Spinner animation="border" size="sm" /> : (formatThousands(eurtopay))} €</b> </span ><br></br><br></br>
        </Col>
        <Col className=' col-12 col-sm-6 col-md-6 col-xl-6'>
          <span className=''></span>
        </Col>
      </Row>
      <Row className='hidden  lg:flex lg:grow'>
        <Col className=' col-12 col-sm-6 col-md-6 col-xl-6 pb-2'>
          <Styles>
            <input type="range" min={0} max={100} value={slider} className="slider" onChange={handleOnChange} />
            <span className=' float-left text-xs mt-1'>0% &nbsp;</span>
            <span className=" float-right text-xs mt-1">&nbsp; 100%</span>
          </Styles>
        </Col>
        <Col className='col-12 col-sm-6 col-md-6 col-xl-6 '>

          {slider > 0?
            <button arial-label="btn-compensar" className=' btn bg-indigo-500 hover:bg-indigo-600 text-white sliderbtn' onClick={() => handleDelete()}>
              <a className='hover:text-white'><span>{t("dash.compensar")} {slider} % </span></a>
            </button>
            :

            <button arial-label="btn-compensar" disabled className=' btn bg-indigo-500 hover:bg-indigo-600 text-white sliderbtn' onClick={() => handleDelete()}>
              <a className='hover:text-white'><span>{t("dash.compensar")} {slider} % </span></a>
            </button>

          }





        </Col>
      </Row>

      {/* <div className='flex grow  md:hidden'> */}
      <Row className='flex grow  lg:hidden'>
        <Col className='col-12  text-center text-xl'>
          <span>{t("dash.compensar")} <b>{slider} % </b>{t("dash.de")} <b>{emisionstotal.length === 0 ? <Spinner animation="border" size="sm" /> : formatThousands(co2PorCOmpensar)} t</b> {t("dash.equivale")} <b>{emisionstotal.length === 0 ? <Spinner animation="border" size="sm" /> : (formatThousands(eurtopay))} €</b> </span ><br></br><br></br>            </Col>
        <Col className=' col-12 '>
          <Styles>
            <input type="range" min={0} max={100} value={slider} className="slider" onChange={handleOnChange} />
            <span className=' float-left text-xs mt-1'>0% &nbsp;</span>
            <span className=" float-right text-xs mt-1">&nbsp; 100%</span>
          </Styles>
        </Col>

      </Row>
      <Row className='flex grow  lg:hidden text-center'>
        {/* <Col className=' col-12 pb-3'>
            <span className=''>{eurtopay.toFixed(2)} €</span>
            </Col> */}
        <Col className='col-12 sliderbtn'>
        {slider > 0?
            <button arial-label="btn-compensar" className=' btn bg-indigo-500 hover:bg-indigo-600 text-white sliderbtn' onClick={() => handleDelete()}>
              <a className='hover:text-white'><span>{t("dash.compensar")} {slider} % </span></a>
            </button>
            :

            <button arial-label="btn-compensar" disabled className=' btn bg-indigo-500 hover:bg-indigo-600 text-white sliderbtn' onClick={() => handleDelete()}>
              <a className='hover:text-white'><span>{t("dash.compensar")} {slider} % </span></a>
            </button>

          }
        </Col>
      </Row>
      {/* </div> */}

      {dialog.isLoading && (
        <Dialog

          onDialog={areUSure}
          message={dialog.message}
          amount={formatThousands(eurtopay)}
          co2Tons={co2tonsSeleccionadas.toFixed(2)}
          unitPrice={co2price}
          discount={discount}
          userId={UserService.getUserId()}
          customerId={UserService.getCustomerId()}
          co2Total={co2TotalToneladas}
          co2Percent={slider}
        />
      )}
    </>
  )

}
export default RangeSlider;