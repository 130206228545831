
import React, { useContext } from 'react';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';
import icon7 from '../../images/icono07.svg'
import icon8 from '../../images/icono08.svg'
import icon9 from '../../images/icono09.svg'


function Process() {
  const [lng, updateLng] = useContext(IdiomContext)
const [t, i18n] = useTranslation("global");

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-10 pb-12 md:pt-16 md:pb-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="text48 sec-blue-text mb-4" data-aos="fade-up" >{t("forestep.cosasfaciles")}</h2>
            {/* <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit laborum — semper quis lectus nulla.</p> */}
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none"  >

            {/* 1st item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up">
              <div aria-hidden="true" className="absolute h-1 border-t border-dashed border-blue hidden md:block" style={{ width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px' }} data-aos="fade-in" data-aos-delay="200"></div>
              
                <img src={icon7} width="64" height="64" alt="imagen" />
              <h3 className=" mb-2 sec-blue-text text24"> {t("forestep.alta")}</h3>
          
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
              <div aria-hidden="true" className="absolute h-1 border-t border-dashed border-blue hidden md:block" style={{ width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px' }} data-aos="fade-in" data-aos-delay="400"></div>
    
              <img src={icon8} width="64" height="64" alt="imagen" />
              <h3 className="mb-2 sec-blue-text text24"> {t("forestep.rellena")}</h3>
             
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="400">
              {/* <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <path className="stroke-current text-purple-300" strokeWidth="2" strokeLinecap="square" d="M21 35l4 4 12-15" fill="none" fillRule="evenodd" />
                <path className="stroke-current text-purple-100" d="M42 29h-3M42 34h-7M42 39H31" strokeWidth="2" strokeLinecap="square" />
              </svg> */}
              <img src={icon9} width="64" height="64" alt="imagen" />
              <h3 className="mb-2 sec-blue-text text24"> {t("forestep.ganardinero")}</h3>
          
            </div>

           

          </div>

        </div>
      </div>
    </section>
  );
}

export default Process;
