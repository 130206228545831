import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { MapContainer, TileLayer, LayerGroup, LayersControl, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import pinasterLayer from "../data/pinasterLayer.json";
import pradiata from "../data/pradiata.json"
import castano from "../data/castano.json"
import { GeoJSON } from 'react-leaflet';
import eucalypto from '../data/eucalyptusnuevo.json';
import fsylvatica from '../data/fsylvatica.json'
import ppinea from '../data/ppinea.json'
import qrobur from '../data/qrobur.json'
import psylvestris from '../data/psylvestris.json';
import quercusilex from '../data/quercusilex.json'
import Translations from '../../../services/Translations';

function mapa_catastro_especie() {


  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const center = {
    lat: 40.512053442143165,
    lng: -3.785596205501745,
  }

  const outer = [
    [26.43122806450644, -23.728142674836377],
    [49.55372551347579, 14.108771387663646]
  ]

  return (
    // <>
    <MapContainer
      center={center}
      zoom={6}
      zoomControl={true}
      minZoom="5"
      maxBounds={outer}
      maxZoom="20"
      className="z-0 mb-4 map">

      <LayersControl position="topright" collapsed={false}>
        <LayersControl.BaseLayer name="Standard" checked>
          <LayerGroup>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom="20"
            />
          </LayerGroup>
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name={t("forestList.ePinoPinaster")}>
          <LayerGroup>
            <GeoJSON key="pinaster" data={pinasterLayer} color={"red"}>
              <Tooltip sticky opacity={1} >
                <label className='text-red font-semibold'>{t("forestList.ePinoPinaster")}</label>
              </Tooltip>
            </GeoJSON>
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name={t("forestList.ePinoRadiata")}>
          <LayerGroup>
            <GeoJSON key="pradiata" data={pradiata} color={"blue"}>
              <Tooltip sticky opacity={1}  >
                <label className='font-semibold' style={{ color: '#8c8af2' }}>{t("forestList.ePinoRadiata")}</label>
              </Tooltip>
            </GeoJSON>
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name={t("forestList.ePiñoSilvestre")}>
          <LayerGroup>
            <GeoJSON key="psylvestris" data={psylvestris} color={"grey"}>
              <Tooltip sticky opacity={1}  >
                <label className='font-semibold' style={{ color: '#a1979c' }}>{t("forestList.ePiñoSilvestre")}</label>
              </Tooltip>
            </GeoJSON>
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name={t("forestList.eCastaño")}>
          <LayerGroup>
            <GeoJSON key="cas" data={castano} color={"green"} >
              <Tooltip sticky opacity={1} >
                <label className='text-green-600 font-semibold'>{t("forestList.eCastaño")}</label>
              </Tooltip>
            </GeoJSON>
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay className="blue" name={t("forestList.eEucalipto")}>
          <LayerGroup>
            <GeoJSON key="eucalyp" data={eucalypto} color={"purple"} >
              <Tooltip sticky opacity={1} >
                <label className='text-purple-600 font-semibold'>{t("forestList.eEucalipto")}</label>
              </Tooltip>
            </GeoJSON>
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name={t("forestList.eHaya")}>
          <LayerGroup>
            <GeoJSON key="fsyl" data={fsylvatica} color={"black"} >
              <Tooltip sticky opacity={1} >
                <label className='text-black-600 font-semibold'>{t("forestList.eHaya")}</label>
              </Tooltip>
            </GeoJSON>
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name={t("forestList.ePinoPiñonero")}>
          <LayerGroup>
            <GeoJSON key="ppinea" data={ppinea} color={"yellow"} >
              <Tooltip sticky opacity={1} >
                <label className='text-yellow-600 font-semibold'>{t("forestList.ePinoPiñonero")}</label>
              </Tooltip>
            </GeoJSON>
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name={t("forestList.eRoble")}>
          <LayerGroup>
            <GeoJSON key="qrobur" data={qrobur} color={"brown"} >
              <Tooltip sticky opacity={1} >
                <label className='font-semibold' style={{ color: '#b36968' }}>{t("forestList.eRoble")}</label>
              </Tooltip>
            </GeoJSON>
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name={t("forestList.eEncina")}>
          <LayerGroup>
            <GeoJSON key="qrobur" data={quercusilex} color={"white"} >
              <Tooltip sticky opacity={1} >
                <label className='font-semibold' style={{ color: '#000000' }}>{t("forestList.eEncina")}</label>
              </Tooltip>
            </GeoJSON>
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>

    </MapContainer>
  )

}
export default mapa_catastro_especie;