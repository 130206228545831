import { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Banner from "../../../../../components/utils/Banner";
import UserService from "../../../../../services/UserService";

export default function ModalUpdateCertification({
  modalCertification,
  setModalCertification,
  updateCertificationData,
}) {

  const [t, i18n] = useTranslation("global");
  const certificationStates = ["CREATED", "SENT", "ACCEPTED", "COMPLETED"];
  const [notifyUser, setNotifyUser] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState(modalCertification?.certification?.trackingNumber != undefined ? modalCertification?.certification?.trackingNumber : "");
  const [urlCertificationDoc, setUrlCertificationDoc] = useState(modalCertification?.certification?.urlCertificationDoc != undefined ? modalCertification?.certification?.urlCertificationDoc : "");
  const [certificationStatus, setCertificationStatus] = useState(modalCertification?.certification?.certificationMitecoStatus);
  const [saving, isSaving] = useState(false);
  const [message, setMessage] = useState({
    type: "",
    message: "",
    open: false,
  });

  const handleSubmitPut = async (e) => {
    e.preventDefault();
    isSaving(true);
    const url = `${process.env.REACT_APP_CH_CERTIFICATION}?notifyUser=${notifyUser}`;
    let data = {
      id: modalCertification?.certification?.id,
      userId: modalCertification?.certification?.userId,
      calculationId: modalCertification?.certification?.calculationId,
      certificationMitecoStatus: certificationStatus,
      trackingNumber: trackingNumber,
      urlCertificationDoc: urlCertificationDoc
    };

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserService.getToken()}`,
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      updateCertificationData(responseData.data);

      setMessage({
        ...message,
        type: "success",
        message: t("account.actualizacion-ok"),
      });
    } catch (error) {
      console.error("Error:", error.message);
      setMessage({
        ...message,
        type: "error",
        message: t("account.actualizacion-ko"),
      });
    }

    isSaving(false);
  };

  const handleCloseModal = () => {
    setModalCertification({ view: false, certification: null });
    setMessage({
      type: "",
      message: "",
      open: false,
    });
  };


  return (
    <Modal
      show={modalCertification?.view}
      animation={false}
      data-aos="fade-up"
    >
      <Modal.Header className="sec-green-background">
        <Modal.Title>
          Editar certificación {modalCertification?.certification?.id + " - " + modalCertification?.certification?.certificationType} 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message.type !== "" && (
          <Banner className={`mb-2`} type={message.type} open setOpen={true}>
            {message.message}
          </Banner>
        )}
        <Row className="font-bold  mb-2">
          <Col xs md="12" className="text-center mb-4">
            {"Cálculo " + modalCertification?.certification?.questionnaireName + " (" + (modalCertification?.certification?.isCalculationVerified ? "Verificado" : "No verificado") + ")"}
          </Col>
          <Col xs md="6">
            {modalCertification?.certification?.organizationName + " " + modalCertification?.certification?.calculationYear}
          </Col>
          <Col xs md="6" className="text-right">
            {modalCertification?.certification?.userEmail}
          </Col>
        </Row>
        <Form onSubmit={(e) => handleSubmitPut(e)} className="space-y-4 mt-4">
          <Row>
              <Col xs md="6" className="flex justify-between items-end">
                <label className="ml-2 text-sm">Estado</label>
              </Col>
              <Col xs md="6">
                <select id="certificationState" name="certificationState" className="w-full text-sm" type="text" onChange={(e) => setCertificationStatus(e.target.value)}>
                    {certificationStates.map((state, i) => {
                        if (state == modalCertification?.certification?.certificationMitecoStatus) {
                            return (<option selected key={`status${i}`} name={state} className='w-full text-sm' value={state} >{state}</option>)
                        } else {
                            return (<option key={`status${i}`} name={state} className='w-full text-sm' value={state}>{state}</option>)
                        }
                    })
                    }
                </select>
              </Col>
            </Row>
            <Row>
              <Col xs md="6" className="flex justify-between items-end">
                <label className="ml-2 text-sm">Nº de seguimiento</label>
              </Col>
              <Col xs md="6">
              <input
                style={{ paddingRight: "1.8rem" }}
                className="w-full text-sm"
                id="trackingNumber"
                name="trackingNumber"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                type="text"
              ></input>
              </Col>
            </Row>
            <Row>
              <Col xs md="6" className="flex justify-between items-end">
                <label className="ml-2 text-sm">Url certificado del sello</label>
              </Col>
              <Col xs md="6">
              <input
                style={{ paddingRight: "1.8rem" }}
                className="w-full text-sm"
                id="urlCertificationDoc"
                name="urlCertificationDoc"
                value={urlCertificationDoc}
                onChange={(e) => setUrlCertificationDoc(e.target.value)}
                type="text"
              ></input>
              </Col>
            </Row>
              <div  className="flex justify-center mt-4">
                <label className="text-sm flex items-center gap-1">             
                  <input
                    id="notifyUer"
                    name="notifyUer"
                    type="checkbox"
                    checked={notifyUser}
                    onChange={(e) => setNotifyUser(e.target.checked)}
                  />
                  Notificar al usuario el cambio de estado 
                </label>
              </div>
          <div className="flex space-x-4 justify-center mt-4">
            <div className={`btn-green rounded p-2 `}>
              <button type="submit" disabled={saving}>
                {!saving ? (
                  t("footPrint.guardar")
                ) : (
                  <Spinner animation="border" variant="light" />
                )}
              </button>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => handleCloseModal()}
          className="btn-cancel rounded p-2"
        >
          {t("footPrint.cerrarCerrar")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}