import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IdiomContext } from "../../../context/createcontext";
import logistica from '../../../images/heavy_box_agqi.svg';
import agricola from '../../../images/nature_m5ll.svg';
import hosteleria from '../../../images/online_groceries_a02y.svg';
import general from '../../../images/resume.svg';
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';


export default function CalculationForm() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const location = useLocation();
    const idUserAdmin = location.state.idUser;
    const navigate = useNavigate();
    const tour = location.state.tour;
    const [questionnaries, setQuestionnaires] = useState({
        data: [],
        isFetching: false
    });

    const form = [
        { tipo: "general", "img": general, number: "1" },
        { tipo: 'agropecuario', "img": agricola, number: "2" },
        { tipo: 'logistica', "img": logistica, number: "3" },
        { tipo: 'hosteleria', "img": hosteleria, number: "4" },

    ]

    const getImageByNumber = (number) => {
        const matchingForm = form.find(item => item.number === number.toString());
        return matchingForm ? matchingForm.img : '';
    };




    useEffect(() => {
        const fetchQuestionnaires = async () => {
            try {

                const response = await axios.get(`${process.env.REACT_APP_CH_QUESTIONNAIRES}?userId=${UserService.hasRole(["admin"]) && idUserAdmin !== undefined && idUserAdmin !== null ? idUserAdmin.value : UserService.getUserId()}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                if (response.data.data.length === 1) {
                    nextPage(response.data.data[0].id, tour);
                }
                setQuestionnaires({ data: response.data, isFetching: true });

            } catch (exception) {
                setQuestionnaires({ data: [], isFetching: true });
            }
        };
        fetchQuestionnaires();
    }, []);



    const nextPage = (idQuestionnaire, tour) => {
        navigate(`/footprint/previousdata`, {
            state: { idUser: idUserAdmin, questionnaire: idQuestionnaire, tour: tour }
        });
    }


    const [selectedCardIndex, setSelectedCardIndex] = useState(null);
    const [selectedCardId, setSelectedCardId] = useState(null);

    const handleCardClick = (index, id) => {
        setSelectedCardIndex(index);
        setSelectedCardId(id)
    };

    const indexForm = selectedCardId


    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main className=''>
                    {!questionnaries.isFetching &&
                        <div className='text-center'>
                            <Spinner className='mt-4' animation="border" variant="dark" />
                        </div>
                    }

                    {questionnaries.isFetching &&
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            {idUserAdmin !== undefined && idUserAdmin !== null &&
                                <p className='text-right'> {t("footPrint.usuario")} : {idUserAdmin.label}</p>}
                            <div className="mb-8">
                                {/* Title */}
                                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("form.calculation")}</h1>
                            </div>
                            {/* Content */}
                            <div className='container'>
                                <p>
                                    {t("form.explicacion")}
                                </p>
                                <p>
                                    {t("form.explicacion2")}
                                </p>
                                <p>
                                    {t("form.explicacion3")} <span className='font-semibold'>{t("form.explicacion3b")}</span>
                                </p>
                                <br />
                                <p>
                                    {t("form.explicacion4")}
                                </p>

                                <div className='pt-5 flex flex-wrap gap-3' style={{ justifyContent: 'space-around' }}>

                                    {questionnaries?.data.data != undefined ?
                                        questionnaries?.data.data.map((x, i) => {
                                            return (
                                                <Card key={i}
                                                    onClick={x.active === false ? "" : () => handleCardClick(i, x.id)}
                                                    style={{ width: '15rem', height: '20rem', borderRadius: '20px', border: selectedCardIndex === i ? '4px solid var(--airco2-green-color)' : 'none', }}
                                                    className=' p-1 flex  shadow-lg   transform hover:scale-105 transition duration-700 ease-out'>
                                                    <Card.Body className={`flex`}>
                                                        <img src={getImageByNumber(x.id)} className={`py-2 `} />
                                                        <div className={` ${x.active === false ? 'restricted' : ''}`}>
                                                            {x.active === false &&
                                                                <p className='text-center text-lg mt-2'>{t("form.proximamente")}</p>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                    <Card.Title className='text-center text-sm font-semibold'>
                                                        {t(`${x.name}`)}
                                                    </Card.Title>
                                                </Card>
                                            )
                                        }) :
                                        <Spinner animation="border" variant="dark" className="m-4" />
                                    }
                                </div>
                                <div className="flex justify-center  mt-5 ">
                                    <button onClick={() => nextPage(indexForm, tour)} disabled={selectedCardId === null ? true : false} arial-label="btn-saveFom" className="btn sec-green-background text-white ml-3" type='onclick' >
                                        {t("footPrint.next")}
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right " width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>

                                </div>

                            </div>

                        </div>
                    }
                </main>

            </div >

        </div >
    )
}
