import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Banner from '../../../components/utils/Banner';
import { IdiomContext } from "../../../context/createcontext";
import UserService from '../../../services/UserService';
import Dropzone from '../utils/Dropzone';
import ImagesTable from '../marketplace/ImagesTable';

export default function ImagesAdmin({ state, origin, setUpdateImages }) {

  const location = useLocation();
  const data = location.state;
 


  //Ver si undefined o null
  //   if(data == undefined){
  //     window.location.href = "/forest/marketplace";
  //   }

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [images, setImages] = useState(origin === 'provider' ? state.gallery : state.productImageDtoList
  );
  const [btnModify, setBtnModify] = useState(false);

  const [formMontesMessage, setFormMontesMessage] = useState({
    type: '',
    message: ''
  });

  const [productResponse, setProductResponse] = useState({
    productResponseData: [],
    isFetching: false
  });


  //   if (data == undefined) {
  //     data = props.state
  //   }

  const urlParams = new URLSearchParams(window.location.search);
  const dataString = urlParams.get('origen');

  const actualizar = (newImages) => {
    setImages([...images, ...newImages])
    if (origin == 'product') {
      setUpdateImages([...images, ...newImages])
    }
  }

  const actulizarAtributosImg = (imagesChanged) => {
    setImages(imagesChanged)
    if (origin == 'product') {
      setUpdateImages(imagesChanged)
    }
    if (imagesChanged != images) {
      setBtnModify(true)
    }
  }

  const handlePostProduct = (e) => {
    e.preventDefault();

    let product = state;
    product.productImageDtoList = images;

    delete product["summary"];
    delete product["description"];
    delete product["translations"];

    let provider = state;
    provider.gallery = images;

    delete provider["summary"];
    delete provider["description"];
    delete provider["translations"];

    setFormMontesMessage({ ...formMontesMessage, type: "warning", message: "Actualizando imágenes..." });
    document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });

    const fetchProduct = async () => {
      try {
        setProductResponse({ productResponse: productResponse.productResponseData, isFetching: true });

        const response = await axios.post(
          origin === 'product' ? `${process.env.REACT_APP_MP_PRIVATE_PRODUCT}?slackAlertType=admin` : `${process.env.REACT_APP_MP_PRIVATE_PROVIDER}?slackAlertType=admin`,
          origin === 'product' ? product : provider,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${UserService.getToken()}`
            }
          }
        ).then(response => {
          if (response.status === 200) {
            setFormMontesMessage({ ...formMontesMessage, type: "success", message: "Imágenes actualizadas correctamente" });
            document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
          } else {
            setFormMontesMessage({ ...formMontesMessage, type: "error", message: "Ha ocurrido un error al actualizar" })
            document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
          }
          setProductResponse({ productResponseData: response.data, isFetching: false });
        })
      
      } catch (exception) {
        console.error(exception);
        setProductResponse({ productResponse: productResponse.productResponseData, isFetching: false });
        setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
        document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
      }
    };
    fetchProduct();
  }

  const urlDropzone = `${process.env.REACT_APP_MP_PRIVATE}/${origin}/${state.id}/images`;
  const maxSize = 1048576;

  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="mb-2 sm:mb-0">
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold"> imágenes de Marketplace✨</h1>
            </div>
            <div className='flex ml-4 mt-4'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="32" height="32" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                <line x1="15" y1="21" x2="15" y2="18" />
                <path d="M8 13l-2 -2" />
                <path d="M8 12l2 -2" />
                <path d="M8 21v-13" />
                <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
              </svg>
              {/* <h5 className='h5 block h4 underline ml-2 font-semibold'>{dataString=="mp"? data.name : data.value}</h5>  */}
            </div>

            <div className=" px-4 pb-4" style={{ width: '100%' }}>
              <div className='flex flex-row mb-4 '>
                {/* { dataString=="mp"? */}
                <div className="overflow-x-auto w-full">
                  <div className='flex justify-center'>
                    {formMontesMessage.type != "" &&

                      <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>}
                  </div>
                  <div className='table-auto w-full divide-y divide-gray-200 border-t '>
                    <ImagesTable origin={origin} img={images} actulizarAtributosImg={actulizarAtributosImg} />
                  </div>
                </div>
                {/* :""} */}
              </div>
              {btnModify ?
                <div className='flex justify-end mb-4'>
                  <button onClick={handlePostProduct} className='btn-sm btn-green'>

                    Guardar cambios
                  </button>
                </div>
                : ""
              }
              {/* <Dropzone origin={origin} id={state.id} actualizar={actualizar} /> */}
              <Dropzone actualizar={actualizar} url={urlDropzone} validExtension={['image/jpeg', 'image/jpg', 'image/png']} size={maxSize} />


            </div>
          </div>
        </main>
      </div>
    </div>
  );
}