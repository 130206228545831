import React from "react";
import { useState, useContext, useEffect} from "react";
import axios from "axios";
import UserService from "../../../services/UserService";
import { Modal} from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Col, Row } from 'react-bootstrap';
import { formatDate } from '../utils/Utils';
import Button from 'react-bootstrap/Button';


export default function ForestActionsModal({ props, updateTable }) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [size, setSize] = useState("");
    const [forestType, setForestType] = useState("");
    const [forestId, setForestId] = useState("");
    const [cadastre, setCadastre] = useState("");
    const [anualCO2, setAnualCO2] = useState("");
    const [forest, setForest] = useState(undefined);
    const [forestState, setForestState] = useState('');
    const [age, setAge] = useState("");
    const [availableCO2, setAvailableCO2] = useState("");
    const [creationDate, setcreationDate] = useState("");
    const [marco, setMarco] = useState("");
    const [owner, setOwner] = useState("");
    const [province, setProvince] = useState("");
    const [validationDate, setvalidationDate] = useState("");
    const [urlGml, setUrlGml] = useState("")
    const [hubSpotNegocio, setHubSpotNegocio] = useState("");
    const [hubSpotContrato, setHubSpotContrato] = useState("");
    const [cuestionarioEnviado, setCuestionarioEnviado] = useState("");
    const [commision, setCommision] = useState("");
    const [fire_insurance, setFire_insurance] = useState("");
    const [activationDate, setActivationDate] = useState("");
    const [emailOwner, setEmailOwner] = useState("");
    const [modificationDate, setModificationDate] = useState("");
    const [nextReloadDate, setNextReloadDate] = useState("");
    const [renovado, setRenovado] = useState("");
    const [respuestaDelete, setRespuestaDelete] = useState("");
    const [respuestaMP, setrespuesMP] = useState("")
    const [municipio, setMunicipio] = useState("");

    useEffect(() => {
        if(props.monte != null && props.user != null && props.action == "edit"){
            setShow(true);
            setForest(props.monte)
            setUserId(props.user.id,);
            setForestId(props.monte.id);
            setName(props.monte.name);
            setSize(props.monte.size);
            setForestType(props.monte.forestType);
            setCadastre(props.monte.cadastre);
            setAnualCO2(props.monte.anualCO2);
            setForestState(props.monte.state);
            setAge(props.monte.age);
            setAvailableCO2(props.monte.availableCO2);
            setcreationDate(props.monte.creationDate);
            setMarco(props.monte.marco);
            setOwner(props.monte.owner);
            setEmailOwner(props.user.email)
            setProvince(props.monte.province);
            setMunicipio(props.monte.municipio);
            setvalidationDate(props.monte.validationDate);
            setUrlGml(props.monte.urlGml);
            setHubSpotNegocio(props.monte.hubspot_negocioId)
            setHubSpotContrato(props.monte.hubspot_contratoId);
            setCuestionarioEnviado(props.monte.cuestionarioEnviado);
            setCommision(props.monte.commision);
            setFire_insurance(props.monte.fire_insurance);
            setActivationDate(props.monte.activationDate);
            setModificationDate(props.monte.modificationDate);
            setNextReloadDate(props.monte.nextReloadDate);
            setRenovado(props.monte.renovado);
        
            const forestTypeValido = () => {
                let cont = 0;
                typeForest.map((x) => {
                    if (props.monte.forestType == x.commonName) { cont++; }
                })
                if (cont > 0) {
                    setForestType(props.monte.forestType);
                } else {
                    setForestType("");
                }
            }

            forestTypeValido();

        }else if(props.action == "create"){
            setShow(true);
            setUserId(props.user.id,);
            setForestId('');
            setName('');
            setSize('');
            setForestType('');
            setCadastre('');
            setAnualCO2('');
            setForestState('CREATED');
            setAge('');
            setAvailableCO2('');
            setcreationDate(new Date());
            setMarco('');
            setOwner(props.user.name);
            setEmailOwner(props.user.email);
            setProvince('');
            setMunicipio('');
            setvalidationDate('');
            setUrlGml('')
            setHubSpotNegocio('')
            setHubSpotContrato("");
            setCuestionarioEnviado("");
            setCommision('');
            setFire_insurance('');
            setActivationDate('');
            setModificationDate('');
            setNextReloadDate('');
            setRenovado('');
        }else if(props.action == "delete"){
            setShowDelete(true);
            setForestId(props.monte.id);
            setName(props.monte.name)
        }
        
    },[props])
    

    const handleClose = () => {
        setForestId('');
        setUserId('');
        setShow(false);
        setName('');
        setSize('');
        setForestType('');
        setCadastre('');
        setAnualCO2('');
        setAge('');
        setAvailableCO2('');
        setcreationDate('');
        setMarco('');
        setOwner('');
        setEmailOwner('')
        setProvince('');
        setMunicipio('');
        setvalidationDate('');
        setUrlGml('');
        setShowDelete(false);
        setRespuestaDelete("");
        setHubSpotNegocio('');
        setHubSpotContrato("");
        setCuestionarioEnviado("");
        setrespuesMP('')
        setCommision('');
        setFire_insurance('');
        setActivationDate('');
        setModificationDate('');
        setNextReloadDate('');
        setRenovado('');
        setMsgEditarForest('');
    }


    const [msgEditarForest, setMsgEditarForest] = useState("");

    const handleSubmit = (e) => {

        e.preventDefault()
        setMsgEditarForest("")
        if ((e.target.state.value === "VALIDATED" || e.target.state.value === "VALIDATEDANDCERTIFICATED") && e.target.validationdate.value === "") {
        setMsgEditarForest("Es obligatorio poner una fecha de validación debido al estado seleccionado")
        return;
        }

        if ( e.target.state.value === "VALIDATEDANDCERTIFICATED" && e.target.municipio.value === "") {
        setMsgEditarForest("Es obligatorio seleccionar el municpio del monte")
        return;
        }


        forestId !== '' ?
        axios.post(`${process.env.REACT_APP_FOREST}?slackAlert=false&email=${emailOwner}&cityId=${e.target.municipio.value}&forestTypeId=${especie.id}`,
            JSON.stringify({
                state: e.target.state.value,
                id: forestId,
                anualCO2: parseFloat(e.target.anualCO2.value),
                forestType: e.target.forestType.value,
                cadastre: e.target.cadastre.value + '',
                name: e.target.name.value,
                size: e.target.size.value,
                userId: userId,
                age: e.target.age.value,
                availableCO2: e.target.disponibleCO2.value,
                creationDate: creationDate,
                validationDate: e.target.validationdate.value,
                marco: e.target.marco.value,
                owner: owner,
                province: e.target.province.value,
                municipio: e.target.municipio.value,
                urlGml: e.target.urlGml.value,
                idPadre: null,
                hubspot_negocioId: e.target.hubSpotNegocio.value,
                hubspot_contratoId: e.target.hubSpotContrato.value,
                cuestionarioEnviado: e.target.cuestionarioEnviado.value,
                commision: e.target.commision.value,
                fire_insurance: e.target.fire_insurance.value,
                activationDate: activationDate,
                modificationDate: modificationDate,
                nextReloadDate: nextReloadDate,
                renovado: renovado
            }), {
            headers: {
                Authorization: `Bearer ${UserService.getToken()}`,
                'content-type': 'application/json'
            }
        })
            .then(response => {
                if(response.status == 200){
                  updateTable(response.data);
                  setMsgEditarForest("Acualizado correctamente")
                }
            })
            
        :

        axios.post(`${process.env.REACT_APP_FOREST}?slackAlert=false&email=${emailOwner}`,
            JSON.stringify({
                state: e.target.state.value,
                id: forestId,
                anualCO2: parseFloat(e.target.anualCO2.value),
                forestType: e.target.forestType.value,
                cadastre: e.target.cadastre.value + '',
                name: e.target.name.value,
                size: e.target.size.value,
                userId: userId,
                age: e.target.age.value,
                availableCO2: e.target.disponibleCO2.value,
                creationDate: creationDate,
                validationDate: e.target.validationdate.value,
                marco: e.target.marco.value,
                owner: owner,
                province: e.target.province.value,
                municipio: e.target.municipio.value,
                urlGml: e.target.urlGml.value,
                idPadre: null,
                hubspot_negocioId: e.target.hubSpotNegocio.value,
                hubspot_contratoId: e.target.hubSpotContrato.value,
                cuestionarioEnviado: e.target.cuestionarioEnviado.value,
                commision: e.target.commision.value,
                fire_insurance: e.target.fire_insurance.value,
                activationDate: activationDate,
                modificationDate: modificationDate,
                nextReloadDate: nextReloadDate,
                renovado: renovado
            }), {
            headers: {
                'content-type': 'application/json'
            }
        })
            .then(response => {
                //PASAR RESPUEST AL PADRE PARA ACTUALIZAR LA TABLA
                updateTable(response.data);
                // setMontes({ montesData: [...montes.montesData, response.data], isFetching: false });
            setMsgEditarForest("Creado correctamente")

            })

        ;

        //setShow(false)

    }

    const [validationDateRequired, setValidationDateRequired] = useState(false);
    const [municipioRequired, setMunicipioRequired] = useState(false);
    const updateValidationDateRequired = (e) => {
  
      if(e.target.value === "VALIDATEDANDCERTIFICATED"){
        setMunicipioRequired(true);
      }else{
        setMunicipioRequired(false);
      }
  
      if (e.target.value === "VALIDATED" || e.target.value === "VALIDATEDANDCERTIFICATED") {
        setValidationDateRequired(true)
      } else {
        setValidationDateRequired(false)
      }
      return
    }

    const [forestTypeBBDD, setForestTypeBBDD] = useState({
        forestTypeDataBBDD: [],
        isFetching: false,
      });
    
      useEffect(() => {
        const fetchForestTypeBBDD = async () => {
          try {
            setForestTypeBBDD({ forestTypeDataBBDD: forestTypeBBDD.forestTypeDataBBDD, isFetching: true });
            await axios.get(`${process.env.REACT_APP_FORESTTYPE}`, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${UserService.getToken()}`
              }
            }).then(response => {
              setForestTypeBBDD({ forestTypeDataBBDD: response.data.sort((x, y) => x.commonName.localeCompare(y.commonName)), isFetching: false });
              // setItems(response.data);
            });
          } catch (exception) {
            setForestTypeBBDD({ forestTypeDataBBDD: forestTypeBBDD.forestTypeDataBBDD, isFetching: false });
          }
        };
        fetchForestTypeBBDD();
      }, []);
    
      const typeForest = forestTypeBBDD.forestTypeDataBBDD;

          //municipios para hacer el select
  let forestObject = province;
  const [municipios, setMunicipios] = useState(null);
  const [locations, setLocations] = useState({
    locationsData: [],
    isFetching: false,
  });
  //Fetch locations MP
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        setLocations({ locationsData: locations.locationsData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_MP_LOCATIONS}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setLocations({ locationsData: response.data, isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        setLocations({ locationsData: locations.locationsData, isFetching: false });
      }
    };
    fetchLocations();
  }, []);

  //Objeto para el select de municipio a partir de la provincia del monte
  useEffect(() => {
    if (forestObject && locations.locationsData && locations.locationsData.length > 0) {
      const tempMunicipios = [];
      for (let index = 0; index < locations.locationsData[0].regionList.length; index++) {
        const actual = locations.locationsData[0].regionList[index];
        actual.stateList.forEach(v => {
          if (v.name && v.name.toUpperCase().includes(forestObject.toUpperCase())) {
            tempMunicipios.push(...v.citiesList.sort((x, y) => x.name.localeCompare(y.name)));
          }
        });
      }
      setMunicipios(tempMunicipios);
    }
  }, [forestObject, locations.locationsData]);

  const [forestTypeMP, setForestTypeMP] = useState({
    forestTypeData: [],
    isFetching: false,
  });
  //Fetch forestType MP
  useEffect(() => {
    const fetchForestTypeMP = async () => {
      try {
        setForestType({ forestTypeData: forestTypeMP.forestTypeData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_MP_FORESTTYPES}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setForestTypeMP({ forestTypeData: response.data, isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {

        setForestTypeMP({ forestTypeData: forestTypeMP.forestTypeData, isFetching: false });
      }
    };
    fetchForestTypeMP();
  }, []);

  const upperForest = typeof forestType === 'string' ? forestType.toUpperCase() : null;


  //Estado que guarda el id del forestType de MP según el nombre de la especie del monte
  const [especie, setEspecie] = useState(null);
  useEffect(() => {
    if (forestTypeMP?.forestTypeData?.length > 0) {
      forestTypeMP.forestTypeData.forEach((v) => {
        if (v && (v.commonName.toUpperCase()).includes(upperForest)) {
          setEspecie(v);
        }
      });
    }
  }, [forestTypeMP.forestTypeData, upperForest]);



  const handleDelete = (forestId) => {
    const fetchDelete = async () => {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_DELETE_FOREST}${forestId}`, {}, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          if (response.status == 200) {
            updateTable(forestId);
            setRespuestaDelete("Borrado correctamente.");
          } else {
            setRespuestaDelete("Error al borrar.")
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchDelete();
  }

    return(
        <>
      <Modal show={show} animation={false}>
        {/* <Modal.Header closeButton> */}
        <Modal.Header className="sec-green-background">
          <Modal.Title className="text-white">Alta monte</Modal.Title>
          <div className="flex justify-end">
            <button onClick={() => handleClose()}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className='text-xs' model={forest} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col col="6">
                <FormGroup >
                  <Label for="name">Nombre</Label>
                  <Input className='text-xs' type="text" name="name" id="name" defaultValue={name} />
                </FormGroup>
              </Col>
              <Col col="6">
                <FormGroup >
                  <Label for="owner">Propietario</Label>
                  <Input className='text-xs' type="text" name="owner" id="owner" defaultValue={owner} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col col="4">
                <FormGroup>
                  <Label for="age">Edad</Label>
                  <Input className='text-xs' type="number" name="age" id="age" defaultValue={age} />
                </FormGroup>
              </Col>
              <Col col="4">
                <FormGroup>
                  <Label for="size">Tamaño</Label>
                  <Input className='text-xs' type="text" name="size" id="size" defaultValue={size} />
                </FormGroup>
              </Col>
              <Col col="4">
                <FormGroup>
                  <Label for="marco">Marco</Label>
                  <Input className='text-xs' type="text" name="marco" id="marco" defaultValue={marco} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col col="6">
                <FormGroup>
                  <Label for="cadastre">Catastro</Label>
                  <Input className='text-xs ' type="text" name="cadastre" id="cadastre" defaultValue={cadastre} />
                </FormGroup>
              </Col>
              <Col col="6">
                <FormGroup>
                  <Label for="province">Provincia</Label>
                  <Input className='text-xs' type="text" name="province" id="province" defaultValue={province} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col col="6">
                <FormGroup>
                  <Label for="state">Estado</Label>
                  <Input className='text-xs' type="select" name="state" id="state" defaultValue={forestState} onChange={(e) => updateValidationDateRequired(e)}>
                    <option value={"CREATED"}>Creado</option>
                    <option value="VALIDATED">Validada absorción CO₂</option>
                    <option value={"VALIDATEDANDCERTIFICATED"}>Validado y contrato firmado</option>
                    <option value="ACTIVE" disabled >Activo</option>
                    <option value="DISABLED">Desactivado</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col col="6">
                <FormGroup>
                  <Label for="forestType">Especie predominante</Label>
                  <Input className='text-xs' type="select" name="forestType" id="forestType" defaultValue={forestType} required>
                    <option name="Selecciona" disabled value="">Selecciona una especie válida, la actual no está en nuestra BBDD</option>
                    {(typeForest.length !== 0) &&
                      typeForest.map((x, y) =>
                        <option key={y}>{x.commonName}</option>

                      )}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col col="6">
                <FormGroup>
                  <Label for="municipio">Municipio</Label>
                  {municipios != null ?
                  <Input className="text-xs" type="select" id="municipio" name="municipio" defaultValue={municipio} required={municipioRequired} >
                    <option name="municipio" className='w-full' value="">Selecciona</option>
                    {municipios && (
                      municipios.map((v) => {
                        return (
                          <option key={v.id + "municipio"} name={v.name} className='w-full' value={v.id}>{v.name}</option>
                        );
                      }))}

                  </Input>
                  : "Cargando ..."
                   }
                </FormGroup>
              </Col>
              <Col col="6">
                <FormGroup>
                  <Label>id/url contrato Hubspot</Label>
                  <Input className='text-xs' type="text" name="idContratoHubspot" id="idContratoHubspot" defaultValue={hubSpotContrato} disabled/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col col="6">
                <FormGroup>
                  <Label for="anualCO2">Compensación anual CO<sub>2</sub></Label>
                  <Input className='text-xs' type="text" name="anualCO2" id="anualCO2" defaultValue={anualCO2} />
                </FormGroup>
              </Col>
              <Col col="6">
                <FormGroup>
                  <Label for="disponibleCO2">CO<sub>2</sub> disponible</Label>
                  <Input className='text-xs' type="text" name="disponibleCO2" id="disponibleCO2" defaultValue={availableCO2} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <FormGroup>
                <Label for="gml">Url Gml</Label>
                <Input className='text-xs' type="text" name="urlGml" id="urlGml" defaultValue={urlGml} />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <Label for="hubSpotNegocio">Id de Negocio en Hubspot</Label>
                <Input className='text-xs' type="text" name="hubSpotNegocio" id="hubSpotNegocio" defaultValue={hubSpotNegocio} />
                <Input type="hidden" id="hubSpotContrato" defaultValue={hubSpotContrato}></Input>
                <Input type="hidden" id="cuestionarioEnviado" defaultValue={cuestionarioEnviado}></Input>
              </FormGroup>
            </Row>
            <Row>
              <Col col="6">
                <FormGroup>
                  <Label for="creationdate">Fecha de creación</Label>
                  <Input className='text-xs' type="date" name="creationdate" id="creationdate" defaultValue={creationDate} />
                </FormGroup>
              </Col>
              <Col col="6">
                <FormGroup>
                  <Label for="validationdate">Fecha de validación</Label>
                  <Input className='text-xs' type="date" name="validationdate" id="validationdate" defaultValue={validationDate} required={validationDateRequired} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col col="6">
                <FormGroup>
                  <Label for="fire_insurance">Seguro de incendios</Label>
                  <Input name="fire_insurance" id="fire_insurance" disabled defaultValue={fire_insurance}></Input>
                </FormGroup>
              </Col>
              <Col col="6">
                <FormGroup>
                  <Label for="commision">Comisión</Label>
                  <Input name="commision" id="commision" disabled defaultValue={commision}></Input>
                </FormGroup>
              </Col>
            </Row>

            <Button className=" text-xs btn-sm btn-green text-white ml-3 whitespace-nowrap" type="submit">Actualizar</Button>
            {msgEditarForest !== "" && <div className="mt-3 mb-3">{msgEditarForest}</div>}

          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button className=" text-xs btn-sm btn-cancel text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => handleClose()}>
            Cerrar
          </Button>

        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} animation={false} className="flex text-center">
        <Modal.Header className="sec-green-background">
          <Modal.Title className="text-white">Eliminar monte</Modal.Title>
          <div className="flex justify-end">
            <button onClick={() => handleClose()}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="mt-2"  >
          {respuestaDelete === "" && <p> ¿Estás seguro de que deseas eliminar el monte <strong>{name}</strong>?</p>}
          {respuestaDelete !== "" && <p>{respuestaDelete}</p>}
        </Modal.Body>
        <Modal.Footer >
        </Modal.Footer>
        <div className="flex mb-3 justify-center">
          {respuestaDelete === "" && <Button className=" text-xs btn-sm btn-green text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => handleDelete(forestId)}>
            Eliminar
          </Button>}
          <Button className=" text-xs btn-sm btn-cancel text-white ml-3 whitespace-nowrap" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </div>
      </Modal>
      </>
    )
}