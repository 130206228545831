import React, { useContext, useMemo, useState } from "react";

import format from "date-fns/format";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IdiomContext } from "../../../context/createcontext";
import { formatThousands } from "../utils/Utils";
function CompensationsMarketplce({ transactions }) {
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];

          if (sortConfig.key.includes(".")) {
            let params = sortConfig.key.split(".");
            valueA = a[params[0]]?.[params[1]];
            valueB = b[params[0]]?.[params[1]];
          }

          if (valueA === null || valueA === undefined || valueA === "") {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (valueB === null || valueB === undefined || valueB === "") {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }

          if (!isNaN(valueA) || moment(valueA).isValid()) {
            if (valueA < valueB) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (valueA > valueB) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (
              valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (
              valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }

          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(
    transactions || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <div className="order-details">
      <table className="table-auto w-full divide-y divide-gray-200 table-compensations bg-white">
        <thead className="text-md text-gray-500  border-t border-gray-200">
          <tr>
            <th className="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
              <div className="font-semibold text-left">
                <button
                  type="button"
                  onClick={() => requestSort("id")}
                  className={`${getClassNamesFor("id")} `}
                >
                  #
                </button>
              </div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
              <div className="font-semibold text-center">
                <button
                  type="button"
                  onClick={() => requestSort("creationDate")}
                  className={`${getClassNamesFor("creationDate")} `}
                >
                  {t("dash.compensaciones.fecha")}
                </button>
              </div>
            </th>

            <th className="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
              <div className="font-semibold text-center">
                {t("dash.compensaciones.cantidadco2")}
              </div>
            </th>

            <th className="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
              <div className="font-semibold text-right">
                <button
                  type="button"
                  onClick={() => requestSort("total")}
                  className={`${getClassNamesFor("total")} `}
                >
                  {t("dash.compensaciones.total")}
                  <br />{" "}
                  <span className="text-xs">
                    {" "}
                    {t("dash.compensaciones.siniva")}
                  </span>
                </button>
              </div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
              <div className="font-semibold text-right">
                {t("dash.compensaciones.iva")}
              </div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-2 whitespace-nowrap">
              <div className="font-semibold text-right">
                <button
                  type="button"
                  onClick={() => requestSort("totalVat")}
                  className={`${getClassNamesFor("totalVat")} `}
                >
                  {t("dash.compensaciones.total")}
                  <br />{" "}
                  <span className="text-xs">
                    {" "}
                    {t("dash.compensaciones.coniva")}
                  </span>
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((order) => (
            <>
              <tr>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className={`text-left font-medium `}>{order.id}</div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className={`text-center font-medium `}>
                    {order.creationDate !== undefined
                      ? format(new Date(order.creationDate), "dd/MM/yyyy")
                      : ""}
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className={`text-center font-medium `}>
                    {formatThousands(
                      order.orderLineList
                        .map((orderLine) => orderLine.quantity)
                        .reduce((total, cantidad) => total + cantidad, 0)
                    )}
                    t
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className={`text-right font-medium `}>
                    {formatThousands(order.total)}€
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className={`text-right font-medium `}>
                    {formatThousands(order.totalVat - order.total)}€
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className={`text-right font-medium `}>
                    {formatThousands(order.totalVat)}€
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={2}>
                  <table className="table-auto w-full divide-y divide-gray-200 text-sm">
                    <thead className="text-xs text-gray-500">
                      <tr>
                        <th className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            {t("dash.compensaciones.producto")}
                          </div>
                        </th>
                        <th className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                          <div className="font-semibold text-center">
                            {t("dash.compensaciones.cantidadco2")}
                          </div>
                        </th>
                        <th className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                          <div className="font-semibold text-center">
                            {t("dash.compensaciones.precioud")}
                          </div>
                        </th>
                        <th className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                          <div className="font-semibold text-center">
                            {t("dash.compensaciones.comision")}
                          </div>
                        </th>
                        <th className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                          <div className="font-semibold text-center">
                            {t("dash.compensaciones.totalforestal")}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.orderLineList.map((orderLine) => (
                        <tr key={orderLine.id}>
                          <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                            <div className={`text-left`}>
                              {orderLine.product.name}
                            </div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                            <div className={`text-center`}>
                              {orderLine.quantity}t
                            </div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                            <div className={`text-center`}>
                              {formatThousands(orderLine.unitPrice)}€/t
                            </div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                            <div className={`text-center`}>
                              {formatThousands(orderLine.commision)}%
                            </div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                            <div className={`text-center`}>
                              {formatThousands(orderLine.total)}€
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
                <td colSpan={3}></td>
              </tr>
            </>
          ))}
        </tbody>
        <tr>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className={`text-left font-bold uppercase`}> {t("dash.compensaciones.total")}</div>
          </td>
          <td></td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className={`text-center font-bold`}>
              {formatThousands(
                transactions.reduce((acumulador, objeto) => {
                  return (
                    acumulador +
                    objeto.orderLineList.reduce((suma, orderLine) => {
                      return suma + orderLine.quantity;
                    }, 0)
                  );
                }, 0)
              )}
              t
            </div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className={`text-right font-bold`}>
              {formatThousands(
                transactions.reduce((acumulador, objeto) => {
                  return acumulador + objeto.total;
                }, 0)
              )}{" "}
              €
            </div>
          </td>
          <td></td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className={`text-right font-bold`}>
              {formatThousands(
                transactions.reduce((acumulador, objeto) => {
                  return acumulador + objeto.totalVat;
                }, 0)
              )}{" "}
              €
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default CompensationsMarketplce;
