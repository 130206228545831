import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import Header from '../Header'
import Sidebar from '../Sidebar';
import axios from 'axios';
import { OverlayTrigger } from 'react-bootstrap';
import UserService from '../../../services/UserService';
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Spinner } from 'react-bootstrap';
import { Modal, Button, Col } from "react-bootstrap";
import { Tooltip } from 'react-bootstrap';
import FilterButton from "../../../pages/intranet/utils/DropdownFilter"
import moment from 'moment';
import ContactsComponent from './ContactsComponent';

export default function Contacts() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [sidebarOpen, setSidebarOpen] = useState(false);




  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

       
       <ContactsComponent></ContactsComponent>
      </div>
    </div>

  )

}