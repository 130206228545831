import React, { useState, useEffect, useContext } from 'react';

import Sidebar from '../Sidebar';
import Header from '../Header';
import ProyectosSidebar from './ProyectosSidebar';
import CardMonte from './CardMonte';
import PaginationClassic from '../PaginationClassic';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from 'react-i18next';
import NotFound from '../../../images/undraw-404.svg';
import UserService from '../../../services/UserService';

function Shop2() {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [activeForests, setActiveForests] = useState({
    activeForestsData: [],
    isFetching: false
  });
  
  useEffect(() => {
    const fetchActiveForests = async () => {
      try {
        setActiveForests({ activeForestsData: activeForests.activeForestsData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_FOREST_ACTIVE}`, {
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setActiveForests({ activeForestsData: response.data, isFetching: false });
  
      } catch (exception) {
        console.error(exception);
        setActiveForests({ activeForestsData: activeForests.activeForestsData, isFetching: false });
      }
    };
    fetchActiveForests();
  }, []);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  // CATEGORIES 
  const allForestsActive = activeForests.activeForestsData;
  const activeForestSpecies = allForestsActive.filter(v => v.forestType != null).sort((a, b) => b.creationDate.localeCompare(a.creationDate));

  const speciesUniq = [];
  activeForestSpecies.map((v) => {
    if (speciesUniq.indexOf(v.forestType) === -1) {
      speciesUniq.push(v.forestType)
    }
  });

  const speciesList = [];
  speciesUniq.map((v) => {
    speciesList.push({
      forestType: v,
      check: false
    });
  })

  const [stateCheck, setCheck] = useState([])
  useEffect(() => {
    setCheck([...speciesList]);
  }, []);


  const applySpeciesFilter = (data) => {
    setCheck([...data[0]]);
    setCheckTons([...data[1]]);

    updateTableLines(0, itemPage, data)
    setCurrentPage(0)
  };


  const toneladas = ["0-100", "100-1000", t("proyectos.filtroTons") ];

  const toneldasCheck = [];
  toneladas.map((v) => {
    toneldasCheck.push({
      toneladas: v,
      check: false
    });
  });

  
const [stateCheckTons, setCheckTons] = useState([])
useEffect(() => {
  setCheckTons([...toneldasCheck]);
}, []);



  // Actulizar datos del filtrado y paginacion
  const updateTableLines = (firstIndex, itemPage, data, ) => {
    if (data == null) {
      data = [stateCheck , stateCheckTons];
    }

    let forestType = data[0].filter(c => c.check).map(c => c.forestType);
    let forestTons = data[1].filter(c => c.check).map(c => c.toneladas);
    let filteredList = [...datosApi];

    if (forestType.length > 0) {
      filteredList = filteredList.filter((c) => forestType.includes(c.forestType));
    }

    const minMax = {
      min:[],
      max:[]
    }
    if (forestTons.length > 0) {
      forestTons.map((v)=>{
        switch (v) {
          case "0-100":
            minMax.min.push(0);
            minMax.max.push(100);
            break;
          case "100-1000":
            minMax.min.push(100);
            minMax.max.push(1000);
            break;
          case t("proyectos.filtroTons"):
            minMax.min.push(1000);
            minMax.max.push(100000000);
            break;
        }
    })

    var minTons = Math.min(...minMax.min);
    var maxTons = Math.max(...minMax.max);

    filteredList = filteredList.filter((c) => c.availableCO2>=minTons && c.availableCO2<=maxTons);
    }

    setTotalElement(filteredList.length);
    setLastPage(Math.ceil((filteredList.length) / itemPage) - 1)
    setItems(filteredList.splice(firstIndex, itemPage));
  };


  //PAGINACIÓN
  const [currentpage, setCurrentPage] = useState(0);
  const [datosApi, setdatosApi] = useState(activeForestSpecies)
  const itemPage = process.env.REACT_APP_ITEMS_PER_PAGE;

  const [totalElement, setTotalElement] = useState(activeForestSpecies.length)
  const [lastPage, setLastPage] = useState(Math.ceil((activeForestSpecies.length) / itemPage) - 1)

  const [items, setItems] = useState([...activeForestSpecies].splice(0, itemPage))
  useEffect(() => {
    setdatosApi(activeForestSpecies);
    setTotalElement(activeForestSpecies.length);
    setLastPage(Math.ceil((activeForestSpecies.length) / itemPage) - 1)
    setItems(activeForestSpecies.slice(currentpage * itemPage, itemPage));
  }, [allForestsActive]);


  const nextHandler = () => {
    const nextpage = currentpage + 1;
    const firstIndex = nextpage * itemPage;
    if (firstIndex >= totalElement) return;
    updateTableLines(firstIndex, itemPage)
    setCurrentPage(nextpage)
  }


  const prevHandler = () => {
    const prevPage = currentpage - 1;
    if (prevPage < 0) return;
    const firstIndex = prevPage * itemPage;
    updateTableLines(firstIndex, itemPage)
    setCurrentPage(prevPage)
  }

  const firstHandler = () => {
    updateTableLines(0, itemPage)
    setCurrentPage(0)
  }

  const lastHandler = () => {
    updateTableLines(lastPage * itemPage, itemPage)
    setCurrentPage(lastPage)
  }

  const pointFirst = () => {
    const prevPage = currentpage - 2;
    if (prevPage < 0) return;
    const firstIndex = prevPage * itemPage;
    updateTableLines(firstIndex, itemPage)
    setCurrentPage(prevPage)
  }

  const pointLast = () => {
    const nextpage = currentpage + 2;
    const firstIndex = nextpage * itemPage;
    if (firstIndex >= totalElement) return;
    updateTableLines(firstIndex, itemPage)
    setCurrentPage(nextpage)
  }

  return (

    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden sec-neutro-background">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-5">

              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("proyectos.proyectos")}</h1>

            </div>
            
              <div className='mb-4 py-8 w-full sec-green-background'>
                <section id=''>
                  <h2></h2>
                  {/* <h3 className="ml-4 mr-2 text-2xl md:text-3xl text-gray-800 font-bold">Ya hemos compensado más de <b className='sec-beige-text'>17.000</b> toneladas de <b className='sec-beige-text'>CO2</b>,<br></br> súmate al cambio! </h3> */}
                  <h3 className="ml-4 mr-2 text-2xl md:text-3xl text-gray-800 font-bold">{t("proyectos.banner")}</h3>

                </section>
              </div>
            
              {/* <ProyectosSidebar
                speciesUniq={speciesUniq} //camvbiar a speciesUniq
                stateCheck={speciesList}
                applySpeciesFilter={applySpeciesFilter}
                currentpage={currentpage}
                stateCheckToneladas = {toneldasCheck}
                // applyTonsFilter={applyTonsFilter}
              /> */}

            {/* Page content */}
            <div className="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9">

              <ProyectosSidebar
                speciesUniq={speciesUniq} //camvbiar a speciesUniq
                stateCheck={speciesList}
                applySpeciesFilter={applySpeciesFilter}
                currentpage={currentpage}
                stateCheckToneladas = {toneldasCheck}
                // applyTonsFilter={applyTonsFilter}
              />


            {activeForests.activeForestsData.length === 0 ? <Spinner  animation="border" className='flex justify-center' /> :
              <div className='w-full'>
                {items.length === 0 ? 
                <div className='flex flex-col items-center'>
                  <img alt='notFound' src={NotFound} width="200"></img>
                  <span>{t("proyectos.busquedaFail")}</span>
                </div> 
                : 
                <>
                  <div>
                    <div className="grid grid-cols-12 gap-6">
                      <CardMonte data={items} />
                    </div>
                  </div>
    
                  {/* Pagination */}
                  <div className="mt-6">
                  {activeForestSpecies.length === 0 ?
                  "" :
                    <PaginationClassic 
                      itemPage={itemPage}
                      currentpage={currentpage}
                      items={items}
                      nextHandler={nextHandler}
                      prevHandler={prevHandler}
                      total={activeForestSpecies}
                      firstHandler={firstHandler}
                      lastHandler={lastHandler}
                      lastPage={lastPage}
                      pointFirst={pointFirst}
                      pointLast={pointLast}
                    />
                  }
                  </div>
                </>
                }
              </div>}

            </div>

          </div>
        </main>

      </div>

    </div>
              
  );
}

export default Shop2;