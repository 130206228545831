import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import OriginDestinationInputs from './OriginDestinationInputs';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { formatThousands } from "../utils/Utils";

const GetOriginDestinationDistance = ({sendDataToParent, idInput,origenTerceros}) => {

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const [distance, setDistance] = useState(null);
  const [distanceInputsData, setDistanceInputsData] = useState('');
  const [factor, setFactor] = useState('');

  const [isLoading, setIsLoading] = useState(null);
  const [message, setMessage] = useState('');
  
  function handleDataFromChild(data) {


    setDistanceInputsData({
      originLatLng: data.origin.coordinates.lat + "," + data.origin.coordinates.lng,
      destinationLatLng: data.destination.coordinates.lat + "," + data.destination.coordinates.lng,
      numberOfTrips: data.numberOfTrips,
      roundTrip: data.roundTrip,
      originName: data.origin.name,
      destinationName: data.destination.name

    });


  }

  useEffect(() => {
    let factor = distanceInputsData.numberOfTrips;
    if (distanceInputsData.roundTrip) {
      factor = factor * 2;
    }
    setFactor(factor);

  }, [distanceInputsData])



  useEffect(() => {
    let inputDistanceElement = document.getElementById(idInput);
    let distanceFinal = ((distance * factor) / 1000).toFixed(2);

    if (!Number.isNaN(distanceFinal) && distanceFinal > 0) {
      sendDataToParent({
        distance: distanceFinal,
        ...distanceInputsData
      });
      inputDistanceElement.value = distanceFinal;
    }

  }, [distance, factor])

  useEffect(() => {
    const getDistance = async () => {
      setIsLoading(true);
      setMessage('');
      try {
        const response = await axios.get(`${process.env.REACT_APP_ROUTES_DISTANCE}?origin=${distanceInputsData.originLatLng}&destination=${distanceInputsData.destinationLatLng}`);
        const data = response.data;
       
        if (data.routes && data.routes.length > 0) {
          const distanceText = data.routes[0].legs[0].distance.value;
          setDistance(distanceText);
        } else {
          console.error('Error in API response');

        }
        if (data.status === 'ZERO_RESULTS') {
          setMessage(t("footPrint.errorGoogleDistancia"))
        } else {
          setMessage('')

        }
      } catch (error) {
        console.error('Error making API request:')


      }
      finally {
        setIsLoading(false);
      }
    };

    if(distanceInputsData != ''){
      getDistance();
    }
  }, [distanceInputsData])


  return (
    <div>

      <OriginDestinationInputs origenTerceros={origenTerceros} sendDataToParent={handleDataFromChild} isLoading={isLoading} />

      <div className='text-center mt-4'>
        {message !== '' && <h1>{message}</h1>}
        {distance && message === '' && <><h1>{t("footPrint.distancia")}:</h1>  <b>{formatThousands((distance * factor) / 1000)} km</b></>}
      </div>


    </div>
  );
};

export default GetOriginDestinationDistance;