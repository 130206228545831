import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import UserService from "../../../../services/UserService";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import DecarbonizationChart from "./DecarbonizationChart";
import DecarbonizationScenarioResume from "./DecarbonizationScenarioResume";
import DecarbonizationTable from "./DecarbonizationTable";


export default function DecarbonizationPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const pathParams = useParams();
    const idScenario = pathParams.id

    const navigate = useNavigate();
    const [t, i18n] = useTranslation("global");

    const [resumeScenario, setResumeScenario] = useState({ data: undefined, isFetching: false });
    const [scenarioItems, setScenarioItems] = useState({ data: undefined, isFetching: false });
    const [updatedPercentsValues, setUpdatedPercentsValues] = useState([])
    const [updatedQuestionLine, setUpdatedQuestionLine] = useState([])


    useEffect(() => {
        const fetchCalculationEvolutions = async () => {
            const url = `${process.env.REACT_APP_CH_DECARBONIZATION}/${idScenario}`;

            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });
                const { decarbonizationScenarioItems, decarbonizationScenarioQuestionItemDto, ...resumenScenarioData } = response?.data?.data;
                setResumeScenario({ data: resumenScenarioData, isFetching: true })
                setScenarioItems({ data: decarbonizationScenarioQuestionItemDto, isFetching: true });

            } catch (exception) {
                setResumeScenario({ data: undefined, isFetching: false });
                setScenarioItems({ data: undefined, isFetching: false });
                console.error(exception);
            }
        };

        fetchCalculationEvolutions();
    }, []);


    const updateScenarioItem = (questionId, newItem, oldPercent, oldCost) => {
        let newPercent = newItem?.discountPercentaje || 0;
        if (oldCost !== undefined) {
            newPercent = newItem.reductionCommitments.reduce((sum, x) => sum + (x?.averageImplementationCost || 0), 0);;
        }
        let scope;
        if (newItem) {
            setScenarioItems(prevData => {
                if (!prevData.data) {
                    console.warn("El estado 'data' no está definido todavía.");
                }

                const itemsActualizados = prevData.data.map(item => {
                    if (item.questionId === questionId) {
                        scope = item.scope;
                        return {
                            ...item,
                            decarbonizationScenarioItemOutputDtos: newItem
                        };
                    } else {
                        return item;
                    }
                });

                return {
                    ...prevData,
                    data: itemsActualizados,
                    isFetching: true
                };
            });
        }

        setResumeScenario(prevScenario => ({
            ...prevScenario,
            data: prevScenario.data
                ? {
                    ...prevScenario.data,
                    ...updateDataProperty(prevScenario.data, (oldCost !== undefined ? undefined : scope), newPercent, (oldCost !== undefined ? oldCost : oldPercent), newItem?.questionId)
                }
                : undefined
        }));

    }

    const updateDataProperty = (prevData, scope, newPercent, oldPercent, questionId) => {
        if (scope === 1 || scope === 2) {
            setUpdatedPercentsValues(["currentScopes1and2"])
            return { currentScopes1and2: prevData.currentScopes1and2 + (newPercent - (oldPercent||0)) };
        } else if (scope === 3) {
            setUpdatedPercentsValues(["currentScope3"])
            return { currentScope3: prevData.currentScope3 + (newPercent - (oldPercent||0)) };
        } else {
            setUpdatedPercentsValues(["currentCost"]);
            setUpdatedQuestionLine(questionId)
            return { currentCost: (prevData.currentCost - oldPercent) + (newPercent) };
        }
    };

    const chartsTabs = ["Largo plazo", "Medio plazo", "Corto plazo"];
    const [selectedChart, setSelectedChart] = useState(chartsTabs[0]);
    
    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">
                        <div className="mb-8 flex justify-between">
                            <div>
                                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("decarbonization.title")}</h1>
                            </div>
                        </div>

                        <div className=''>

                            <div className="evolution-years-block">
                                <div className=" evolution-info-card flex ">
                                    <div className="bg-white rounded-sm border border-gray-200 p-4 flex flex-col justify-content-between w-full" >
                                        <DecarbonizationScenarioResume
                                            resume={resumeScenario.data}
                                            updatedValues={updatedPercentsValues} />
                                    </div>
                                </div>
                                <div className="w-full flex items-stretch">
                                    <div className="w-full flex">
                                        <div className="w-full bg-white rounded-sm border border-gray-200 p-4 flex flex-col justify-content-center" >
                                            <h3 className="text-center font-bold mb-2">{t("evolution.infoAlcance")}</h3>
                                            <div className="mb-3">
                                                <ul className="flex justify-center flex-wrap -m-1">
                                                    {chartsTabs.map((tab) => {
                                                        return(
                                                            <li className="m-1">
                                                            <button onClick={() => setSelectedChart(tab)} className={`${selectedChart === tab ? 'sec-green-background text-white' : ' text-gray-500'}
                                    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                                                {tab}
                                                            </button>
                                                        </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                            <DecarbonizationChart
                                                resume={resumeScenario.data}
                                                plazo={selectedChart}
                                            ></DecarbonizationChart>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="w-full bg-white rounded-sm border border-gray-200 p-4 pb-2 flex flex-col justify-content-center" >
                                    <h3 className="text-center font-bold mb-4">{t("evolution.infoActividad")}</h3>
                                    <DecarbonizationTable
                                        items={scenarioItems.data}
                                        resume={resumeScenario.data}
                                        updateScenarioItem={updateScenarioItem}
                                        updatedQuestionLine={updatedQuestionLine}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )

}