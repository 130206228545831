import axios from "axios";
import { BarController, BarElement, CategoryScale, Chart, DatasetController, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';
import { IdiomContext } from "./../../../../context/createcontext";

Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);
export default function EvolutionChart() {
    const [lng, updateLng] = useContext(IdiomContext)
    const canvas = useRef(null);
    const legend = useRef(null);
    const [t, i18n] = useTranslation("global");
    const [selectedChart, setSelectedChart] = useState("Largo plazo");

    const [calculationEvolutions, setCalculationEvolutions] = useState({ labels: undefined, scope1: undefined, scope2: undefined, scope3: undefined, scopeMonthly1: undefined, scopeMonthly2: undefined, scopeMonthly3: undefined, isFetching: false });
    const [calculationEvolutionsMonthly, setCalculationEvolutionsMonthly] = useState({scopeMonthly1: undefined, scopeMonthly2: undefined, scopeMonthly3: undefined, isFetching: false });

    useEffect(() => {
        const fetchCalculationEvolutions = async () => {
            let url = `${process.env.REACT_APP_CH_EVOLUTIONS}`;
            url += `?monthlyView=${(selectedChart == "Corto plazo" && calculationEvolutionsMonthly.scopeMonthly1 == undefined) ? "true": "false"}`;

            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });
                const groupDataByYear = (data) => {
                    return data.reduce((acc, curr) => {
                        const year = curr.year;
                        const totalKgCO2eScope1 = curr.totalKgCO2eScope1 || 0;
                        const totalKgCO2eScope2 = curr.totalKgCO2eScope2 || 0;
                        const totalKgCO2eScope3 = curr.totalKgCO2eScope3 || 0; 

                        if (!acc[year]) {
                            acc[year] = { 
                                totalKgCO2eScope1: 0, 
                                totalKgCO2eScope2: 0, 
                                totalKgCO2eScope3: 0,
                                totalKgCO2eScope1Monthly: Array(12).fill(0),
                                totalKgCO2eScope2Monthly: Array(12).fill(0),
                                totalKgCO2eScope3Monthly: Array(12).fill(0),
                             };
                        }

                        acc[year].totalKgCO2eScope1 += totalKgCO2eScope1;
                        acc[year].totalKgCO2eScope2 += totalKgCO2eScope2;
                        acc[year].totalKgCO2eScope3 += totalKgCO2eScope3;

                        // Sumar los valores mensuales (por posición)
                        const monthsScope1 = curr.totalKgCO2eScope1Monthly || [];
                        const monthsScope2 = curr.totalKgCO2eScope2Monthly || [];
                        const monthsScope3 = curr.totalKgCO2eScope3Monthly || [];

                        monthsScope1.forEach((value, index) => {
                            acc[year].totalKgCO2eScope1Monthly[index] += value || 0;
                        });

                        monthsScope2.forEach((value, index) => {
                            acc[year].totalKgCO2eScope2Monthly[index] += value || 0;
                        });

                        monthsScope3.forEach((value, index) => {
                            acc[year].totalKgCO2eScope3Monthly[index] += value || 0;
                        });

                        return acc;
                    }, {});
                };

                // Procesamos los datos recibidos para agruparlos
                const groupedData = groupDataByYear(response.data.data);

                // Extraemos los valores agrupados para setear el estado
                const years = Object.keys(groupedData);
                const scope1 = years.map(year => groupedData[year].totalKgCO2eScope1);
                const scope2 = years.map(year => groupedData[year].totalKgCO2eScope2);
                const scope3 = years.map(year => groupedData[year].totalKgCO2eScope3);

                if(selectedChart == "Corto plazo" && calculationEvolutionsMonthly.scopeMonthly1 == undefined){

                    const scopeMonthly1 = years.map(year => groupedData[year].totalKgCO2eScope1Monthly).filter(value => value !== undefined);
                    const scopeMonthly2 = years.map(year => groupedData[year].totalKgCO2eScope2Monthly).filter(value => value !== undefined);
                    const scopeMonthly3 = years.map(year => groupedData[year].totalKgCO2eScope3Monthly).filter(value => value !== undefined);
                
                    setCalculationEvolutionsMonthly({scopeMonthly1: scopeMonthly1, scopeMonthly2: scopeMonthly2, scopeMonthly3: scopeMonthly3, isFetching: false })

                }

                setCalculationEvolutions({ labels: years, scope1: scope1, scope2: scope2, scope3: scope3, isFetching: true })
            } catch (exception) {
                setCalculationEvolutions({ id: undefined, isFetching: true })
                console.error(exception);
            }
        };

        if (calculationEvolutions.labels == undefined){
            fetchCalculationEvolutions();
        }

        if(selectedChart == "Corto plazo" && calculationEvolutionsMonthly.scopeMonthly1 == undefined) {
            setCalculationEvolutionsMonthly({scopeMonthly1: undefined, scopeMonthly2: undefined, scopeMonthly3: undefined, isFetching: true });
            fetchCalculationEvolutions();
        }

    }, [selectedChart]);




    useEffect(() => {
        if (!calculationEvolutions?.isFetching) return;

        const dataFormateada = calculationEvolutions?.labels?.map((year, index) => ({
            year: year,
            alcance1: calculationEvolutions.scope1[index],
            alcance2: calculationEvolutions.scope2[index],
            alcance3: calculationEvolutions.scope3[index],
        }));


        const scope1Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope1');
        const scope1LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope1-light');


        const scope2Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope2');
        const scope2LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope2-light');

        const scope3Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3');
        const scope3LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-light');

        let data;
        if (selectedChart == "Corto plazo") {

            if (calculationEvolutionsMonthly.scopeMonthly1 == undefined) return ;

            const years = calculationEvolutions?.labels?.map(year => parseInt(year));
            const firstYear = Math.min(...years);
            const currentYear = new Date().getFullYear();
            const previousYear = currentYear - 1;
        
            // Aaños relevantes
            let relevantYears = [firstYear, previousYear, currentYear];
            if (previousYear == firstYear) {
                relevantYears = [firstYear, currentYear];
            }
        
            // Crear las etiquetas para los meses
            const months =  [
                "Enero", "Febrero", "Marzo", "Abril", 
                "Mayo", "Junio", "Julio", "Agosto", 
                "Septiembre", "Octubre", "Noviembre", "Diciembre"
            ];

            // Filtrar y mapear datos por año
            const monthlyData = relevantYears.map(year => {
                const index = years.indexOf(year);
                return {
                    year,
                    scope1: calculationEvolutionsMonthly.scopeMonthly1[index] || Array(12).fill(0),
                    scope2: calculationEvolutionsMonthly.scopeMonthly2[index] || Array(12).fill(0),
                    scope3: calculationEvolutionsMonthly.scopeMonthly3[index] || Array(12).fill(0),
                };
            });

            const colorScope1 = ['#f28c6a', '#d37855', '#f8a58a']
            const colorScope2 = ['#ffb080', '#e69d73', '#ffccaa']
            const colorScope3 = ['#6699cc', '#4d80b3', '#80add6']

            data = {
                labels: months,
                datasets:[]
            }

            monthlyData.forEach((item, yearIndex) => {
                data.datasets.push({
                    label: `Alcance 1 (${item.year})`,
                    data: item.scope1,
                    backgroundColor: colorScope1[yearIndex],
                    stack: `a${item.year}`
                });
                data.datasets.push({
                    label: `Alcance 2 (${item.year})`,
                    data: item.scope2,
                    backgroundColor: colorScope2[yearIndex],
                    stack: `a${item.year}`,
                });
                data.datasets.push({
                    label: `Alcance 3 (${item.year})`,
                    data: item.scope3,
                    backgroundColor: colorScope3[yearIndex],
                    stack: `b${item.year}`,
                });
            })
            
        
        } else { 

            data = {
                labels: dataFormateada?.map(item => item.year),
                datasets: [
                    {
                        label: t("dash.alcance1"),
                        data: dataFormateada?.map(item => item.alcance1),
                        backgroundColor: scope1Color,
                        hoverBackgroundColor: scope1LightColor,
                        stack: 'a',
                        borderWidth: 1,
                    },
                    {
                        label: t("dash.alcance2"),
                        data: dataFormateada?.map(item => item.alcance2),
                        backgroundColor: scope2Color,
                        hoverBackgroundColor: scope2LightColor,
                        stack: 'a',
                        borderWidth: 1,
                    },
                    {
                        label: t("dash.alcance3"),
                        data: dataFormateada?.map(item => item.alcance3),
                        backgroundColor: scope3Color,
                        hoverBackgroundColor: scope3LightColor,
                        stack: 'b',
                        borderWidth: 1,
                    },
                ],
            };
        }


        const ctx = canvas.current;
        // eslint-disable-next-line no-unused-vars
        switch (lng) {
            case "en":
                moment.locale("en");
                break;
            case "pt":
                moment.locale("pt");
                break;
            case "gl":
                moment.locale("gl");
                break;
            default:
                moment.locale("es");
                break;
        }
        const chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart - Stacked'
                    },

                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const dataset = context.dataset;
                                const valorFormateado = `${formatThousands(context.parsed.y)} kgCO₂e`;
                                return `${dataset.label}: ${valorFormateado}`;
                            },
                        },
                    },

                    legend: {
                        display: true,
                    },
                },
                // maintainAspectRatio: false,
                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                        type: 'category',
                    },
                    y: {
                        stacked: true
                    }
                }
            }

        });

        return () => chart.destroy();
        // eslint-disable-next-line
    }, [lng, calculationEvolutions, calculationEvolutionsMonthly, selectedChart]);


    return (
        <React.Fragment>
            {!calculationEvolutions?.isFetching && <div className="text-center"><Spinner className='' /></div>}
            {!canvas !== null && calculationEvolutions?.isFetching && calculationEvolutions?.labels?.length > 0 && <>
                <h3 className="text-center font-bold mb-2">{t("evolution.infoAlcance")}</h3>
                <div className="mb-3">
                    <ul className="flex justify-center flex-wrap -m-1">
                        <li className="m-1">
                            <button onClick={() => setSelectedChart("Largo plazo")} className={`${selectedChart === "Largo plazo" ? 'sec-green-background text-white' : ' text-gray-500'}
        inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                Largo plazo
                            </button>
                        </li>
                        <li className="m-1">
                            <button onClick={() => setSelectedChart("Corto plazo")} className={`${selectedChart === "Corto plazo" ? 'sec-green-background text-white' : ' text-gray-500'}
        inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                Corto plazo
                            </button>
                        </li>
                    </ul>
                                        
                </div>
                {calculationEvolutionsMonthly?.isFetching && <div className="text-center"><Spinner className='' /></div>}
                <canvas ref={canvas} style={{ maxHeight: "350px" }}></canvas></>}
            {!canvas !== null && calculationEvolutions?.isFetching && calculationEvolutions?.labels?.length === 0 && <p className="text-center">
                Para ver esta gráfica primero es necesario que finalices algún cálculo.</p>}
        </React.Fragment>
    );


}