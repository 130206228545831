
import { default as React, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import PieChart from '../../charts/PieChart';
import { formatThousands } from '../../utils/Utils';
import { tailwindConfig } from '../../utils/Utils';

function FootprintOrganizationsOrgaization({ report }) {
    // cambiar idioma
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [uniqueArray, setUniqueArray] = useState([]);
    const [totalPerOrganization, setTotalPerOrganization] = useState([]);
    useEffect(() => {
        if (report && report.length > 0) {
            const organizationsList = new Set();
            report?.forEach(element => {
                organizationsList.add(element.organization);
            });

            const uniqueOrgaizations = Array.from(organizationsList);
            const totalPerOrganizationItem = [];
            uniqueOrgaizations?.forEach((org) => {
                const emisionSum = report
                    .filter((item) => item.organization === org)
                    .reduce((acc, item) => acc + item.totalKgCO2e, 0);
                    totalPerOrganizationItem.push(emisionSum);
            });
            

            setTotalPerOrganization(totalPerOrganizationItem);

            setUniqueArray(uniqueOrgaizations.map((item, index) =>
                [`${item.name}: ${formatThousands(totalPerOrganizationItem[index])} kgCO₂e`]));
        }
    }, [report])



    const chartData = {
        labels:
            uniqueArray,
        datasets: [
            {
                label: t("footPrint.totalCO2eByOrganization"),
                data: totalPerOrganization,
                backgroundColor: [
                    tailwindConfig().theme.colors.yellow[400],
                    tailwindConfig().theme.colors.indigo[500],
                    tailwindConfig().theme.colors.orange[400],
                    tailwindConfig().theme.colors.gray[500],
                ],
                hoverBackgroundColor: [
                    tailwindConfig().theme.colors.yellow[500],
                    tailwindConfig().theme.colors.indigo[600],
                    tailwindConfig().theme.colors.orange[500],
                    tailwindConfig().theme.colors.gray[600],
                ],
                hoverBorderColor: tailwindConfig().theme.colors.white,
            },
        ],
    };


    return (
        <div id='galcance' className="flex flex-col col-span-full sm:col-span-6 bg-white  rounded-sm border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800">{t("footPrint.totalCO2eByOrganization")}</h2>
            </header>

            {!report &&
                <div className="text-center"><Spinner className='' /></div>}

            {report !== undefined && report.length == 0 &&
                <div className='mt-4 mb-4'>{t("footPrint.noDataToGraphic")}</div>}

            {report !== undefined && report.length > 0 &&
                <PieChart data={chartData} width={389} height={260} unit={" kg"} />
            }
        </div>
    );
}
export default FootprintOrganizationsOrgaization;
