import React, { useState,useContext,useEffect} from 'react';

import Sidebar from '../Sidebar';
import Header from '../Header';
import MapaEspecies from './mapa_especies';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';

function MapaEspecie() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  return (
      <div className=" sec-neutro-background flex h-screen overflow-hidden">

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} className="z-40" />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

              {/* Page header */}
              <div className="mb-8">
                {/* Title */}
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("sidebar.speciesMap")}</h1>
              </div>

              {/* Content */}
              <div className='text-sm'>
                <p>{t("speciesMap.explicacion0")}</p>
                <p className='flex text-justify'>{t("speciesMap.explicacion1")}</p>
              </div>

              <div className="flex flex-col md:flex-row md:-mr-px">
                <MapaEspecies />  
              </div>

            </div>
          </main>

        </div>

      </div>
  );
}

export default MapaEspecie;