import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import Header from '../Header'
import Sidebar from '../Sidebar';
import axios from 'axios';
import UserService from '../../../services/UserService';
import { Spinner } from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import Contacts from './ContactsComponent';
import { useLocation } from 'react-router-dom';
import Banner from '../../../components/utils/Banner';
import format from 'date-fns/format';
import Translations from '../../../services/Translations';


export default function SendForm() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const location = useLocation();
    const pathParams = useParams();
    const [dataChild, setDataChild] = useState('')
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showSend, setShowSend] = useState(false)
    const [loading, setLoading] = useState(false);
    const [deSelectAllContacts, setDeSelectAllContacts] = useState(false);
    const [contacts, setContacts] = useState({
        data: [],
        isFetching: false
    });


    const [msg, setMsg] = useState({
        type: '',
        message: '',
        open: false,
    });
    const idExternalQuest = pathParams.id

    const [emailHistory, setEmailHistory] = useState({
        data: [],
        isFetching: false
    });

    const fetchEmailHistory = async () => {
        setEmailHistory({ data: emailHistory.data, isFetching: true });
        try {

            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/${idExternalQuest}/email-history`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setEmailHistory({ data: response.data, isFetching: false });




        } catch (exception) {
            setEmailHistory({ data: [], isFetching: false });

        }
    };
    const fetchContacts = async () => {
        setContacts({ data: contacts.data, isFetching: true });
        try {

            const response = await axios.get(`${process.env.REACT_APP_CH_CONTACTS}/all?userId=${dataCalculation.userId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setContacts({ data: response.data, isFetching: false });




        } catch (exception) {
            setContacts({ data: [], isFetching: false });

        }
    };


    const [externalQuestionaire, setExternalQuestionaire] = useState({
        data: [],
        isFetching: false
    });

    const fetchExternalQuestionaire = async () => {
        setExternalQuestionaire({ data: externalQuestionaire.data, isFetching: true });
        try {

            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternalQuest}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setExternalQuestionaire({ data: response.data, isFetching: false });




        } catch (exception) {
            setExternalQuestionaire({ data: [], isFetching: false });

        }
    };
    useEffect(() => {

        fetchEmailHistory();
        fetchExternalQuestionaire();
        fetchContacts();
    }, [])
    const dataCalculation = location.state?.data;

    if(dataCalculation == undefined){
        () => window.history.back();
    }


    const handleDataFromChild = (data) => {
        setDataChild(data)
    };

    const handleClose = () => {
        setShowSend(false)
        setMsg(
            {
                type: '',
                message: '',
                open: false,
            })
        setButtonDisabled(false);
    }


    const handleSubmitNew = async (e) => {
        e.preventDefault();
        setLoading(true);
        setButtonDisabled(true);


        const url = `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/${idExternalQuest}/send-email`;
        const data = dataChild

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`

                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            await fetchExternalQuestionaire();

            setMsg({ type: "success", message: t("fpThird.email-ok") });
            setDeSelectAllContacts(true);

        } catch (error) {
            console.error('Error:', error.message);
            setMsg({ type: "error", message: t("fpThird.email-ko") });
        } finally {
            setLoading(false);
            setDeSelectAllContacts(false);

        }

    }

    const dataEmail = externalQuestionaire?.data?.data?.mailShipmentsDto

    function obtenerNombrePorId(id) {
        const contactos = contacts?.data?.data


        const contacto = contactos?.find(contacto => contacto.id === id);


        if (contacto) {

            return contacto.name;
        } else {

            return null;
        }
    }

    const useSortableData = (items, config = null) => {

        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            if (!items || items.length === 0) {
                return [];
            }
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    let valueA = a[sortConfig.key];
                    let valueB = b[sortConfig.key];

                    if (sortConfig.key.includes(".")) {
                        let params = sortConfig.key.split(".");
                        valueA = a[params[0]]?.[params[1]];
                        valueB = b[params[0]]?.[params[1]];
                    }

                    if (valueA === null || valueA === undefined || valueA === "") {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }

                    if (valueB === null || valueB === undefined || valueB === "") {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }

                    if (!isNaN(valueA) || moment(valueA).isValid()) {
                        if (valueA < valueB) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (valueA > valueB) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    } else {
                        if (
                            valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (
                            valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    }

                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(dataEmail);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };




    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <button arial-label="btn-saveFom" className="ml-4 mt-2 text-base font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                        </svg>
                        <span>{t("footPrint.volver")}</span>
                </button>
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">


                    <Modal show={showSend}>

                        <Modal.Header className="sec-green-background">
                            <Modal.Title className="text-white">{t("fpThird.envioForm")}</Modal.Title>
                            <div className="flex justify-end">
                                <button onClick={() => handleClose()}>
                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                </button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            {msg.type !== "" && <Banner type={msg.type} open setOpen={true}>{msg.message}</Banner>}
                            <p>{t("fpThird.enviarEmail")}</p>
                            <div className='flex justify-center mt-4'>


                                <button onClick={() => handleClose()} className='btn-cancel mr-4 rounded-lg px-4 py-2 btn-cancel'>{t("footPrint.cerrarCerrar")}</button>
                                <button className="btn-green mr-4 rounded-lg px-4 py-2" onClick={(e) => handleSubmitNew(e)} type="submit" disabled={buttonDisabled}>

                                    {loading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        t("fpThird.enviar")
                                    )}


                                </button>
                            </div>



                        </Modal.Body>
                    </Modal>

                    {/* <div className="mb-8 mt-4 text-gray-800  flex justify-between flex-wrap"> */}
                    <div className="font-bold text-left mb-4">
                        <span className='text-2xl md:text-3xl '>
                            {t("fpThird.titleEnvioFormulario1") + dataCalculation.orgName + t("fpThird.titleEnvioFormulario2") + dataCalculation.year} 
                        </span>
                        <span className='text-md md:text-lg'> ({Translations.transalteStatusCalculations(dataCalculation.status)})</span>


                    </div>
                    {/* tabla elegir contactos */}

                    <div>
                        <h1 className="text-lg md:text-xl font-bold flex gap-1">  {t("fpThird.contactos")}</h1>
                        <Contacts onDataFromChild={handleDataFromChild} deSelectAll={deSelectAllContacts} userId={dataCalculation.userId}/>
                    </div>


                    {/* Datos para enviar */}
                    <div className=" py-8 w-full max-w-9xl mx-auto">

                        <h1 className="text-lg md:text-xl font-bold flex gap-1">  {t("fpThird.datosSeleccionados")}</h1>

                        <div className="overflow-x-auto w-full flex-row mt-4">
                            < table className="table-striped table-auto w-full bg-white ">
                                <thead className=' text-center text-xl sec-green-background text-white'>
                                    <tr className='py-4'>
                                        <th className=''>{t("fpThird.email")}</th>

                                    </tr>
                                </thead>
                                <tbody className=' text-md'>

                                    {dataChild &&
                                        dataChild.map((contacto) => (
                                            <tr>
                                                <td className='px-4'>{contacto.email}</td>
                                            </tr>))}


                                    {dataChild.length === 0 &&
                                        <tr>
                                            <td className='px-4 text-center'>{t("fpThird.noContactos")}</td>

                                        </tr>
                                    }

                                </tbody>
                            </table>
                            {dataChild.length !== 0 &&


                                <button className="mt-4 btn-green mr-4 rounded-lg px-4 py-2" type="submit" onClick={() => { setShowSend(true) }}>  {t("fpThird.enviar")}</button>
                            }
                        </div>
                    </div>

                    {/* tabla con envios */}


                    <div className=" py-8 w-full max-w-9xl mx-auto">
                        <h1 className="text-lg md:text-xl font-bold flex gap-1">{t("fpThird.envios")}</h1>
                        <div className="overflow-x-auto w-full mt-4">
                            {(externalQuestionaire.isFetching && !externalQuestionaire?.data?.data?.mailShipmentsDto) ?
                                <Spinner></Spinner>
                                :
                                (
                                    <table className="table-striped table-bordered table-auto w-full bg-white ">
                                        <thead className='text-center  text-xl sec-green-background text-white'>
                                            <tr className='py-4'>
                                                <th>
                                                    <button type="button" onClick={() => requestSort('fecha')} className={`${getClassNamesFor('fecha')} text-underline`}>
                                                        {t("fpThird.fecha")} </button></th>
                                                <th>
                                                    <button type="button" onClick={() => requestSort('contact')} className={`${getClassNamesFor('contact')} text-underline`}>
                                                        {t("fpThird.contact")} </button></th>
                                                <th>
                                                    <button type="button" onClick={() => requestSort('vecesAbierto')} className={`${getClassNamesFor('vecesAbierto')} text-underline`}>
                                                        {t("fpThird.vecesAbierto")} </button></th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-center text-md'>
                                            {dataEmail && dataEmail.length > 0 ? (
                                                items.map((x) => (
                                                    <tr key={x.id}>
                                                        <td>{x.creationDate ? format(new Date(x.creationDate), 'dd/MM/yyyy') : ''}</td>
                                                        <td>{obtenerNombrePorId(x.contactId)}</td>
                                                        <td>{x.timesOpened}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td className='' colSpan="3">{t("fpThird.noEnvios")}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                )
                            }
                        </div>
                    </div>


                    {/* tabla con aperturas */}

                    <div className=" py-8 w-full max-w-9xl mx-auto">
                        <h1 className="text-lg md:text-xl font-bold flex gap-1">  {t("fpThird.aperturasTotales")}</h1>
                        <div className="overflow-x-auto w-full mt-4">
                            {(emailHistory.isFetching && !emailHistory.data) ?
                                <Spinner></Spinner>
                                :
                                (
                                    <table className=" table-bordered table-striped table-auto w-full bg-white ">
                                        <thead className='text-center  text-xl sec-green-background text-white'>
                                            <tr className='py-4'>
                                                <th>{t("fpThird.employees")}</th>
                                                <th>{t("fpThird.providers")}</th>
                                                <th>{t("fpThird.suppliers")}</th>
                                                <th>{t("fpThird.qr")}</th>
                                                <th>{t("fpThird.total")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-center text-md'>
                                            {emailHistory?.data?.data ? (
                                                <tr>
                                                    <td>{emailHistory.data.data.employees}</td>
                                                    <td>{emailHistory.data.data.providers}</td>
                                                    <td>{emailHistory.data.data.suppliers}</td>
                                                    <td>{emailHistory.data.data.qr}</td>
                                                    <td>{emailHistory.data.data.totalViews}</td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan="5">
                                                        <Spinner></Spinner>


                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                )
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
