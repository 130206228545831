import React, { useState, useContext, useEffect } from 'react';
import UserService from '../../../services/UserService';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import MPForestListTable from './MPForestListTable';
import { Spinner } from "react-bootstrap";
import axios from 'axios';

function MPForestList({ forestList }) {
  const [loading, setLoading] = useState(true);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [allProducts, setAllProducts] = useState();

  const [montes, setMontes] = useState({
    montesData: [],
    isFetching: false,
  });

  useEffect(() => {
    if (forestList != undefined) {
      setAllProducts(forestList)
      setLoading(false)
    }
  }, [forestList])


  return (
    <div className="grow sec-neutro-background">

      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">{t("marketplace.forestListMP_title")}</h2>
        {allProducts !== undefined && allProducts.length > 0 && <p className="text-justify text-sm">{t("marketplace.forestListMP_description")}</p>}

        {allProducts === undefined ?
          loading ? <span>  <Spinner animation="border" className='flex justify-center' /></span> : <p> {t("sidebar.nomontes")}.</p>
          : <>
            {allProducts.length === undefined  ||  allProducts.length === 0  && <>{t("marketplace.forestListMP_vacio")}</>}
            {allProducts.length > 0 && <MPForestListTable allProducts={allProducts} />}
          </>
        }

      </div>
    </div>
  );
}

export default MPForestList;