import React from 'react';
import { Modal, ModalHeader, ModalTitle, ModalBody } from 'react-bootstrap';
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

function ColumnMapping({ view, userColumns, predefinedColumns, onMappingSubmit, userFirstLineData, inputOptions, predefinedColumnsRequired }) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  let columnMappings = predefinedColumns.map(column => ({
    name: column,
    index: null
  }))

  const handleMappingChange = (e, index) => {
    const selectedColumn = e.target.value;
    if (selectedColumn != "") {
      columnMappings[index] = JSON.parse(selectedColumn);
    }
  }

  const handleSubmit = () => {
    onMappingSubmit(columnMappings);
  }

  const hanldeReset = () => {
    onMappingSubmit([]);
  }

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <table className="bg-white text-sm table-auto self-center">
          {/* <thead>
            <tr>
              <td className='sec-grey-text'>{t("footPrint.cabecerasOk")}:</td>
              <td className='sec-grey-text'>{t("footPrint.tusCabeceras")}:</td>
            </tr>
          </thead> */}
          <tbody>
            {predefinedColumns.map((col, index) => (
              <tr key={index}>
                <td className='pt-4 pr-4'>
                  <label className=''>{col}:
                    {predefinedColumnsRequired.includes(col) && <span className="text-red-500">*</span>}
                  </label>
                </td>
                <td className='pt-4 pr-4'>
                  <select className='text-sm' style={{ maxWidth: '300px' }} onChange={(e) => handleMappingChange(e, index)} required={predefinedColumnsRequired.includes(col)}>
                    <option value="">{t("footPrint.cabeceraAdecuada")}</option>
                    {userColumns.map((userCol, i) => (
                      <option key={i} value={JSON.stringify({ name: col, index: i })} style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {userCol}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='flex '>
          <button className='mt-4 m-4 btn-sm btn-banner-o w-1/4' type="submit">{t("fpThird.continuar")}</button>
        </div>
      </form>
    </div>

  );
}

export default ColumnMapping;