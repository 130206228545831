

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import { formatThousands } from '../../utils/Utils';


function TopEmissions({
  topFactors
}) {

  const [topOneEmissions, setTopOneEmissions] = useState(0);
  useEffect(() => {
    if (topFactors !== undefined) {
      if (topFactors.length > 0) {
        setTopOneEmissions(topFactors?.reduce((max, obj) => (obj.kgCO2e > max ? obj.kgCO2e : max), topFactors[0].kgCO2e))
      }
    }
  }, [topFactors]);


  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">{t('footPrint.topEfactor')}</h2>
      </header>
      {topFactors == undefined &&
        <div className="text-center"><Spinner className='' /></div>}

      {topFactors !== undefined && topFactors.length == 0 &&
        <div className='mt-4 mb-4'>{t("footPrint.noDataToGraphic")}</div>}

      {topFactors !== undefined && topFactors.length > 0 && <div className="grow p-3">
        <div className="flex flex-col h-full">
          {/* Card content */}
          <div className="grow">
            <ul className="flex justify-between text-xs  text-gray-400 font-semibold px-2 space-x-2">
              <li>{t("footPrint.factor")}</li>
              <li className='flex'>
                {/* <span>{t("footPrint.cantidad")}</span> */}
                <span>KgCO<sub>2</sub>e</span>
              </li>
            </ul>

            <ul className="space-y-1 text-sm text-gray-800 mt-3 mb-4" >
              {Array.isArray(topFactors) && (
                topFactors?.map((x, y) =>
                  <li key={y} className="relative px-2 py-1">
                    <div className="absolute inset-0 bg-indigo-100" aria-hidden="true" style={{ width: `${x.kgCO2e * 100 / topOneEmissions}%`, backgroundColor: '#e0f2fe', maxWidth: '90%' }}></div>
                    <div className="relative flex justify-between space-x-2">
                      <div className='flex gap-3'>
                        <span className='font-semibold'>{x.name}</span>
                        <span className='italic'>
                          {window.innerWidth > 500 ?
                            t("footPrint.alcance")
                            :
                            t("footPrint.alc")
                          }
                          {x.scope}
                        </span>
                      </div>
                      <div className="font-medium flex gap-4 justify-end text-end" style={{ minWidth: '15%' }}>
                        {/* <span className='w-1/2'>{x.quantity ? formatThousands(x.quantity) : ''}</span> */}
                        <span className='w-1/2'> {typeof x.kgCO2e === 'number' ? formatThousands(x.kgCO2e) : 'N/A'}</span>
                      </div>
                    </div>
                  </li>
                ))

              }
            </ul>
          </div>
        </div>
      </div>
      }
    </div>
  );
}

export default TopEmissions;

