import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IdiomContext } from "../../../context/createcontext";
import UserService from "../../../services/UserService";
import Header from "../Header";
import Sidebar from "../Sidebar";
import CompensationsMarketplce from "./CompensationsMarketplace";

function CompensationsMarketplacePage() {
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [compensations, setCompensations] = useState({
    data: [],
    isFetching: false,
  });

  useEffect(() => {
    const fetchTransactionsYearsSummary = async () => {
      try {
        setCompensations({
          data: [],
          isFetching: true,
        });
        await axios
          .get(`${process.env.REACT_APP_MP_USER_ORDERS}?orderStatus=FINALIZED`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          })
          .then((response) => {
            let transactions = response.data
              .filter((v) => v.orderStatus === "FINALIZED")
              .sort((a, b) => {
                if (!a.updatedDate && !b.updatedDate) {
                  return 0;
                }
                if (!a.updatedDate) {
                  return 1;
                }
                if (!b.updatedDate) {
                  return -1;
                }
                return b.updatedDate.localeCompare(a.updatedDate);
              });
            setCompensations({
              data: response.data,
              isFetching: false,
            });
          });
      } catch (exception) {
        setCompensations({
          data: compensations.data,
          isFetching: false,
        });
      }
    };
    fetchTransactionsYearsSummary();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-2 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  {t("sidebar.transacciones")}
                </h1>
              </div>
            </div>

            {/* Table */}
            {compensations.isFetching && (
              <div className="text-center">
                <Spinner animation="border"  />
                <div>    {t("dash.compensaciones.obtener")}</div>
              </div>
            )}

            {!compensations.isFetching && compensations.data.length > 0 && (
              <CompensationsMarketplce
                transactions={compensations.data}
              ></CompensationsMarketplce>
            )}

            {!compensations.isFetching && !compensations.data.length > 0 && (
              <div className="text-center">
                <p className="text-lg mb-4 font-bold">
                  {t("dash.compensaciones.sindatos.titulo")}
                </p>
                <div className="my-4">
                  <p>{t("dash.compensaciones.sindatos.texto1")} </p>
                  <p>{t("dash.compensaciones.sindatos.texto2")}</p>
                </div>
                <a
                  target="_blank"
                  href="https://market.airco2.earth/"
                  className="btn btn-green"
                >
                  {t("dash.compensaciones.sindatos.boton")}
                </a>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
export default CompensationsMarketplacePage;
