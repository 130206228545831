import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Banner from '../../../components/utils/Banner';
import { IdiomContext } from "../../../context/createcontext";
import UserService from '../../../services/UserService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ImagesTable({img, actulizarAtributosImg,origin}) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [modalDelete, setModalDelete] = useState(false);
    const [imageObjDelete, setImageObjDelete] = useState();
    const [formMontesMessage, setFormMontesMessage] = useState({
      type: '',
      message: ''
    });
    
  const [imageDeleteResponse,setImageDeleteResponse] = useState({
    imageDeleteResponseData: [],
    isFetching: false
  });
    const [images, setImages] = useState(img)

    useEffect(()=>{
        setImages(img)
    }, [img])

    const handlerUpdateAtributos = (v) => {
        actulizarAtributosImg(v)
    }

    useEffect(()=>{
        handlerUpdateAtributos(images)
    }, [images])

    const handlePrincipalBtn = (imageObj, index) =>{
        let arrayImagesToModifyCopy = [...images];
        for (let i = 0; i < arrayImagesToModifyCopy.length; i++) {
          let actual = arrayImagesToModifyCopy[i];
          if (actual.principal== true ){
            actual.principal=!actual.principal;
          }
        }
        arrayImagesToModifyCopy[index]={
          "id": imageObj.id,
          "active": true,
          "path": imageObj.path,
          "principal": !imageObj.principal,
          "satelital": imageObj.satelital
        }
        setImages(arrayImagesToModifyCopy)
        
      }
      
      const handleVisibleBtn = (imageObj, index) =>{
        let arrayImagesToModifyCopy = [...images];
        if (imageObj.principal == false){
        arrayImagesToModifyCopy[index]={
          "id": imageObj.id,
          "path": imageObj.path,
          "principal": imageObj.principal,
          "active": !imageObj.active,
          "satelital": imageObj.satelital
        }
      }
        setImages(arrayImagesToModifyCopy)
       
      }

      
      const handleSatelitalBtn = (imageObj, index) =>{
        let arrayImagesToModifyCopy = [...images];
        arrayImagesToModifyCopy[index]={
          "id": imageObj.id,
          "path": imageObj.path,
          "principal": imageObj.principal,
          "active": imageObj.active,
          "satelital": !imageObj.satelital
        }
      
        setImages(arrayImagesToModifyCopy)
       
      }

      
      const handleDelete = (e , imageObjDeleteIndex) =>{
        e.preventDefault();
        setModalDelete(false);
        setFormMontesMessage({ ...formMontesMessage, type: "warning", message: t("marketplace.imgMP_deleting") });
        document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        let newImagesArray =[...images];
        let removedObject = newImagesArray.splice(imageObjDeleteIndex,1);
       

        const fetchDeleteImage = async () => {
          try {
            setImageDeleteResponse({ imageDeleteResponse: imageDeleteResponse.imageDeleteResponseData, isFetching: true });
            const response = await axios.delete(origin === 'provider' ? `${process.env.REACT_APP_MP_PRIVATE_PROVIDER}/images`: 
            `${process.env.REACT_APP_MP_PRIVATE_PRODUCT}/images`, {
              data: removedObject,
              headers: {
                Authorization: `Bearer ${UserService.getToken()}`
              }
            }).then(response => {
              if (response.status === 200) {
                setFormMontesMessage({ ...formMontesMessage, type: "success", message: t("marketplace.imgMP_deleteOK") });
                document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
                setImages(newImagesArray)
              } else {
                setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("marketplace.imgMP_deleteKO")  })
                document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
              }
              setImageDeleteResponse({ imageDeleteResponseData: response.data, isFetching: false });
            })
          } catch (exception) {
            console.error(exception);
            setImageDeleteResponse({ imageDeleteResponse: imageDeleteResponse.imageDeleteResponseData, isFetching: false });
            setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("marketplace.imgMP_deleteKO")  })
                document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
          }
        };
        fetchDeleteImage();
      
     
      }
      


    return(
      <>
        <div className='flex justify-center'>
          {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>} 
        </div>  
      <table className='table-auto w-full divide-y divide-gray-200 border-t '>

        <Modal show={modalDelete}>
              <Modal.Header className='sec-green-background text-white text-lg'><b>{t("marketplace.imgMP_delete")}</b></Modal.Header>
              <Modal.Body className='text-left'>
                <p>{t("marketplace.imgMP_deleteModal")}</p>
                <button></button>
              </Modal.Body>
              <div className='flex justify-center pb-2'>
              <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={(e)=>handleDelete(e, imageObjDelete)}>
                {t("formMonte.alert_aceptar")}
                </Button>
                <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={()=>setModalDelete(false)}>
                {t("formMonte.cancelar")}
                </Button>
              </div> 
            </Modal>

        <tbody>           
        {images.map((v, index)=>{
            return(
              <tr key={index} className="border-t">
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className='flex flex-col sm:flex-row items-center  justify-left'>
                    <img width ="70" src={v.path} />
                    <a href={v.path} target="_blank" className="  ml-2 text-xs text-center">{t("marketplace.imgMP_fullImage")}</a>
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className='flex flex-col sm:flex-row space-x-4 items-center justify-end'>
                    <div className='flex flex-col'>
                      {v.principal ? "" :
                      <button className='text-xs mb-1 border rounded-full px-2' onClick={()=>handleVisibleBtn(v,index)}>{v.active ? t("marketplace.imgMP_invisible") : t("marketplace.imgMP_visible")}</button>
            }
                      {v.principal ? "" :
                      <button className='text-xs mb-1 border rounded-full px-2' onClick={()=>handlePrincipalBtn(v,index)}>{t("marketplace.imgMP_principal")}</button>
                      }

                      <button className='text-xs mb-1 border rounded-full px-2' onClick={()=>handleSatelitalBtn(v,index)}>{v.satelital ? t("marketplace.imgMP_nosatelital") : t("marketplace.imgMP_satelital")}</button>
                      
                    </div>
                    <div className='flex flex-row'>
                      <div>
                      {v.active===true ? 
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-check" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <circle cx="12" cy="12" r="2" />
                          <path d="M12 19c-4 0 -7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7c-.42 .736 -.858 1.414 -1.311 2.033" />
                          <path d="M15 19l2 2l4 -4" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <line x1="3" y1="3" x2="21" y2="21" />
                          <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                          <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                        </svg>
                      }
                    </div>
                    <div>
                    {v.satelital===true ? 

                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-satellite" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M3.707 6.293l2.586 -2.586a1 1 0 0 1 1.414 0l5.586 5.586a1 1 0 0 1 0 1.414l-2.586 2.586a1 1 0 0 1 -1.414 0l-5.586 -5.586a1 1 0 0 1 0 -1.414z" />
                        <path d="M6 10l-3 3l3 3l3 -3" />
                        <path d="M10 6l3 -3l3 3l-3 3" />
                        <path d="M12 12l1.5 1.5" />
                        <path d="M14.5 17a2.5 2.5 0 0 0 2.5 -2.5" />
                        <path d="M15 21a6 6 0 0 0 6 -6" />
                      </svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-satellite-off" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M7.707 3.707l5.586 5.586m-1.293 2.707l-1.293 1.293a1 1 0 0 1 -1.414 0l-5.586 -5.586a1 1 0 0 1 0 -1.414l1.293 -1.293" />
                        <path d="M6 10l-3 3l3 3l3 -3" />
                        <path d="M10 6l3 -3l3 3l-3 3" />
                        <path d="M12 12l1.5 1.5" />
                        <path d="M14.5 17c.69 0 1.316 -.28 1.769 -.733" />
                        <path d="M15 21c1.654 0 3.151 -.67 4.237 -1.752m1.507 -2.507a6 6 0 0 0 .256 -1.741" />
                        <path d="M3 3l18 18" />
                      </svg>
                    }
                    </div>
                    <div>
                        <svg id={index + "svg"} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-star" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2"  stroke="var(--airco2-green-color)" fill={v.principal==true? "var(--airco2-green-color)": "none"} strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                        </svg>
                    </div>
                    <div className='ml-2' >
                      <button onClick={()=>{setImageObjDelete(index);setModalDelete(true)}}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2" stroke="#ff0000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="4" y1="7" x2="20" y2="7" />
                        <line x1="10" y1="11" x2="10" y2="17" />
                        <line x1="14" y1="11" x2="14" y2="17" />
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg>
                      </button>
                    </div>
                   </div> 
                  </div>
                </td>
              </tr>
            )
          })}
          </tbody>
          </table>
        </>
    );
}