import { React, useState, useContext, useEffect } from 'react'
import Header from '../Header'
import Sidebar from "../Sidebar";
import ArbolMontes from './ArbolMontes';
import naranja from '../../../images/naranja.png'
import amarillo from '../../../images/amarillo.png'
import verde from '../../../images/verde.png'
import UserService from '../../../services/UserService';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import axios from "axios";
import ProgressBM from '../charts/ProgressBM';
import BannerMonte from './BannerMonte';
import { Spinner } from 'react-bootstrap';
import GraficaDineroGenerado from '../graficas/GraficaDineroGenerado';

export default function Dashboardmontes() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  const [loading, setLoading] = useState(true);
  const [montes, setMontes] = useState({
    montesData: [],
    isFetching: false,
  });



  useEffect(() => {
    const fetchMontesData = async () => {
      try {
        setMontes({ montesData: montes.montesData, isFetching: false });
        const response = await axios.get(`${process.env.REACT_APP_FOREST}active/${UserService.getUserId()}`);
        setMontes({ montesData: response.data, isFetching: true });
      } catch (exception) {
        console.error(exception);
        setMontes({ montesData: montes.montesData, isFetching: false });
      } finally {
        setLoading(false)
      }

    };
    fetchMontesData();
  }, []);


  const montesdata = montes.montesData;
  const montesbystate = montesdata.sort((a, b) => b.creationDate.localeCompare(a.creationDate));;

  return (

    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">



        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden sec-neutro-background">

          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}></Header>

          {/* <main className="sec-neutro-background" style={{ height: '100vh' }}>
            <div className="sec-neutro-background" style={{ display: 'flex', justifyContent: 'center', paddingTop: '10vh' }}>

              <img src="https://i.ibb.co/2dPVCFw/Footer-Mail.png"></img>

            </div>
            <div className="sec-neutro-background" style={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="secondary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true" />
                  <p style={{ marginLeft: '5px' }}>{t("dashmonte.mantenimiento")}</p>
                </Button>

            </div>


          </main> */}


          <main>
            <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">



              <div className="mb-1 sm:mb-0">

                <div className=" w-full max-w-9xl mx-auto">
                  <BannerMonte />
                </div>

              </div>



              <div className='border-1 border-gray-200'>
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold p-4">{t("dash.availableCredits")} </h1>

                {montesbystate.length > 0 ? <div className=''>

                  <ul className='flex justify-center' style={{ gap: '40px', fontSize: '11px' }}>
                    <li className='flex flex-column items-center'>
                      <img src={amarillo} width='30px' alt='color amarillo leyenda'></img>
                      <p>-30%</p>
                    </li>

                    <li className='flex flex-column items-center'>
                      <img src={naranja} width='30px' alt='color naranja leyenda'></img>
                      <p>30-70%</p>
                    </li>

                    <li className='flex flex-column items-center'>
                      <img src={verde} width='30px' alt='color verde leyenda'></img>
                      <p>+70%</p>
                    </li>

                  </ul>
                </div> : <></>}

                <div className="container-arbolMontes " >
                  {montesbystate == undefined ? <Spinner animation="border" variant="light" className='flex justify-center' /> : <>



                    {montesbystate.length === 0 ? <>
                      {montes.isFetching ? <>
                        <div className="sec-neutro-background text-center  " >
                          <img src="https://i.ibb.co/2dPVCFw/Footer-Mail.png"></img>
                          <p style={{ display: 'flex', justifyContent: 'center' }}>{t("dash.novalidateforest")} </p>
                        </div> </> : <Spinner animation="border" variant="light" className='flex justify-center' />} </> :


                      montesbystate.map((v, i) => {
                        return (

                          <div key={"montes_" + i} className='arbolYprogress transform hover:scale-105 transition duration-700 ease-out '>
                            <ArbolMontes key={"arbol_" + i} age={v.age} anualCO2={v.anualCO2} availableCO2={v.availableCO2} cadastre={v.cadastre}
                              creationDate={v.creationDate} forestType={v.forestType} marco={v.marco} id={v.id} name={v.name} owner={v.owner}
                              size={v.size} state={v.state} anualEarings={v.anualEarings}
                            />



                            <ProgressBM key={"progress_" + i} age={v.age} anualCO2={v.anualCO2} availableCO2={v.availableCO2} cadastre={v.cadastre}
                              creationDate={v.creationDate} forestType={v.forestType} marco={v.marco} id={v.id} name={v.name} owner={v.owner}
                              size={v.size} state={v.state}
                            />
                          </div>


                        )
                      }

                      )



                    }
                  </>}

                </div>



              </div>

              <div className='mt-4 mb-4'>
                <GraficaDineroGenerado></GraficaDineroGenerado>

              </div>


            </div>



          </main>
        </div>

      </div >
    </div >




  )
}
