
import React, { useContext,useState,} from 'react';

import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import Header from '../Header';
import Sidebar from '../Sidebar';
import ContentFormExtra from './ContentFormExtra'

export default function FormInfoExtra() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
   

    return (
      
          
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("formExtra.informacion")}</h1>
            </div>
              {/* Content */}
              <div className="shadow-lg rounded-sm mb-8">
                <div className="flex flex-col md:flex-row md:-mr-px">
                 <ContentFormExtra></ContentFormExtra>
                </div>
              </div>

            </div>
          </main>

        </div>

      </div>
            


           

     
    )
}
