import React from "react";
import { useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import ForestListTableItemLine from "../forestales/ForestListTableItemLine";
import { formatDate } from "../utils/Utils";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function ForestTable ({ items, forestUsers, action, userObject }) {


    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const toggleDescription = (id) => {
        setDescriptionOpen((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const renderTooltipEditar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Editar Monte
        </Tooltip>
    );
    
    const renderTooltipBorrar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Eliminar Monte
        </Tooltip>
    );
    
    const renderTooltipGml = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Descargar Gml
        </Tooltip>
    );

    const statusColor = (props) => {
        switch (props) {
          case 'CREATED':
            return 'bg-gray-100 text-gray-500';
          case 'VALIDATED':
            return 'bg-gray-100 text-yellow-600';
          case 'VALIDATEDANDCERTIFICATED':
            return 'bg-yellow-500 text-grey-100';
          case 'ACTIVE':
            return 'bg-green-100 text-green-600';
          case 'DISABLED':
            return 'bg-red-100 text-red-600';
        }
    };
    

    const statusName = (props) => {
        switch (props) {
          case 'CREATED':
            return t("forestList.sCreated");
          case 'VALIDATED':
            return t("forestList.sValidated");
          case 'VALIDATEDANDCERTIFICATED':
            return t("forestList.sValidatedAndCertificated");
          case 'ACTIVE':
            return t("forestList.sActive");
          case 'DISABLED':
            return t("forestList.sDisabled");
        }
    };

    
    //Calcula nº total de ha de montes multiples
    const totalHa = (sizeMontePadre, childs) => {
        let ha = sizeMontePadre;
            childs.map((v) => {
            ha += v.size;
        })
        if (ha !== null) {
            return ha.toFixed(2);
        } else {
            eturn;
        }
    }
  
    const iconStyle = {
        width: '24px',
        height: '24px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)', 
    }

    
    const getForestIdDelete = (idForest) => {
        action({user: userObject, monte: {id: idForest}, action: "delete" })
    }

    const getUserObjectByUserId = (userId) => {
        let filteredArray = forestUsers.filter(user => user.id == userId);
        if (filteredArray.length > 0) {
          return filteredArray[0];
        } else {
          console.error('Usuario no encontrado');
          return null;
        }
      }

    return(
        <tbody>
            {items.map((monte, i) =>{
                return (
                    <>
                        <tr key={i} id={"line-" + monte.id}>
                            <td className='text-right pr-5 font-italic flex gap-1 justify-between'>
                                {monte.childList != undefined && monte.childList.length > 0 ?
                                    <div className=" flex ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="28" height="28" viewBox="0 0 24 24" stroke="#80817f" fill="none" >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                                            <line x1="15" y1="21" x2="15" y2="18" />
                                            <path d="M8 13l-2 -2" />
                                            <path d="M8 12l2 -2" />
                                            <path d="M8 21v-13" />
                                            <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                                        </svg>
                                        <span className="ml-2">{monte.name}</span>
                                    </div>
                                    :
                                    <div className=" flex ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-tree" width="28" height="28" viewBox="0 0 24 24" stroke="#80817f" fill="none" >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 13l-2 -2" />
                                            <path d="M12 12l2 -2" />
                                            <path d="M12 21v-13" />
                                            <path d="M9.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                                        </svg>
                                        <span className="ml-2">{monte.name}</span>
                                    </div>
                                }
                            </td>
                            {forestUsers && 
                              <td className={`text-center font-italic`}>
                                <div className="flex flex-col">
                                  <span>{monte.owner}</span>
                                  {getUserObjectByUserId(monte.userId) && 
                                  <span>{getUserObjectByUserId(monte.userId).email}</span>}
                                </div>
                              </td>
                              }
                            <td className={`text-center font-italic`} >
                                <div className={`text-center rounded-full py-0.5 px-2 ${statusColor(monte.state)}`}
                                  style={monte.state == 'VALIDATED' ? { backgroundColor: 'rgb(254 249 195)' } : {}}>
                                  {statusName(monte.state)}
                                </div>
                            </td>
                            <td className={`text-center font-italic`} >{monte.activationDate && formatDate(monte.activationDate)}</td>
                            <td className={`text-center font-italic`} >{monte.province}</td>
                            <td className={`text-center font-italic `} >{monte.childList == undefined || monte.childList.length == 0 ? monte.size : totalHa(monte.size, monte.childList)}</td>
                            <td className={`text-center font-italic`} >{monte.cadastre}</td>
                            <td className={`text-center font-italic`} >
                                {monte.childList == undefined || monte.childList.length == 0 ? monte.forestType :
                                    <div className=" flex justify-center text-sm"><span className="font-medium">Ver todo</span>
                                        <button className={`text-gray-400 hover:text-gray-500 transform ${descriptionOpen[monte.id] && 'rotate-180'}`}
                                        aria-expanded={descriptionOpen[monte.id]}
                                        onClick={() => toggleDescription(monte.id)}
                                        aria-controls={`allParcelsFrom-${monte.id}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.25" stroke="#80817f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <polyline points="6 9 12 15 18 9" />
                                        </svg>
                                        </button>
                                    </div>
                                }
                            </td>
                            <td className={`text-center font-italic`} >{monte.anualCO2}</td>
                            <td className={`px-2 space-x-4 first:pl-5 last:pr-5 py-3`}>
                                <div style={{ display: 'flex', position: 'relative', width:'100px'}}>
                                {(monte.urlGml !== null && monte.urlGml !== "") &&
                                  <div style={{...iconStyle, left:'15px'}}>
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltipGml}
                                    >
                                      <a aria-label='redirige a descargar gml' href={monte.urlGml} target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-down" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="#80817f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                          <path d="M12 18.5l-3 -1.5l-6 3v-13l6 -3l6 3l6 -3v8.5" />
                                          <path d="M9 4v13" />
                                          <path d="M15 7v8" />
                                          <path d="M19 16v6" />
                                          <path d="M22 19l-3 3l-3 -3" />
                                        </svg>
                                      </a>
                                    </OverlayTrigger>
                                  </div>
                                }

                                <div style={{...iconStyle, left:'45px'}}>
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipEditar}
                                  >
                                    <button onClick={() => action({user: forestUsers == null ? userObject : getUserObjectByUserId(monte.userId), monte: monte, action: "edit"})} className="active" aria-pressed="true">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="#80817f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                        <path d="M16 5l3 3" />
                                      </svg>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                                {monte.state == "CREATED" ?
                                  <div style={{...iconStyle, left:'75px'}}>
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltipBorrar}
                                    >
                                      <button onClick={() => action({user: forestUsers == null ? userObject : getUserObjectByUserId(monte.userId), monte: monte, action: "delete" })} className="active" aria-pressed="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="red" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                          <path d="M4 7l16 0" />
                                          <path d="M10 11l0 6" />
                                          <path d="M14 11l0 6" />
                                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                        </svg>
                                      </button>
                                    </ OverlayTrigger>
                                  </div>
                                  : ""
                                }
                                </div>
                              </td>
                        </tr>
                        <tr id={`allParcelsFrom-${monte.id}`} role="region" className={`${!descriptionOpen[monte.id] && 'hidden'} w-full`}>
                            <td></td>
                            <td colSpan="3" className="px-2 first:pl-5 last:pr-5 py-3">
                            {monte.childList != undefined && monte.childList.length > 0 ?
                                <table className="table-auto w-full divide-y divide-gray-200 text-sm">
                                    <thead className='text-xs text-gray-500 border-t'>
                                        <tr>
                                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("forestList.catastro")}</th>
                                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("forestList.especie")}</th>
                                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("forestList.hectareas")}</th>
                                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("forestList.años")}</th>
                                        <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-sm'>
                                        <tr>
                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="text-right">{monte.cadastre}</div>
                                        </td>
                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="text-center">{monte.forestType}</div>
                                        </td>
                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="text-center">{monte.size}</div>
                                        </td>
                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="text-center">{monte.age}</div>
                                        </td>
                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div className="text-center"></div>
                                        </td>
                                        </tr>
                                        {monte.childList.map(parcela => {
                                        return <ForestListTableItemLine props={parcela} key={"line" + parcela.id} admin="admin" getForestIdDelete={getForestIdDelete } ></ForestListTableItemLine>
                                        })}
                                    </tbody>
                                </table> : ""}
                            </td>
                            <td colSpan="2" />
                      </tr>
                    </>
                )
            })}
        </tbody>
    )
}