import React, { useState, useEffect,useContext } from 'react';
import format from 'date-fns/format';

import Sidebar from '../Sidebar';
import Header from '../Header';
import SettingsSidebar from './SettingsSidebar';
import mano from './../../../../src/images/mano.png';
import manobajo from './../../../../src/images/manobajo.png';
import { Link } from 'react-router-dom';
import UserService from '../../../services/UserService';
import Spinner from 'react-bootstrap/Spinner';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';

function PlansConfirmationSubscription() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  const url = window.location.href


  const word = 'session_id'

  const [paymentExists, setPaymentExists] = useState(true);

  const payOk = url.includes(word) ? true : false

  const param = url.split("?")[1]

  const [planObject, setPlanObject] = useState({});





  useEffect(() => {

    payOk ?
    
      fetch(`${process.env.REACT_APP_PAYMENTS_SUBSCRIBE_RESPONSE}${param}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }

        }).then(j => j.json())
        .then(data => {

      
          if (data.planName != undefined) {
            setPlanObject(data)

          } else {
            setPaymentExists(false);
          }


        }


        )
      :
      setPaymentExists(false);

  }, [])


  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("sidebar.account")}</h1>
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <SettingsSidebar />
                <div className="sec-neutro-background grow">
                  <div className="max-w-md mx-auto p-6 space-y-6">

                    {payOk && paymentExists ? <div className='text-center'><img alt='mano ok' src={mano}></img></div> : <div><img alt='mano ko' className='mx-auto' src={manobajo}></img></div>}

                    <div><p className=' font-bold text-center' style={{ fontSize: '1.5rem', color: '#56596e' }}>
                      {payOk && paymentExists ?
                        <>{t("planes.suscripcion")}</>
                        : <> {t("planes.suscripcionerror")}</>}
                    </p> </div>

                    {Object.keys(planObject).length == 0 ?
                      <> {paymentExists ?
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          role="status"
                          aria-hidden="true" /> : ""}
                      </>
                      :
                      <>
                        <table className='table-auto w-full border-slate-200'>
                          <tr>
                            <td className='font-bold'>{t("planes.plancontratado")}</td>
                            <td className='text-right'>{planObject.planName} </td>
                          </tr>
                          <tr>
                            <td className='font-bold'>{t("planes.pprecio")}</td>
                            <td className='text-right'>{planObject.price.toString().replace(".", ",")} €</td>
                          </tr>
                          <tr>
                            <td className='font-bold'>{t("planes.fecharenovacion")}</td>
                            <td className='text-right'>{format(new Date(planObject.dateEnd), 'dd/MM/yyyy')}  </td>
                          </tr>
                        </table></>}
                    <div className='text-center'>
                      <Link className='btn   sec-green-background  text-decoration-none' to="/company/plans"><p  >{t("account.volver")}</p></Link>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

export default PlansConfirmationSubscription;