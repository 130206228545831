import { useState } from "react";
import { useTranslation } from "react-i18next";
import UserService from "../../../services/UserService";
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';


export default function BannerSolicitarInformacion() {
    const [t, i18n] = useTranslation("global");
    const [sendAcess, setSendAcess] = useState(false)
    const [loading, setLoading] = useState(false);


    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const handleEmailAccess = async () => {
        setLoading(true);
        try {
            const response = await fetch(` ${process.env.REACT_APP_CH_INFORMATION}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
            });

            if (response.status === 200) {
                const access = await response.json();
                setSendAcess(true)
            }
        } catch (error) {
            console.error('Error al enviar el email:', error);
        } finally {
            setLoading(false);
        }
    };

    return <div className="mt-4 text-center sec-green-background p-4 text-white">
        <Modal show={showModal} animation={false} data-aos="fade-up" >
            <Modal.Header className='sec-green-background'>
                <Modal.Title>{t("footPrint.info.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!sendAcess && <div className='p-4'>
                    {t("footPrint.info.text")}
                </div>}

                {sendAcess &&
                    <>{t("footPrint.planes.encontacto")}</>
                }

            </Modal.Body>
            <Modal.Footer>
                <div className='flex space-x-4 justify-center mt-4'>
                    {!sendAcess && <button onClick={() => handleEmailAccess()} className={`sec-green-background rounded-lg p-2 ${loading && "disabled"}`}>
                        {loading ? <Spinner variant="light" animation="border" /> : t("footPrint.solicitarInfo")}
                    </button>}
                    <button onClick={() => handleCloseModal()} className='btn-cancel rounded-lg p-2'>{t("footPrint.cerrarCerrar")}</button>
                </div>
            </Modal.Footer>

        </Modal>
        <div className="text-2xl font-bold mb-2">{t("footPrint.planes.dudas")}</div>
        <p className="mb-4">{t("footPrint.planes.infodudas")}</p>
        <div>
            <button
                className={`btn-sm btn-banner-o rounded-lg text-md font-bold`}
                onClick={() => handleShowModal()}>
                {loading ? (
                    <div className="spinner-border" role="status"></div>)
                    : (<p>{t("footPrint.solicitarInfo")}</p>)
                }
            </button>
        </div>
    </div>
} 