import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { IdiomContext } from "../../../context/createcontext";
import DownloadForm from "./DownloadForm";

export default function Download({ handleUpdateName }) {
    const pathParams = useParams();
    const downloadslug = pathParams.download;

    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");

    const [download, setDownload] = useState({ data: undefined, isFetching: false });

    useEffect(() => {
        const fetchTerm = async () => {
            const url = `${process.env.REACT_APP_PUBLIC_DOWNLOAD}/${downloadslug}?lng=${lng}`
            try {
                setDownload({ data: download.data, isFetching: true });
                const response = await axios.get(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

                setDownload({ data: response.data.data, isFetching: false });
                handleUpdateName(response.data.data.title)
            } catch (exception) {
                setDownload({ data: [], isFetching: false });
                console.error('Error al realizar la solicitud:', exception);
            }
        };

        fetchTerm();
    }, [lng]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<div>
        <section className="relative">
            <div className="absolute inset-0 rounded-bl-[100px] bg-gray-50 pointer-events-none -z-10" aria-hidden="true" />
            <div className="flex items-center h-full header-glossary-top overflow-hidden">
                {!download.data && download.isFetching &&
                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6 text-center md:text-left flex flex-col items-center" >
                        <Spinner animation="border" variant="dark"></Spinner>
                        {t("glossary.charging")}</div>
                }
                {download.data && !download.isFetching &&
                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6 text-center md:text-left flex flex-col items-center">
                        <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5 mt-5 flex flex-col md:flex-row items-center">
                            <div className="md:w-1/2 mb-8 md:mb-0">
                                <h1 className="h1 md:text-5xl font-bold mb-4">
                                    {download.data.title}
                                </h1>
                                <div className="text-xs text-center" data-aos="fade-up" data-aos-delay="100">
                                    <HashLink
                                        aria-label="apartado resources"
                                        className="text-airco2-principal flex items-center justify-center"
                                        to={`/resources/downloads`}
                                        smooth={true}
                                        offset={50}
                                        duration={500}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="20" height="20" strokeWidth="1">
                                            <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1"></path>
                                        </svg>
                                        <span className="ml-2">{t("downloads.return")}</span>
                                    </HashLink>
                                </div>
                            </div>
                            <div className="md:w-1/2 pl-2">
                                <img
                                    src={download.data.imageUrl}
                                    alt={download.data.title}
                                    className="rounded-lg shadow-lg w-full h-auto"
                                    style={{ width: '500px' }}
                                />
                                <div className="block md:hidden mt-4">
                                    <a href="#form"  className="btn sec-green-background text-white whitespace-nowrap w-full">
                                        {t("downloads.goToForm")}
                                        </a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </section>

        {download.data && !download.isFetching &&
            <div className="max-w-6xl mx-auto px-4 sm:px-6 m-4" data-aos="fade-down" data-aos-delay="200">
                <div className="flex flex-col md:flex-row gap-8">
                    <div className="width-66percent resources-style">
                        <p dangerouslySetInnerHTML={{ __html: download.data?.description }} />
                    </div>
                    <div className="width-33percent client-actions-sticky" >
                        <div className="border rounded-lg p-6 bg-white shadow-md relative glossary-scroll" id="form" >
                            <div className='bg-white w-full px-1 mb-2 text-xs' >
                                <h1>{download.data?.typeName}</h1>
                            </div>
                            <h2 className="text-2xl font-bold mb-4">{t(download.data?.title)}</h2>
                            <div>
                                <DownloadForm
                                    downloadSlug={downloadslug}
                                    lng={lng}
                                    dowloadType={download.data?.typeName}
                                ></DownloadForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
    )

}