import React, { useState, useEffect,useContext } from 'react';
import ForestListTableItem from './MPForestListTableItem';
import { IdiomContext } from '../../../context/createcontext';
import { useTranslation } from 'react-i18next';


function ForestListTable({
   allProducts
}) {

 const [lng, updateLng] = useContext(IdiomContext)
 const [t, i18n] = useTranslation("global");

  return (
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full divide-y divide-gray-200">
            {/* Table header */}
            <thead className="text-xs  text-gray-500  border-t border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.nombre")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-right">{t("marketplace.forestListMP_refCat")}</div>
                </th> 
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-right">{t("marketplace.forestListMP_price")}</div>
                </th> 
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.especie")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.estado")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-right">{t("marketplace.forestListMP_stock")}</div>
                </th>    
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-right">{t("marketplace.forestListMP_anualStock")}</div>
                </th>  
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("marketplace.forestListMP_location")}</div>
                </th> 
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.fechaCrea")}</div>
                </th> 
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("marketplace.forestListMP_actions")}</div>
                </th> 
              </tr>
            </thead>
            {/* Table body */}
            {
              allProducts.map(monte => {

                return (
                  <ForestListTableItem
                    key={monte.id}
                    id={monte.id}
                    name={monte.name}
                    price={monte.price}
                    forestType={monte.forestType}
                    size={monte.size}
                    age={monte.age}
                    validated={monte.validated}
                    active={monte.active}
                    updatedDate={monte.updatedDate}
                    cadastre={monte.cadastre}
                    stockCredits={monte.stockCredits}
                    totalCredits={monte.totalCredits}
                    productImageDtoList={monte.productImageDtoList}
                    city={monte.city}
                    state={monte.state}
                    allData={monte}
                  />
                )
              })
            }
          </table>
         

        </div>
      </div>
    // </div>
  );
}

export default ForestListTable;