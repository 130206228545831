import React from 'react'
import HeroHome from './HeroHome_2'
import Stats from './Stats'
import Bloglist from './BlogListtidy'
import News from './News'
import Process from './Process'
import Newsletterf from './Newsletter_forestal'
import FeaturesHomef from './FeaturesHome_forestal'
import BannerMetodologia from './BannerMetodologia'

export default function Forestal() {
  
  return (
    <div id='forestal' className="flex flex-col min-h-screen overflow-hidden sec-green-background">
         <main className="grow">
        <HeroHome></HeroHome>
        <Stats></Stats>
        <Bloglist></Bloglist>
        {/* <BannerMetodologia ></BannerMetodologia> */}
        <FeaturesHomef></FeaturesHomef>
        <News></News>
        <Process></Process>
        <Newsletterf></Newsletterf>
      
    

       
       


        </main>
    </div>
  )
}
