
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import UserService from "../../../../../services/UserService";
import { Modal, ModalHeader, ModalBody, ModalTitle, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IdiomContext } from "../../../../../context/createcontext";
import Banner from "../../../../../components/utils/Banner";

export default function ModalDocumentationDocumentaion({ modalDocumentation, setModalDocumentation }) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [documentationOriginal, setDocumentationOriginal] = useState({ data: undefined, isFetching: false })
    const [documentation, setDocumentation] = useState({ data: undefined })


    const getStatusDocumentationText = (props) => {
        switch (props) {
            case "INCOMPLETE":
                return "Incompleto";
            case "IN_PROGRESS":
                return "En curso";
            case "IN_REVIEW":
                return "En revisión";
            case "COMPLETED":
                return "Completado";
            case "WITH_ERRORS":
                return "Revisar errores";
        }
    }

    const documentationnStates = ["INCOMPLETE", "IN_PROGRESS", "IN_REVIEW", "COMPLETED", "WITH_ERRORS"];

    useEffect(() => {
        const fetchCertificationDocumentation = async () => {
            try {
                setDocumentationOriginal({ data: documentation.data, isFetching: true });
                const response = await axios.get(
                    `${process.env.REACT_APP_CH_CERTIFICATION}/${modalDocumentation?.certification?.id}/documentation`,
                    {
                        headers: {
                            // "Access-Control-Allow-Origin": "*",
                            //   Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${UserService.getToken()}`,
                        },
                    }
                );

                setDocumentation(response.data.data);
                setDocumentationOriginal({ data: response.data, isFetching: false });

            } catch (exception) {
                console.error(exception);
                setDocumentationOriginal({ data: documentation.data, isFetching: false });
            }
        };

        if (modalDocumentation?.certification?.id != undefined) {
            fetchCertificationDocumentation();
        }

    }, [modalDocumentation.certification]);



    const [reuquestedUrl, setRequestedUrl] = useState({
        data: [],
        isFetching: false
    });

    const [loadingItems, setLoadingItems] = useState([]);
    
    const handleOpenFile = (props) => {
        if (props.fileUrl.includes("https://")) {
            window.open(props.fileUrl, '_blank')
        } else {
            getDocumentationUrl(props.id)
        }
    }

    const getDocumentationUrl = async (id) => {
        try {
            setLoadingItems((prevState) => [...prevState, id]);
            setRequestedUrl({ data: reuquestedUrl.data, isFetching: true });

            const response = await axios.get(`${process.env.REACT_APP_CH_CERTIFICATION}/miteco/${modalDocumentation?.certification?.id}/documentation/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            let objeto = response.data;
            setRequestedUrl({ data: objeto, isFetching: false });
            window.open(objeto.data, '_blank')

        } catch (exception) {
            console.error(exception);
            setRequestedUrl({ data: [], isFetching: false });
        } finally {
            setLoadingItems((prevState) => prevState.filter(x => x !== id));
        }
    };


    const [deletingDocumentation, setDeletingDocumentation] = useState({ view: false, document: null, spinner: false });

    const [message, setMessage] = useState({
        type: "",
        message: "",
        open: false,
    });

    const handleDeleteFile = (props) => {
        setDeletingDocumentation({ view: true, document: props, spinner: false })
    }

    const handleCloseModal = () => {
        setDeletingDocumentation({ view: false, document: null, spinner: false })
        setMessage({
            type: "",
            message: "",
            open: false,
        });
    };

    const fetchDeleteDocumentation = async () => {
        try {
            setDeletingDocumentation({ view: true, document: deletingDocumentation.document, spinner: true })

            const response = await axios.delete(`${process.env.REACT_APP_CH_CERTIFICATION}/miteco/${modalDocumentation?.certification?.id}/documentation/${deletingDocumentation.document.certificationMitecoId}?idCertificationDocumentation=${deletingDocumentation.document.id}`, {
                headers: {
                    'Accept': 'application/json',
                    //   'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            setDocumentation((prevState) => prevState.filter(doc => doc.id !== deletingDocumentation.document.id));
            setMessage({
                ...message,
                type: "success",
                message: t("account.actualizacion-ok"),
            });

        } catch (error) {
            console.error(error);
            setMessage({
                ...message,
                type: "error",
                message: t("account.actualizacion-ko"),
            });
        } finally {
            setDeletingDocumentation({ view: true, document: deletingDocumentation.document, spinner: false })
        }
    }



    const [updateDocumentation, setUpdateDocumentation] = useState({ id: null, status: null, error: null });
    const [updatingDocumentation, setUpdatingDocumentation] = useState(false);

    const fetchUpdateDocumentation = async (document) => {

        try {
            setUpdatingDocumentation(true);
            let data = {
                id: document.id,
                certificationMitecoId: document.certificationMitecoId,
                documentationCertificationStatus: updateDocumentation.status,
                errorMessage: updateDocumentation.error != null ? updateDocumentation.error : null
            };

            const response = await axios.patch(`${process.env.REACT_APP_CH_CERTIFICATION}/miteco/${modalDocumentation?.certification?.id}/documentation`, data, {
                headers: {
                    'Accept': 'application/json',
                    //   'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            let documentationWithoutUpdatedDocument = documentation.filter(doc => doc.id !== updateDocumentation.id);
            setDocumentation([...documentationWithoutUpdatedDocument, response.data.data])

        } catch (error) {
            console.error(error);
        } finally {
            setUpdateDocumentation({ id: null, status: null, error: null });
            setUpdatingDocumentation(false);

        }
    }


    return (
        <>
            <Modal show={deletingDocumentation.view} className="xl-secondary-modal" animation={false} data-aos="fade-up" backdropClassName="xl-secondary-modal-backshadow">
                <div className="flex justify-end">
                    <button type="button" onClick={() => handleCloseModal()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M18 6l-12 12" />
                            <path d="M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <ModalBody>
                    {message.type !== "" && (
                        <Banner className={`mb-2`} type={message.type} open setOpen={true}>
                            {message.message}
                        </Banner>
                    )}
                    <p className="text-center mb-4">Estás seguro de que quieres eliminar {deletingDocumentation?.document?.fileUrl == undefined ? "esta respuesta" : "este dcoumento"}?</p>
                    <div className="text-sm">
                        <div>Tipo de documentación: {deletingDocumentation?.document?.certificationMitecoName}</div>
                        {deletingDocumentation?.document?.fileUrl == undefined ?
                            <span>Respuesta: {deletingDocumentation?.document?.answer}</span>
                            :
                            <span>Nombre: {deletingDocumentation?.document?.fileName}</span>
                        }
                    </div>
                    <div className="flex items-center justify-center gap-4 mt-4">
                        <button type="button" onClick={() => handleCloseModal()} className="btn-sm btn-cancel text-white text-sm">Cerrar</button>
                        {message.type == "" &&
                            <button type="button" disabled={deletingDocumentation.spinner} onClick={() => fetchDeleteDocumentation()} className="btn-sm btn-green text-white text-sm">
                                {deletingDocumentation.spinner ? <Spinner animation="border" size="sm" variant="light" /> : "Eliminar"}
                            </button>
                        }
                    </div>
                </ModalBody>
            </Modal>


            <Modal show={modalDocumentation.view} className='xl-modal' animation={false} data-aos="fade-up" fullscreen backdropClassName="xl-modal-backshadow">
                <ModalHeader className='xl-modal-header sec-neutro-background sec-grey-text text-lg flex justify-between '>
                    <ModalTitle className=''>Documentación recopilada de la certificación {modalDocumentation?.certification?.id + " - " + modalDocumentation?.certification?.organizationName + " " + modalDocumentation?.certification?.calculationYear + " " + modalDocumentation?.certification?.certificationType}</ModalTitle>
                    <button onClick={() => { setModalDocumentation({ view: false, certification: null }); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M18 6l-12 12" />
                            <path d="M6 6l12 12" />
                        </svg>
                    </button>
                </ModalHeader>
                <ModalBody className='xl-modal-body'>

                    {documentationOriginal.isFetching ?
                        <div className="flex justify-center">
                            <Spinner animation="border" variant="dark" className="m-4" />
                        </div> :
                        (documentation != undefined && documentation?.length > 0 ?
                            <div className="mt-4 mb-4">

                                <div className="table-responsive">
                                    <table className=" text-sm table-auto w-full divide-y divide-gray-200  mb-4">
                                        <thead className="text-center" style={{ backgroundColor: "var(--airco2-green-color" }}>
                                            <th>Id</th>
                                            <th>Tipo de documentación</th>
                                            <th>Nomnbre documento</th>
                                            <th>Respuesta</th>
                                            <th className="text-center">Estadao</th>
                                            <th className="text-center">Acciones</th>
                                        </thead>
                                        <tbody>
                                            {documentation.map((x, i) => {
                                                return (
                                                    <tr key={`documentRow${x.id}`} className={`sec-neutro-background ${updateDocumentation.id == x.id && 'border bg-white'}`} >
                                                        <td>{x?.id}</td>
                                                        <td>{x?.certificationMitecoName}</td>
                                                        <td>{x?.fileName}</td>
                                                        <td>{x?.answer}</td>
                                                        <td>
                                                            {updateDocumentation?.id == x.id ?
                                                                <div className=" gap-2 ">
                                                                    <div className="flex flex-col mb-2 text-xs">
                                                                        <label>Selecciona el estado:</label>
                                                                        <select id="documentationState" name="documentationState" defaultValue={updateDocumentation.status} className="text-sm" type="text" onChange={(e) => setUpdateDocumentation({ id: updateDocumentation.id, status: e.target.value, error: updateDocumentation.error })}>
                                                                            {documentationnStates.map((state, i) => {
                                                                                return (<option key={`status${i}`} name={state} className='w-full text-sm' value={state}>{getStatusDocumentationText(state)}</option>)
                                                                            })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {updateDocumentation.status == "WITH_ERRORS" &&
                                                                        <div className="flex flex-col mb-2 text-xs">
                                                                            <label className="">Indica el error si es necesario:</label>
                                                                            <input id="errorMessageInput" defaultValue={x?.errorMessage != undefined ? x.errorMessage : ""} name="errorMessageInput" type="text" className="text-sm" onBlur={(e) => setUpdateDocumentation({ id: updateDocumentation.id, status: updateDocumentation.status, error: e.target.value })}></input>
                                                                        </div>
                                                                    }

                                                                    <div className="flex justify-center items-center gap-2">
                                                                        <button className="btn-sm btn-cancel text-xs text-white" onClick={() => setUpdateDocumentation({ id: null, status: null, error: null })}>Cancelar</button>
                                                                        <button className="btn-sm btn-green text-xs text-white" disabled={updatingDocumentation} onClick={() => fetchUpdateDocumentation(x)}>
                                                                            {updatingDocumentation ? <Spinner animation="border" size="sm" variant="light" /> : "Guardar"}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="flex justify-center items-center gap-2">
                                                                    <span>{getStatusDocumentationText(x?.documentationCertificationStatus)}</span>
                                                                    <button type="button" onClick={() => setUpdateDocumentation({ id: x.id, status: x.documentationCertificationStatus, error: null })}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit-circle" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M12 15l8.385 -8.415a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3z" />
                                                                            <path d="M16 5l3 3" />
                                                                            <path d="M9 7.07a7 7 0 0 0 1 13.93a7 7 0 0 0 6.929 -6" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="flex justify-center items-center gap-2">
                                                                {x?.fileUrl != undefined &&
                                                                    <button type="button" onClick={() => handleOpenFile(x)} disabled={loadingItems?.includes(x.id)} >
                                                                        {loadingItems?.includes(x.id) ?
                                                                            <Spinner animation="border" size="sm" />
                                                                            :
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-files" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                                                                                <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                                                                                <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                                                                            </svg>}
                                                                    </button>
                                                                }
                                                                <button type="button" onClick={() => handleDeleteFile(x)} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M4 7l16 0" />
                                                                        <path d="M10 11l0 6" />
                                                                        <path d="M14 11l0 6" />
                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div >
                            :
                            <div className="my-4 footprint-seccion-respuestas">
                                <p className="text-sm font-bold">{t("footPrint.noDatos")}</p>
                            </div>
                        )
                    }


                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className=" text-xs btn-sm btn-cancel" onClick={() => {
                        setModalDocumentation({ view: false, certification: null });
                    }}> {t("footPrint.cerrarCerrar")}</button>
                </div>
            </Modal >
        </>
    )
}