import React from 'react'
import miteco from '../../../images/logo-miteco.webp'
import ghg from '../../../images/logo-ghg.webp'
import iso from '../../../images/logo-iso.webp'
import { useContext } from "react";
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import tuv from '../../../images/tuv-circle.webp'

export default function BannerCertificaciones() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
 
    return (
        <section className='max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5 mt-5 '>
            
            <div className="banner-container" data-aos="fade-up">
                <div className="banner-content">
                    <div className="text-section">
                        <div className="labelCert font-bold h3 text-airco2-principal">  {t("newHome.certificaciones")}</div>
                        <div className="main-text text-lg ">
                            {t("newHome.comprometidos")} <span className="font-bold">  {t("newHome.estandares")}</span>   {t("newHome.medicion")}
                        </div>
                        <div className="sub-text text-md text-airco2-general-grey">
                            {t("newHome.internacionales")}
                        </div>
                    </div>
                    <div className="logo-section flex flex-wrap items-center">

                        <img src={ghg} alt="GHG Protocol" className="logo mt-1 mb-1" />
                        <img src={miteco} alt="Miteco" className="logo mt-1 mb-1" />
                        <img src={iso} alt="ISO 14064" className="logo mt-1 mb-1" />
                        <img src={tuv} alt="TÜV Rheinland" className="logo mt-1 mb-1" />
                    </div>
                </div>
            </div>
        </section>
    );
};

