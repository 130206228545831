
import { default as React, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import PieChart from '../../charts/PieChart';
import { formatThousands } from '../../utils/Utils';
import { tailwindConfig } from '../../utils/Utils';

function FootprintOrganizationBuildings({ report }) {
    // cambiar idioma
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const sedesConEmisiones = new Set();
    report?.forEach(element => {
        sedesConEmisiones.add(element.organizationBuilding);
    });
    const uniqueSedeArray = Array.from(sedesConEmisiones);


    const totalPerOrganizationBuilding = [];
    uniqueSedeArray?.forEach((sede) => {
        const emisionSum = report
            .filter((item) => item.organizationBuilding === sede)
            .reduce((acc, item) => acc + item.totalKgCO2e, 0);
        totalPerOrganizationBuilding.push(emisionSum);
    });




    const combinedArray = uniqueSedeArray.map((item, index) => [` ${formatThousands(totalPerOrganizationBuilding[index])} ${t("footPrint.sede")} ${item}`]);


    const chartData = {
        labels:
            combinedArray,
        datasets: [
            {
                label: t("footPrint.totalBySede"),
                data: totalPerOrganizationBuilding,
                backgroundColor: [
                    tailwindConfig().theme.colors.yellow[400],
                    tailwindConfig().theme.colors.indigo[500],
                    tailwindConfig().theme.colors.orange[400],
                    tailwindConfig().theme.colors.gray[500],
                ],
                hoverBackgroundColor: [
                    tailwindConfig().theme.colors.yellow[500],
                    tailwindConfig().theme.colors.indigo[600],
                    tailwindConfig().theme.colors.orange[500],
                    tailwindConfig().theme.colors.gray[600],
                ],
                hoverBorderColor: tailwindConfig().theme.colors.white,
            },
        ],
    };


    return (
        <div id='galcance' className="flex flex-col col-span-full sm:col-span-6 bg-white  rounded-sm border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800">{t("footPrint.totalBySede")}</h2>
            </header>

            {!report &&
                <div className="text-center"><Spinner className='' /></div>}

            {report !== undefined && report.length == 0 &&
                <div className='mt-4 mb-4'>{t("footPrint.noDataToGraphic")}</div>}

            {report !== undefined && report.length > 0 &&
                <PieChart data={chartData} width={389} height={260} unit={" kg"} />
            }
        </div>
    );
}
export default FootprintOrganizationBuildings;
