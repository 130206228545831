import parse from "html-react-parser";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatThousands } from './../utils/Utils';


function GetTransactions02({ columns, fileData, coinscrapExcelEments, updateStep, updateSelectedColumns }) {
  const [t, i18n] = useTranslation("global");

  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    coinscrapExcelEments.filter(x => !x.required).map(x => {
      setSelectedColumns([...selectedColumns, { type: x.key, columnIndex: "-1", columnName: "" }]);
    });

  }, []);

  const handleChange = (e) => {

    let key = e.target.getAttribute("data-key");
    let type = e.target.getAttribute("data-type");

    let columnIndex = Number(e.target.value);
    let columnName = e.nativeEvent.target[columnIndex + 1].text; //+1 por el option disable

    if (!validateDataType(type, Number(e.target.value))) {
      alert(t("exceltransactions.columnContentTypeError") + " '" + type + "'");
      e.currentTarget.selectedIndex = 0;
      return;
    }

    // colorSelectedColums(columnIndex);
    let insert = false;
    const newState = selectedColumns.map(obj => {
      if (obj.type === key) {
        insert = true;
        return { ...obj, columnIndex: columnIndex, columnName: columnName };
      }
      return obj;
    });

    if (insert) {
      setSelectedColumns(newState);
    } else {
      setSelectedColumns([...selectedColumns, { type: key, columnIndex: columnIndex, columnName: columnName }]);
    }
  }

  const validateDataType = (type, position) => {

    const columnData = fileData[1][position];
    switch (type) {
      case "text":
        return isNaN(columnData);

      case "number":
        return !isNaN(columnData);

      case "date":
        var date = moment(columnData, ["MM-DD-YYYY", "YYYY-MM-DD"]);
        return date.isValid();

      case "":
        return true;
    }
  }




  const colorSelectedColums = (status) => {
    let color = ""
    selectedColumns.map(u => {
      if (u.columnIndex == status) {
        color = "sec-green-background text-white"
      }
    });
    return color;

  };


  const labelExample = (key) => {
    const res = selectedColumns.filter(m => m.type === key);
    if (res.length > 0) {
      return isNaN(fileData[1][res[0]["columnIndex"]]) ? fileData[1][res[0]["columnIndex"]] : formatThousands(fileData[1][res[0]["columnIndex"]]);
    }
  }


  useEffect(() => {
    updateSelectedColumns(selectedColumns)
  }, [selectedColumns])


  return (

    <main >
      <div className="min-h-screen h-full flex flex-col after:flex-1">
        <div className=" py-8">
          <div className="max-w-6xl mx-auto">

            <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("exceltransactions.title03")}  ✨</h1>
            <h3 className="text-gray-800 mb-12">   {t("exceltransactions.subtitle03")}      </h3>
            {/* Form */}
            <form>
              <div className=''> {/*//className="flex-outer max-w-xl mx-auto " */}
                <div className=''>


                </div>
                <div className="excel-select">
                  <label className='  font-weight-bold uppercase excel-header'>{t("exceltransactions.headerConcept")}</label>
                  <p className=' font-weight-bold  uppercase  excel-header text-center'> {t("exceltransactions.headerColumn")}</p>
                  <p className=' font-weight-bold  uppercase excel-header '> {t("exceltransactions.headerData")} </p>
                  {coinscrapExcelEments.map((e, i) => {
                    return (<>
                      <p className=" text-left font-semibold "><span className='align-center'>{t("exceltransactions." + e.key)} {e.required && <span className="text-red">*</span>}</span>  </p>
                      <select className='' data-key={e.key} data-type={e.type} onChange={(ev) => { handleChange(ev) }} defaultValue="" required={e.required} >
                        <option value="" disabled="disabled">{t("exceltransactions.select")} </option>
                        {columns.map((c, index) => (
                          <option value={index} key={index}>{c}</option>))
                        }
                      </select>
                      <p className={`separator text-xs italic `} > <span className='align-center' > <span className={`excel-ex ${labelExample(e.key) == undefined ? "invisible" : ""}`} > {t("exceltransactions.dataExample")} </span>  {labelExample(e.key)} </span> </p>
                    </>)
                  })}
                </div>



              </div>

              <p className="note-font text-center mt-8 mb-12">     {parse(t("exceltransactions.helpNote"))}     </p>



              <div className="flex items-center justify-between mt-5">
                <a className="btn sec-grey-background text-sm text-white hover:no-underline" onClick={() => { updateStep(1) }}> {t("exceltransactions.previous")}</a>
                <a className="btn sec-green-background hover:sec-green-background text-white " onClick={() => { updateStep(3) }}> {t("exceltransactions.next")}</a>
              </div>
            </form>

          </div>

          <div className="pt-5 pb-5">
            <h2 className='text-2xl text-gray-800 font-bold mb-6'>Extracto del documento subido</h2>
            <div className="table-responsive">
              <table className="table-bordered table-auto  w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    {columns.map((c, index) => (
                      <th className={`px-2 text-center first:pl-5 last:pr-5 py-3  ${colorSelectedColums(index)}`} key={index}>{c}</th>))
                    }
                  </tr>
                </thead>
                <tbody>
                  {fileData.slice(1, 5).map((item, index) => (
                    <tr key={`tr${index}`}>
                      {item.map((element, i) =>
                        <td className={`px-2 text-center first:pl-5 last:pr-5 py-3 `} key={`td${i}`}>
                          {isNaN(element) ? element : formatThousands(element)}
                        </td>)}

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </main>
  );
}

export default GetTransactions02;