import es from 'date-fns/locale/es';
import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext.js";


function DateRangeFootpintFilter({ applyFilter }) {
     // cambiar el idioma
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

    const [selectedDates, setSelectedDates] = useState([null, null]);

    const handleChange = (dates) => {
        const [start, end] = dates;
        setSelectedDates([start, end]);
        if (start === null && end === null) {
            applyFilter([]);
        } else {
            applyFilter([start, end]);
        }
    };

    return (<div style={{alignSelf:"center"}}>
        <DatePicker
            id="date-filter"
            selectsRange
            startDate={selectedDates[0]}
            endDate={selectedDates[1]}
            onChange={handleChange}
            isClearable
            locale={es}
            dateFormat="dd/MM/yyyy"
            placeholderText={t("footPrint.datesSearh")}
        /></div>
    );
}

export default DateRangeFootpintFilter;