import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
import qr from '../../../images/landing-qr.webp'
import roll from '../../../images/rollup.webp'
import tiempoReal from '../../../images/tiempoReal.webp'

export default function bloqueDocServicios() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <div className='container flex justify-center mt-5'>
      <div className='bloque-pasos  max-w-6xl mx-auto px-2 sm:px-3 text-airco2-general'>
        <span className='number-landing' >
          2
        </span>
        <div className='flex items-center pt-5 flex-no-wrap custom-flex'>


          <h3 className='h1 w-full md:w-1/2  text-airco2-title-landing m-3'> {t("landingEventos.servicioEventos")}</h3>
          <p className='w-full md:w-1/2  m-3 font-semibold flex flex-col items-end text-right'>
            <span>{t("landingEventos.implicamos")}</span>

            <span className='mt-2' style={{ width: '100%', height: '1.5px', backgroundColor: ' #878787' }}> </span>



          </p>

        </div>
        {/* bloque 1 */}
        <div className='services m-3 pt-4'>
          <div className=' flex flex-wrap  justify-center md:justify-between items-center'>
            <img className='rounded-lg transform hover:scale-105 transition duration-700 ease-out' style={{ height: '300px' }} src={qr} alt="Tarjeta con código QR"></img>
            <div className='w-full md:w-1/2'>
              <h4 className="text-airco2-principal md:text-center font-bold pb-3 pt-3 text-2xl" data-aos="fade-up" data-aos-delay="100" > {t("landingEventos.repartoQr")} :
              </h4>

              <ul className=' list-service'>
                <li>
                  {t("landingEventos.accesoFormulario")}
                </li>
                <li>
                  {t("landingEventos.tarjetasOrganicas")}
                </li>
                <li>
                  {t("landingEventos.bienvenidaPositiva")}
                </li>
                <li>
                  {t("landingEventos.opcionVoluntaria")}
                </li>

              </ul>

            </div>
          </div>

        </div>
        {/* bloque 2 */}
        <div className='services m-3 pt-4'>
          <div className='flex flex-wrap justify-center md:justify-between items-center '>
          <img className=' w-1/2  md:hidden rounded-lg transform hover:scale-105 transition duration-700 ease-out' style={{ height: '300px' }} src={roll} alt="Puesto informativo"></img>
            <div className='w-full md:w-1/2'>
          
              <h4 className="text-airco2-principal md:text-center font-bold pb-3 pt-3 text-2xl" data-aos="fade-up" data-aos-delay="100" > {t("landingEventos.rollUp")} :
              </h4>
              <ul className='list-service'>
                <li>
                  {t("landingEventos.dudas")}
                </li>
                <li>
                  {t("landingEventos.formaReactiva")}
                </li>
                <li>
                  {t("landingEventos.obsequio")}
                </li>
                <li>
                  {t("landingEventos.servicioPersonal")}
                </li>

              </ul>

            </div>
            <img className=' hidden  md:flex rounded-lg transform hover:scale-105 transition duration-700 ease-out' style={{ height: '300px' }} src={roll} alt="Puesto informativo"></img>

          </div>

        </div>

        {/* bloque 3 */}
        <div className='services m-3 pt-4'>

        <div className='flex flex-wrap justify-center md:justify-between items-center '>
          
            <img className='rounded-lg transform hover:scale-105 transition duration-700 ease-out' style={{ height: '300px' }} src={tiempoReal} alt="Anuncio de la huella en tiempo real"></img>
            <div className='w-full md:w-1/2'>
              <h4 className="text-airco2-principal md:text-center font-bold pb-3 pt-3 text-2xl" data-aos="fade-up" data-aos-delay="100" > {t("landingEventos.tiempoReal")} :
              </h4>
              <ul className='list-service'>
                <li className='italic pb-3 text-airco2-secondary '>
                {t("landingEventos.graciasDatos")}
                </li>
                <li>
                {t("landingEventos.ejemploInicio")}
                </li>
                <li>
                {t("landingEventos.duracion")}
                </li>
               

              </ul>

            </div>
          

          </div>

        </div>


     

      </div>
    </div>
  )
}
