import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import emailPic from '../../../images/email.svg'
import axios from 'axios';
import { useParams } from 'react-router';
import { Spinner } from 'react-bootstrap';
import Header from '../../../partials/Header';
import { useNavigate } from 'react-router-dom';

export default function ValidateContact() {
    const [lng, updateLng] = useContext(IdiomContext)
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("global");
    const pathParams = useParams();
    const idContact = parseInt(pathParams.idContact)
    const idExternal = pathParams.idExternal;
    const [validateData, setValidateData] = useState({
        data: [],
        isFetching: false
    });
    const [qr,setQr]=useState(true)

    const fetchValidate = async () => {
        setValidateData({ data: validateData.data, isFetching: true });
        try {
            const response = await axios.get(`${process.env.REACT_APP_CH_CONTACTS}/contact/${idContact}/validate?idExternalQuestionnaire=${idExternal}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            setValidateData({ data: response.data.data, isFetching: false });
        } catch (exception) {
            console.error(exception)
            setValidateData({ data: [], isFetching: false });
        }
    };
    
    useEffect(() => {
        fetchValidate();
    }, []);

 const url = validateData.data?.urlExternalQuestionnaire
 const handleNavigate = () => {
    if (url) {
      const urlWithState = `${url}?qr=${encodeURIComponent(qr)}`;
      window.location.href = urlWithState;
    }
  };

    return (
        <div className=" sec-neutro-background  h-screen overflow-hidden">
            <Header></Header>
            <div className='flex flex-col h-screen justify-center'>
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto flex items-center justify-center">
                    {validateData.isFetching ?
                        <Spinner></Spinner>
                        :
                        <div className="text-gray-800 text-center">
                            <h1 className="text-lg md:text-xl mb-4 font-bold">{t("fpThird.validateEmail1") + validateData.data?.email + t("fpThird.validateEmail2")}</h1>
                            <div className='flex justify-center'>
                                <img alt='perfilok' src={emailPic} width={'280'}></img>
                            </div>
                            <div className='flex justify-center mt-4 pt-4'>
                                <button className="btn-green mr-4 rounded-lg px-4 py-2"  onClick={handleNavigate}>{t("fpThird.continuar")}</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
