import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import BarChart from '../../charts/BarChart04';
// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function PartialsByQuestions({ data }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const arrayLabels = [' kgCO₂', 'g CH₄', 'g N₂O'];

    const [groupedData, setGroupedData] = useState()
    const [filterData, setFilterData] = useState(undefined);

    useEffect(() => {
        const groupedDataInfo = data?.reduce((acc, item) => {
            const existingItem = acc.find((groupedItem) => groupedItem.question === item.question);
            if (existingItem) {
                existingItem.kgCO2 += item.kgCO2;
                existingItem.gch4 += item.gch4;
                existingItem.gn2O += item.gn2O;
            } else {
                acc.push({
                    question: item.question,
                    kgCO2: item.kgCO2,
                    gch4: item.gch4,
                    gn2O: item.gn2O,
                });
            }
            return acc;
        }, []);

        setFilterData(groupedDataInfo?.filter(item => item.kgCO2 !== 0 && item.gch4 !== 0 && item.gn2O !== 0));

    }, [data]);




    const chartData = {
        labels: filterData?.map((item) => item.question),
        datasets: [
            {
                label: arrayLabels[0],
                data: filterData?.map((item) => item.kgCO2),
                backgroundColor: [tailwindConfig().theme.colors.lime[400]],
                hoverBackgroundColor: [tailwindConfig().theme.colors.lime[500]],
                categoryPercentage: 0.66,
            },
            {
                label: arrayLabels[1],
                data: filterData?.map((item) => item.gch4),
                backgroundColor: [tailwindConfig().theme.colors.cyan[400]],
                hoverBackgroundColor: [tailwindConfig().theme.colors.cyan[500]],
                categoryPercentage: 0.66,
            },
            {
                label: arrayLabels[2],
                data: filterData?.map((item) => item.gn2O),
                backgroundColor: [tailwindConfig().theme.colors.fuchsia[400]],
                hoverBackgroundColor: [tailwindConfig().theme.colors.fuchsia[500]],
                categoryPercentage: 0.66,
            },
        ],
    };


    return (
        <div className="flex flex-col col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800">{t("footPrint.partialsByQuestion")}</h2>
            </header>

            {filterData === undefined &&
                <div className="text-center"><Spinner className='' /></div>
            }
            {filterData !== undefined && filterData.length == 0 &&
                <div className='mt-4 mb-4'>{t("footPrint.noDataToGraphic")}</div>}


            {filterData !== undefined && filterData.length > 0 &&
                <>
                    <BarChart data={chartData} height={498} info={true} />
                    <span></span>
                    <span className="italic text-gray-800 text-xs text-center mb-2">{t("footPrint.partials*")}</span>
                    <div className='border-1 border-solid m-2'
                        style={{ backgroundColor: 'rgba(170, 255, 0, 0.1)', borderColor: 'rgba(170, 255, 0, 0.6)' }}

                    >
                        <p className=" text-gray-800 px-2 text-xs mb-1">** <span className='font-bold text-sm'> CO<sub>2</sub>: </span>{t("footPrint.co2*")}</p>
                        <p className="text-gray-800 px-2 text-xs mb-1 "><span className='font-bold text-sm'> CH<sub>4</sub>: </span>{t("footPrint.ch4*")}</p>
                        <p className="text-gray-800 px-2 text-xs mb-1 "><span className='font-bold text-sm'> N<sub>2</sub>O: </span>{t("footPrint.n2o*")}</p>

                    </div>
                </>
            }
        </div>
    );
}


export default PartialsByQuestions;