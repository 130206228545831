import React, { useContext, useEffect, useRef } from 'react';

import { ArcElement, Chart, DoughnutController, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt"
import "moment/locale/gl"

// Import utilities
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "./../../../context/createcontext";
import { formatThousands, tailwindConfig } from '../utils/Utils';

Chart.register(DoughnutController, ArcElement, TimeScale, Tooltip);

function DoughnutChart({
  data,
  width,
  height,
  unit
}) {

  const canvas = useRef(null);
  const legend = useRef(null);
     // cambiar idioma
    
 const [lng, updateLng] = useContext(IdiomContext)
 const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    switch (lng) {
      case "en":
        moment.locale("en");
        break;
      case "pt":
        moment.locale("pt");
        break;
      case "gl":
        moment.locale("gl");
        break;
      default:
        moment.locale("es");
        break;
    }
    const chart = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: {
        cutout: '80%',
        layout: {
          padding: 24,
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatThousands(context.parsed) + (unit != undefined ? unit : ""),
            },
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [{
        id: 'htmlLegend',
        afterUpdate(c, args, options) {
          const ul = legend.current;
          if (!ul) return;
          // Remove old legend items
          while (ul.firstChild) {
            ul.firstChild.remove();
          }
          // Reuse the built-in legendItems generator
          const items = c.options.plugins.legend.labels.generateLabels(c);
          items.forEach((item) => {
            const li = document.createElement('li');
            li.style.margin = tailwindConfig().theme.margin[1];
            // Button element
            const button = document.createElement('button')
            button.style.display = 'inline-flex'
            button.style.alignItems = 'center'
            button.style.opacity = item.hidden ? '.3' : ''
            button.onclick = () => {
              c.toggleDataVisibility(item.index, !item.index);
              c.update();
            }
            // Color box
            const box = document.createElement('span')
            box.style.display = 'block'
            box.style.width = tailwindConfig().theme.width[3]
            box.style.height = tailwindConfig().theme.height[3]
            box.style.borderRadius = tailwindConfig().theme.borderRadius.full
            box.style.marginRight = tailwindConfig().theme.margin[2]
            box.style.borderWidth = '3px'
            box.style.borderColor = item.fillStyle
            box.style.pointerEvents = 'none'
            // Label
            const label = document.createElement('span')
            label.style.color = tailwindConfig().theme.colors.gray[500]
            label.style.fontSize = tailwindConfig().theme.fontSize.sm[0]
            label.style.lineHeight = tailwindConfig().theme.fontSize.sm[1].lineHeight
            const labelText = document.createTextNode(item.text)
            label.appendChild(labelText)
            li.appendChild(button)
            button.appendChild(box)
            button.appendChild(label)
            ul.appendChild(li)
          });
        },
      }],
    });
    return () => chart.destroy();
    // eslint-disable-next-line
  }, [lng, data]);
 


  return (
    <div className="grow flex flex-col">
      <div className="px-5 pt-2 pb-6">
        <ul ref={legend} className="flex flex-wrap justify-center " style={{fontWeight:'bold'}}></ul>
      </div>
      <div>
        <canvas ref={canvas} width={width} height={height}></canvas>
      </div>

    </div>
  );
}

export default DoughnutChart;