import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalTitle, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { IdiomContext } from "../../../../../context/createcontext";
import ModalMatchColumns from "../../../calculo-huella/columnMappingForm";
import ModalGoogleDistance from '../../ModalGoogleDistance';

export default function DataEmail({
  idExternal,
  questions,
  idQuestion,
  idContact,
  answerTableData,
  setQuestions,
  currentIndexQuestion
}) {

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const [modalDescription, setModalDescription] = useState(false);
  const [modalCorrectErrors, setModalCorrectErrors] = useState(false);
  const [modalDeleteRow, setModalDeleteRow] = useState({ view: false, indexRow: null, spinner: false })

  const [answerTable, setAnswerTable] = useState(false)


  const [excelData, setExcelData] = useState([]);
  const [columnMappings, setColumnMappings] = useState([]);
  const [showModalConfirmColumns, setShowModalConfirmColumns] = useState(false);
  const [formatData, setFormatData] = useState([]);

  const [inputObjectSedes, setInputObjectSedes] = useState([]);
  const [inputObjectCombustible, setInputObjectCombustible] = useState([]);

  const dataCopyRef = useRef(null);
  const [formatDataCopy, setFormatDataCopy] = useState([]);
  const [inputTitles, setInputTitles] = useState([]);
  const [inputOptions, setInputOptions] = useState([]);
  const [activeCellIndex, setActiveCellIndex] = useState(null);

  const [changeSedes, setChangeSedes] = useState();
  const [changeQuantity, setChangeQuantity] = useState();
  const [changeRegistrationDate, setChangeRegistrationDate] = useState();

  const [selectedFormatImport, setSelectedFormatImport] = useState(null);
  const [hasNullIds, setHasNullIds] = useState(true);
  const [inputData, setInputData] = useState(undefined);
  const [googleDistanceModal, setGoogleDistanceModal] = useState({ view: false, idInput: null });

  const [savedAnswer, setSavedAnswer] = useState({
    data: [],
    isFetching: false,
  });

  const [dataQuestions, setDataQuestions] = useState({
    data: [],
    isFetching: false
  });

  const fetchDataQuestions = async () => {
    setDataQuestions({ data: dataQuestions.data, isFetching: true });
    try {
      if (idQuestion) {
        const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternal}/question/${idQuestion}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
        setDataQuestions({ data: response.data, isFetching: false });
        setInputData(response.data.data?.externalQuestionInputsDto.sort((a, b) => a.position - b.position))
      }
    } catch (exception) {
      console.error(exception)
      setDataQuestions({ data: [], isFetching: false });
    }
  };

  useEffect(() => {
    fetchDataQuestions();
  }, [questions, idQuestion])

  const dataAnswer = (savedData) => {
    answerTableData(savedData);
  };


  //POST SAVE EXTERNALANSWER
  const [savingSpinner, setSavingSpinner] = useState(false);

  const handlerSubmit = e => {
    e.preventDefault();
    if (hasNullIds) {
      setModalCorrectErrors(true);
    } else {



      const countQuantity = inputOptions.reduce((acc, currentValue) => {
        if (currentValue === "QUANTITY") {
          return acc + 1;
        }
        return acc;
      }, 0);

      let sedesIndex = inputOptions.indexOf("SEDES");
      let conceptIndex = inputOptions.indexOf("CONCEPT");
      let quantityIndex = [inputOptions.indexOf("QUANTITY")];
      let quantity2Index = [inputOptions.indexOf("QUANTITY2")];

      let nameIndex = inputOptions.indexOf("NAME");
      let observationsIndex = [inputOptions.indexOf("OBSERVATIONS")];
      let registrationDateIndex = [inputOptions.indexOf("REGISTRATION_DATE")];

      if (countQuantity > 1) {
        quantityIndex.push(inputOptions.lastIndexOf("QUANTITY"))
      }

      let data = formatData.map(element => {
        let submitObject = {
          "concept": element[conceptIndex].id,
          "quantity": Number(element[quantityIndex[0]]?.name),
          "quantity2": Number(element[quantity2Index[0]]?.name),
          "organizationBuildingId": element[sedesIndex].id,
          "contactId": parseInt(idContact),
          "mainQuestionId": element[conceptIndex].idMainQuestion,
          "name": nameIndex == -1 ? "" : element[nameIndex].name,
          "observations": observationsIndex == -1 ? "" : element[observationsIndex].name,
          "registration_date": registrationDateIndex == -1 ? "" : changeDateFormat(element[registrationDateIndex].name),
        }

        if (element[conceptIndex].idMainQuestion == 11 || element[conceptIndex].idMainQuestion == 14) {
          delete submitObject.quantity2;
        }

        return submitObject;

      });

      const fetchSavedAnswer = async () => {
        setSavingSpinner(true)
        const url = `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternal}/answer/${idQuestion}`;
        try {
          setSavedAnswer({ savedAnswer: savedAnswer.data, isFetching: true });
          const response = await axios.post(url, data, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          });
          setSavedAnswer({ data: response.data, isFetching: false });
          if (response.status === 200) {
            dataAnswer(response.data)

            const totalObjetos = response.data.data.reduce((total, currentItem) => {
              return total + currentItem.answerBodyOutputDtos.length;
            }, 0);

            const updatedQuestions = [...questions];
            updatedQuestions[currentIndexQuestion].answersByContact += totalObjetos;
            setQuestions(updatedQuestions)
          }
        } catch (exception) {
          setSavedAnswer({ data: [], isFetching: false });
        }
        (false)

      }
      fetchSavedAnswer();
    }
  }


  //Evento que crea un array con la data pegada en el textarea
  const handlePasteExcel = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');
    const rows = pastedData.split('\n').map(row => {
      const columns = row.split('\t');
      return columns.map(column => column.replace(/,/g, '.'));
    });

    setExcelData(rows);
    setShowModalConfirmColumns(true);
  };


  //Evento que crea un array con la data pegada en el textarea
  const handlePasteCsv = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');
    const rows = pastedData.split('\n');

    const parsedData = rows.map(row => {
      const columns = [];
      let currentColumn = '';
      let inQuotes = false;

      for (let i = 0; i < row.length; i++) {
        const char = row[i];

        if (char === '"') {
          inQuotes = !inQuotes;
        } else if (char === ',' && inQuotes) {
          currentColumn += '.';
        } else if (char === ',') {
          columns.push(currentColumn);
          currentColumn = '';
        } else {
          currentColumn += char;
        }
      }

      columns.push(currentColumn);

      return columns;
    });

    setExcelData(parsedData);
    setShowModalConfirmColumns(true);
  };


  //Transformar la data de excel 
  const dataFormater = () => {
    if (excelData.length === 0) {
      return [];
    }

    let formattedData = excelData.slice(1).map((row) => {

      let formattedRow = Array(inputTitles.length);

      for (let i = 0; i < columnMappings.length; i++) {
        let obj = columnMappings[i];
        formattedRow[i] = { name: row[obj.index], id: null, idMainQuestion: null };
      }
      return formattedRow;
    });

    return formattedData;
  };


  //Seteo del array con cabeceras correctas ordenadas, 
  useEffect(() => {
    //Array original usado para la tabla
    setFormatData(dataFormater())
    //Array con el mismo valor que el original que se usa para hacer la deep copy que sirve para editar cada celda si está mal
    setFormatDataCopy(dataFormater())
  }, [columnMappings])

  //Función que recoge el match de cabeceras desde componente del modal y construye un array con el orden correspondiente o resetea a 0 el componente
  const handleColumnMappingSubmit = (mappings) => {
    if (mappings.length > 0) {
      setColumnMappings(mappings);
    } else {
      setFormatData([]);
      setColumnMappings([]);
      setExcelData([])
    }
    setShowModalConfirmColumns(false);
  };

  //Seteo del array con cabeceras correctas ordenadas, 
  useEffect(() => {
    //Array original usado para la tabla
    setFormatData(dataFormater())
    //Array con el mismo valor que el original que se usa para hacer la deep copy que sirve para editar cada celda si está mal
    setFormatDataCopy(dataFormater())
  }, [columnMappings])

  //Estilos para el select de React
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid var(--airco2-green-color)' : provided.border,
      boxShadow: state.isFocused ? '0 0 0 1px var(--airco2-green-color)' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? 'var(--airco2-green-color)' : provided.borderColor,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'var(--airco2-green-color)' : provided.backgroundColor,
      '&:hover': {
        backgroundColor: 'var(--airco2-green-color)',

      },
      color: state.isSelected ? 'var(--airco2-green-color)' : provided.color,
    }),
  };

  //Setteo de estados con titulos, inputOption y concept objects
  useEffect(() => {
    if (inputData != undefined && inputData != true) {

      setInputObjectSedes(inputData.filter(inputObj => inputObj.input.inputOptions === "SEDES").flatMap(filteredObj => filteredObj.input.options));
      setInputObjectCombustible(inputData.filter(inputObj => inputObj.input.inputOptions == "CONCEPT").flatMap(filteredObj => filteredObj.input.options));

      let basicTitles = inputData.map(inputObj => inputObj.input).map(input => input.title);
      let inputOptions = inputData.map(inputObj => inputObj.input).map(input => input.inputOptions);

      setInputTitles(basicTitles);
      setInputOptions(inputOptions);
    }
  }, [inputData])


  //Duplicado del array original que se usa para modificar las celdas erroneas desde la tabla
  useEffect(() => {
    dataCopyRef.current = formatDataCopy.map(subArray => subArray.map(item => ({ ...item })));
  }, [formatDataCopy])


  //Función que cambia todas las sedes con el mismo nombre al valor correcto escogido y guarda su id
  const handleApplyAllSedes = (nameSede) => {

    let indexColumnSedes = inputOptions.indexOf("SEDES");
    let copiaArray = [...formatData];

    copiaArray.forEach(element => {
      if (element[indexColumnSedes].name == nameSede) {
        element[indexColumnSedes] = { name: changeSedes.name, id: changeSedes.id }
      }
    });

    setFormatData(copiaArray);
    setChangeSedes();

    let selects = document.querySelectorAll('[id=selectSedes]');
    selects.forEach((select) => {
      select.value = '';
    });
  }



  //Función que cambia todas las sedes con el mismo nombre al valor correcto escogido y guarda su id
  const handleApplyAllConcepts = (nameSede) => {
    let indexColumnSedes = inputOptions.indexOf("CONCEPT");
    let copiaArray = [...formatData];
    copiaArray.forEach(element => {
      if (element[indexColumnSedes].name == nameSede) {
        element[indexColumnSedes] = { name: selectedGroup.value + ", " + selectedFuelId.value, id: selectedFuelId.id, idMainQuestion: mainQuestion }
      }
    });

    setFormatData(copiaArray);
    setSelectedVehicle(null);
    setSelectedGroup(null);
    setMainQuestion(null);
    setSelectedFuelId(null);
    setActiveCellIndex(null);
  }

  //Estados para construir selects de concept y guardar mainQuestion y id del FE
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [mainQuestion, setMainQuestion] = useState(null); // Estado para mainQuestion
  const [selectedFuelId, setSelectedFuelId] = useState(null); // Estado para el id del combustible seleccionado

  // Opciones para los selects 
  const vehicleOptions = inputObjectCombustible.map(item => ({ value: item.name, label: item.name }));

  const groupOptions = selectedVehicle ? inputObjectCombustible.find(item => item.name === selectedVehicle.value)?.listGroupEmissions.map(group => ({
    value: group.name,
    label: group.name,
    mainQuestion: group.mainQuestion
  })) : [];

  const fuelOptions = selectedGroup ? inputObjectCombustible.find(item => item.name === selectedVehicle.value)?.listGroupEmissions.find(group => group.name === selectedGroup.value)?.combustible.map(fuel => ({
    value: fuel.name,
    label: fuel.name,
    id: fuel.id
  })) : [];

  const handleVehicleChange = (selectedOption) => {
    setSelectedVehicle(selectedOption);
    setSelectedGroup(null);
    setMainQuestion(null);
    setSelectedFuelId(null);
  };

  //Si el 2º select solo tiene un option se marca por defecto
  useEffect(() => {
    if (selectedVehicle != null && groupOptions.length === 1) {
      handleGroupChange(groupOptions[0]);
    }
  }, [selectedVehicle])

  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
    setMainQuestion(selectedOption.mainQuestion); // Guardar mainQuestion del grupo seleccionado
    setSelectedFuelId(null);
  };

  //Si el 3º select solo tiene un option se marca por defecto
  useEffect(() => {
    if (selectedGroup != null && mainQuestion != null && selectedGroup.length === 1) {
      handleFuelChange(selectedGroup[0]);
    }
  }, [selectedGroup])

  const handleFuelChange = (selectedOption) => {
    setSelectedFuelId(selectedOption); // Guardar el id del combustible seleccionado
  };

  //Resetea estados de los select de concept y muestra el correspondiente. Si el 1º select solo tiene 1 option lo selecciona por defecto
  const handleShowSelect = (colIndex, rowIndex) => {
    setActiveCellIndex({ colIndex, rowIndex })
    if (vehicleOptions.length === 1) {
      handleVehicleChange(vehicleOptions[0])
    } else {
      setSelectedVehicle(null);
    }
    setSelectedGroup(null);
    setMainQuestion(null);
    setSelectedFuelId(null);
  }

  //Borrado de una linea del array que forma la tabla con resultados previos a la importación
  const handleDeleteRow = (rowIndex) => {
    setModalDeleteRow({ view: false, indexRow: null, spinner: true })
    let copiaArray = [...formatData];
    copiaArray.splice(rowIndex, 1);
    setFormatDataCopy(copiaArray);
    setModalDeleteRow({ view: false, indexRow: null, spinner: false })
  }

  //Función que cambia la cantidad de una celda concreta
  const handleApplyQuantity = (rowIndex, colIndex) => {
    let copiaArray = [...formatData];
    copiaArray[rowIndex][colIndex] = { name: changeQuantity.name, id: changeQuantity.id }

    setFormatData(copiaArray);
    setChangeQuantity();
  }

  const renderTooltipDistancia = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("footPrint.calcularDistancia")}
    </Tooltip>
  );

  const handleModalDistanceCalculation = (rowIndex) => {
    setGoogleDistanceModal({ view: true, idInput: `quantity-distance-input-${rowIndex}` });
  }

  const handleDistanceFromGoogle = (data) => {
    setChangeQuantity({ name: data.distance, id: "qtyOK" })
  }

  function changeDateFormat(date) {
    // Crea un objeto Date con la fecha proporcionada
    if (date !== undefined && date !== "" && date !== "\r") {
      date = date.trim();
      var originalDate;

      // Extrae los componentes del día, mes y año
      if (date.includes("/")) {
        var dateParts = date.split('/');
        var dayPart = parseInt(dateParts[0], 10);
        var monthPart = parseInt(dateParts[1], 10) - 1; // Restamos 1 al mes porque en JavaScript los meses van de 0 a 11
        var yearPart = parseInt(dateParts[2], 10);

        originalDate = new Date(yearPart, monthPart, dayPart);
      } else {
        originalDate = new Date(date);

      }
      // Obtiene los componentes de la fecha (año, mes y día)
      var year = originalDate.getFullYear();
      var month = ('0' + (originalDate.getMonth() + 1)).slice(-2); // Agrega un cero al mes si es necesario
      var day = ('0' + originalDate.getDate()).slice(-2); // Agrega un cero al día si es necesario

      // Construye la cadena en el formato deseado
      var formatedDate = year + '-' + month + '-' + day;

      return formatedDate;
    } else {
      return date;
    }
  }

  //Función que cambia la cantidad de una celda concreta
  const handleApplyRegistrationDate = (e, registrtionDate) => {
    e.preventDefault();
    if (registrtionDate !== undefined && changeRegistrationDate !== undefined) {
      let indexColumnRegistrationDate = inputOptions.indexOf("REGISTRATION_DATE");
      let copiaArray = [...formatData];

      copiaArray.forEach(element => {
        if (element[indexColumnRegistrationDate].name == registrtionDate) {
          element[indexColumnRegistrationDate] = { name: changeRegistrationDate.name, id: changeRegistrationDate.id }
        }
      });

      setFormatData(copiaArray);
      setChangeRegistrationDate();
    }
  }

  const comprobaciones = (value, rowIndex, colIndex, allCellsOk) => {
    //allCellsOk: booleano que indica si la fila tiene todas las columnas con valores correctos

    switch (inputOptions[colIndex]) {

      case "SEDES":

        let sedeStatus = false;

        inputObjectSedes.map((sedeBBDD) => {
          if (sedeBBDD.name === (value.name) && value.name !== "") {
            dataCopyRef.current[rowIndex][colIndex].id = sedeBBDD.id;
            sedeStatus = true;
          }
        })

        return (
          <div key={colIndex + "comprobacion"}>
            {!allCellsOk ? value.name :
              (sedeStatus ? <div> {value.name} ✅</div> :
                <div className='text-sm flex flex-col items-center justify-center mb-2'>
                  <span className='text-base'> {value.name} ❌</span>
                  <form onSubmit={() => handleApplyAllSedes(value.name)} className="flex flex-col">
                    <select id="selectSedes" className='text-xs my-1' style={{ maxWidth: "220px" }} onChange={(e) => setChangeSedes(JSON.parse(e.target.value))} required>
                      <option value="">{t("footPrint.seleccionaSede")}</option>
                      {inputObjectSedes.map((sedeBBDD) => (
                        <option key={sedeBBDD.id} value={JSON.stringify({ name: sedeBBDD.name, id: sedeBBDD.id })}>
                          {sedeBBDD.name}
                        </option>
                      ))}
                    </select>
                    <button type="submit" className="btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                  </form>
                </div>
              )
            }
          </div>
        );

      case "QUANTITY":


        let quantityStatus = true;
        if (Number(value.name) <= 0 || isNaN(Number(value.name))) {
          quantityStatus = false;
        } else {
          dataCopyRef.current[rowIndex][colIndex].id = "qtyOK";
        }

        return (
          <div key={colIndex + "comprobacion"}>
            {!allCellsOk ? value.name :
              (quantityStatus ? <div> {value.name} ✅</div> :
                <div className='text-sm flex flex-col items-center justify-center mb-2'>
                  <span className='text-base'>{t("footPrint.+Que")}  0 ❌</span>
                  <form onSubmit={() => handleApplyQuantity(rowIndex, colIndex)} className="flex flex-col items-center">
                    <div className="flex flex-row items-center">
                      <input type="number" min="0.01" step="0.01" id={`quantity-distance-input-${rowIndex}`} className='text-base w-auto my-1' defaultValue={Number(value.name)} style={{ maxWidth: "140px" }} onChange={(e) => setChangeQuantity({ name: e.target.value, id: "qtyOK" })} required />
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipDistancia}
                      >
                        <button type="button" onClick={() => handleModalDistanceCalculation(rowIndex)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-google-maps" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 9.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                            <path d="M6.428 12.494l7.314 -9.252" />
                            <path d="M10.002 7.935l-2.937 -2.545" />
                            <path d="M17.693 6.593l-8.336 9.979" />
                            <path d="M17.591 6.376c.472 .907 .715 1.914 .709 2.935a7.263 7.263 0 0 1 -.72 3.18a19.085 19.085 0 0 1 -2.089 3c-.784 .933 -1.49 1.93 -2.11 2.98c-.314 .62 -.568 1.27 -.757 1.938c-.121 .36 -.277 .591 -.622 .591c-.315 0 -.463 -.136 -.626 -.593a10.595 10.595 0 0 0 -.779 -1.978a18.18 18.18 0 0 0 -1.423 -2.091c-.877 -1.184 -2.179 -2.535 -2.853 -4.071a7.077 7.077 0 0 1 -.621 -2.967a6.226 6.226 0 0 1 1.476 -4.055a6.25 6.25 0 0 1 4.811 -2.245a6.462 6.462 0 0 1 1.918 .284a6.255 6.255 0 0 1 3.686 3.092z" />
                          </svg>
                        </button>
                      </OverlayTrigger>
                    </div>
                    <button type="submit" className="btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  </form>
                </div>
              )
            }
          </div>
        );


      case "QUANTITY2":

        let quantity2Status = true;
        if (Number(value.name) <= 0 || isNaN(Number(value.name))) {
          quantity2Status = false;
        } else {
          dataCopyRef.current[rowIndex][colIndex].id = "qtyOK";
        }

        return (
          <div key={colIndex + "comprobacion"}>
            {!allCellsOk ? value.name :
              (quantity2Status ? <div> {value.name} ✅</div> :
                <div className='text-sm flex flex-col items-center justify-center mb-2'>
                  <span className='text-base'>{t("footPrint.+Que")}  0 ❌</span>
                  <form onSubmit={() => handleApplyQuantity(rowIndex, colIndex)} className="flex flex-col items-center">
                    <div className="flex flex-row items-center">
                      <input type="number" min="0.01" step="0.01" className='text-base w-auto my-1' defaultValue={Number(value.name)} style={{ maxWidth: "140px" }} onChange={(e) => setChangeQuantity({ name: e.target.value, id: "qtyOK" })} required />
                    </div>
                    <button type="submit" className="btn-sm btn-green mt-2">{t("footPrint.guardarCambio")}</button>
                  </form>
                </div>
              )
            }
          </div>
        );

      case "CONCEPT":

        const isSelectedCell = activeCellIndex && activeCellIndex.colIndex === colIndex && activeCellIndex.rowIndex === rowIndex;
        return (

          <div key={colIndex + "-" + rowIndex + "-concept"}>
            {!allCellsOk ? value.name :
              (value.id != null && value.idMainQuestion != null ? <div> {value.name} ✅</div> :
                <div>
                  <div>
                    <span>{value.name} ❌</span>

                  </div>
                  {isSelectedCell ?
                    <div id={colIndex + "-" + rowIndex + "-selectDiv"} className='flex flex-col gap-2' >
                      {vehicleOptions.length > 1 &&
                        <div className='flex items-center justify-center m-2'>
                          <Select
                            menuPosition="fixed"
                            options={vehicleOptions}
                            onChange={handleVehicleChange}
                            value={selectedVehicle}
                            placeholder={`${t('footPrint.selecciona')}...`}
                            className='custom-width-px'
                            styles={customStyles}
                          />
                        </div>
                      }

                      {selectedVehicle && groupOptions.length > 1 && (
                        <div className='flex items-center justify-center m-2'>
                          <Select
                            options={groupOptions}
                            menuPosition="fixed"
                            onChange={handleGroupChange}
                            value={selectedGroup}
                            placeholder={`${t('footPrint.selecciona')}...`}
                            className='custom-width-px'
                            styles={customStyles}
                          />
                        </div>
                      )}

                      {selectedGroup && (
                        <form onSubmit={() => handleApplyAllConcepts(value.name)}>
                          <div className='flex items-center justify-center m-2'>
                            <Select
                              options={fuelOptions}
                              menuPosition="fixed"
                              onChange={handleFuelChange}
                              value={selectedFuelId}
                              placeholder={`${t('footPrint.selecciona')}...`}
                              className='custom-width-px'
                              styles={customStyles}
                              required
                            />
                          </div>

                          <button className='btn-sm btn-green my-2 text-white' type='submit'>{t("footPrint.aplicarTodos")}</button>
                        </form>
                      )}
                    </div>
                    :
                    <button type="button" className='btn-sm btn-green text-white my-2' onClick={() => handleShowSelect(colIndex, rowIndex)}>Corregir</button>
                  }
                </div>
              )}
          </div>
        );

      case "NAME":
        dataCopyRef.current[rowIndex][colIndex].id = "nameOK";

        return (
          <div key={colIndex + "comprobacion"}>
            {value.name} ✅
          </div>
        );


      case "REGISTRATION_DATE":
        let registrationDate = true;
        if (value.name === undefined || value.name === "" || value.name === "\r") {
          dataCopyRef.current[rowIndex][colIndex].id = "registrationDateOk";
        } else if (!isNaN(Number(value.name))) {
          registrationDate = false;
        } else if (!value.name.includes("-") && !value.name.includes("/")) {
          registrationDate = false;
        } else {
          dataCopyRef.current[rowIndex][colIndex].id = "registrationDateOk";
        }


        return (
          <div key={colIndex + "comprobacion"}>
            {!allCellsOk ? changeDateFormat(value.name) :
              (registrationDate ? <div> {changeDateFormat(value.name)} ✅</div> :
                <div className='text-sm flex flex-col items-center justify-center mb-2'>
                  <span className='text-base'>Fecha del dato: {value.name} ❌</span>
                  <form onSubmit={(e) => handleApplyRegistrationDate(e, value.name)} className="flex flex-col">
                    <input type="date"
                      onChange={(e) => setChangeRegistrationDate({ name: e.target.value, id: "registrationDateOk" })}
                      className='text-base w-auto my-1'
                      defaultValue={value.name} style={{ maxWidth: "140px" }}
                    />

                    <button type="submit" className="btn-sm btn-green mt-2">{t("footPrint.aplicarTodos")}</button>
                  </form>
                </div>
              )
            }
          </div>
        );


      case "OBSERVATIONS":
        dataCopyRef.current[rowIndex][colIndex].id = "observations";
        return (
          <div key={colIndex + "comprobacion"}>
            {value.name} ✅
          </div>
        );

    }
  }

  //UseEffect que guarda el mismo array pero con los IDs de los campos que están correctos después de que acabe el renderizado
  useEffect(() => {
    setFormatData(dataCopyRef.current);
  });

  //Seteo del estado hasNullIds con booleano para indicar si la tabla tiene todas las lineas correctas
  useEffect(() => {
    if (formatData.length > 0) {
      let hasNullId = false;
      for (const row of formatData) {
        if (row.some(obj => obj.id === null)) {
          hasNullId = true;
          break;
        }
      }
      setHasNullIds(hasNullId);
    }
  }, [formatData])


  return (
    <div>
      <ModalGoogleDistance
        googleDistanceModal={googleDistanceModal}
        setGoogleDistanceModal={setGoogleDistanceModal}
        handleDistanceFromGoogle={handleDistanceFromGoogle} />

      <Modal show={modalDeleteRow.view} animation={false} data-aos="fade-up">
        <ModalHeader className='sec-green-background text-white text-lg'>
          <ModalTitle>{t("footPrint.eliminarFila")}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p className="text-center">{t("footPrint.eliminarFilaSeguro")}</p>
        </ModalBody>
        <div className="flex justify-center space-x-4 mb-4">
          <button className="btn-sm btn-cancel" onClick={() => setModalDeleteRow({ view: false, indexRow: null, spinner: false })}>{t("footPrint.cancelar")}</button>
          <button className="btn-sm btn-green" onClick={() => handleDeleteRow(modalDeleteRow.indexRow)}>{modalDeleteRow.spinner ? <Spinner variant="light" animation="border" /> : "Eliminar"}</button>
        </div>
      </Modal>

      <Modal show={modalCorrectErrors} animation={false} data-aos="fade-up">
        <ModalHeader className='sec-green-background text-white text-lg '>
          <ModalTitle className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="32" height="32" viewBox="0 0 24 24" strokeWidth="2" stroke="#fb608a" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
              <path d="M12 8v4" />
              <path d="M12 16h.01" />
            </svg>
            <span>{t("footPrint.error")} </span>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p className="text-center">{t("footPrint.corregir")}</p>
        </ModalBody>
        <div className="flex justify-center space-x-4 mb-4">
          <button className="btn-sm btn-green" onClick={() => setModalCorrectErrors(false)}>{t("footPrint.aceptar")}</button>
        </div>
      </Modal>

      {/* <Modal show={googleDistanceModal.view}>
        <ModalHeader className='sec-green-background'>
          <ModalTitle className="text-white">{t("footPrint.calcularDistancia")}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div>
            <GoogleDistance sendDataToParent={handleDistanceFromGoogle} idInput={googleDistanceModal.idInput} />
          </div>
        </ModalBody>
        <ModalFooter className="flex justify-center">
          <button className="btn-sm btn-cancel rounded-lg" onClick={() => setGoogleDistanceModal({ view: false, idInput: null })}>
            {t("fpThird.cerrar")}
          </button>
        </ModalFooter>
      </Modal> */}

      {showModalConfirmColumns &&
        <ModalMatchColumns
          view={showModalConfirmColumns}
          userColumns={excelData[0]}
          predefinedColumns={inputTitles}
          onMappingSubmit={handleColumnMappingSubmit}
          userFirstLineData={excelData[1]}
          inputOptions={inputOptions} />}

      <div id="firstComponent flex justify-center" className=''>


        {inputData === undefined &&
          <div className='text-center'>
            <Spinner animation="border" variant="dark" />
            <p>{t("fpThird.getQuestions")}</p>
          </div>
        }

        {inputData !== undefined &&
          <div className='m-2'>
            <div>
              <div className='flex flex-row space-x-4 my-2'>
                <p className='font-semibold'>{t("footPrint.formato")}:</p>
                <div className='flex flex-row items-center space-x-2'>
                  <input type="radio" name="formatImport" value="excel" id="excel" onChange={() => setSelectedFormatImport("excel")} checked={selectedFormatImport == "excel" ? true : false} />
                  <span htmlFor="excel">Excel</span>
                </div>
                <div className='flex flex-row items-center space-x-2'>
                  <input type="radio" name="formatImport" value="csv" id="csv" onChange={() => setSelectedFormatImport("csv")} checked={selectedFormatImport == "csv" ? true : false} />
                  <span htmlFor="csv">CSV</span>
                </div>
              </div>
              <div className='flex flex-col my-2'>
                <p className="flex gap-1 items-center">
                  <div><span className='font-semibold'>{t("footPrint.datos")}:</span> <span>{t("footPrint.columnasNecesarias")}: {inputTitles.join(", ")}</span></div>

                </p>
                <textarea onPaste={selectedFormatImport == "excel" ? handlePasteExcel : handlePasteCsv} className='w-full mt-2' placeholder={selectedFormatImport == null ? t("footPrint.seleccionaFormato") : t("footPrint.pegaDatos")} disabled={selectedFormatImport != null ? false : true} />
              </div>
            </div>
            <div>

              {formatData.length > 0 ?
                <div className=" overflow-x-auto w-full mt-2">
                  <p>{t("footPrint.resultadoPrevio")}:</p>
                  <table className="table-auto w-full border border-collapse">
                    <thead>
                      <tr>
                        {inputTitles.map((cell, cellIndex) => (
                          <th key={cellIndex} className="border p-2 text-center">{cell}</th>
                        ))}
                        <th className='text-center border'>{t("footPrint.estado/borrar")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formatData.map((row, rowIndex) => {
                        let allCellsOk = row.some(obj => obj.id === null);
                        return (
                          <tr key={rowIndex} className='text-center  '>
                            {row.map((value, colIndex) => {
                              return (
                                <td key={colIndex + "-" + rowIndex} className='border'>
                                  {comprobaciones(value, rowIndex, colIndex, allCellsOk)}
                                </td>
                              )
                            }
                            )}
                            <td className='border'>
                              <div className="flex flex-row justify-center items-center space-x-2">
                                {allCellsOk ?
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fb608a" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                    <path d="M12 8v4" />
                                    <path d="M12 16h.01" />
                                  </svg>
                                  :
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-checks" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#59d78e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 12l5 5l10 -10" />
                                    <path d="M2 12l5 5m5 -5l5 -5" />
                                  </svg>
                                }
                                <button onClick={() => setModalDeleteRow({ view: true, indexRow: rowIndex, spinner: false })} tittle="Eliminar linea">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#f1a187" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M4 7l16 0" />
                                    <path d="M10 11l0 6" />
                                    <path d="M14 11l0 6" />
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                  </svg>
                                </button>

                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                : ""}
              <div className='flex justify-end'>
                <button type="button" onClick={handlerSubmit} className={`btn-sm btn-green mt-4`} disabled={formatData.length == 0 && true} >
                  {savingSpinner ? <Spinner animation="border" variant="light" /> : t("fpThird.guardar")}
                </button>
              </div>
            </div>
          </div>
        }

      </div >
    </div >
  )
}