import Footer from "../../../partials/Footer";
import Header from "../../../partials/Header";
import BannerDemo from "../componentsHome/BannerDemo";
import Downloads from "./Downloads";
import DownloadsHeader from "./DownloadsHeader";
import { useEffect } from "react";

export default function DownloadsPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<>
        <Header></Header>
        <DownloadsHeader></DownloadsHeader>
        <Downloads></Downloads>
        <BannerDemo></BannerDemo>
        <Footer></Footer>
    </>
    )
}