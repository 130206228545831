import parse from "html-react-parser";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import mano from './../../../images/mano.png';

function GetTransactions04({ updateStep }) {
  const [t, i18n] = useTranslation("global");

  return (
    <main>

      <div className="min-h-screen h-full flex flex-col after:flex-1">


        <div className=" py-8">
          <div className="max-w-6xl mx-auto">

            <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("exceltransactions.title05")} ✨</h1>
            <div className="max-w-6xl mx-auto">
              {/* Form */}
              <form>
                <div className="space-y-3 mb-8">
                  <img alt='mano ok' className='rounded mx-auto d-block' src={mano}></img>
                  <h3 className="text-gray-800 mb-12 text-center">{parse(t("exceltransactions.subtitle05"))} </h3>

                </div>

                <div className="flex flex-wrap  gap-3 items-center justify-center">
                  <a className="btn sec-grey-background text-sm  text-white hover:no-underline" onClick={() => { updateStep(0) }}>{t("exceltransactions.newExcel")} </a>
                  <Link className="btn sec-green-background hover:sec-green-background text-white " to="/company/dashboardintranet">{t("exceltransactions.goDashboard")}</Link>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>





    </main>
  );
}

export default GetTransactions04;