import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { IdiomContext } from "../../../context/createcontext";
import Header from '../Header';
import Sidebar from '../Sidebar';
//import img from './../../../public/arbol4.svg';


export default function CompensationPaymentInformation() {
  const location = useLocation();
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div className="flex h-screen overflow-hidden">

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <div className="sm:flex sm:justify-between sm:items-center mb-8">

                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("dash.compensartitle")}</h1>
                  {/* <h2 className='text-xl md:text-2xl text-gray-800 font-bold pl-2'>$47,347.09</h2> */}
                </div>
              </div>

              <div className='sec-neutro-background'>
                <div className='container text-center'>
                  <img className="imgs" src="/arbol2.svg"></img>

                  <p>{t("dash.compensarinfo")}</p>
                  {location.state != null ? <><p>{t("dash.compensaerredir")} <a href={location.state.url} target="_blank" rel="noopener noreferrer" style={{textDecoration:"underline"}} >{t("dash.compensarenlace")} </a></p>
                  </> : ""}

                  <div className="flex items-center justify-between mt-5">
                    {/* <div className='container text-center '><Link to="/company/dashboardintranet" className="btn sec-grey-background text-sm text-white  hover:no-underline">Volver al área interna</Link></div> */}
                    <div className='container text-center '><Link to="/company/compensations" className="btn sec-green-background hover:sec-green-background text-white ml-auto">{t("dash.compensarver")} </Link></div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

    </>
  )
}
