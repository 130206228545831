import React from "react";
import { useTranslation } from 'react-i18next';

export default function tableGreatPackaging() {
  const [t, i18n] = useTranslation("global");
  const cert = process.env.REACT_APP_URL + '/pdf/applus_certificado_gp.pdf';

  const certBAM = process.env.REACT_APP_URL + "/gp_cert_BAM.jpg";
  const certVerra = process.env.REACT_APP_URL + "/gp_cert_verra.jpg";

  return (
    <div className="table-responsive rounded-lg my-3">
      {/* <table className=" table table-dark table-striped  bg-white text-xs table-auto w-full "> */}
      <table className=" table bg-white text-md table-auto w-full ">
        <thead className="border-b">
          <tr className="text-gp-green ">
            <th className="pl-2 text-md first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th1")}</th>
            <th className="pl-2 text-md first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th2")}</th>
            <th className="pl-2 text-md first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th3")}</th>
            <th className="pl-2 text-md first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th4")}</th>
            <th className="pl-2 text-md first:pl-5 last:pr-5 pl-3 py-3 text-center" style={{minWidth:'100px'}}>{t("gp.block4.table.th5")}</th>
            <th className="pl-2 text-md first:pl-5 last:pr-5 pl-3 py-3 text-center" >SKU</th>         
            <th className="pl-2 text-md first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th6")}</th>         
            <th className="pl-2 text-md first:pl-5 last:pr-5 pl-3 py-3 text-center" >{t("gp.block4.table.th7")}</th>

          </tr>
        </thead>
        <tbody className="text-md text-gp-lightgrey">
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center">847</td>
            <td className="text-center">Wong</td>
            <td className="text-center">
              <a
                href={certBAM}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(certBAM, "_blank");
                  window.open(certVerra, "_blank");
                }}
              >BAM Madre de Dios Amazon REDD+ Project</a>
            </td>
            <td className="text-center"><a href="https://registry.verra.org/app/projectDetail/VCS/868" target="_blank">{t("gp.block4.table.tdBosques")}</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center">847</td>
            <td className="text-center">Metro</td>
            <td className="text-center">
              <a
                href={certBAM}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(certBAM, "_blank");
                  window.open(certVerra, "_blank");
                }}
              >BAM Madre de Dios Amazon REDD+ Project</a>
            </td>
            <td className="text-center"><a href="https://registry.verra.org/app/projectDetail/VCS/868" target="_blank">{t("gp.block4.table.tdBosques")}</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center">847</td>
            <td className="text-center">Metro</td>
            <td className="text-center">
              <a
                href={certBAM}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(certBAM, "_blank");
                  window.open(certVerra, "_blank");
                }}
              >BAM Madre de Dios Amazon REDD+ Project</a>
            </td>
            <td className="text-center"><a href="https://registry.verra.org/app/projectDetail/VCS/868" target="_blank">{t("gp.block4.table.tdBosques")}</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center">847</td>
            <td className="text-center">Wong</td>
            <td className="text-center">
              <a
                href={certBAM}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(certBAM, "_blank");
                  window.open(certVerra, "_blank");
                }}
              >BAM Madre de Dios Amazon REDD+ Project</a>
            </td>
            <td className="text-center"><a href="https://registry.verra.org/app/projectDetail/VCS/868" target="_blank">{t("gp.block4.table.tdBosques")}</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center">847</td>
            <td className="text-center">Metro</td>
            <td className="text-center">
              <a
                href={certBAM}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(certBAM, "_blank");
                  window.open(certVerra, "_blank");
                }}
              >BAM Madre de Dios Amazon REDD+ Project</a>
            </td>
            <td className="text-center"><a href="https://registry.verra.org/app/projectDetail/VCS/868" target="_blank">{t("gp.block4.table.tdBosques")}</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center">847</td>
            <td className="text-center">Wong</td>  
            <td className="text-center">
              <a
                href={certBAM}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(certBAM, "_blank");
                  window.open(certVerra, "_blank");
                }}
              >BAM Madre de Dios Amazon REDD+ Project</a>
            </td>
            <td className="text-center"><a href="https://registry.verra.org/app/projectDetail/VCS/868" target="_blank">{t("gp.block4.table.tdBosques")}</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
