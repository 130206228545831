import format from 'date-fns/format';
import { default as React, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import BarChart from '../../charts/BarChart01Years';
import {formatThousands } from '../../utils/Utils'

// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function FootprintYears({ data }) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  let yearList = data?.map(item => item.year);

  const years = yearList?.filter((elemento, index, self) => elemento !== null && index === self.indexOf(elemento))
  .sort((a, b) => a - b);






  const totalKgCO2eList = data?.map(item => item.totalKgCO2e);


 

let totalesByYear=[]
 totalesByYear = data?.reduce((result, item) => {
    const { year, totalKgCO2e } = item;


    if (!result[year]) {
      result[year] = 0;
    }


    result[year] += totalKgCO2e;

    return result;
  }, []);

  const resultado = totalesByYear?.filter(total => typeof total === 'number').map(num => Number(num.toFixed(2)));

  const chartData = {
    labels: years,
    datasets: [
      {
        label: `${t("footPrint.total")} CO₂e (Kg)`,
        data: resultado,
        backgroundColor: tailwindConfig().theme.colors.indigo[400],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[700],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };


  const bgcolors = [tailwindConfig().theme.colors.blue[400], tailwindConfig().theme.colors.indigo[500], tailwindConfig().theme.colors.indigo[700], tailwindConfig().theme.colors.orange[400], tailwindConfig().theme.colors.gray[500]];
  const hbgcolors = [tailwindConfig().theme.colors.blue[500], tailwindConfig().theme.colors.indigo[600], tailwindConfig().theme.colors.indigo[800], tailwindConfig().theme.colors.orange[500], tailwindConfig().theme.colors.gray[600]];










  return (
    <div id="gdashboard04" className="flex flex-col col-span-full sm:col-span-6 bg-white rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">{t("footPrint.huellageneradaYears")}</h2>

      </header>


      {(!yearList?.length || !totalKgCO2eList?.length) ? (
        <Spinner className='flex justify-center' />
      ) : (

        <BarChart data={chartData} width={595} height={248} unit={" Kg"} />
      )}
    </div>

  );
}

export default FootprintYears;
