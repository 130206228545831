import React from 'react';
import { useCallback, useMemo, useRef, useState, useEffect, useContext } from 'react';
import { MapContainer, TileLayer, Marker, Popup, WMSTileLayer, Polygon, useMapEvents, LayerGroup, LayersControl, useMap, Rectangle, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import { Spinner } from 'react-bootstrap';

function mapa_catastro_multiple({ sendDataToParent, parcela2delete, mapStep, refCatParcelData, refCatParcelDataEdit,allParcelsEdit,formMonte }) {




  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");




  useEffect(() => {
    if (refCatParcelData != undefined) {
      let index = refCatParcelData.parcelCoords.indexOf(",");
      let lng = refCatParcelData.parcelCoords.substr(0, index);
      let lat = refCatParcelData.parcelCoords.substr(index + 1);
      const coords = { lat, lng };
      // if (refCatParcelData != clickParcela){
      // updLoc(lat+","+lng);
      // updLoc(coords);
      setPositionc({
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      });

      // }
    }
  }, [refCatParcelData]);

  //Componente que hace el zoom a las coordenadas del poligono de la parcela
  function Test({ location }) {
    const map = useMap();
    if (location) map.flyTo(location, 16);
    return null;
  }

  const customMarker = new L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40]
  });

  //Coords oficina 
  let center = {
    lat: 42.432053442143165,
    lng: -8.645596205501745,
  }


  let centeredit = {}

  if (refCatParcelDataEdit && refCatParcelDataEdit?.parcelCoords) {
    const coordsArrayEdit = refCatParcelDataEdit.parcelCoords.split(',');
    const longitudEdit = coordsArrayEdit[0];
    const latitudEdit = coordsArrayEdit[1];
    centeredit = {
      lat: parseFloat(latitudEdit),
      lng: parseFloat(longitudEdit),
    };



  }





  //Coordenadas para los maxBounds del mapa principal
  const outer = [
    [26.43122806450644, -23.728142674836377],
    [49.55372551347579, 14.108771387663646]
  ]
  const limeOptions = { color: 'lime' }

  //newBounds guarda las coordenadas que tendrá el cuadrado para setear el mapa2
  const [newBounds, setNewBounds] = useState(null);
  //Posición que tendrá el Marker
  const [positionc, setPositionc] = useState(center);
  //Guarda los objetos que devuelve el endpoint al hacer click en el mapa
  const [clickParcela, setClickParcela] = useState(null);
  //Array que llega desde el padre con los objetos correctos tras borrar alguna parcela del formulario
  const [newArray, setNewArray] = useState(parcela2delete);


  const [allParcels, setAllParcels] = useState([]);

  //Guardar el array que llega del padre
  useEffect(() => {
    setNewArray(parcela2delete);
  }, [parcela2delete])

  if (newArray != null) {
    setAllParcels(newArray);
    sendDataToParent(newArray);
    setNewArray(null);
  }

  function DraggableMarker() {

    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPositionc(marker.getLatLng())
          }
        },
      }),
      [],
    )
    //Coordenadas del cuadrado a dibujar a partir de la posicion que tendrás el Marker
    const autoRectangle = new Array(2);
    autoRectangle[0] = [positionc.lat - 0.004, positionc.lng - 0.005];
    autoRectangle[1] = [positionc.lat + 0.004, positionc.lng + 0.005];


    return (
      <>
        <Marker
          icon={customMarker}
          draggable="true"
          eventHandlers={eventHandlers}
          position={positionc}
          ref={markerRef}>
          <Popup minWidth={90}>
            <span className="text-center">Asegúrate de que todas tus parcelas están dentro del cuadrado👇</span>
            <br /><br />
          </Popup>
        </Marker>
        <Rectangle bounds={autoRectangle} />
      </>
    )
  }


  //Hook que setea las coordenadas del cuadrado igual que el DraggableMarker, para poder usarlas de maxBounds con el nuevo mapa
  useEffect(() => {
    const autoRectangle = new Array(2);
    autoRectangle[0] = [positionc.lat - 0.004, positionc.lng - 0.005];
    autoRectangle[1] = [positionc.lat + 0.004, positionc.lng + 0.005];
    setNewBounds(autoRectangle);
  }, [positionc]);


  //Actualiza el array con todas las parcelas y la envía al padre
  useEffect(() => {
    if (clickParcela != null) {
      let clickParcelaEdit = clickParcela;
      clickParcelaEdit.polygonCoordinates = setCoordenadas(clickParcela);

      if (allParcels.length > 0) {
        if (allParcels.map(e => e.cadastralReference).indexOf(clickParcelaEdit.cadastralReference) === -1) {
          setAllParcels(allParcels.concat(clickParcelaEdit));
          sendDataToParent(allParcels.concat(clickParcelaEdit));
        }
      } else {
        setAllParcels([clickParcelaEdit]);
        sendDataToParent([clickParcelaEdit]);
      }
    }
    // sendDataToParent(allParcels);
  }, [clickParcela]);



  function getInfo(position, bbox) {
    fetch(`${process.env.REACT_APP_CATASTRO_BBOX_COORDS}` + bbox + "/Coordenada_X=" + position.lng + "&Coordenada_Y=" + position.lat, {
      method: "GET",
      headers: {
        "Accept": "*",
        "Content-Type": "application/json",
        "Allow": "*",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((clickParcela) => {
        if (!clickParcela.error) {
          // allParcels.push(clickParcela);
          setClickParcela(clickParcela);
        }
      });
  }

  function setCoordenadas(clickParcela) {
    var arraycoords = (clickParcela.polygonCoordinates).replaceAll(" ", ",");
    var string = arraycoords.split(',');
    // Create array of float for each pair of coordinate
    var a = string.length;
    for (let i = 0; i < a; i++) {
      string[i] = parseFloat(string[i]);
    }
    // Initialize an array to store the new values
    var b = string.length / 2;
    var array = [];
    for (let x = 0; x < b; x++) {
      array[x] = [0, 0];
    }
    // Create an array of array of coordinates
    var k = 0;
    for (let i = 0; i < b; i++) {
      for (let j = 0; j < 2; j++) {
        array[i][j] = string[k];
        k++;
      }
    }
    return array;
  }

  //Componente que por evento onClick recibe las coordenadas dadas y llama a la función que tiene el endpoint para devolver el objeto con la info del poligono
  const MapEvents = () => {
    useMapEvents({
      click(e) {
        var position = {
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        };
        let x1 = position.lat - 0.00025;
        let y1 = position.lng - 0.00025;
        let x2 = position.lat + 0.00025;
        let y2 = position.lng + 0.00025;
        var bbox = x1 + "," + y1 + "," + x2 + "," + y2;
        getInfo(position, bbox);
      },
    });
    return false;
  }


  //carga del mapa si esta editando o si es el segundo paso 
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    if (formMonte || refCatParcelDataEdit) {
      setIsFetching(true);
    }
    
  }, [formMonte, refCatParcelDataEdit]);



  return (


    <>
      {/* MAPA PRIMER PASO */}
      {mapStep && (
        <MapContainer
          center={{ lat: 40.4035, lng: -3.7283 }}
          zoom={6}
          zoomControl={true}
          minZoom="5"
          maxBounds={outer}
          maxZoom="20"
          className="z-0 mb-4 map">




          <LayersControl position="topright">
            <LayersControl.BaseLayer name="Callejero">
              <LayerGroup>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  maxZoom="20"
                />

                <WMSTileLayer
                  url="https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx"
                  format='image/png'
                  transparent="false"
                  minZoom="14"
                  maxZoom="20"
                  opacity="0.4"
                />
              </LayerGroup>
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satélite" checked>
              <LayerGroup>
                <TileLayer
                  attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                  url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                  maxZoom="20"
                />

                <WMSTileLayer
                  url="https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx"
                  format='image/png'
                  transparent="false"
                  minZoom="14"
                  maxZoom="20"
                  opacity="0.4"
                />
              </LayerGroup>
            </LayersControl.BaseLayer>
          </LayersControl>

          {refCatParcelData  != undefined ?
            <Test location={positionc} />
            : ""
          }

          <DraggableMarker />

        </MapContainer>
      )}
      {/* MAPA EDITAR */}
      {(!mapStep && refCatParcelDataEdit) && (
        <>
          {centeredit && isFetching&&allParcelsEdit ? (
            <>
              <p className='font-medium'>{t("formMonte.multi_instructionsEdit")}</p>


              <MapContainer
                center={centeredit}
                zoom={16}
                zoomControl={true}
                minZoom="16"
                // maxBounds={newBounds}
                maxZoom="20"
                className="z-0 mb-4 map">


                <LayersControl position="topright">
                  <LayersControl.BaseLayer name="Callejero" >
                    <LayerGroup>
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxZoom="20"
                      />

                      <WMSTileLayer
                        url="https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx"
                        format='image/png'
                        transparent="false"
                        minZoom="14"
                        maxZoom="20"
                        opacity="0.4"
                      />
                    </LayerGroup>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satélite" checked>
                    <LayerGroup>
                      <TileLayer
                        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        maxZoom="20"
                      />

                      <WMSTileLayer
                        url="https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx"
                        format='image/png'
                        transparent="false"
                        minZoom="14"
                        maxZoom="20"
                        opacity="0.4"
                      />
                    </LayerGroup>
                  </LayersControl.BaseLayer>
                </LayersControl>


                <MapEvents />


                {allParcels.length > 0 ?
                  allParcels.map((v, i) => {
                    return (
                      <Polygon pathOptions={limeOptions} positions={v.polygonCoordinates} >
                        <Tooltip direction="bottom" offset={[0, 0]} opacity={0.5} >
                          {i + 1}
                        </Tooltip>
                      </Polygon>
                    );
                  })
                  :
                  <></>}
                   {allParcelsEdit.length > 0 ?
                  allParcelsEdit.map((v, i) => {
                    return (
                      <Polygon pathOptions={limeOptions} positions={v.polygonCoordinates} >
                        <Tooltip direction="bottom" offset={[0, 0]} opacity={0.5} >
                          {i + 1}
                        </Tooltip>
                      </Polygon>
                    );
                  })
                  :
                  <></>}
                {/* <DraggableMarker />  */}


              </MapContainer>

            </>
          ) : (

            <Spinner animation="border" ></Spinner>
          )}

        </>)}
      {/* MAPA NO EDITAR, AÑADIR HIJAS NUEVAS */}
      {(!mapStep && !refCatParcelDataEdit) && (
        <>
          {isFetching ? (
            <>
              <p className='font-medium'>{t("formMonte.multi_instructions5")}<a href='#form_multimonte' className='font-semibold underline'>{t("formMonte.multi_instructions6")}</a>{t("formMonte.multi_instructions7")}</p>
              <MapContainer
                center={positionc}
                zoom={16}
                zoomControl={true}
                minZoom="16"
                maxBounds={newBounds}
                maxZoom="20"
                className="z-0 mb-4 map">


                <LayersControl position="topright">
                  <LayersControl.BaseLayer name="Callejero" >
                    <LayerGroup>
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxZoom="20"
                      />

                      <WMSTileLayer
                        url="https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx"
                        format='image/png'
                        transparent="false"
                        minZoom="14"
                        maxZoom="20"
                        opacity="0.4"
                      />
                    </LayerGroup>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satélite" checked>
                    <LayerGroup>
                      <TileLayer
                        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        maxZoom="20"
                      />

                      <WMSTileLayer
                        url="https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx"
                        format='image/png'
                        transparent="false"
                        minZoom="14"
                        maxZoom="20"
                        opacity="0.4"
                      />
                    </LayerGroup>
                  </LayersControl.BaseLayer>
                </LayersControl>


                <MapEvents />


                {allParcels.length > 0 ?
                  allParcels.map((v, i) => {
                    return (
                      <Polygon pathOptions={limeOptions} positions={v.polygonCoordinates} >
                        <Tooltip direction="bottom" offset={[0, 0]} opacity={0.5} >
                          {i + 1}
                        </Tooltip>
                      </Polygon>
                    );
                  })
                  :
                  <></>}
                {/* <DraggableMarker />  */}


              </MapContainer>
            </>
          ) : (

            <Spinner animation="border" ></Spinner>
          )}

        </>)}




    </>



  )

}
export default mapa_catastro_multiple;