import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import UserService from '../../../services/UserService';
import Button from 'react-bootstrap/Button';
import { HashLink } from 'react-router-hash-link';

export function StripeButton() {

    const [t, i18n] = useTranslation("global");
    const [infoProvider, setInfoProvider] = useState({ data: {}, isFetching: false });
    const [stripeAccountGet, setStripeAccountGet] = useState({ data: {}, isFetching: false });

    const [newAccountStripe, setNewAccountStripe] = useState({
        newAccountStripeData: [],
        isFetching: false
    });

    const [stripeDashboard, setStripeDashboard] = useState({
        stripeDashboardData: [],
        isFetching: false
    });

    const [disabledButton, setDisabledButton] = useState(false);
    const [hidenButton, setHidenButton] = useState(false);
    const [disabledLink, setDisabledLink] = useState(false);



    const [showModal, setShowModal] = useState(false);

    const handleClose = () => { setShowModal(false); }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_MP_PRIVATE_PROVIDER}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${UserService.getToken()}`
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((infoProvider) => {
                if (!infoProvider.error) {
                    setInfoProvider({ data: infoProvider, isFetching: true });
                }
            });
    }, [newAccountStripe])


    useEffect(() => {
        if (infoProvider.data.stripeId != null) {

            let params = "?update=airco2&";
            if (UserService.getIban() != null && UserService.getIban() != "") {
                params += "&iban=" + UserService.getIban();
            }
            if (UserService.getDpostal() != null && UserService.getDpostal() != "") {
                params += "&line=" + UserService.getDpostal();
            }
            if (UserService.getCodigoPostal() != null && UserService.getCodigoPostal() != "") {
                params += "&pc=" + UserService.getCodigoPostal();
            }
            if (UserService.getMunicipio() != null && UserService.getMunicipio() != "") {
                params += "&city=" + UserService.getMunicipio();
            }

            fetch(`${process.env.REACT_APP_MP_PAYMENT_EXPRESS}${params}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            })
                .then((response) => {
                    return response.json();
                })
                .then((stripeAccountGet) => {
                    if (!stripeAccountGet.error) {
                        setStripeAccountGet({ data: stripeAccountGet, isFetching: true });
                    }
                });
        } else {
            setStripeAccountGet({ data: {}, isFetching: true });

        }
    }, [infoProvider])



    const handleClick = (e) => {
        e.preventDefault();
        setDisabledButton(true)
        const fetchStripeUrl = async () => {
            try {
                let params = "?alta=airco2&";
                if (UserService.getIban() != null && UserService.getIban() != "") {
                    params += "&iban=" + UserService.getIban();
                }
                if (UserService.getDpostal() != null && UserService.getDpostal() != "") {
                    params += "&line=" + UserService.getDpostal();
                }
                if (UserService.getCodigoPostal() != null && UserService.getCodigoPostal() != "") {
                    params += "&pc=" + UserService.getCodigoPostal();
                }
                if (UserService.getMunicipio() != null && UserService.getMunicipio() != "") {
                    params += "&city=" + UserService.getMunicipio();
                }

                setNewAccountStripe({ newAccountStripe: newAccountStripe.newAccountStripeData, isFetching: true });
                const response = await axios.post(`${process.env.REACT_APP_MP_PAYMENT_EXPRESS}${params}`, {}, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${UserService.getToken()}`
                    }
                });
                if (response.status == "200") {
                    window.open(response.data, '_blank');
                    setHidenButton(true)
                }
                setNewAccountStripe({ newAccountStripe: response.data, isFetching: false });
                setDisabledButton(false);

            } catch (exception) {
                setNewAccountStripe({ newAccountStripe: newAccountStripe.newAccountStripeData, isFetching: false });
                setDisabledButton(false);

            }
        };
        fetchStripeUrl();
    }



    const handleRedirectStripeDashboard = (e) => {
        e.preventDefault();
        setDisabledLink(true)
        const fetchStripeDashboard = async () => {
            try {
                setStripeDashboard({ stripeDashboard: stripeDashboard.stripeDashboardData, isFetching: true });
                const response = await axios.get(`${process.env.REACT_APP_MP_PAYMENT_DASHBOARD}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${UserService.getToken()}`
                    }
                });
                if (response.status == "200") {
                    window.open(response.data, '_blank');
                }
                setStripeDashboard({ stripeDashboard: response.data, isFetching: false });

            } catch (exception) {
                setStripeDashboard({ stripeDashboard: stripeDashboard.stripeDashboardData, isFetching: false });
            }
            setDisabledLink(false);
        };
        fetchStripeDashboard();
    }



    return <>
        {!stripeAccountGet.isFetching || !infoProvider.isFetching && <span> <Spinner animation="border" className='flex justify-center' /></span>}
        {stripeAccountGet.isFetching && infoProvider.isFetching && <>
            {infoProvider.data.stripeId == null || !infoProvider.data.stripeSubmited || !infoProvider.data.stripeChargesEnabled || stripeAccountGet.data.length == 0 ?
                <> {!hidenButton && <p>{t("marketplace.banner_forest_stripe0")}
                    <button arial-label="btn-saveForm" className="btn-sm btn-green ml-2 rounded-lg  " onClick={() => setShowModal(!showModal)}>{t("marketplace.banner_forest_stripe1")}</button>
                </p>}
                    {hidenButton && <p>{t("marketplace.recargar_texto")}
                        <button arial-label="btn-saveForm" className="btn-sm btn-green ml-2 rounded-lg  " disabled={disabledButton} onClick={() => { window.location.reload(); setDisabledButton(true); }}>{t("marketplace.recargar")}</button>
                    </p>}
                </>

                : <button className=' hover:text-blue-600' onClick={handleRedirectStripeDashboard} disabled={disabledLink}>
                    {!disabledLink ? t("marketplace.banner_forest_stripe2") : t("marketplace.banner_forest_stripe_link")}
                </button>
            }</>
        }




        {/* //modal pasar provider a marketplace */}
        < Modal show={showModal} >
            <Modal.Header>
                <Modal.Title>Información sobre Stripe</Modal.Title>
                <div className="flex justify-end">
                    <button onClick={() => handleClose()}>
                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
            {UserService.getIban() == "" || UserService.getIban() == undefined || UserService.getDpostal == "" || UserService.getDpostal() == undefined || UserService.getMunicipio() == "" || UserService.getMunicipio() == undefined || UserService.getMunicipio() == "undefined" ?
                    <p>{t("marketplace.modal_info_stripe-0")} <HashLink to="/account/account">{t("account.mi-cuenta")}</HashLink>.</p> : <div>
                        <p>{t("marketplace.modal_info_stripe-1")}
                            <br /><br />
                            {t("marketplace.modal_info_stripe-2")}
                            <br /><br />
                            {t("marketplace.modal_info_stripe-3")}
                            <br /><br />
                            {t("marketplace.modal_info_stripe-4")}
                            <br /><br />
                        </p>
                        <div>
                            <button disabled={disabledButton} arial-label="btn-saveForm" className="btn-sm btn-green mt-3 mx-auto rounded-lg  " onClick={handleClick}>
                                {disabledButton ? <span>  <Spinner animation="border" className='flex justify-center' /></span> : t("marketplace.banner_forest_stripe1")}</button>
                        </div>
                    </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button className=" text-xs btn btn-dashboard  bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={handleClose}>
                    {t("marketplace.form_close")}
                </Button>

            </Modal.Footer>

        </Modal >
    </>
}