import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import comparativeService from '../../../images/comparativeService.svg';
import UserService from '../../../services/UserService';
import { formatThousands } from '../utils/Utils';

export default function BannerSectorComparation({ facturacion, totalCO2e, sectorId }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [datoMediaSector, setDatosetDatoMedia] = useState(undefined);
    const [diffPercentage, setDiffPercentage] = useState(undefined);



    useEffect(() => {
        const fetchSectorData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_CNAE_SECTORS}/subsector/${sectorId}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setDatosetDatoMedia(response.data.data);
                var totalCO2eSector = facturacion * response.data.data.value
                var diff = totalCO2e - totalCO2eSector;
                var average = (totalCO2e + totalCO2eSector) / 2;
                setDiffPercentage((diff / average) * 100)
            } catch (exception) {
                console.error(exception);
                setDatosetDatoMedia({});
            }
        };
        if (sectorId !== undefined) {
            fetchSectorData();
        }
    }, []);

    return (<div className="bg-white rounded-sm border border-gray-200 py-4 px-3" >
        <div className=" flex flex-col lg:flex-row justify-between items-center">
            <div className="flex justify-center" style={{ flex: "1" }}>
                <img src={comparativeService} width={100}></img>
            </div>
            {facturacion !== undefined && datoMediaSector !== undefined &&
                <div className="txt text-lg  justify-center" style={{ flex: "2" }}>
                    <h3 className="ml-4   mb-2 " >
                        <p className='mb-2'>{t("footPrint.huellaEquivaleSector1")} <span className='font-bold'>{datoMediaSector.name}</span>
                            {t("footPrint.huellaEquivaleSector2")}
                            <span className='font-bold'>{formatThousands(diffPercentage)}%</span>  {diffPercentage > (0) ? t("footPrint.superior") : t("footPrint.inferior")}.</p>
                        <p className='italic text-xs'>{t("footPrint.equivaleFuenteSector")}</p>
                    </h3>
                </div>
            }
            {facturacion === undefined && <div className=' justify-center font-italic' style={{ flex: "2" }}>
                {t("footPrint.huellaEquivaleSectorNoBillingData")}
            </div>
            }

            {sectorId === undefined && <div className=' justify-center font-italic' style={{ flex: "2" }}>
                {t("footPrint.huellaEquivaleSectorNoSectorData")}
            </div>
            }
        </div>
    </div>
    )
}
