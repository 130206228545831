
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IdiomContext } from "../../../context/createcontext";
import { useTour } from '../../../context/TourContext';
import processing from '../../../images/undraw_processing.svg';
import { customButtonStylesTourTooltip } from '../../../services/Functions';
import translations from '../../../services/Translations';
import UserService from '../../../services/UserService';
import steps from '../data/TourSteps';
import Header from '../Header';
import Sidebar from '../Sidebar';
import AnswerTable from './AnswerTable';
import BannerRecalculate from './BannerRecalculate';
import InfoForm from './InfoForm';
import OptionForm from './OptionForm';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ModalFinishCalculation from './ModalFinishCalculation';

import logo from '../../../images/Logo verde_airCO2.png'


export default function Form() {

    const { setStepIndex, startTour, stopTour, isTourRunning, currentStepIndex } = useTour();

    useEffect(() => {
        if (!isTourRunning && currentStepIndex == 12) {
            startTour();
        }
    }, [currentStepIndex])

    const handleDataFromHeader = (data) => {
        if (data == true) {
            setStepIndex(12);
        }
    }

    const handleJoyrideCallback = (data) => {
        const { action, index, type, status, step } = data;

        let mainDivFormPage = document.getElementById('mainDivFormPage');
        let hasOverflowStyle = window.getComputedStyle(mainDivFormPage).getPropertyValue('overflow');
        if (hasOverflowStyle) {
            mainDivFormPage.style.removeProperty('overflow');
        }

        // Update step index on step change
        if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
            setStepIndex(index + 1);
        } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
            setStepIndex(index - 1);
        } else if (ACTIONS.CLOSE === action) {
            stopTour();
            setStepIndex(0);
        }

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            stopTour();
            setStepIndex(0);
        }
    };


    const [lng, updateLng] = useContext(IdiomContext)
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const startRef = useRef(null);
    const location = useLocation();
    const locale = translations.translationsTourTooltip(lng) || translations.translationsTourTooltip("es");
    const [openScopes, setOpenScopes] = useState({ 1: true, 2: true, 3: true });
    const [openCategories, setOpenCategories] = useState();
    const [groupedQuestions, setGoupedQuestions] = useState();
    const [orderedQuestions, setOrderedQuestions] = useState();



    let calculationOutputDto = location?.state?.calculationOutputDto
    if (location && location.state) {
        calculationOutputDto = location.state.calculationOutputDto;
    }

    let adminUser = ''
    if (location && location.state) {
        adminUser = location.state.user;
    }



    if (location.state == undefined) {
        window.location.href = '/footprint/myfootprint';
    }



    const status = (location?.state?.calculationOutputDto?.data?.calculationStatus)
    const year = (location?.state?.calculationOutputDto?.data?.year)
    const orgName = (location?.state?.calculationOutputDto?.data?.organization?.name)
    const idCalculation = (location?.state?.calculationOutputDto?.data?.id)
    const thirdPartyForm = (location?.state?.calculationOutputDto?.data?.thirdPartyForm)
    const orgId = (location?.state?.calculationOutputDto?.data?.organization?.id)
    const userId = (location?.state?.calculationOutputDto?.data?.userId)

    const [questResume, setQuestResume] = useState({
        data: [],
        isFetching: true
    });

    const [questBlock, setQuestBlock] = useState({
        data: [],
        isFetching: true
    });

    const [idQuestion, setIdQuestion] = useState("");
    const [newRow, setNewRow] = useState();
    const [triggerIdQuestionFetch, setTriggerIdQuestionFetch] = useState(true);
    const [modalFinalizarCalculo, setModalFinalizarCalculo] = useState({ view: false, idCalculation: null, idOrganization: null });
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [optionsSelectedBlock, setOptionsSelectedBlock] = useState(null);

    const [selectedBlockid, setSelectedBlockid] = useState(null)
    const [viewFinishCalculation, setViewFinishCalculation] = useState(true)
    const [viewFinishCalculationCurrent, setViewFinishCalculationCurrent] = useState(false)

    const [isButtonDisabled, setIsButtonDisabled] = useState(false)


    const sendDataToParent = (props) => {
        setIdQuestion(props);
    };

    const addAnswer = (props) => {
        setNewRow(props);
        let updateHasAnswer = selectedBlock;
        updateHasAnswer.hasAnswers = true;
        setSelectedBlock(updateHasAnswer);
    };

    const setNoAnswer = () => {
        setNewRow();
        let updateHasAnswer = selectedBlock;
        updateHasAnswer.hasAnswers = false;
        setSelectedBlock(updateHasAnswer);
    };

    useEffect(() => {
        if (idQuestion !== "") {
            setTriggerIdQuestionFetch(!triggerIdQuestionFetch)
        } else if (idQuestion == "reset") {

            setTriggerIdQuestionFetch(!triggerIdQuestionFetch)
        }
    }, [idQuestion])

    useEffect(() => {
        const fetchQuestResume = async () => {
            const url = `${process.env.REACT_APP_CH_QUESTIONNAIRE}/calculation/${calculationOutputDto.data.id}/resume`

            try {
                setQuestResume({ data: [], isFetching: true });
                const response = await axios.get(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setQuestResume({ data: response.data, isFetching: false });
            } catch (exception) {
                setQuestResume({ data: [], isFetching: false });
            }

        };
        fetchQuestResume();
    }, []);



    useEffect(() => {
        if (questResume.data?.data) {
            const questions = questResume?.data?.data?.questions
                .sort((a, b) => {
                    if (a.categoryPosition === b.categoryPosition) {
                        return a.position - b.position;
                    }
                    return a.categoryPosition - b.categoryPosition;
                })
                .map((question, index) => ({
                    ...question,
                    position: index // Asignar el nuevo `position` secuencialmente
                }));

            setOrderedQuestions(questions)

            const initialSelectedBlock = questions[0];
            setSelectedBlock(initialSelectedBlock);

            const initialOpenCategories = questions?.reduce((acc, question) => {
                acc[question.categoryId] = true;
                return acc;
            }, {});

            setOpenCategories(initialOpenCategories);

            const groupedInitialQuestions = questions?.reduce((acc, question) => {
                const scope = question.scope;

                if (!acc[scope]) {
                    acc[scope] = [];
                }

                const categoryIndex = acc[scope].findIndex(
                    category => category.categoryId === question.categoryId
                );

                if (categoryIndex === -1) {
                    // Añadimos una nueva categoría
                    acc[scope].push({
                        categoryId: question.categoryId,
                        categoryName: question.categoryName,
                        categoryIcon: question.categoryIcon,
                        questions: [question],
                    });
                } else {
                    // Añadimos la pregunta a una categoría existente
                    acc[scope][categoryIndex].questions.push(question);
                }

                return acc;
            }, {});
            setGoupedQuestions(groupedInitialQuestions);

        }

    }, [questResume]);





    useEffect(() => { //Para cambiar los RADIOBUTTONS
        if (selectedBlockid !== optionsSelectedBlock?.questionBlockId) {
            if (questBlock?.data?.data?.inputs?.length === 1 &&
                optionsSelectedBlock !== questBlock?.data?.data?.inputs[0]?.input?.options) {
                setOptionsSelectedBlock({
                    options: questBlock?.data?.data?.inputs[0]?.input?.options,
                    questionBlockId: selectedBlockid
                })
            } else {
                setOptionsSelectedBlock({ options: [], questionBlockId: 0 })
            }
        }

    }, [selectedBlockid]);


    const handleButtonClick = (x) => {
        setSelectedBlock(x);
        setIdQuestion("");
    };

    const handleNextPreviousButtons = (x) => {
        let indexQuestions = orderedQuestions.findIndex(item => item.id === selectedBlockid);
        if (x === "previous") {
            if (!(indexQuestions <= 0)) {
                setSelectedBlock(orderedQuestions[indexQuestions - 1]);
                setIdQuestion("");
            }
        } else if (x === "next") {
            if (!(indexQuestions >= orderedQuestions.length - 1)) {
                setSelectedBlock(orderedQuestions[indexQuestions + 1]);
                setIdQuestion("");
            }
        } else if ((x === "current")) {
            setViewFinishCalculationCurrent(true);

        } else {
            status === "DRAFT" && setModalFinalizarCalculo({ view: true, idCalculation: idCalculation, idOrganization: orgId });
        }
        // startRef.current.scrollIntoView({ behavior: 'smooth' });


    };

    const resetQuestionBlock = () => {
        setSelectedBlock(selectedBlock);
        setIdQuestion("");
        setTriggerIdQuestionFetch(!triggerIdQuestionFetch)
    }


    const updateViewFromParent = (props) => {
        setModalFinalizarCalculo({ view: false, idCalculation: null, idOrganization: null });

    }





    useEffect(() => {
        const fetchQuestBlock = async () => {

            const url = `${process.env.REACT_APP_CH_QUESTIONNAIRE}/calculation/${calculationOutputDto.data.id}/questionblock/${selectedBlock.id}?idQuestion=${idQuestion == "reset" ? "" : idQuestion}`
            try {
                setQuestBlock({ data: [], isFetching: true });
                const response = await axios.get(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setQuestBlock({ data: response.data, isFetching: false });
                if (response.data.data.id === 0) {
                    setIdQuestion(response.data.data.inputs[0].input.options[0].value);

                }
                setSelectedBlockid(selectedBlock.id)
            } catch (exception) {
                setQuestBlock({ data: [], isFetching: false });
            }

        };
        if (selectedBlock && selectedBlock !== null) {
            fetchQuestBlock();
        }
    }, [selectedBlock, triggerIdQuestionFetch]);

    const activePostButton = (data) => {
        setIsButtonDisabled(data);
    }



    const renderTooltipFormularioTercerosNoDisponible = (props) => (
        <Tooltip id="button-tooltip" {...props}>

            {!thirdPartyForm ? t("fpThird.formContactsNoAvalaible") : t("fpThird.huellaTercerosTxt")}

        </Tooltip>
    );
    const renderTooltipMiscalculos = (props) => (
        <Tooltip id="button-tooltip" {...props}>

            {t("sidebar.miscalculos")}

        </Tooltip>
    );
    const renderTooltipNext = (props) => (
        <Tooltip id="button-tooltip" {...props}>

            {t("fpThird.siguientePregunta")}

        </Tooltip>
    );
    const renderTooltipPrevious = (props) => (
        <Tooltip id="button-tooltip" {...props}>

            {t("fpThird.preguntaAnterior")}

        </Tooltip>
    );
    const renderTooltipFinish = (props) => (
        <Tooltip id="button-tooltip" {...props}>

            {t("footPrint.Finalizar")}

        </Tooltip>
    );

    const renderTooltipReducction = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.reductionCommitment")}
        </Tooltip>
    )




    const toggleScope = (scope) => {
        setOpenScopes(prevState => ({
            ...prevState,
            [scope]: !prevState[scope]
        }));
    };

    const toggleCategory = (category) => {
        setOpenCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(window.matchMedia(query).matches);

        useEffect(() => {
            const mediaQueryList = window.matchMedia(query);
            const documentChangeHandler = () => setMatches(mediaQueryList.matches);
            mediaQueryList.addListener(documentChangeHandler);

            return () => {
                mediaQueryList.removeListener(documentChangeHandler);
            };
        }, [query]);

        return matches;
    };
    const desktop = useMediaQuery('(min-width: 768px)');

    const [questionsMenu, setquestionsMenu] = useState(desktop ? true : true)
    const [instructionsPanelOpen, setInstructionsPanelOpen] = useState({ open: false, fixed: false });
    useEffect(() => {
        if (!desktop) {
            setInstructionsPanelOpen({ open: false, fixed: false })
        } else {
            setInstructionsPanelOpen({ open: false, fixed: true })
        }

    }, [desktop])

    const [questionsPanelOpen, setQuestionsPanelOpen] = useState({ open: false, fixed: false });
    useEffect(() => {
        if (!desktop) {
            setQuestionsPanelOpen({ open: false, fixed: false })
        } else {
            setQuestionsPanelOpen({ open: true, fixed: true })
        }

    }, [desktop])

    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />



            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden" id="mainDivFormPage">


                {/*  Site header */}

                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} triggerTour={handleDataFromHeader} />
                {
                    questResume.isFetching && questBlock.isFetching ?

                        <div className=" h-screen flex justify-center items-center sec-neutro-background ">
                            <div className="relative ">
                                <img src={logo} alt="logo" className='mx-auto' style={{ width: "323px" }} />

                                <div className="text-center text-xl absolute font-semibold sec-grey-text mb-4" style={{ top: "150px", left: "85px" }}>
                                    CarbonManager
                                </div>

                                <div className="flex justify-center items-center absolute" style={{ top: "85px", left: "191px" }}>
                                    <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <main className='flex flex-col md:flex-row w-full justify-between relative' style={{ height: desktop ? 'calc(100vh - 64px)' : '' }}>

                            {/*  Panel PREGUNTAS */}
                            {!questionsPanelOpen.open &&
                                <span className='italic text-xs flex items-center ' id='footprint-description-section'
                                    onClick={() => setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: true })}>
                                    <div className='flex pb-2 pl-1 gap-1 items-center'>
                                        {desktop && <button onClick={() => setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: true })}
                                            onMouseEnter={() => !questionsPanelOpen.open && setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false })}
                                            className="flex flex-row items-center"
                                        >
                                            <p className="text-xs pl-1" style={{ writingMode: "vertical-rl", transform: "rotate(180deg)", whiteSpace: "nowrap" }}>
                                                {t("footPrint.datosDeConsumo")}
                                            </p>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-right"
                                                width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M7 7l5 5l-5 5" />
                                                <path d="M13 7l5 5l-5 5" />
                                            </svg>

                                        </button>}
                                        {!desktop &&
                                            <span className='font-bold text-sm flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-right"
                                                    width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M7 7l5 5l-5 5" />
                                                    <path d="M13 7l5 5l-5 5" />
                                                </svg>
                                                {t("footPrint.datosDeConsumo")}</span>
                                        }
                                    </div>
                                </span>
                            }
                            {questionsPanelOpen.open &&
                                <div id='footprint-menu' className='pt-2  bg-white border sidebar-airco2 relative'
                                    style={{ width: desktop ? '15%' : '100%', height: desktop ? '100%' : '', overflowY: desktop ? 'scroll' : 'hidden' }}
                                    onMouseLeave={() => { questionsPanelOpen.open && !questionsPanelOpen.fixed && setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false }) }}
                                    onClick={() => { questionsPanelOpen.open && !questionsPanelOpen.fixed && setQuestionsPanelOpen({ open: true, fixed: true }) }}>

                                    {desktop && <div className='italic text-xs flex items-center justify-start mr-2 fixed z-10 pl-2' id='footprint-description-section'>
                                        <button onClick={() => setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false })} >

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-left" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M11 7l-5 5l5 5" />
                                                <path d="M17 7l-5 5l5 5" />
                                            </svg>
                                        </button>
                                    </div>}

                                    {!desktop &&
                                        <div className='flex pb-2 pl-1 gap-1 items-center'>
                                            <button onClick={() => setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false })}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-left" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M11 7l-5 5l5 5" />
                                                    <path d="M17 7l-5 5l5 5" />
                                                </svg>
                                            </button>
                                            <span className='font-bold text-sm'>{t("footPrint.datosDeConsumo")}</span>
                                        </div>
                                    }

                                    <ul className='footprint-menu-vertical  pt-4' style={{ width: '100%' }}>
                                        {(questionsMenu || desktop) &&
                                            groupedQuestions && Object.keys(groupedQuestions).map(scope => (
                                                <li key={scope} className='mb-3 pt-1 pb-1' style={{ borderBottom: "1px solid lightgrey" }}>
                                                    <div
                                                        className=" font-bold text-md pl-1 cursor-pointer ml-2"
                                                        onClick={() => toggleScope(scope)}
                                                    >
                                                        <span className='flex items-center gap-1 justify-between'>
                                                            <span>{`Alcance ${scope}`} </span>
                                                            {openScopes[scope] ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-up" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M6 15l6 -6l6 6" />
                                                                </svg>
                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-down" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M6 9l6 6l6 -6" />
                                                                </svg>
                                                            }
                                                        </span>
                                                    </div>
                                                    {openScopes[scope] && (
                                                        <ol>
                                                            {groupedQuestions[scope].map(category => (<li key={category.id}
                                                                className="py-2 text-sm">
                                                                <div
                                                                    className=" font-bold pl-1 cursor-pointer ml-2"
                                                                    onClick={() => toggleCategory(category.categoryId)}
                                                                >
                                                                    <span className='flex items-center gap-1 justify-between pr-1 '>
                                                                        <div className='flex items-center gap-1'><span dangerouslySetInnerHTML={{ __html: category.categoryIcon }} />
                                                                            <span> {category.categoryName} </span></div>
                                                                        {openCategories[category.categoryId] ?
                                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ minWidth: "24px" }} width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path d="M5 12l14 0"></path>
                                                                            </svg>
                                                                            :
                                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ minWidth: "24px" }} width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path d="M12 5l0 14"></path>
                                                                                <path d="M5 12l14 0"></path>
                                                                            </svg>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                {openCategories[category.categoryId] && (<ol>
                                                                    {category.questions.map(question => (
                                                                        <li
                                                                            key={question.id}
                                                                            className={`my-1 py-2 pr-1 pl-4  italic 
                                            ${question.position === selectedBlock?.position ? 'bg-gray-200' : 'bg-white'}`}
                                                                            onClick={() => { handleButtonClick(question); if (!desktop) { setQuestionsPanelOpen({ open: !questionsPanelOpen.open, fixed: false }) } }}

                                                                        ><button className='w-full flex items-center gap-1'>
                                                                                <div style={{ minWidth: "20px" }} >
                                                                                    {question.hasAnswers && (<span style={{ color: "var(--airco2-green-color)" }}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                            className="icon icon-tabler icon-tabler-circle-check-filled" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="var(--airco2-green-color)" strokeLinecap="round" strokeLinejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" strokeWidth="0" />
                                                                                        </svg></span>
                                                                                    )}
                                                                                </div>
                                                                                <span className="flex text-left text-xs">
                                                                                    {question.name}
                                                                                </span>
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ol>)}
                                                            </li>))}



                                                        </ol>
                                                    )}
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            }

                            {!desktop &&
                                <Modal show={instructionsPanelOpen.open} animation={false} data-aos="fade-up">
                                    <Modal.Header className='sec-green-background'>
                                        <Modal.Title>{t("footPrint.descripcionInstrucciones")}</Modal.Title>
                                        <div className="flex justify-end">
                                            <button onClick={() => setInstructionsPanelOpen({ open: false, fixed: false })}>
                                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                            </button>
                                        </div>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='p-2'>
                                            <p className='font-bold'>{t('footPrint.descripcion')}</p>

                                            <span className='text-sm italic' dangerouslySetInnerHTML={{ __html: questBlock?.data?.data?.description }} />
                                        </div>
                                        <div className='p-2'>
                                            <p className='font-bold'>{t('footPrint.instrucciones')}</p>

                                            <span className='text-sm italic' dangerouslySetInnerHTML={{ __html: questBlock?.data?.data?.instructions }} />

                                        </div>
                                    </Modal.Body>

                                </Modal>
                            }
                            {/* Panel central  */}
                            <div className="bg-white sidebar-airco2"
                                style={{
                                    width: !desktop ? '100%' : instructionsPanelOpen.open && questionsPanelOpen.open ? '70%' : (instructionsPanelOpen.open && !questionsPanelOpen.open ? '85%' : (!instructionsPanelOpen.open && questionsPanelOpen.open ? "85%" : "100%")),
                                    height: desktop ? '100%' : '',
                                    overflowY: desktop ? 'scroll' : 'hidden'
                                }} >
                                <div style={{ borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6' }}>

                                    <div className='px-2 flex justify-between items-center flex-wrap flex-col md:flex-row'>
                                        <div className='flex items-center gap-3 py-3 p-2' id='footprint-button-left'>


                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipMiscalculos}
                                            >

                                                <button onClick={() => navigate('/footprint/myfootprint')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home-move" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2" />
                                                        <path d="M19 12h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h5.5" />
                                                        <path d="M16 19h6" />
                                                        <path d="M19 16l3 3l-3 3" />
                                                    </svg>
                                                </button>

                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipFormularioTercerosNoDisponible}
                                            >
                                                <button disabled={!thirdPartyForm}
                                                    onClick={() => navigate(`/footprint/formContacts/${idCalculation}`, { state: { data: { year: year, orgName: orgName, status: status, thirdPartyForm: thirdPartyForm, userId: userId} } })}>
                                                    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.5 13C7.32843 13 8 12.3284 8 11.5C8 10.6716 7.32843 10 6.5 10C5.67157 10 5 10.6716 5 11.5C5 12.3284 5.67157 13 6.5 13Z"
                                                            stroke="var(--airco2-green-color)"
                                                            strokeWidth="1.5" />
                                                        <path d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z"
                                                            stroke="var(--airco2-green-color)"
                                                            strokeWidth="1.5" />
                                                        <path d="M18 13C18.8284 13 19.5 12.3284 19.5 11.5C19.5 10.6716 18.8284 10 18 10C17.1716 10 16.5 10.6716 16.5 11.5C16.5 12.3284 17.1716 13 18 13Z"
                                                            stroke="var(--airco2-green-color)"
                                                            strokeWidth="1.5" />
                                                        <path d="M9 16.5C9 15.6716 8.32843 15 7.5 15C6.67157 15 6 15.6716 6 16.5C6 17.3284 6.67157 18 7.5 18C8.32843 18 9 17.3284 9 16.5Z"
                                                            stroke="var(--airco2-green-color)"
                                                            strokeWidth="1.5" />
                                                        <path d="M17 16.5C17 15.6716 16.3284 15 15.5 15C14.6716 15 14 15.6716 14 16.5C14 17.3284 14.6716 18 15.5 18C16.3284 18 17 17.3284 17 16.5Z"
                                                            stroke="var(--airco2-green-color)"
                                                            strokeWidth="1.5" />
                                                        <path d="M12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14Z"
                                                            stroke="var(--airco2-green-color)"
                                                            strokeWidth="1.5" />
                                                    </svg>



                                                </button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipReducction}
                                            >
                                                <button onClick={() => navigate(`/footprint/myfootprint/reductionCommitment/${idCalculation}`, { state: { data: { year: year, orgName: orgName } } })}>

                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-checklist" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8" />
                                                        <path d="M14 19l2 2l4 -4" />
                                                        <path d="M9 8h4" />
                                                        <path d="M9 12h2" />
                                                    </svg>




                                                </button>

                                            </OverlayTrigger>

                                        </div>
                                        <div id='footprint-title' className="text-lg font-bold flex flex-wrap py-3 p-2 ">

                                            <span> {questResume?.data?.data?.organizatioName} -  {questResume?.data?.data?.year}</span>
                                        </div>


                                        <div className='flex gap-3 items-center py-3 p-2' id='footprint-button-right'>

                                            {selectedBlock?.position !== 0 &&
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipPrevious}
                                                >
                                                    <button onClick={() => handleNextPreviousButtons("previous")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-chevron-left" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M13 15l-3 -3l3 -3" />
                                                            <path d="M21 12a9 9 0 1 0 -18 0a9 9 0 0 0 18 0z" />
                                                        </svg>
                                                    </button>
                                                </OverlayTrigger>}
                                            {selectedBlock?.position !== orderedQuestions?.length - 1 &&
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipNext}
                                                >

                                                    <button onClick={() => handleNextPreviousButtons("next")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-chevron-right" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M11 9l3 3l-3 3" />
                                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0z" />
                                                        </svg>
                                                    </button>
                                                </OverlayTrigger>
                                            }
                                            {status !== 'FINALIZED' && selectedBlock?.position !== orderedQuestions?.length &&
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipFinish}
                                                >
                                                    <button onClick={() => handleNextPreviousButtons(status === 'CURRENTYEAR' ? "current" : "")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-browser-check" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
                                                            <path d="M4 8h16" />
                                                            <path d="M8 4v4" />
                                                            <path d="M9.5 14.5l1.5 1.5l3 -3" />
                                                        </svg>
                                                    </button>
                                                </OverlayTrigger>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {!calculationOutputDto?.data?.hasUpdatedFactors &&

                                    <div className='italic text-xs text-center border px-2 p-2 sec-neutro-background flex justify-center' ref={startRef}>
                                        <BannerRecalculate calculation={calculationOutputDto?.data}></BannerRecalculate>
                                    </div>
                                }

                                {(adminUser !== null && adminUser !== undefined) &&
                                    <p className='text-right'>{t("footPrint.usuario")}: {adminUser.label}</p>}

                                {UserService.getUserType() === undefined && <div className='text-right'>{t("footPrint.calculo")}  <span>{calculationOutputDto?.data?.questionnaire?.name} </span>{t("footPrint.paraLa")} <span className='font-bold'>{calculationOutputDto?.data?.organization?.name}</span> {t("footPrint.delaño")}<span className='font-bold'>{calculationOutputDto?.data?.year}</span>.</div>}
                                <ModalFinishCalculation view={modalFinalizarCalculo.view} calculationId={modalFinalizarCalculo.idCalculation} idOrg={modalFinalizarCalculo.idOrganization} updateViewFromParent={updateViewFromParent} />
                                <Modal show={viewFinishCalculationCurrent} animation={false} data-aos="fade-up">
                                    <Modal.Header className='sec-neutro-background sec-grey-text'>
                                        <Modal.Title>{t("footPrint.finalizarCalc")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {t("footPrint.finalizarCalcTextCurrent")}
                                        <div className="mt-4 text-center">
                                            <Link className={`btn-sm btn-green`}
                                                to={`/footprint/myfootprint/report/${calculationOutputDto?.data?.id}`}>{t("footPrint.verCalculoDetalle")}
                                            </Link>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className='btn-cancel rounded-lg text-xs p-2'
                                            onClick={() => { setViewFinishCalculationCurrent(false) }}>
                                            {t("footPrint.cancelar")}
                                        </button>
                                    </Modal.Footer>
                                </Modal>

                                {questResume?.data?.data?.questions.length > 0 && <>
                                    {/* Content */}
                                    <div className='mx-4'>
                                        <span className='flex justify-between'>
                                            {/* <h1 className="mb-1  text-lg  text-gray-800 font-bold">{selectedBlock?.name}</h1> */}
                                            {(!instructionsPanelOpen.open && !desktop) &&
                                                <span className='italic text-xs flex items-center ' id='footprint-description-section'>
                                                    <button onClick={() => setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: true })}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                                            <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                                        </svg>
                                                    </button>
                                                    {t("footPrint.descripcionInstrucciones")}
                                                </span>
                                            }

                                        </span>
                                        {selectedBlockid === selectedBlock?.id &&
                                            calculationOutputDto?.data?.calculationStatus !== "FINALIZED" &&
                                            <OptionForm
                                                options={optionsSelectedBlock?.options}
                                                sendDataToParent={sendDataToParent}
                                                selectedOption={idQuestion}>
                                            </OptionForm>}

                                        {calculationOutputDto?.data?.calculationStatus !== "FINALIZED" &&
                                            <InfoForm
                                                questBlock={questBlock.data}
                                                selectedBlockId={selectedBlockid}
                                                selectedBlockName={selectedBlock?.name}
                                                sendDataToParent={sendDataToParent}
                                                idCalculation={calculationOutputDto != null ? calculationOutputDto?.data?.id : ""}
                                                addAnswer={addAnswer}
                                                isButtonDisabled={isButtonDisabled}
                                                resetQuestionBlock={resetQuestionBlock}
                                                idQuestion={idQuestion}
                                                questBlockFetching={questBlock.isFetching}
                                                questionnaireType={calculationOutputDto?.data?.questionnaire.name}
                                                status={status}
                                                year={year}
                                                orgName={orgName}
                                                thirdPartyForm={thirdPartyForm}
                                                userId={userId}
                                            />
                                        }

                                        <AnswerTable
                                            idQuestion={selectedBlock?.id}
                                            idCalculation={calculationOutputDto != null ? calculationOutputDto?.data?.id : ""}
                                            addAnswer={newRow}
                                            activePostButton={activePostButton}
                                            status={status}
                                            setNoAnswer={setNoAnswer}
                                            inputs={questBlock?.data?.data?.inputs}
                                        />


                                        <div className='flex flex-wrap items-center justify-center md:justify-between gap-4 ' style={{ marginBottom: '5rem' }}>
                                            <div>
                                                <Link
                                                    to="/footprint/myfootprint">
                                                    <button type='button' className={`btn-sm btn-cancel text-xs `}>

                                                        {t("sidebar.miscalculos")}
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="flex justify-center gap-4">
                                                {!(selectedBlock?.position === orderedQuestions?.length - (orderedQuestions?.length - 1)) &&
                                                    <button type='button' className='btn-sm btn-green text-xs' onClick={() => handleNextPreviousButtons("previous")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right rotate-180	" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M9 6l6 6l-6 6" />
                                                        </svg>  {t("footPrint.volver")}</button>}
                                                {!(status === 'FINALIZED' && selectedBlock?.position === orderedQuestions?.length) && (
                                                    (selectedBlock?.position === orderedQuestions?.length && status != "DRAFT") ? "" : (
                                                        <button type='button' className='btn-sm btn-green text-xs' onClick={() => handleNextPreviousButtons(selectedBlock?.position !== orderedQuestions?.length ? "next" : "")}>
                                                            {selectedBlock?.position === orderedQuestions?.length ? t("footPrint.Finalizar") : <>{t("footPrint.next")}<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right " width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M9 6l6 6l-6 6" />
                                                            </svg> </>}
                                                        </button>)
                                                )}
                                            </div>
                                            <div>{status !== 'FINALIZED' && selectedBlock?.position !== orderedQuestions?.length &&
                                                <button type='button' className='btn-sm btn-banner-o text-xs'
                                                    onClick={() => handleNextPreviousButtons(status === 'CURRENTYEAR' ? "current" : "")}> {t("footPrint.Finalizar")}</button>}
                                            </div>
                                        </div>





                                    </div>
                                </>}
                                {questResume?.data?.data == undefined && <>
                                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto text-center text-xs">
                                        <div>{t("footPrint.chargequestions")}</div>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true" />
                                    </div></>}
                                {/* <p className='text-sm italic px-4'>*{t("footPrint.navega2")}</p> */}

                            </div>


                            {/* Panel descripción  */}
                            {!instructionsPanelOpen.open && desktop &&
                                <span className='italic text-xs flex items-center ' id='footprint-description-section'
                                    onClick={() => setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: true })}>
                                    <button onClick={() => setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: true })}
                                        onMouseEnter={() => !instructionsPanelOpen.open && setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: false })}
                                        className="flex flex-row items-center"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-left"
                                            width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M11 7l-5 5l5 5" />
                                            <path d="M17 7l-5 5l5 5" />
                                        </svg>
                                        <p className="text-xs pl-1" style={{ writingMode: "vertical-rl", transform: "rotate(180deg)", whiteSpace: "nowrap" }}>
                                            {t("footPrint.descripcionInstrucciones")}
                                        </p>

                                    </button>
                                </span>
                            }

                            {instructionsPanelOpen.open &&
                                <div className='p-2 border sidebar-airco2 relative' style={{ width: desktop ? '15%' : '0%', height: desktop ? '100%' : '', overflowY: desktop ? 'scroll' : 'hidden' }}
                                    onMouseLeave={() => { instructionsPanelOpen.open && !instructionsPanelOpen.fixed && setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: false }) }}
                                    onClick={() => { instructionsPanelOpen.open && !instructionsPanelOpen.fixed && setInstructionsPanelOpen({ open: true, fixed: true }) }}>

                                    <div className='italic text-xs flex items-center justify-end mr-2 fixed z-10 right-6' id='footprint-description-section'>
                                        <button onClick={() => setInstructionsPanelOpen({ open: !instructionsPanelOpen.open, fixed: true })} >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevrons-right"
                                                width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M7 7l5 5l-5 5" />
                                                <path d="M13 7l5 5l-5 5" />
                                            </svg>
                                        </button>
                                    </div>

                                    <div className='p-2'>
                                        <p className='font-bold'>{t('footPrint.descripcion')}</p>
                                        {!questBlock?.isFetching ?
                                            <span className='text-sm italic' dangerouslySetInnerHTML={{ __html: questBlock?.data?.data?.description }} />
                                            :
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" />}
                                    </div>
                                    <div className='p-2'>
                                        <p className='font-bold'>{t('footPrint.instrucciones')}</p>
                                        {!questBlock?.isFetching ?
                                            <span className='text-sm italic' dangerouslySetInnerHTML={{ __html: questBlock?.data?.data?.instructions }} />
                                            :
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" />}
                                    </div>
                                </div>


                            }


                        </main>

                }


                <Joyride
                    run={isTourRunning}
                    steps={steps}
                    stepIndex={currentStepIndex}
                    callback={handleJoyrideCallback}
                    continuous={true}
                    showProgress={true}
                    showSkipButton={true}
                    hideBackButton={currentStepIndex == 12 ? true : false}
                    styles={customButtonStylesTourTooltip()}
                    locale={locale}
                    disableScrolling={false}
                    disableOverlayClose={true}
                    scrollOffset={70}
                    showCloseButton={false}
                />
            </div >
        </div >
    )
}
