import format from 'date-fns/format';
import React, { useContext, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import Translations from '../../../services/Translations';
import { formatThousands } from '../utils/Utils';

function MPOrdersList({ orderList }) {
  const [loading, setLoading] = useState(true);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [orders, setOrders] = useState({
    data: orderList,
    isFetching: true,
  });


  const statusColor = (color) => {
    switch (color) {
      case 'CART':
        return 'bg-gray-100 text-gray-500';
      case 'CONFIRMED':
        return 'bg-gray-100 text-yellow-600';
      case 'WAITING_PAYMENT':
        return 'bg-yellow-500 text-white';
      case 'FINALIZED':
        return 'bg-green-100 text-green-600';
      case 'EXPIRED':
        return 'bg-red-100 text-red-600';
    }
  };

  return (
    <div className="grow sec-neutro-background">

      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">{t("marketplace.panel_orders_title")}</h2>

        {orders.data === null ?
          loading ? <span>  <Spinner animation="border" className='flex justify-center' /></span> : <p> {t("sidebar.nomontes")}.</p>
          : <>
            {orders.data.length === undefined || orders.data.length === 0 && <>{t("marketplace.panel_orders_vacio")}</>}

            {orders.data.length > 0 && <table className="table-auto w-full divide-y divide-gray-200">
              <thead className="text-xs  text-gray-500  border-t border-gray-200">
                <tr>

                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{t("marketplace.panel_orders_pedido")} </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{t("marketplace.panel_orders_fecha")}</th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{t("marketplace.panel_orders_monte")}</th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{t("marketplace.panel_orders_cantidad")}</th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{t("marketplace.panel_orders_precio")}</th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{t("marketplace.panel_orders_total")}</th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{t("marketplace.panel_orders_estado")}</th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{t("marketplace.panel_orders_factura")}</th>

                </tr>
              </thead>
              <tbody className="text-sm">
                {orders.data.map(o => {
                  return <tr key={o.id}><td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">#{o.orderId.id}</td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{format(new Date(o.orderId.creationDate), 'dd/MM/yyyy HH:mm:ss')}</td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{o.productName}</td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{o.quantity} t</td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{formatThousands(o.unitPrice)} €/t</td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">{formatThousands(o.total)} €</td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"> <span className={`text-center rounded-full py-0.5 px-2 ${statusColor(o.orderId.orderStatus)}`}>  {t(Translations.transalteStatusCart(o.orderId.orderStatus))}</span></td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      {o.orderId.orderStatus === "WAITING_PAYMENT" && <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock text-center" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="9" />
                        <polyline points="12 7 12 12 15 15" />
                      </svg>}
                      {o.urlInvoiceForestStripe !== null && o.urlInvoiceForestStripe !== "" && o.orderId.orderStatus === "FINALIZED" && <a href={o.urlInvoiceForestStripe} target='_blank'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-download text-center" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <line x1="12" y1="11" x2="12" y2="17" />
                          <polyline points="9 14 12 17 15 14" />
                        </svg></a>}</td>
                  </tr>
                })}
              </tbody>
            </table>}
          </>
        }

      </div>
    </div >
  );
}

export default MPOrdersList;