import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import UserService from '../../../services/UserService';

export default function DownloadReportModal({ calculation, showReport, handleReport, loadingReport, loadingReportIso, loadingReportGhg, loadingReportCommitments, handleCloseReport, idCalculation }) {
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const isAdmin = UserService.getUsername() == "admin@airco2.eu" ? true : false;
    const editSvg = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                        <path d="M16 5l3 3" />
                    </svg>;

    return (<Modal show={showReport} animation={false} data-aos="fade-up" >
        <Modal.Header className='sec-green-background'>
            <Modal.Title>{t("footPrint.downReporte")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='p-4'>
                <table>
                    <tr className='flex justify-between items-center mb-4'>
                        <td className='col-8 text-lg'>
                        {isAdmin && <button onClick={()=>navigate(`/admin/footprint/report/edit/${idCalculation}/alcances`)}>{editSvg}</button>}
                            {t("footPrint.reportEmissions")}
                            </td>
                        <td className='flex justify-center col-4'>
                            <button onClick={() => (calculation?.data?.urlReport === undefined || calculation?.data?.urlReport === '' ? handleReport() : window.open(calculation?.data?.urlReport, '_blank'))}
                                style={{ pointerEvents: loadingReport ? 'none' : 'auto' }}
                                className='p-2 flex flex-row items-center btn-sm btn-green rounded-lg'>
                                <div className='mb-1'>
                                    {loadingReport ? (
                                        <div className="spinner-border" role="status">
                                        </div>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" strokeWidth="1.5" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                        </svg>
                                    )}
                                </div>
                                {t("excel.descargar")}
                            </button>
                        </td>
                    </tr>
                    <tr className='flex justify-between items-center mb-4'>
                        <td className='col-8 text-lg'>
                            {isAdmin && <button onClick={()=>navigate(`/admin/footprint/report/edit/${idCalculation}/GHG`)}>{editSvg}</button>}
                            {t("footPrint.reportEmissions")}
                            <span className='text-sm italic font-bold'>{t("footPrint.reportEmissionsghg")}</span>
                        </td>
                        <td className='flex justify-center col-4'>
                            <button onClick={() => (calculation?.data?.urlReportGHG === undefined || calculation?.data?.urlReportGHG === '' ? handleReport('GHG') : window.open(calculation?.data?.urlReportGHG, '_blank'))}
                                style={{ pointerEvents: loadingReportGhg ? 'none' : 'auto' }}
                                className='p-2 flex flex-row items-center btn-sm btn-green rounded-lg'
                            >
                                <div className='mb-1'>
                                    {loadingReportGhg ? (
                                        <div className="spinner-border" role="status">
                                        </div>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" strokeWidth="1.5" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                        </svg>
                                    )}
                                </div>
                                {t("excel.descargar")}
                            </button>
                        </td>
                    </tr>
                    <tr className='flex justify-between items-center mb-4'>
                        <td className='col-8 text-lg'>
                            {isAdmin && <button onClick={()=>navigate(`/admin/footprint/report/edit/${idCalculation}/ISO`)}>{editSvg}</button>}
                            {t("footPrint.reportEmissions")}
                            <span className='text-sm italic font-bold'>{t("footPrint.reportEmissionsIso")}</span>
                        </td>
                        <td className='flex justify-center col-4'>
                            <button onClick={() => (calculation?.data?.urlReportISO === undefined || calculation?.data?.urlReportISO === '' ? handleReport('ISO') : window.open(calculation?.data?.urlReportISO, '_blank'))}
                                style={{ pointerEvents: loadingReportIso ? 'none' : 'auto' }}
                                className='p-2 flex flex-row items-center btn-sm btn-green rounded-lg'
                            >
                                <div className='mb-1'>
                                    {loadingReportIso ? (
                                        <div className="spinner-border" role="status">
                                        </div>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" strokeWidth="1.5" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                        </svg>
                                    )}
                                </div>
                                {t("excel.descargar")}
                            </button>
                        </td>
                    </tr>

                    <tr className='flex justify-between items-center '>
                        <td className='col-8'><span className='text-lg'>{t("footPrint.reportEmissionRedComm")}</span>
                            {calculation?.data?.hasReductionCommitments === undefined || !calculation?.data?.hasReductionCommitments ?
                                <div className='text-md sec-grey-text'>⚠️ {t("footPrint.redCommIncomplete")}</div>
                                : ""}
                        </td>
                        <td className='flex justify-center col-4'>
                            {calculation?.data?.hasReductionCommitments === undefined || !calculation?.data?.hasReductionCommitments ?
                                <button className='btn-sm btn-banner-o rounded-lg ' onClick={() =>
                                    navigate(`../footprint/myfootprint/reductionCommitment/${calculation?.data?.id}`,
                                        { state: { data: { year: calculation?.data?.year, orgName: calculation?.data?.organization.name } } })}>
                                    {t("footPrint.completeNow")}
                                </button>
                                :
                                <button onClick={() => (calculation?.data?.urlReportReductionCommitment === undefined || calculation?.data?.urlReportReductionCommitment === '' ? handleReport(null, true) : window.open(calculation?.data?.urlReportReductionCommitment, '_blank'))}
                                    style={{ pointerEvents: loadingReportCommitments ? 'none' : 'auto' }}
                                    className='p-2 flex flex-row items-center btn-sm btn-green rounded-lg'>
                                    {loadingReportCommitments ? (
                                        <div className="spinner-border" role="status">
                                        </div>
                                    ) : (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" strokeWidth="1.5" className="mb-1 bi bi-filetype-pdf" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                        </svg>)
                                    }
                                    {t("excel.descargar")}
                                </button>
                            }
                        </td>
                    </tr>
                </table>
            </div>
            <div className='flex space-x-4 justify-center mt-4'>
                <button onClick={() => handleCloseReport()} className='btn-cancel rounded-lg p-2'>{t("footPrint.cerrar")}</button>
            </div>
        </Modal.Body>
    </Modal >)
}