import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import AgendaAseoria from "./landing-externals/AgendaAseoria";

export default function Asesoria() {
    return (<>
        <div style={{ height: '100vh', width: '100vw' }}>
            <Header></Header>
            <AgendaAseoria></AgendaAseoria>
        </div>
    </>
    )
}