import React, { useState, useContext, useEffect } from 'react';

import Sidebar from './../Sidebar';
import Header from './../Header';
import Mapa from './mapa_catastro';
import Form from "react-bootstrap/Form";
import FormMontesMap from './FormMontesMap';
import UserService from './../../../services/UserService';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';


import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';

function NewForestForm() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [refCatValue, setRefCatValue] = useState(null);
  const [formMonte, setFormMonte] = useState(false);

  const [infoParcela, setInfoParcela] = useState(null);
  const [registerResponse, setRegisterResponse] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [booleanClickMap, setBooleanClickMap] = useState(false);
  const [catastroCaido, setCatastroCaido] = useState(false);

  // const [dataFromMap, setDataFromMap] = useState();
  const sendDataToParent = (props) => {
    setInfoParcela(props);
  };

  const [pingCatastro, setPingCatastro] = useState();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_CATASTRO_BY_REFCAT}27027A02601426`, {
      method: "GET",
      headers: {
        "Accept": "*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${UserService.getToken()}`
      },
    })
      .then((response) => {
        if (response.status == "500") {
          setCatastroCaido(true)
        }
        return response.json();
      })
      .then((pingCatastro) => {
        if (!pingCatastro.error) {
          setPingCatastro(pingCatastro);
        }
      });
  }, [])

  function getInfoByCatastral(catastral) {
    // useEffect(() => {
    fetch(`${process.env.REACT_APP_CATASTRO_BY_REFCAT}` + catastral, {
      method: "GET",
      headers: {
        "Accept": "*",
        "Content-Type": "application/json",
        // "Allow": "*",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${UserService.getToken()}`
      },
    })
      .then((response) => {
        if (response.status != "200") {
          setBooleanClickMap(true)
        }
        if (response.status == "400") {
          setModal(true);
        }
        else if (response.status == "500") {
          setModal(true);
        }
        return response.json();
      })
      .then((infoParcela) => {
        if (!infoParcela.error) {
          setInfoParcela(infoParcela);
        }
      });


  }

  const handlerSubmit = e => {
    e.preventDefault();
    setSpinner(true);
    if (refCatValue == "Si") {
      getInfoByCatastral(e.target.catastral.value)
    } else if (refCatValue == "No") {
      setBooleanClickMap(true);
    }
    const monte = {
      "owner": e.target.titular.value,
      "size": "",
      "cadastre": "",
      "forestType": "",
      "age": "",
      "marco": "",
      "name": e.target.nombre.value.trim(),
      "creationDate": new Date(),
      "userId": UserService.getUserId(),
      "province": "",
      "state": "CREATED"
    }

    // useEffect(() => {
    const fetchForestUploaded = async () => {
      try {
        setForestUploaded({ forestUploadedData: forestUploaded.forestUploadedData, isFetching: true });
        await axios.post(`${process.env.REACT_APP_FOREST}?email=${UserService.getUsername()}`, monte, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
          // body:JSON.stringify(monte)
        }).then(response => {
          setFormMonte(true);
          setForestUploaded({ forestUploadedData: response.data, isFetching: false });
          setSpinner(true);
          // setItems(response.data);
          // setLoading(false)
        });
      } catch (exception) {
        console.error(exception);
        // setLoading(false)
        setForestUploaded({ forestUploadedData: forestUploaded.forestUploadedData, isFetching: false });
        setSpinner(true);
      }
    };
    fetchForestUploaded();
    // }, []);

  }


  //Implementar todos los años con compensaciones y settear al actual
  const [forestUploaded, setForestUploaded] = useState({
    forestUploadedData: [],
    isFetching: false,
  });

  // if(registerResponse !=null){
  const [show, setShow] = useState(true);
  // }
  const [modal, setModal] = useState(false);
  const onDismiss = () => setModal(false);
  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} className="z-40" />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <Alert color="primary" isOpen={modal} onClose={() => setModal(false)} dismissible className='mx-4'>
              <h4 className="h4">
                {t("formMonte.alert_titulo")}
              </h4>
              <p>
                {t("formMonte.alert_monteSimple1")}<br />
                {t("formMonte.alert_monteSimple2")}
                <hr className='mt-2 mb-2' />
                <p className="mb-0">
                  {t("formMonte.alert_monteSimple3")}
                  <a className="alert-link" href="https://www.sedecatastro.gob.es/" rel="noreferrer" target="_blank">{t("formMonte.alert_aqui")}</a>
                </p>
              </p>
              <br />
              <div className='flex justify-center'>
                <Button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap " onClick={() => setModal(false)}>{t("formMonte.alert_aceptar")}</Button>
              </div>
            </Alert>

            <Alert color="primary" isOpen={catastroCaido} onClose={() => setCatastroCaido(false)} dismissible className='mx-4'>
              <h4 className="h4">
                {t("formMonte.alert_catastro_titulo")}
              </h4>
              <p>
                {t("formMonte.alert_catastro1")}<br />
                {t("formMonte.alert_catastro2")}
              </p>
              <br />
              <div className='flex justify-center'>
                <Button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap "><Link to="/forest/forestlist" style={{ textDecoration: 'none', color: 'inherit' }}>{t("formMonte.alert_aceptar")}</Link></Button>
              </div>
            </Alert>

            {catastroCaido ? <></> :
              <>
                {/* Page header */}
                <div className="mb-8">
                  {/* Title */}
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("formMonte.regismonte")}</h1>
                </div>

                {/* Content */}
                <div className="flex flex-col md:flex-row md:-mr-px">
                  <Mapa dataParcela={infoParcela} sendDataToParent={sendDataToParent} booleanClick={booleanClickMap} />
                  {formMonte ?
                    <FormMontesMap newForest={forestUploaded.forestUploadedData} dataParcela={infoParcela} /> :


                    // <div className='ml-4 '>
                    <React.Fragment>

                      <div className="max-w-sm mx-auto px-4" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>

                        <Form onSubmit={handlerSubmit} className="sec-neutro-background" style={{ width: '100%' }}>
                          <div className="space-y-4">

                            <div className='mb-4'>
                              <label className="block font-medium mb-1" htmlFor="nombre">{t("formMonte.nombre")}<span className="text-red-500">* </span></label>
                              <input id="nombre" name="nombre" className="form-input w-full" type="text" required />
                            </div>
                            <div className='mb-4'>
                              <label className="block font-medium mb-1" htmlFor="titular">{t("formMonte.titular")}<span className="text-red-500">* </span></label>
                              <input id="titular" name="titular" className="form-input w-full" type="text" required />
                            </div>
                            <div className='mb-4'>
                              <label className="block font-medium mb-1" htmlFor="catastralknown">{t("formMonte.catastralknown")} <span className="text-red-500">* </span></label>
                              <div className='inline-block'>
                                <input id="refcat" name="refcat" className="mr-2" value="Si" type="radio"
                                  onChange={e => setRefCatValue(e.target.value)}
                                  checked={refCatValue == "Si" ? true : false} required />
                                <label>{t("formMonte.si")}</label>
                              </div>
                              <div>
                                <input id="refcat" name="refcat" className="mr-2" value="No" type="radio"
                                  onChange={e => setRefCatValue(e.target.value)}
                                  checked={refCatValue == "No" ? true : false} required />
                                <label>{t("formMonte.no")}</label>
                              </div>
                            </div>
                            {
                              refCatValue == "Si" ?
                                <div>
                                  <div className='mb-4'>
                                    <label className="block font-medium mb-1" htmlFor="catastral">{t("formMonte.catastral")} <span className="text-red-500">* </span></label>
                                    <input id="catastral" name="catastral" className="form-input w-full" type="text" minlength="14" maxLength="14" required />
                                  </div>
                                </div>
                                : ""
                            }

                            <div className='flex justify-center mt-4'>
                              <button arial-label="btn-regristrar" type="submit" className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">
                                {spinner ? <Spinner animation="border" variant="light" /> : t("formMonte.registrar")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </React.Fragment>

                  }

                </div>
              </>}

          </div>
        </main>

      </div>

    </div>
  );
}

export default NewForestForm;