import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../context/createcontext";
import Transition from '../../utils/Transition.js';
import banco1 from '../../images/01_DataDriven.webp';
import banco2 from '../../images/02_DataDriven.webp';
import banco3 from '../../images/03_DataDriven.webp';
import banco4 from '../../images/04_DataDriven.webp';

function FeaturesHome() {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");





  return (
    <section className="relative" id='forestal2' >

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 sec-green-background pointer-events-none mb-16" aria-hidden="true"></div>
      <div className="absolute  right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">




          {/* Section content */}
          <div className="md:grid md:grid-cols-12 md:gap-6">

            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6" data-aos="fade-right" >
              <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                <h2 className="h3 mb-3 sec-blue-text" style={{ fontFamily: 'openReg' }}>{t("foreimg.data")}</h2>
                <p className="text-xl sec-white-text" >{t("foreimg.analiza")}</p>
              </div>
              {/* Tabs buttons */}
              <div className="mb-8 md:mb-0 sec-blue-text" >
                <button arial-label="tbn-nextimg"
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  onClick={(e) => { e.preventDefault(); setTab(1); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1"><h3>{t("foreimg.mmonte")}</h3></div>
                    <div>{t("foreimg.monterevalorizado")}</div>

                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-christmas-tree" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 3l4 4l-2 1l4 4l-3 1l4 4h-14l4 -4l-3 -1l4 -4l-2 -1z" />
                      <path d="M14 17v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-3" />
                    </svg>
                  </div>
                </button>
                <button arial-label="tbn-nextimg"
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  onClick={(e) => { e.preventDefault(); setTab(2); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1"><h3>{t("foreimg.+rent")}</h3></div>
                    <div className="text-gray-600">{t("foreimg.rentadicional")}</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trending-up" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <polyline points="3 17 9 11 13 15 21 7" />
                      <polyline points="14 7 21 7 21 14" />
                    </svg>
                  </div>
                </button>
                <button arial-label="tbn-nextimg"
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  onClick={(e) => { e.preventDefault(); setTab(3); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1"><h3>{t("foreimg.liqui")}</h3></div>
                    <div className="text-gray-600">{t("foreimg.afrontargasto")}</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ripple" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 7c3 -2 6 -2 9 0s6 2 9 0" />
                      <path d="M3 17c3 -2 6 -2 9 0s6 2 9 0" />
                      <path d="M3 12c3 -2 6 -2 9 0s6 2 9 0" />
                    </svg>
                  </div>
                </button>
                <button arial-label="tbn-nextimg"
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 4 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                  onClick={(e) => { e.preventDefault(); setTab(4); }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1"><h3>{t("foreimg.transp")}</h3></div>
                    <div className="text-gray-600">{t("foreimg.legalidad")}</div>
                  </div>
                  <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plant-2" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M2 9a10 10 0 1 0 20 0" />
                      <path d="M12 19a10 10 0 0 1 10 -10" />
                      <path d="M2 9a10 10 0 0 1 10 10" />
                      <path d="M12 4a9.7 9.7 0 0 1 2.99 7.5" />
                      <path d="M9.01 11.5a9.7 9.7 0 0 1 2.99 -7.5" />
                    </svg>
                  </div>
                </button>

              </div>
            </div>

            {/* Tabs items */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 mt-5" data-aos="zoom-y-out" ref={tabs}>
              <div className="relative flex flex-col text-center hidden md:block lg:text-right mt-5">
                {/* Item 1 */}
                <Transition
                  show={tab === 1}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <img className="md:max-w-none mx-auto rounded" src={banco1} width="600" height="700" alt="Features bg" />
                    {/* <img className="md:max-w-none absolute w-full left-0 transform animate-float" src={require('../images/features-home-element-01.png').default} width="500" height="147" alt="Element 01" style={{ top: '22%' }} />
                    <img className="md:max-w-none absolute w-full left-0 transform animate-float animation-delay-500" src={require('../images/features-home-element-02.png').default} width="500" height="158" alt="Element 02" style={{ top: '39%' }} />
                    <img className="md:max-w-none absolute w-full left-0 bottom-0 transform animate-float animation-delay-1000" src={require('../images/features-home-element-03.png').default} width="500" height="167" alt="Element 03" style={{ top: '77%' }} /> */}
                  </div>
                </Transition>
                {/* Item 2 */}
                <Transition
                  show={tab === 2}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <img className="md:max-w-none mx-auto rounded" src={banco2} width="600" height="700" alt="Features bg" />

                  </div>
                </Transition>
                {/* Item 3 */}
                <Transition
                  show={tab === 3}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <img className="md:max-w-none mx-auto rounded" src={banco3} width="600" height="700" alt="Features bg" />

                  </div>
                </Transition>
                <Transition
                  show={tab === 4}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    <img className="md:max-w-none mx-auto rounded" src={banco4} width="600" height="700" alt="Features bg" />

                  </div>
                </Transition>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesHome;
