import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../../context/createcontext";
import combustible from '../../../../../images/car2.svg';
import { formatThousands } from '../../../utils/Utils';
import { Spinner } from 'reactstrap';

export default function Combustible({ highLights, formatNumber }) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [combustibleConsumption, setCombustibleConsumption] = useState();
    const [kgCombustible, setKgCombustible] = useState();

    useEffect(() => {
        setCombustibleConsumption(highLights.data?.combustibleConsumption);
        setKgCombustible(formatNumber(highLights.data?.combustibleConsumption?.kgCO2e));
    }, [highLights])




    return (
        <div className='grid-report-hightlights rounded-sm border border-gray-200 px-2 py-3' >

            <div className='flex flex-col text-center items-center justify-center'>
                <img src={combustible} className="" />
            </div>

            <div className='flex flex-col text-center items-center justify-center text-sm'>
                {t("footPrint.combustible")}<br />
                {t("dash.alcance1")}
                {highLights.isFetching && combustibleConsumption &&
                    <div className='text-center mt-1 text-md font-bold text-base'>
                        {combustibleConsumption.name}
                    </div>
                }
            </div>

            <div className='flex flex-col justify-center'>


                {!highLights.isFetching && <div className='text-center'>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" />
                </div>}



                {highLights.isFetching && <>
                    {(kgCombustible && combustibleConsumption) &&
                        <table>
                            <tbody>
                                <tr>
                                    <td className='font-bold text-xl text-right'>{formatThousands(combustibleConsumption.quantity)}</td>
                                    <td className='text-xs text-left pl-2'>{t("footPrint.uds")}</td>
                                </tr>
                                <tr>
                                    <td className='font-bold text-xl text-right'>{formatThousands(kgCombustible)}</td>
                                    <td className='text-xs text-left pl-2' >kgCO<sub>2</sub>e</td>
                                </tr>

                            </tbody>
                        </table>
                    }

                    {(kgCombustible === undefined && combustibleConsumption === undefined) &&
                        <div className='text-center w-full flex flex-col md:flex-row justify-evenly text-md'>
                            <div className='text-xs'> {t("footPrint.sinDatoSombustible")}</div>
                        </div>
                    }
                </>
                }
            </div>
        </ div>
    )
}