
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../context/createcontext"

import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.css';
import 'swiper/modules/a11y.css'
import 'swiper/modules/pagination.css';
import 'swiper/modules/scrollbar.css';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation.scss'; // Navigation module
import 'swiper/modules/pagination.scss'; // Pagination module

import mtred from '../../../../images/mtred.webp'
import defra from '../../../../images/defra.webp'
import occ from '../../../../images/occ.webp'
import ademe from '../../../../images/ademe.webp'
import tuv from '../../../../images/logo-tuv.webp'

export default function BloqueLogos() {
    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
    let images = [mtred,defra, tuv, ademe,occ]
    return (
        <section className='max-w-6xl mx-auto px-4 sm:px-6 pb-5' >


            <div className="mb-5 pb-5">
               


               

                   <div className="block md:hidden">
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={50}

                        modules={[Autoplay]}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                            loop: true
                        }}

                        className="mySwiper"
                        loop={true}
                    >
                        {images.map((imageUrl, index) => (
                            <SwiperSlide key={index}>
                                <img src={imageUrl} alt={`Slide ${index + 1}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className="hidden md:block">
                    <Swiper
                        slidesPerView={5}
                        spaceBetween={50}

                        modules={[Autoplay]}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                            loop: true
                        }}

                        className="mySwiper"
                        loop={true}
                    >
                        {images.map((imageUrl, index) => (
                            <SwiperSlide key={index} className='flex'>
                                <img src={imageUrl} alt={`Slide ${index + 1}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
          
           

            </div>
        </section>


    )
}
