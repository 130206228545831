import axios from 'axios';
import { useEffect, useState } from 'react';
import UserService from '../../../../services/UserService';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';


export default function RelatedUsers({ newUser }) {
    const [t, i18n] = useTranslation("global");

    const [userList, setUserList] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fetchRelatedUsers = async () => {
            try {
                const url = `${process.env.REACT_APP_CH_ADMIN_USER}/related`;

                const response = await axios.get(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setUserList({ data: response.data.data, isFetching: true });
            } catch (exception) {
                console.error(exception)
                setUserList({ data: [], isFetching: true });
            }
        };
        fetchRelatedUsers();
    }, [newUser]);

    return (<>
        <div className='container-simple-table'>
            {!userList.isFetching ?
            <div className='flex justify-center'>
                <Spinner className='mt-4' animation="border" variant="dark" />
            </div>
            :
            (userList.data.length > 0 ?
                <table className='simple-table'>
                    <thead>
                        <tr>
                            <th>{t("auth.email")}</th>
                            <th>{t("auth.name")}</th>
                            <th>{t("auth.lastName")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList.data?.map(user => (
                            <tr key={user.id}>
                                <td>{user.email}</td>
                                <td>{user.name}</td>
                                <td>{user.lastName}</td>
                            </tr>))}
                    </tbody>
                </table>
                :
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                <div className="max-w-2xl m-auto mt-16">
                    <div className="text-center px-4">
                        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 dark:from-gray-700 dark:to-gray-800 mb-4">

                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-seeding" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" stroke-dasharray="40" stroke-dashoffset="40">
                                    <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" />
                                </path>
                                <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" stroke-dasharray="40" stroke-dashoffset="40">
                                    <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" begin="3s" />
                                </path>
                                <path d="M12 20l0 -10" stroke-dasharray="10" stroke-dashoffset="10">
                                    <animate attributeName="stroke-dashoffset" from="10" to="0" dur="3s" repeatCount="indefinite" begin="5s" />
                                </path>
                            </svg>
                        </div>
                        <h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-2">!Crea nuevos usuarios para que te ayuden con tu Huella de Carbono!</h2>
                        <div className="mb-6">
                            <p> Aun no tienes usuarios relacionados </p>
                        </div>
                    </div>
                </div>
            </div>
            )
            }
        </div>
    </>)
}