import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import UserService from "../../../services/UserService";
import { Spinner } from 'react-bootstrap';


function FaqsContratoForestal() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const [contractTerms, setContractTerms] =useState();
  const [estipulaciones,setEstipulaciones ] = useState();

useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_FOREST_CONTRACT_TERMS}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${UserService.getToken()}`,
          },
        });
        
        setContractTerms(await response.text());
        
        if (contractTerms && !contractTerms.error) {
          setContractTerms(contractTerms);
        }
      } catch (error) {
        // Handle error if any
        console.error(error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(()=>{
    if(contractTerms!=undefined){
      let style = contractTerms.substring(2590,15939);
     

      style=style.replace(`<ul style="list-style-type: disc;">`, `<ul style="margin-top: 8px; margin-bottom: 8px; margin-left: 32px; list-style-type: disc;">`);
      style=style.replaceAll(`<h2 style='text-align:center'>`,`<h2 style='text-align:center; margin-top: 32px; margin-bottom: 16px; font-weight: 600;'>`);
      style=style.replace(`<h3 style='text-align:center; margin-top:30px; margin-bottom:20px'>`,`<h3 style='text-align:center; margin-top:32px; margin-bottom:32px; font-weight: 600;'>`);
      style=style.replace(`<ol>`,`<ol style="list-style-type: decimal">`);
      style=style.replace(`<ul style='list-style-type: none;'>`,`<ul style='list-style-type: none; margin-top: 8px; margin-bottom: 8px; text-align:center;'>`);
      style=style.replace(`<h3 style='text-align:center'>`,`<h3 style='text-align:center; margin-top:8px; margin-bottom:16px; font-weight:600;'>`);
      style=style.replace(`<ul><li>Masas`,`<ul style="margin-top: 8px; margin-bottom: 8px; margin-left: 32px; list-style-type: disc;"><li>Masas`);
      setEstipulaciones(style);
    }
  },[contractTerms])

return (
      <div className=" sec-neutro-background flex h-screen overflow-hidden">

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
           {/* Content */}
         

           <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("contratoForestal.titulo")} 📜🤝</h1>
                </div>
            </div>    
            {estipulaciones==undefined ? <div className="flex justify-center"><Spinner animation="border" variant="dark"/></div> : 
            <div className="px-4 text-justify " dangerouslySetInnerHTML={{ __html: estipulaciones }}></div>
}
          </div>
        </main>

        </div>

      </div>
  );
}

export default FaqsContratoForestal;
