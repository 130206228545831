import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../context/createcontext";
import BarChart from './../charts/BarChart04bis';

// Import utilities
import format from 'date-fns/format';
import { tailwindConfig } from './../utils/Utils';

function GraficaEmisioneMesGas(props) {

  const data = props.data

  let emisionsMonthData = data.filter(v => v.category === null)

  //Meses únicos
  const monthList = emisionsMonthData.map((x) => {
    return x.month;
  })
  const uniqueMonths = monthList.filter((elemento, index, self) => {
    return index === self.indexOf(elemento)
  });

  //Array de fechas para las Labels
  const currentYear = new Date().getFullYear();

  const monthsLabel = uniqueMonths.map((x) => {
    return format(new Date(currentYear, x - 1, '01'), 'MM/dd/yyyy');
  })


  const co2List = emisionsMonthData.map((v) => {
    return v.co2;
  })

  const ch4List = emisionsMonthData.map((v) => {
    return v.ch4;
  })

  const no2List = emisionsMonthData.map((v) => {
    return v.no2;
  })

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");




  const chartData = {
    labels: monthsLabel,
    datasets: [
      // Blue bars
      {
        label: t("sidebar.co2") + " (CO₂)",
        data: co2List,
        backgroundColor: tailwindConfig().theme.colors.indigo[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
        categoryPercentage: 0.66,
      },
      // Light blue bars
      {
        label:  t("sidebar.ch4") +" (CH4)",
        data: ch4List,
        backgroundColor: tailwindConfig().theme.colors['light-blue'][400],
        hoverBackgroundColor: tailwindConfig().theme.colors['light-blue'][500],
        categoryPercentage: 0.66,
      },

      // Gray bars
      {
        label:  t("sidebar.n2o") +" (N2O)",
        data: no2List,
        backgroundColor: tailwindConfig().theme.colors.gray[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.gray[600],
        categoryPercentage: 0.66,
      },
    ],
  };

  return (
    <div id="gemisionesgas" className="flex flex-col col-span-full sm:col-span-6 bg-white rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-gray-800">{t("dash.emisionesmes")}</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      {
        emisionsMonthData.length === 0 ? <p className='mt-4 text-center'> {t("dash.noemissiondata")} </p> : <BarChart data={chartData} width={595} height={248} unit={" kg"} />
      }
    </div>
  );
}

export default GraficaEmisioneMesGas;
