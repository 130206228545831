
import React, { useState, useContext } from 'react';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';
import amazonas from '../../images/amazonas.svg'
import casa from '../../images/casa.svg'

function BlogList() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <div>

        <div className="text-center">
          <h2 className="tracking-tighter  mt-5 text48 sec-blue-text "  data-aos="zoom-y-out">{t("foretext.amazonas")}</h2>
          <h3 className="tracking-tighter text30 sec-blue-text " data-aos="zoom-y-out">{t("foretext.casa")} </h3>

          <div className="max-w-3xl mx-auto">


          </div>
        </div>




        {/* Sticky article */}
        <section >

          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="">

              <article className="max-w-sm mx-auto  md:max-w-none md:flex md:items-center">
                {/* Image */}
                <div className="relative block group overflow-hidden md:w-1/2" data-aos="fade-down">
                 
                  <img className="object-cover group-hover:scale-105 transition duration-700 ease-out" src={amazonas} width="540" height="340" alt="amazonas" />
                  <div className="absolute top-6 right-6">
                    
                  </div>
                </div>
                {/* Content */}
                <div className="md:w-1/2" data-aos="fade-left">

                  <p className=" sec-white-text text18 grow font-playfair-display" >{t("foretext.cadaarbol")}</p>

                </div>
              </article>
              <article className="max-w-sm mx-auto  md:max-w-none md:flex md:items-center">
                {/* Content */}
                <div className="md:w-1/2" data-aos="fade-right">


                  <p className=" sec-white-text text18 grow font-playfair-display" >{t("foretext.airco2")}</p>

                </div>
                <div className='' id='bannerMetodologia'></div> 
                {/* Image */}
                <div className="relative block group overflow-hidden md:w-1/2" data-aos="fade-down">
                  <img className="object-cover group-hover:scale-105 transition duration-700 ease-out" src={casa} width="540" height="340" alt="casa" />
                  <div className="absolute top-6 right-6">
                  
                  </div>
                  
                </div>
              </article>

             

             


            </div>

          </div>
          
        </section>
      
      </div>
    </>
  );
}

export default BlogList;