import axios from "axios";
import { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import TermsHeader from "./TermsHeader";
import { Spinner } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

export default function Terms() {

    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");

    const [terms, setTerms] = useState({ data: undefined, isFetching: false });
    const [letters, setLetters] = useState([]);

    const [activeLetter, setActiveLetter] = useState('');
    const stickyContainerRef = useRef(null); // Referencia para el contenedor sticky


    useEffect(() => {
        const fetchTerms = async () => {
            const url = `${process.env.REACT_APP_PUBLIC_TERMS}?lng=${lng}`
            try {
                setTerms({ data: terms?.data, isFetching: true });
                const response = await axios.get(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
                const groupedByLetter = response.data.data.reduce((acc, term) => {
                    const letter = term.title[0].toUpperCase();
                    if (!acc[letter]) {
                        acc[letter] = [];
                    }
                    acc[letter].push(term);
                    return acc;
                }, {});
                const letters = Object.keys(groupedByLetter).sort();
                letters.forEach(letter => {
                    groupedByLetter[letter] = groupedByLetter[letter].sort((a, b) => a.title.localeCompare(b.title));
                });

                setTerms({ data: groupedByLetter, isFetching: false });
                setLetters(letters)
            } catch (exception) {
                setTerms({ data: [], isFetching: false });
                console.error('Error al realizar la solicitud:', exception);
            }
        };

        fetchTerms();
    }, [lng]);



     useEffect(() => {
        const handleScroll = () => {
          const headerOffset = 120; // altura del header
          const stickyContainer = stickyContainerRef.current;
  
          if(stickyContainer){
            const stickyRect = stickyContainer.getBoundingClientRect();
            for (const letter of letters) {
              const section = document.getElementById(letter);
              if (section) {
                const sectionRect = section.getBoundingClientRect();
  
                // Calcula la posición relativa al contenedor sticky
                const relativeTop = sectionRect.top - stickyRect.top;
  
  
                if (relativeTop <= headerOffset && relativeTop + sectionRect.height >= headerOffset) {
                    setActiveLetter(letter);
                    break;
                }
              }
            }
          }
        };
  
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [letters]);

    return (<>
        <TermsHeader></TermsHeader>
        {!terms.data && terms.isFetching && <div className="flex justify-center mt-4 mb-4">
            <Spinner animation="border" variant="dark"></Spinner>
            {t("glossary.charging")}</div>
        }
        {terms.data && !terms.isFetching &&
            <div className="max-w-6xl mx-auto px-4 sm:px-6 m-4" data-aos="fade-down" data-aos-delay="200">
                <div className="row">
                    <div className="col-2 col-md-2"  ref={stickyContainerRef}>
                        <div className="sticky block" style={{ height: "auto", top:"120px"}}> 
                            <div className="flex flex-col rounded-sm border" style={{ justifySelf: "center" }}>
                                {letters?.map(letter => (
                                    <a key={letter}
                                        href={`#${letter}`}
                                        className={`px-4 py-2  text-lg ${activeLetter === letter ? 'font-bold text-airco2-principal' : ''}`}
                                        onClick={() => { setActiveLetter(letter); }}
                                    >
                                        {letter}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Muestra los términos agrupados por letra */}
                    <div className="terms-list col-10 col-md-10">
                        {letters?.map(letter => (
                            <div key={letter} id={letter} className="mb-8 glossary-scroll" >
                                <h2 className="text-3xl font-bold mt-4 mb-2">{letter}</h2>
                                <ul>
                                    {terms?.data[letter]?.map(term => (
                                        <li key={term.title} className="pb-3">
                                            <h3 className="font-bold text-xl">{term.title}</h3>
                                            <p dangerouslySetInnerHTML={{ __html: term.summary }} />
                                            <HashLink
                                                to={`/resources/glossary/${term.slug}`}
                                                className="text-sm text-airco2-principal flex items-center"
                                                smooth={true}
                                                offset={50}
                                                duration={500}>
                                                {t("glossary.read")}
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="1">
                                                    <path d="M5 12l14 0"></path>
                                                    <path d="M15 16l4 -4"></path>
                                                    <path d="M15 8l4 4"></path>
                                                </svg>
                                            </HashLink>

                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        }
    </>)
}