import UserService from "../../services/UserService";
import { Navigate } from 'react-router-dom';

import { useEffect, useState } from 'react';

const RenderOnEmpresa = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
  
    useEffect(() => {
      if (!UserService.isLoggedIn()) {
        const query = "?originUrl=" + window.location.pathname;
        UserService.doLogin(query);
      } else {
        setIsAuthenticated(true);
      }
    }, []);
  
    if (!isAuthenticated) {
      return null; 
    }
  
    if (UserService.getUserType().toLowerCase() === 'propietario forestal') {
      return <Navigate to="/forest/dashboardmontes" replace={true} />;
    }
  
    if (UserService.getUserType() === undefined) {
      return <Navigate to="/adminusuarios" replace={true} />;
    }
  
    return children;
  };
  
  export default RenderOnEmpresa;
  