import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import UserService from '../../../services/UserService';
import { formatThousands } from '../utils/Utils';
import unveriefFootprint from '../../../images/unverifiedFootprint.svg';
import veriefFootprint from '../../../images/verifiedFootprint.svg';
import Card from "react-bootstrap/Card";
import { Spinner } from 'react-bootstrap';
import logo from '../../../images/Logo verde_airCO2.png'

export default function CreateCertification({ certificationStep, changeStep, getNewCertification, userId }) {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [selectedCalculation, setSelectedCalculation] = useState();
    const [isVerified, setIsVerified] = useState(null);
    const [newCertification, setNewCertification] = useState({
        data: [],
        isFetching: false
    });

    const [calculosUsuario, setCalculosUsuario] = useState({
        data: [],
        isFetching: false
    });

    const footprinttTypes = [
        { id: 1, verified: false, name: "Huella no verificada", description: "Se tratan de informes de huella de carbono que no han pasado un proceso de auditoría para la verificación de los resultados. Por ello, el MITECO tendrá que revisarlos y el proceso de inscripción llevará más tiempo.", img: unveriefFootprint },
        { id: 2, verified: true, name: "Huella verificada", description: "Se tratan de informes de huellas de carbono que han pasado por un proceso de auditoría para la verificación de los cálculos. Las huellas de carbono bajo esta categoría son inscritas más rápido al tratarse de cálculos que ya han sido revisados por entidades acreditadas. Si quieres optar por esta vía y dotar a tus informes de un mayor aseguramiento, consúltanos para poder ayudarte", img: veriefFootprint },
    ]


    useEffect(() => {

        const fetchCalculoUsuario = async () => {
            setCalculosUsuario({ data: calculosUsuario.data, isFetching: true });
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_CALCULO}?userId=${userId}&calculationStatus=FINALIZED`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                let objeto = response.data;
                setCalculosUsuario({ data: objeto, isFetching: false });

            } catch (exception) {
                console.error(exception);
                setCalculosUsuario({ data: [], isFetching: false });
            }
        };
        fetchCalculoUsuario();
    }, [])


    const fetchCreateCertification = async () => {
        setNewCertification({ data: newCertification.data, isFetching: true });
        try {
            let body = {
                userId: userId,
                calculationId: selectedCalculation.id,
                certificationType: "MITECO",
                isCalculationVerified: isVerified
            };

            const response = await axios.post(`${process.env.REACT_APP_CH_CERTIFICATION}`, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            setNewCertification({ data: response.data, isFetching: false });
            getNewCertification(response.data.data)

            let url = `/footprint/certification/${response.data.data.id}`;
            window.history.pushState({}, '', encodeURI(url));

        } catch (exception) {
            console.error(exception);
            setNewCertification({ data: [], isFetching: false });
        }
    };


    const backcToCalculationSelect = () => {
        setIsVerified(null);
        changeStep(1);
    }


    const createCertification = () => {
        fetchCreateCertification();
    }


    return (

        <div className={` mt-2 ${certificationStep == 1 && 'bg-white'} rounded-lg w-full h-full p-4`}  style={{minHeight: '400px'}}>
            {certificationStep == 1 &&
                <>
                    {!calculosUsuario.isFetching && <h2 className='text-md mt-2 mb-2 text-airco2-general-grey'>Selecciona el cáculo a registrar:</h2>}
                    {calculosUsuario.isFetching ? <div className='flex justify-center'> <Spinner animation="border" variant="dark" /> </div> :
                        <div className='row'>
                            <div className="container-simple-table width-66percent col-12 md:col6">
                                <table className="simple-table">
                                    <thead className='text-center  text-xl sec-green-background text-white'>
                                        <tr className='py-4'>
                                            <th>{t("footPrint.organizacion")}</th>
                                            <th>{t("footPrint.year")}</th>
                                            <th>Tipo de cálculo</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {calculosUsuario.data.data != undefined && calculosUsuario.data.data.map((calculo) => {
                                            if (calculo?.certificationId == null) {
                                                return (
                                                    <tr className='cursor-pointer' key={calculo.id} onClick={() => setSelectedCalculation(calculo)} style={{ border: selectedCalculation?.id == calculo.id ? '2px solid var(--airco2-orange-color)' : 'none' }}>
                                                        <td>{calculo.organization.name}</td>
                                                        <td>{calculo.year}</td>
                                                        <td>{calculo.questionnaire.name}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='width-33percent col-12 md:col6 mt-4 md:mt-0 flex justify-center items-center'>
                                {selectedCalculation != undefined &&
                                    <div className={`box-calculation p-3 relative`} >
                                        <div>
                                            <img src={selectedCalculation.organization.urlLogo} style={{
                                                position: 'absolute',
                                                opacity: '0.05',
                                                width: '75%',
                                                height: 'auto',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)'
                                            }}
                                            />
                                            <div className='name-cif mt-2 mb-2' style={{ minHeight: '50px' }}>
                                                <p className='block text-xl font-bold text-airco2-principal '>{selectedCalculation.organization.name}  </p>
                                                <p className='block mt-1 text-lg font-medium text-airco2-principal'>{selectedCalculation.year}  </p>
                                            </div>
                                            <div className='type-cnae mt-2 mb-2' style={{ minHeight: '40px' }}>
                                                <p className='block mt-1 text-xs font-medium text-airco2-general-grey'>Cálculo {selectedCalculation.questionnaire.name}  </p>
                                            </div>
                                            <div className='sede&Actions flex mt-2 items-center justify-between'>
                                                <span className='block text-md font-medium text-airco2-general-grey'><span>{formatThousands(selectedCalculation.totalKgCO2e)}</span> kg CO2e </span>
                                                <span className='actions flex gap-3' />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className='flex justify-end mt-4 mr-4'>
                        <button className='btn-sm btn-green ' onClick={() => changeStep(2)} disabled={selectedCalculation == undefined}>Continuar</button>
                    </div>
                </>
            }

            {certificationStep == 2 &&
                ((newCertification.data?.data == undefined && !newCertification.isFetching) ?
                    <div>
                        <div className='pt-5 flex flex-wrap gap-3' style={{ justifyContent: 'space-around' }}>
                            {selectedCalculation != undefined &&
                                footprinttTypes.map((x, i) => {
                                    return (
                                        <Card key={i}

                                            onClick={() => setIsVerified(x.verified)}
                                            style={{ width: '20rem', height: '25rem', borderRadius: '20px', border: (isVerified != null && isVerified == x.verified) ? '3px solid var(--airco2-green-color)' : 'none', }}
                                            className=' p-1 flex  shadow-lg   transform hover:scale-105 transition duration-700 ease-out'>
                                            <Card.Title className='flex justify-center'>
                                                <img src={x.img} width={x.id == 1 ? 150 : 110} className={`py-2 `} />
                                            </Card.Title>
                                            <Card.Body className={`flex flex-col text-airco2-general-grey`}>
                                                <span className='text-md text-center font-semibold'>{t(`${x.name}`)} </span>
                                                    <div className='text-sm text-justify'>{x.description}</div>
                                            </Card.Body>
                                        </Card>
                                    )
                                })
                            }
                        </div>
                        <div className='flex justify-center mt-4 mr-4 gap-4'>
                            <button className='btn-sm btn-cancel ' onClick={() => backcToCalculationSelect()}>Atrás</button>
                            <button className='btn-sm btn-green ' onClick={() => createCertification()} disabled={isVerified == null || newCertification.isFetching}>
                                {newCertification.isFetching ? <Spinner animation="border" variant="dark" /> : "Continuar"}
                            </button>
                        </div>
                    </div>
                    :
                    <div>
                        <div className=" h-full flex justify-center items-center sec-neutro-background ">
                            <div className="relative ">
                                <img src={logo} alt="logo" className='mx-auto' style={{ width: "323px" }} />
                                <div className="text-center text-lg absolute font-semibold sec-grey-text mb-4" style={{ top: "150px", left: "52px" }}>
                                    Cargando documentación
                                </div>
                                <div className="flex justify-center items-center absolute" style={{ top: "85px", left: "191px" }}>
                                    <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>

    )
}