import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";
import { IdiomContext } from "../../../context/createcontext";
import Header from '../Header';
import Sidebar from '../Sidebar';
import ReductionCommitmentComponent from './ReductionCommitmentComponent';

export default function ReductionCommitment() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const location = useLocation();
  const pathParams = useParams();
  const data = location?.state?.data
  const idCalculation = pathParams.id;
  
  if (location.state === null || location.state === undefined) {
    window.location.href = "/footprint/myfootprint";

  }
  
  const updateDocumentation = (props) => {

  }

  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <button arial-label="btn-back" className="ml-4 mt-2 text-base font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
            </svg>
          <span>{t("footPrint.volver")}</span>
          </button>
        <ReductionCommitmentComponent organizationName={data.orgName} calcualtionYear={data.year} idCalculation={idCalculation} isModal={false} updateDocumentation={updateDocumentation} idCertification={null}/>
        
      </div>
    </div>
  )
}