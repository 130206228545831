
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {
  Link
} from 'react-router-dom'
import Header from './../Header';
import Sidebar from './../Sidebar';
import format from 'date-fns/format';
import UserService from '../../../services/UserService';

function AdminBanco() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-3">

              {/* Left: Title */}
              {/* <div> */}
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Banco de montes</h1>
                {/* <h2 className='text-xl md:text-2xl text-gray-800 font-bold pl-2'>$47,347.09</h2> */}
              {/* </div> */}



            </div>

            <div className="panel panel-default">

              <AdminComponent />

            </div>
          </div>

        </main>
      </div>
    </div>
  );
}

// }


function AdminComponent() {
  // constructor(props, context) {
  //   super(props, context);

  //   this.state = {
  //     usuarios: [],
  //     montes: [],
  //     show: false,
  //     userId: '',
  //     name: '',
  //     size: '',
  //     forestType: '',
  //     cadastre: '',
  //     showempresas: false,
  //     anualCO2: ''
  //   };
  //   this.updatebanco = this.updatebanco.bind(this)

  // };


  const [usuarios, setUsuarios] = useState([]);
  const [montes, setMontes] = useState([]);
  const [banco, setBanco] = useState([])
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [forestType, setForestType] = useState("");
  const [forestId, setForestId] = useState("");
  const [cadastre, setCadastre] = useState("");
  const [anualCO2, setAnualCO2] = useState("");
  const [forest, setForest] = useState(undefined);
  const [forestState, setForestState] = useState('');

  const [showempresas, setShowempresas] = useState(false);
  const [filterList, setFilterList] = useState('');

  const [loading, setLoading] = useState(true);
  const renderMontes = () => {
    return (
      <>


        {
          banco.map((row2, j) => {
            return (
              <>
                <tr key={j}>
                  <td style={{ minWidth: '100px',}}>{row2.forest.name}</td>
                  <td>{row2.percentage}</td>
                  <td>{row2.forest.size}</td>
                  <td>{row2.forest.userId.substring(0, 8)}..</td>
                  <td >{row2.forest.anualCO2}</td>
                  <td>{row2.forest.availableCO2}</td>
                  <td ></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                {
                  row2.forest.transactions ?
                    row2.forest.transactions.map((row3, k) => {
                      return (

                        <tr className="text-xs" key={k}>

                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                         
                          <td style={{ minWidth: '100px' }} >{format(new Date(row3.transaction.date),'dd/MM/yyyy')}</td>
                          <td style={{ minWidth: '100px' }}>{row3.transaction.userId.substring(0, 8)}..</td>
                          <td>{row3.quantity}</td>
                          <td>{row3.transaction.co2Amount}</td>
                          <td>{row3.transaction.co2Price}</td>
                        </tr>

                      );
                    })

                    : null
                }
              </>
            );
          })

        }

      </>
    );
  }
  // usuarios: [],
  //     montes: [],
  //     show: false,
  //     userId: '',
  //     name: '',
  //     size: '',
  //     forestType: '',
  //     cadastre: '',
  //     showempresas: false,
  //     anualCO2: ''


  useEffect(() => {
    const fetchAllEmissionsData2 = async () => {
      setLoading(true)
      try {
         await axios.get(`${process.env.REACT_APP_ADMIN_BANCO}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setBanco(response.data);
        });


      } catch (exception) {
   ;
      }
      setLoading(false);

    };
    fetchAllEmissionsData2();
  }, []);



  const updatebanco = () => {
    setShowempresas(true)
    const fetchAllEmissionsData2 = async () => {
      setLoading(true)
      try {
         await axios.get(`${process.env.REACT_APP_ADMIN_BANCO}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setBanco(response.data);
        });


      } catch (exception) {
   
      }
      setLoading(false);

    };
    fetchAllEmissionsData2();
    
  }


  const handleClose = () => {
    setUserId('');
    setShow(false);
    setName('');
    setSize('');
    setForestType('');
    setCadastre('');
    setAnualCO2('');
  }
  const handleShow = () => setShow(true);



  const handleSubmit = (e) => {

    e.preventDefault()

    axios.post(`${process.env.REACT_ADMIN_BANCO_TR}`, JSON.stringify({ paymentId: e.target.paymentId.value, userId: e.target.userid.value, co2Amount: e.target.cantidad.value }), {
      headers: {
        // 'application/json' is the modern content-type for JSON, but some
        // older servers may use 'text/json'.
        // See: http://bit.ly/text-json
        'content-type': 'application/json'
      }
    })
      .then(response => console.log("postok"));

    setShow(false)
    updatebanco()
  }

  // render() {


  return (
    <div className=" sec-beige ">

      <Modal show={show} >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          <Modal.Title>Edición de Propietario Forestal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form onSubmit={(e) => handleSubmit(e)}> */}
          <Form className='text-xs' onSubmit={(e) => handleSubmit(e)}>
            <FormGroup>
              <Label for="name">usuario</Label>
              <Input className='text-xs' type="text" name="userid" id="userid" placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="name">cantidad</Label>
              <Input className='text-xs' type="text" name="cantidad" id="cantidad" placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="name">paymentId</Label>
              <Input className='text-xs' type="text" name="paymentId" id="paymentId" placeholder="" />
            </FormGroup>



            <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" type="submit">Crear</Button>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => handleClose()}>
            Cerrar
          </Button>

        </Modal.Footer>
      </Modal>

      <div>
        <ul className="flex flex-wrap justify-center mb-3">
          <li className="m-1">
            <button onClick={() => updatebanco()} className='text-sm font-semibold text-white  btn sec-green-background rounded-lg'  aria-pressed="true" >Actualizar</button>
          </li>

          <li className="m-1">
            <button onClick={() => handleShow()} className='text-sm font-semibold text-white  btn sec-green-background rounded-lg' aria-pressed="true" style={{maxWidth:'130px'}}>Añadir transacción</button>
          </li>



        </ul>
      </div>
      <div className='table-responsive'>



        <table id="bancoTable"  className="table-bordered table-striped text-xs  table-auto w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th>Monte</th>
              <th>Porcentaje Banco</th>
              <th>Tamaño</th>

              <th>Propieterio</th>
              <th>Capadicad Absorción</th>
              <th>Absorcion Disponible</th>
              <th>Fecha</th>
              <th>Empresa</th>
              <th>Cantidad</th>
              <th>Cant. Total</th>
              <th>Precio CO₂</th>

            </tr>
          </thead>
          <tbody classname='text-xs'  id="bancoTable">{renderMontes()}</tbody>
        </table>
  
    </div >
    </div >
  )


}


export default AdminBanco;
