import React, { useEffect, useState, useContext } from "react";

import axios from "axios";
import { Spinner } from "react-bootstrap";
import UserService from "../../../services/UserService";
import DeleteButton from "../utils/DeleteButton";
import FilterButton from "../utils/DropdownFilter";
import EmissionsTable from "./EmissionsTable";
import Header from "../Header";
import PaginationClassic from "../PaginationClassic";
import Sidebar from "../Sidebar";
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from "react-i18next";
import DownExcel from "./DownExcel";
import Translations from "../../../services/Translations";
import FilterYear from "./FilterYear";
import { yearsToMonths } from "date-fns";

function Emissions() {
  const [loading, setLoading] = useState(true);

  const [emisionYear, setEmisionYear] = useState();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_EMISSIONS_YEARS}/${UserService.getUserId()}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${UserService.getToken()}`,
        },
      }
    )
      .then((j) => j.json())
      .then((r) => {
        setEmisionYear(r);
      });
  }, []);

  const currentYear = new Date().getFullYear();

  let maxYears =
    emisionYear === undefined ? currentYear : Math.max(...emisionYear);
  useEffect(() => {
    maxYears;
  }, [emisionYear]);

  //funcion que recibe el año del hijo
  let [envF, updateEnv] = useState(maxYears);

  useEffect(() => {
    updateEnv(maxYears);
  }, [emisionYear]);

  const actualizar = (v) => {
    updateEnv(v);
  };

  //Obtiene el resumen de las emisiones de una empresa en un intervalo /emissions/v1/user/summary OKK
  const [emisionSummary, setEmisionSummary] = useState({
    emisionSummaryData: [],
    isFetching: false,
  });

  useEffect(() => {
    const fetchEmisionSummaryData = async () => {
      try {
        setEmisionSummary({
          emisionSummaryData: emisionSummary.emisionSummaryData,
          isFetching: true,
        });
        const response = await axios.get(
          `${
            process.env.REACT_APP_EMISSIONS_USER_ALL
          }/${UserService.getUserId()}/${envF}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        );
        setEmisionSummary({
          emisionSummaryData: response.data,
          isFetching: false,
        });
      } catch (exception) {
        setEmisionSummary({
          emisionSummaryData: emisionSummary.emisionSummaryData,
          isFetching: false,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchEmisionSummaryData();
  }, [envF]);

  // CATEGORIES
  const transactionstotal = emisionSummary.emisionSummaryData;
  const transactions = transactionstotal
    .filter((v) => v.category != null)
    .sort((a, b) => b.accountingDate.localeCompare(a.accountingDate));


  const categoriesUniq = [];
  transactions
    .map((v) => v.category)
    .map((c) => {
      if (categoriesUniq.indexOf(c.name) === -1) {
        categoriesUniq.push(c.name);
      }
    });

  const nameCategory = categoriesUniq;

  const categoriesList = [];
  nameCategory.map((v) => {
    categoriesList.push({
      category: Translations.translateCategories(v),
      check: false,
      categoryName: v,
    });
  });

  const [stateCheck, setCheck] = useState([]);
  useEffect(() => {
    setCheck([...categoriesList]);
  }, []);

  const applyCategoryFilter = (data) => {
    setCheck([...data]);
    updateTableLines(0, itemPage, data);
    setCurrentPage(0);
  };

  // END CATEGORIES

  const [filtroCategorias, setFiltroCategorias] = useState(transactions);

  const updateTableLines = (firstIndex, itemPage, data) => {
    if (data == null) {
      data = stateCheck;
    }
    let categories = data.filter((c) => c.check).map((c) => c.categoryName);

    let filteredList = [...datosApi];

    if (categories.length > 0) {
      filteredList = filteredList.filter((c) =>
        categories.includes(c.category["name"])
      );
    }
    setFiltroCategorias([...filteredList]);
    setTotalElement(filteredList.length);
    setLastPage(Math.ceil(filteredList.length / itemPage) - 1);
    setItems(filteredList.splice(firstIndex, itemPage));
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  // PAGINACIÓN

  const [currentpage, setCurrentPage] = useState(0);
  const itemPage = process.env.REACT_APP_ITEMS_PER_PAGE;

  const [datosApi, setdatosApi] = useState(transactions);
  const [totalElement, setTotalElement] = useState(transactions.length);
  const [lastPage, setLastPage] = useState(
    Math.ceil(transactions.length / itemPage) - 1
  );

  const [items, setItems] = useState([...transactions].splice(0, itemPage));
  useEffect(() => {
    setdatosApi(transactions);
    setTotalElement(transactions.length);
    setLastPage(Math.ceil(transactions.length / itemPage) - 1);
    setItems(transactions.slice(currentpage * itemPage, itemPage));
  }, [transactionstotal]);

  const nextHandler = () => {
    const nextpage = currentpage + 1;
    const firstIndex = nextpage * itemPage;
    if (firstIndex >= totalElement) return;

    updateTableLines(firstIndex, itemPage);
    setCurrentPage(nextpage);
  };

  const prevHandler = () => {
    const prevPage = currentpage - 1;
    if (prevPage < 0) return;
    const firstIndex = prevPage * itemPage;

    updateTableLines(firstIndex, itemPage);
    setCurrentPage(prevPage);
  };

  const firstHandler = () => {
    updateTableLines(0, itemPage);
    setCurrentPage(0);
  };

  const lastHandler = () => {
    updateTableLines(lastPage * itemPage, itemPage);
    setCurrentPage(lastPage);
  };

  const pointFirst = () => {
    const prevPage = currentpage - 2;
    if (prevPage < 0) return;
    const firstIndex = prevPage * itemPage;

    updateTableLines(firstIndex, itemPage);

    setCurrentPage(prevPage);
  };

  const pointLast = () => {
    const nextpage = currentpage + 2;
    const firstIndex = nextpage * itemPage;
    if (firstIndex >= totalElement) return;

    updateTableLines(firstIndex, itemPage);
    setCurrentPage(nextpage);
  };

  // cambiar el idioma

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  {t("sidebar.emisiones")}
                </h1>
                {/* <h2 className='text-xl md:text-2xl text-gray-800 font-bold pl-2'>$47,347.09</h2> */}
              </div>

              {/* filtro por años */}

              <div className="mb-4 sm:mb-0">
                {emisionYear === undefined ? (
                  t("sidebar.cargando") + "..."
                ) : (
                  <FilterYear
                    emisionYear={emisionYear}
                    actualizar={actualizar}
                  />
                )}
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
                <DeleteButton selectedItems={selectedItems} />
                {/* Dropdown */}
                {/* <DateSelect /> */}
                {/* Filter button */}
                <FilterButton
                  align="left"
                  // categoriesUniq={categoriesUniq}
                  categoriesUniq={nameCategory}
                  stateCheck={categoriesList}
                  applyCategoryFilter={applyCategoryFilter}
                  envF={envF}
                />
                {/* <FilterYear align="right"
                  unicosYear={unicosYear}
                  stateCheckYear={yearList}
                  applyYearFilter={applyYearFilter}

                />
*/}
                <DownExcel
                  align="right"
                  transactions={transactions}
                  filtroCategorias={filtroCategorias}
                />
              </div>
            </div>

            {/* Table */}
            {transactions.length === 0 ? (
              loading ? (
                <p>
                  {" "}
                  <Spinner animation="border" className="flex justify-center" />
                </p>
              ) : (
                <p> {t("sidebar.noemisiones")}.</p>
              )
            ) : (
              <EmissionsTable
                selectedItems={handleSelectedItems}
                transactions={items}
                currentpage={currentpage}
              />
            )}

            {/* Pagination */}
            <div className="mt-8">
              {transactions.length === 0 ? (
                ""
              ) : (
                <PaginationClassic
                  itemPage={itemPage}
                  currentpage={currentpage}
                  items={items}
                  nextHandler={nextHandler}
                  prevHandler={prevHandler}
                  total={transactions}
                  firstHandler={firstHandler}
                  lastHandler={lastHandler}
                  lastPage={lastPage}
                  pointFirst={pointFirst}
                  pointLast={pointLast}
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Emissions;
