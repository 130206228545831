import React, { useContext } from 'react';
import { IdiomContext } from "./../../../context/createcontext"
import { useTranslation } from 'react-i18next';
function BillingPanel() {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (




    <section>
      <h3 className="text-xl leading-snug text-gray-800 font-bold mb-1">Información Facturación</h3>
      
      <ul>
        <li className="md:flex md:justify-between md:items-center py-3 ">
          {/* Left */}
          <div className="text-sm text-gray-800 font-medium">{t("account.no-payments")} </div>

        </li>

      </ul>
    </section>





  );
}

export default BillingPanel;