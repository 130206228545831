import Keycloak from "keycloak-js";
import PreviousMap from "postcss/lib/previous-map";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { IdiomContext } from "../context/createcontext";
import CryptoJS from "crypto-js";
import { useCallback } from "react";
import { getDashboardUrl } from "./Functions";
import { getLogin } from "./FunctionsDataWhiteLabel";

const _kc = new Keycloak("/keycloak.json");
const urlForgotPassword = process.env.REACT_APP_K_FORGOTPASSWORD; //TODO: revisar

const MY_AUTH_APP = "MY_AUTH_APP";
const MY_AUTH_TOKEN = "MY_AUTH_TOKEN";
const MY_AUTH_TOKEN_R = "MY_AUTH_TOKEN_R";
const MY_AUTH_ENCRYPT_PASS = "passWord25874AirCo2419_";
const token = CryptoJS.AES.decrypt(
  localStorage.getItem(MY_AUTH_TOKEN) ?? "",
  MY_AUTH_ENCRYPT_PASS
);
const tokenRefresh = CryptoJS.AES.decrypt(
  localStorage.getItem(MY_AUTH_TOKEN_R) ?? "",
  MY_AUTH_ENCRYPT_PASS
);
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  let tokenEnc = "";
  let tokenRefreshEnc = "";
  try {
    tokenEnc = token.toString(CryptoJS.enc.Utf8);
    tokenRefreshEnc = tokenRefresh.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error(error);
    logout();
  }

  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      checkLoginIframe: false, // para bug en Firefox: evita que se pierda la sesión al navegar
      pkceMethod: "S256",
      token: tokenEnc,
      refreshToken: tokenRefreshEnc,
    })
    .then((authenticated) => {
      if (!authenticated) {
        logout();
      } else {
        login();
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

// const doLogin = _kc.login;

const doLogin = (query) => {
  window.location.href = "/login" + query;
};

const urlLogin = () => _kc.createLoginUrl();
const doLoginIdioma = (origin) => {
  let storedParam = localStorage.getItem("param");
  const [lng, updateLng] = useContext(IdiomContext);
  window.location.href = storedParam
    ? getLogin(storedParam)
    : `/login?originUrl=${origin}`;
};

const doLogout = _kc.logout;

const doRegistration = () => _kc.createRegisterUrl();

const forgotPassword = () => urlForgotPassword;

const getToken = () => _kc.token;

const isLoggedIn = () => !!localStorage.getItem(MY_AUTH_TOKEN) && !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUserId = () => _kc.tokenParsed?.sub;
const getUsername = () => _kc.tokenParsed?.preferred_username;

const getUserType = () => _kc.tokenParsed?.usertype;
var getName = () => _kc.tokenParsed?.name;
var getLastName = () => _kc.tokenParsed?.lastName;
var getCompanyName = () =>
  _kc.tokenParsed?.companyName == undefined ? "" : _kc.tokenParsed?.companyName;
// var getSector = () =>
//   _kc.tokenParsed?.sector == undefined ? "" : _kc.tokenParsed?.sector;
var getPhone = () =>
  _kc.tokenParsed?.phone == undefined ? "" : _kc.tokenParsed?.phone;
var getDni = () =>
  _kc.tokenParsed?.dni == undefined ? "" : _kc.tokenParsed?.dni;
var getCustomerId = () =>
  _kc.tokenParsed?.stripeCustomerId == undefined
    ? ""
    : _kc.tokenParsed?.stripeCustomerId;
var getPlan = () =>
  _kc.tokenParsed?.plan == undefined ? userPlan : _kc.tokenParsed?.plan;
var getPlanEnd = () =>
  _kc.tokenParsed?.planEnd == undefined
    ? userPlanExpiry
    : _kc.tokenParsed?.planEnd;
var getOriginData = () =>
  _kc.tokenParsed?.originData ? _kc.tokenParsed.originData : "Formulario";
var getTitularCuenta = () =>
  _kc.tokenParsed?.titularCuenta == undefined
    ? ""
    : _kc.tokenParsed?.titularCuenta;
var getDpostal = () =>
  _kc.tokenParsed?.dpostal == undefined ? "" : _kc.tokenParsed?.dpostal;
var getBankingEntity = () =>
  _kc.tokenParsed?.bankingEntity == undefined
    ? ""
    : _kc.tokenParsed?.bankingEntity;
var getIban = () =>
  _kc.tokenParsed?.iban == undefined ? "" : _kc.tokenParsed?.iban;
var getMunicipio = () =>
  _kc.tokenParsed?.municipioCode == undefined
    ? ""
    : _kc.tokenParsed?.municipioCode;
var getCodigoPostal = () =>
  _kc.tokenParsed?.codigoPostal == undefined
    ? ""
    : _kc.tokenParsed?.codigoPostal;

const getHasCoinscrap = () =>
  _kc.tokenParsed?.coinscrap == undefined ? false : _kc.tokenParsed?.coinscrap;
const getCoinscrapDate = () =>
  _kc.tokenParsed?.coinscrapDate == undefined
    ? ""
    : _kc.tokenParsed?.coinscrapDate;

const getFootprint = () => _kc.tokenParsed?.footprintcalculator;
const getwhiteLabel = () =>
  _kc.tokenParsed?.whiteLabel == undefined ? "" : _kc.tokenParsed?.whiteLabel;

var showCoinscrap = true;

const setShowCoinscrap = (show) => {
  showCoinscrap = show;
};

const getShowCoinscrap = () => showCoinscrap;

const getAvatar = () => _kc.tokenParsed?.avatar;

const updateKeycloackToken = () => {
  _kc
    .updateToken(30)
    .then(function () {
      loadData();
    })
    .catch(function () {
      alert("Failed to refresh token");
    });
};

const userProfile = () => {
  return _kc
    .loadUserProfile()
    .then(function (profile) {
      return profile;
    })
    .catch(function () {
      alert("Failed to load user profile");
    });
};

const updateProfile = async (
  name,
  lastName,
  phone,
  companyName,
  dni,
  titularCuenta,
  dpostal,
  bankingEntity,
  iban,
  codigoPostal,
  municipioCode
) => {
  var url = process.env.REACT_APP_K_UPDATEPROFILE + getUserId();
  var data = `{ "name": "${name}",  "surname": "${lastName}", "phone":"${phone}",  "companyName": "${companyName}",  

  "dni": "${dni}",   "titularCuenta": "${titularCuenta}",   "dpostal": "${dpostal}", 
   "bankingEntity": "${bankingEntity}",   "iban": "${iban}", "codigoPostal": "${codigoPostal}", "municipioCode": "${municipioCode}" }"`;

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${UserService.getToken()}`,
    },
    body: data,
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.error) {
        return false;
      }
      getName = responseJson.firstName;
      getCompanyName = responseJson.companyName;
      getLastName = responseJson.surname;
      getPhone = responseJson.phone;
      getDni = responseJson.dni;
      getCustomerId = responseJson.stripeCustomerId;
      getPlan = responseJson.plan;
      getPlanEnd = responseJson.planEnd;
      getTitularCuenta = responseJson.titularCuenta;
      getDpostal = responseJson.dpostal;
      getBankingEntity = responseJson.bankingEntity;
      getIban = responseJson.iban;
      getMunicipio = responseJson.municipioCode;
      getCodigoPostal = responseJson.codigoPostal;

      return true;
    });
};

const resetPassword = (password) => {
  var url = process.env.REACT_APP_K_RESETPASSWORD + getUserId();
  var data = `{ "newPassword": "${password}"  }"`;

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${UserService.getToken()}`,
    },
    body: data,
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.error) {
        return false;
      }
      return true;
    });
};

var userPlan = "";
var userPlanExpiry = "";
const setPlan = (nombre, expiryDate) => {
  userPlan = nombre;
  userPlanExpiry = expiryDate;
};

const setLogin = (token, tokenRefresh, urlRedirect) => {
  // let url = "/company/dashboardintranet";
  let url = "";
  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      checkLoginIframe: false, // para bug en Firefox: evita que se pierda la sesión al navegar
      pkceMethod: "S256",
      // promiseType: 'native',
      token: token,
      refreshToken: tokenRefresh,
    })
    .then((authenticated) => {
      if (!authenticated) {
        logout();
      } else {
        login();
        if (urlRedirect !== null && urlRedirect !== "/register") {
          url = urlRedirect;
        } else {
          url = getDashboardUrl(
            _kc.tokenParsed?.usertype,
            _kc.tokenParsed?.originData
          );
        }

        window.location.href = url;
      }
      // setIsReady(true)
    })
    .catch(console.error);
};

const setLoginExternal = (token, tokenRefresh, urlRedirect, whiteLabel) => {
  // let url = "/company/dashboardintranet";
  let url = "";
  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      checkLoginIframe: false, // para bug en Firefox: evita que se pierda la sesión al navegar
      pkceMethod: "S256",
      // promiseType: 'native',
      token: token,
      refreshToken: tokenRefresh,
    })
    .then((authenticated) => {
      if (!authenticated) {
        logout();
      } else {
        login();
        if (
          urlRedirect !== null &&
          urlRedirect !== "/register-external" + whiteLabel
        ) {
          url = urlRedirect;
        } else {
          url = "/redirect-external";
        }

        window.location.href = url;
      }
      // setIsReady(true)
    })
    .catch(console.error);
};
function login() {
  localStorage.setItem(MY_AUTH_APP, true);
  localStorage.setItem(
    MY_AUTH_TOKEN,
    CryptoJS.AES.encrypt(_kc.token, MY_AUTH_ENCRYPT_PASS)
  );
  localStorage.setItem(
    MY_AUTH_TOKEN_R,
    CryptoJS.AES.encrypt(_kc.refreshToken, MY_AUTH_ENCRYPT_PASS)
  );
}

function logout() {
  localStorage.removeItem(MY_AUTH_APP);
  localStorage.removeItem(MY_AUTH_TOKEN);
  localStorage.removeItem(MY_AUTH_TOKEN_R);
}

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  // doLogout,
  doRegistration,
  forgotPassword,
  isLoggedIn,
  getToken,
  updateToken,
  getUserId,
  getUsername,
  getUserType,
  getName,
  getLastName,
  getCompanyName,
  // getSector,
  getPhone,
  getDni,
  getTitularCuenta,
  getDpostal,
  getBankingEntity,
  getIban,
  getMunicipio,
  getCodigoPostal,
  getCustomerId,
  getPlan,
  getPlanEnd,
  getAvatar,
  getHasCoinscrap,
  getCoinscrapDate,
  hasRole,
  updateKeycloackToken,
  userProfile,
  updateProfile,
  resetPassword,
  setShowCoinscrap,
  getShowCoinscrap,
  urlLogin,
  doLoginIdioma,
  getOriginData,
  getwhiteLabel,
  setLogin,
  setLoginExternal,
  logout,
  getFootprint,
  setPlan,
};

export default UserService;
