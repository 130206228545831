import { useEffect, useState } from "react";

import axios from "axios";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import UserService from "../../../../../services/UserService";
import FootprintCertificationList from './FootprintCertificationList';

function FootprintCertificationAdmin() {
    const [displayModal, setDisplayModal] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const navigate = useNavigate();
  
    const fetchAllUsers = async () => {
      try {
        await axios
          .get(`${process.env.REACT_APP_ADMIN_USERS}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          })
          .then((response) => {
            setUsuarios(response.data);
          });
      } catch (exception) {
        console.error(exception);
      }
    };
  
    const showNewCertificationModal = () => {
      fetchAllUsers();
      setDisplayModal(true);
    };
  
    const handleNewCertification = (props) => {
      setSelectedOption();
      navigate("/footprint/certification/new", { state: {userId:props.value, userName: props.label} });
    };
  
    const handleSelectChange = (option) => {
      setSelectedOption(option);
    };
  
    const yearActual = new Date().getFullYear();
    const years = [];
  
    for (let year = process.env.REACT_APP_CH_YEAR; year < yearActual; year++) {
      years.push(year);
    }
  
    const usuariosEmpresa = usuarios.filter((usuario) => usuario.userType === "Representante empresarial");

  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto sec-neutro-background">
        {/* Page header */}
        <div className="flex justify-end space-x-2">
          <button
            className="text-right btn btn-banner-o"
            onClick={() => showNewCertificationModal()}
          >
            Nueva certificación
          </button>
        </div>
      </div>

      {/* Content */}
      <div className="bg-white shadow-lg rounded-sm mb-8">
        <div className="flex flex-col xxl:flex-row xxl:-mr-px p-4">
          {/* Group 1 */}
          <div>
            <Modal show={displayModal}>
              <ModalHeader className="sec-green-background">
                <ModalTitle>Crear nueva certificación</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <div>
                  <label>Usuarios</label>
                  <Select
                    name={`usuariosa`}
                    className="custom-width-md"
                    options={usuariosEmpresa.map((user) => ({
                      value: user.id,
                      label: user.email,
                    }))}
                    placeholder={`Selecciona`}
                    onChange={handleSelectChange}
                    required
                  ></Select>
                </div>
              </ModalBody>
              <div className="flex justify-center space-x-4 m-2">
                <button
                  className="btn-sm btn-cancel"
                  onClick={() => {
                    setDisplayModal(false), setSelectedOption();
                  }}
                >
                  Cancelar
                </button>
                <button
                  className="btn-sm btn-green"
                  onClick={() => handleNewCertification(selectedOption)}
                >
                  Aceptar
                </button>
              </div>
            </Modal>
            <FootprintCertificationList />
          </div>
        </div>
      </div>
    </main>
  );
}
export default FootprintCertificationAdmin;
