import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../context/createcontext";
import logo from '../../images/logo2.webp';

function Footer() {


  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [hovert, sethovert] = useState(false)

  const handleChangeT = e => {
    hovert === true ?
      sethovert(false) :
      sethovert(true)

  }


  const [hoverf, sethoverf] = useState(false)

  const handleChangef = e => {
    hoverf === true ?
      sethoverf(false) :
      sethoverf(true)

  }

  const [hoverig, sethoverig] = useState(false)

  const handleChangeig = e => {
    hoverig === true ?
      sethoverig(false) :
      sethoverig(true)

  }

  const [hoverli, sethoverli] = useState(false)

  const handleChangeli = e => {
    hoverli === true ?
      sethoverli(false) :
      sethoverli(true)

  }
  return (
    <footer >
      <div className="py-2 md:py-6 text-white" style={{ backgroundColor: 'var(--airco2-green-color)' }}>






        <div className="max-w-6xl mx-auto px-1 sm:px-2">


          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">

            {/* 1st block */}

            <div className="md:col-span-5  lg:col-span-6 flex flex-column  justify-content pt-3 ">

              <div className="mb-3 pl-3">
                {/* Logo */}

                <img alt='logo' src={logo} height="200" width="130" ></img>
              </div>




              <div className="pl-3 pr-3"> {t("footer.f1")}</div>
            </div>


          </div>


         
        </div>
      </div>
    </footer>
  );
}

export default Footer;