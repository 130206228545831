import LineChart from './../charts/LineChart03';
import { tailwindConfig, hexToRGB } from '../utils/Utils';
import axios from 'axios';
import format from 'date-fns/format';
import { default as React, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../context/createcontext"
import UserService from '../../../services/UserService';

function AnalyticsCard01() {

  const [co2Historic, setCo2Historic] = useState({
    co2HistoricData: [],
    isFetching: false
  });
  useEffect(() => {
    const fetchCo2HistoricData = async () => {

      try {
        setCo2Historic({ co2HistoricData: co2Historic.co2HistoricData, isFetching: true });
        //TODO; fecha
        await axios.get(`${process.env.REACT_APP_CO2_HISTORIC}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          
          setCo2Historic({ co2HistoricData: response.data, isFetching: false });
          //updateLoading(false)
        });

      } catch (exception) {
        console.error(exception);
        // updateLoading(false)
        setCo2Historic({ co2HistoricData: co2Historic.co2HistoricData, isFetching: false });
      }


    };
    fetchCo2HistoricData();
  }, []);

  const dates = co2Historic.co2HistoricData.map((x) => {
    return format(new Date(x.date), 'dd/MM/yyyy');
  })


  const precios = co2Historic.co2HistoricData.map((x) => {
    return x.price;
  })




  const [plan, setPlan] = useState({
    plaData: [],
    isFetching: false
  });

  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        setPlan({ plaData: plan.plaData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_PAYMENTS_PLAN}/${UserService.getPlan()}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setPlan({ plaData: response.data, isFetching: false });

      } catch (exception) {
        console.error(exception);
        setPlan({ plaData: plan.plaData, isFetching: false });
      }
    };
    fetchPlanData();
  }, []);

  const preciosAirco2 = precios.map((x) => {
    return x - x * plan.plaData.discountCO2;
  })




  const chartData = {
    labels: dates,
    // ['10/08/2022', '11/08/2022', '12/08/2022', '13/08/2022', '14/08/2022', '15/08/2022', '16/08/2022', '17/08/2022'],

    datasets: [
      // Indigo line
      {
        label: 'Precio mercado',
        data: precios,
        // [87.7, 87.7, 84.7, 84, 83, 84, 85, 86.48],
        fill: false,
        // backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.gray[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        // pointBackgroundColor: tailwindConfig().theme.colors.gray[500],
      },
      // Gray line
      {
        label: 'Precio airCO2',
        data: preciosAirco2,
        // [40.7, 47.7, 44.7, 44, 43, 44, 45, 46.48],
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.indigo[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        fill: true,
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[300],
      },
    ],
  };
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">{t("dash.precioco2")}</h2>
      </header>
      <div className="px-5 py-1">
        <div className="flex flex-wrap">

        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        {
          plan.plaData.discountCO2 == null ? <Spinner className='flex justify-center' /> : <LineChart data={chartData} width={800} height={300} unit={" €/t"} />
        }
      </div>
    </div>
  );
}

export default AnalyticsCard01;
