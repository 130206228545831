import React, {useEffect, useState, useContext} from 'react';
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import Translations from '../../../services/Translations';

function ProyectosSidebar({speciesUniq,
  stateCheck,
  applySpeciesFilter,
  stateCheckToneladas,
}) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(speciesUniq);
  }, [data]);


  //Actualiar el check del filtro de toneladas:
  const [checkedTons, setCheckedTons] = useState([]);
  const handleCheckTons = (event) => {
    var updatedList = [...checkedTons];
    if (event.target.checked) {
      updatedList = [...checkedTons, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setCheckedTons(updatedList);
  };

  const [check, setcheck] = useState(false)

  //Actualiar el check del filtro de especies:
  const [checked, setChecked] = useState([]);
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checkedTons.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };


  //Aplicar cambios
  const handleApply = () => {
    const newState=new Array(2);

    newState[0] = stateCheck.map(obj => {

      if (checked.some(v => (v === obj.forestType))) {
        return { ...obj, check: true };
      }

      return { ...obj, check: false };
    });

    newState[1] = stateCheckToneladas.map(obj => {

      if (checkedTons.some(v => (v === obj.toneladas))) {
        return { ...obj, check: true };
      }

      return { ...obj, check: false };
    });

    applySpeciesFilter(newState);
  };

  // Borrar filtros seleccionados
  const handleClear = () => {
    const newState=new Array(2);

    newState [0]= stateCheck.map(obj => {
      return { ...obj, check: false };
    });
    //Evita duplicidad en el array con los nombres de lo que está a true
    setChecked([]);

    newState[1] = stateCheckToneladas.map(obj => {
      return { ...obj, check: false };
    });
    // applyTonsFilter(newStateTons);
    stateCheck = newState;
    document.querySelectorAll('input[type=checkbox]').forEach(function(checkElement) {
      checkElement.checked = false;
    });
    //Evita duplicidad en el array con los nombres de lo que está a true
    setCheckedTons([]);
    applySpeciesFilter(newState);
  }

  return (
    <div>
            <div className="sec-neutro-background min-w-60">
        <div className="grid md:grid-cols-2 xl:grid-cols-1 gap-6">
      <div className="sec-neutro-background p-5 min-w-60">
          <button className='text-gray-800 font-semibold text-base mb-2 flex' onClick={() => setShow(!show)}>
          {t("proyectos.filtros")}
            <span className={show ? "rotate-180" : ""}>
              <svg className="w-6 h-6 rounded fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </span>
          </button>
          {show ?
        <div >
          <div  className='flexFilterUp'>
            {stateCheck.length === 0 ?
              <span>
                <Spinner animation="border" className='flex justify-center' />
              </span>
              :
              <>
            <div>
            <div className="text-sm text-gray-800 font-semibold mb-3">{t("proyectos.especies")}</div>
              <ul className="space-y-1">
              {stateCheck.map((item, index) => (
                <li className="py-1 px-3" key={index}>
                  <label className="flex items-center">
                    <input value={item.forestType} type="checkbox" onChange={handleCheck} defaultChecked={item.check} />
                    <span className="text-sm font-medium ml-2">{t(Translations.translateForestTypeName(item.forestType))}</span>
                  </label>
                </li>
              ))}
              </ul>
              </div>
              <div className=''>
              <div className="text-sm text-gray-800 font-semibold mb-3">{t("proyectos.co2diponible")}</div>
              <ul className="space-y-1">
              {stateCheckToneladas.map((item, index) => (
                <li className="py-1 px-3" key={index}>
                  <label className="flex items-center">
                    <input value={item.toneladas} type="checkbox" onChange={handleCheckTons} defaultChecked={item.check} />
                    <span className="text-sm font-medium ml-2">{item.toneladas} t</span>
                  </label>
                </li>
              ))}
              </ul>
              </div>
             </>
          }
          </div>

          <div className="py-2 px-3 sec-neutro-background mt-2 ">
              <ul className="filterBtnsRow flexFilterUp ">
                <li className='filterBtnMr mb-2'>
                  <button className="btn-sm sec-green-background hover:border-gray-300 text-gray-500 hover:text-gray-600" onClick={handleApply}>Aplicar&nbsp;</button>
                </li>
                <li className='filterBtnMt '>
                  <button className="btn-sm bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600" onClick={handleClear}>Limpiar</button>
                </li>
              </ul>
            </div>
        </div>
        :
        ""}
      </div>
    </div>
    </div>
    </div>
  );
}

export default ProyectosSidebar;