import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from 'react-bootstrap';
import Header from "../Header";
import Sidebar from "../Sidebar";
import AccessFootprint from "./AccessFootprint";
import UserService from "../../../services/UserService";
import { useNavigate } from 'react-router-dom';

export default function WelcomeFootprint() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [currentPlan, setCurrentPlan] = useState({ id: undefined, isFetching: false });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserPlan = async () => {
            const url = `${process.env.REACT_APP_CH_PLAN}`;

            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });

                if (response?.data?.data?.id != undefined) {
                    UserService.setPlan(response?.data?.data?.planName, response?.data?.data?.expirationDate)
                    navigate("/footprint/myfootprint");
                }
                setCurrentPlan({ id: response?.data?.data?.id, isFetching: true })
            } catch (exception) {
                setCurrentPlan({ id: undefined, isFetching: true })
                console.error(exception);
            }
        };
        fetchUserPlan();
    }, []);

    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    {!currentPlan.isFetching && <>  <Spinner variant="light" animation="border" /></>}
                    {currentPlan.isFetching && currentPlan.id == undefined && <AccessFootprint></AccessFootprint>}
                </main>
            </div>
        </div>
    )

}