import React from 'react'
import { useContext } from "react";
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export default function BannerConcelloPontevedra() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <section className=' bg-footer  '>
            <div className="max-w-6xl mx-auto px-4 sm:px-6  ">
                <div className="  p-6 flex flex-col pt-5 text-airco2-general-grey items-center">

                    <div className="mx-auto text-center " data-aos="fade-down">
                        <h2 className="h2 font-cabinet-grotesk">  {t("concellosNaturePositive.home.title")}</h2>
                    </div>

                    <div className='flex flex-col md:flex-row gap-4 items-center justify-between w-full  mt-2 mb-3  py-4' data-aos="fade-right" data-aos-delay="400">
                        <div className=''>
                            <p>{t("concellosNaturePositive.home.subtitle")}</p>


                        </div>
                        <div className='mt-4' data-aos="fade-left" data-aos-delay="600">
                            <NavLink
                                aria-label="apartado resources"
                                className="btn text-airco2-principal bg-white linkNav   shadow-sm"
                                to={`/concellopontevedra`}
                                smooth={true}
                                offset={50}
                                duration={500}>
                                {t("concellosNaturePositive.home.button")}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
