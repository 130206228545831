
import React, { useState, useContext } from 'react';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';
import pr1 from '../../images/Selvicultura.webp';
import pr2 from '../../images/Aforestacion.webp'
import pr3 from '../../images/Conservcion.webp'



function News() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h2 className="sec-blue-text text48"  data-aos="fade-up">{t("foreproyect.cadadia")}</h2>
            <p className="text-xl mt-4 sec-grey-text" data-aos="fade-up" data-aos-delay="200">{t("foreproyect.subcadadia")}</p>    
          </div>

          {/* Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none" >
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">

              {/* 1st article */}
              <article className="flex flex-col h-full" data-aos="fade-up">
                <header>
                  <p className="block mb-6">
                    {/* <figure className=" flex flex-col h-0 pb-9/16 overflow-hidden rounded-sm"> */}
                      <img className="flex flex-col inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={pr1} width="352" height="198" alt="News 01" />
                    {/* </figure> */}
                  </p>
               
                  <h3 className="h4 mb-2">
                    <p className="hover:text-gray-100 transition duration-150 ease-in-out sec-blue-text text18"  href="blog-post.html">{t("foreproyect.selvicultura")}</p>
                  </h3>
                </header>
                <p className="grow sec-blue-text text14" >{t("foreproyect.rentaAdicional")}</p>

              </article>

              {/* 2nd article */}
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="200">
                <header>
                  <p className="block mb-6">
                    {/* <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm"> */}
                      <img className="flex flex-col inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={pr2} width="352" height="198" alt="News 02" />
                    {/* </figure> */}
                  </p>
                
                  <h3 className="h4 mb-2">
                    <p className=" sec-blue-text text18 hover:text-gray-100 transition duration-150 ease-in-out" href="blog-post.html" >{t("foreproyect.aforestacion")}</p>
                  </h3>
                </header>
                <p className="text-lg  sec-blue-text grow">{t("foreproyect.nuevosbosques")}</p>
                <footer className="flex items-center mt-4">
                 
                </footer>
              </article>

              {/* 3rd article */}
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="400">
                <header>
                  <p className="block mb-6">
                    {/* <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm"> */}
                      <img className="flex flex-col inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={pr3} width="352" height="198" alt="News 03" />
                    {/* </figure> */}
                  </p>
              
                  <h3 className="h4 mb-2">
                    <p className="  sec-blue-text text18 hover:text-gray-100 transition duration-150 ease-in-out" >{t("foreproyect.conservacion")}</p>
                  </h3>
                </header>
                <p className="text-lg  sec-blue-text grow">{t("foreproyect.preservar")}</p>
                <footer className="flex items-center mt-4" id='forestal3'>
                
                </footer>
              </article>

            </div>
          </div>

        </div>
      </div>
    
        {/* button faqs */}
        <div className='md:min-w-[30rem] text-center' data-aos="fade-up" data-aos-delay="400">

          <a aria-label='redirige a faqs' className="btn btn-faqs btn-banner-o  sec-white-text w-full mb-4 sm:w-auto sm:mb-0 mt-4 sec-orange-background " href="/faqs">

            {t("hometitle.preguntas2")}</a>
        </div>
    
    </section>
  );
}

export default News;
