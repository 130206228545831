import React from 'react';



function AuthPages({ children, image, decoration }) {

    return (
        <main className="">

            <div className="relative md:flex">

                {/* Content */}
                <div className="auth-page min-h-screen h-full flex items-center" style={{marginTop:'50px'}}>


                    {/* Contenido */}
                    <div className=" mx-auto px-4 py-8">

                        {children}

                    </div>


                </div>

                {/* Image */}
                <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/3" aria-hidden="true">
                    <img className="object-cover object-center w-full h-full" src={image} width="760" height="800" alt="Authentication" />
                    <img className=" absolute left-0 transform -translate-x-1/2 hidden lg:block" src={decoration} width="80" alt="Authentication decoration" style={{ top: '30%' }} />
                </div>

            </div>

        </main>
    );
}

export default AuthPages;