import Dropzone from "../utils/Dropzone";
import { React, useState, useContext } from "react";
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useParams } from "react-router-dom";
import Documents from "../subirDoc/Documents";
import { useLocation } from 'react-router-dom';


export default function UploadDocuments() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const location = useLocation();
  const pathParams = useParams();

  const idCalculation = pathParams.id;
  const calcObjetc = location.state?.calcObjetc;
  const enableUpload = location.state?.dropzone;
  const enableDelete = location.state?.delete;
  const dataTitle = location.state?.data;

  let [envD, updateEnv] = useState();
  const actualizar = (v) => {
      updateEnv(v.data)
  }
  
  const urlDocuments = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/documentation`;
  const urlDropzone = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/documentation`;
  const maxSize = 4194304;

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Title */}
        <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
            { enableUpload ? t("sidebar.subirDoc") : "Mis documentos " }
          </h1>
          {calcObjetc &&
            <div className="flex justify-end">
              <span className="text-sm">{t("footPrint.docDelCalculo")} {idCalculation}{t("footPrint.deOrg")}<strong>{calcObjetc.organization.name}</strong> {t("footPrint.paraElAño")} <strong>{calcObjetc.year}</strong></span>
            </div>
          }

        </div>

        <div className="body-content w-full">
          <div className="w-full px-4 py-4 mt-4 mx-4">
            {enableUpload && 
              <Dropzone
                actualizar={actualizar}
                url={urlDropzone}
                validExtension={["application/pdf", "application/PDF"]}
                size={maxSize}
              />
            }
            <Documents envD={envD} url={urlDocuments} enableDelete={enableDelete} dataTitle={dataTitle} />
            
          </div>
        </div>
      </div>
    </div>
  );
}