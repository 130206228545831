
import React, { useState, useContext, useEffect } from 'react';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';
import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import { useLocation } from 'react-router-dom';

function Faqs() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [page, setPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#invoices') {
      setPage(2);
    }
  }, []);

  useEffect(() => {
    if (location.hash === '#invoices' && page==2) {
      const elemento = document.querySelector(location.hash);
      if (elemento) {
        elemento.scrollIntoView({ behavior: 'smooth' });
      }      
    }
        
  }, [page]);



  return (
    <div>

      <Header></Header>

      <main className="grow mt-5 pt-5">


        {/*  Page sections */}
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-10 pb-12 md:pt-12 md:pb-20">
            <div className="flex flex-col md:flex-row text-airco2-general">

              {/* Main content */}
              <main className="md:flex-auto text-airco2-general md:pl-10 order-1" data-aos="fade-up">

                {/* FAQ’s – Frequently Asked Questions */}
                <div className={page !== 1 ? 'hidden' : undefined}>
                  <div className="mb-8">
                  <h1 className="h2 mb-4">{t("faqs.generales")}</h1>

                  </div>
                  <ul className="-my-4">
                    <li className="py-4">
                      <h4 className=" font-bold mb-2" >{t("faqs.creditosCarbono")}</h4>
                      <p className=" ">{t("faqs.respCreditosCarbono")}</p>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.gei")}</h4>
                      <p className="  ">{t("faqs.respGei")}</p>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.mercadoVoluntario")}</h4>
                      <p className="  "> {t("faqs.respMercadoVoluntario")}</p>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.compensar")}</h4>
                      <p className="  "> {t("faqs.respCompensar")} </p>
                    </li>
                   

                  </ul>
                </div>

                {/* especificas */}
                <div className={page !== 2 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h1 className="h2 mb-4">{t("faqs.especificas")}</h1>

                  </div>
                  <ul className="-my-4">
                    <li className="py-4">
                      <h4 className=" font-bold mb-2" >{t("faqs.generarCreditos")}</h4>
                      <p className=" ">{t("faqs.respgenerarCreditos")}</p>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.gestionForestal")}</h4>
                      <p className="  ">{t("faqs.respGestionForestal")}</p>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.requisitosMontes")}</h4>
                      <li className="  ">-  {t("faqs.resp1RequisitosMontes")}</li>
                      <li className="  ">- {t("faqs.resp2RequisitosMontes")}</li>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.transacciones")}</h4>
                      <p className="  "> {t("faqs.respTransacciones")} </p>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.reduccionCubierta")}</h4>
                      <p className="  "> {t("faqs.respReduccionCubierta")} </p>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.incendio")}</h4>
                      <p className="  "> {t("faqs.respIncendio")} </p>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.venta")}</h4>
                      <p className="  "> {t("faqs.respVenta")} </p>
                    </li>
                    <li className="py-4">
                      <h4 className="font-bold   mb-2">{t("faqs.certificados")}</h4>
                      <p className="  "> {t("faqs.respCertificados")} </p>
                    </li>
                    <li className="py-4" id="invoices">
                      <h4 className="font-bold   mb-2">{t("faqs.facturas")}</h4>
                      <p className="  "> {t("faqs.respFacturas0")} </p>
                      <p className="  "> {t("faqs.respFacturas1")} </p>
                      <p className="  "> {t("faqs.respFacturas2")} </p>

                    </li>

                  </ul>
                </div>

              



              </main>

              {/* Nav sidebar */}
              <aside className="md:w-64 mb-16 md:mb-0 md:mr-10 md:shrink-0" data-aos="fade-up" data-aos-delay="200">
                <h4 className="font-bold   px-3 pb-3 border-b border-gray-700">{t("faqs.categoria")}</h4>
                <nav>
                  <ul>
                    <li className=" py-2 border-b border-gray-800">
                      <a
                        className={`text-airco2-general  font-bold  flex items-center px-3 group  hover:text-airco2-secondary transition duration-150 ease-in-out ${page === 1 && 'text-airco2-principal'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(1); }}
                      >
                        <span>{t("faqs.generales")}</span>
                      
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`text-airco2-general  font-bold flex items-center px-3 group  hover:text-airco2-secondary transition duration-150 ease-in-out ${page === 2 && 'text-airco2-principal'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(2); }}
                      >
                        <span>{t("faqs.especificas")}</span>
                       
                      </a>
                    </li>
                  

                  </ul>
                </nav>
              </aside>

            </div>
          </div>
        </div>

      </main>
      <Footer></Footer>
    </div>
  );
}

export default Faqs;