
import { React, useState, useContext } from 'react'
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import Sidebar from "../Sidebar";
import Header from '../Header';
import Documents from "./Documents";
import '../utils/Dropzone.css';
import UserService from '../../../services/UserService';
import Dropzone from '../utils/Dropzone';


export default function AppDropMontes() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");


    let [envD, updateEnv] = useState();
    const actualizar = (v) => {
        updateEnv(v)
    }

    const urlDropzone = `${process.env.REACT_APP_FOREST_DOC}/${UserService.getUserId()}`;
    const urlDocuments = `${process.env.REACT_APP_FOREST_DOC}/${UserService.getUserId()}`;

    const maxSize = 4194304;

    return (

        <div className="flex h-screen overflow-hidden">
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>

                    {/* Title */}
                    <div className="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto">
                      <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("sidebar.subirDoc")}</h1>
                    </div>
                    <div className="body-content w-full">


                        <div className="w-full px-4 py-4 mt-4 mx-4">
                            <Dropzone 
                                actualizar={actualizar} 
                                url={urlDropzone} 
                                validExtension={['application/pdf', 'application/PDF']} 
                                size={maxSize}
                            />
                        </div>


                        <Documents
                            envD={envD} url={urlDocuments}/>

                    </div>
                </main>
            </div>
        </div >
    )


}