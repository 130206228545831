import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import UserService from '../../../services/UserService';
import { formatThousands } from '../utils/Utils';
import unveriefFootprint from '../../../images/unverifiedFootprint.svg';
import veriefFootprint from '../../../images/verifiedFootprint.svg';
import Card from "react-bootstrap/Card";
import { Spinner } from 'react-bootstrap';
import logo from '../../../images/Logo verde_airCO2.png'

export default function CreateCertification({ certificationStep, certificationDetailData }) {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [certificationDetail, setCertificationDetail] = useState(certificationDetailData.data);

   
    return (

        <div className='bg-white rounded-lg mt-4 py-4 px-4' style={{minHeight: '400px'}}>
            <div className='flex gap-2 items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mailbox" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M10 21v-6.5a3.5 3.5 0 0 0 -7 0v6.5h18v-6a4 4 0 0 0 -4 -4h-10.5" />
                    <path d="M12 11v-8h4l2 2l-2 2h-4" />
                    <path d="M6 15h1" />
                </svg>
                <h2 className='text-xl mt-2 mb-2 text-airco2-general-grey font-semibold'>Envío solicitud al MITECO</h2>
            </div>
        
            <div className='miteco-final-step gap-4'>
                {(certificationDetail.certificationMitecoStatus == "SENT" || certificationDetail.certificationMitecoStatus == "ACCEPTED") ?
                <div className='text-sm text-airco2-general-grey'>
                    <p>El equipo de airCO2 gestionará el envío de la documentación al MITECO en breves momentos.</p>
                    <p>Cuando el MITECO nos confirme la recepción de tu solicitud, aparecerá el código de seguimiento de solicitud en el cuadro de la derecha y se te notificará por correo electrónico. </p>
                    <p>A partir de ese momento, el tiempo medio de espera de resolución por parte del MITECO es de 1-3 meses.</p>
                </div>
                :
                <div className='text-sm text-airco2-general-grey'>
                    <p>El registro de tu huella de la organización {certificationDetail.organizationName} del año {certificationDetail.calculationYear} se ha completado con éxtio.</p>
                    <p>Puedes descargar tu sello, el anual de uso de sello y el certificado inscripción del mismo.</p>
                </div>
                }
                <div className={`sec-neutro-background rounded text-airco2-general-grey py-2 px-4 text-center`}>
                    <div className='font-semibold'>Código de seguimiento Registro MITECO</div>            
                    <p className='text-sm'>{certificationDetail?.trackingNumber != undefined ? certificationDetail.trackingNumber :  "En espera de respuesta del MITECO"}</p>
                </div>
            </div>
            
            {certificationDetail.certificationMitecoStatus == "COMPLETED" && 
                <div className='miteco-download-certification'>
                    <div className='rounded sec-neutro-background shadow flex flex-col justify-center items-center gap-4 p-4' 
                        style={{ width: '200px', minHeight: '200px' }}>
                        <span className='text-center'>Descargar sello MITECO</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-sticker" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M20 12l-2 .5a6 6 0 0 1 -6.5 -6.5l.5 -2l8 8" />
                            <path d="M20 12a8 8 0 1 1 -8 -8" />
                        </svg>
                    </div>
                    <div className='rounded sec-neutro-background shadow flex flex-col justify-center items-center gap-4 p-4' 
                        style={{ width: '200px', minHeight: '200px' }}>
                        <span className='text-center'>Descargar manual de uso del sello</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-info" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                            <path d="M11 14h1v4h1" />
                            <path d="M12 11h.01" />
                        </svg>
                    </div>
                    <div className='rounded sec-neutro-background shadow flex flex-col justify-center items-center gap-4 p-4' 
                        style={{ width: '200px', minHeight: '200px' }}>
                        <span className='text-center'>Descargar certificado de inscripción del sello</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-certificate" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                            <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
                            <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
                            <path d="M6 9l12 0" />
                            <path d="M6 12l3 0" />
                            <path d="M6 15l2 0" />
                        </svg>
                    </div>
                </div>
            }



        </div>

    )
}