import React, { useState, useContext, useEffect, useRef } from 'react';
import UserService from '../../../services/UserService';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import Transition from '../utils/Transition';
import tipoFormulario from '../../../images/tipoFormulario.png'
import formulario from '../../../images/formulario.png'
import calculos from '../../../images/misCalculos.png'
import reporte from '../../../images/reporteNew.png'
import tipoFormulariomvl from '../../../images/tipoFormulariomvl.png'
import formulariomvl from '../../../images/formularioMvl.png'
import calculosmvl from '../../../images/misCalculosMvl.png'
import reporteMvl from '../../../images/reporteMvl.png'
import { Link } from "react-router-dom";

export default function AccessFootprint() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [sendAcess, setSendAcess] = useState(false)
    const [loading, setLoading] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [sizeWindow, setSizeWindow] = useState(width <= 760 ? 'mvl' : 'pc');
    const classPc = 'flex flex-wrap gap-6 justify-center pb-8'

    const [tab, setTab] = useState(1);
    const tabs = useRef(null);


    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setSizeWindow(window.innerWidth <= 760 ? 'mvl' : 'pc');
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);





    const heightFix = () => {
        if (tabs.current && tabs.current.children[tab - 1]) {
            tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px';
        }
    };

    const iconosMvl = [
        {
            src: <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
                <path fill="var(--airco2-green-color)" d="M20 20h6v16h-6z" />
                <path fillOpacity=".64" fill="var(--airco2-orange-color)" d="M29 20h3v16h-3zM35 20h1v16h-1z" />
            </svg>,
            alt: 'Tipo de formulario',
            tab: 1
        },

        {
            src: <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
                <path fillOpacity=".64" fill="var(--airco2-orange-color)" d="M33 23v8h3V20H25v3z" />
                <path fill="var(--airco2-green-color)" d="M20 25h11v11H20z" />
            </svg>
            , alt: 'Formulario',
            tab: 2
        },
        {
            src: <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
                <path fillOpacity=".64" fill="var(--airco2-orange-color)" d="M20 27l7-7h-7z" />
                <path fill="var(--airco2-green-color)" d="M29 20l7 7v-7z" />
                <path fillOpacity=".64" fill="var(--airco2-orange-color)" d="M36 29l-7 7h7z" />
                <path fill="var(--airco2-green-color)" d="M27 36l-7-7v7z" />
            </svg>,
            alt: 'Dashboard cálculos',
            tab: 3
        },
        {
            src: <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 28h-4v4.9c0 1 .7 1.9 1.7 2.1 1.2.2 2.3-.8 2.3-2v-5z" fillOpacity=".64" fill="var(--airco2-orange-color)" />
                <path d="M35 21h-8c-.6 0-1 .4-1 1v11c0 .7-.2 1.4-.6 2H33c1.7 0 3-1.3 3-3V22c0-.6-.4-1-1-1z" fill="var(--airco2-green-color)" />
            </svg>, alt: 'Reporte de un cálculo',
            tab: 4
        },
    ];
    const handlePrev = () => {
        setTab((prevTab) => prevTab === 1 ? iconosMvl.length : prevTab - 1);
    };

    const handleNext = () => {
        setTab((prevTab) => prevTab === iconosMvl.length ? 1 : prevTab + 1);
    };
    const currentIcon = iconosMvl.find((icono) => icono.tab === tab);

    useEffect(() => {
        heightFix()

    }, [tab])


    const name = UserService.getName().split(' ')[0]
    return (
        <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>



            <main className='mb-8'>

                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{name}, {t("footPrint.preAcceso")}</h1>
                <section className="relative">

                    <div className="absolute inset-0 bg-slate-100 pointer-events-none mb-64 md:mb-80" aria-hidden="true"></div>
                    <p className="text-md mx-4 mt-4  text-gray-800  ">{t("footPrint.preAccesoTxt")}</p>
                    <p className="text-md mx-4 mt-1  text-gray-800  ">{t("footPrint.preAccesoTxt3")}</p>
                    <p className="text-md font-bold mx-4 mt-3 text-gray-800  ">{t("footPrint.preAccesoTxt2")}</p>




                    <div className="relative max-w-6xl mx-auto px-4">

                        <div className='flex flex-wrap justify-between mt-4 mb-4 gap-2 '>
                            <Link className={`btn btn-green rounded-lg px-5 py-3 text-md font-bold w-full sm:w-auto`}
                                to="/footprint/plans">{t("footPrint.gosubscription")}
                            </Link>
                            <Link className={`btn btn-banner-o rounded-lg px-5 py-3 text-md font-bold w-full block sm:w-auto`}
                                to="/footprint/myfootprint">{t("footPrint.goTestCalculator")}
                            </Link>
                        </div>



                        {/* Section content */}
                        <div className="max-w-6xl mx-auto py-4">

                            {/* Tabs buttons */}
                            {/* botones para movil */}
                            {(sizeWindow === 'mvl') &&
                                <div className='flex gap-5 items-center justify-center'>

                                    <button onClick={handlePrev} className="">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-circle-chevron-left"
                                            width="44"
                                            height="44"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="#545a6d"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M13 15l-3 -3l3 -3" />
                                            <path d="M21 12a9 9 0 1 0 -18 0a9 9 0 0 0 18 0z" />
                                        </svg>

                                    </button>


                                    {currentIcon && (
                                        <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">

                                            <circle cx="28" cy="28" r="26" fill="white" />
                                            {currentIcon.src.props.children}
                                        </svg>
                                    )}

                                    <button onClick={handleNext} className="">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-circle-chevron-right"
                                            width="44"
                                            height="44"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="#545a6d"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M11 9l3 3l-3 3" />
                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0z" />
                                        </svg>
                                    </button>
                                </div>
                            }

                            {/* botones para pc */}
                            {(sizeWindow === 'pc') &&
                                <div className={classPc}>
                                    <button
                                        className={`text-center transition-opacity ${tab !== 1 && 'opacity-50 hover:opacity-75'}`}
                                        onClick={(e) => { e.preventDefault(); setTab(1); }}
                                    >
                                        <div>
                                            <div className="inline-flex bg-white rounded-full shadow-md mb-3">
                                                <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="var(--airco2-green-color)" d="M20 20h6v16h-6z" />
                                                    <path fillOpacity=".64" fill="var(--airco2-orange-color)" d="M29 20h3v16h-3zM35 20h1v16h-1z" />
                                                </svg>
                                            </div>
                                            <div className="md:text-lg leading-tight font-semibold text-slate-800">{t("footPrint.typeForm")}</div>
                                        </div>
                                    </button>
                                    <button
                                        className={`text-center transition-opacity ${tab !== 2 && 'opacity-50 hover:opacity-75'}`}
                                        onClick={(e) => { e.preventDefault(); setTab(2); }}
                                    >
                                        <div>
                                            <div className="inline-flex bg-white rounded-full shadow-md mb-3">
                                                <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillOpacity=".64" fill="var(--airco2-orange-color)" d="M33 23v8h3V20H25v3z" />
                                                    <path fill="var(--airco2-green-color)" d="M20 25h11v11H20z" />
                                                </svg>
                                            </div>
                                            <div className="md:text-lg leading-tight font-semibold text-slate-800">{t("footPrint.form")}</div>
                                        </div>
                                    </button>
                                    <button
                                        className={`text-center transition-opacity ${tab !== 3 && 'opacity-50 hover:opacity-75'}`}
                                        onClick={(e) => { e.preventDefault(); setTab(3); }}
                                    >
                                        <div>
                                            <div className="inline-flex bg-white rounded-full shadow-md mb-3">
                                                <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillOpacity=".64" fill="var(--airco2-orange-color)" d="M20 27l7-7h-7z" />
                                                    <path fill="var(--airco2-green-color)" d="M29 20l7 7v-7z" />
                                                    <path fillOpacity=".64" fill="var(--airco2-orange-color)" d="M36 29l-7 7h7z" />
                                                    <path fill="var(--airco2-green-color)" d="M27 36l-7-7v7z" />
                                                </svg>
                                            </div>
                                            <div className="md:text-lg leading-tight font-semibold text-slate-800">{t("footPrint.dashCalculos")}</div>
                                        </div>
                                    </button>
                                    <button
                                        className={`text-center transition-opacity ${tab !== 4 && 'opacity-50 hover:opacity-75'}`}
                                        onClick={(e) => { e.preventDefault(); setTab(4); }}
                                    >
                                        <div>
                                            <div className="inline-flex bg-white rounded-full shadow-md mb-3">
                                                <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24 28h-4v4.9c0 1 .7 1.9 1.7 2.1 1.2.2 2.3-.8 2.3-2v-5z" fillOpacity=".64" fill="var(--airco2-orange-color)" />
                                                    <path d="M35 21h-8c-.6 0-1 .4-1 1v11c0 .7-.2 1.4-.6 2H33c1.7 0 3-1.3 3-3V22c0-.6-.4-1-1-1z" fill="var(--airco2-green-color)" />
                                                </svg>
                                            </div>
                                            <div className="md:text-lg leading-tight font-semibold text-slate-800">{t("footPrint.reporteCalculo")}</div>
                                        </div>
                                    </button>

                                </div>
                            }

                            {/* Tabs items */}
                            <div className="relative flex flex-col mt-4"
                                data-aos="fade-up"
                                ref={tabs}


                            >
                                {/* Item 1 */}
                                <Transition
                                    show={tab === 1}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterStart="opacity-0 translate-y-16"
                                    enterEnd="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveStart="opacity-100 translate-y-0"
                                    leaveEnd="opacity-0 -translate-y-16"
                                >
                                    <img className="mx-auto shadow-2xl" src={sizeWindow === 'pc' ? tipoFormulario : tipoFormulariomvl} width="768" height="474" alt="Features home 01" />
                                </Transition>
                                {/* Item 2 */}
                                <Transition
                                    show={tab === 2}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterStart="opacity-0 translate-y-16"
                                    enterEnd="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveStart="opacity-100 translate-y-0"
                                    leaveEnd="opacity-0 -translate-y-16"
                                >
                                    <img className="mx-auto shadow-2xl" src={sizeWindow === 'pc' ? formulario : formulariomvl} width="768" height="474" alt="Features home 02" />
                                </Transition>
                                {/* Item 3 */}
                                <Transition
                                    show={tab === 3}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterStart="opacity-0 translate-y-16"
                                    enterEnd="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveStart="opacity-100 translate-y-0"
                                    leaveEnd="opacity-0 -translate-y-16"
                                >
                                    <img className="mx-auto shadow-2xl" src={sizeWindow === 'pc' ? calculos : calculosmvl} width="768" height="474" alt="Features home 03" />
                                </Transition>
                                {/* Item 4 */}
                                <Transition
                                    show={tab === 4}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterStart="opacity-0 translate-y-16"
                                    enterEnd="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveStart="opacity-100 translate-y-0"
                                    leaveEnd="opacity-0 -translate-y-16"
                                >
                                    <img className="mx-auto shadow-2xl" src={sizeWindow === 'pc' ? reporte : reporteMvl} width="768" height="474" alt="Features home 04" />
                                </Transition>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </div>
    )
}
