import React, { useState, useContext, useEffect } from "react";
import { IdiomContext } from "../../../context/createcontext";
import { Translation, useTranslation } from "react-i18next";
import ForestListTableItemLine from "./ForestListTableItemLine";
import UserService from "../../../services/UserService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Modal, Button, Col } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Translations from "../../../services/Translations";

function ForestListTableItem({ props, updateTable }) {
  const [spinnerContratoBtn, setSpinnerContratoBtn] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const { childList, ...montePadre } = props;
  const montesChild=props.childList

  const [showEdit, setShowEdit] = useState(false)
  const [userProfile, setUserProfile] = useState({
    name: "",
    dni: "",
    titularCuenta: "",
    dpostal: "",
    bankingEntity: "",
    iban: "",
  });
  const [terceros, setTerceros] = useState(false);
  const [msgEditarForest, setMsgEditarForest] = useState("");

  const handleClose = () => {
    setShowEdit(false)

  }
  const handleShowEdit = (x) => {
    setShowEdit(true)
    // setForestType(x.forestType)
  }

  const handleEdit = (e) => {
    e.preventDefault()

    setMsgEditarForest("")
    axios.post(`${process.env.REACT_APP_ADMIN_FOREST}?slackAlert=true&isUpdated=true&`,

      JSON.stringify({
        state: props.state,
        id: props.id,
        anualCO2: parseFloat(props.anualCO2),
        forestType: e.target.forestType.value,
        cadastre: props.cadastre,
        name: props.name,
        size: props.size,
        userId: props.userId,
        owner: props.owner,
        age: props.age,
        availableCO2: props.disponibleCO2,
        creationDate: props.creationDate,
        validationDate: props.validationdate,
        marco: props.marco,
        province: props.province,
        municipio: props.municipio,
        urlGml: props.urlGml,
        idPadre: null,
        hubspot_negocioId: props.hubSpotNegocio,
        hubspot_contratoId: props.hubSpotContrato,
        cuestionarioEnviado: props.cuestionarioEnviado,
        commision: props.commision,
        fire_insurance: props.fire_insurance,
        activationDate: props.activationDate,
        modificationDate: props.modificationDate,
        nextReloadDate: props.nextReloadDate,
        renovado: props.renovado

      })
      , {
        headers: {
          Authorization: `Bearer ${UserService.getToken()}`,
          'content-type': 'application/json'
        }
      })
      .then(response => {

        if (response.status == 200) {
          updateTable(response.data);
          setMsgEditarForest("Acualizado correctamente")
        }
      })

  }
  const statusColor = (props) => {
    switch (props.state) {
      case "CREATED":
        return "bg-gray-100 text-gray-500";
      case "VALIDATED":
        return "bg-gray-100 text-yellow-600";
      case "VALIDATEDANDCERTIFICATED":
        return "bg-yellow-500 text-white";
      case "ACTIVE":
        return "bg-green-100 text-green-600";
      case "DISABLED":
        return "bg-red-100 text-red-600";
    }
  };

  const statusName = (props) => {
    switch (props.state) {
      case "CREATED":
        return t("forestList.sCreated");
      case "VALIDATED":
        return t("forestList.sValidated");
      case "VALIDATEDANDCERTIFICATED":
        return t("forestList.sValidatedAndCertificated");
      case "ACTIVE":
        return t("forestList.sActive");
      case "DISABLED":
        return t("forestList.sDisabled");
    }
  };

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const totalHa = (sizeMontePadre, childs) => {
    let ha = sizeMontePadre;
    childs.map((v) => {
      ha += v.size;
    });
    if (ha !== null) {
      return ha?.toFixed(2);
    } else {
      return;
    }
  };

  const renderTooltipFormExtra = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("forestList.formularioExtra")}
    </Tooltip>
  );

  const renderTooltipEditar = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("formMonte.editar")}
    </Tooltip>
  );


  //Estado con todas las especies de BBDD
  const [forestType, setForestType] = useState({
    forestTypeData: [],
    isFetching: false,
  });

  useEffect(() => {
    const fetchForestType = async () => {
      try {
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_FORESTTYPE}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setForestType({ forestTypeData: response.data.sort((x, y) => x.commonName.localeCompare(y.commonName)), isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        console.error(exception);
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: false });
      }
    };
    fetchForestType();
  }, []);


  return (
    <tbody className="text-sm">
      {/* Row */}

      <Modal show={showEdit}>

        <Modal.Header className="sec-green-background">
          <Modal.Title className="text-white">{props.name} {props.cadastre}</Modal.Title>
          <div className="flex justify-end">
            <button onClick={() => handleClose()}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className='text-xs'
            onSubmit={(e) => handleEdit(e)}
          >
            <Col col="6">
              <FormGroup>
                <Label for="forestType">Especie predominante</Label>
                <Input className='text-xs' type="select" name="forestType" id="forestType" defaultValue={props.forestType} required>

                  <option name="Selecciona" disabled className='w-full' value="">{t("formMonte.selectespecie")}</option>
                  {
                    forestType.forestTypeData.map((x) => {
                      return (
                        <option key={x.id} name={x.commonName} className='w-full' value={x.commonName} >{t(Translations.translateForestTypeName(x.commonName)) + " (" + x.scientificName + ")"}</option>
                      );
                    })
                  }
                </Input>
              </FormGroup>
            </Col>
            <Button className=" text-xs btn-sm btn-green text-white ml-3 whitespace-nowrap" type="submit">Actualizar</Button>
            {msgEditarForest !== "" && <div className="mt-3 mb-3">{msgEditarForest}</div>}

          </Form>
        </Modal.Body>
      </Modal>
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center flex items-center">
            {props.childList.length > 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-trees"
                width="36"
                height="36"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                 stroke="var(--airco2-green-color)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                <line x1="15" y1="21" x2="15" y2="18" />
                <path d="M8 13l-2 -2" />
                <path d="M8 12l2 -2" />
                <path d="M8 21v-13" />
                <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-tree"
                width="36"
                height="36"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                 stroke="var(--airco2-green-color)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 13l-2 -2" />
                <path d="M12 12l2 -2" />
                <path d="M12 21v-13" />
                <path d="M9.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
              </svg>
            )}

            <span>{props.name}</span>
            <span>{props.cuestionarioEnviado}</span>

            {props.cuestionarioEnviado !== true &&
              props.state !== "CREATED" && (
                <span className="pl-2">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipFormExtra}
                  >
                    <Link
                      to={`/forest/formInfo/${props.id}`}
                      className="text-airco2-general"
                      state={props.name}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-list-check"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                         stroke="var(--airco2-green-color)"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />
                        <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />
                        <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />
                        <line x1="11" y1="6" x2="20" y2="6" />
                        <line x1="11" y1="12" x2="20" y2="12" />
                        <line x1="11" y1="18" x2="20" y2="18" />
                      </svg>
                    </Link>
                  </OverlayTrigger>
                </span>
              )}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">
            {props.childList.length == 0 ? (
              props.cadastre
            ) : (
              <div className="flex justify-center items-center">

                <span>Ver todas</span>
                <button
                  className={`text-gray-400 hover:text-gray-500 transform ${descriptionOpen && "rotate-180"
                    }`}
                  aria-expanded={descriptionOpen}
                  onClick={() => setDescriptionOpen(!descriptionOpen)}
                  aria-controls={`description-${props.id}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-down"
                    width="36"
                    height="36"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                     stroke="var(--airco2-green-color)"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">
            {props.childList.length == 0 ? (
              <div className="flex flex-col">


                <div className="flex gap-1 items-center">
                  <p className="pl-2"> {t(Translations.translateForestTypeName(props.forestType))}</p>
                  {props.state === 'CREATED' &&
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipEditar}
                    >
                      <button onClick={() => handleShowEdit(props)} className="active" aria-pressed="true">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="#80817f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                          <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                          <path d="M16 5l3 3" />
                        </svg>
                      </button>
                    </OverlayTrigger>
                  }
                </div>

              </div>


            )


              : (
                <></>
              )}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">
            {props.childList.length == 0
              ? props.size
              : totalHa(props.size, props.childList)}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex flex-col items-center justify-center">
            <div
              className={`text-center rounded-full py-0.5 px-2 ${statusColor(
                props
              )}`}
              style={
                props.state == "VALIDATED"
                  ? { backgroundColor: "rgb(254 249 195)" }
                  : {}
              }
            >
              {statusName(props)}
            </div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{props.anualCO2}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{props.availableCO2}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{props.province}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{props.creationDate}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{props.validationDate}</div>
        </td>
      </tr>
      <tr
        id={`description-${props.id}`}
        role="region"
        className={`${!descriptionOpen && "hidden"} w-full`}
      >
        <td></td>
        <td colSpan="5" className="px-2 first:pl-5 last:pr-5 py-3">
          <table className="table-auto w-full divide-y divide-gray-200 text-sm">
            <thead className="text-xs text-gray-500 border-t">
              <tr>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">

                  {t("forestList.catastro")}
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">
                  {t("forestList.especie")}
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">
                  {t("forestList.hectareas")}
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">
                  {t("forestList.años")}
                </th>
                {props.state === 'CREATED' &&
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipEditar}
                    >
                      <Link to="/forest/newmultiforest" state={{
                        montePadre,
                        childList

                      }} className="active" aria-pressed="true">
                      <button onClick={() => ('')} className="active pt-2" aria-pressed="true">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="#80817f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                          <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                          <path d="M16 5l3 3" />
                        </svg>
                      </button>
                      </Link>
                    </OverlayTrigger>
                  </th>

                }
              </tr>
            </thead>
            <tbody className="text-sm">
              <tr>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="text-right">{props.cadastre}</div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="text-center">
                    {t(Translations.translateForestTypeName(props.forestType))}
                  </div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="text-center">{props.size}</div>
                </td>
                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="text-center">{props.age}</div>
                </td>
              </tr>
              {props.childList.map((parcela) => {
                return (
                  <ForestListTableItemLine
                    props={parcela}
                    key={"line" + parcela.id}
                  ></ForestListTableItemLine>
                );
              })}
            </tbody>
          </table>
        </td>
        <td />
      </tr>
    </tbody >
  );
}
export default ForestListTableItem;
