import parse from "html-react-parser";
import moment from 'moment';
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ImageAbanca01 from './../../../images/excels/Excel_Abanca_01.png';
import ImageAbanca02 from './../../../images/excels/Excel_Abanca_02.png';

function GetTransactions00({ fileAttributes, setFileAttributes, periodoCoinscrap, updateStep, setTipoCuenta, setUltimosDigitos, setCuentaCoinscrap }) {
  const [t, i18n] = useTranslation("global");

  const banks = [{
    name: "Abanca", img: "https://www.afterbanks.com/api/icons/abanca.min.png", format: "csv", header: 1, separator: ";"
  },
  { name: "UniCaja", img: "https://www.afterbanks.com/api/icons/unicaja.min.png", format: "xls", header: 5, separator: "," },
  { name: "BBVA", img: "https://www.afterbanks.com/api/icons/bbva.min.png", format: "xlsx", header: 5, separator: "," },
  { name: "Caixa", img: "https://www.afterbanks.com/api/icons/caixa.min.png", format: "xls", header: 3, separator: "," },
  { name: "Santander", img: "https://www.afterbanks.com/api/icons/santander.min.png", format: "xls", header: 8, separator: "," },
  { name: "Bankinter", img: "https://www.afterbanks.com/api/icons/bankinter.min.png", format: "xlsx", header: 4, separator: "," },
  { name: "ING", img: "https://www.afterbanks.com/api/icons/ingdirect.min.png", format: "xls", header: 6, separator: "," },
  { name: "WiZink", img: "https://www.afterbanks.com/api/icons/wizink.min.png", format: "xls", header: 1, separator: "," },
  { name: "RuralVia", img: "https://www.afterbanks.com/api/icons/ruralvia.min.png", format: "xlsx", header: 3, separator: "," },
  { name: "Sabadell", img: "", format: "xls", header: 1, separator: "," },
  { name: "Kutxabank", img: "", format: "xls", header: 1, separator: "," },
  { name: "Otra", img: "", format: "xlsx", header: 1, separator: "," }];




  const handleSelectBank = (nombre, formato, cabecera, separador) => {
    //alert(`${nombre} ${formato} ${cabecera} ${separador}`);
    let updatedValue = { "name": nombre, "format": formato, "header": cabecera, "separator": separador };
    setFileAttributes(fileAttributes => ({
      ...fileAttributes,
      ...updatedValue
    }));
  }

  const [showCoinscrapDataCondition, setShowCoinscrapDataCondition] = useState(false)
  const handleSelect = (hasCoinscrap) => {
    if (hasCoinscrap) {
      setShowCoinscrapDataCondition(true)
    } else {
      setShowCoinscrapDataCondition(false)

    }
  }


  return (
    <main>

      <div className="min-h-screen h-full flex flex-col after:flex-1">


        <div className="py-8">
          <div className="max-w-6xl mx-auto">
            <div className=' mb-12'>
              <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("exceltransactions.title01")} ✨</h1>
              <h3 className="text-gray-800">{t("exceltransactions.subtitle01")}</h3>
              {showCoinscrapDataCondition && <div className='mt-4 px-4 py-2 rounded-sm text-sm text-white bg-yellow-500 text-center'> {parse(t("exceltransactions.coinscrapCondition"))} <b>{moment(periodoCoinscrap).format("DD/MM/yyyy")}</b></div>}
            </div>
            {/* Form */}
            <form onSubmit={(event) => event.preventDefault()}>

              <div>

                <div className="row">
                  <div className='mb-4 col-12 col-sm-6 col-md-6 col-xl-3'>
                    <label className='block text-sm font-medium mb-1'>{t("exceltransactions.typeAccountLabel")} <span className="text-red">*</span></label>
                    <select defaultValue="" className='form-input grey-input w-full' onChange={(e) => { setTipoCuenta(e.target.value); }}>
                      <option value={""} disabled="disabled">{t("exceltransactions.typeAccountSelect")} </option>
                      <option value="cuenta">{t("exceltransactions.typeAccountBank")}</option>
                      <option value="tarjeta">{t("exceltransactions.typeAccountCard")}</option>
                    </select>
                  </div>
                  <div className='mb-4 col-12 col-sm-6 col-md-6 col-xl-3'>
                    <label className='block text-sm font-medium mb-1'>{t("exceltransactions.lastDigits")} <span className="text-red">*</span></label>
                    <input
                      name='account'
                      type='number'
                      onKeyPress={(e) => { if (e.target.value.length >= 4) e.preventDefault() }}
                      placeholder="0000"
                      onChange={e => { setUltimosDigitos(e.target.value) }}
                      className="form-input w-full grey-input"
                    />
                  </div>

                  <div className='mb-4 col-12 col-xl-6  '>
                    <label className='block text-sm font-medium mb-1'>{t("exceltransactions.openBankingQuestion")} </label>
                    <div className="flex items-center ">
                      <label className="w-full form-input grey-input" style={{ border: "0px !important" }}>
                        <input type="checkbox"
                          className="form-checkbox "
                          name="coinscrap"
                          onChange={e => { setCuentaCoinscrap(e.target.checked); handleSelect(e.target.checked) }} />

                        <span className="ml-2">{t("exceltransactions.openBankingAnswer")} </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className='mb-4 col-12'>
                    <label className='block text-sm font-medium mb-1'>{t("exceltransactions.bankingEntitySelect")} <span className="text-red">*</span></label>
                    <div className="container-bank-list flex flex-wrap justify-center w-full form-input grey-input">

                      {banks.map((c, index) => (
                        <div key={index} className="" onClick={() => handleSelectBank(c.name, c.format, c.header, c.separator)}>
                          <div className={`box-bank ${fileAttributes.name == c.name ? "selected-box-bank" : ""} `} >
                            <div className="container-img-bank">
                              {c.img == "" ?
                                <p className='block text-sm font-medium text-gray-800'>{c.name === "Otra" ? t("exceltransactions.bankingEntityOther") : c.name}  </p>
                                :
                                <img src={c.img} className="img-bank" />
                              }
                            </div>
                          </div>
                        </div>
                      ))
                      }
                    </div>
                  </div>
                </div>
              </div>





              <div id="container-instruccions" className='mb-5'>
                <div id="abanca-info" >
                  {/* TODO: translate */}
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "Abanca" ? "block" : "hidden"}`}>
                      <Accordion.Header>Instrucciones para obtener Excel de Abanca</Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">
                          <li>Inicia sesión en la <a href="https://bancaelectronica.abanca.com/" target="_blank"> Banca electrónica</a>.  </li>
                          <li>Pulsa sobre la opción  <i>"Cuentas"</i> en el menú horizontal para poder ver todas tus cuentas. </li>
                          <li>Pincha sobre el enlace <i>"Movimientos"</i> de la cuenta de la cual quieras obtener la información. </li>
                          <img alt='logoBank' className='mx-auto' src={ImageAbanca01} />
                          <li>En la parte superior de la tabla con los movimientos verás dos pestañas "Últimos días" y <i>"Bucar más movimientos"</i>, haz click con el ratón en esta última. </li>
                          <li>Selecciona el periodo que quieres descargar. Recuerda que solo se importará la información anterior al <b>{moment(periodoCoinscrap).format("DD/MM/yyyy")}</b>. Si el rango de tiempo seleccionado es superior a 90 días tendrás que validar la descarga. </li>
                          <li>Al final de la tabla con el listado de movimientos verás un apartado con el título <i> "Descargar movimientos en formato:"</i> Presiona el primer icono para descargar un "Excel".</li>
                          <img alt='logoBank2' className='mx-auto' src={ImageAbanca02} />
                          <li>Si ya tienes el archivo, puedes pasar al siguiente paso.</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "UniCaja" ? "block" : "hidden"}`}>
                      <Accordion.Header>Instrucciones para obtener Excel de UniCaja</Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">
                          <li>Inicia sesión en la <a href="https://bancaelectronica.abanca.com/" target="_blank"> Banca electrónica</a>.  </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "BBVA" ? "block" : "hidden"}`}>
                      <Accordion.Header>Instrucciones para obtener Excel de BBVA</Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">
                          <li>Inicia sesión en la <a href="https://bancaelectronica.abanca.com/" target="_blank"> Banca electrónica</a>.  </li>

                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "Caixa" ? "block" : "hidden"}`}>
                      <Accordion.Header>Instrucciones para obtener Excel de La Caixa</Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">
                          <li>Inicia sesión en la <a href="https://bancaelectronica.abanca.com/" target="_blank"> Banca electrónica</a>.  </li>

                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>



                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "Santander" ? "block" : "hidden"}`}>
                      <Accordion.Header>Instrucciones para obtener Excel de Santander</Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">
                          <li>Inicia sesión en la <a href="https://bancaelectronica.abanca.com/" target="_blank"> Banca electrónica</a>.  </li>

                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>



                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "Bankinter" ? "block" : "hidden"}`}>
                      <Accordion.Header>Instrucciones para obtener Excel de Bankinter</Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">
                          <li>Inicia sesión en la <a href="https://bancaelectronica.abanca.com/" target="_blank"> Banca electrónica</a>.  </li>

                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "WiZink" ? "block" : "hidden"}`}>
                      <Accordion.Header>Instrucciones para obtener Excel de WiZink</Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">
                          <li>Inicia sesión en la <a href="https://bancaelectronica.abanca.com/" target="_blank"> Banca electrónica</a>.  </li>

                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "ING" ? "block" : "hidden"}`}>
                      <Accordion.Header>Instrucciones para obtener Excel de ING</Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">
                          <li>Inicia sesión en la <a href="https://bancaelectronica.abanca.com/" target="_blank"> Banca electrónica</a>.  </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "RuralVia" ? "block" : "hidden"}`}>
                      <Accordion.Header>Instrucciones para obtener Excel de RuralVia</Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">
                          <li>Inicia sesión en la <a href="https://bancaelectronica.abanca.com/" target="_blank"> Banca electrónica</a>.  </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="0" className={`${fileAttributes.name == "Otra" ? "block" : "hidden"}`}>
                      <Accordion.Header>{t("exceltransactions.instructions.other.title")} </Accordion.Header>
                      <Accordion.Body>
                        <ol className="bank-instrucction-list">


                          <li>{t("exceltransactions.instructions.other.01")} <b style={{color: 'var(--airco2-green-color)'}}>{t("exceltransactions.instructions.other.01a")}</b> {t("exceltransactions.instructions.other.01b")} </li>
                          <li>{t("exceltransactions.instructions.other.02a")}
                            <a download={t("exceltransactions.filename")} href={"/downloads/excel/" + t("exceltransactions.filename")} title="ImageName">
                              {t("exceltransactions.instructions.other.02link")}
                            </a>
                            {t("exceltransactions.instructions.other.02b")}</li>
                          <li>{t("exceltransactions.instructions.other.03")}  </li>
                          <li>{t("exceltransactions.instructions.other.04")}</li>

                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>




              </div>
              <div className="flex items-center justify-between">
                <a className="btn sec-green-background hover:sec-green-background text-white ml-auto" onClick={() => { updateStep(1) }}>{t("exceltransactions.next")} </a>
              </div>
            </form>

          </div>
        </div>

      </div>





    </main>
  );
}

export default GetTransactions00;