import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../Sidebar';
import UserService from '../../../services/UserService';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import OrganizacionesComponent from './OganizacionesComponent';
import { useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { useTour } from '../../../context/TourContext';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import steps from '../data/TourSteps';
import translations from '../../../services/Translations';
import { customButtonStylesTourTooltip } from '../../../services/Functions';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function PreviousData() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const clasDiv = 'flex flex-wrap justify-center gap-3';
  const [numberOrg, setNumberOrg] = useState(2);
  const [yearCalc, setYearCalc] = useState("");
  const [empleados, setEmpleados] = useState("");
  const [leanGrean, setLeanGrean] = useState("");
  const [hasGlobalPactTrackingProtocol, setHasGlobalPactTrackingProtocol] = useState(undefined);
  const [hasCommittedGlobalPactInfringements, setHasCommittedGlobalPactInfringements] = useState(undefined);
  const [hasRelationWithWeaponManufacturingSelling, setHasRelationWithWeaponManufacturingSelling] = useState(undefined);
  const [averageGrossHourlyWageWomen, setAverageGrossHourlyWageWomen] = useState();
  const [averageGrossHourlyWageMen, setAverageGrossHourlyWageMen] = useState();
  const [numberInManagementCommitteeWomen, setNumberInManagementCommitteeWomen] = useState();
  const [numberInManagementCommitteeMen, setNumberInManagementCommitteeMen] = useState();
  const [hasConductsActivitiesInSensitiveBiodiversityAreas, setHasConductsActivitiesInSensitiveBiodiversityAreas] = useState(undefined);
  const [amountOfDischargesEmittedToWaterBodies, setAmountOfDischargesEmittedToWaterBodies] = useState();
  const [aiDescription, setAiDescription] = useState();
  const [number, setNumber] = useState();
  const [unit, setUnit] = useState();

  const [questionnaire, setQuestionnaire] = useState("");
  const [idUserAdmin, setIdUserAdmin] = useState("");


  // const [superficie, setSuperficie] = useState("");
  const [anualTurnover, setAnualTurnover] = useState("")
  const [yearCalcError, setYearCalcError] = useState(null);
  // const [superficieError, setSuperficieError] = useState(null);
  const [empleadosError, setEmpleadosError] = useState(null);
  const [orgError, setOrgError] = useState(null);
  const [leanError, setLeanError] = useState(null);
  const [indiceActividadError, setindiceActividadError] = useState(null);
  const location = useLocation();
  const idUser = UserService.getUserId()
  const navigate = useNavigate();

  const storedData = localStorage.getItem('previousData');
  const lastOrgCreated = localStorage.getItem('lastOrgCreated');


  if (location.state == null) {
    window.location.href = "/footprint/myfootprint";
  }

  useEffect(() => {
    if (location.state != undefined) {
      setQuestionnaire(location.state.questionnaire);
      setIdUserAdmin(location.state.idUser);
    } else {
      if (storedData != undefined) {
        let data = JSON.parse(storedData);
        setQuestionnaire(data.value.questionnaire);
        setIdUserAdmin(data.value.idUserAdmin);
      }
    }
  }, [storedData])





  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (storedData != null) {
      let data = JSON.parse(storedData);
      let now = new Date().getTime();
      if (now > data.expiry) {
        localStorage.removeItem('previousData');
        localStorage.removeItem('lastOrgCreated');
      } else {
        setYearCalc(data.value.año)
        setLeanGrean(data.value.leanGrean)
        setAnualTurnover(data.value.facturacion)
        setEmpleados(data.value.empleados)
        setHasCommittedGlobalPactInfringements(data.value.hasCommittedGlobalPactInfringements)
        setHasGlobalPactTrackingProtocol(data.value.hasGlobalPactTrackingProtocol)
        setHasRelationWithWeaponManufacturingSelling(data.value.hasRelationWithWeaponManufacturingSelling)
        setAverageGrossHourlyWageWomen(data.value.averageGrossHourlyWageWomen)
        setAverageGrossHourlyWageMen(data.value.averageGrossHourlyWageMen)
        setNumberInManagementCommitteeWomen(data.value.numberInManagementCommitteeWomen)
        setNumberInManagementCommitteeMen(data.value.numberInManagementCommitteeMen)
        setHasConductsActivitiesInSensitiveBiodiversityAreas(data.value.hasConductsActivitiesInSensitiveBiodiversityAreas)
        setAmountOfDischargesEmittedToWaterBodies(data.value.amountOfDischargesEmittedToWaterBodies)
      }
    }
  }, [])

  useEffect(() => {
    if (lastOrgCreated != null) {
    }
  }, [])



  const [message, setMessage] = useState({
    type: '',
    message: '',
    open: false,
  });

  const cleanMessage = () => {
    setMessage({ ...message, type: "", message: "", open: false });

  }

  const yearActual = new Date().getFullYear();
  const years = [];

  for (let year = process.env.REACT_APP_CH_YEAR; year <= yearActual; year++) {
    years.push(year);
  }


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setNumberOrg(1);
      } else {
        setNumberOrg(2);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let [idOrg, updateIdOrg] = useState('');
  let [total, updateTotal] = useState('')
  let [sector, updateSector] = useState('')

  const childData = (v) => {
    updateIdOrg(v)

  }
  const childTotal = (v) => {
    updateTotal(v)
  }

  const childSector = (v) => {
    updateSector(v)

  }



  const handleSubmit = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_CH_CALCULATION;
      if (!yearCalc) {
        setYearCalcError(t("footPrint.errYear"));
        return;
      } else {
        setYearCalcError(null);
      }



      if (!idOrg) {
        setOrgError(t("footPrint.errOrg"));
        return;
      } else {
        setOrgError(null);
      }

      if (!empleados) {
        setEmpleadosError(t("footPrint.errEmpleados"));
        return;
      } else {
        setEmpleadosError(null);
      }


      if (sector === 8 && !leanGrean) {
        setLeanError(t("footPrint.errLean"));
        return;
      } else {
        setLeanError(null);
      }

      if (!aiDescription && (number || unit)) {
        setindiceActividadError(t("footPrint.errAiDescription"));
        return;
      } else {
        setindiceActividadError(null);
      }
      if (!number && (aiDescription || unit)) {
        setindiceActividadError(t("footPrint.errAiDescription"));
        return;
      } else {
        setindiceActividadError(null);
      }
      if (!unit && (number || aiDescription)) {
        setindiceActividadError(t("footPrint.errAiDescription"));
        return;
      } else {
        setindiceActividadError(null);
      }



      let data = {
        "userId": UserService.getUsername() == "admin@airco2.eu" ? idUserAdmin.value : idUser,
        "year": yearCalc,
        "leanGrean": leanGrean,
        "employees": empleados,
        "origin": "AirCO2",
        "organizationId": idOrg,
        "questionnaireId": questionnaire,
        "annualTurnover": anualTurnover,
        "iaDescription": aiDescription,
        "iaNumber": number,
        "iaUnit": unit

      };

      if (hasGlobalPactTrackingProtocol !== undefined) {
        data = {
          ...data,
          "hasGlobalPactTrackingProtocol": hasGlobalPactTrackingProtocol
        };
      }
      if (hasCommittedGlobalPactInfringements !== undefined) {
        data = {
          ...data,
          "hasCommittedGlobalPactInfringements": hasCommittedGlobalPactInfringements
        };
      }
      if (hasRelationWithWeaponManufacturingSelling !== undefined) {
        data = {
          ...data,
          "hasRelationWithWeaponManufacturingSelling": hasRelationWithWeaponManufacturingSelling
        };
      }
      if (averageGrossHourlyWageWomen) {
        data = {
          ...data,
          "averageGrossHourlyWageWomen": averageGrossHourlyWageWomen
        };
      }
      if (averageGrossHourlyWageMen) {
        data = {
          ...data,
          "averageGrossHourlyWageMen": averageGrossHourlyWageMen
        };
      }
      if (numberInManagementCommitteeWomen) {
        data = {
          ...data,
          "numberInManagementCommitteeWomen": numberInManagementCommitteeWomen
        };
      }
      if (numberInManagementCommitteeMen) {
        data = {
          ...data,
          "numberInManagementCommitteeMen": numberInManagementCommitteeMen
        };
      }
      if (hasConductsActivitiesInSensitiveBiodiversityAreas !== undefined) {
        data = {
          ...data,
          "hasConductsActivitiesInSensitiveBiodiversityAreas": hasConductsActivitiesInSensitiveBiodiversityAreas
        };
      }
      if (amountOfDischargesEmittedToWaterBodies) {
        data = {
          ...data,
          "amountOfDischargesEmittedToWaterBodies": amountOfDischargesEmittedToWaterBodies
        };
      }


      const response = await axios.post(url, data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',

          'Authorization': `Bearer ${UserService.getToken()}`
        }
      });

      if (response.status === 200) {
        navigate('/footprint/carbonmanager', { state: { user: idUserAdmin, calculationOutputDto: response.data } });
      }


      setMessage({ ...message, type: 'success', message: t('footPrint.new-ok') });
      localStorage.removeItem("previousData");
      localStorage.removeItem("lastOrgCreated");

    } catch (error) {
      console.error('Error:', error.message);
      setMessage({ ...message, type: 'error', message: t('footPrint.new-ko') });
    } finally {
      setLoading(false);
    }

  };

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const handleClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };


  //TOUR
  const { setStepIndex, startTour, stopTour, isTourRunning, currentStepIndex } = useTour();
  const [disableAutoScroll, setDisableAutoScroll] = useState(false);

  useEffect(() => {
    if (location.state?.tour) {
      startTour();
    }
  }, [location.state]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        stopTour()
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDataFromHeader = (data) => {
    if (data == true) {
      startTour();
    }
  }

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;

    let mainDiv = document.getElementById('contentAreaDiv');
    let hasOverflowStyle = window.getComputedStyle(mainDiv).getPropertyValue('overflow');
    if (hasOverflowStyle) {
      mainDiv.style.removeProperty('overflow');
    }

    if (type === 'step:before' && steps[index].disableScrolling) {
      setDisableAutoScroll(true); // Disable auto-scroll for this step
    } else {
      setDisableAutoScroll(false); // Enable auto-scroll for other steps
    }

    // Update step index on step change
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      setStepIndex(index + 1);
      if (index + 1 == 14) {
        stopTour();
      }

    } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
      setStepIndex(index - 1);
    } else if (ACTIONS.CLOSE === action) {
      stopTour();
      setStepIndex(0);
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      stopTour();
      setStepIndex(0);
    }
  };

  const locale = translations.translationsTourTooltip(lng) || translations.translationsTourTooltip("es");

  const renderTooltipLeanGrean = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("footPrint.leanGreanTooltip")}
    </Tooltip>
  );

  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden" id="contentAreaDiv">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} triggerTour={handleDataFromHeader} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {idUserAdmin !== null &&
              <p className='text-right'> {t("footPrint.usuario")}: {idUserAdmin.label}</p>}
            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("footPrint.previousData")}</h1>
              <div className='mt-2'>
                <p>
                  {t("footPrint.previousData2")}
                </p>
                <p>
                  {t("footPrint.previousData3")}
                </p>
              </div>
            </div>

            {/* Content */}
            <div className='max-w-6xl mx-auto w-full'>
              <div className='flex flex-wrap items-center m-4 my-first-step'>
                <label className="block text-sm font-medium mb-1" htmlFor="yearCalc">{t("footPrint.yearCalculo")} <span className="text-red-500">*</span></label>
                <select required value={yearCalc || ''} list="yearCalc"
                  className="form-user-data w-full bg-white" id="yearCalc" name="yearCalc"
                  onChange={(e) => { cleanMessage(); setYearCalc(e.target.value); setYearCalcError(null); }}>
                  {yearCalc ? null : (
                    <option className='italic' disabled="disabled" value={''}>
                      {t("footPrint.chooseYear")}
                    </option>
                  )}

                  {years.map((x, y) => (
                    <option key={y} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
                {yearCalcError && <div className="error-message text-red italic px-1 text-xs font-bold">{yearCalcError}</div>}
              </div >

              <div className='flex flex-wrap items-center m-4'>
                <label className='block text-sm font-medium mb-1'>{t("footPrint.chooseOrg")} <span className="text-red-500">*</span></label>
                <div className='flex justify-center m-4 w-full my-second-step'>
                  {idUserAdmin != "" && <OrganizacionesComponent
                    clase={clasDiv}
                    numberOrg={numberOrg}
                    childData={childData}
                    childTotal={childTotal}
                    childSector={childSector}
                    idUserAdmin={idUserAdmin !== undefined ? idUserAdmin : null}
                    previousData={{
                      año: yearCalc == "" ? "" : yearCalc,
                      facturacion: anualTurnover == "" ? "" : anualTurnover,
                      empleados: empleados == "" ? "" : empleados,
                      leanGrean: leanGrean == "" ? "" : leanGrean,
                      questionnaire: questionnaire,
                      idUserAdmin: idUserAdmin,
                      hasGlobalPactTrackingProtocol: hasGlobalPactTrackingProtocol,
                      hasCommittedGlobalPactInfringements: hasCommittedGlobalPactInfringements,
                      hasRelationWithWeaponManufacturingSelling: hasRelationWithWeaponManufacturingSelling,
                      averageGrossHourlyWageWomen: averageGrossHourlyWageWomen,
                      averageGrossHourlyWageMen: averageGrossHourlyWageMen,
                      numberInManagementCommitteeWomen: numberInManagementCommitteeWomen,
                      numberInManagementCommitteeMen: numberInManagementCommitteeMen,
                      hasConductsActivitiesInSensitiveBiodiversityAreas: hasConductsActivitiesInSensitiveBiodiversityAreas,
                      amountOfDischargesEmittedToWaterBodies: amountOfDischargesEmittedToWaterBodies
                    }}
                    lastOrgCreated={lastOrgCreated}
                  > </OrganizacionesComponent>
                  }
                </div>
              </div>
              {orgError && <div className="error-message text-red italic px-1 text-xs font-bold text-center">{orgError}</div>}

              {/* <div className='flex flex-wrap m-4 pt-2'>
                <label className="block text-sm font-medium w-full  mb-1" htmlFor="name">{t("footPrint.superficie")} (m<sup>2</sup>) <span className="text-red-500">*</span></label>
                <input id="superficie" className="form-user-data  w-full bg-white" type="number" min="0" step="1" required defaultValue={superficie} onChange={(e) => { cleanMessage(); setSuperficie(e.target.value); setSuperficieError(null); }} />
                {superficieError && <div className="error-message text-red italic  px-1 text-xs font-bold">{superficieError}</div>}
              </div> */}

              <div className='flex flex-wrap m-4'>
                <label className="block text-sm font-medium w-full  mb-1" htmlFor="name">{t("footPrint.facturacionAnual")} (€)</label>
                <input id="superficie" className="my-third-step form-user-data  w-full bg-white" type="number" step="1" defaultValue={anualTurnover}
                  onChange={(e) => { cleanMessage(); setAnualTurnover(e.target.value) }} />
              </div>

              <div className='flex flex-wrap m-4'>
                <label className="block text-sm font-medium w-full  mb-1" htmlFor="empleados">{t("footPrint.nEmpleados")} <span className="text-red-500">*</span></label>
                <input id="empleados" className="my-fourth-step  form-user-data  w-full bg-white" type="number" min="0" step="1" required defaultValue={empleados}
                  onChange={(e) => { cleanMessage(); setEmpleados(e.target.value) }}
                  onKeyPress={(e) => {
                    if (e.key === "." || e.key === "-") {
                      e.preventDefault();
                    }
                  }} />
                {empleadosError && <div className="error-message text-red italic px-1 text-xs font-bold">{empleadosError}</div>}
              </div>
              {sector === 8 &&
                <div className='flex flex-wrap m-4'>
                  <label className="block text-sm font-medium w-full flex items-center  mb-1" htmlFor="leanGrean">{t("footPrint.leanGrean")}
                    <span className="text-red-500"> *</span>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipLeanGrean}
                    >
                      <button>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                          <path d="M12 9h.01" />
                          <path d="M11 12h1v4h1" />
                        </svg>
                      </button>
                    </OverlayTrigger>

                  </label>
                  <input id="leanGrean" className="my-fourth-step  form-user-data  w-full bg-white" type="number" min="0" step="1" required={sector === 8 ? true : false} defaultValue={leanGrean}
                    onChange={(e) => { cleanMessage(); setLeanGrean(e.target.value) }}
                    onKeyPress={(e) => {
                      if (e.key === "." || e.key === "-") {
                        e.preventDefault();
                      }
                    }} />
                  {leanError && <div className="error-message text-red italic px-1 text-xs font-bold">{leanError}</div>}
                </div>
              }

              <div className='flex flex-wrap m-4'>
                <label className="block text-sm  font-bold w-full  mb-1" htmlFor="indiceActividad">{t("footPrint.indiceActividad")}</label>
        
               

                  <div className='w-full flex flex-col md:flex-row gap-4 justify-between mt-4'>
                  <div className='w-full md:w-1/2'>
                  <label className="block text-sm font-medium w-full  mb-1" htmlFor="ia-description">{t("footPrint.ia-description")}</label>
                  <input id="ia-description" className="my-fourth-step  form-user-data  w-full bg-white" type="text" required={number || unit} defaultValue={aiDescription}
                    onChange={(e) => { cleanMessage(); setAiDescription(e.target.value) }}
                    onKeyPress={(e) => {
                      if (e.key === "." || e.key === "-") {
                        e.preventDefault();
                      }
                    }} />
                    </div>

                    <div className='w-full md:w-1/2'>
                      <label className="block text-sm font-medium w-full  mb-1" htmlFor="ia-number">{t("footPrint.ia-number")}</label>
                      <input id="ia-number" className="my-fourth-step  form-user-data  w-full bg-white" type="number" min="0" step="1" required={aiDescription || unit} defaultValue={number}
                        onChange={(e) => { cleanMessage(); setNumber(e.target.value) }}
                        onKeyPress={(e) => {
                          if (e.key === "." || e.key === "-") {
                            e.preventDefault();
                          }
                        }} />


                    </div>
                    <div className='w-full md:w-1/2'>
                      <label className="block text-sm font-medium w-full  mb-1" htmlFor="ia-unit">{t("footPrint.ia-unit")}</label>
                      <input id="ia-unit" className="my-fourth-step  form-user-data  w-full bg-white" type="text" required={aiDescription || number} defaultValue={unit}
                        placeholder={t("footPrint.ia-unit-placeholder")}
                        onChange={(e) => { cleanMessage(); setUnit(e.target.value) }}
                        onKeyPress={(e) => {
                          if (e.key === "." || e.key === "-") {
                            e.preventDefault();
                          }
                        }} />

                    </div>

                  </div>


                {indiceActividadError && <div className="error-message text-red italic px-1 text-xs font-bold">{indiceActividadError}</div>}
              </div>

              <div className="text-right m-4">
                <button type="button" onClick={handleClick} className="text-sm">
                  <div className='my-fifth-step  flex flex-inline'>
                    {showAdvancedOptions ? t("footPrint.hideAvancedOption") : t("footPrint.showAvancedOption")}
                    <svg xmlns="http://www.w3.org/2000/svg" className={`icon icon-tabler icon-tabler-chevron-up transition-transform duration-200  ${showAdvancedOptions ? 'transform rotate-180' : ''}`}
                      width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5"
                      stroke="#545a6d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M6 15l6 -6l6 6" />
                    </svg>
                  </div>
                </button>
              </div>


              <div id="advancedOptions" className={`m-4 ${!showAdvancedOptions ? "hidden" : ""}`}>
                <div className='mb-4'>
                  <p className='my-2 font-bold'>{t("footPrint.socialandwork")}</p>
                  <div className='flex flex-inline my-4'>
                    <input
                      name="hasGlobalPactTrackingProtocol"
                      onChange={(e) => setHasGlobalPactTrackingProtocol(e.target.checked)}
                      checked={hasGlobalPactTrackingProtocol}
                      className='custom-width-md'
                      style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                      type="checkbox" />
                    <label className='ml-2 text-sm'>{t("footPrint.hasGlobalPactTrackingProtocol")}</label>
                  </div>

                  <div className='flex flex-inline my-4'>
                    <input
                      name="hasCommittedGlobalPactInfringements"
                      onChange={(e) => setHasCommittedGlobalPactInfringements(e.target.checked)}
                      checked={hasCommittedGlobalPactInfringements}
                      className='custom-width-md'
                      style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                      type="checkbox" />
                    <label className='ml-2 text-sm'>{t("footPrint.hasCommittedGlobalPactInfringements")}</label>
                  </div>

                  <div className='flex flex-inline'>
                    <input
                      name="hasRelationWithWeaponManufacturingSelling"
                      onChange={(e) => setHasRelationWithWeaponManufacturingSelling(e.target.checked)}
                      checked={hasRelationWithWeaponManufacturingSelling}
                      className='custom-width-md'
                      style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                      type="checkbox" />
                    <label className='ml-2 text-sm'>{t("footPrint.hasRelationWithWeaponManufacturingSelling")}</label>
                  </div>


                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className='flex flex-wrap my-4'>
                        <label className="block text-sm font-medium w-full  mb-1" htmlFor="averageGrossHourlyWageWomen">
                          {t("footPrint.averageGrossHourlyWageWomen")}<span className="text-red-500">*</span>
                        </label>
                        <input id="averageGrossHourlyWageWomen"
                          className="form-user-data  w-full bg-white"
                          type="number" min="0" step="0.01" required
                          defaultValue={averageGrossHourlyWageWomen}
                          onChange={(e) => { cleanMessage(); setAverageGrossHourlyWageWomen(e.target.value) }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className='flex flex-wrap my-4'>
                        <label className="block text-sm font-medium w-full  mb-1" htmlFor="averageGrossHourlyWageMen">
                          {t("footPrint.averageGrossHourlyWageMen")} <span className="text-red-500">*</span>
                        </label>
                        <input id="averageGrossHourlyWageMen"
                          className="form-user-data  w-full bg-white"
                          type="number" min="0" step="0.01" required
                          defaultValue={averageGrossHourlyWageMen}
                          onChange={(e) => { cleanMessage(); setAverageGrossHourlyWageMen(e.target.value) }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className='flex flex-wrap my-4'>
                        <label className="block text-sm font-medium w-full  mb-1" htmlFor="numberInManagementCommitteeWomen">
                          {t("footPrint.numberInManagementCommitteeWomen")} <span className="text-red-500">*</span>
                        </label>
                        <input id="numberInManagementCommitteeWomen"
                          className="form-user-data  w-full bg-white"
                          type="number" min="0" step="1" required
                          defaultValue={numberInManagementCommitteeWomen}
                          onChange={(e) => { cleanMessage(); setNumberInManagementCommitteeWomen(e.target.value) }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className='flex flex-wrap my-4'>
                        <label className="block text-sm font-medium w-full  mb-1" htmlFor="numberInManagementCommitteeMen">
                          {t("footPrint.numberInManagementCommitteeMen")} <span className="text-red-500">*</span>
                        </label>
                        <input id="numberInManagementCommitteeMen"
                          className="form-user-data  w-full bg-white"
                          type="number" min="0" step="1" required
                          defaultValue={numberInManagementCommitteeMen}
                          onChange={(e) => { cleanMessage(); setNumberInManagementCommitteeMen(e.target.value) }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='pt-4'>
                  <p className='font-bold'>{t("footPrint.environment")}</p>
                  <div className="row">
                    <div className="col-12 col-md-6 flex">
                      <div className='flex flex-inline items-center my-4'>
                        <input
                          name="hasConductsActivitiesInSensitiveBiodiversityAreas"
                          onChange={(e) => setHasConductsActivitiesInSensitiveBiodiversityAreas(e.target.checked)}
                          checked={hasConductsActivitiesInSensitiveBiodiversityAreas}
                          className='custom-width-md'
                          style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                          type="checkbox" />
                        <label className='ml-2 text-sm'>{t("footPrint.hasConductsActivitiesInSensitiveBiodiversityAreas")}</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className='flex flex-wrap my-4'>
                        <label className="block text-sm font-medium w-full  mb-1" htmlFor="amountOfDischargesEmittedToWaterBodies">
                          {t("footPrint.amountOfDischargesEmittedToWaterBodies")} <span className="text-red-500">*</span></label>
                        <input id="amountOfDischargesEmittedToWaterBodies"
                          className="form-user-data  w-full bg-white"
                          type="number" min="0" step="0.01" required
                          defaultValue={amountOfDischargesEmittedToWaterBodies}
                          onChange={(e) => { cleanMessage(); setAmountOfDischargesEmittedToWaterBodies(e.target.value) }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className='flex items-center mt-8 pt-4 justify-center gap-4'>
                <button arial-label="btn-saveFom" className="btn sec-green-background text-white ml-3" type='onclick' onClick={() => window.history.back()}  >
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right rotate-180	" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 6l6 6l-6 6" />
                  </svg>
                  {t("footPrint.volver")}
                </button>

                <button disabled={false} arial-label="btn-saveFom" className="my-sixth-step btn sec-green-background text-white" type='submit' onClick={() => handleSubmit()}  >
                  {loading ? (
                    <div className="spinner-border" role="status">
                    </div>
                  ) : (
                    <>
                      {t("footPrint.next")}
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right " width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 6l6 6l-6 6" />
                      </svg>
                    </>
                  )}
                </button>
              </div>

            </div>
          </div>
        </main>
        <Joyride
          run={isTourRunning}
          steps={steps}
          stepIndex={currentStepIndex}
          callback={handleJoyrideCallback}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          styles={customButtonStylesTourTooltip()} // Apply your custom styles
          locale={locale}
          disableScrolling={disableAutoScroll}
          disableOverlayClose={true}

        />

      </div >
    </div >
  )
}