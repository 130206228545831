import SubscriptionPlans from "./SubscriptionPlans";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useState } from "react";
import BannerSolicitarInformacion from "./BannerSolicitarInformacion";
import { useTranslation } from "react-i18next";


export default function PlansFootprint() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");

    return (<>  <div className=" sec-neutro-background flex h-screen overflow-hidden">

        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                    <div className="mb-8">
                        <h1 className="text-2xl md:text-3xl text-gray-800 font-bold mb-2">{t("footPrint.planes.titulo")}</h1>
                        <p className="mb-2">{t("footPrint.planes.subtitulo1")}</p>
                        <p className="mb-2">{t("footPrint.planes.subtitulo2")}</p>
                        <div className="my-3 ">
                            <SubscriptionPlans></SubscriptionPlans>
                        </div>

                        <BannerSolicitarInformacion></BannerSolicitarInformacion>


                    </div>
                </div>


            </main>
        </div>
    </div>
    </>)
}