import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

export default function ButtonsCalculationBlock({ calculation, urlStamp, handleShowExcel, handleEditCalculation, handleShowReport, loadingReport }) {
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    const handleReductionCommitmentButton = () => {
        if (calculation?.data?.calculationStatus !== "CURRENTYEAR" && calculation?.data?.calculationStatus !== "DRAFT") {
            navigate(`../footprint/myfootprint/reductionCommitment/${calculation?.data?.id}`,
            { state: { data: { year: calculation?.data?.year, orgName: calculation?.data?.organization.name } } })
        }
    }

    const handleReportButton = () => {
        if (calculation?.data?.calculationStatus !== "CURRENTYEAR" || calculation?.data?.calculationStatus !== "DRAFT") {
            handleShowReport();
        }
    }

    const handleExcelButton = () => {
        if (calculation?.data?.calculationStatus !== "CURRENTYEAR" || calculation?.data?.calculationStatus !== "DRAFT") {
            handleShowExcel();
        }
    }

    const handleSeeResults = () => {
        let calculationDataReuslt = {...calculation.data};
        calculationDataReuslt.calculationStatus = "FINALIZED";
        handleEditCalculation(calculationDataReuslt)
    }
    

    return <>
        <div onClick={handleReductionCommitmentButton}
            className={`box-report-footprint rounded-sm border border-gray-200  ${calculation?.data?.calculationStatus === "CURRENTYEAR" || calculation?.data?.calculationStatus === "DRAFT" ? 'no-hover' : 'hover:bg-gray-200'} `} style={{ filter: (calculation?.data?.calculationStatus === "CURRENTYEAR" || calculation?.data?.calculationStatus === "DRAFT" ) ? "blur(2px)" : "blur(0)" }}>
            <div className='p-2 flex flex-col items-center'
                style={{ pointerEvents: calculation?.data?.calculationStatus === "CURRENTYEAR" ? 'none' : 'auto' }}>
                <div className='mb-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#545a6d" strokeWidth="1.5" className="bi bi-file-earmark-medical" viewBox="0 0 16 16">
                        <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z" />
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    </svg>
                </div>
                <p className="text-xs text-center">{t("footPrint.reductionCommitment")}</p>
            </div>
        </div>
        <div onClick={handleReportButton}
            className={`box-report-footprint rounded-sm border border-gray-200  ${calculation?.data?.calculationStatus === "CURRENTYEAR" || calculation?.data?.calculationStatus === "DRAFT" ? 'no-hover' : 'hover:bg-gray-200'} `} style={{ filter: (calculation?.data?.calculationStatus === "CURRENTYEAR" || calculation?.data?.calculationStatus === "DRAFT" ) ? "blur(2px)" : "blur(0)" }}>
            <div className='p-2 flex flex-col items-center'
                style={{ pointerEvents: calculation?.data?.calculationStatus === "CURRENTYEAR" ? 'none' : (loadingReport ? 'none' : 'auto') }}
            >

                <div className='mb-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#545a6d" strokeWidth="1.5" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                    </svg>

                </div>
                <p className="text-xs text-center">{t("footPrint.downReporte")}</p>
            </div>
        </div>

        {calculation?.data?.calculationStatus !== "FINALIZED" ?
        <div onClick={() => handleSeeResults(calculation.data)}
            className="box-report-footprint rounded-sm border border-gray-200 hover:bg-gray-200" >
            <div className='p-2 flex flex-col items-center'>
                <div className='mb-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#545a6d" strokeLinecap="round" strokeLinejoin="round" width="40" height="40" strokeWidth="1.5">
                        <path d="M19 22.5a4.75 4.75 0 0 1 3.5 -3.5a4.75 4.75 0 0 1 -3.5 -3.5a4.75 4.75 0 0 1 -3.5 3.5a4.75 4.75 0 0 1 3.5 3.5"></path> 
                        <path d="M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7"></path>
                        <path d="M3 10h18"></path>
                        <path d="M10 3v18"></path>
                    </svg> 
                </div>
                <p className="text-xs text-center">{t("footPrint.detalleEmisiones")}</p>
            </div>
        </div>
        :
        <div
            onClick={handleExcelButton}
            className={`box-report-footprint rounded-sm border border-gray-200  ${calculation?.data?.calculationStatus === "CURRENTYEAR" || calculation?.data?.calculationStatus === "DRAFT" ? 'no-hover' : 'hover:bg-gray-200'} `} disabled={(calculation?.data?.calculationStatus === "CURRENTYEAR" || calculation?.data?.calculationStatus === "DRAFT" ) ? true : false} style={{ filter: calculation?.data?.calculationStatus === "CURRENTYEAR" || calculation?.data?.calculationStatus === "DRAFT"  ? "blur(2px)" : "blur(0)" }}>
            <div className='p-2 flex flex-col items-center' >
                <div className='mb-2'>
                    {/* {loadingExcel ? (

                        <div className="spinner-border" role="status">

                        </div>
                    ) : ( */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#545a6d" strokeWidth="1.5" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
                        </svg>
                    {/* )} */}
                </div>
                <p className="text-xs text-center">{t("footPrint.downEmisiones")}</p>
            </div>
        </div>
        }
        <div onClick={() => handleEditCalculation(calculation.data)}
            className="box-report-footprint rounded-sm border border-gray-200 hover:bg-gray-200" >
            <div className='p-2 flex flex-col items-center'
            >
                <div className='mb-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#545a6d" strokeWidth="1.5" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                        <path d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12zM1 13V6h4v8H2a1 1 0 0 1-1-1zm5 1V6h9v7a1 1 0 0 1-1 1H6z" />
                    </svg>
                </div>
                <p className="text-xs text-center">{calculation?.data?.calculationStatus === "CURRENTYEAR" ? "Continuar el cálculo" : t("footPrint.detalleEmisiones")}</p>
            </div>
        </div>
    </>
}