import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { getFont } from '../../../services/FunctionsDataWhiteLabel';
import UserService from "../../../services/UserService";
import Header from '../Header';
import Sidebar from '../Sidebar';

export default function Tutorials() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");

    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(window.matchMedia(query).matches);

        useEffect(() => {
            const mediaQueryList = window.matchMedia(query);
            const documentChangeHandler = () => setMatches(mediaQueryList.matches);
            mediaQueryList.addListener(documentChangeHandler);

            return () => {
                mediaQueryList.removeListener(documentChangeHandler);
            };
        }, [query]);

        return matches;
    };
    const desktop = useMediaQuery('(min-width: 768px)');

    const [tutorialsData, setTutorialsData] = useState({
        data: undefined,
        isFetching: false
    });

    useEffect(() => {
        const fetchTutorialsData = async () => {
            setTutorialsData({ data: tutorialsData.data, isFetching: true });
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_TUTORIALS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                let state = [];
                response.data?.data?.forEach((element, i) => {
                    state.push({
                        index: i,
                        visible: false,
                        categoryId: element.id,
                        categoryName: element.name,
                        categoryDescription: element.description,
                        categoryIcon: element.icon,
                        subcategories: element.subcategoryList
                    })

                });
                setCategoryStates(state);
                setTutorialsData({ data: response.data, isFetching: false });
            } catch (exception) {
                setCategoryStates([]);
                setTutorialsData({ data: undefined, isFetching: false });
            }
        };
        fetchTutorialsData();
    }, [])

    useEffect(() => {
        if (tutorialsData.data) {
            const segments = window.location.href.split('/').filter(segment => !isNaN(segment) && segment !== '');
            let categoryUrl = segments[0];
            let subacategoryUrl = segments[1];
            let questionUrl = segments[2];

            if (categoryUrl != undefined) {
                let categoryId = categoryUrl ? Number(categoryUrl) : undefined;
                let subcategoryId = subacategoryUrl ? Number(subacategoryUrl) : undefined;
                let questionId = questionUrl ? Number(questionUrl) : undefined;

                if (categoryStates?.length > 0) {
                    setTimeout(() => {
                        handleQuestionsDisplay(categoryId, subcategoryId, questionId);
                    }, 0);
                }
            }
        }
    }, [tutorialsData.data]);



    const [categoryStates, setCategoryStates] = useState();
    const [currentQuestions, setCurrentQuestions] = useState([]);
    const [selectedSubcategory, setSelectedSubcategory] = useState();
    const [dinamicUrl, setDinamicUrl] = useState("");


    const questionRefs = useRef([]);



    const handleQuestionsDisplay = (categoryIdd, subcategoryIdd, questionIdd) => {
        let url = `/footprint/tutorials`

        let categoryArray = [...categoryStates];
        categoryArray.forEach((element) => {
            element.visible = false;
        });
        setCurrentQuestions([]);
        setSelectedSubcategory(undefined)

        if (categoryIdd) {
            let indexCategory = categoryArray.findIndex((element) => element.categoryId == categoryIdd);

            //Visibilidad categorias
            categoryArray[indexCategory].visible = true;
            setCategoryStates(categoryArray);

            //URL
            url += `/${categoryArray[indexCategory].categoryName}/${categoryArray[indexCategory].categoryId}`;


            let subcategoryArray = categoryArray[indexCategory].subcategories;
            let indexSubcategory;
            let questionArray;

            if (subcategoryIdd) {
                indexSubcategory = subcategoryArray.findIndex((element) => element.id == subcategoryIdd);
                questionArray = subcategoryArray[indexSubcategory].tutorialList;

                url += `/${subcategoryArray[indexSubcategory].name}/${subcategoryArray[indexSubcategory].id}`;
                setSelectedSubcategory({ id: subcategoryArray[indexSubcategory].id, name: subcategoryArray[indexSubcategory].name })


                let indexQuestion = 0;
                if (questionIdd != undefined) {
                    let indexFinder = questionArray.findIndex(item => item.id === questionIdd);
                    if (indexFinder != -1) {
                        indexQuestion = indexFinder;
                    }
                }

                if (questionArray.length > 0) {
                    url += `/${questionArray[indexQuestion].question}/${questionArray[indexQuestion].id}`;
                    setCurrentQuestions(questionArray);
                    scrollToQuestion(indexQuestion)

                }
            }
        }
        window.history.pushState({}, '', encodeURI(formatUrlSegment(url)));
        setDinamicUrl(formatUrlSegment(url));
        if (!isMenuCollapsed) {
            setIsMenuCollapsed(!isMenuCollapsed)
        }
    }


    // Función para hacer scroll a la pregunta específica
    const scrollToQuestion = (index) => {
        if (questionRefs.current[index]) {
            questionRefs.current[index].scrollIntoView({ behavior: 'smooth' });

            setTimeout(() => {
                const yOffset = 150;
                const y = questionRefs.current[index].getBoundingClientRect().top + window.scrollY - yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            }, 300);
        }
    };


    const formatUrlSegment = (segment) => {
        // Reemplaza caracteres acentuados por su equivalente sin tilde
        const normalizeSegment = segment.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        // Elimina cualquier caracter que no sea alfanumérico, espacios, guiones o barras
        const cleanedSegment = normalizeSegment.replace(/[^a-zA-Z0-9\s-/]/g, '');

        // Reemplaza espacios múltiples por un solo guion y convierte a minúsculas
        return cleanedSegment.trim().replace(/\s+/g, '-').toLowerCase();
    };


    const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);

    return (
        <div className={`sec-neutro-background ${getFont(UserService.getwhiteLabel())} flex h-screen overflow-hidden`}>

            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main style={{ height: desktop ? 'calc(100vh - 64px)' : '', overflowY: desktop ? "hidden" : "" }}>

                    <div className="px-4 sm:px-6 lg:px-8  w-full max-w-9xl mx-auto">
                        {/* <div className="mb-4 md:mb-8">
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Tutoriales</h1>
                        </div> */}

                        <div>
                            {tutorialsData.isFetching &&
                                <div className="text-center">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" />
                                </div>
                            }

                            {!tutorialsData.isFetching && tutorialsData.data && categoryStates &&
                                <>
                                    <Row>
                                        <Col className={`tutorial-menu-position md:relative sidebar-airco2`} md={3}
                                            style={{ height: desktop ? 'calc(100vh - 64px)' : '', overflowY: "auto" }}>

                                            <div className="md:hidden font-semibold text-sm">

                                                <button className="w-full text-left flex items-center" onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}>
                                                    <span className="md:hidden"><svg xmlns="http://www.w3.org/2000/svg"
                                                        className="icon icon-tabler icon-tabler-menu-2 mr-2"
                                                        width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M4 6l16 0" />
                                                        <path d="M4 12l16 0" />
                                                        <path d="M4 18l16 0" />
                                                    </svg></span>
                                                    {isMenuCollapsed ? t("footPrint.tutorialespage.showCat") : t("footPrint.tutorialespage.hideCat")}

                                                </button>
                                            </div>

                                            <div className="hidden md:block w-full text-left  font-semibold mb-4 pt-4">
                                                {t("footPrint.tutorialespage.categorias")}
                                            </div>
                                            {!tutorialsData.isFetching ?
                                                <ul className={`ml-2 mb-4 text-sm ${isMenuCollapsed ? 'hidden md:block' : ''}`}>
                                                    {tutorialsData.data?.data?.map((category, i) => {
                                                        return (
                                                            <li key={`category-${category.id}`} className="space-y-2 mb-3">
                                                                <button className="w-full text-left flex items-center justify-between " onClick={() => handleQuestionsDisplay(category.id, undefined, undefined)}>
                                                                    <span className="flex items-center">
                                                                        <span className='text-sm italic' dangerouslySetInnerHTML={{ __html: category.icon }} />
                                                                        <span className="font-bold ml-2">{category.name}</span>
                                                                    </span>
                                                                </button>

                                                                <ul className="">
                                                                    {category?.subcategoryList?.map((subcategory) => {
                                                                        return (
                                                                            <li key={`subcategory-${subcategory.id}`} className="sapce-y-2 mb-2">
                                                                                <button onClick={() => handleQuestionsDisplay(category.id, subcategory.id, undefined)}
                                                                                    className=" text-left flex items-center">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        style={{ transform: (categoryStates[i]?.visible && selectedSubcategory?.id == subcategory.id) ? 'rotate(90deg)' : '', minWidth: "24px", maxWidth: "24px" }}
                                                                                        className="icon icon-tabler icon-tabler-chevron-right ml-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="#64748b" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                        <path d="M9 6l6 6l-6 6" />
                                                                                    </svg>
                                                                                    <span className={`ml-2 ${selectedSubcategory?.id == subcategory.id && "font-bold"}`}>{subcategory.name}</span>
                                                                                </button>

                                                                                {categoryStates[i]?.visible && selectedSubcategory?.id == subcategory.id && <>
                                                                                    <ul className="ml-5">
                                                                                        {subcategory.tutorialList.map((elements, j) => {
                                                                                            return (<li className="space-y-2 mb-2" key={j} onClick={() => handleQuestionsDisplay(category.id, subcategory.id, elements.id)}>
                                                                                                {elements.question}
                                                                                            </li>)
                                                                                        })}
                                                                                    </ul>
                                                                                </>
                                                                                }
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>

                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                :
                                                <Spinner />}
                                        </Col>

                                        <Col md={9} className={`md:px-8 sidebar-airco2 ${isMenuCollapsed ? "block" : "hidden"} pt-4`}
                                            style={{ height: desktop ? 'calc(100vh - 64px)' : '', overflowY: "auto" }}>
                                            <div className="text-sm mb-4 font-bold flex items-center">
                                                <span onClick={() => handleQuestionsDisplay(undefined, undefined, undefined)} className="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home-move" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2" />
                                                        <path d="M19 12h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h5.5" />
                                                        <path d="M16 19h6" />
                                                        <path d="M19 16l-3 3l3 3" />
                                                    </svg>
                                                </span>

                                                <span onClick={() => handleQuestionsDisplay(undefined, undefined, undefined)}>
                                                    {t("footPrint.tutorialespage.tutoriales")}
                                                </span>
                                                {categoryStates?.find(category => category.visible) &&
                                                    <> &nbsp;/&nbsp;
                                                        <span onClick={() => handleQuestionsDisplay(categoryStates?.find(category => category.visible)?.categoryId, undefined, undefined)}>{categoryStates?.find(category => category.visible)?.categoryName}</span>
                                                    </>}
                                                {selectedSubcategory && <> &nbsp;/&nbsp;{ selectedSubcategory?.name} </>}
                                            </div>

                                            <ul>
                                                {currentQuestions.length > 0 &&
                                                    currentQuestions.map((v, i) => {
                                                        return (
                                                            <li key={i} ref={(el) => questionRefs.current[i] = el}>

                                                                <div >
                                                                    <div>
                                                                        <h5 className="h5 text-black">{v.question}</h5>

                                                                    </div>
                                                                    <div>

                                                                        <div className="max-w-lg w-full mb-4">
                                                                            <div dangerouslySetInnerHTML={{ __html: v.answer }} />
                                                                        </div>
                                                                        <Row>
                                                                            {v.imageUrl &&
                                                                                <Col md={v.videoUrl == undefined ? 12 : 6}>
                                                                                    <div className="mb-4 flex justify-center">
                                                                                        <a href={v.imageUrl} target="_blank" rel="noopener noreferrer">
                                                                                            <img src={v.imageUrl} alt="" className="" height={500} width={350} />
                                                                                        </a>
                                                                                    </div>
                                                                                </Col>
                                                                            }
                                                                            {v.videoUrl &&
                                                                                <Col md={v.imageUrl == undefined ? 12 : 6}>
                                                                                    <div className="mb-4" style={{ maxWidth: '42rem' }}>
                                                                                        <iframe
                                                                                            title=""
                                                                                            width="560"
                                                                                            height="315"
                                                                                            src={v.videoUrl}
                                                                                            allowFullScreen
                                                                                            className="w-full"
                                                                                        ></iframe>
                                                                                    </div>
                                                                                </Col>
                                                                            }
                                                                        </Row>

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }

                                                {/* Vista categorías y subcategorías */}
                                                {categoryStates?.length > 0 && currentQuestions.length === 0 &&
                                                    <div className="">

                                                        {categoryStates.find(category => category.visible) === undefined && <>
                                                            <div>
                                                                <h2 className="text-2xl font-bold mb-3">{t("footPrint.tutorialespage.title")}</h2>
                                                                <p className="text-md">{t("footPrint.tutorialespage.subtitle1")}</p>
                                                                <p className="text-md">  {t("footPrint.tutorialespage.subtitle2")}  <a href="mailto:tech@airco2.earth">tech@airco2.earth</a>.</p>
                                                            </div>
                                                            <div className="mx-auto py-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                                                {categoryStates.map((category, i) => {
                                                                    return (
                                                                        <div key={`category-${category.categoryId}`}
                                                                            className="card-tutorial bg-white border border-gray-200 rounded-lg p-6 shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-2 text-center"
                                                                            onClick={() => handleQuestionsDisplay(category.categoryId, undefined, undefined)}>

                                                                            <div className="card-tutorial-icon flex justify-center items-center text-green-500 text-4xl mb-2">
                                                                                <span dangerouslySetInnerHTML={{ __html: category.categoryIcon }} />
                                                                            </div>
                                                                            <div className="card-tutorial-title text-lg font-semibold mb-2">
                                                                                {category.categoryName}
                                                                            </div>
                                                                            <div className="card-tutorial-description text-gray-500 text-sm">
                                                                                {category.categoryDescription}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </>}
                                                        {categoryStates.find(category => category.visible) && <>
                                                            <div className="">
                                                                <h2 className="text-xl font-bold">{categoryStates.find(category => category.visible).categoryName}</h2>
                                                                <p>{categoryStates.find(category => category.visible).categoryDescription}</p>
                                                            </div>
                                                            <div className="mx-auto py-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                                                {categoryStates.find(category => category.visible).subcategories.map((subcategory, i) => {
                                                                    return (
                                                                        <div key={`category-${subcategory.id}`}
                                                                            className="card-tutorial bg-white border border-gray-200 rounded-lg p-6 shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-2 text-center"
                                                                            onClick={() => handleQuestionsDisplay(categoryStates.find(category => category.visible).categoryId, subcategory.id, undefined)} >
                                                                            <div className="card-tutorial-title text-lg font-semibold mb-2">
                                                                                {subcategory.name}
                                                                            </div>
                                                                            <div className="card-tutorial-description text-gray-500 text-sm">
                                                                                {subcategory.description}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </>
                                                        }
                                                    </div>
                                                }
                                            </ul>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </div>
                    </div>
                </main>
            </div>
        </div>


    )
}