import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../../context/createcontext";
import employe from '../../../../../images/team3.svg';
import { formatThousands } from "../../../utils/Utils";

export default function EmployeBilling({ highLights, formatNumber }) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");


    const [perEmployee, setPerEmployee] = useState();
    const [perBilling, setPerBilling] = useState();

    useEffect(() => {
        setPerEmployee(formatNumber(highLights?.data.perEmployee, 2));
        setPerBilling(formatNumber(highLights?.data.perBilling, 2));
    }, [highLights])




    return (
        <div className='grid-report-hightlights  rounded-sm border border-gray-200 px-2 py-3'>

            <div className='flex flex-col items-center '>
                <img src={employe} className="" />
            </div>

            <div className='flex flex-col text-center items-center justify-center text-sm'>
                {t("footPrint.empleadosFacturacion")}
            </div>

            <div className='flex flex-col justify-center text-center'>
                <table>
                    <tbody>
                        {!highLights.isFetching && <tr> <div className='text-center'>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true" /></div>
                        </tr>}
                        {highLights.isFetching && <>
                            {perEmployee !== undefined &&
                                <tr>
                                    <td className='font-bold text-xl text-right '>{formatThousands(perEmployee)}</td>
                                    <td className='text-xs text-left pl-2'>kgCO<sub>2</sub>e/{t("footPrint.employee")}</td>
                                </tr>
                            }
                            {perEmployee === undefined &&
                                <tr>
                                    <td colSpan={2} className='text-xs text-center'>
                                        {t("footPrint.sinDatoEmpleados")}
                                    </td>
                                </tr>}
                        </>}
                        {!highLights.isFetching && <tr><div className="text-center">  <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" /></div></tr>}

                        {highLights.isFetching && <>
                            {perBilling !== undefined &&
                                <tr>
                                    <td className='font-bold text-xl'>{formatThousands(perBilling)}</td>
                                    <td className='text-xs '> kgCO<sub>2</sub>e/{t("footPrint.facturacion")}</td>
                                </tr>}
                            {highLights.isFetching && perBilling === undefined && <tr>
                                <td colSpan={2} className='text-xs text-center'>
                                    {t("footPrint.sinDatoFacturacion")}
                                </td>
                            </tr>
                            }
                        </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
