import format from 'date-fns/format';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../context/createcontext";
import BarChart from './../charts/BarChart03';
import Translations from '../../../services/Translations';

// Import utilities
import { tailwindConfig } from './../utils/Utils';

function AnalyticsCard03({ data }) {

  const categoryMonthData = data;


  //Categorias únicas
  const categoryList = categoryMonthData.filter(x => x.h2o > 0).map((x) => {
    return x.category;
  })
  const uniqueCategories = categoryList.filter((elemento, index, self) => {
    return index === self.indexOf(elemento) && elemento !== null
  });


  //Meses únicos
  const monthList = categoryMonthData.map((x) => {
    return x.month;
  })
  const uniqueMonths = monthList.filter((elemento, index, self) => {
    return index === self.indexOf(elemento)
  });


  //Array de fechas para las Labels
  const currentYear = new Date().getFullYear();
 
  const monthsLabel = uniqueMonths.map((x) => {
    return format(new Date(currentYear, x - 1, '01'), 'MM/dd/yyyy');
  })



  const objH2OArrayCategory = new Array(uniqueCategories.length);

  for (let i = 0; i < uniqueCategories.length; i++) {
    let arrayh2ocategoriaxmes = new Array(uniqueMonths.length).fill(0);
    // debugger;
    categoryMonthData.map((emision) => {

      if (uniqueCategories[i] == emision.category) {

        for (let x = 0; x < uniqueMonths.length; x++) {
          if (uniqueMonths[x] == emision.month) {
            arrayh2ocategoriaxmes[x] += emision.h2o; //viene en litros
          }
        }
        objH2OArrayCategory[i] =
        {
          h2o: arrayh2ocategoriaxmes,
          category: uniqueCategories[i]
        }
      

      }
    })
  }

  // cambiar idioma
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const nameCategory = uniqueCategories.map((v) => {
    return Translations.translateCategories(v)
  })




  const bgcolors = [tailwindConfig().theme.colors.indigo[700], tailwindConfig().theme.colors.indigo[500], tailwindConfig().theme.colors.indigo[300]];
  const hbgcolors = [tailwindConfig().theme.colors.indigo[800], tailwindConfig().theme.colors.indigo[600], tailwindConfig().theme.colors.indigo[400]];
  var cont = -1;



  const datasetInfo = uniqueCategories.map((x) => {
    cont += 1;

    // const [Labelinit,setLabel]= usesestate(t(nameCategory[cont]));

    return (
      {
        // label: objH2OArrayCategory[cont].category.charAt(0).toUpperCase() + objH2OArrayCategory[cont].category.slice(1).toLowerCase(),
        label: t(nameCategory[cont]),
        data: objH2OArrayCategory[cont].h2o,
        backgroundColor: bgcolors[cont],
        hoverBackgroundColor: hbgcolors[cont],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      }
    );
  })
  // useEffect(()=>{
  //   datasetInfo
  // },[lng]
  // )



  const chartData = {
    labels: monthsLabel,
    datasets: datasetInfo,
  };


  return (
    <div id="ganalytics03" className="flex flex-col col-span-full sm:col-span-6 bg-white rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">{t("dash.huellahidrica")}</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      {
        uniqueCategories.length === 0 ? <p className='mt-4 text-center'> {t("dash.noemissiondata")} </p>: <BarChart data={chartData} width={595} height={248} unit={" L"} />
      }
    </div>
  );
}

export default AnalyticsCard03;
