import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfigFile from "./../css/tailwind.config.js";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig(tailwindConfigFile);
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
    maximumSignificantDigits: 9,
    notation: "compact",
  }).format(value);

export const formatThousands = (value) =>
  Intl.NumberFormat("de-DE", {
    style: "decimal",
    //maximumSignificantDigits: 9,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(value);

export const formatDateTime = (dateUTC) => {
  if (dateUTC !== null) {
    var date = new Date(dateUTC);
    return (
      [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }
};

export const formatDate = (dateUTC) => {
  if (dateUTC !== null) {
    var date = new Date(dateUTC);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
};

export const formatDefaultDate = (dateUTC) => {
  if (dateUTC !== null) {
    var date = new Date(dateUTC);
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
