import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { useTranslation } from 'react-i18next';

import PageNotFound from '../../legales-wp/PageNotFound';


export default function RedirectCoinscrap() {
  const [t, i18n] = useTranslation("global");

  // FUNCIONALIDADES DE USO
  const [sidebarOpen, setSidebarOpen] = useState(false);


  const queryParams = new URLSearchParams(window.location.search);
  const uniqueAccesToke = queryParams.get('uniqueAccesToke');
  // const uniqueAccesToke = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MzUyZjFiM2E3N2M3MTAwMTM3ODRhYTEiLCJpYXQiOjE2NjY2MTA3MDIsImV4cCI6MTY2NjYxMTMwMn0.Fr40Xuo905cQ65TljOX_PSKp1vqPx8TZGS1wxuaPeYU"
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  //Actualiza el attributo de coinsctrap del usuario a true y guarda fecha de Alta e ID
  const updateUser = (idCoinscrap) => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_ATTRIBUTE_COINSCRAP}${UserService.getUserId()}/coinscrap`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        },
        body: JSON.stringify(idCoinscrap)

      }).then(j => j.json())
      .then(response => {
        if (response.id != "") {
          setLoading(false)
        }
      }
      )

  }

  //ON BOARDING COINSCRAP
  useEffect(() => {
    setLoading(true)
    const dataToken = {
      "uniqueIdentifier": uniqueAccesToke
    }
    // (COINSCRAP) Validamos el token recibido en la URL tras la redirección en el alta en Open Banking
    // Devuelve un token que utilizaremos para realizar las próximas consultas
    fetch(`${process.env.REACT_APP_TOKEN_IDENTIFIER}`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(dataToken)
      }).then(j => j.json())
      .then(response => {
        //
        if (response.accessToken != undefined) {
          let accessToken = response.accessToken;
          // (COINSCRAP) Solicitamos la información del usuario y obtenemos su ID
          // Authorization con el token obtenido en el fetch anterior.
          fetch(`${process.env.REACT_APP_ONBOARDING_USER}`,
            {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${accessToken}`
              },
            }).then(j => j.json())
            .then(response => {
              if (response.id != undefined) {
                let userid = response.id;

                //Actualizamos información del usuario:
                updateUser(userid);

                //Obtenemos emisiones del usuario (Coinscrap)
                let dateStart = new Date();
                let dateEnd = new Date();
                dateStart.setDate(dateEnd.getDate() - 90);

                let getCarbonFootPrint = {
                  "type": "DATES_CATEGORY",
                  "aggregationFields": ["features.carbonFootprint.h20",
                    "features.carbonFootprint.co2",
                    "features.carbonFootprint.n2o",
                    "features.carbonFootprint.ch4",
                    "amount"],
                  "input": {
                    "payload": {
                      "startDate": dateStart.toISOString(),
                      "endDate": dateEnd.toISOString(),
                      "aggregationLevel": "day"
                    }
                  }
                };

                // (COINSCRAP) Obtenemos emisiones del usuario obtenido en la consulta anterior
                fetch(`${process.env.REACT_APP_ONBOARDING_EMISSIONS.replace("[id]", userid)
                  }`,
                  {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': `Bearer ${UserService.getToken()} `
                    },
                    body: JSON.stringify(getCarbonFootPrint)

                  }).then(j => j.json())
                  .then(response => {
                    if (response.output != undefined) {
                      let expenses = response.output.payload.dates.expenses;
                      let emissions = [];
                      for (let i = 0; i < expenses.length; i++) {
                        if ((expenses[i]["features.carbonFootprint.co2"] != undefined && expenses[i]["features.carbonFootprint.co2"] != 0) || (expenses[i]["features.carbonFootprint.h2o"] != undefined && expenses[i]["features.carbonFootprint.h2o"] != 0)) {
                          let item = {
                            "userId": UserService.getUserId(),
                            "accountingDate": expenses[i].year + "-" + (expenses[i].month.toString().length == 1 ? "0" + expenses[i].month : expenses[i].month) + "-" + (expenses[i].day.toString().length == 1 ? "0" + expenses[i].day : expenses[i].day) + "T00:00:00.000Z",
                            "category": expenses[i].category,
                            "subCategory": expenses[i].subcategory,
                            "co2": (expenses[i]["features.carbonFootprint.co2"] == undefined ? 0 : expenses[i]["features.carbonFootprint.co2"]),
                            "h2o": (expenses[i]["features.carbonFootprint.h2o"] == undefined ? 0 : expenses[i]["features.carbonFootprint.h2o"]),
                            "ch4": (expenses[i]["features.carbonFootprint.ch4"] == undefined ? 0 : expenses[i]["features.carbonFootprint.ch4"]),
                            "n2o": (expenses[i]["features.carbonFootprint.n2o"] == undefined ? 0 : expenses[i]["features.carbonFootprint.n2o"]),
                          };

                          emissions.push(item);
                        }
                      }

                      //Guardamos en nuestra BD la información de las emisiones recibidas 
                      fetch(`${process.env.REACT_APP_SAVE_EMISSIONS}`,
                        {
                          method: 'POST',
                          headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': `Bearer ${UserService.getToken()}`
                          },
                          body: JSON.stringify(emissions)
                        }).then(j => j.json())
                        .then(response => {
                          if (response.successfulUpdate == true) {
                            setLoading(false)
                           window.location.href = "/company/dashboardintranet";
                          } else {
                            setError(true);

                          }
                        }
                        )
                      setLoading(false)
                    } else {
                      setError(true);

                    }
                  }
                  )
              } else {
                setError(true);

              }
            }
            )


          setLoading(false)
        } else {
          setError(true);
        }
      }
      )
  }, []
  )


  return (
    <>
      {uniqueAccesToke == undefined ? <PageNotFound /> :
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* Content area */}
          <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

            {/*  Site header */}
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                {/* Page header */}
                <div className="sm:flex sm:justify-between sm:items-center mb-4">

                  {/* Left: Title */}
                  <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold text-center">{t("redirect.title")}</h1>
                    {/* <h2 className='text-xl md:text-2xl text-gray-800 font-bold pl-2'>$47,347.09</h2> */}
                  </div>


                </div>

                {/* Table */}
                <div className="sec-neutro-background" >
                  <div className="space-y-3 mb-8 text-center">


                    {loading ?


                      <>
                        <p style={{ marginLeft: '10px', marginTop: '10px' }}> {t("redirect.calculating")} <Spinner animation="border" ></Spinner></p>


                        <p>{t("redirect.calculatinginfo")}</p>
                      </>
                      : <>
                        {error && <p> {t("redirect.error")}</p>}
                        {!error && <p>{t("redirect.redirecting")} <Spinner animation="border" ></Spinner></p>
                        }
                      </>


                    }


                  </div>

                  <div className='mx-auto'>
                    <img alt='Obteniendo emisiones' className='rounded mx-auto d-block' src="/arbol_fondo.svg" style={{ maxWidth: "500px" }}></img>
                  </div>

                </div>



              </div>
            </main>

          </div>

        </div>}


    </>

  )
}
