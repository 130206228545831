import React, { useState, useEffect, useContext } from 'react';
import UserService from '../../../services/UserService';
import Spinner from 'react-bootstrap/Spinner';
import format from 'date-fns/format';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import Dialog from './PlansDialog';

function PlansPanel() {

  const [annual, setAnnual] = useState(true);

  const [respuestacompesateco2, setRespuestacompesateco2] = useState("");
  const [plans, setPlans] = useState([]);
  const [userPlan, setUserPlan] = useState([]);
  const [t, i18n] = useTranslation("global");

  const [dialog, setDialog] = useState({
    message: "",
    isLoading: false,
    idProductStripe: ""

  });

  const handleDialog = (message, isLoading, idProductStripe) => {
    setDialog({
      message,
      isLoading,
      idProductStripe
    });
  };

  const handleSelectPlan = (idProductStripe) => {
    handleDialog(`${t("dash.stripe_continuarpago")}`, true, idProductStripe);
  };
  const areUSure = (choose) => {
    if (choose) {
      handleDialog("", false);
    } else {
      handleDialog("", false);
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PAYMENTS_PLANS_INFO}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${UserService.getToken()}`

      }
    }).then(j => j.json())
      .then(r => {

        setPlans(r);
        setUserPlan(r.filter((a) => { if (a.id == UserService.getPlan()) { return a } }));
      })
  }, [])





  function paySubscription(idProductStripe) {

    const dataPost = {
      'productoStripeID': idProductStripe,
      'userId': UserService.getUserId(),
      'customerId': UserService.getCustomerId()
    };

    fetch(`${process.env.REACT_APP_PAYMENTS_SUBSCRIBE}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${UserService.getToken()}`

      },
      body: JSON.stringify(dataPost)

    }).then((response) => {
      return response.json()
    })
      .then((respuestacompesateco2) => {
        window.location.href = respuestacompesateco2.url;
        if (!respuestacompesateco2.error) {
          //TODO:
        }
      })
  }


  return (
    <div className="sec-neutro-background grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">{t("account.planes")}</h2>
        <div id='galcance' className="s sec-green-background text-center  p-3 rounded-sm border border-gray-200">

          {userPlan.length > 0 ? <>{t("planes.planactual")} <span className="font-italic">{userPlan[0].name}</span> {t("planes.renovarplan")} {format(new Date(UserService.getPlanEnd()), 'dd/MM/yyyy')} </> : <>{t("planes.noplan")}</>}
        </div>
        {/* Plans */}
        <section>
          {/* Pricing */}
          <div>
            {/* Pricing tabs */}
            <div className="grid grid-cols-12 gap-6">
              {plans.length > 0 ? <>  {/* CURIOUS */}
                <div className="relative col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
                  <div className="absolute top-0 left-0 right-0 h-0.5 bg-green-500" aria-hidden="true"></div>
                  <div className="px-5 pt-5 pb-6 border-b border-gray-200">
                    <header className="flex justify-center mb-2">
                      <div className="w-6 h-6 rounded-full shrink-0 bg-gradient-to-tr from-green-500 to-green-300 mr-3">
                        <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                          <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                        </svg>
                      </div>
                      <h3 className="text-lg text-gray-800 font-semibold">{plans[0].name}</h3>
                    </header>
                    {/* Price */}
                    <div className="flex justify-center text-gray-800 font-bold mb-4">
                      <span className="text-2xl pt-1">€</span><span className="text-3xl">{plans[0].price}</span><span className="text-gray-500 font-medium text-sm pt-2">/{t("planes.año")}</span>
                    </div>
                    {/* CTA */}

                    <button arial-label="btn-subscription" className="btn bg-indigo-500 hover:bg-indigo-600 text-white w-full" onClick={() => { handleSelectPlan(plans[0].stripeId) }}>{UserService.getPlan() != "" || UserService.getPlan > 0 ? <>{t("planes.actualizar")}</> : <>{t("planes.contratar")}</>} {t("planes.ahora")}</button>
                  </div>
                  <div className="px-5 pt-4 pb-5">
                    {/* List */}
                    <ul>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.calculadora")}</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.compensacion")} (-70% {t("dash.mercado")})</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.miteco")}</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.iso")} (+2.500 €)</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.sello")} <b className="text-gray-400">Verified Emissions</b> <b className="text-indigo-500">airCO2</b></div>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* GO GREEN */}
                <div className="relative col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
                  <div className="absolute top-0 left-0 right-0 h-0.5 bg-light-blue-500" aria-hidden="true"></div>
                  <div className="px-5 pt-5 pb-6 border-b border-gray-200">
                    <header className="flex justify-center mb-2">
                      <div className="w-6 h-6 rounded-full shrink-0 bg-gradient-to-tr from-light-blue-500 to-light-blue-300 mr-3">
                        <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                          <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                        </svg>
                      </div>
                      <h3 className="text-lg text-gray-800 font-semibold">{plans[1].name}</h3>
                    </header>
                    {/* Price */}
                    <div className="flex justify-center text-gray-800 font-bold mb-4">
                      <span className="text-2xl pt-1">€</span><span className="text-3xl">{plans[1].price}</span><span className="text-gray-500 font-medium text-sm pt-2">/{t("planes.año")}</span>
                    </div>
                    {/* CTA */}
                    <button arial-label="btn-subscription" className="btn bg-indigo-500 hover:bg-indigo-600 text-white w-full" onClick={() => { handleSelectPlan(plans[1].stripeId) }}>{UserService.getPlan() != "" || UserService.getPlan > 0 ? <>{t("planes.actualizar")}</> : <>{t("planes.contratar")}</>} {t("planes.ahora")}</button>
                  </div>
                  <div className="px-5 pt-4 pb-5">
                    {/* List */}
                    <ul>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.calculadora")}</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.compensacion")} (-80% {t("dash.mercado")})</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.selloministerio")}</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.iso")} (+2000 €)</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.sello")} <b className="text-green-500">Net-Zero</b> <b className="text-indigo-500">airCO2</b></div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.redflags")}</div>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* PRO ZERO */}
                <div className="relative col-span-full xl:col-span-4 bg-white shadow-md rounded-sm border border-gray-200">
                  <div className="absolute top-0 left-0 right-0 h-0.5 bg-indigo-500" aria-hidden="true"></div>
                  <div className="px-5 pt-5 pb-6 border-b border-gray-200">
                    <header className="flex justify-center mb-2">
                      <div className="w-6 h-6 rounded-full shrink-0 bg-gradient-to-tr from-indigo-500 to-indigo-300 mr-3">
                        <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                          <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                        </svg>
                      </div>
                      <h3 className="text-lg text-gray-800 font-semibold">{plans[2].name}</h3>
                    </header>
                    {/* Price */}
                    <div className="flex justify-center text-gray-800 font-bold mb-4">
                      <span className="text-2xl pt-1">€</span><span className="text-3xl"> {plans[2].price} </span><span className="text-gray-500 font-medium text-sm pt-2">/{t("planes.año")}</span>
                    </div>
                    {/* CTA */}
                    <button arial-label="btn-subscription" className="btn bg-indigo-500 hover:bg-indigo-600 text-white w-full" onClick={() => { handleSelectPlan(plans[2].stripeId) }}>{UserService.getPlan() != "" || UserService.getPlan > 0 ? <>{t("planes.actualizar")}</> : <>{t("planes.contratar")}</>} {t("planes.ahora")}</button>
                  </div>
                  <div className="px-5 pt-4 pb-5">
                    {/* List */}
                    <ul>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.calculadora")}</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.compensacion")} (-85% {t("dash.mercado")})</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.selloministerio")}</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.iso")} (+2000 €)</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.sello")} <b className="text-green-500">Net-Zero</b> <b className="text-indigo-500">airCO2</b></div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.redflags")}</div>
                      </li>
                      <li className="flex items-center py-1">
                        <svg className="w-3 h-3 shrink-0 fill-current text-green-500 mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <div className="text-sm">{t("planes.proveedores")}</div>
                      </li>
                    </ul>
                  </div>
                </div></> : <>       <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true" /></>}


            </div>
          </div>
        </section>
        {dialog.isLoading && (<Dialog

          onDialog={areUSure}
          message={dialog.message}
          idProductStripe={dialog.idProductStripe}

        />)}

      </div>
    </div>
  );
}

export default PlansPanel;