import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Banner from '../../../components/utils/Banner';
import { IdiomContext } from "../../../context/createcontext";
import UserService from '../../../services/UserService';
import Sidebar from '../Sidebar';
import Header from '../Header';
import ImagesTable from './ImagesTable';
import Dropzone from '../utils/Dropzone';

export default function ImagesMP() {

  const location = useLocation();
  const data = location.state;

  
  //Ver si undefined o null
  if(data == undefined){
    window.location.href = "/forest/marketplace";
  }

  const urlDropzone = `${process.env.REACT_APP_MP_PRIVATE}/product/${data?.id}/images`;
  const maxSize = 1048576;

  const urlParams = new URLSearchParams(window.location.search);
  const dataString = urlParams.get('origen');

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [images, setImages] = useState(dataString== "form" ? [] : data.productImageDtoList);
  const [btnModify, setBtnModify] = useState(false);

  const [formMontesMessage, setFormMontesMessage] = useState({
    type: '',
    message: ''
  });

  const [productResponse, setProductResponse] = useState({
    productResponseData: [],
    isFetching: false
  });


  if (data == undefined) {
    data = props.state
  }

const actualizar = (newImages) => {

  setImages([...images, ...newImages])
}

const actulizarAtributosImg = (imagesChanged) => {
  setImages(imagesChanged)
  if(imagesChanged != images){
    setBtnModify(true)
  }
}

  const handlePostProduct = (e) => {
    e.preventDefault();

    let product = data;
    product.productImageDtoList = images;
    // product.translations = [{
    //   "summary": data.summary,
    //   "description": data.description,
    //   "languageId": 1
    // }]
    delete product["summary"];
    delete product["description"];
    delete product["translations"];


  setFormMontesMessage({ ...formMontesMessage, type: "warning", message: t("marketplace.imgMP_updating") });
  document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });

  const fetchProduct = async () => {
    try {
      setProductResponse({ productResponse: productResponse.productResponseData, isFetching: true });
      const response = await axios.post(`${process.env.REACT_APP_MP_PRIVATE_PRODUCT}`, product, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${UserService.getToken()}`
        }
      }).then(response => {
        if (response.status === 200) {
          setFormMontesMessage({ ...formMontesMessage, type: "success", message: t("marketplace.imgMP_updateOK") });
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        } else {
          setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("marketplace.imgMP_updateKO") })
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        }
        setProductResponse({ productResponseData: response.data, isFetching: false });
      })
    } catch (exception) {
      console.error(exception);
      setProductResponse({ productResponse: productResponse.productResponseData, isFetching: false });
      setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  fetchProduct();
}


  return (
      <div className=" sec-neutro-background flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content area */}
        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
           {/* Content */}
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <div className="mb-2 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{dataString=="mp" ? t("marketplace.subeEditaImg") : t("marketplace.subeImg")}</h1>
              </div>
              <div className='flex ml-4 mt-4'>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="32" height="32" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" fill="none">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                  <line x1="15" y1="21" x2="15" y2="18" />
                  <path d="M8 13l-2 -2" />
                  <path d="M8 12l2 -2" />
                  <path d="M8 21v-13" />
                  <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                </svg>
                <h5 className='h5 block h4 underline ml-2 font-semibold'>{dataString=="mp"? data.name : data.name}</h5> 
              </div>
              
              <div className=" px-4 pb-4" style={{ width: '100%'}}>
              <div className='flex flex-row mb-4 '>
                { dataString=="mp"?
                <div className="overflow-x-auto w-full">
                  <div className='flex justify-center'>
                  {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>} 
                  </div>            
                  <ImagesTable img={images} actulizarAtributosImg={actulizarAtributosImg}/>
                </div>
                :
                <div className="overflow-x-auto w-full">
                  <div className='flex justify-center'>
                  {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>} 
                  </div>            
                    <ImagesTable img={images} actulizarAtributosImg={actulizarAtributosImg}/>
                </div>
                }
              </div>
                {btnModify  ?
                <div className='flex justify-end mb-4'>
                  <button onClick={handlePostProduct} className='btn-sm btn-green'>
                    {t("marketplace.imgMP_saveChanges")}
                  </button>
                </div>
                : ""
                }
                <Dropzone actualizar={actualizar} url={urlDropzone} validExtension={['image/jpeg', 'image/jpg', 'image/png']} size={maxSize} />

            </div>
          </div>
        </main>
      </div>
    </div>
  );
}