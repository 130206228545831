import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext, useState, useEffect } from 'react'
import valladolid from '../../../images/Valladolid.webp'
import porrino from '../../../images/Porrino.webp'
import salvaterra from '../../../images/Salvaterra.webp'

export default function BloqueCasos() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleImages, setVisibleImages] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [pics, setPics] = useState(3);
    const [intervalId, setIntervalId] = useState(null);
    const [isMouseOverImage, setIsMouseOverImage] = useState(false);
    let data = [
        {
            url: "",
            pic: porrino,
        },

        {
            url: "",
            pic: valladolid,
        },

        {
            url: "",
            pic: salvaterra,
        },
    ];

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (width <= 960) {
            setPics(1);
        } else {
            setPics(3);
        }
    }, [width]);

    const handleMouseEnter = () => {
        setIsMouseOverImage(true);
      };
      
      const handleMouseLeave = () => {
        setIsMouseOverImage(false);
      };


    useEffect(() => {
        const interval = setInterval(() => {
          if (!isMouseOverImage) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
          }
        }, 2000);
      
       
        setIntervalId(interval);
      
        return () => clearInterval(interval);
      }, [isMouseOverImage]);

    useEffect(() => {
        const endIndex = (currentIndex + pics - 1) % data.length;
        let imagesToShow = [];

        if (currentIndex <= endIndex) {
            imagesToShow = data.slice(currentIndex, endIndex + 1);
        } else {
            imagesToShow = [
                ...data.slice(currentIndex),
                ...data.slice(0, endIndex + 1),
            ];
        }
        setVisibleImages(imagesToShow);
    }, [currentIndex, data.length]);

  
    return (
        <div className='pb-4 pt-4 mt-5 mb-4'>
            <div>
                <p className='container pt-4 text-center text-2xl text-airco2-titles-bloques'>{(t("landingConcellos.casos"))}<span className='font-bold'>{(t("landingConcellos.exito"))}</span> </p>

            </div>


            <div className="py-12 lg:py-20">
                {/* Section header */}

                <div style={{
              
                    overflow: 'hidden',
                  
                }}>

                    <div
                        className="flex items-center justify-center"
                        data-aos="fade-up"
                        data-aos-delay="200"
                        style={{
                            maxWidth: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        {visibleImages.map((x, y) => (
                            <div
                                key={y}
                                className={`cardConcello p-5 mx-3 transform hover:scale-105 transition duration-700 ease-out`}
                                style={{
                                    backgroundImage: `url(${x.pic})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    flex: '0 0 auto',

                                }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            ></div>
                          
                        ))}
                    </div>

                </div>

            </div>





        </div>
    )
}
