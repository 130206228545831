import React, { useState, useContext } from "react";
import format from "date-fns/format";
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from "react-i18next";
import Translations from "../../../services/Translations";
import { formatThousands } from "../utils/Utils";

import { categoryIcono } from "../../../services/Functions";

function EmissionsTableItem(props) {
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  // const gasBgColor = "bg-indigo-500";
  // const luzBgColor = "bg-yellow-500";
  // const viajesBgColor = "bg-light-blue-500";

  // const categoryIcon = (idcategory, category) => {
  //   let categoryImage = "";
  //   // let categoryBgColor = "";
  //   switch (category) {
  //     case "Gastos hogar":
  //       categoryImage = compras;
  //       // categoryBgColor = combustibleBgColor;
  //       break;
  //     case "sin categoría":
  //       categoryImage = electricidad;
  //       // categoryBgColor = gasBgColor;
  //       break;
  //     case "Automovil y transporte":
  //       categoryImage = transporte;
  //       // categoryBgColor = luzBgColor;
  //       break;
  //     case "nóminas":
  //       categoryImage = viajes;
  //       //   categoryBgColor = viajesBgColor;
  //       break;
  //     case "ocio":
  //       categoryImage = ocio;
  //       //   categoryBgColor = viajesBgColor;
  //       break;
  //     default:
  //       categoryImage = otros;
  //       // categoryBgColor = "bg-red-100";
  //       break;

  //   }

  //   return (
  //     <div className={`w-10 h-10 shrink-0 flex items-center justify-center rounded-full mr-2 sm:mr-3`}>
  //       <img src={categoryImage} width="20" height="20" alt={category} />
  //     </div>

  //   );
  // };

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const name = props.category;
  const nameSub = props.subcategory;

  const nameCategory = Translations.translateCategories(name);
  const nameSubcategory = Translations.translateCategories(nameSub);

  return (
    <tbody className="text-sm">
      {/* Row */}
      <tr>
        {/* CHECK BOX
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input id={props.id} className="form-checkbox" type="checkbox" onChange={props.handleClick} checked={props.isChecked} />
            </label>
          </div>
        </td> */}

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center text-gray-800 ">
            {categoryIcono(props.category)}

            <div className="font-medium text-gray-800">{t(nameCategory)}</div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5`}
          >
            {t(nameSubcategory)}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div>{format(new Date(props.accountingDate), "dd/MM/yyyy")}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-right">{formatThousands(props.co2 / 1000)}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-right">{formatThousands(props.ch4 / 1000)}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-right">{formatThousands(props.n2o / 1000)}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-right">{formatThousands(props.h2o)}</div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"></td>
      </tr>
    </tbody>
  );
}

export default EmissionsTableItem;
