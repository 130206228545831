import { useState, useEffect } from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import FootprintUserList from "./FootprintUserList";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import axios from "axios";
import Select from 'react-select';
import UserService from "../../../../services/UserService";
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { event } from "react-ga";


function FootprintUsersAdmin() {

  const navigate = useNavigate();

  return (

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto sec-neutro-background">
            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
              <div className="flex flex-col xxl:flex-row xxl:-mr-px p-4">
                {/* Group 1 */}
                <div>
                  <FootprintUserList></FootprintUserList>
                </div>
              </div>
            </div>
          </div>
        </main>
  );
}
export default FootprintUsersAdmin;
