
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import UserService from './../../services/UserService';

const RenderOnMonte = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (!UserService.isLoggedIn()) {
      const query = "?originUrl=" + window.location.pathname;
      UserService.doLogin(query);
    } else {
      setIsAuthenticated(true);
    }
  }, []);

  if (!isAuthenticated) {
    return null; 
  }

  if (UserService.getUserType().toLowerCase() === 'representante empresarial') {
    return <Navigate to="/company/dashboardintranet" replace={true} />;
  }

  if (UserService.getUserType() === undefined) {
    return <Navigate to="/adminusuarios" replace={true} />;
  }

  return children;
};

export default RenderOnMonte;
