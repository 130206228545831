import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Col, Modal, Row, Form } from 'react-bootstrap';
import UserService from '../../../services/UserService';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import Translations from '../../../services/Translations';
import { formatThousands } from '../utils/Utils';



export default function MisCertificaciones() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const [showInstructions, setShowInstructions] = useState(false);

    const [certifications, setCertifications] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fectCertifications = async () => {
            setCertifications({ data: certifications.data, isFetching: true });
            try {

                const response = await axios.get(`${process.env.REACT_APP_CH_CERTIFICATIONS}?userId=${UserService.getUserId()}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setCertifications({ data: response.data, isFetching: false });

            } catch (exception) {
                setCertifications({ data: [], isFetching: false });
            }
        };
        fectCertifications();
    }, [])


    const statusColor = (props) => {
        switch (props) {
            case "CREATED":
                return "#b4e1ef";
            case "DOCUMENTATION":
                return "#b4e1ef";
            case "COMPLETED":
                return "#97cd9f";
            case "SENT":
                return "#cfbd5e";
            case "ACEPTED":
                return "#e3b199";
        }
    }

    const InstructionsText = () => {
        return <>
            <div className="mb-6">
                <p className="mb-2">{t("certificaciones.instrucciones.line1")}</p>
                <ol style={{ listStyle: "decimal", paddingLeft: "2rem", lineHeight: "2" }}>
                    <li><span dangerouslySetInnerHTML={{ __html: t("certificaciones.instrucciones.line2") }}></span></li>
                    <li><span dangerouslySetInnerHTML={{ __html: t("certificaciones.instrucciones.line3") }}></span></li>
                    <li><span dangerouslySetInnerHTML={{ __html: t("certificaciones.instrucciones.line4") }}></span></li>
                    <li><span dangerouslySetInnerHTML={{ __html: t("certificaciones.instrucciones.line5") }}></span></li>
                </ol>
            </div>
        </>
    }

    return (

        <div className={`sec-neutro-background flex h-screen overflow-hidden`}>

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                    <Modal show={showInstructions} size={"lg"}>
                        <Modal.Header className="sec-green-background">
                            <Modal.Title className="text-white">
                                {t("certificaciones.instrucciones.instruction")}
                            </Modal.Title>
                            <div className="flex justify-end">
                                <button onClick={() => setShowInstructions(false)}>
                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                </button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <h2 className="text-2xl text-center text-gray-800 dark:text-gray-100 font-bold mb-2">
                                {t("certificaciones.instrucciones.title")}
                            </h2>
                            <InstructionsText></InstructionsText>
                        </Modal.Body>
                    </Modal>

                        {/* Page header */}
                        <div className="mb-8">
                            {/* Title */}
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("certificaciones.misCertificaciones")}</h1>

                            <div className='flex justify-end gap-2'>
                                {certifications.isFetching || (certifications?.data?.data?.length !== undefined && certifications?.data?.data?.length > 0)  &&
                                <button onClick={() => setShowInstructions(true)} className='flex items-center btn-banner-o mr-4 rounded-lg px-4 py-2' >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-square-rounded mr-2" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M12 9h.01" />
                                        <path d="M11 12h1v4h1" />
                                        <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                                    </svg>
                                    {t("certificaciones.instrucciones.instruction")}
                                </button>}
                                <button className='btn-green rounded-lg px-4 py-2' onClick={() => navigate('/footprint/certifications/miteco')} >
                                {t("certificaciones.nueva")}
                                </button>
                            </div>
                        </div>


                        {certifications.isFetching ? (
                            <div className='flex justify-center'>
                                <Spinner className='mt-4' animation="border" variant="dark" />
                            </div>

                        ) : (
                            (certifications?.data?.data?.length !== undefined && certifications?.data?.data?.length !== 0) ? 
                                <div className="container-simple-table w-full">
                                    <table className="simple-table">
                                        <thead className='text-center  text-xl sec-green-background text-white'>
                                            <tr className='py-4'>
                                                <th>
                                                {t("certificaciones.tipoCertificacion")}
                                                </th>
                                                <th>
                                                    {t("footPrint.organizacion")}
                                                </th>
                                                <th>
                                                    {t("footPrint.year")}
                                                </th>
                                                <th>
                                                    {t("footPrint.estado")}
                                                </th>
                                                <th>
                                                    {t("footPrint.tipoCalculo")}
                                                </th>
                                                <th className='text-end pr-5' />
                                            </tr>
                                        </thead>
                                        <tbody className='text-center'>
                                            {certifications.data.length > 0 ? <tr className='flex justify-center'> <Spinner animation="border" variant="dark" /> </tr> :
                                                certifications.data.data != undefined && certifications.data.data.map((certification) => {

                                                    return (
                                                        <tr key={certification.id}>
                                                            <td>{certification.certificationType}</td>
                                                            <td>{certification.organizationName}</td>
                                                            <td>{certification.calculationYear}</td>
                                                            <td>
                                                                <div className='rounded-full' style={{ backgroundColor: statusColor(certification.certificationMitecoStatus) }}>
                                                                    {Translations.transalteStatusCertifications(certification.certificationMitecoStatus)}
                                                                </div>
                                                            </td>
                                                            <td>{certification.questionnaireName}</td>
                                                            <td>
                                                                <span className="airco2-a-tag" onClick={()=>navigate(`/footprint/certification/${certification.id}`)}>{t("certificaciones.verCertificacion")}</span>
                                                                </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                             :                                
                                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                    <div className="max-w-2xl m-auto mt-16">
                                        <div className="text-center px-4">
                                            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 dark:from-gray-700 dark:to-gray-800 mb-4">
                
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-seeding" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" stroke-dasharray="40" stroke-dashoffset="40">
                                                        <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" />
                                                    </path>
                                                    <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" stroke-dasharray="40" stroke-dashoffset="40">
                                                        <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" begin="3s" />
                                                    </path>
                                                    <path d="M12 20l0 -10" stroke-dasharray="10" stroke-dashoffset="10">
                                                        <animate attributeName="stroke-dashoffset" from="10" to="0" dur="3s" repeatCount="indefinite" begin="5s" />
                                                    </path>
                                                </svg>
                                            </div>
                                            <h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-2">{t("certificaciones.instrucciones.title")}</h2>
                                            <div className="mb-6">
                                                <p>{t("certificaciones.sinCertificaciones1")}</p>
                                                <p>{t("certificaciones.sinCertificaciones2")}</p>     
                                                <InstructionsText></InstructionsText>                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </main>
            </div>
        </div>
    )
}