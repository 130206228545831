import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import { IdiomContext } from "../../../../../context/createcontext"
import electrical from '../../../../../images/electricity.svg'
import { formatThousands } from '../../../utils/Utils'

export default function Electrical({ highLights, formatNumber }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");


    const [electricalConsumption, setElectricalConsumption] = useState(undefined);
    const [kgElectrical, setKgElectrical] = useState(undefined);

    useEffect(() => {
        setElectricalConsumption(highLights?.data?.electricalConsumption);
        setKgElectrical(formatNumber(highLights?.data?.electricalConsumption?.kgCO2e));
    }, [highLights])

    return (
        <div className='grid-report-hightlights rounded-sm border border-gray-200 px-2 py-3'>

            <div className='flex flex-col text-center items-center justify-center'>
                <img src={electrical} className=" flex flex-col items-center" />
            </div>

            <div className='flex flex-col text-center items-center justify-center text-sm'>
                {t("footPrint.electricidad")}
                {highLights.isFetching && electricalConsumption &&
                    <div className='text-center mt-1 text-md font-bold text-base'>
                        {electricalConsumption.name}
                    </div>
                }
            </div>

            <div className='flex flex-col  w-full   justify-center'>

                {!highLights.isFetching && <div className='text-center'>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" />
                </div>}


                {highLights.isFetching && <>


                    {(kgElectrical && electricalConsumption) &&
                        <table>
                            <tbody>
                                <tr>
                                    <td className='font-bold text-xl text-right '>{electricalConsumption.quantity !== null ? formatThousands(electricalConsumption.quantity) : ""}</td>
                                    <td className='text-xs text-left pl-2'>kW</td>
                                </tr>
                                <tr>
                                    <td className='font-bold text-xl text-right '>{formatThousands(kgElectrical)}</td>
                                    <td className='text-xs text-left pl-2' >kgCO<sub>2</sub>e</td>
                                </tr>

                            </tbody>
                        </table>
                        // <div className='text-center w-full grid grid-cols-2 gap-x-4'>
                        //     <div className='flex flex-row items-end'>
                        //         <span className='font-bold text-xl'>{formatThousands(kgElectrical)}</span>
                        //         <span className='text-xs' >kgCO<sub>2</sub>e</span>
                        //     </div>
                        //     <div className='flex flex-row items-end'>
                        //         <span className='font-bold text-xl'>{electricalConsumption.quantity !== null ? formatThousands(electricalConsumption.quantity) : ""}</span>
                        //         <span className='text-xs'>kW</span>
                        //     </div>
                        // </div>
                    }

                    {(kgElectrical === undefined && electricalConsumption === undefined) &&
                        <div className='text-center w-full flex flex-col md:flex-row justify-evenly text-md'>
                            <div className='text-xs'> {t("footPrint.sinDatoElectrica")}</div>
                        </div>
                    }
                </>

                }





            </div>

        </div>

    )
}