import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import UserService from '../../../services/UserService';
import { formatThousands } from '../utils/Utils';
import  MitecoStepMenu from './MitecoStepMenu';
import CreateCertification from './CreateCertification';
import Documentation from './UploadDocumentationMiteco';
import { Spinner } from 'react-bootstrap';
import SentDocumentation from './MitecoSentDocumentation';

export default function CertificationComponent() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const pathParams = useParams();
    const [idCertification, setIdCertification] = useState(pathParams.id);

    const [certificationStep, setCertificationStep] = useState(null);
    const [certificationDetail, setCertificationDetail] = useState({
        data: [],
        isFetching: false
    });

    const location = useLocation();
    const idUserAdmin = location?.state?.userId;
    const userEmail = location?.state?.userName;


    useEffect(() => {
        if (idCertification != undefined && idCertification == "new") {
            setCertificationStep(1)
        } else if (idCertification != undefined) {
            fetchCertificationDetail();
        }
    }, [idCertification])


    const getCertificationStepByStatus = (props) => {
        switch (props) {
            case "CREATED":
                return 3;
            case "SENT":
                return 4;
            case "ACCEPTED":
                return 4;
            case "COMPLETED":
                return 4;
        }
    }


    const fetchCertificationDetail = async () => {
        setCertificationDetail({ data: certificationDetail.data, isFetching: true });
        try {

            const response = await axios.get(`${process.env.REACT_APP_CH_CERTIFICATION}/${idCertification}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            let objeto = response.data;
            setCertificationDetail({ data: objeto, isFetching: false });
            setCertificationStep(getCertificationStepByStatus(objeto.data.certificationMitecoStatus));

        } catch (exception) {
            console.error(exception);
            setCertificationDetail({ data: [], isFetching: false });
        }
    };


    const changeStep = (props) => {
        setCertificationStep(props);
    }

    const getNewCertification = (props) => {
        setIdCertification(props.id)
    }

    const getCertificationContentByStep = (props) => {
        switch (props) {
            case 1:
            case 2:
                return <CreateCertification
                    certificationStep={certificationStep}
                    changeStep={changeStep}
                    getNewCertification={getNewCertification} 
                    userId={UserService.hasRole(["admin"]) ? idUserAdmin : UserService.getUserId()}/>;
            case 3:
                return <Documentation
                    certificationDetailData={certificationDetail.data} />;
            case 4:
                return <SentDocumentation 
                certificationStep={certificationStep} 
                certificationDetailData={certificationDetail.data} />
        }
    }

    return (

        <div className={`sec-neutro-background flex h-screen overflow-hidden`}>

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className='flex justify-between'>
                    <div>
                        <button arial-label="btn-saveFom" className="ml-4 mt-2 text-base font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                            </svg>
                            <span>{t("footPrint.volver")}</span>
                        </button>
                    </div>
                    {UserService.hasRole(["admin"]) &&  <span className='mr-2 mt-1 text-sm'>Usuario: <i>{userEmail}</i></span>}
                </div>
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="mb-8">
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Registro de la huella de carbono del MITECO <span className='text-xl md:text-2xl font-semibold'>{certificationDetail.data.data != undefined && ( " - " +certificationDetail.data?.data?.organizationName + " " + certificationDetail.data?.data?.calculationYear) }</span></h1>
                        </div>
                        {certificationStep == null ?
                            <div className='flex justify-center'><Spinner animation="border" variant="dark" /></div> 
                            :
                            <>
                                <MitecoStepMenu certificationStep={certificationStep}/>
                                
                                {getCertificationContentByStep(certificationStep)}
                                
                            </>
                        }
                    </div>
                </main>
            </div>
        </div>
    )
}