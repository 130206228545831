
import axios from 'axios';
import { default as React, useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import Banner from '../../../../../components/utils/Banner'
import { IdiomContext } from "../../../../../context/createcontext";
import { formatDate, formatThousands } from '../../../utils/Utils';
import DocumentsListTable from "../../../subirDoc/DocumentsListTable";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function AnswerTable({
    idExternal,
    questions,
    idQuestion,
    titleQuestion,
    idContact,
    currentIndexQuestion,
    answerTable,
    setQuestions
}) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [currentInputIndex, setCurrentInputIndex] = useState(0);
    const [t, i18n] = useTranslation("global");

    const [dataAnswers, setDataAnswers] = useState({
        data: [],
        isFetching: false
    });

    const [modalDocument, setModalDocument] = useState({ view: false, item: {} });
    const [uploadSpinner, setUploadSpiner] = useState(false)
    const [file, setFile] = useState(null);
    const [fileMsg, setFileMsg] = useState({ state: "", text: "" })
    const [modalConfirmDelete, setModalConfirmDelete] = useState({ view: false, id: "", indexTable: 0 });
    const [deletedAnswer, setDeletedAnswer] = useState({
        data: [],
        isFetching: false
    });
    const [deleteSpinner, setDeleteSpinner] = useState({ status: false, index: -1 })


    const handleUploadFileAnswer = async (e, idExternalCalculationAnswer, item) => {
        setUploadSpiner(true);
        e.preventDefault();
        let formData = new FormData();
        formData.append('files', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternal}/documentation?idExternalQuestionAnswer=${idExternalCalculationAnswer}`,
                formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            item.calculationDocumentation = response.data.data;
            modalDocument.item.calculationDocumentation = response.data.data[0];
            setFileMsg({ state: "success", text: t("exceltransactions.fileUpload") });
            setFile(null);

        } catch (exception) {
            console.error(exception);
        }
        setUploadSpiner(false);
    }



    const handleFileChange = (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];

        const maxFileSize = process.env.REACT_APP_CH_CALCULATION;
        if (selectedFile && selectedFile.size > maxFileSize) {
            setFileMsg({ state: "warning", text: t("footPrint.archivoGrande") });
        } else {
            setFile(selectedFile);
            setFileMsg({ state: "", text: "" });
        }

        const file = e.target.files[0];
        const fileName = file.name;
        let fileOk = true;
        let errorMsg = "";
        if (file) {

            if (/\s/.test(fileName) || /[^\w.-]/.test(fileName)) {
                fileOk = false;
                errorMsg = t("marketplace.errImgNombre")
            }

            const extension = fileName.split(".").pop();
            if (extension !== extension.toLowerCase()) {
                fileOk = false;
                errorMsg = t("marketplace.errImgExtensionMayusculas");
            }

            const fileExtension = fileName.split('.').pop().toLowerCase();
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
            if (!allowedExtensions.includes(fileExtension)) {
                fileOk = false;
                errorMsg = t("footPrint.logo-extension");
            }

            if (fileOk) {
                setFile(e.target.files[0]);
                setFileMsg({ state: "", text: "" });
            } else {
                setFileMsg({ state: "warning", text: errorMsg });
                console.error('Error: ' + errorMsg);
                e.target.value = null;
                // setuploadLogoValue([])
            }
        }
    };

    const cleanMessage = () => {
        setFileMsg({ state: "", text: "" });
    }

    const fetchDataAnswers = async () => {
        setDataAnswers({ data: dataAnswers.data, isFetching: true });
        try {
            if (idQuestion) {
                await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternal}/answers/${idQuestion}?contactId=${idContact}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }).then(response => {
                    if (response.status === 200) {
                        const newData = response.data.data.map(item => ({
                            ...item,
                            answerBodyOutputDtos: item.answerBodyOutputDtos.map(doc => ({
                                ...doc,
                                calculationDocumentation: doc.calculationDocumentation ? {
                                    id: doc.calculationDocumentation.id,
                                    name: doc.calculationDocumentation.name,
                                    creationDate: doc.calculationDocumentation.creationDate,
                                    calculationId: doc.calculationDocumentation.calculationId
                                } : undefined
                            }))
                        }));
                        setDataAnswers({ data: { data: newData }, isFetching: false });

                    }
                });


            }
        } catch (exception) {
            debugger;
            console.error(exception)
            setDataAnswers({ data: [], isFetching: false });
        }
    };

    useEffect(() => {
        if (idContact) {
            fetchDataAnswers();
        }
    }, [questions, idQuestion, idContact, answerTable])


    const handleDelete = (idCalcAnswer, indexTable) => {

        const originalData = dataAnswers.data.data[indexTable]; // Guardar la referencia al objeto original
        const filteredAnswerBodyOutputDtos = originalData.answerBodyOutputDtos.filter((item) => item.id !== idCalcAnswer);
        const updatedData = {
            ...originalData, // Conservar las otras variables intactas
            answerBodyOutputDtos: filteredAnswerBodyOutputDtos, // Actualizar solo answerBodyOutputDtos
        };

        let newArray = dataAnswers.data.data[indexTable].answerBodyOutputDtos.filter((item) => item.id != idCalcAnswer);
        const fetchDeleteAnswer = async () => {
            setDeleteSpinner({ status: true, index: idCalcAnswer })
            const url = `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternal}/answer/${idCalcAnswer}?contactId=${idContact}`
            try {
                setDeletedAnswer({ deletedAnswer: deletedAnswer.data, isFetching: true });
                const response = await axios.delete(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                setDeletedAnswer({ data: response.data, isFetching: false });
                if (response.data.success) {
                    const updatedArray = [...dataAnswers.data.data]; // Clonar el array
                    updatedArray[indexTable] = updatedData; // Actualizar el objeto en el índice correspondiente
                    // if (updatedArray[0]?.answerBodyOutputDtos.length == 0) {
                    //     // setNoAnswer()
                    // }
                    const updatedQuestions = [...questions];
                    updatedQuestions[currentIndexQuestion].answersByContact -= 1;

                    setQuestions(updatedQuestions)
                    setDataAnswers({ data: { data: updatedArray }, isFetching: false });
                }

            } catch (exception) {
                setDeletedAnswer({ data: [], isFetching: false });
            }
            setDeleteSpinner({ status: false, index: -1 })
        };
        setModalConfirmDelete({ view: false, id: "", indexTable: 0 })
        fetchDeleteAnswer();
    }

    const setDocumentList = (item, documentList) => {
        item.calculationDocumentation = documentList;
        modalDocument.item.calculationDocumentation = documentList[0];
    }

    const renderTooltipValidated = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.userValidated")}
        </Tooltip>
    );

    return (
        <div>
            <Modal show={modalConfirmDelete.view}>
                <ModalHeader className='sec-green-background text-white text-lg'>
                    {t("footPrint.eliminar")}
                </ModalHeader>
                <ModalBody>
                    <p className="text-center"> {t("footPrint.eliminarSeguro")}</p>
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className="btn-sm btn-cancel" onClick={() => setModalConfirmDelete({ view: false, id: "", indexTable: 0 })}> {t("footPrint.cancelar")}</button>
                    <button className="btn-sm btn-green" onClick={() => handleDelete(modalConfirmDelete.id, modalConfirmDelete.indexTable)}> {t("footPrint.eliminar")}</button>
                </div>
            </Modal>

            <Modal show={modalDocument.view} size="lg">
                <ModalHeader className='sec-green-background text-white text-lg'>
                    {t("forestList.NombreDoc")}
                </ModalHeader>
                <ModalBody>

                    <div className="mb-4 flex justify-between items-center gap-1">
                        {modalDocument.item?.questionSubtitle !== undefined && <div>{modalDocument.item?.questionSubtitle}</div>}
                        <div>{modalDocument.item?.organizationBuildingName !== undefined ? modalDocument.item?.organizationBuildingName : ""}</div>
                        <div>{modalDocument.item?.factorName}</div>
                        <div>{modalDocument.item?.quantity && formatThousands(modalDocument.item?.quantity)}</div>
                    </div>

                    {fileMsg.state !== "" && <div className="mb-4"> <Banner type={fileMsg.state} setOpen={true} open={fileMsg.state !== "" ? "open" : ""}>
                        {fileMsg.text}</Banner>
                    </div>}

                    {modalDocument.item?.calculationDocumentation != undefined && <>
                        <DocumentsListTable
                            documents={[{ ...modalDocument.item.calculationDocumentation, externalId: modalDocument.item.id, contactId: idContact }]}
                            enableDelete={false}
                            idCalculation={idExternal}
                            handleUpdateDocuments={setDocumentList}
                            idCuestionAnswer={modalDocument.item}
                            getFileUrl={"external"}
                        /></>}

                    {modalDocument.item?.calculationDocumentation === undefined &&
                        <form className=" flex justify-center items-center"
                            onSubmit={(e) => handleUploadFileAnswer(e, modalDocument.item.id, modalDocument.item)}>
                            <input className=''
                                type="file" id="subir_archivo"
                                required
                                onChange={(e) => { cleanMessage(); handleFileChange(e); }}
                                title={t("footPrint.seleccionaArchivo")} />
                            {file && <button className="btn-sm btn-banner-o " type="submit" disabled={uploadSpinner ? "disabled" : ""}>
                                <div className="mr-1">
                                    {!uploadSpinner && <svg xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-file-upload"
                                        width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                        <path d="M12 11v6" />
                                        <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
                                    </svg>}
                                    {uploadSpinner && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" />}
                                </div>
                                {t("lectorfacturas.subirArchivos")}</button>}
                        </form>}
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className="btn-sm btn-cancel" onClick={() => {
                        setModalDocument({ view: false, item: {} });
                        setFileMsg({ state: "", text: "" });
                    }}> {t("footPrint.cerrar")}</button>
                </div>
            </Modal >

            {dataAnswers.isFetching ? (
                <tr className='flex justify-center'>
                    <Spinner animation="border" variant="dark" />
                </tr>
            ) : (
                dataAnswers.data && dataAnswers.data.data ? (
                    dataAnswers.data.data.length > 0 ? (
                        dataAnswers.data.data.map((question, y) => (
                            <div key={y}>
                                <p className='font-bold'>
                                    {idQuestion == 6 ? t(`footPrint.EVENT_${question.questionType}`) : t(`footPrint.${question.questionType}`)}
                                </p>
                                <div className="container-simple-table mt-2 mb-4">
                                    <table key={y} className="simple-table">
                                        <thead className="text-center text-white sec-green-background">
                                            <tr>
                                                {Object.keys(question.answerHeaderOutputDto).map((key) => (
                                                    <th key={key} className="px-4 py-2" style={{ overflowWrap: 'break-word' }}>
                                                        {question.answerHeaderOutputDto[key]}
                                                    </th>
                                                ))}
                                                <th className="px-4 py-2">{t("footPrint.eliminar")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {question.answerBodyOutputDtos.map((answer, z) => (
                                                <tr key={z}
                                                    className={`${modalDocument?.view && modalDocument?.item?.id == answer.id ? "sec-green-background-tr" : ''}`}>


                                                    {Object.keys(question.answerHeaderOutputDto).map((key) => (
                                                        <td key={key} className="px-4 py-2" >
                                                            {(key === "calculationDocumentation"
                                                                ? (
                                                                    <button onClick={() => setModalDocument({ view: true, item: answer })}
                                                                        className="relative">
                                                                        {answer[key] === undefined || answer[key] === "" ?
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                className="icon icon-tabler icon-tabler-clipboard-plus" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                                                                <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                                                <path d="M10 14h4" />
                                                                                <path d="M12 12v4" />
                                                                            </svg>
                                                                            :
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                className="icon icon-tabler icon-tabler-file-check" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                                                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                                                <path d="M9 15l2 2l4 -4" />
                                                                            </svg>}
                                                                    </button>
                                                                )
                                                                : answer[key] === undefined || answer[key] === "" ? ""

                                                                    : ((isNaN(answer[key])
                                                                        ? (key === "registrationDate"
                                                                            ? formatDate(answer[key])
                                                                            : answer[key])
                                                                        : formatThousands(answer[key])))
                                                            )}
                                                        </td>
                                                    ))}
                                                    <td className='text-center'>
                                                        {answer.externalQuestionnaireAnswerId === undefined &&
                                                            <div className="inline-block align-middle">
                                                                {(!deleteSpinner.status || deleteSpinner.index !== answer.id) &&
                                                                    <button onClick={() => setModalConfirmDelete({ view: true, id: answer.id, indexTable: y })}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M4 7l16 0" />
                                                                            <path d="M10 11l0 6" />
                                                                            <path d="M14 11l0 6" />
                                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                        </svg>
                                                                    </button>}
                                                                {deleteSpinner.status && deleteSpinner.index === answer.id && <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true" />}
                                                            </div>
                                                        }
                                                        {answer.externalQuestionnaireAnswerId !== undefined && <div className='inline-block align-middle'>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltipValidated}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-checkbox" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M9 11l3 3l8 -8" />
                                                                    <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
                                                                </svg>
                                                            </OverlayTrigger>
                                                        </div>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3">{t("fpThird.noAnswers")}</td>
                        </tr>
                    )
                ) : null
            )}



        </div>
    )
}
