import InputsQuestions from "./InputsQuestions";
import ExcelQuestions from "./ExcelQuestions";
import { useState, useEffect } from "react";
import SaveAnswerResponse from "./SaveAnswerResponse";


export default function QuestionInputs({ externalQuestionsDto, question, selectedFormat, updatedSelectedFormat,
    externalQuestionnaire, handleGoQuestions, updatedDataExternal, dataExternal, updatedShowAnswerTable }) {

    const [savedAnswerTable, setSavedAnswerTable] = useState(false);
    const [savedAnswerResponse, setSavedAnswerResponse] = useState({})
    const [lastAnswer, setLastAnswer] = useState();


    const getResponseOnSavingAnswer = (savedData) => {
        // updatedSelectedFormat(undefined);
        setSavedAnswerTable(true)
        setSavedAnswerResponse(savedData)
    }


    const initCurrentQuestion = (lastAnswer) => {
        setSavedAnswerTable(false)
        if (externalQuestionsDto[question.currentIndex].id == 5) {
            updatedSelectedFormat("inputs");
        } else {
            updatedSelectedFormat("inputs")
        }
        setLastAnswer(lastAnswer)

        handleGoQuestions(externalQuestionsDto[question.currentIndex], false, question.currentIndex)
    }


    const setUpdateAnswerQuestion = (data) => {
        const newDataExternal = { ...dataExternal.data };
        newDataExternal.data.externalQuestionsDto = data
        updatedDataExternal(newDataExternal);
    }


    const showAnswerTableByQuestion = () => {
        setSavedAnswerTable(false);
        updatedShowAnswerTable(true)
        // updatedSelectedFormat(undefined);
    }



    return (<div className=''>
        <div className=''>
            {!savedAnswerTable && <>
                {(selectedFormat === "inputs" || selectedFormat === "calculada") &&
                    <InputsQuestions
                        questions={externalQuestionsDto}
                        idQuestion={question.id}
                        idExternal={externalQuestionnaire.idExternal}
                        titleQuestion={question.title}
                        descriptionQuestion={question.description}
                        instructionsQuestion={question.intruction}
                        idContact={externalQuestionnaire.idContact}
                        currentIndexQuestion={question.currentIndex}
                        answerTableData={getResponseOnSavingAnswer}
                        externalData={question.isExernalData}
                        setQuestions={setUpdateAnswerQuestion}
                        lastAnswer={lastAnswer}
                    >
                    </InputsQuestions>
                }

                {selectedFormat == "excel" &&
                    <ExcelQuestions
                        questions={externalQuestionsDto}
                        idQuestion={question.id}
                        idExternal={question.questionnaireId}
                        titleQuestion={question.title}
                        descriptionQuestion={question.description}
                        instructionsQuestion={question.instructions}
                        idContact={question.idContact}
                        currentIndexQuestion={question.currentIndex}
                        answerTableData={getResponseOnSavingAnswer}
                        setQuestions={setUpdateAnswerQuestion}
                    />
                }
            </>
            }

            {savedAnswerTable &&
                <SaveAnswerResponse
                    savedAnswer={savedAnswerResponse}
                    showAnswerTableByQuestion={showAnswerTableByQuestion}
                    lastAnswer={lastAnswer}
                    initCurrentQuestion={initCurrentQuestion}
                ></SaveAnswerResponse>
            }

        </div>
    </div>)
}