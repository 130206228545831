

import React from 'react';
import { useState, useContext } from 'react';
import { formatThousands } from '../../utils/Utils';
import { renderIcon } from '../../../../services/Functions';
import { IdiomContext } from "../../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';


function QuestionsEmissionsFactors({
  emissionsFactors
}) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [sortBy, setSortBy] = useState({
    column: '',
    ascending: true,
  });





  const handleHeaderClick = (columnName) => {
    if (sortBy.column === columnName) {

      setSortBy({ column: columnName, ascending: !sortBy.ascending });
    } else {

      setSortBy({ column: columnName, ascending: true });
    }
  };

  const renderHeaderIcon = (columnName) => {
    if (sortBy.column === columnName) {
      return sortBy.ascending ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-sort-ascending" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M4 6l7 0" />
        <path d="M4 12l7 0" />
        <path d="M4 18l9 0" />
        <path d="M15 9l3 -3l3 3" />
        <path d="M18 6l0 12" />
      </svg>
        : <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-sort-descending" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 6l9 0" />
          <path d="M4 12l7 0" />
          <path d="M4 18l7 0" />
          <path d="M15 15l3 3l3 -3" />
          <path d="M18 6l0 12" />
        </svg>;
    }
    return null;
  };

  if (emissionsFactors && Array.isArray(emissionsFactors)) {
    emissionsFactors.forEach(item => {
      if (item && item.topEmissions && Array.isArray(item.topEmissions)) {
        item.topEmissions.forEach(emission => {
          if (emission) {
            emission.nameFactor = t(`footPrint.${item.name}`);
            emission.nameFactorOriginal = item.name

          }
        });
      }
    });
  }



  const newData = [];

  if (emissionsFactors && Array.isArray(emissionsFactors)) {
    newData.push(
      ...emissionsFactors
        .filter((item) => item && Array.isArray(item.topEmissions))
        .flatMap((item) =>
          item.topEmissions.map((emission) => ({
            name: emission.name,
            nameFactor: emission.nameFactor,
            nameFactorOriginal: emission.nameFactorOriginal,
            scope: emission.scope,
            quantity: emission.quantity,
            kgCO2e: emission.kgCO2e,
          }))
        )
    );
  }

  let sortedEmissionsFactors = [];

  if (newData) {
    sortedEmissionsFactors = newData
      .filter((x) => x)
      .sort((a, b) => {
        if (sortBy.column === 'factor') {
          const nameA = a.nameFactor.toLowerCase();
          const nameB = b.nameFactor.toLowerCase();
          if (nameA < nameB) return sortBy.ascending ? -1 : 1;
          if (nameA > nameB) return sortBy.ascending ? 1 : -1;
          return 0;
        } else if (sortBy.column === 'emission') {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) return sortBy.ascending ? -1 : 1;
          if (nameA > nameB) return sortBy.ascending ? 1 : -1;
          return 0;
        } else if (sortBy.column === 'quantity') {
          const quantityA = parseFloat(a.quantity);
          const quantityB = parseFloat(b.quantity);
          return sortBy.ascending ? quantityA - quantityB : quantityB - quantityA;
        } else if (sortBy.column === 'kg') {
          const quantityA = parseFloat(a.kgCO2e);
          const quantityB = parseFloat(b.kgCO2e);
          return sortBy.ascending ? quantityA - quantityB : quantityB - quantityA;
        }
        return 0;
      });
  }


  return (

    <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">{t('footPrint.preguntasEmisionesFactor')}</h2>
      </header>
      {!emissionsFactors && <div className="text-center"><Spinner className='' /></div>}

      {emissionsFactors !== undefined && emissionsFactors.length == 0 &&
        <div className='mt-4 mb-4'>{t("footPrint.noDataToGraphic")}</div>}

      {emissionsFactors !== undefined && emissionsFactors.length > 0 && <>

        <div className="p-3">

          {/* Table */}

        </div>
        <div className="overflow-x-auto px-4">
          <table className='auto w-full'>
            <thead className="text-xs text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2" onClick={() => handleHeaderClick('factor')}>
                  <div className="font-semibold text-left pl-2 flex items-center gap-1">  <span>{t('footPrint.categoria')}</span>{renderHeaderIcon('factor')}</div>
                </th>
                <th className="p-2" onClick={() => handleHeaderClick('emission')}>
                  <div className="font-semibold text-lef pl-2 flex items-center gap-1">  <span>{t('footPrint.factor')}</span>{renderHeaderIcon('emission')}</div>
                </th>
                <th className="p-2" onClick={() => handleHeaderClick('quantity')}>

                  <div className="font-semibold  pl-2 flex items-center justify-end gap-1"> <span>{t('footPrint.cantidad')}</span>{renderHeaderIcon('quantity')}</div>
                </th>
                <th className="p-2" onClick={() => handleHeaderClick('kg')}>
                  <div className="font-semibold justify-end pl-2 flex items-center gap-1">  <span>kgCO<sub>2</sub>e</span>{renderHeaderIcon('kg')}</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm font-medium divide-y divide-gray-100 px-4">
              {emissionsFactors &&
                sortedEmissionsFactors.map((y, index) => (
                  <tr key={`${index}`}>
                    <td className="p-2">
                      <div className="p-2 text-left flex items-center gap-1">
                        <span >{renderIcon(y.nameFactorOriginal)}</span><span style={{ maxWidth: '70%' }}>{t(y.nameFactor)}</span></div>
                    </td>
                    <td className='td-multi p-2'>
                      <div className="p-2 text-left">{y.name}</div>
                    </td>
                    <td className='td-multi p-2'>
                      <div className="p-2 text-end">{y.quantity ? formatThousands(y.quantity) : ''}</div>

                    </td>
                    <td className='td-multi p-2'>
                      <div className="p-2 text-end">
                        {typeof y.kgCO2e === 'number' ? formatThousands(y.kgCO2e) : 'N/A'}
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </>
      }
    </div>
  );
}

export default QuestionsEmissionsFactors;

