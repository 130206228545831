import React from 'react';
import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Forestal from './Forestal';

export default function ForestalHome() {
  return (
    <div>
      <Header></Header>
      <Forestal></Forestal>
      <Footer></Footer>
    </div>
  )
}
