import React, { useRef, useEffect,useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "./../../../context/createcontext"

import {
  Chart, Filler, LinearScale, LineController, LineElement, PointElement, TimeScale
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt"
import "moment/locale/gl"

// Import utilities
import { formatThousands } from '../utils/Utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale);

function LineChart03({
  data,
  width,
  height,
  unit
}) {

  const canvas = useRef(null);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    switch (lng) {
      case "en":
        moment.locale("en");
        break;
      case "pt":
        moment.locale("pt");
        break;
      case "gl":
        moment.locale("gl");
        break;
      default:
        moment.locale("es");
        break;
    }
    const chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              drawBorder: false,
            },
            ticks: {
              callback: (value) => formatThousands(value),
            },
          },
          x: {
            type: 'time',
            time: {
              parser: 'DD-MM-YYYY',
              //Cambiado a day (antes month)
              unit: 'day',
              // displayFormats: {
              //   month: 'MMM YY',
              // },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatThousands(context.parsed.y) + (unit != undefined ? unit : ""),
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
    });
    return () => chart.destroy();
  }, [lng]);

  return (

    <React.Fragment>
      <div className="px-5 pt-2 pb-6">
        <div className="grow mb-1">
          <ul className="flex flex-wrap justify-center ">
          <li className="mr-1 ">
            <span className="display inline-flex items-center">
              <span className="display block w-3 h-3 rounded-full	mr-2 border-3	"></span>
              <span className="decoration-slate-500	text-sm leading-normal ">{t("dash.preciomercado")}&nbsp;&nbsp;</span>
              <style>{"\
                      .borderblue{\
                      border-color:rgb(99, 102, 241);\
                      }\
                      "}
              </style>
            </span>
          </li>
          <li className="mr-1">
            <span className="display inline-flex items-center">
              <span className="display block w-3 h-3 rounded-full	mr-2 border-3 borderblue"></span>
              <span className="decoration-slate-500	text-sm leading-normal">{t("dash.airco2")}</span>
            </span>
          </li>
          </ul>
        </div>
      </div>
      <div className="grow ">
        <canvas ref={canvas} width={width} height={height}></canvas>
      </div>
    </React.Fragment>

    // <div className="px-5 pt-2 pb-6">
    //   <div className="grow mb-1">
    //     <ul ref={legend} className="flex flex-wrap justify-center "></ul>
    //   </div>
    // </div>

  );
}

export default LineChart03;