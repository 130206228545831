import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import axios from 'axios';
import { OverlayTrigger } from 'react-bootstrap';
import UserService from '../../../services/UserService';
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Spinner } from 'react-bootstrap';
import { Modal, Button, Col } from "react-bootstrap";
import { Tooltip } from 'react-bootstrap';
import FilterButton from "../../../pages/intranet/utils/DropdownFilter"
import moment from 'moment';
import Banner from '../../../components/utils/Banner';

export default function Contacts(props) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [groupsIds, setGroupsIds] = useState('');
    const [contactId, setContactId] = useState('')
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [contactsFilter, setContactsFilter] = useState();
    const [contacts, setContacts] = useState({
        data: [],
        isFetching: false
    });
    const [showImportContacts, setShowImportContacts] = useState(false);
    const [importContactsGroupId, setImportContactsGroupId] = useState('');
    const [unsavedContacts, setUnsavedContacts] = useState([]);
    const [postIsFetching, setPostIsFetching] = useState(false);

    const currentPathname = window.location.pathname;
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const userId = props.userId != undefined ? props.userId : UserService.getUserId();

    const fetchContacts = async () => {
        setContacts({ data: contacts.data, isFetching: true });
        try {

            const response = await axios.get(`${process.env.REACT_APP_CH_CONTACTS}/all?userId=${userId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setContacts({ data: response.data, isFetching: false });
            setContactsFilter(response.data.data)



        } catch (exception) {
            setContacts({ data: [], isFetching: false });

        }
    };
    useEffect(() => {

        fetchContacts();
    }, [])

    const [groups, setGroups] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fetchGroups = async () => {
            setGroups({ data: contacts.data, isFetching: true });
            try {

                const response = await axios.get(`${process.env.REACT_APP_CH_GROUPS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setGroups({ data: response.data, isFetching: false });

            } catch (exception) {
                setGroups({ data: [], isFetching: false });
            }
        };
        fetchGroups();
    }, [])



    const groupsData = groups.data.data


    const getGroupNameById = (groupId) => {
        const group = groupsData?.find((group) => group.id == groupId);
        return group ? group.name : 'Desconocido';
    };

    const statusColor = (statusId) => {

        const statusColorMap = {
            1: "#b4e1ef",
            3: "#a0a9f7",
            2: "#ff879f",

        };

        return statusColorMap[statusId] || "#f9dc70";
    };



    const useSortableData = (items, config = null) => {

        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            if (!items || items.length === 0) {
                return [];
            }
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    let valueA = a[sortConfig.key];
                    let valueB = b[sortConfig.key];

                    if (sortConfig.key.includes(".")) {
                        let params = sortConfig.key.split(".");
                        valueA = a[params[0]]?.[params[1]];
                        valueB = b[params[0]]?.[params[1]];
                    }

                    if (valueA === null || valueA === undefined || valueA === "") {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }

                    if (valueB === null || valueB === undefined || valueB === "") {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }

                    if (!isNaN(valueA) || moment(valueA).isValid()) {
                        if (valueA < valueB) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (valueA > valueB) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    } else {
                        if (
                            valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (
                            valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    }

                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(contactsFilter);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };



    const renderTooltipEditar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.editar")}
        </Tooltip>
    );
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const handleClose = () => {
        setShowEdit(false)
        setShowDelete(false)
        setEmail('')
        setName('')
        setGroupsIds('')
        setContactId('')
        cleanMessage()
        setButtonDisabled(false)
        setShowImportContacts(false)
        setImportContactsGroupId('')
        setTableData([])
        setUnsavedContacts([])
    }
    const [msg, setMsg] = useState({
        type: '',
        message: '',
        open: false,
    });

    const handleShowEdit = (x) => {
        setShowEdit(true)
        setEmail(x.email)
        setName(x.name)
        setGroupsIds(x.groupIds)
        setContactId(x.id)
    }
    const handleShowDelete = (x) => {
        setShowDelete(true)
        setName(x.name)
        setContactId(x.id)

    }
    const handleSubmitNew = async (e) => {
        e.preventDefault();

        const data = [{
            "name": name,
            "email": email,
            "validate": true,
            "groupIds": groupsIds
        }];

        handlePostContacts(data);

    }

    const handlePostContacts = async (data) => {

        const url = `${process.env.REACT_APP_CH_CONTACTS}/contact?userId=${userId}`;
        setPostIsFetching(true);

        try {
            setButtonDisabled(true);

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            await fetchContacts();

            const responseData = await response.json();

            if (responseData.success) {
                //El POST devuelve solo contactos que no han podido crearse por duplicidad
                setUnsavedContacts(responseData.data);

                if (data.length == responseData.data.length) {
                    setMsg({ type: "error", message: t("fpThird.new-ko") });
                } else {
                    setMsg({ type: "success", message: t("fpThird.new-ok") });
                }
            } else {
                setMsg({ type: "error", message: t("fpThird.new-ko") });
            }

        } catch (error) {
            console.error('Error:', error.message);
            setMsg({ type: "error", message: t("fpThird.new-ko") });
        }
        setPostIsFetching(false);
    }

    const handleCheckboxChange = (e, groupId) => {
        const checked = e.target.checked;
        setGroupsIds((prevGroupIds) => {
            if (checked) {

                return [...prevGroupIds, groupId];
            } else {

                return prevGroupIds.filter((id) => id !== groupId);
            }
        });
    };

    const handleSubmitPut = async (e) => {
        e.preventDefault();
        setSendingRequest(true);

        const url = `${process.env.REACT_APP_CH_CONTACTS}/contact`;
        const data = {
            "id": contactId,
            "name": name,
            "email": email,
            "validate": true,
            "groupIds": groupsIds
        };

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`

                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            await fetchContacts()



            const responseData = await response.json();

            if (responseData.success) {
                setButtonDisabled(true);
                setMsg({ type: "success", message: t("fpThird.update-ok") });
            } else {
                setMsg({ type: "error", message: t("fpThird.update-ko") });
            }





        } catch (error) {
            console.error('Error:', error.message);
            setMsg({ type: "error", message: t("fpThird.update-ko") });
        } finally {
            setSendingRequest(false);
        }
    };

    const handleDelete = async (e) => {
        const url = `${process.env.REACT_APP_CH_CONTACTS}/contact/${contactId}`;
        e.preventDefault();
        setSendingRequest(true);

        try {
            const response = await axios.delete(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',

                    'Authorization': `Bearer ${UserService.getToken()}`
                }

            });
            await fetchContacts()

            const responseData = await response.data;

            if (responseData.success) {
                setButtonDisabled(true);
                setMsg({ type: "success", message: t("fpThird.delete-ok") });
            } else {
                setMsg({ type: "error", message: t("fpThird.delete-ko") });
            }

        } catch (error) {
            console.error('Error al eliminar:', error);
            setMsg({ type: "error", message: t("fpThird.delete-ko") })
        }
        finally {
            setSendingRequest(false);
        }

    };
    const cleanMessage = () => {
        setMsg({
            type: '',
            message: '',
            open: false
        })
    }

    const [filtros, setFiltros] = useState({

        email: "",
        name: "",
        groups: [],

    });
    const [uniqueGroupsList, setUniqueGroupsList] = useState(
        []
    );



    const [groupsList, setGroupsList] = useState([]);
    useEffect(() => {
        const uniqueGroups = Array.from(new Set(contacts?.data?.data?.flatMap(contact => contact.groupIds)));
        setUniqueGroupsList(uniqueGroups);


        setGroupsList(uniqueGroups.map(groupId => ({
            category: getGroupNameById(groupId),
            state: groupId.toString(),
            check: false
        })));




    }, [contacts?.data?.data]);




    const OnFilterEmail = (filter, filteredData) => {
        return filteredData.filter((v) =>
            v.email.toLowerCase().includes(filter.toLowerCase())
        );

    };

    const OnFilterName = (filter, filteredData) => {
        return filteredData.filter((v) =>
            v.name.toLowerCase().includes(filter.toLowerCase())
        );
    };




    const OnFilterGroups = (data, filteredData) => {
        const selectedGroupIds = data.filter((group) => group.check).map((group) => group.state.toString());

        if (selectedGroupIds.length > 0) {
            const filteredResult = filteredData.filter((contact) => contact.groupIds.some(groupId => selectedGroupIds.includes(groupId.toString())));
            return filteredResult;
        }

        return filteredData;
    };



    const applyCategoryFilter = (data) => {


        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            groups: data,
        }));
    };

    useEffect(() => {
        const handleInputChange = () => {

            let filteredData = contacts.data?.data


            if (filtros.email) {
                filteredData = OnFilterEmail(filtros.email, filteredData);
            }

            if (filtros.name) {
                filteredData = OnFilterName(filtros.name, filteredData);
            }
            if (filtros.groups) {
                filteredData = OnFilterGroups(filtros.groups, filteredData);
            }




            setContactsFilter(filteredData);
        };
        handleInputChange();
    }, [filtros]);

    const handleCheckboxContacts = (contacto) => {


        setSelectedContacts((prevSelectedContacts) => {
            const isContactSelected = prevSelectedContacts.some((contact) => contact.id === contacto.id);

            if (isContactSelected) {

                return prevSelectedContacts.filter((contact) => contact.id !== contacto.id);
            } else {

                return [...prevSelectedContacts, contacto];
            }
        });
    };


    const handleSelectAll = () => {
        setSelectAll(!selectAll);

        if (!selectAll) {

            setSelectedContacts(items);
        } else {

            setSelectedContacts([]);
        }
    };

    useEffect(() => {
        if (props && props.onDataFromChild) {
            props.onDataFromChild(selectedContacts);
        }
        if (props?.deSelectAll) {
            setSelectedContacts([])
        }
    }, [selectedContacts, props]);



    const [tableData, setTableData] = useState([]);

    const handlePasteExcel = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text');

        const rows = pastedData.trim().split('\n').map(row => row.split('\t'));

        let headerRow = rows[0];
        let emailIndex = headerRow.findIndex(header => header.toLowerCase().includes('@'));
        if(emailIndex == -1){
            headerRow = rows[1];
            emailIndex = headerRow.findIndex(header => header.toLowerCase().includes('@'));
            rows.shift()
        }
        const nameIndex = headerRow.findIndex((header, index) => index !== emailIndex);

        const parsedData = rows.map(row => ({
            email: row[emailIndex],
            name: row[nameIndex]
        }));

        setTableData(parsedData);
    }

    const handleSaveMultipleContacts = async () => {
        let newUsersToSave = [];
        tableData.forEach(element => {
            let userObject = {
                "name": element.name,
                "email": element.email,
                "validate": true,
                "groupIds": [importContactsGroupId]
            }
            newUsersToSave.push(userObject);
        });

        handlePostContacts(newUsersToSave);
    }


    return (




        <div>

            {/*  Site header */}


            <Modal show={showEdit}>

                <Modal.Header className="sec-green-background">
                    <Modal.Title className="text-white">{t("fpThird.contact")}: {name}</Modal.Title>
                    <div className="flex justify-end">
                        <button onClick={() => handleClose()}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {msg.type !== "" && <Banner type={msg.type} open setOpen={true}>{msg.message}</Banner>}
                    <Form className='text-xs' onSubmit={(e) => { contactId ? handleSubmitPut(e) : handleSubmitNew(e) }}

                    >
                        <Col col="6">
                            <FormGroup>
                                <Label for="name">{t("fpThird.name")}</Label>
                                <Input className='text-xs' type="text" name="name" id="name" defaultValue={name} onChange={(e) => { cleanMessage(); setName(e.target.value) }} required>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col col="6">
                            <FormGroup>
                                <Label for="email">{t("fpThird.email")}</Label>
                                <Input className='text-xs' type="email" name="email" id="email" defaultValue={email} onChange={(e) => { cleanMessage(); setEmail(e.target.value) }} required>


                                </Input>
                            </FormGroup>
                        </Col>
                        <Col col="6">
                            <FormGroup>
                                <Label for="groupsType">{t("fpThird.grupos")}</Label>

                                {groupsData
                                    ?.map((group) => (
                                        (group.editable || groupsIds.includes(group.id)) && (
                                            <div key={group.id} className="form-check">
                                                <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="forestType"
                                                    id={`groupCheckbox_${group.id}`}
                                                    value={group.id}
                                                    defaultChecked={groupsIds.includes(group.id)}
                                                    onChange={(e) => handleCheckboxChange(e, group.id)}
                                                    disabled={!group.editable}
                                                />
                                                <Label className="form-check-label" for={`groupCheckbox_${group.id}`}>
                                                    {group.name}
                                                </Label>
                                            </div>
                                        )
                                    ))}


                            </FormGroup>
                        </Col>


                        <Button className="btn-green mr-4 rounded-lg px-4 py-2" type="submit" disabled={buttonDisabled || sendingRequest}>
                            {sendingRequest ? (
                                <Spinner animation="border" role="status">

                                </Spinner>
                            ) : (
                                contactId ? t("fpThird.actualizar") : t("fpThird.guardar")
                            )}
                        </Button>


                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showDelete}>
                <Modal.Header className="sec-green-background">
                    <Modal.Title className="text-white">{t("fpThird.contact")}: {name}</Modal.Title>
                    <div className="flex justify-end">
                        <button onClick={() => handleClose()}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <Col col="6" className='text-center'>
                        {msg.type !== "" && <Banner className='mb-4' type={msg.type} open setOpen={true}>{msg.message}</Banner>}

                        {msg.type === "" &&

                            <FormGroup>
                                <span >
                                    {t("fpThird.eliminarContact")}
                                </span>

                            </FormGroup>
                        }
                    </Col>
                    <Col className='flex justify-center'>
                        <Button className='btn-cancel mr-4 rounded-lg px-4 py-2' type="button" onClick={(e) => handleClose(e)}>
                            {msg.type === "" ?
                                t("footPrint.cancelar")
                                :
                                t("fpThird.cerrar")
                            }

                        </Button>
                        {msg.type === "" &&
                            <Button onClick={(e) => handleDelete(e)} className="btn-green mr-4 rounded-lg px-4 py-2" type="submit" disabled={buttonDisabled || sendingRequest}>
                                {sendingRequest ?
                                    <Spinner animation="border" role="status"></Spinner>
                                    :

                                    t("fpThird.eliminar")
                                }



                            </Button>
                        }
                    </Col>




                </Modal.Body>

            </Modal>

            <Modal show={showImportContacts}>
                <Modal.Header className="sec-green-background">
                    <Modal.Title className="text-white">{t("fpThird.importar-contactos")}</Modal.Title>
                    <div className="flex justify-end">
                        <button onClick={() => handleClose()}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        {tableData.length == 0 ?
                            <div>
                                <div>
                                    <p><b>1) </b>{t("fpThird.importar-contactos-1")}</p>
                                    <select required defaultValue={''} list="group" className="custom-width-md rounded-lg mt-2" onChange={(e) => setImportContactsGroupId(e.target.value)}>
                                        <option className='' disabled="disabled" value={''}>{t("footPrint.selecciona")}</option>
                                        {groupsData?.map((group, i) => ((group.editable || groupsIds.includes(group.id)) && (
                                            <option key={i} value={group.id}>{group.name}</option>)))
                                        }
                                    </select>
                                </div>
                                <div className='mt-4'>
                                    <p className='mb-2'><b>2) </b>{t("fpThird.importar-contactos-2")}</p>
                                    <textarea onPaste={handlePasteExcel} className='w-full mt-2' placeholder={importContactsGroupId != '' ? "" : t("fpThird.select-group")} disabled={importContactsGroupId != '' ? false : true} />
                                </div>
                            </div>
                            :
                            (buttonDisabled && !postIsFetching ?
                                <div>
                                    {msg.type !== "" && <Banner className='mb-4' type={msg.type} open setOpen={true}>{msg.message}</Banner>}
                                    {unsavedContacts.length > 0 &&
                                        <div className='mt-4'>
                                            <p>{t("fpThird.importar-contactos-unsaved")}</p>
                                            <div className='overflow-x-auto w-full p-4'>
                                                <table className='table-striped table-bordered table-auto w-full bg-white '>
                                                    <thead>
                                                        <th>{t("fpThird.name")}</th>
                                                        <th>{t("fpThird.email")}</th>
                                                    </thead>
                                                    <tbody>
                                                        {unsavedContacts.map((rowData, index) => (
                                                            <tr key={index}>
                                                                <td>{rowData.name}</td>
                                                                <td>{rowData.email}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    }
                                </div>

                                :

                                <div>
                                    <div className='flex justify-end'>
                                        <button className='flex items-center my-2' onClick={() => setTableData([])}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                                            </svg>
                                            <span>{t("fpThird.paso-atras")}</span>
                                        </button>
                                    </div>
                                    <h5 className='h5'>{t("fpThird.nuevos-contactos")} ({getGroupNameById(importContactsGroupId)})</h5>
                                    <div className='overflow-x-auto w-full p-4'>
                                        <table className='table-striped  table-bordered table-auto w-full bg-white '>
                                            <thead>
                                                <th>{t("fpThird.name")}</th>
                                                <th>{t("fpThird.email")}</th>
                                            </thead>
                                            <tbody>
                                                {tableData.map((rowData, index) => (
                                                    <tr key={index}>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.email}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <Col className='flex justify-center mt-4'>
                        <Button className='btn-cancel mr-4 rounded-lg px-4 py-2' type="button" onClick={(e) => handleClose(e)}>
                            {msg.type === "" ?
                                t("footPrint.cancelar")
                                :
                                t("fpThird.cerrar")
                            }
                        </Button>
                        {tableData.length > 0 &&
                            <Button onClick={handleSaveMultipleContacts} className="btn-green mr-4 rounded-lg px-4 py-2" type="button" disabled={buttonDisabled}>
                                {postIsFetching ? <Spinner animation="border" variant="light" /> : t("fpThird.guardar")}
                            </Button>
                        }
                    </Col>
                </Modal.Body>
            </Modal>

            <div className={`${currentPathname.includes('sendForm') ? 'py-8 w-full max-w-9xl mx-auto' : 'px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto '}`}>
                {(!currentPathname.includes('sendForm') || UserService.hasRole(['admin'])) &&
                    <div className="mb-8 text-gray-800 ">
                        {/* Title */}

                        <div className='flex justify-between flex-wrap'>
                            <h1 className="text-2xl md:text-3xl font-bold">{t("fpThird.contacts")}</h1>
                            {/* <button onClick={() => setShowEdit(true)} className='btn-green mr-4 rounded-lg px-4 py-2' >
                                {t("fpThird.nuevoContacto")}
                            </button> */}
                            <div className='flex items-center'>
                                <button onClick={() => setShowEdit(true)} className='btn-green mr-4 rounded-lg px-4 py-2' >
                                    {t("fpThird.nuevoContacto")}
                                </button>
                                <button onClick={() => setShowImportContacts(true)} className='btn-green mr-4 rounded-lg px-4 py-2' >
                                    {t("fpThird.importar-contactos")}
                                </button>
                            </div>

                        </div>
                        <h1 className="mt-4">{t("fpThird.contactsSubtitle")}</h1>
                        <h1 className="">{t("fpThird.contactsSubtitle2")}</h1>

                    </div>

                }


                <div className="flex flex-col gap-2 mb-3 text-xs sm:flex-row">
                    <FilterButton align="left"
                        categoriesUniq={uniqueGroupsList}
                        stateCheck={groupsList}
                        applyCategoryFilter={applyCategoryFilter}
                        nombreFiltro={t("fpThird.grupos")}
                    />


                    <Input value={filtros.email}
                        className=" form-input w-full rounded-lg  bg-white border-gray-200 hover:border-gray-300 "
                        onChange={(e) =>
                            setFiltros((prevFiltros) => ({
                                ...prevFiltros,
                                email: e.target.value,
                            }))
                        }
                        placeholder={t("fpThird.search-email")}
                        type="text"
                    />
                    <Input value={filtros.name}
                        className="form-input w-full rounded-lg  bg-white border-gray-200 hover:border-gray-300 "
                        onChange={(e) =>
                            setFiltros((prevFiltros) => ({
                                ...prevFiltros,
                                name: e.target.value,
                            }))
                        }
                        placeholder={t("fpThird.search-name")}
                        type="text"

                    />





                </div>

                <div className="overflow-x-auto w-full">
                    <table className="table-striped table-bordered table-auto w-full bg-white ">
                        <thead className='text-center text-xl sec-green-background text-white'>
                            <tr className='py-4'>
                                {(currentPathname.includes('sendForm')) &&
                                    <th className='mx-2 border-2'>
                                        <input
                                            style={{ borderColor: 'rgb(255 255 255)' }}
                                            type="checkbox"
                                            name="selectAll"
                                            checked={selectAll}
                                            onChange={handleSelectAll}

                                        />

                                    </th>

                                }
                                <th>
                                    <button type="button" onClick={() => requestSort('email')} className={`${getClassNamesFor('email')} text-underline`}>

                                        {t("fpThird.email")}
                                    </button>
                                </th>
                                <th>
                                    <button type="button" onClick={() => requestSort('nombre')} className={`${getClassNamesFor('nombre')} text-underline`}>
                                        {t("fpThird.name")}
                                    </button>
                                </th>
                                <th>
                                    <button type="button" onClick={() => requestSort('grupos')} className={`${getClassNamesFor('grupos')} text-underline`}>
                                        {t("fpThird.grupos")}
                                    </button>
                                </th>
                                {(!currentPathname.includes('sendForm')) &&
                                    <th>
                                        <button type="button" className={`${getClassNamesFor('acciones')} text-underline`}>
                                            {t("fpThird.acciones")}
                                        </button>
                                    </th>
                                }

                            </tr>
                        </thead>
                        <tbody className='text-center text-md'>
                            {contacts.isFetching ? (
                                <tr className='flex justify-center'>
                                    <Spinner animation="border" variant="dark" />
                                </tr>
                            ) : (
                                contacts.data && contacts.data.data && contactsFilter ? (
                                    contacts.data.data.length && contactsFilter.length > 0 ? (
                                        items.map((contacto) => (
                                            <tr key={contacto.id}>
                                                {(currentPathname.includes('sendForm')) &&
                                                    <td className=''>

                                                        <input
                                                            className="mx-2"
                                                            type="checkbox"
                                                            name="contacts"
                                                            id={`groupCheckbox_${contacto.id}`}
                                                            value={contacto.id}
                                                            checked={selectedContacts.some((selectedContact) => selectedContact.id === contacto.id)}
                                                            onChange={() => handleCheckboxContacts(contacto)}
                                                            disabled={''}
                                                        />
                                                    </td>
                                                }
                                                <td>{contacto.email}</td>
                                                <td>{contacto.name}</td>
                                                <td>
                                                    {contacto.groupIds && contacto.groupIds.length > 0 ? (
                                                        <div>

                                                            <ul className='flex ml-4 '>
                                                                {contacto.groupIds.map((groupId, index) => (
                                                                    <li className='rounded-full mx-2 px-3' style={{ backgroundColor: statusColor(groupId) }} key={index}> {getGroupNameById(groupId)}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ) : (
                                                        'Sin grupos'
                                                    )}
                                                </td>
                                                {(!currentPathname.includes('sendForm')) &&
                                                    <td>
                                                        <div className='flex items-center justify-center'>


                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltipEditar}
                                                            >
                                                                <button onClick={() => handleShowEdit(contacto)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                                        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                                        <path d="M16 5l3 3" />
                                                                    </svg>
                                                                </button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltipEditar}
                                                            >
                                                                <button onClick={() => handleShowDelete(contacto)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <line x1="4" y1="7" x2="20" y2="7" />
                                                                        <line x1="10" y1="11" x2="10" y2="17" />
                                                                        <line x1="14" y1="11" x2="14" y2="17" />
                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                    </svg>
                                                                </button>
                                                            </OverlayTrigger>

                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">{t("fpThird.no-hay-contactos")}</td>
                                        </tr>
                                    )
                                ) : null
                            )}
                        </tbody>


                    </table>
                </div>
            </div>
        </div>


    )

}