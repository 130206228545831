import { useState } from "react";
import { useTranslation } from 'react-i18next';
import ModalRecalculate from "./ModalRecalculate";



export default function BannerRecalculate({ calculation }) {

    const [t, i18n] = useTranslation("global");

    const [modalRecalculate, setModalRecalculate] = useState({
        view: false,
        idCalculation: null,
    });

    const [recalculating, setRecalculating] = useState({
        id: 0,
        isFetching: false,
    });

    const handleRecalculate = async (id) => {
        setModalRecalculate({ view: true, id: id });
    };


    const getText = () => {
        if (recalculating.isFetching) {
            return <div className=" ">
                {t("footPrint.recalculate.recalculating")}{" "}
            </div>
        }

        if (!calculation.hasUpdatedFactors && !calculation.updatable) {
            return <div className=" ">
                Todavía no han sido publicados los factores para el año  {calculation.year}.
            </div>
        }

        if (!calculation.hasUpdatedFactors && calculation.updatable) {
        return <div className="flex gap-2">
            Ya están disponibles los factores para el año  {calculation.year}.
            <div className="flex items-center gap-1">
            <p>Recalcular</p>
                <button className="italic text-xs flex flex-col items-center" onClick={() => handleRecalculate(calculation.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-rotate" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#f19b7f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" />
                    </svg>
                   



                </button>

            </div>

        </div>
        }
    }



    const updateFromRecalculate = (id) => {
        calculation.hasUpdatedFactors = true
    };

    return <>
        <ModalRecalculate
            modalRecalculate={modalRecalculate}
            setModalRecalculate={setModalRecalculate}
            recalculating={recalculating}
            setRecalculating={setRecalculating}
            updateFromRecalculate={updateFromRecalculate}
        ></ModalRecalculate>
        {getText()}
    </>
}