import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'

export default function BloqueDocOtros() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className='container flex justify-center mt-5'>
            <div className='bloque-pasos  max-w-6xl mx-auto px-2 sm:px-3 text-airco2-general'>
                <span className='number-landing' >
                    3
                </span>
                <div className='flex items-center pt-5 pb-3 flex-no-wrap custom-flex'>


                    <h3 className='h1  text-airco2-title-landing w-full md:w-1/2 mt-3  m-3 '> {t("landingEventos.otrosServicios")}</h3>
                    <p className='text-sub w-full md:w-1/2 m-3 font-semibold flex flex-col items-end text-right'>
                        <span>{t("landingEventos.aportaValor")}</span>
                        
                        <span className='mt-2' style={{ width: '100%', height: '1.5px', backgroundColor: ' #878787' }}> </span>



                    </p>

                </div>
                {/* bloque1 */}
                <div className='m-3 mb-5'>
                    <h4 className="text-airco2-principal font-bold pb-3  text-2xl" data-aos="fade-up" data-aos-delay="100" > {t("landingEventos.pildoras")} :
                    </h4>
                    <p>
                        {t("landingEventos.pildorasTxt")}
                    </p>
                    <ul className='m-2 list-decimal'>
                        <li>
                            {t("landingEventos.verdadMercados")}

                        </li>
                        <li>
                            {t("landingEventos.evitar")} <span className='italic'>Greenwashing</span>
                        </li>
                        <li>
                            {t("landingEventos.ROInetZero")}
                        </li>
                    </ul>
                    <h4 className="text-airco2-principal font-bold pb-3 pt-3  text-2xl" data-aos="fade-up" data-aos-delay="100" > {t("landingEventos.ayuda")}. 
                    </h4>

                </div>

             


                {/* bloque2 */}

                {/* <div className='m-3 mb-5'>
                    <h3 className="text-airco2-principal font-bold pb-3  text-2xl" data-aos="fade-up" data-aos-delay="100" > {t("landingEventos.marcaBlanca")} <span className='italic'> partners</span>
                    </h3>
                    <p>
                        {t("landingEventos.redEmpresas")}
                    </p>
                </div> */}

             


            </div>

        </div>
    )
}
