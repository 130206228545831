import React, { useContext } from 'react';
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { formatThousands } from '../utils/Utils';
import Translations from '../../../services/Translations'




export default function ArbolMontes(props) {
  const porcentaje = (props.anualCO2 - props.availableCO2) / props.anualCO2 * 100

  function Baremo(porcentaje) {
    if (porcentaje <= 30) {
      return 'rgb(249, 231, 159)'

    } else if (porcentaje > 30 && porcentaje <= 70) {
      return ' rgb(245, 176, 65)'

    } else if (porcentaje > 70) {
      return '  rgb(130, 224, 170) '
    }
  }



  const color = Baremo(porcentaje)


  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (

    <Container className=" col-md-1 mx-auto card-container">
      <Card.Body className='card-body-img'>

        <div className="backgroundTree">
          <div className="slope"></div>
          <div className="tree">

            <div className="leaf leaf1" style={{ background: `${color}` }}></div>
            <div className="leaf leaf2" style={{ background: `${color}` }}></div>


            <div className="branch left branch1">
              <div className="branch left branch-inner1">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>

              </div>

              <div className="branch left branch-inner2">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>
                <div className="leaf leaf4" style={{ background: `${color}` }}>

                </div>
              </div>
              <div className="branch left branch-inner3">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
              </div>

            </div>
            <div className="branch right branch2">
              <div className="branch left branch-inner1">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>

              </div>
              <div className="branch right branch-inner2">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>
                <div className="leaf leaf4" style={{ background: `${color}` }}>

                </div>
              </div>
              <div className="branch right branch-inner3">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>
                <div className="branch left branch-inner4">
                  <div className="leaf leaf1" style={{ background: `${color}` }}></div>

                </div>


              </div>

            </div>
            <div className="branch left branch3">
              <div className="branch right branch-inner1">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>

              </div>
              <div className="branch left branch-inner2">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>
                <div className="leaf leaf4" style={{ background: `${color}` }}>

                </div>
              </div>
              <div className="leaf leaf1" style={{ background: `${color}` }}></div>
              <div className="leaf leaf2" style={{ background: `${color}` }}></div>

            </div>
            <div className="branch right branch4">
              <div className="branch left branch-inner1">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>

              </div>
              <div className="branch right branch-inner2">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>

              </div>

            </div>
            <div className="branch left branch5">
              <div className="branch right branch-inner1">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>

              </div>
              <div className="branch left branch-inner2">
                <div className="leaf leaf1" style={{ background: `${color}` }}></div>
                <div className="leaf leaf2" style={{ background: `${color}` }}></div>
                <div className="leaf leaf3" style={{ background: `${color}` }}></div>
                <div className="leaf leaf4" style={{ background: `${color}` }}>

                </div>
              </div>

            </div>
          </div>
          <div className='porcentajeTree rounded-full' style={{ background: `${color}` }}>
            <p>
              {porcentaje?.toFixed(2)}%
            </p>
          </div>
        </div>

      </Card.Body>
      <Card.Body className='flex flex-column text-center'>
        <Card.Title style={{ color: "black", font: "bold" }}>{props.name}</Card.Title>

        <ListGroup variant="flush" className=' listArbol'>

          <ul>
            <li className='flex inline mb-2'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dimensions" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none"   >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 5h11" />
                <path d="M12 7l2 -2l-2 -2" />
                <path d="M5 3l-2 2l2 2" />
                <path d="M19 10v11" />
                <path d="M17 19l2 2l2 -2" />
                <path d="M21 12l-2 -2l-2 2" />
                <rect x="3" y="10" width="11" height="11" rx="2" />
              </svg>
              <span className='ml-2'>{formatThousands(props.size)} ha</span>
            </li>

            <li className='flex inline mb-2'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                <line x1="15" y1="21" x2="15" y2="18" />
                <path d="M8 13l-2 -2" />
                <path d="M8 12l2 -2" />
                <path d="M8 21v-13" />
                <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
              </svg>
              <span className='ml-2'>{t(Translations.translateForestTypeName(props.forestType))}</span>
            </li>

            <li className='flex inline mb-2'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-stack-3" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 2l-8 4l8 4l8 -4l-8 -4" />
                <path d="M4 10l8 4l8 -4" />
                <path d="M4 18l8 4l8 -4" />
                <path d="M4 14l8 4l8 -4" />
              </svg>
              <span className='ml-2'>{formatThousands(props.anualCO2)} {t("proyectos.anual")}</span>
            </li>
            <li className='flex inline mb-2'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-stack-3" width="22" height="22" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" strokeWidth="1.5" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 2l-8 4l8 4l8 -4l-8 -4" />
                <path d="M4 10l8 4l8 -4" />
                <path d="M4 13l8 4l8 -4" />

              </svg>
              <span className='ml-2'>{formatThousands((props.availableCO2)?.toFixed(2))} {t("proyectos.available")}</span>
            </li>
            <li className='flex inline mb-2'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-currency-euro" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M17.2 7a6 7 0 1 0 0 10" />
                <path d="M13 10h-8m0 4h8" />
              </svg>
              <span className='ml-2'>{formatThousands(props.anualEarings)} € {t("proyectos.generados")} </span>
            </li>

          </ul>

        </ListGroup>




      </Card.Body>


    </Container>



  )
}
