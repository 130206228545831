import React from 'react';
import { useContext } from 'react';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';


function PaginationClassic(props) {

  const data = props.items

  const datatotal = props.total
  const total = (datatotal.length)
  const npage = props.currentpage + 1
  const npagina = props.itemPage * npage

  // cambiar el idioma

 const [lng, updateLng] = useContext(IdiomContext)
 const [t, i18n] = useTranslation("global");

  return (

    <div className="flex justify-center">

      <nav className="flex" role="navigation" aria-label="Navigation">

     {/* boton pagina primera */}
     <div className="mr-2">
          <a href="#0"  aria-label='lleva a primera pagina' className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white  border-gray-200 text-gray-600 shadow-sm text-decoration-none hover:color-purple" 
          onClick={props.firstHandler}
          >{t("sidebar.primera")}</a>


        </div>

        {/* boton pagina anterior */}
        <div className="mr-2">
          <a href="#0"  aria-label='lleva a pagina anterior'  className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 hover:text-purple shadow-sm " 
          onClick={props.prevHandler}>

            <span className="sr-only" >Previous</span><wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
            </svg>
          </a>

        </div>
    {/*botones para paginar*/}

        <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
      


          {/* boton puntos */}
          {props.currentpage > 1 ?
            <li>
              <a  aria-label='hay mas paginas'  className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 text-decoration-none hover:text-purple" href="#0"
              onClick={props.pointFirst}
              >...</a>
            </li>
            :
            ""
          }


          {/* boton pagina anterior actual */}
          {props.currentpage < 1 ?
            ""
            :
            <li>
              <a  aria-label='pagina anterior actual' className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 text-decoration-none hover:text-purple" 
              onClick={props.prevHandler} href="#0">
                {props.currentpage}</a>
            </li>
          }
          {/* boton pagina actual */}
          <li className='sec-green-background'>
            <a  aria-label='pagina actual'  className="inline-flex items-center justify-center leading-5 px-3.5 py-2  text-white" href="#0">
              {props.currentpage + 1}</a>
          </li>

          {/* boton paginadespues actual */}

          {props.currentpage >= props.lastPage ?
            ""
            :
            <li>
              <a aria-label='pagina posterior actual' className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 text-decoration-none hover:text-purple" 
              onClick={props.nextHandler} href="#0">

                {props.currentpage + 2}

              </a>
            </li>

          }
          {/* boton puntos */}
          {props.currentpage >= props.lastPage-1 ?

            ""
            :

            <li>
              <a aria-label='hay mas paginas'  className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 text-decoration-none hover:text-purple" href="#0"
                  onClick={props.pointLast}
             >...</a>
            </li>

          }



        </ul>

        


        {/* boton pagina anterior */}
        <div className="ml-2">
          <a  aria-label='pagina siguiente' href="#0" className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white hover:bg-indigo-500 border border-gray-200 text-gray-600 text-decoration-none hover:text-purple shadow-sm " 
          onClick={props.nextHandler}>
            <span className="sr-only" >Next</span><wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
            </svg>
          </a>
        </div>

        {/* boton ultima pagina */}
        <div className="ml-2">
          <a aria-label='pagina ultima'  href="#0" className="inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white  border-gray-200 text-gray-600 shadow-sm text-decoration-none hover:text-purple" 
          onClick={props.lastHandler}>{t("sidebar.ultima")}</a>


        </div>
       
      </nav>

    </div>



  );
}

export default PaginationClassic;