import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function OrganizationsLocations({ countries, selectedInputs, index, handleSetSedes }) {
    const [t, i18n] = useTranslation("global");

    const [formData, setFormData] = useState({
        country: '',
        region: '',
        state: ''
    });

    const [regionsCountry, setRegionsCountry] = useState([]);
    const [statesRegions, setStatesRegions] = useState([]);

    useEffect(() => {
        const country = countries.find(item => item.id == selectedInputs[0]);
        if (!country) return; // Si no se encuentra el padre, retornar
        const countriesWRegions = country.regionList.map(child => ({
            id: child.id,
            name: child.name,
            stateList: child.stateList
        }));

        const sortedCountries = countriesWRegions.slice().sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        setRegionsCountry(sortedCountries);

        const region = sortedCountries.find(item => item.id == selectedInputs[1]);
        if (!region) return; // Si no se encuentra el padre, retornar
        const statesInRegion = region.stateList.map(child => ({
            id: child.id,
            name: child.name
        }));

        const sortedStates = statesInRegion.slice().sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        setStatesRegions(sortedStates);
        setFormData({ ...formData, country: selectedInputs[0], region: selectedInputs[1], state: selectedInputs[2] });

    }, []);


    const handleSelectCountry = (countryID) => {
        const country = countries.find(item => item.id == countryID);
        if (!country) return; // Si no se encuentra el padre, retornar
        const countriesWRegions = country.regionList.map(child => ({
            id: child.id,
            name: child.name,
            stateList: child.stateList
        }));

        const sortedCountries = countriesWRegions.slice().sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        setRegionsCountry(sortedCountries);
        setStatesRegions([]);
        setFormData({ ...formData, country: countryID });
    };

    const handleSelectRegion = (regionID) => {
        const region = regionsCountry.find(item => item.id == regionID);
        if (!region) return; // Si no se encuentra el padre, retornar
        const statesInRegion = region.stateList.map(child => ({
            id: child.id,
            name: child.name
        }));

        const sortedStates = statesInRegion.slice().sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        setStatesRegions(sortedStates);
        setFormData({ ...formData, region: regionID });
    };

    const handleSelectState = (stateID) => {
        setFormData({ ...formData, state: stateID });
        handleSetSedes(index, stateID);
    };

    return (
        <>
            {countries && countries.length > 0 ? (
                <>
               
                    <div className='pb-2 col-12 col-md-6 col-xxl-4'>
                    <label className="block text-sm font-medium mb-1" htmlFor="tipoSede">{t("footPrint.pais")} </label>
                        <select
                            className="form-user-data bg-white  w-full"
                            name="orgBuildingProvince"
                            value={formData.country}
                            onChange={(e) => handleSelectCountry(e.target.value)}
                            required
                        >
                            <option value="">{t("footPrint.seleccionaPais")}</option>
                            {countries.map(v => <option key={v.id} value={v.id}>{v.name}</option>)}
                        </select>
                    </div>
                    <div className='pb-2 col-12 col-md-6 col-xxl-4'>
                    <label className="block text-sm font-medium mb-1" htmlFor="tipoSede">{t("footPrint.region")} </label>
                        <select
                            className="form-user-data bg-white  w-full"
                            name="orgBuildingRegion"
                            value={formData.region}
                            onChange={(e) => handleSelectRegion(e.target.value)}
                            required
                        >
                            <option value="">{t("footPrint.seleccionaRegion")}</option>
                            {regionsCountry.map(v => <option key={v.id} value={v.id}>{v.name}</option>)}
                        </select>
                    </div>
                    <div className='pb-2 col-12 col-md-6 col-xxl-4'>
                    <label className="block text-sm font-medium mb-1" htmlFor="tipoSede">{t("footPrint.estado")} </label>
                        <select
                            className="form-user-data bg-white  w-full"
                            name="orgBuildingState"
                            value={formData.state}
                            onChange={(e) => handleSelectState(e.target.value)}
                            required
                        >
                            <option value="">{t("footPrint.seleccionaState")}</option>
                            {statesRegions.map(v => <option key={v.id} value={v.id}>{v.name}</option>)}
                        </select>
                    </div>
                </>
            ) : (
                <p>{t("footPrint.cargandoProvincia")}...</p>
            )
            }
        </>
    );
}
