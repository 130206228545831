import React, { useContext, useEffect, useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar'
import UserService from '../../../services/UserService';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import Form from "react-bootstrap/Form";
import Banner from '../../../components/utils/Banner';
import toast, { Toaster } from 'react-hot-toast';
import ReactTooltip from "react-tooltip";
import { Spinner } from 'react-bootstrap';


function DashboardExcel() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [uploadView, setUploadView] = useState(false);
  const [fileToUpload, setFileToUpload] = useState();
  const [statusExcelFetch, setStatusExcelFetch] = useState();
  const [emissions, setEmissions] = useState();
  const [sendBtn, setSendBtn] = useState(false);

  const [uploadExcelMessage, setuploadExcelMessage] = useState({
    type: '',
    message: ''
  })

  useEffect(() => {
    if(UserService.getOriginData()!= "excel"){
      window.location.href="/company/dashboardintranet"
    }
  },[])

  const originData = "excelUploaded";

  function updateView(){
    setUploadView(true);
  }

  //Evento que recoge el archivo y guarda si el formato es correcto
  const handleFiles = e => {
    e.preventDefault();
    let file = e.target.files[0];
    if (validateFile(file)) {
      setFileToUpload(file);
    } else {
      e.target.value = null;
      toast.error(t("dashboardExcel.invalido"));
    } 
  }

  //VALIDAR EL TIPO DE ARCHIVO
  const validateFile = (file) => {
    const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/vnd.ms-excel'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (fileToUpload != undefined ){
      setSendBtn(true);

      const formData = new FormData();
      formData.append("file", fileToUpload);

      fetch(`${process.env.REACT_APP_EMISSIONS_EMAIL_EXCEL}`,
      {
        method: 'POST',
        headers: {
          // 'Accept': 'application/file',
          // 'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        },
        body : formData
      }).then((response) => {

        if (response.status == 200){
          setuploadExcelMessage({ ...uploadExcelMessage, type: "success", message: t("account.actualizacion-ok") });
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
          
          fetch(`${process.env.REACT_APP_K_UPDATEPROFILE}${UserService.getUserId()}/origindata`,
            {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${UserService.getToken()}`
              },
              body: JSON.stringify(originData)
            }).then((response) => response.json())
            .then((data) => {
              if (data.originData=="excelUploaded"){
                setTimeout(function () {
                  window.location.href="/company/dashboardintranet";
                }, 2000)
              }
            })
            .catch((error) => {
              console.error('Error:', error);
            });

          

        }else {
          setuploadExcelMessage({ ...uploadExcelMessage, type: "error", message: t("account.actualizacion-ko") })
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        }
        setSendBtn(false);
        return response.json()
      })
      .then((statusExcelFetch) => {
        if (!statusExcelFetch.error) {
          // setRespuestacompesateco2(respuestacompesateco2)
        }
      })

     }else{
      toast.error(t("dashboardExcel.noarchivo"));

     }

  }


  return (

    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden sec-neutro-background">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />


        <main>

            <div className="">

              {UserService.getOriginData()!="excelUploaded" ?
              <>
                <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto sec-neutro-background '>
                    <h1 className='h2 text-center'>{t("dashboardExcel.bienvenida")}👋<br/>{t("dashboardExcel.bienvenida2")}</h1>
                    <div className="mb-8 border-b border-gray-200"/>
                    <div className='text-center'>
                    {uploadView === false ? 
                      <>
                        <p className='px-4 h5'>{t("dashboardExcel.paso1")}</p>
                        <p className='px-6'>{t("dashboardExcel.paso2")}</p>
                        <p className='px-6'  id="divbanner">{t("dashboardExcel.minutos")}</p>
                        <br/>
                        
                          <div className='flex justify-center mb-2'>
                              <button  className='btn sec-green-background' onClick={() => updateView()}>
                                  <span className='pl-2 text-sm text-white font-bold'>{t("dashboardExcel.start")}</span>
                              </button>
                          </div>
                      </>
                        :
                          <div className='mb-2'>
                              {uploadExcelMessage.type != "" && <><Banner className="text-center" width="50%" type={uploadExcelMessage.type} open setOpen={true}>{uploadExcelMessage.message}</Banner><br/></>}
                              <Form className='text-center' onSubmit={handleSubmit}>
                                  <h1>{t("dashboardExcel.ficheros1")}<span data-tip data-for="typefile">{t("dashboardExcel.ficheros2")}</span>
                                  <ReactTooltip id="typefile" place="top" effect="solid" clickable>.xlsx .xls .csv</ReactTooltip>
                                  </h1>
                                  <br/>
                                  <input type="file" id="uploadfile" accept='.csv, .xls, .xlsx' className='form-control form-input' onChange={(e) => handleFiles(e)}/>
                                  <br/><br/>
                                  {sendBtn ? 
                                    <button className='btn sec-green-background' type="submit" >
                                      <Spinner animation="border" variant="light" className='flex justify-center' disabled/>
                                    </button>
                                  :
                                    <button className='btn sec-green-background' type="submit" >{t("dashboardExcel.enviar")}</button>
                                }
                              </Form>
                          </div>
                        }

                    </div >
                </div>
                <div className='float-right'>
                  <img src="/arbol3.svg" />
                </div>
              </>

              :
              
              window.location.href="/company/dashboardintranet"
            }
            
          </div>
          {/* PopUp para archivos invalidos */}
          <Toaster position="bottom-center"reverseOrder={false}/>
        </main>

      </div>

    </div>



  );

}

export default DashboardExcel;