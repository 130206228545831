import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Spinner } from "react-bootstrap";
import { Form, FormGroup, Input, Label } from "reactstrap";


export default function ByEmail({ externalQuestionnaire, handleSetContactData, t }) {
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [searchingUser, setSearchingUser] = useState(false);
    const [checkContactRespose, setCheckContactRespose] = useState(undefined)
    const [email, setEmail] = useState('');
    const cancelTokenSource = useRef(null); //Para evitar errores

    useEffect(() => {
        if (externalQuestionnaire?.idContact !== undefined) {
            fetchValidateEmail();
        }

        return () => {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel('Componente desmontado');
            }
        };
    }, [externalQuestionnaire?.idContact])


    const checkContact = (e) => {
        e.preventDefault();
        setSearchingUser(true);

        // Cancelar la solicitud anterior si existe
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Solicitud anterior cancelada');
        }

        // Crear un nuevo token de cancelación
        cancelTokenSource.current = axios.CancelToken.source();

        fetchValidateEmail(cancelTokenSource.current.token) // Pasar el token a fetchValidateEmail
            .then(response => {
                if (response.data.data.validated) {
                    handleSetContactData(response.data.data);
                }
                setCheckContactRespose(response);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.info('Solicitud cancelada');
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                setSearchingUser(false);
                cancelTokenSource.current = null; // Limpiar la referencia al token
            });
    };

    const fetchValidateEmail = (cancelToken) => {
        let url = process.env.REACT_APP_CH_CONTACTS + `/external/contact?idExternalQuestionnaire=${externalQuestionnaire?.idExternal}`;

        if (externalQuestionnaire?.idContact != undefined) {
            url += `&idContact=${externalQuestionnaire.idContact}`;
        } else {
            url += `&email=${email}`;
        }
        try {
            return axios.get(`${url}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                cancelToken: cancelToken
            }).then(response => {
                return response;
            });
        } catch (exception) {
            console.error(exception)
        }
    }

    return (
        <div className="w-full max-w-9xl mx-auto  text-justify">
            {checkContactRespose === undefined && <>

                {searchingUser &&
                    <div className="text-center">
                        <Spinner animation="border" variant="dark" ></Spinner>
                        <div> {t("fpThird.getUserInfo")}</div>
                    </div>
                }

                {!searchingUser && <>
                    <div className="mb-8 pt-8 text-gray-800 text-center px-1">
                        <p className="text-md md:text-xl font-bold mb-2">
                            {t("fpThird.indicaEmail")}
                        </p>
                        <p className="text-md md:text-xl font-bold mb-2">
                            {t("fpThird.validarEmail")}
                        </p>
                    </div>

                    <Form className='text-md font-bold max-w-xl m-auto' onSubmit={(e) => checkContact(e)}>
                        <FormGroup>
                            <Label for="email">{t("fpThird.email")}</Label>
                            <Input className='' type="email" name="email" id="email" defaultValue={email} onChange={(e) => { setEmail(e.target.value) }} required></Input>
                        </FormGroup>
                        <div className='flex items-center'>
                            <input type="checkbox" className="text-black" required onChange={e => { setButtonDisabled(!e.target.checked) }} />
                            <span className="text-xs ml-2 ">{(t("landingEventos.leidoYacepto"))} <a rel="noopener noreferrer" target='_blank' href="/terminos">{(t("landingEventos.legales"))}</a><span className="text-red-500" >*</span></span>
                        </div>
                        <div className='flex justify-center' style={{ marginTop: '10vh' }}>
                            <Button className="btn-green  rounded-lg px-4 py-2" type="submit" disabled={buttonDisabled}>{t("fpThird.continuar")}</Button>
                        </div>
                    </Form>
                </>}
            </>}

            {checkContactRespose !== undefined && !checkContactRespose.validated && <>
                <div className="mb-8 text-gray-800 text-center px-1 pt-16">
                    <p className="text-md md:text-xl font-bold mb-2">{t("fpThird.revisaCorreo")}</p>
                    <div className='flex justify-center' style={{ marginTop: '4rem' }}>
                        <Button className="btn-green  rounded-lg px-4 py-2" type="submit" onClick={() => fetchValidateEmail()}>{t("fpThird.recargar")}</Button>
                    </div>
                </div>
            </>}



        </div >)
}