import format from 'date-fns/format';
import { default as React, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "./../../../context/createcontext";
import LineChart from './../charts/LineChart01';
// Import utilities
import { formatThousands, hexToRGB, tailwindConfig } from './../utils/Utils';

function GraficaHuellaGenerada({ data }) {

  const categoryMonthData = data;

  // const categoryMonthData = catsgoryMonthData.map((x) => {
  //   if (x.category==null) {
  //     return x.month;
  //   }
  // })

  //Meses únicos
  const monthList = categoryMonthData.map((x) => {
    return x.month;
  })
  const uniqueMonths = monthList.filter((elemento, index, self) => {
    return index === self.indexOf(elemento)
  });
 

  //Array de fechas para las Labels
  const currentYear = new Date().getFullYear();

  const monthsLabel = uniqueMonths.map((x) => {
    return format(new Date(currentYear, x - 1, '01'), 'MM/dd/yyyy');
  })


  //CO2 total por mes
  const totalCo2xMonthFiltered = categoryMonthData.filter((element) => {
    return element.category == null;
  }).map((x) => {
    for (let i = 0; i < uniqueMonths.length; i++) {
      if (x.category == null && x.month == uniqueMonths[i]) {
        return x.co2 / 1000;
      }
    }
  });



  const totalco2 = totalCo2xMonthFiltered.reduce((acumulador, numero) => acumulador + numero, 0);

  const chartData = {
    labels: monthsLabel,
    datasets: [
      // Indigo line
      {
        data: totalCo2xMonthFiltered,
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        clip: 20,
      },
    ],
  };
  // cambiar idioma

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  return (
    <div id="ghuellagenerada" className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white rounded-sm border border-gray-200">
      <div className="px-5 pt-5">
      
        <h2 className="text-lg font-semibold text-gray-800 mb-2">{t("dash.huellagenerada")} {new Date().getFullYear()}</h2>
        <div className="flex items-end">
          <div className="text-3xl font-bold text-gray-800 mr-2">{formatThousands(totalco2)} t</div>
          <div className="text-xs font-semibold text-gray-400 uppercase mb-1">CO<sub>2</sub></div>


        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        {
          uniqueMonths.length == 0 ? <Spinner /> : <LineChart data={chartData} width={389} height={128} unit={" t"} />
        }
      </div>
    </div>
  );
}

export default GraficaHuellaGenerada;
