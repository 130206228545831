import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import sello1 from '../../../images/sello1.png';
import sello2 from '../../../images/sello2.png';
import sello3 from '../../../images/sello3.png';
import sello4 from '../../../images/sello4.png';
import { useNavigate } from 'react-router-dom';


export default function IntroMiteco() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    return (

        <div className={`sec-neutro-background flex h-screen overflow-hidden`}>

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <button arial-label="btn-saveFom" className="ml-4 mt-2 text-base font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                    </svg>
                    <span>{t("footPrint.volver")}</span>
                </button>
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {/* Page header */}
                        <div className="mb-8">
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Registro de la huella de carbono del MITECO</h1>
                        </div>
                        <h2 className='text-lg md:text-xl text-gray-800 font-semibold'>¿Qué es el registro de Huella de Carbono del MITECO?</h2>
                        <p>El registro de huella de carbono del MITECO es una plataforma oficial donde las empresas y organizaciones pueden reportar sus emisiones de gases de efecto invernadero. Las empresas que logran inscribirse reciben un reconocimiento oficial que garantiza que la información reportada cumple con ciertos estándares y criterios de rigor validados por el MITECO. Como resultado, las organizaciones obtienen un sello oficial que acredita su compromiso con la medición y, en su caso, la reducción de su huella de carbono.</p>

                        <div className='bg-white rounded-lg mt-4 py-4 px-2'>
                            <h2 className='text-lg md:text-xl text-gray-800 font-semibold mb-4 px-4'>
                                ¿Qué puedo conseguir con ello?
                            </h2>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 sellos-miteco'>
                                <div className=' '>
                                    <div className=''>
                                        <img src={sello1} className='' alt="Sello cálculo" />
                                    </div>
                                    <p className='text-sm'>
                                        <b className='text-md'>Sello cálculo.</b> Las empresas con este sello han calculado su huella de carbono (mínimo alcances 1 y 2) y han definido unos compromisos de reducción. Estos cálculos han sido validados por el MITECO.
                                    </p>
                                </div>

                                <div className=''>
                                    <div className=''>
                                        <img src={sello2} className='' alt="Sello cálculo + reduzco" />
                                    </div>
                                    <p className='text-sm'>
                                        <b className='text-md'>Sello cálculo + reduzco.</b> Las empresas han logrado demostrar que han calculado su huella de carbono y han logrado reducir sus emisiones con respecto a su primer año calculado. Tanto el cálculo como las reducciones han sido validadas por el MITECO.
                                    </p>
                                </div>

                                <div className=''>
                                    <div className=''>
                                        <img src={sello3} className='' alt="Sello cálculo + compenso" />
                                    </div>
                                    <p className='text-sm'>
                                        <b className='text-md'>Sello cálculo + compenso.</b> La empresa ha logrado calcular su huella de carbono y ha compensado sus emisiones (total o parcialmente) con un proyecto inscrito en la sección de “proyectos de absorción de CO2” o, mediante otras reducciones de emisiones de gases de efecto invernadero reconocidas por el miteco. Tanto los cálculo como la transacción emisiones-absorciones (compra de créditos de carbono para la compensación de emisiones) han sido validadas por el MITECO.
                                    </p>
                                </div>

                                <div className=''>
                                    <div className=''>
                                        <img src={sello4} className='' alt="Sello cálculo, reduzco y compenso" />
                                    </div>
                                    <p className='text-sm'>
                                        <b className='text-md'>Sello cálculo, reduzco y compenso.</b> Este sello demuestra que la empresa ha calculado su huella de carbono para un año determinado, ha logrado una reducción de sus emisiones y ha compensado sus emisiones (total o parcialmente) mediante un proyecto aprobado por el MITECO. Los cálculos, reducciones y compensación de las emisiones han sido validadas por el MITECO.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='mt-4 flex justify-center'>
                            <button className='btn btn-green ' onClick={() => navigate('/footprint/certification/new')} >
                                Empezar registro
                            </button>
                        </div>

                    </div>
                </main>
            </div>
        </div>
    )
}