import logo from '../../../images/Logo verde_airCO2.png'

export default function AirCO2Spinner({ title, info }) {
    return (<div className=" h-screen flex justify-center items-center sec-neutro-background ">
        <div className="relative ">
            <img src={logo} alt="logo" className='mx-auto' style={{ width: "323px" }} />

            <div className="text-center text-xl absolute font-semibold sec-grey-text mb-4" style={{ top: "150px", left: "85px" }}>
                {title}
            </div>

            <div className="flex justify-center items-center absolute" style={{ top: "85px", left: "191px" }}>
                <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status">
                    <span className="visually-hidden">{info}</span>
                </div>
            </div>
        </div>
    </div>)
}