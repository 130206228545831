import format from 'date-fns/format';
import { default as React, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../context/createcontext";
import BarChart from './../charts/BarChart03';
import Translations from '../../../services/Translations';

// Import utilities
import { tailwindConfig } from './../utils/Utils';

function GraficaCategoryMonth({ data }) {
  const categoryMonthData = data;


  //Categorias únicas
  const categoryList = categoryMonthData.map((x) => {
    return x.category;
  })
  const uniqueCategories = categoryList.filter((elemento, index, self) => {
    return index === self.indexOf(elemento) && elemento !== null
  });


  //Meses únicos
  const monthList = categoryMonthData.map((x) => {
    return x.month;
  })
  const uniqueMonths = monthList.filter((elemento, index, self) => {
    return index === self.indexOf(elemento)
  });
 

  //Array de fechas para las Labels
  const currentYear = new Date().getFullYear();

  const monthsLabel = uniqueMonths.map((x) => {
    return format(new Date(currentYear, x - 1, '01'), 'MM/dd/yyyy');
  })



  const objCo2ArrayCategory = new Array(uniqueCategories.length);

  for (let i = 0; i < uniqueCategories.length; i++) {
    let arrayco2categoriaxmes = new Array(uniqueMonths.length).fill(0);
    // debugger;
    categoryMonthData.map((emision) => {

      if (uniqueCategories[i] == emision.category) {

        for (let x = 0; x < uniqueMonths.length; x++) {
          if (uniqueMonths[x] == emision.month) {
            arrayco2categoriaxmes[x] += emision.co2 / 1000;
          }
        }
        objCo2ArrayCategory[i] =
        {
          co2: arrayco2categoriaxmes,
          category: uniqueCategories[i]
        }
       

      }
    })
  }
  // cambiar idioma

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
 

  const nameCategory = uniqueCategories.map((v) => {
    return Translations.translateCategories(v)
  })

  



  const bgcolors = [tailwindConfig().theme.colors.indigo[700], tailwindConfig().theme.colors.orange[400], tailwindConfig().theme.colors.gray[500]];
  const hbgcolors = [tailwindConfig().theme.colors.indigo[800], tailwindConfig().theme.colors.orange[500], tailwindConfig().theme.colors.gray[600]];
  var cont = -1;

  const datasetInfo = uniqueCategories.map((x) => {
    cont += 1;
    return (
      {
        // label: objCo2ArrayCategory[cont].category.charAt(0).toUpperCase() + objCo2ArrayCategory[cont].category.slice(1).toLowerCase(),

        label: t(nameCategory[cont]),

        data: objCo2ArrayCategory[cont].co2,
        backgroundColor: bgcolors[cont],
        hoverBackgroundColor: hbgcolors[cont],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      }
    );
  })


  const chartData = {
    labels: monthsLabel,
    datasets: datasetInfo,
  };



  return (
    <div id="gcategorymonth" className="flex flex-col col-span-full sm:col-span-6 bg-white rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">{t("dash.toneladasmes")}</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      {
        uniqueCategories.length === 0 ? <Spinner className='flex justify-center' /> : <BarChart data={chartData} width={595} height={248} unit={" t"} />
      }

    </div>
  );
}

export default GraficaCategoryMonth;
