
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import AnswerTable from './AnswerTable';
import ModalDescriptionInstrucctions from './ModalDescriptionInstrucctions';
import QuestionButtons from './QuestionButtons';
import QuestionInputs from './QuestionInputs';
import QuestionIntro from './QuestionIntro';
import QuestionsList from './QuestionsList';
import FinishedForm from './FinishedForm';
export default function QuestionWizard({ externalQuestionnaire, deleteCurrentQuestion, updateShowQuestionListButton, event }) {
    const [t, i18n] = useTranslation("global");

    const [dataExternal, setDataExternal] = useState({
        data: undefined,
        isFetching: true
    });

    const [currentQuestion, setCurrentQuestion] = useState({
        questionnaireId: undefined,
        id: undefined,
        title: undefined,
        description: undefined,
        instructions: undefined,
        answers: undefined,
        idQuestionToExternalData: undefined,
        showIntro: true,
        isExernalData: undefined
    });
    const [selectedFormat, setSelectedFormat] = useState('inputs');
    const [showAnswerTable, setShowAnswerTable] = useState(false)

    const updatedSelectedFormat = (format) => {
        setSelectedFormat(format);
    }
    const updateShowQuestionAndExternalData = (externalData) => {
        setCurrentQuestion((prev) => ({ ...prev, showIntro: false, isExernalData: externalData }));
    }
    const updatedShowAnswerTable = (show) => {
        setShowAnswerTable(show);
    }
    const updatedDataExternal = (data) => {
        setDataExternal({
            data: data,
            isFetching: false,
        });
    }

    const fetchDataExternal = useCallback(async () => {
        setDataExternal((prev) => ({ ...prev, isFetching: true }));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/public/${externalQuestionnaire?.idExternal}/contact/${externalQuestionnaire?.idContact}`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );
            setDataExternal({
                data: response.data,
                isFetching: false,
            });
            setCurrentQuestion((prev) => ({ ...prev, questionnaireId: response.data.data.id }));

            if (response.data?.data?.totalKgCO2e !== undefined) {
                setFinished({
                    state: true, data: { contactId: externalQuestionnaire?.idContact, externalQuestionnaireId: externalQuestionnaire.id, finalized: true, totalKgCO2e: response.data?.data.totalKgCO2e },
                    spinner: false
                });
            }
        } catch (exception) {
            console.error(exception);
            setDataExternal({ data: [], isFetching: false });
        }
    }, [externalQuestionnaire?.idExternal, externalQuestionnaire?.idContact]);

    useEffect(() => {
        if (externalQuestionnaire?.idContact) {
            fetchDataExternal();
        }
    }, [externalQuestionnaire?.idContact, fetchDataExternal]);

    const handleGoQuestions = (question, showIntro, index) => {
        updateShowQuestionListButton(true)
        setCurrentQuestion((prev) => ({
            ...prev,
            id: question.id,
            title: question.name,
            description: question.description,
            instructions: question.instructions,
            answers: question.answersByContact,
            idQuestionToExternalData: question.idQuestionToExternalData || '',
            showIntro: showIntro,
            currentIndex: index,
        }));
    }


    useEffect(() => {
        if (deleteCurrentQuestion) {
            setCurrentQuestion({});
            updateShowQuestionListButton(false);
            setShowAnswerTable(false);
        }
    }, [deleteCurrentQuestion]);

    const [savedAnswerResponse, setSavedAnswerResponse] = useState({})
    const updateSavedAnswerResponse = (savedResponse) => {
        setSavedAnswerResponse(savedResponse);
    }

    const [showModalInstructions, setShowModalInstructions] = useState(false)
    const handleCloseModal = () => {
        setShowModalInstructions(false);
    };

    const [finished, setFinished] = useState({ state: false, data: undefined, spinner: false });
    const finishContactQuestionnaire = async () => {
        setFinished({ state: true, data: finished.data, spinner: true });


        let url = process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE + `/${externalQuestionnaire?.idExternal}/contact/${externalQuestionnaire.idContact}/finishQuestionnaire`;

        try {
            const response = await axios.patch(`${url}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            setFinished({ state: true, data: response.data.data, spinner: false });
        } catch (exception) {
            console.error(exception)
        }
    }

    const handleFinishContactQuestionnaire = () => {
        finishContactQuestionnaire();
    }



    return (

        <div className="px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto flex flex-col">
            <ModalDescriptionInstrucctions
                show={showModalInstructions}
                handleClose={handleCloseModal}
                descriptionQuestion={currentQuestion.description}
                instructionsQuestion={currentQuestion.instructions} />


            {dataExternal.isFetching &&
                <div className='text-center mt-5'>
                    <Spinner animation="border" variant="dark" ></Spinner>
                    <p>{t("footPrint.chargequestions")}</p>
                </div>}


            {!dataExternal.isFetching && dataExternal.data !== undefined &&
                <div>
                    {!finished.state && <>
                        {//PREGUNTAS CON INPUTS 
                            ((currentQuestion?.id && !currentQuestion?.showIntro) || showAnswerTable) &&
                            <div className='mb-4 font-semibold text-xl flex-wrap flex gap-2 items-end ' >
                                <span className=''>{currentQuestion.currentIndex + 1}. {currentQuestion.title ? currentQuestion.title : ''}</span>
                                <span onClick={() => setShowModalInstructions(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                        <path d="M12 9h.01" />
                                        <path d="M11 12h1v4h1" />
                                    </svg>
                                </span>
                            </div>
                        }
                        {currentQuestion?.id === undefined &&
                            <QuestionsList
                                externalQuestionnaire={externalQuestionnaire}
                                questions={dataExternal.data?.data?.externalQuestionsDto}
                                onQuestionClick={handleGoQuestions}
                                updatedShowAnswerTable={updatedShowAnswerTable}
                                t={t}
                            />
                        }

                        {!showAnswerTable && currentQuestion?.id !== undefined && <>
                            {currentQuestion?.showIntro && <>
                                <QuestionIntro
                                    question={currentQuestion}
                                    updateShowQuestionAndExternalData={updateShowQuestionAndExternalData}
                                    formatToAnswer={selectedFormat}
                                    updatedSelectedFormat={updatedSelectedFormat}
                                    updatedShowAnswerTable={updatedShowAnswerTable}
                                    t={t} />
                            </>}

                            {!currentQuestion?.showIntro && <>
                                <QuestionInputs
                                    externalQuestionsDto={dataExternal.data?.data?.externalQuestionsDto}
                                    question={currentQuestion}
                                    selectedFormat={selectedFormat}
                                    updatedSelectedFormat={updatedSelectedFormat}
                                    externalQuestionnaire={externalQuestionnaire}
                                    updateSavedAnswerResponse={updateSavedAnswerResponse}
                                    handleGoQuestions={handleGoQuestions}
                                    dataExternal={dataExternal}
                                    updatedDataExternal={updatedDataExternal}
                                    updatedShowAnswerTable={updatedShowAnswerTable}

                                    t={t} />
                            </>}
                        </>}


                        {showAnswerTable &&
                            <AnswerTable
                                questions={dataExternal.data?.data?.externalQuestionsDto}
                                idQuestion={currentQuestion?.id}
                                idExternal={externalQuestionnaire?.idExternal}
                                titleQuestion={currentQuestion?.title}
                                idContact={externalQuestionnaire?.idContact}
                                currentIndexQuestion={currentQuestion?.currentIndex}
                                answerTable={showAnswerTable}
                                setQuestions={updatedDataExternal}
                            />
                        }

                        {currentQuestion?.id !== undefined &&
                            <QuestionButtons
                                handleGoQuestions={handleGoQuestions}
                                currentQuestion={currentQuestion}
                                externalQuestionsDto={dataExternal.data?.data?.externalQuestionsDto}
                                externalQuestionnaire={externalQuestionnaire}
                                showFinish={showAnswerTable}
                                handleFinishContactQuestionnaire={handleFinishContactQuestionnaire}
                                t={t}>
                            </QuestionButtons>
                        }
                    </>}
                    {finished.state &&
                        (finished.spinner ?
                            <div className='text-center mt-5'>
                                <Spinner animation="border" variant="dark" ></Spinner>
                                <p>{t("fpThird.finalizandoCuestionario")}</p>
                            </div>
                            :
                            <FinishedForm
                                data={finished.data}
                                url={externalQuestionnaire.organizationWeb}
                                isEvent={event}>
                            </FinishedForm>
                        )
                    }
                </div>
            }



        </div >



    )
}