import React, {useContext } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { formatThousands } from '../utils/Utils';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "./../../../context/createcontext"



function Progress(props) {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const generado = props.generado
  const compensado= props.compensado

  const porcentaje = compensado/generado *100
  const porCifra = porcentaje.toFixed(2)
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
    {t("dash.llevascompensado")} {formatThousands(porCifra)} % {t("dash.deltotal")}
    </Tooltip>
  );
  

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
    <ProgressBar animated now={porcentaje} label={`${formatThousands(porCifra)}%`}  /> 
    </OverlayTrigger>
  );
}


export default Progress;

