import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
import icon7 from '../../../images/icono07.svg'
import icon8 from '../../../images/icono08.svg'
import icon9 from '../../../images/icono09.svg'
import icon10 from '../../../images/4.png'

export default function BloqueNeutraliza() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className=' max-w-6xl mx-auto px-2 sm:px-3 mt-5 mb-5 '>


            <div className='text-airco2-general text-center mb-5 mt-5'>
                <h3 className=" h2 text-airco2-principal font-bold m-3 text-4xl " data-aos="fade-up" data-aos-delay="100" >  {t("landingEventos.neutralizarHuella")}
                </h3>
                <h4 className='text-3xl m-3 '>
                    {t("landingEventos.mayorImpacto")}
                </h4>

            </div>

            <div className=' flex  justify-center'>
                <div className='div-m-items flex flex-wrap w-70 '>

                    {/* item-1 */}
                    <div className='item-pasos w-full md:w-1/2 p-items'>
                        <div className='flex flex-column  mb-2' data-aos="fade-left" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]" >
                            <img src={icon7} width="44" height="44" alt="imagen" />
                            <p className='text-airco2-principal text-xl font-bold'> {t("landingEventos.bosqueLocal")}</p>

                        </div>
                        <div className='' data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]" >
                            <p className='text-airco2-general'> {t("landingEventos.tipoCompensacion")}</p>

                        </div>

                    </div>


                    {/* item-2 */}
                    <div className='item-pasos w-full md:w-1/2 p-items'>
                        <div className='flex flex-column  mb-2' data-aos="fade-left" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]" >
                            <img src={icon8} width="44" height="44" alt="imagen" />
                            <p className='text-airco2-principal text-xl font-bold'> {t("landingEventos.neutralizaBosque")}</p>


                        </div>
                        <div className='' data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]" >
                            <p className='text-airco2-general'>{t("landingEventos.sumaAcciones")}</p>
                        </div>
                    </div>
                    {/* item-3 */}
                    <div className='item-pasos w-full md:w-1/2 p-items'>
                        <div className='flex flex-column  mb-2' data-aos="fade-left" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]" >
                            <img src={icon9} width="44" height="44" alt="imagen" />
                            <p className='text-airco2-principal text-xl font-bold'> {t("landingEventos.implantaCertificado")}</p>


                        </div>
                        <div className='' data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]" >
                            <p className='text-airco2-general'> {t("landingEventos.empresaSostenible")}</p>
                        </div>
                    </div>
                    {/* item-4 */}
                    <div className='item-pasos w-full md:w-1/2 p-items'>
                        <div className='flex flex-column  mb-2' data-aos="fade-left" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]" >
                            <img src={icon10} width="44" height="44" alt="imagen" />
                            <p className='text-airco2-principal text-xl font-bold'> {t("landingEventos.beneficiaImpacto")}</p>


                        </div>
                        <div className='' data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]" >
                            <p className='text-airco2-general'> {t("landingEventos.marcaVerde")}.</p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}
