import { useState } from "react";
import Footer from "../../../partials/Footer";
import Header from "../../../partials/Header";
import BannerDemo from "../componentsHome/BannerDemo";
import Download from "./Download";

export default function DownloadPage() {

    const [name, setName] = useState("");

    const handleUpdateName = (name) => {
        setName(name)
    }

    return (<>
        <Header name={name}></Header>
        <Download handleUpdateName={handleUpdateName}></Download> 
        <BannerDemo></BannerDemo>
        <Footer></Footer>
    </>
    )
}