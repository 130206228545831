import React, { useState, useEffect } from 'react'
import Header from '../../partials/Header'
import Footer from '../../partials/Footer'
import pontevedra1 from '../../images/pontevedra1.webp';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from '../../context/createcontext';
import { useContext } from 'react'
import logo from '../../images/Concello_Pegada07.png';
export default function ConcelloPontevedra() {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const color = 'RGBA(135, 135, 135, 0.7)';
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState();
    const [consent, setConsent] = useState(false);
    const [submitForm, setSubmitForm] = useState({ data: undefined, isFetching: false });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        const defaultLanguage = 'gl';

        if (i18n.language !== defaultLanguage) {
            i18n.changeLanguage("gl");
            updateLng("gl");
            document.documentElement.lang = "gl";
        }
    }, [i18n, updateLng]);

    const handleSubmit = (e) => {

        e.preventDefault();

        const fetchUserInformation = async () => {
            try {
                setSubmitForm({ success: undefined, isFetching: true });

                const response = await axios.post(`${process.env.REACT_APP_PUBLIC_FORM_LANDING}?email=${email}&name=${name}&typeCompany=${company}&phone=${phone}`, {}, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }).then((response) => {
                    setSubmitForm({ success: response?.data?.success, isFetching: false });
                });
            } catch (exception) {
                setSubmitForm({ success: undefined, isFetching: false });
                console.error(exception);
            }
        };

        fetchUserInformation();
    }

    return (
        <div>
            <Header></Header>
            <div className='container-concello-pontevedra' style={{ paddingTop: '95px', minHeight: '90vh' }}>
                <div className={`text-white`} style={{ backgroundImage: `url(${pontevedra1})`, backgroundSize: 'cover' }} >
                    <div className='container flex flex-column items-center h-full'>
                        <div className="">
                            <div className="max-w-3xl text-white mx-auto text-center pt-4 " >
                                <h1 className="text-white text-landing-title" data-aos="fade-up" data-aos-delay="100">
                                    Pontevedra Nature Positive
                                </h1>

                                <h2 className="text-2xl mt-4 font-semibold mb-6" data-aos="fade-up" data-aos-delay="200">
                                    {t("concellosNaturePositive.pontevedraNaturePositive")}
                                </h2>
                                <div className="form-link mb-6" data-aos="fade-in" data-aos-delay="400">
                                    <a href="#formulario" className=" btn sec-green-background text-airco2-filter-content  whitespace-nowrap lg:w-1/2 " >
                                        <span style={{ color: 'white', textDecoration: 'none' }}>{t("landingEventos.interesa")}</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className=' container pb-2 pr-4 items-center justify-center' data-aos-id-blocks >
                            <div className='flex gap-1  mb-4'>
                                <div className='p-2 rounded-lg flex items-center' style={{ backgroundColor: color }} data-aos="fade-left" data-aos-delay="400" data-aos-anchor="[data-aos-id-blocks]">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-bag" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M6.331 8h11.339a2 2 0 0 1 1.977 2.304l-1.255 8.152a3 3 0 0 1 -2.966 2.544h-6.852a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304z" />
                                        <path d="M9 11v-5a3 3 0 0 1 6 0v5" />
                                    </svg>
                                </div>
                                <div className='p-2 rounded-lg flex items-center w-full' style={{ backgroundColor: color }} data-aos="fade-right" data-aos-delay="400" data-aos-anchor="[data-aos-id-blocks]">
                                    <p><b>{t("concellosNaturePositive.comercioNP")}</b>{t("concellosNaturePositive.comercioNPText")}</p>
                                </div>
                            </div>
                            <div className='flex gap-1  mb-4'>
                                <div className='p-2 rounded-lg flex items-center' style={{ backgroundColor: color }} data-aos="fade-left" data-aos-delay="500" data-aos-anchor="[data-aos-id-blocks]">

                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chef-hat" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M12 3c1.918 0 3.52 1.35 3.91 3.151a4 4 0 0 1 2.09 7.723l0 7.126h-12v-7.126a4 4 0 1 1 2.092 -7.723a4 4 0 0 1 3.908 -3.151z" />
                                        <path d="M6.161 17.009l11.839 -.009" />
                                    </svg>
                                </div>
                                <div className='p-2 rounded-lg flex items-center w-full' style={{ backgroundColor: color }} data-aos="fade-right" data-aos-delay="500" data-aos-anchor="[data-aos-id-blocks]">
                                    <p><b>{t("concellosNaturePositive.restaurantesNP")}</b>{t("concellosNaturePositive.restaurantesNPText")}</p>
                                </div>
                            </div>
                            <div className='flex gap-1  mb-4'>
                                <div className='p-2 rounded-lg flex items-center' style={{ backgroundColor: color }} data-aos="fade-left" data-aos-delay="600" data-aos-anchor="[data-aos-id-blocks]">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-bank" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 21l18 0" />
                                        <path d="M3 10l18 0" />
                                        <path d="M5 6l7 -3l7 3" />
                                        <path d="M4 10l0 11" />
                                        <path d="M20 10l0 11" />
                                        <path d="M8 14l0 3" />
                                        <path d="M12 14l0 3" />
                                        <path d="M16 14l0 3" />
                                    </svg>
                                </div>
                                <div className='p-2 rounded-lg flex items-center w-full' style={{ backgroundColor: color }} data-aos="fade-right" data-aos-delay="600" data-aos-anchor="[data-aos-id-blocks]">
                                    <p><b>{t("concellosNaturePositive.huellaConcello")}</b>{t("concellosNaturePositive.huellaConcelloText")}</p>
                                </div>
                            </div>
                            <div style={{ maxHeight: '200px' }} className='flex items-center justify-center' data-aos="fade-up" data-aos-delay="500" data-aos-anchor="[data-aos-id-blocks]">
                                <img width="150" height="200" src={logo} alt='logo pontevedra airco2' />
                            </div>
                        </div>
                    </div>
                </div>

                {submitForm.success == undefined &&

                    <div className='registro container flex items-center justify-center '>
                        <div className='' id='formulario'>
                            <div>
                            </div>
                            <div className='text-airco2-general p-3' data-aos="fade-up"
                                data-aos-delay="400">
                                <h3 className='text-xl text-airco2-grey-general font-semibold mt-2 mb-4'>{t("concellosNaturePositive.inscribete")}</h3>
                                <form onSubmit={handleSubmit}>
                                    <div className='flex flex-wrap'>
                                        <div className='w-full pr-1 mt-1 mb-1'>
                                            <label className="block text-sm font-medium mb-1" htmlFor="name">{t("concellosNaturePositive.nombre")} <span className="text-red-500">*</span></label>
                                            <input id="name" className="form-user-data w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap'>

                                        <div className='w-full pr-1 mt-1 mb-1'>
                                            <label className="block text-sm font-medium mb-1" htmlFor="phone">{t("concellosNaturePositive.telefono")}<span className="text-red-500">*</span></label>
                                            <input id="phone" className=" w-full bg-white form-user-data" type="tel" pattern="[0-9]{7}|[0-9]{9}" defaultValue={''} required onChange={(e) => { setPhone(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap  '>
                                        <div className='w-full pr-1 mt-1 mb-1 '>
                                            <label className="block text-sm font-medium mb-1" htmlFor="email">{t("concellosNaturePositive.email")}<span className="text-red-500">*</span></label>
                                            <input id="email" className=" w-full bg-white form-user-data" type="email" defaultValue={''} required onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap'>
                                        <div className='w-full pr-1 mt-1 mb-1 '>
                                            <label className="block text-sm font-medium mb-1" htmlFor="company">{t("concellosNaturePositive.tipoNegocio")}<span className="text-red-500">*</span></label>
                                            <select id="company" className=" w-full bg-white form-user-data" type="text" defaultValue={''} required onChange={(e) => { setCompany(e.target.value) }} >
                                                <option value="">{t("concellosNaturePositive.selecciona")}</option>
                                                <option value="comercio">{t("concellosNaturePositive.comercio")}</option>
                                                <option value="restaurante">{t("concellosNaturePositive.restaurante")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="flex items-center font-medium mt-2">
                                            <input type="checkbox" className="form-checkbox" required onChange={e => { setConsent(e.target.checked) }} />
                                            <span className="text-sm ml-2">{t("auth.conditions")} <a href="/terminos">{t("auth.terms")}</a><span className="text-red-500" >*</span></span>
                                        </label>
                                    </div>
                                    <div className=" mt-6">
                                        <button disabled={submitForm.isFetching} className="mb-4 md:mb-0 btn sec-green-background text-white whitespace-nowrap w-full" type="submit" >
                                            {submitForm.isFetching ? <Spinner animation="border" variant="light" /> : t("concellosNaturePositive.enviar")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
                {submitForm.success !== undefined &&
                    (submitForm.success ?
                        <div className='registro container flex items-center justify-center flex-col  mb-4  ' >
                            <p className="text-3xl mb-8 mt-4 text-airco2-principal font-semibold text-center" data-aos="fade-up"
                                data-aos-delay="200">{t("concellosNaturePositive.submitOk")}</p>
                            <button className="btn sec-green-background text-white  whitespace-nowrap w-full" onClick={() => (window.location.href = "/")} >
                                {t("concellosNaturePositive.webAirco2")}
                            </button>
                        </div>
                        :
                        <div className='registro container flex items-center justify-center flex-col  mb-4  ' >
                            <p className="text-2xl mb-8 mt-4 font-semibold text-center" data-aos="fade-up"
                                data-aos-delay="200">{t("concellosNaturePositive.submitKo")}</p>
                            <button className="btn sec-green-background text-white  whitespace-nowrap w-full" onClick={() => (window.location.href = "/")} >
                                {t("concellosNaturePositive.webAirco2")}
                            </button>
                        </div>
                    )
                }
            </div >

            <Footer></Footer>

        </div>
    )
}
