import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
import ayuntamiento from '../../../images/Ayuntamiento.svg'
import negocios from '../../../images/Negocios.svg'
import forestales from '../../../images/Propietarios_Forestales.svg'

export default function BloqueIconos() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className='pb-4'>

            <p className='pb-4 text-center text-2xl text-airco2-titles-bloques'>{(t("landingConcellos.todoNecesitamos"))}</p>


            <div className='bg-iconos flex items-center '>
                <div id='container-iconos' className='container  p-4  flex flex-wrap  justify-center   items-center gap-5 '>

                    <div className='text-white flex flex-col items-center text-center max-w-xs'>
                        <img width={84} src={ayuntamiento} alt={(t("landingConcellos.ayuntamiento"))}></img>
                        <p className='font-bold text-2xl '>{(t("landingConcellos.ayuntamiento"))}</p>
                        <p className='font-semibold text-lg '>{(t("landingConcellos.ayuntamientoTxt"))}</p>

                    </div>
                    <div className='text-white flex flex-col items-center text-center max-w-xs'>
                        <img width={84} src={negocios} alt={(t("landingConcellos.negocios"))}></img>
                        <p className='font-bold text-2xl '>{(t("landingConcellos.negocios"))}</p>
                        <p className='font-semibold text-lg '>{(t("landingConcellos.negociosTxt"))}</p>

                    </div>
                    <div className='text-white flex flex-col items-center text-center max-w-xs'>
                        <img width={84} src={forestales} alt={(t("landingConcellos.forestales"))}></img>
                        <p className='font-bold text-2xl '>{(t("landingConcellos.forestales"))}</p>
                        <p className='font-semibold text-lg '>{(t("landingConcellos.forestalesTxt"))}</p>

                    </div>


                </div>








            </div>

        </div>

    )
}
