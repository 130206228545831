import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import UserService from "../../../../services/UserService";
import Banner from "../../../../components/utils/Banner";

export default function ModalDraftCalculation({
  modalCalculationDraft,
  setModalCalculationDraft,
  updateCalculationData,
}) {
  const [drafting, setDrafting] = useState({
    id: 0,
    isFetching: false,
  });
  const [textCalculo, setTextCalculo] = useState("");

  const [draftResult, setDraftResult] = useState(undefined);
  const [message, setMessage] = useState({
    type: "",
    message: "",
    open: false,
  });

  useEffect(() => {
    if (modalCalculationDraft && modalCalculationDraft.calculation) {
      setTextCalculo(getTextCalculation());
    }
  }, [modalCalculationDraft]);

  const getTextCalculation = () => {
    return (
      <>
        el cálculo{" "}
        <b>
          {modalCalculationDraft?.calculation?.questionnaire.name}{" "}
          {modalCalculationDraft?.calculation?.year}{" "}
        </b>
        con id <b>{modalCalculationDraft?.calculation?.id}</b> de la
        organización{" "}
        <b>'{modalCalculationDraft?.calculation?.organization.name}'</b> del
        usuario{" "}
        <b> {modalCalculationDraft?.calculation?.organization.userEmail}</b>
      </>
    );
  };

  const handleDraftCalculation = async () => {
    setDrafting({ id: modalCalculationDraft.calculation.id, isFetching: true });

    await axios
      .patch(
        `${process.env.REACT_APP_CH_CALCULATION}/${modalCalculationDraft.calculation.id}/draft`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`,
          },
        }
      )
      .then((response) => {
        setDraftResult(response.data.data);
        updateCalculationData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
    setDrafting({ id: 0, isFetching: false });
  };

  const handleCloseReport = () => {
    setModalCalculationDraft({ view: false, calculation: null });
    setMessage({
      type: "",
      message: "",
      open: false,
    });
    setDraftResult(undefined);
  };

  return (
    <Modal
      show={modalCalculationDraft.view}
      animation={false}
      data-aos="fade-up"
    >
      <Modal.Header className="sec-green-background">
        <Modal.Title>Poner cálculo en borrador </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message.type !== "" && (
          <Banner className={`mb-2`} type={message.type} open setOpen={true}>
            {message.message}
          </Banner>
        )}

        {drafting.isFetching && drafting.id != 0 && (
          <div className="text-center">
            Actualizando el estado a borrador {textCalculo}
            <div className="mt-3">
              <Spinner animation="border" variant="dark" />
            </div>
          </div>
        )}
        {draftResult != undefined && !drafting.isFetching && (
          <div className="text-center">
            Actualizado a borrador{" "}
            {draftResult.hasErrors ? "con errores" : "sin errores"}{" "}
            {textCalculo}
          </div>
        )}
        {draftResult == undefined && !drafting.isFetching && (
          <>
            <div className="p-4">
              <p>¿Deseas poner en borrador {textCalculo}?</p>
            </div>
            <div className="flex space-x-4 justify-center mt-4">
              <button
                onClick={() => handleDraftCalculation()}
                className="btn-green rounded-lg p-2"
              >
                {drafting.isFetching &&
                drafting.id === modalCalculationDraft.id ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  "Cambiar a borrador"
                )}
              </button>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => handleCloseReport()}
          className="btn-cancel rounded-lg p-2"
        >
          Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  );
}
