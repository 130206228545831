import React, { useEffect, useRef } from 'react';

export default function AgendaAseoria() {

    return (
        <iframe
            src="https://app.gohighlevel.com/v2/preview/RC0hAGOMpCCFg6aEVB1i"
            style={{
                height: '100%',
                width: '100%',
                border: 'none',
            }}
            title="Agendar asesoría"
        ></iframe>
    )

}