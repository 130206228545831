import React, { useState, useContext } from 'react';

import Sidebar from './../Sidebar';
import Header from './../Header';
import SettingsSidebar from './SettingsSidebar';
import BillingPanel from './BillingPanel';
import UserService from '../../../services/UserService';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';


function Billing() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("");
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  useEffect(() => {
    setLoading(true);
     fetch(`${process.env.REACT_APP_PAYMENTS_BILLING}${UserService.getCustomerId()}`,
    //fetch(`${process.env.REACT_APP_PAYMENTS_BILLING}a7a9f4a1-b451-4aed-8607-e285368542eh`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        },
      }).then(j => j.json())
      .then(data => {
        if (data != undefined && data.url != null) {
          window.location.href = data.url;
          setUrl(data.url);
          return null;
        }
      }
      ).finally(() => {
        setLoading(false);
      });


  }, []

  )
  return (


    <div className="sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      < Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden sec-neutro-background">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("sidebar.account")}</h1>
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <SettingsSidebar />

                <div className="sec-neutro-background grow">

                  {/* Panel body */}
                  <div className="p-6 space-y-6">
                    <h2 className="text-2xl text-gray-800 font-bold mb-5">{t("account.facturacion")}</h2>


                    {!loading ?
                      <>
                        {url == "" ? <BillingPanel /> : <div className="text-sm">
                          <Spinner animation="border" ></Spinner>
                          <p>{t("account.redirigiendostripe")}</p>
                        </div>}

                      </> :
                      <>
                        <div className="text-sm">
                          <Spinner animation="border" ></Spinner>
                          <p>{t("account.conectandostripe")}.</p>
                        </div>
                      </>

                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </main>

      </div>




    </div>
  );
}

export default Billing;