import React, { useState, useEffect, useRef, useContext } from 'react';
import './Dropzone.css'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import axios from 'axios';
import UserService from "../../../services/UserService";
import Banner from '../../../components/utils/Banner';
import { Link } from 'react-router-dom';


const Dropzone = ({
     actualizar, url, validExtension, size
}) => {


    const handlerUpdate = (v) => {
        actualizar(v)
    }

   
    const [selectedFiles, setSelectedFiles] = useState([]);
    // selectedFiles has a type of array while errorMessage has a type of string.
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [seeList, updateseeList] = useState(true)
    const [formMontesMessage, setFormMontesMessage] = useState({
        type: '',
        message: ''
    });
    const [imagesResponse,setImagesResponse] = useState({
        imagesResponseData: [],
        isFetching: false
    });



    //VALIDAR EL TIPO DE ARCHIVO
    const validateFile = (file) => {
       
        const validTypes = validExtension;
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }
        
    // Verificar si contiene espacios o caracteres no deseados
    const validarEspacioCaracteresNombre= (file) => {
        if (/\s/.test(file) || /[^\w.-]/.test(file)) {
          return false;
        }
        return true;
    }

    // Verificar si la extensión está en minúsculas
    const validarExtensionMinuscula = (file) => {
        const extension = file.split('.').pop();
        if (extension !== extension.toLowerCase()) {
          return false;
        }
        return true;
    }

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }

    // GUARDAR EN UN ARRAY
    const handleFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i]) && files[i].size < size && validarEspacioCaracteresNombre(files[i].name) && validarExtensionMinuscula(files[i].name)) {
                // if (validateFile(files[i]) && files[i].size < size) {

                setSelectedFiles(prevArray => [...prevArray, files[i]]);

            } else {
                files[i]['invalid'] = true
                files[i]['errMessage'] = "";

                setSelectedFiles(prevArray => [...prevArray, files[i]]);

                if(files[i].size >= size ){
                    files[i].errMessage= t("marketplace.errImgGrande") + fileSize(size);
                }else if(!validarEspacioCaracteresNombre(files[i].name)){
                    files[i].errMessage= t("marketplace.errImgNombre");
                }else if(!validarExtensionMinuscula(files[i].name)){
                    files[i].errMessage= t("marketplace.errImgExtensionMayusculas");
                }else if(!validateFile(files[i])){
                    files[i].errMessage= t("lectorfacturas.tiponovalido");
                }
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }

    }

    //ARRASTRAR Y EJECUTAR HANDLEFILES QUE GUARDA EN UN ARRAY Y ACTUALIZA SELECTEDFILES
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
           

        }
        updateseeList(true)
    }

  

    // // add a new property called invalid
    // // files\[i\]['invalid'] = true;

    // VALIDACION DE TAMAÑO
    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        // if(size >= size ) return "demasiado grnade";
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }
    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }


    //VALIDACION PARA NO PODER SUBIR DOS ARCHIVOS IGUALES
    useEffect(() => {
        let filteredArray = selectedFiles.reduce((file, current) => {
            const x = file.find(item => item.name === current.name);
            if (!x) {
                return file.concat([current]);
            } else {
                return file;
            }
        }, []);
        setValidFiles([...filteredArray]);

    }, [selectedFiles]);

    //ELIMINAR DOCUMENTO DE LA LISTA
    const removeFile = (name) => {
        // find the index of the item
        // remove the item from array

        const validFileIndex = validFiles.findIndex(e => e.name === name);
        validFiles.splice(validFileIndex, 1);
        // update validFiles array
        setValidFiles([...validFiles]);
        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array
        setSelectedFiles([...selectedFiles]);

        const unsupportedFileIndex = unsupportedFiles.findIndex(e => e.name === name);
        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            // update unsupportedFiles array
            setUnsupportedFiles([...unsupportedFiles]);
        }

    }

    const fileInputRef = useRef();

    //SUBIR DOCUMENTO HACIENDO CLICK EN LA PANTALLA
    const fileInputClicked = () => {
        fileInputRef.current.click();
        updateseeList(true)
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
            updateseeList(true)
        }
    }


    const uploadFiles = async () => {
        setFormMontesMessage({ ...formMontesMessage, type: "warning", message: t("marketplace.subiendoImg") });
        document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });

        updateseeList(false)

        const formData = new FormData();
         // formData.append('files', validFiles);
        for (let i = 0; i < validFiles.length; i++) {
            formData.append('files', validFiles[i]);
        }
       

        const fetchImages = async () => {
            try {
              setImagesResponse({ imagesResponse: imagesResponse.imagesResponseData, isFetching: true });
              const response = await axios.post(`${url}`, formData, {
                headers: {
                  Accept: '*/*',
                  'Content-Type': 'multipart/form-data',
                //   'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${UserService.getToken()}`
                }
              }).then(response => {
                if (response.status === 200) {
                  setFormMontesMessage({ ...formMontesMessage, type: "success", message:  t("marketplace.imgMP_uploadOK") });
                  document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
                } else {
                  setFormMontesMessage({ ...formMontesMessage, type: "error", message:  t("marketplace.imgMP_uploadKO") })
                  document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
                }
               
                setImagesResponse({ imagesResponseData: response.data, isFetching: false });
                handlerUpdate(response.data)
                setValidFiles([])
                setSelectedFiles([])
              })
            } catch (exception) {
              console.error(exception);
              setImagesResponse({ imagesResponse: imagesResponse.imagesResponseData, isFetching: false });
              setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
                document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
                setValidFiles([])
                setSelectedFiles([])
            }
          };
          fetchImages();
    };


    return (
        <div className="container-dragdrop">

                        {formMontesMessage.type != "" &&
                            <div className='flex flex-col items-center my-2 '>
                            <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>
                            {/* {formMontesMessage.type == "success" ?

                                    <p>¡Listo!<br/>
                                    Ya solo te queda esperar a que nuestro equipo valide toda la información y en poco tiempo ya podrás ver tu monte en nuestro Marketplace. Desde <Link className="text-indigo-500 hover:text-indigo-600" to="/forest/marketplace">Mi Marketplace</Link> podrás ver el estado de tu monte y modificar información e imágenes</p>
                                 :
                                formMontesMessage.type == "error" && (
                                    <div>
                                    <p>Ups... algo ha salido mal</p>
                                    <p>Puedes comprobar la información de tu monte desde <Link className="text-indigo-500 hover:text-indigo-600" to="/forest/marketplace">Mi Marketplace</Link>. Si no se han subido las imágenes desde ahí podrás intentar subirlas de nuevo!</p>
                                </div>
                                )
                            } */}
                            </div>
                        }

            {unsupportedFiles.length === 0 && validFiles.length ?
                <div>
                    <button className="file-upload-btn" onClick={() => uploadFiles()}>{t("lectorfacturas.subirArchivos")}</button>
                </div>
            : ''}

            {unsupportedFiles.length ? <p className='errorData mb-2 '>{ "⚠️ " + t("lectorfacturas.borrarnovalido")}</p> : ''}

            <div className="drop-container"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={fileInputClicked}
            >
                <div className='message-container'>
                    <div className="drop-message">
                        <input
                            ref={fileInputRef}
                            className="file-input"
                            type="file"
                            multiple
                            accept={validExtension}
                            // onChange={(e) => upFiles(e.target.files)}
                            onChange={filesSelected}
                        />
                        <div className="upload-icon"></div>
                        <div className='hidden sm:flex grow '>
                            {t("lectorfacturas.dragydrop")}
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-2 text-center'>
                <p className='text-xs'>{t("marketplace.avisoNombreImg")}</p>
            </div>
            <div className='file-container my-4 mb-4'>
                <div className="file-display-container">
                <table className='table-auto w-full divide-y divide-gray-200 border-t '>
                    <tbody>
                    {seeList === true ?
                        validFiles.map((data, i) =>
                        <tr key={i} className="border-t">
                            <td className=" py-3">
                                <div className='flex  sm:flex-row space-x-4 items-center mr-2'>
                                    {fileType(data.name)!= "jpeg" && fileType(data.name)!= "jpg" && fileType(data.name)!= "png" ?
                                        <>
                                            <div className="file-type-logo"></div>
                                            <div className="file-type ml-4">{fileType(data.name)}</div>
                                        </> 
                                        : <img width ="70" height="70" src={URL.createObjectURL(data)} />
                                    }
                                </div>
                            </td>
                            <td className="ml-2 py-3">
                                <span className={`mr-4 text-sm file-name mr-4 ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                            </td>
                            <td className="py-3">
                                <div className='flex flex-col space-x-4 items-center'>
                                    <span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className='text-xs file-error-message'>({validFiles[i].errMessage})</span>}
                                </div>
                            </td>
                            <td>
                                <button onClick={() => removeFile(data.name)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff0000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <line x1="4" y1="7" x2="20" y2="7" />
                                        <line x1="10" y1="11" x2="10" y2="17" />
                                        <line x1="14" y1="11" x2="14" y2="17" />
                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                        )
                        : ""
                    }
                    </tbody>
                    </table>
                </div>
            </div>

        </div >)
}

export default Dropzone