import React, { useState, useContext, useEffect } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";


function ButtonAddForest({ direction}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  
    const toggle = () => setDropdownOpen((prevState) => !prevState);
  
    return (
      <div >
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction} className=" bg-indigo-500 hover:bg-indigo-600 text-white rounded-lg">
          <DropdownToggle caret>
            <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            &nbsp; {t("forestList.newMonte")}
            </DropdownToggle>
          <DropdownMenu >
            <DropdownItem header>{t("forestList.newMonte_btn1")}</DropdownItem>
            <Link className="" to="/forest/newforestform">
              <DropdownItem>{t("forestList.newMonte_btn2")}</DropdownItem>
            </Link>
            <Link className="" to="/forest/newmultiforest">
              <DropdownItem>{t("forestList.newMonte_btn3")}</DropdownItem>
            </Link>
            <Link className="" to="/forest/newmonte">
              <DropdownItem>{t("forestList.newMonte_btn4")}</DropdownItem>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
}
    export default ButtonAddForest;