
import { default as React, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import DoughnutChart from '../../charts/DoughnutChart';



// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function ByOrganization({
    data

}) {




    // cambiar idioma

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    


    const totalKgCO2e = [];
    const organizationNames = [];
    const organizationTotals = [];


  

    data?.filter(item => item.calculationStatus === 'FINALIZED').forEach((item) => {
        const organizationName = item.organization.name;
        const kgCO2e = item.totalKgCO2e;
    
      
        const organizationIndex = organizationNames.indexOf(organizationName);
    
        if (organizationIndex === -1) {
     
            organizationNames.push(organizationName);
            totalKgCO2e.push(kgCO2e);
        } else {
        
            totalKgCO2e[organizationIndex] += kgCO2e;
        }
    
     
        const totalsIndex = organizationTotals.findIndex(entry => entry[0] === organizationName);
    
        if (totalsIndex === -1) {
        
            organizationTotals.push([organizationName, kgCO2e]);
        } else {
    
            organizationTotals[totalsIndex][1] += kgCO2e;
        }
    });




    const chartData = {


        labels:

            organizationNames
        ,




        datasets: [
            {
                label: t("footPrint.kgOrganizacion"),
                data:

                    totalKgCO2e,
                backgroundColor: [
                    tailwindConfig().theme.colors.yellow[400],
                    tailwindConfig().theme.colors.indigo[500],
                    tailwindConfig().theme.colors.orange[400],
                    tailwindConfig().theme.colors.gray[500],
                ],
                hoverBackgroundColor: [
                    tailwindConfig().theme.colors.yellow[500],
                    tailwindConfig().theme.colors.indigo[600],
                    tailwindConfig().theme.colors.orange[500],
                    tailwindConfig().theme.colors.gray[600],
                ],
                hoverBorderColor: tailwindConfig().theme.colors.white,
            },
        ],
    };



    return (
        <div id='galcance' className="flex flex-col col-span-full sm:col-span-6 bg-white  rounded-sm border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800">{t("footPrint.totalByOrganization")}</h2>
            </header>



            {(organizationNames.length === 0 && totalKgCO2e.length === 0) ? (
                <Spinner className='flex justify-center' />
            ) : (

                <DoughnutChart data={chartData} width={389} height={260} unit={" Kg"} />
            )}


        </div>
    );
}
export default ByOrganization;