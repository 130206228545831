import React from 'react'
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { formatDateTime, formatThousands } from '../utils/Utils'

function MarketplaceOrdersLineItem({ itemOrder }) {

    return (<tbody className='text-center' >
        <tr>
            <td>
                {itemOrder.id}
            </td>
            <td>
                {itemOrder.product.name + "-" + itemOrder.product.id}
            </td>
            <td>
                {itemOrder.commision}
            </td>
            <td>
                <div>{formatThousands(itemOrder.unitPrice)} €</div>
            </td>

            <td>
                <div>{formatThousands(itemOrder.quantity)} t</div>
            </td>
            <td>
                <div>{formatThousands(itemOrder.total)} €</div>
            </td>
            <td>
                {itemOrder.urlInvoiceForestStripe !== null &&

                <button>
                    <a href={itemOrder.urlInvoiceForestStripe} target="_blank" title="Factura forestal">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-dollar" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                          <path d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                          <path d="M12 17v1m0 -8v1" />
                        </svg>
                    </a>
                </button>}
                <button>
                    <a href={process.env.REACT_APP_CATALOGUE_MP + "/" + itemOrder.product.id} target='_blank' title="Ver monte en MP">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-store" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <path d="M3 21l18 0" />
                          <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                          <path d="M5 21l0 -10.15" />
                          <path d="M19 21l0 -10.15" />
                          <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                        </svg>
                    </a>
                </button>
            </td>
        </tr>
    </tbody>)

}

export default MarketplaceOrdersLineItem;