import axios from "axios";
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalTitle, ModalHeader, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import ReductionCommitmentComponent from "../calculo-huella/ReductionCommitmentComponent";

export default function ReductionPlanModal({ show, organizationName, calcualtionYear, idCalculation, updateShow, updateDocumentationMiteco, idCertification }) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
 
    const updateDocumentation = (props) => {
        updateDocumentationMiteco(props);
    }

    return (
        <>
            <Modal show={show} className='xl-modal' animation={false} data-aos="fade-up" fullscreen backdropClassName="xl-modal-backshadow">
                <ModalHeader className='xl-modal-header sec-neutro-background sec-grey-text text-lg flex justify-between '>
                    <ModalTitle className=''>Plan de reducción: compromisos y percentajes de reducción</ModalTitle>
                    <button onClick={() => {updateShow({state: false, modal: "REDUCTION"}); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 6l-12 12" />
                        <path d="M6 6l12 12" />
                        </svg>
                    </button>
                </ModalHeader>
                <ModalBody className='xl-modal-body'>

                    <ReductionCommitmentComponent organizationName={organizationName} calcualtionYear={calcualtionYear} idCalculation={idCalculation} isModal={true} updateDocumentation={updateDocumentation} idCertification={idCertification} />

                <div className="flex justify-center space-x-4 mb-4">
                    <button className=" text-xs btn-sm btn-cancel" onClick={() => {
                        updateShow({state: false, modal: "REDUCTION"});
                    }}> {t("footPrint.cerrarCerrar")}</button>
                </div>

                </ModalBody>
            </Modal >
        </>
    )
}