
import React, { useState, useContext } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import CalculatePrice from './CalculatePrice';

export default function CalculatePriceContent() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("calc.preciorecomendado")}</h1>
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm ">
              <div className="">


                <CalculatePrice></CalculatePrice>

              </div>
            </div>

          </div>
        </main>
     
      </div>

    </div>
  );
}


