import React from 'react'

import { useRef, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.css';
import 'swiper/modules/a11y.css'
import 'swiper/modules/pagination.css';
import 'swiper/modules/scrollbar.css';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation.scss'; // Navigation module
import 'swiper/modules/pagination.scss'; // Pagination module

import atlas from '../../../images/javierAtlas.webp'
import ontime from '../../../images/carlosOntime.webp'
import moeve from '../../../images/javierMoeve.webp'
import cool from '../../../images/coolberries.webp'



export default function CommunityClub() {

    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");

    const testimonials = [
        {
            image: atlas,
            alt: 'atlas',
            username: 'Javier Pereira, Atlas Bus',
            story: t("newHome.communityClub-atlas"),
        },
        {
            image: ontime,
            alt: 'ontime',
            username: 'Carlos Moreno, Ontime',
            story: t("newHome.communityClub-ontime"),
        },
        {
            image: moeve,
            alt: 'moeve',
            username: 'Javier García, Moeve',
            story: t("newHome.communityClub-moeve"),
        },
        {
            image: cool,
            alt: 'coolberries',
            username: 'Juan Hitos, CoolBerries',
            story: t("newHome.communityClub-coolberries"),
        },
    ];
    const swiperRef = useRef(null);

    return (
        <section className='mb-5'>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-12 md:pt-20  pb-12 md:pb-20 ">
                    <div className="pb-8">
                        <div className="relative">
                            {/* Content */}
                            <div className="relative px-6 pb-8 md:px-12 lg:pb-0">
                                {/* Carousel built with Swiper.js [https://swiperjs.com/] */}
                                <div className="testimonial-carousel swiper-container" >
                                    <Swiper
                                        ref={swiperRef}
                                        modules={[Navigation, Pagination, Autoplay]}
                                        slidesPerView={1}
                                        pagination={{ clickable: true }}
                                        // autoplay={{ delay: 5000 }}
                                        className="swiper-wrapper"
                                    >
                                        {testimonials.map((testimonial, index) => (
                                            <SwiperSlide key={index} >
                                                <div className="space-y-8 flex flex-col md:flex-row lg:flex items-center lg:space-y-0 lg:space-x-8 text-center lg:text-left space-between gap-2 text-airco2-general-grey " data-aos="zoom-in" data-aos-delay="800">
                                                    <div className="shrink-0 relative inline-flex img-txt-hidden">
                                                        <img className="img-community" src={testimonial.image}
                                                            alt={testimonial.alt} />
                                                        <div className="absolute " style={{ bottom: "20px", right: "-40px" }}>
                                                            <a className="flex items-center sec-green-background font-cabinet-grotesk text-xs text-white  decoration-2 underline-offset-2 hover:underline px-3 py-1 rounded-full shadow-sm" href="#0">
                                                                {/* <svg className="shrink-0 mr-1.5" width="16" height="14" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="m5.063 0 3.495 4.475L12.601 0h2.454L9.696 5.931 16 14h-4.938L7.197 9.107 2.771 14H.316L6.05 7.658 0 0h5.063Zm-.74 1.347H2.866l8.875 11.232h1.36L4.323 1.347Z" fill="var(--airco2-green-color)" fillRule="nonzero" />
                                                            </svg> */}
                                                                <span>{testimonial.username}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <svg
                                                            className="absolute left-0 top-0" style={{ marginTop: "-3rem" }}
                                                            width="24"
                                                            height="48"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill="var(--airco2-green-color)"
                                                                d="M9.736 2.485c-.458-.226-.257-.84.318-1.7.185-.273.932-.82 1.107-.783.348.065.692.136.979.256.18.067 1.969 1.987 2.587 2.648.615.624 1.083 1.347 1.53 2.09L17.6 7.26c.445.756.883 1.516 1.3 2.287.142.256.257.527.347.812l.081.29c.282 1.15.768 2.18 1.292 3.19l.386.742a.66.66 0 0 0 .286.294c.394.193 1.857 3.493 1.922 3.978.221 1.181.258 2.447.447 3.634l.046.2c.023.099.034.203-.028.331-.237.5-.06.81.025 1.16.125.486.232.98.29 1.495.03.257-.059.56-.103.847-.044.263-.507.352-.586.304-.222-.138-.354.122-.522.225l-.128.072a.317.317 0 0 1-.058.022c-.073.008-1.941-1.856-2.315-2.283l-.051-.063c-.019-.026-.604-.888-1.23-1.81-.688-.87-1.177-1.889-1.326-2.07-.14-.202-.146-.47-.186-.718-.014-.127-1.707-2.888-1.761-3-.241-.629-.68-1.149-.602-1.95.015-.155-.062-.268-.125-.385-.541-1.007-.898-2.118-1.32-3.194-.213-.55-.53-1.04-.789-1.563-.047-.094-.043-.218-.07-.327l-.064-.27a.681.681 0 0 0-.05-.124c-.68-1.196-1.16-2.527-1.992-3.62-.18-.26-.585-1.813-.829-2.088-.127-.157.036-1.103-.15-1.192ZM.246 30.253c-.444-.162-.255-.76.311-1.603.181-.27.91-.842 1.081-.836.333.018.657.04.927.104.162.05 1.832 1.473 2.456 1.898.588.425 1.074.94 1.534 1.478.947 1.084 1.946 2.123 2.938 3.169.221.234.402.5.557.8.45.912 1.096 1.635 1.784 2.31l.458.443a.49.49 0 0 0 .32.14c.382.023 2.439 2.21 2.624 2.586.48.9.833 1.948 1.316 2.865.062.136.192.213.158.44-.077.523.162.705.334.963.242.356.472.726.67 1.13.097.2.102.5.141.762.033.237-.356.518-.438.512-.236-.018-.283.261-.406.424a.968.968 0 0 1-.138.16c-.06.042-2.243-.708-2.71-.915l-.07-.034-1.605-1.025c-.859-.466-1.577-1.147-1.759-1.25-.179-.114-.254-.348-.357-.548l-2.36-1.975c-.375-.463-.9-.783-1.001-1.523-.022-.145-.117-.222-.199-.304-.72-.73-1.276-1.62-1.865-2.476-.3-.435-.672-.81-1.003-1.217-.06-.073-.077-.185-.119-.277l-.098-.233a.458.458 0 0 0-.067-.102c-.813-.94-1.43-2.061-2.312-2.943-.207-.201-.686-1.6-.927-1.826-.137-.127.002-1.032-.176-1.097Z"
                                                            />
                                                        </svg>
                                                        <h4 className="text-lg font-cabinet-grotesk mb-4">{testimonial.story}</h4>

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

