import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';


export default function ServiciosForestales() {
  const [t, i18n] = useTranslation("global");
  const [sidebarOpen, setSidebarOpen] = useState(false);




  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <div className="mb-2 sm:mb-0">
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("forestryService.title")} 🍃</h1>
            </div>

            <div className=" px-4 pb-4" style={{ width: '100%' }}>
              <div className='flex flex-col mb-4 text-justify space-y-4'>
                <p>{t("forestryService.welcome")}</p>
                <p>{t("forestryService.serviceText1")}</p>
                <p>{t("forestryService.serviceText2")}</p>
                <br></br>
              </div>

              <div className='flex justify-center'>
                <a href='https://4xq7scpnae1.typeform.com/to/HOlCAWLq' target='_blank' className='text-base btn-sm rounded-lg btn-green hover:no-underline'>{t("forestryService.goForm")}</a>
              </div>

            </div>

          </div>
        </main>
      </div>
    </div>
  )
}
