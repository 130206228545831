import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';
import perfilko from './../../../images/perfilko.svg';
import perfilok from './../../../images/perfilok.svg';

export default function StripeResponse({ status }) {
    const [t, i18n] = useTranslation("global");
    const [sidebarOpen, setSidebarOpen] = useState(false);

    // FETCH para saber si tiene usuario o no en stripe
    const [stripeInfo, setStripeInfo] = useState({
        submited: false,
        chargesEnabled: false,
        isFetching: false
    });

    useEffect(() => {

        const fetchStatusStripeProvider = async () => {
            try {

                fetch(`${process.env.REACT_APP_MP_PAYMENT_EXPRESS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((stripeAccountGet) => {
                        if (!stripeAccountGet.error) {
                            setStripeInfo({ submited: stripeAccountGet.stripeSubmited, chargesEnabled: stripeAccountGet.stripeChargesEnabled, isFetching: true });
                        }
                    });

            } catch (exception) {
                setStripeInfo({ submited: false, chargesEnabled: false, isFetching: true });
            }
        };

        if (status) {
            fetchStatusStripeProvider();
        } else {
            setStripeInfo({ submited: false, chargesEnabled: false, isFetching: true });
        }

    }, []);



    const handlerGetUrlConnectStripeAccount = () => {
        const data = {
        }

        const fetchGetUrlConnectStripeAccount = async () => {
            try {
                await axios.post(`${process.env.REACT_APP_MP_PRIVATE_PROVIDER}`, data,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': `Bearer ${UserService.getToken()}`
                        }
                    })
                    .then((response) => {
                        window.location.href = response.data;


                    }, (error) => {
                        console.error(error);
                    });
            } catch (exception) {
                console.error(exception);
            }
        };
        fetchGetUrlConnectStripeAccount();
    }


    const handlerGetUrlStripeDashboardAccount = () => {


        const fetchGetUrlStripeDashboardAccount = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_MP_PAYMENT_DASHBOARD}`,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': `Bearer ${UserService.getToken()}`
                        }
                    })
                    .then((response) => {
                        window.location.href = response.data;


                    }, (error) => {
                        console.error(error);
                    });
            } catch (exception) {
                console.error(exception);
            }
        };
        fetchGetUrlStripeDashboardAccount();
    }



    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        <div className='text-center text-airco2-filter-content mx-auto' >
                            {!stripeInfo.isFetching ?
                                <h1>{t("sidebar.cargando")}</h1>
                                :
                                <div className='divContainer'>
                                    {

                                        stripeInfo.submited ?
                                            <h1 className=" text-xl md:text-2xl  mb-6">{t("pay.usuarioOkStripe")}✨</h1>
                                            :
                                            <h1 className=" text-xl md:text-2xl mb-6">{t("pay.usuariofallo")}</h1>
                                    }
                                    <div className='flex-row'>

                                        <div className='flex justify-center'>
                                            {

                                                stripeInfo.submited ?
                                                    <img alt='perfilok' src={perfilok} width={'380'}></img>
                                                    :
                                                    <img alt='perfilko' src={perfilko} width={'380'}></img>
                                            }
                                        </div>

                                        <div className='w-full flex flex-row justify-center'>
                                            <div className='px-2 flex  mt-1 text-xl gap-2 flex-wrap'>
                                                {
                                                    stripeInfo.submited ?
                                                        <span className='flex gap-1'>
                                                            <span> {t("pay.acceder")}</span>
                                                            <a href="#" target="_blank" onClick={() => handlerGetUrlStripeDashboardAccount()} className='text-airco2-principal'>{t("pay.perfilStripe")} </a>
                                                            <span> {t("pay.oaTu")}</span>
                                                            <Link to='/forest/marketplace' className='text-airco2-principal'>{t("pay.dashboard")}</Link>.
                                                        </span>
                                                        :
                                                        <span className='flex gap-1'>

                                                            <span> {t("pay.dirigeStripe")}</span>
                                                            <Link to="/forest/dashboardmontes" className='text-airco2-principal'>{t("pay.paginainicio")} </Link>
                                                            <span> {t("pay.vuelveintentar")}</span>
                                                        </span>
                                                }




                                            </div>

                                        </div>

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </main>

            </div>

        </div>
    )
}
