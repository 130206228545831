import React, { useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IdiomContext } from "./../../../context/createcontext";

import {
  Chart,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-moment";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/gl";

// Import utilities
import { tailwindConfig, formatThousands } from "../utils/Utils";

Chart.register(
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend
);

function LineChartDays({ data, width, height, unit }) {
  const canvas = useRef(null);
  const legend = useRef(null);
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    switch (lng) {
      case "en":
        moment.locale("en");
        break;
      case "pt":
        moment.locale("pt");
        break;
      case "gl":
        moment.locale("gl");
        break;
      default:
        moment.locale("es");
        break;
    }
    const chart = new Chart(ctx, {
      type: "line",
      data: data,
      options: {
        chartArea: {
          backgroundColor: tailwindConfig().theme.colors.gray[50],
        },
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
          },
          x: {
            // type: 'time',
            // time: {
            //   parser: 'DD-MM-YYYY',
            //   unit: 'day',
            // },
            type: "time",
            time: {
              parser: "MM-DD-YYYY",
              unit: "month",
              tooltipFormat: "MMMM",
              displayFormats: {
                month: "MMM",
              },
            },
            display: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              // label, poner x o y al contrario del indice.
              label: (context) =>
                formatThousands(context.parsed.y) +
                (unit != undefined ? unit : ""),
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [
        {
          id: "htmlLegend",
          afterUpdate(c, args, options) {
            const ul = legend.current;
            if (!ul) return;
            // Remove old legend items
            while (ul.firstChild) {
              ul.firstChild.remove();
            }
            // Reuse the built-in legendItems generator
            const items = c.options.plugins.legend.labels.generateLabels(c);
            //  const color= items.fillstyle.split(".")[1];
            items.forEach((item) => {
              const li = document.createElement("li");
              li.style.marginRight = tailwindConfig().theme.margin[2];
              // Button element
              const button = document.createElement("button");
              button.style.display = "inline-flex";
              button.style.alignItems = "center";
              button.style.opacity = item.hidden ? ".3" : "";
              button.onclick = () => {
                c.setDatasetVisibility(
                  item.datasetIndex,
                  !c.isDatasetVisible(item.datasetIndex)
                );
                c.update();
              };
              // Color box
              const box = document.createElement("span");
              box.style.display = "block";
              box.style.width = tailwindConfig().theme.width[3];
              box.style.height = tailwindConfig().theme.height[3];
              box.style.borderRadius = tailwindConfig().theme.borderRadius.full;
              box.style.marginRight = tailwindConfig().theme.margin[2];
              box.style.borderWidth = "3px";
              box.style.borderColor = item.fillStyle;
              box.style.pointerEvents = "none";
              // Label
              const label = document.createElement("span");
              label.style.color = tailwindConfig().theme.colors.gray[500];
              label.style.fontSize = tailwindConfig().theme.fontSize.sm[0];
              label.style.lineHeight =
                tailwindConfig().theme.fontSize.sm[1].lineHeight;
              const labelText = document.createTextNode(item.text);
              label.appendChild(labelText);
              li.appendChild(button);
              button.appendChild(box);
              button.appendChild(label);
              ul.appendChild(li);
            });
          },
        },
      ],
    });
    return () => chart.destroy();
    // {// eslint-disable-next-line react-hooks/exhaustive-deps}
  }, [lng]);

  return (
    <React.Fragment>
      <div className="px-5 pt-2 pb-6">
        <ul ref={legend} className="flex flex-wrap justify-center"></ul>
      </div>
      <div className="grow">
        <canvas ref={canvas} width={width} height={height}></canvas>
      </div>
    </React.Fragment>
  );
}

export default LineChartDays;
