import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import air from '../../../images/air.svg'
import { formatThousands } from '../utils/Utils';

export default function BannerEquivalencia({ totalHuella }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const toneladas = 1
    const numeroVuelos = Math.round(totalHuella / toneladas)



    return (
        <div className="bg-white rounded-sm border border-gray-200 py-4 px-3" >
            <div className=" flex flex-col lg:flex-row justify-between items-center">
                <div className="txt text-lg " style={{ flex: "2" }}>
                    <h3 className="ml-4   mb-2 " >
                        <p className='mb-2'>{t("footPrint.huellaEquivale")}<span className='font-bold'>{formatThousands(numeroVuelos)}</span>{t("footPrint.veces")}</p>
                    <p className='italic text-xs'>{t("footPrint.equivaleFuente")}</p>
                    </h3>
                    
                </div>
                <div className="flex justify-end" style={{ flex: "1" }}>
                    <img src={air} width={200}></img>
                </div>
            </div>
        </div>
    )
}
