import React, { useState, useContext, useEffect } from 'react';
import UserService from '../../../services/UserService';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import Form from "react-bootstrap/Form";
import axios from 'axios';
import Banner from '../../../components/utils/Banner';
import Dropzone from '../utils/Dropzone';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ImagesTable from './ImagesTable';

function MPForestList({ infoProvider }) {

  let providerData = infoProvider;
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [formMontesMessage, setFormMontesMessage] = useState({
    type: '',
    message: ''
  })
  const [modalDelete, setModalDelete] = useState(false);
  const [imageObjDelete, setImageObjDelete] = useState([]);
  const [name, setName] = useState(providerData == undefined ? "" : providerData.name);
  const [descripcionCorta, setDescripcionCorta] = useState(providerData == undefined || providerData.providerTranslation[0] == undefined ? "" : providerData.providerTranslation[0].summary);
  const [descripcionLarga, setDescripcionLarga] = useState(providerData == undefined || providerData.providerTranslation[0] == undefined ? "" : providerData.providerTranslation[0].description);
  const [images, setImages] = useState(providerData.gallery);
  const [providerResponse, setProviderResponse] = useState({
    providerResponseData: [],
    isFetching: false
  });

  // const [imageDeleteResponse,setImageDeleteResponse] = useState({
  //   imageDeleteResponseData: [],
  //   isFetching: false
  // });

  const urlDropzone = `${process.env.REACT_APP_MP_PRIVATE}/provider/${providerData?.id}/images`;
  const maxSize = 1048576;

  const actualizar = (newImages) => {
    setImages([...images, ...newImages])
  }
  const actulizarAtributosImg = (imagesChanged) => {
    setImages(imagesChanged)
  }

  const handlePostProvider = (e) => {
    e.preventDefault();

    setFormMontesMessage({ ...formMontesMessage, type: "warning", message: "Actualizando datos..." });
    document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });

    providerData.name = name;
    if (providerData.providerTranslation.length > 0) {
      providerData.providerTranslation[0].summary = descripcionCorta;
      providerData.providerTranslation[0].description = descripcionLarga;
    } else {
      let data = {
        "summary": descripcionCorta,
        "description": descripcionLarga,
        "languageId": 1
      }
      providerData.providerTranslation.push(data);
    }
    providerData.gallery = images;

    const fetchStripeUrl = async () => {
      try {
        setProviderResponse({ providerResponse: providerResponse.providerResponseData, isFetching: true });
        const response = await axios.post(`${process.env.REACT_APP_MP_PRIVATE_PROVIDER}`, providerData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          if (response.status === 200) {
            setFormMontesMessage({ ...formMontesMessage, type: "success", message: t("account.actualizacion-ok") });
            document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
          } else {
            setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
            document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
          }
          setProviderResponse({ providerResponse: response.data, isFetching: false });
        })
      } catch (exception) {
        console.error(exception);
        setProviderResponse({ providerResponse: providerResponse.providerResponseData, isFetching: false });
      }
    };
    fetchStripeUrl();
  }

  return (
    <div className="grow sec-neutro-background">
      <Modal show={modalDelete}>
        <Modal.Header className='sec-green-background text-white text-lg'><b>{t("marketplace.imgMP_delete")}</b></Modal.Header>
        <Modal.Body className='text-left'>
          <p>{t("marketplace.imgMP_deleteModal")}</p>
          <button></button>
        </Modal.Body>
        <div className='flex justify-center pb-2'>
          <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => handleDelete(imageObjDelete)}>
            {t("formMonte.alert_aceptar")}
          </Button>
          <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => setModalDelete(false)}>
            {t("formMonte.cancelar")}
          </Button>
        </div>
      </Modal>

      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-2">{t("marketplace.edit_provider")}</h2>
        <div className=' flex justify-center '>

          {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>}
        </div>

        <div className='md:flex space-x-4 mx-2'>
          <div className='w-full md:w-3/6 md:mr-4 mb-4'>
            <h5 className='h5'>{t("marketplace.data_provider")}</h5>
            {/* <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-4"> */}
            <div className="flex-col items-center space-y-4">
              <div className=" w-full">
                <label className="block text-sm font-medium mb-1" htmlFor="name">{t("marketplace.name")}</label>
                <input id="name" className="form-input w-full" type="text" defaultValue={name} onChange={event => setName(event.target.value)} />
              </div>
              <div className="w-full">
                <label className="block text-sm font-medium mb-1" htmlFor="sector">{t("marketplace.description0_provider")}</label>
                <textarea id="descriptionProvider" maxLength="255" className="form-input w-full" type="text" value={descripcionCorta} onChange={event => setDescripcionCorta(event.target.value)} />
              </div>
              <div className="w-full">
                <label className="block text-sm font-medium mb-1" htmlFor="sector">{t("marketplace.description1_provider")}</label>
                <textarea id="descriptionProvider" className="form-input w-full" type="text" value={descripcionLarga} onChange={event => setDescripcionLarga(event.target.value)} />
              </div>
            </div>

          </div>
          <div className="w-full md:w-3/6 md:ml-4 ">
            <h5 className='h5 mb-4 '>{t("marketplace.img_provider")}</h5>

            <div className="flex-col items-center space-y-4">
              {images.length > 0 ?
                <div className="overflow-x-auto w-full">
                  <div className='table-auto w-full divide-y divide-gray-200 border-t '>
                    <ImagesTable img={images} actulizarAtributosImg={actulizarAtributosImg} origin="provider" />
                  </div>
                </div>
                : ""}
            </div>
          </div>
        </div>
        <div className='mt-2 mb-4 pb-4 flex justify-center'>
          <button className='btn-sm btn-green' onClick={handlePostProvider}>{t("marketplace.update_provider")}</button>
        </div>
        <hr></hr>
        <div className='mx-2'>
          <h2 className="text-2xl text-gray-800 font-bold mb-4">{t("marketplace.upload_imgs")}</h2>
          <Dropzone actualizar={actualizar} url={urlDropzone} validExtension={['image/jpeg', 'image/jpg', 'image/png']} size={maxSize} />
        </div>
      </div>

    </div>
  );
}

export default MPForestList;