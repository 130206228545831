import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'
export default function bloqueForm() {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const fondo = 'RGBA(255, 255, 255, 0.8)'
    const color = 'RGBA(135, 135, 135, 0.5)'
    const claseI = 'p-2 rounded-lg flex items-center'
    const claseT = 'p-2 rounded-lg flex items-center w-full h-full'
    const urlAgenda = "https://links.bastidafarina.com/widget/bookings/reunion-30-source-web-airco2"
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [consent, setConsent] = useState(false);
    const [event, setEvent] = useState("");
    const [people, setPeople] = useState("")
    const [dateEvent, setDateEvent] = useState("")
    const [hubspotResponse, setHubspotResponse] = useState("");
    const [formError, setFormError] = useState("");
    const [text, setText] = useState("")
    const handleSubmit = async (e) => {
        e.preventDefault();


        if (!name || !people || !email || !event || !dateEvent) {
            handleError(t("landingEventos.camposObligatorios"))
            return;


        }

        if (!consent) {
            handleError(t("landingEventos.datosPersonales"))
            return;

        }


        setHubspotResponse(await submit_landing_hubspot_form(name, email, people, event,dateEvent));

    }

    const handleOnchange = () => {
        setFormError("");

    }


    const handleError = (mensaje) => {
        document.getElementById('main')?.scrollIntoView({ behavior: 'smooth' });
        setFormError(mensaje);
    }

    const submit_landing_hubspot_form = async (name, email, people, event) => {
        const portalId = '25435066';
        const formGuid = 'da1faa6a-e1fa-4111-812f-05febdce6f7e';
        const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`
        const data = {
            fields: [

                {
                    name: 'firstname',
                    value: name
                },

                {
                    name: 'email',
                    value: email
                }
                ,

                {
                    name: 'event',
                    value: event
                },
                {
                    name: 'people',
                    value: people
                },
                {
                    name: 'dateevent',
                    value: dateEvent
                }


            ]
        };



        return fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((responseJson) => {
                setText(t("landingEventos.solicitudOk"))
                return responseJson;


            });

    }
    return (
        <div className='container-landing-eventos pb-4'>
            <div className="container flex flex-column items-center  mx-auto pt-4 text-center " >
                <h1 className="text-white text-landing-title" data-aos="fade-up" data-aos-delay="100"> {(t("landingEventos.eventoNetZero"))}
                </h1>

                <div className="mt-6 md:mb-1 w-1/2" data-aos="fade-in" data-aos-delay="400" style={{ marginBottom: '80px' }}>

                    <a href={urlAgenda} target='_blank' className="btn sec-green-background text-airco2-filter-content  whitespace-nowrap w-full lg:w-1/2 " >
                        <span style={{ color: 'white', textDecoration: 'none' }}> {(t("landingEventos.interesa"))}</span>

                    </a>

                </div>
            </div>

            <div className='cnt-txt pb-5  container'>

                <div className='flex text-center justify-center registro pc-form w-full container md:w-1/2 text-airco2-landing' data-aos-id-blocks>

                    <div className={claseT} style={{ backgroundColor: color, maxHeight: '557.91px' }} data-aos="fade-left" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]" >
                        <h2 className="text-white mr-4 ml-4 pr-2 pl-2 text-landing-claim " data-aos="fade-up" data-aos-delay="100"> Marca el camino con una experiencia inspiradora para tus asistentes
                        </h2>
                    </div>



                </div>






                {formError != "" &&
                    <div className='mb-3'><Banner type="error" children={formError} open setOpen={true} ></Banner></div>
                }
                {(hubspotResponse === "") &&
                    <div className='rounded-lg  registro container  w-full md:w-1/2 flex  justify-center pc-form ' >
                        <div className=' container-form  flex flex-col justify-center rounded-lg h-full w-full' style={{ backgroundColor: fondo }}>
                            <p className='text-airco2-general text-2xl mt-2  text-center  font-bold'> {(t("landingEventos.contacta"))}</p>
                            <div className='mr-3 ml-3 form-landing' id='formulario'  >

                                <div className='text-airco2-general p-1' data-aos="fade-up"
                                    data-aos-delay="400">
                                    <form onSubmit={handleSubmit} className=''>

                                        <div className='flex flex-wrap NameLastname '>
                                            <div className='w-full lg:w-1/2 pr-1 mt-2 mb-1'>
                                                <label className="block text-sm mb-1  " htmlFor="name">{(t("landingEventos.nombre"))} <span className="text-red-500">*</span></label>
                                                <input id="name" className="form-user-data rounded-lg w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setName(e.target.value), handleOnchange() }} />
                                            </div>
                                            <div className='w-full lg:w-1/2 pr-1 mt-2 mb-1 '>
                                                <label className="block text-sm mb-1  " htmlFor="email">{(t("landingEventos.email"))} <span className="text-red-500">*</span></label>
                                                <input id="email" className=" w-full bg-white rounded-lg form-user-data" type="email" defaultValue={''} required onChange={(e) => { setEmail(e.target.value), handleOnchange() }} />
                                            </div>

                                        </div>



                                        <div className='flex flex-wrap companyEvent '>
                                            <div className='w-full lg:w-1/2 pr-1 mt-2 mb-1 '>
                                                <label className="block text-sm mb-1  " htmlFor="people">{(t("landingEventos.personas"))} <span className="text-red-500">*</span></label>
                                                <input id="people" className=" w-full rounded-lg bg-white form-user-data" type="number" defaultValue={''} required onChange={(e) => { setPeople(e.target.value), handleOnchange() }} />
                                            </div>
                                            <div className='w-full lg:w-1/2 pr-1 mt-2 mb-1'>
                                                <label className="block text-sm mb-1 " htmlFor="event">{(t("landingEventos.evento"))} <span className="text-red-500">*</span></label>
                                                <input id="event" className=" w-full rounded-lg bg-white form-user-data" type="text" defaultValue={''} required onChange={(e) => { setEvent(e.target.value), handleOnchange() }} />
                                            </div>
                                        </div>
                                        <div className='flex eventDate'>
                                            <div className='w-full  pr-1 mt-2 mb-1 '>
                                                <label className="block text-sm mb-1  " htmlFor="dateEvent">{(t("landingEventos.dateEvent"))} <span className="text-red-500">*</span></label>
                                                <input id="dateEvent" className=" w-full rounded-lg bg-white form-user-data" type="date" defaultValue={''} required onChange={(e) => { setDateEvent(e.target.value), handleOnchange() }} />
                                            </div>

                                        </div>

                                        <div className='mt-2'>
                                            <label className="flex items-center font-medium">
                                                <input type="checkbox" className="form-checkbox" required onChange={e => { setConsent(e.target.checked), handleOnchange() }} />
                                                <span className="text-sm ml-2 ">{(t("landingEventos.leidoYacepto"))} <a href="/terminos">{(t("landingEventos.legales"))}</a><span className="text-red-500" >*</span></span>
                                            </label>
                                        </div>

                                        <div className=" mt-4">
                                            <button className="btn text-white sec-green-background   whitespace-nowrap w-full" type="submit" >{(t("landingEventos.enviar"))}

                                            </button>
                                        </div>
                                    </form>


                                </div>


                            </div>

                        </div>


                    </div>
                }
                {(hubspotResponse !== "") &&

                    <div className='rounded-lg registro container pc-form w-full md:w-1/2 flex justify-center' >
                        <div className='container-form flex flex-col items-center justify-center rounded-lg h-full w-full ' style={{ backgroundColor: fondo }}>
                            <p className='text-airco2-general mt-4 text-lg text-center pb-2 font-bold'>{text}</p>
                            <button className="btn mb-4 sec-green-background text-white w-1/2" onClick={() => (window.location.href = "/")} >{(t("landingEventos.visitaWeb"))}

                            </button>



                        </div>


                    </div>




                }
            </div>

        </div>
    )
}
