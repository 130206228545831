import { React, useState, useContext, useEffect } from 'react'
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import UserService from "../../../services/UserService";
import axios from 'axios';
import Select from 'react-select';
import { Col, Spinner } from 'react-bootstrap';


export default function CalculatePrice() {

    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");


    const [categoryId, setCategoryId] = useState('');


    const [categories, setCategories] = useState({
        categoriesData: [],
        isFetching: false,
    });

    const [sustainability, setSustainability] = useState({
        sustainabilityData: [],
        isFetching: false,
    });

    const [goalsToSend, setGoalsToSend] = useState([]);

    const [sustainabilityDefaults, setSustainabilityDefaults] = useState(null)

    const [goalsSelect, setGoalsSelect] = useState(null);

    const [recommendedPrice, setRecommendedPrice] = useState({
        recommendedPriceData: [],
        isFetching: false,
    });





    //Fetch categories MP


    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setCategories({ categoriesData: categories.categoriesData, isFetching: true });
                await axios.get(`${process.env.REACT_APP_MP_CATEGORIES}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                }).then(response => {
                    setCategories({ categoriesData: response.data, isFetching: false });
                    // setItems(response.data);
                });
            } catch (exception) {
                console.error(exception);
                setCategories({ categoriesData: categories.categoriesData, isFetching: false });
            }
        };
        fetchCategories();
    }, []);

    // Fetch SustainabilityGoals MP
    useEffect(() => {
        const fetchSustainability = async () => {
            try {
                setSustainability({ sustainabilityData: sustainability.sustainabilityData, isFetching: true });
                await axios.get(`${process.env.REACT_APP_MP_GOALS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                }).then(response => {
                    setSustainability({ sustainabilityData: response.data, isFetching: false });
                    // setItems(response.data);
                });
            } catch (exception) {
                console.error(exception);
                setSustainability({ sustainabilityData: sustainability.sustainabilityData, isFetching: false });
            }
        };
        fetchSustainability();
    }, []);

    //Objeto para el React-Select con las sustainabilityGoals

    useEffect(() => {
        if (sustainability.sustainabilityData[0] != undefined) {
            setGoalsSelect(sustainability.sustainabilityData.map((v) => {
                return ({
                    value: v.id, label: v.name, id: v.id
                })
            })
            )
        }
    }, [sustainability.sustainabilityData])



    //Guarda en el estado GoalsToSend los valores seleccionados por el ReactSelect
    const handleSelectChange = (option) => {
        setGoalsToSend(option)
        setSustainabilityDefaults(option)
    }

    const hanldeChangeCategory = (event) => {
        let category = event.target.value;
        if (categories.categoriesData.find(v => v.id == category) == undefined) {
            setCategoryId("")
        } else {
            setCategoryId(categories.categoriesData.find(v => v.id == category))
        }
    }

    useEffect(() => {
        let body;
        if (categoryId == null || categoryId == "") {
            body = { "sustainabilityGoalList": goalsToSend.map((va) => { return ({ "id": va.value }); }) }
        } else {
            body = {
                "sustainabilityGoalList": goalsToSend.map((va) => { return ({ "id": va.value }); }).filter((v) => { return !categoryId.sustainabilityGoalDtoList.some((obj) => obj.id === v.id) }),
                "category": { "id": Number(categoryId.id) }
            }
        }
        const fetchRecommendedPrice = async () => {
            try {
                setRecommendedPrice({ recommendedPriceData: recommendedPrice.recommendedPriceData, isFetching: true });

                await axios.post(`${process.env.REACT_APP_MP_RECOMENDED_PRICE}`, body, {

                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                }).then(response => {
                    setRecommendedPrice({ recommendedPriceData: response.data, isFetching: false });
                    // setItems(response.data);
                });
            } catch (exception) {
                console.error(exception);
                setRecommendedPrice({ recommendedPriceData: recommendedPrice.recommendedPriceData, isFetching: false });
            }
        };
        fetchRecommendedPrice();
    }, [categoryId, goalsToSend]);



    return (

        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">


            <main>

                <div className='flex flex-wrap container justify-content-between pt-2 pb-3'>
                    <Col md={12} lg={6} className='pt-3 pl-4'>

                        <div>
                            <label id="categoria" className="block text-sm font-bold mb-1">{t("calc.categoria")}*</label>
                            <select id="categoria" name="" className="form-input w-full" type="text" onChange={hanldeChangeCategory}>
                                <option key="categoriaNull" name="categoria" className='w-full' value="">{t("calc.ninguna")}</option>
                                {categories.categoriesData.map((v) => {
                                    return (<option key={v.id + "categoria"} name={v.name} className='w-full' value={v.id}>{v.name}</option>)
                                })}
                            </select>
                           <p className='italic text-xs'>*{t("calc.categoriaUnica")}</p> 
                        </div>
                        <div>
                            <label id="sustainability" className="block text-sm font-bold mb-1 mt-4">{t("calc.objSost")}:</label>


                            <Select
                                value={sustainabilityDefaults}
                                isMulti
                                name="sustainability"
                                id="sustainability"
                                options={goalsSelect != null ? goalsSelect : ""}
                                classNamePrefix="Selecciona"
                                placeholder={t("calc.selecciona")}
                                onChange={handleSelectChange}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? "#334155" : "#334155",
                                        borderColor: state.isSelected ? "#334155" : "#334155",
                                        borderRadius: "0.125rem",
                                        paddingTop: "0.5rem",
                                        paddingBottom: "0.5rem",

                                        backgroundColor: "transparent",
                                    }),
                                }}
                            />

                        </div>


                       


                    </Col>


                    <Col md={12} lg={6} className='pt-3 pl-4'>

                        <label className="block text-sm font-bold mb-1">{t("calc.pxRecomendado")}</label>
                        <div className='w-full flex'>
                            <label className="block text-sm font-medium">{t("calc.pxEnTorno")}
                                <br></br>
                                <span className='font-bold text-3xl ml-2 flex '>
                                    {recommendedPrice.isFetching ?
                                        <div className='text-base'><Spinner animation="border" className='flex justify-center' /></div>
                                        :
                                        recommendedPrice.recommendedPriceData} €/t
                                </span>
                                {t("calc.pxAjustado")}.
                            </label>
                        </div>
                        <Col className=''>
                            <div className='mr-2 flex justify-end'>
                                <img src="https://www.airco2.earth/wid3.svg" className='' />

                            </div>

                        </Col>


                    </Col>


                </div>

            </main>


        </div>
    )
}
