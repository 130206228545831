import React, { useState, useContext, useEffect, useMemo } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Col, Modal, Row, Form } from 'react-bootstrap';
import UserService from '../../../services/UserService';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import OrganizacionesComponent from './OganizacionesComponent';
import Translations from '../../../services/Translations';
import FootprintYears from './graficas/FootprintYears'
import moment from 'moment';
import { formatThousands } from '../utils/Utils';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ByOrganization from './graficas/ByOrganization';
import ModalFinishCalculation from './ModalFinishCalculation';
import Banner from '../../../components/utils/Banner';
import { getFont } from '../../../services/FunctionsDataWhiteLabel';
import Joyride from 'react-joyride';

export default function MisCalculos() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    const handleButtonStartNow = () => {
        navigate('/footprint/calculationform', { state: { tour: true, idUser: null } });
    };

    const [calculosUsuario, setCalculosUsuario] = useState({
        data: [],
        isFetching: false
    });

    const [postHuellaExterna, setPostHuellaExterna] = useState({
        data: [],
        isFetching: false
    });

    const [message, setMessage] = useState({
        type: '',
        message: '',
        open: false,

    });


    const [modalFinalizarCalculo, setModalFinalizarCalculo] = useState({ view: false, idCalculation: null, idOrganization: null });
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [modalHuellaExterna, setModalHuellaExterna] = useState({ view: false, spinner: false });
    const [file, setFile] = useState(null);
    const [show, setShow] = useState(false);
    const [showData, setShowData] = useState(false);
    const handleCloseCompensar = () => setShow(false);
    const handleShow = () => setShow(true);
    const [fileError, setFileError] = useState('');
    const [idCalc, setIdCalc] = useState('')
    const [employees, setEmployees] = useState('')
    const [annualTurnover, setAnnualTurnover] = useState('')
    const [hasGlobalPactTrackingProtocol, setHasGlobalPactTrackingProtocol] = useState(undefined);
    const [hasCommittedGlobalPactInfringements, setHasCommittedGlobalPactInfringements] = useState(undefined);
    const [hasRelationWithWeaponManufacturingSelling, setHasRelationWithWeaponManufacturingSelling] = useState(undefined);
    const [averageGrossHourlyWageWomen, setAverageGrossHourlyWageWomen] = useState();
    const [averageGrossHourlyWageMen, setAverageGrossHourlyWageMen] = useState();
    const [numberInManagementCommitteeWomen, setNumberInManagementCommitteeWomen] = useState();
    const [numberInManagementCommitteeMen, setNumberInManagementCommitteeMen] = useState();
    const [hasConductsActivitiesInSensitiveBiodiversityAreas, setHasConductsActivitiesInSensitiveBiodiversityAreas] = useState(undefined);
    const [amountOfDischargesEmittedToWaterBodies, setAmountOfDischargesEmittedToWaterBodies] = useState();
    const [aiDescription, setAiDescription] = useState();
    const [number, setNumber] = useState();
    const [unit, setUnit] = useState();


    const handleShowData = (calculo) => {
        setShowData(true)
        setIdCalc(calculo.id)
        setEmployees(calculo.employees)
        setAnnualTurnover(calculo.annualTurnover)
        setHasCommittedGlobalPactInfringements(calculo.hasCommittedGlobalPactInfringements)
        setHasGlobalPactTrackingProtocol(calculo.hasGlobalPactTrackingProtocol)
        setHasRelationWithWeaponManufacturingSelling(calculo.hasRelationWithWeaponManufacturingSelling)
        setAverageGrossHourlyWageWomen(calculo.averageGrossHourlyWageWomen)
        setAverageGrossHourlyWageMen(calculo.averageGrossHourlyWageMen)
        setNumberInManagementCommitteeWomen(calculo.numberInManagementCommitteeWomen)
        setNumberInManagementCommitteeMen(calculo.numberInManagementCommitteeMen)
        setHasConductsActivitiesInSensitiveBiodiversityAreas(calculo.hasConductsActivitiesInSensitiveBiodiversityAreas)
        setAmountOfDischargesEmittedToWaterBodies(calculo.amountOfDischargesEmittedToWaterBodies)
        setAiDescription(calculo.iaDescription)
        setUnit(calculo.iaUnit)
        setNumber(calculo.iaNumber)

    }
    const handleCloseShowData = (e) => {
        setShowData(false)
        setIdCalc('')
        setMessage({ ...message, type: "", message: "" })
    }

    const handleFileChange = (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];

        const maxFileSize = process.env.REACT_APP_CH_CALCULATION;
        if (selectedFile && selectedFile.size > maxFileSize) {
            setFileError(t("footPrint.archivoGrande"));
        } else {
            setFile(selectedFile);
            setFileError("");
        }
    };

    useEffect(() => {
        const fetchCalculoUsuario = async () => {
            setCalculosUsuario({ data: calculosUsuario.data, isFetching: true });
            try {

                const response = await axios.get(`${process.env.REACT_APP_CH_CALCULO}?userId=${UserService.getUserId()}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                let objeto = response.data;

                // 1. Contar el número de organizaciones por año
                const recuentoOrganizacionesPorAnio = {};
                objeto.data.forEach(item => {
                    if (item.calculationStatus === 'FINALIZED' || item.calculationStatus === 'CURRENTYEAR') {
                        const anio = item.year;
                        recuentoOrganizacionesPorAnio[anio] = (recuentoOrganizacionesPorAnio[anio] || 0) + 1;
                    }
                });

                // 2. Agrupar por año y sumar los totales SOLO si hay más de una organización en ese año
                const totalesPorAnio = {};
                objeto.data.forEach(item => {
                    const anio = item.year;

                    if (item.calculationStatus === 'FINALIZED' && recuentoOrganizacionesPorAnio[anio] > 1) {
                        if (!totalesPorAnio[anio]) {
                            totalesPorAnio[anio] = {
                                totalKgCO2e: 0,
                                totalKgCO2eScope1: 0,
                                totalKgCO2eScope2: 0,
                                totalKgCO2eScope3: 0
                            };
                        }
                        totalesPorAnio[anio].totalKgCO2e += item.totalKgCO2e;
                        totalesPorAnio[anio].totalKgCO2eScope1 += item.totalKgCO2eScope1;
                        totalesPorAnio[anio].totalKgCO2eScope2 += item.totalKgCO2eScope2;
                        totalesPorAnio[anio].totalKgCO2eScope3 += item.totalKgCO2eScope3;
                    }
                });

                // 3. Crear los nuevos registros SOLO para los años con más de una organización
                const nuevosRegistros = Object.entries(totalesPorAnio).map(([anio, totales]) => ({
                    id: null,
                    userId: null,
                    year: parseInt(anio),
                    employees: null,
                    annualTurnover: null,
                    ...totales,
                    origin: 'Agregado'
                }));

                // 4. Añadir los nuevos registros al array 'data'
                objeto.data = [...objeto.data, ...nuevosRegistros];


                // 5. Ordenar 'data' por año y organización
                objeto.data.sort((a, b) => {
                    if (a.year !== b.year) {
                        return a.year - b.year;
                    }
                    return a.organization?.name?.localeCompare(b.organization?.name);
                });

                setCalculosUsuario({ data: objeto, isFetching: false });

            } catch (exception) {
                console.error(exception);
                setCalculosUsuario({ data: [], isFetching: false });
            }
        };
        fetchCalculoUsuario();
    }, [])



    const statusColor = (props) => {
        switch (props) {
            case "DRAFT":
                return "#b4e1ef";
            case "CURRENTYEAR":
                return "#b4e1ef";
            case "FINALIZED":
                return "#97cd9f";
            case "EXTERNAL":
                return "#cfbd5e";
            case "CANCELLED":
                return "#c7c7c7";
            case "ERROR":
                return "#e3b199";
        }
    }



    const handleEditCalculation = (calculationObjetc) => {
        navigate('/footprint/carbonmanager', { state: { calculationOutputDto: { data: calculationObjetc } } });

    }

    const [highlight, setHighlight] = useState(false);

    const handleHuellaExterna = (e) => {
        e.preventDefault();
        if (idOrg == '') {

            let elemento = document.querySelector('#organizationsRow');
            if (elemento) {
                elemento.scrollIntoView({ behavior: 'smooth' });


                setHighlight(true)
            }
        } else {
            setModalHuellaExterna({ view: true, spinner: true })
            let body = {
                userId: UserService.getUserId(),
                year: e.target.añoHuellaExterna.value,
                employees: Number(e.target.empleadosHuellaExterna.value),
                annualTurnover: Number(e.target.anualTurnoverHuellaExterna.value),
                totalKgCO2e: parseFloat(e.target.co2HuellaExterna.value),
                origin: e.target.entidadHuellaExterna.value,
                organizationId: idOrg,
                questionnaireId: e.target.tipoHuellaExterna.value,
                totalKgCO2eScope1: parseFloat(e.target.co2Alcance1.value),
                totalKgCO2eScope2: parseFloat(e.target.co2Alcance2.value),
                totalKgCO2eScope3: parseFloat(e.target.co2Alcance3.value)
            }


            const fetchHuellaExterna = async () => {
                try {
                    setPostHuellaExterna({ data: [], isFetching: true });
                    const response = await axios.post(`${process.env.REACT_APP_CH_CALCULATION}?isExternal=true`, body, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            // 'Access-Control-Allow-Origin': '*',
                            'Authorization': `Bearer ${UserService.getToken()}`
                        }
                    });
                    if (response.status === 200) {
                        let copyCalculationArray = [...calculosUsuario.data.data]
                        copyCalculationArray.push(response.data.data);
                        uploadDocumentHuellaExterna(response.data.data.id);
                        setCalculosUsuario({ data: { data: copyCalculationArray }, isFetching: false });
                    }
                    setPostHuellaExterna({ data: response.data, isFetching: false });
                } catch (exception) {
                    setPostHuellaExterna({ data: [], isFetching: false });
                }
            };
            fetchHuellaExterna();
            updateIdOrg('');
            setHighlight(false);
            // setModalHuellaExterna(false);
        }
    };

    const uploadDocumentHuellaExterna = async (idCalculation) => {
        let formData = new FormData();
        formData.append('files', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/documentation`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

        } catch (exception) {
            console.error(exception);
        }
        setModalHuellaExterna({ view: false, spinner: false });

    }


    const clasDiv = 'flex flex-wrap justify-center gap-3';
    const [numberOrg, setNumberOrg] = useState(2);
    let [idOrg, updateIdOrg] = useState('');
    let [total, updateTotal] = useState('')

    const childData = (v) => {
        updateIdOrg(v)

    }
    const childTotal = (v) => {
        updateTotal(v)
    }

    const yearActual = new Date().getFullYear();
    const years = [];

    for (let year = process.env.REACT_APP_CH_YEAR; year < yearActual; year++) {
        years.push(year);
    }
    const renderTooltipDatosinicio = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.modificar")}
        </Tooltip>
    );

    const renderTooltipVerDetalle = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.verDetalle")}
        </Tooltip>
    );

    const renderTooltipEditar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.editar")}
        </Tooltip>
    );

    const renderTooltipFinalizar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.finalizarCuestionario")}
        </Tooltip>
    );

    const renderTooltipDropzone = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.subirDoc")}
        </Tooltip>
    );

    const renderTooltipDocumentos = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.verDoc")}
        </Tooltip>
    );

    const renderTooltipReducction = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.reductionCommitment")}
        </Tooltip>
    )

    const renderTooltipFormularioTerceros = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.formContacts")}
        </Tooltip>
    );

    const renderTooltipFormularioTercerosNoDisponible = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.formContactsNoAvalaible")}
        </Tooltip>
    );

    const [questionnaries, setQuestionnaires] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            try {

                const response = await axios.get(`${process.env.REACT_APP_CH_QUESTIONNAIRES}?userId=${UserService.getUserId()}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setQuestionnaires({ data: response.data, isFetching: true });

            } catch (exception) {
                setQuestionnaires({ data: [], isFetching: true });
            }
        };
        fetchQuestionnaires();
    }, []);


    const useSortableData = (items, config = null) => {

        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            if (!items || items.length === 0) {
                return [];
            }
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    let valueA = a[sortConfig.key];
                    let valueB = b[sortConfig.key];

                    if (sortConfig.key.includes(".")) {
                        let params = sortConfig.key.split(".");
                        valueA = a[params[0]]?.[params[1]];
                        valueB = b[params[0]]?.[params[1]];
                    }

                    if (valueA === null || valueA === undefined || valueA === "") {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }

                    if (valueB === null || valueB === undefined || valueB === "") {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }

                    if (!isNaN(valueA) || moment(valueA).isValid()) {
                        if (valueA < valueB) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (valueA > valueB) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    } else {
                        if (
                            valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (
                            valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    }

                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(calculosUsuario.data.data);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };




    const updateViewFromParent = (props) => {
        setModalFinalizarCalculo({ view: false, idCalculation: null, idOrganization: null });

    }

    const name = UserService.getName().split(' ')[0]
    const numberFinalized = calculosUsuario?.data?.data?.filter(x => x.calculationStatus === 'FINALIZED').length



    const handleSubmitPut = async (e) => {
        e.preventDefault();

        const url = `${process.env.REACT_APP_CH_CALCULATION}`
        let data = {
            "employees": employees,
            "annualTurnover": annualTurnover,
            "id": idCalc
        };
        if (aiDescription !== undefined) {
            data = {
                ...data,
                "iaDescription": aiDescription
            };
        }
        if (number !== undefined) {
            data = {
                ...data,
                "iaNumber": number
            };
        }
        if (unit !== undefined) {
            data = {
                ...data,
                "iaUnit": unit
            };
        }
        if (hasGlobalPactTrackingProtocol !== undefined) {
            data = {
                ...data,
                "hasGlobalPactTrackingProtocol": hasGlobalPactTrackingProtocol
            };
        }
        if (hasCommittedGlobalPactInfringements !== undefined) {
            data = {
                ...data,
                "hasCommittedGlobalPactInfringements": hasCommittedGlobalPactInfringements
            };
        }
        if (hasRelationWithWeaponManufacturingSelling !== undefined) {
            data = {
                ...data,
                "hasRelationWithWeaponManufacturingSelling": hasRelationWithWeaponManufacturingSelling
            };
        }
        if (averageGrossHourlyWageWomen) {
            data = {
                ...data,
                "averageGrossHourlyWageWomen": averageGrossHourlyWageWomen
            };
        }
        if (averageGrossHourlyWageMen) {
            data = {
                ...data,
                "averageGrossHourlyWageMen": averageGrossHourlyWageMen
            };
        }
        if (numberInManagementCommitteeWomen) {
            data = {
                ...data,
                "numberInManagementCommitteeWomen": numberInManagementCommitteeWomen
            };
        }
        if (numberInManagementCommitteeMen) {
            data = {
                ...data,
                "numberInManagementCommitteeMen": numberInManagementCommitteeMen
            };
        }
        if (hasConductsActivitiesInSensitiveBiodiversityAreas !== undefined) {
            data = {
                ...data,
                "hasConductsActivitiesInSensitiveBiodiversityAreas": hasConductsActivitiesInSensitiveBiodiversityAreas
            };
        }
        if (amountOfDischargesEmittedToWaterBodies) {
            data = {
                ...data,
                "amountOfDischargesEmittedToWaterBodies": amountOfDischargesEmittedToWaterBodies
            };
        }



        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`

                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();

            setCalculosUsuario(prevState => {
                const indexToUpdate = prevState.data.data.findIndex(record => record.id === idCalc);

                if (indexToUpdate !== -1) {
                    const updatedData = [...prevState.data.data];
                    updatedData[indexToUpdate] = responseData.data;


                    return { ...prevState, data: { ...prevState.data, data: updatedData }, isFetching: false };
                }


                return prevState;
            });

            setMessage({ ...message, type: "success", message: t("account.actualizacion-ok") })




        } catch (error) {
            console.error('Error:', error.message);
            setMessage({ ...message, type: "error", message: t("account.actualizacion-ko") })
        }
    };



    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

    const handleClick = () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    const childSector = (v) => {
        }


    return (
        <div className={`sec-neutro-background ${getFont(UserService.getwhiteLabel())} flex h-screen overflow-hidden`}>

            {/* Sidebar */}

            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />



            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>

                    {calculosUsuario.isFetching ? (
                        <div className='flex justify-center'>
                            <Spinner className='mt-4' animation="border" variant="dark" />
                        </div>

                    ) : (
                        (calculosUsuario?.data?.data?.length !== undefined && calculosUsuario?.data?.data?.length !== 0) ? (
                            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                {/* Page header */}
                                <div className="mb-8">
                                    {/* Title */}
                                    <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("footPrint.miHuella")}</h1>

                                    <div className='flex justify-end'>

                                        <button className='btn-green mr-4 rounded-lg px-4 py-2' onClick={() => navigate('/footprint/calculationform', { state: { tour: false, idUser: null } })} >
                                            {t("footPrint.nuevoCalculo")}
                                        </button>
                                        <button className='btn-banner-o rounded-lg px-4 py-2' onClick={() => handleShow()}>
                                            {t("footPrint.compensar")}
                                        </button>

                                    </div>
                                </div>

                                {/* Content */}
                                <div className=' flex flex-wrap gap-8'>
                                    <Modal show={show} animation={false} data-aos="fade-up">
                                        <Modal.Header className='sec-green-background'>
                                            <Modal.Title>
                                                {t("footPrint.compensar")}

                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='text-center'>
                                            <p>{t("footPrint.compensar1")}</p><br />
                                            <p>{t("footPrint.compensar2")}.</p>

                                            <div className='flex space-x-4 justify-center mt-4'>
                                                <button onClick={() => handleCloseCompensar()} className='btn-cancel rounded-lg p-2'>{t("footPrint.cancelar")}</button>
                                                <a href='https://market.airco2.earth' target='_blank'
                                                    className={`btn-green rounded-lg p-2`}
                                                    onClick={() => handleCloseCompensar()}

                                                >
                                                    {t("footPrint.quieroCompensar")}




                                                </a>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                    <Modal show={showData} animation={false} data-aos="fade-up">
                                        <Modal.Header className='sec-green-background'>
                                            <Modal.Title>{t("footPrint.modificar")}</Modal.Title>
                                            <div className="flex justify-end">
                                                <button onClick={(e) => handleCloseShowData(e)}>
                                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                                </button>
                                            </div>
                                        </Modal.Header>
                                        <Modal.Body className=''>
                                            {(message.type !== '') &&
                                                <Banner className={`mb-2`} type={message.type} open setOpen={true}>{message.message}</Banner>}

                                            <Form onSubmit={(e) => handleSubmitPut(e)} className='space-y-4 '>
                                                <Row>
                                                    <Col xs md="6">
                                                        <label className='text-sm'>{t("footPrint.empleados")}</label>
                                                        <input value={employees} onChange={e => setEmployees(e.target.value)} type='number' min={0} id="empleados" className='' required></input>
                                                    </Col>
                                                    <Col xs md="6">
                                                        <label className='text-sm'>{t("footPrint.facturacionAnual")}</label>
                                                        <input value={annualTurnover} onChange={e => setAnnualTurnover(e.target.value)} type='number' min={0.01} step={0.01} id="facturacion" className='' ></input>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <label className="mb-3 mt-5 font-bold" htmlFor="indiceActividad">{t("footPrint.indiceActividad")}</label>
                                                    <div style={{ paddingRight: '1.8rem' }}>

                                                        <label className='text-sm'>{t("footPrint.ia-description")}</label>


                                                        <input value={aiDescription} onChange={e => setAiDescription(e.target.value)} type='text' id="ia-description" className='w-full' required={number || unit}></input>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <Col xs md="6">
                                                        <label className='text-sm'>{t("footPrint.ia-number")}</label>
                                                        <input value={number} onChange={e => setNumber(e.target.value)} type='number' min={0} id="ia-number" className='' required={aiDescription || unit} ></input>
                                                    </Col>
                                                    <Col xs md="6">
                                                        <label className='text-sm'>{t("footPrint.ia-unit")}</label>
                                                        <input value={unit} placeholder={t("footPrint.ia-unit-placeholder")} onChange={e => setUnit(e.target.value)} type='text' id="ia-unit" className='' required={aiDescription || number}  ></input>
                                                    </Col>
                                                </Row>
                                                <div className="text-right m-4">
                                                    <button type="button" onClick={handleClick} className="text-sm">
                                                        <div className='flex flex-inline'>
                                                            {showAdvancedOptions ? t("footPrint.hideAvancedOption") : t("footPrint.showAvancedOption")}
                                                            <svg xmlns="http://www.w3.org/2000/svg" className={`icon icon-tabler icon-tabler-chevron-up transition-transform duration-200  ${showAdvancedOptions ? 'transform rotate-180' : ''}`}
                                                                width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5"
                                                                stroke="#545a6d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M6 15l6 -6l6 6" />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                </div>


                                                <div id="advancedOptions" className={`my-4 ${!showAdvancedOptions ? "hidden" : ""}`}>
                                                    <p className='mb-3 mt-5 font-bold'>{t("footPrint.socialandwork")}</p>
                                                    <Row>
                                                        <Col xs md="12" className='flex justify-between items-end'>
                                                            <div className='flex flex-inline'>
                                                                <input
                                                                    name="hasGlobalPactTrackingProtocol"
                                                                    onChange={(e) => setHasGlobalPactTrackingProtocol(e.target.checked)}
                                                                    checked={hasGlobalPactTrackingProtocol}
                                                                    className='custom-width-md'
                                                                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                                    type="checkbox" />
                                                                <label className='ml-2 text-sm'>{t("footPrint.hasGlobalPactTrackingProtocol")}</label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs md="12" className='flex justify-between items-end'>
                                                            <div className='flex flex-inline '>
                                                                <input
                                                                    name="hasCommittedGlobalPactInfringements"
                                                                    onChange={(e) => setHasCommittedGlobalPactInfringements(e.target.checked)}
                                                                    checked={hasCommittedGlobalPactInfringements}
                                                                    className='custom-width-md'
                                                                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                                    type="checkbox" />
                                                                <label className='ml-2 text-sm'>{t("footPrint.hasCommittedGlobalPactInfringements")}</label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs md="12" className='flex justify-between items-end'>
                                                            <div className='flex flex-inline'>
                                                                <input
                                                                    name="hasRelationWithWeaponManufacturingSelling"
                                                                    onChange={(e) => setHasRelationWithWeaponManufacturingSelling(e.target.checked)}
                                                                    checked={hasRelationWithWeaponManufacturingSelling}
                                                                    className='custom-width-md'
                                                                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                                    type="checkbox" />
                                                                <label className='ml-2 text-sm'>{t("footPrint.hasRelationWithWeaponManufacturingSelling")}</label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs md="6" >
                                                            <label className='text-sm'>{t("footPrint.averageGrossHourlyWageWomen")}</label>
                                                            <input value={averageGrossHourlyWageWomen}
                                                                onChange={e => setAverageGrossHourlyWageWomen(e.target.value)}
                                                                type='number' min={0.01} step={0.01} id="facturacion" className='' ></input>
                                                        </Col>
                                                        <Col xs md="6" >
                                                            <label className='text-sm'>{t("footPrint.averageGrossHourlyWageMen")}</label>
                                                            <input value={averageGrossHourlyWageMen}
                                                                onChange={e => setAverageGrossHourlyWageMen(e.target.value)}
                                                                type='number' min={0.01} step={0.01} id="facturacion" className='' ></input>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs md="6" >
                                                            <label className='text-sm'>{t("footPrint.numberInManagementCommitteeWomen")}</label>
                                                            <input value={numberInManagementCommitteeWomen}
                                                                onChange={e => setNumberInManagementCommitteeWomen(e.target.value)}
                                                                type='number' min={0} step={1} id="facturacion" className='' ></input>
                                                        </Col>
                                                        <Col xs md="6" >
                                                            <label className='text-sm'>{t("footPrint.numberInManagementCommitteeMen")}</label>
                                                            <input value={numberInManagementCommitteeMen}
                                                                onChange={e => setNumberInManagementCommitteeMen(e.target.value)}
                                                                type='number' min={0} step={1} id="facturacion" className='' ></input>
                                                        </Col>
                                                    </Row>
                                                    <p className='font-bold mb-3 mt-5'>{t("footPrint.environment")}</p>

                                                    <Row>
                                                        <Col xs md="6" >
                                                            <div className='flex flex-inline items-center my-4'>
                                                                <input
                                                                    name="hasConductsActivitiesInSensitiveBiodiversityAreas"
                                                                    onChange={(e) => setHasConductsActivitiesInSensitiveBiodiversityAreas(e.target.checked)}
                                                                    checked={hasConductsActivitiesInSensitiveBiodiversityAreas}
                                                                    className='custom-width-md'
                                                                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                                    type="checkbox" />
                                                                <label className='ml-2 text-sm'>{t("footPrint.hasConductsActivitiesInSensitiveBiodiversityAreas")}</label>
                                                            </div>
                                                        </Col>
                                                        <Col xs md="6">
                                                            <label className='text-sm'>{t("footPrint.amountOfDischargesEmittedToWaterBodies")}</label>
                                                            <input value={amountOfDischargesEmittedToWaterBodies}
                                                                onChange={e => setAmountOfDischargesEmittedToWaterBodies(e.target.value)}
                                                                type='number' min={0.01} step={0.01} id="facturacion" className='' ></input>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='flex space-x-4 justify-center mt-4'>

                                                    <button type="button" className='btn-cancel rounded-lg p-2' onClick={(e) => handleCloseShowData(e)}>{t("footPrint.cancelar")}</button>
                                                    <div className={`btn-green rounded-lg p-2 `}>
                                                        <button type='submit'> {t("footPrint.guardar")}</button>
                                                    </div>
                                                </div>
                                            </Form>


                                        </Modal.Body>

                                    </Modal>

                                    <ModalFinishCalculation view={modalFinalizarCalculo.view} calculationId={modalFinalizarCalculo.idCalculation} idOrg={modalFinalizarCalculo.idOrganization} updateViewFromParent={updateViewFromParent} />



                                    <Modal animation={false} data-aos="fade-up" show={modalHuellaExterna.view} size='lg'>
                                        <Modal.Header className='sec-green-background'>
                                            <Modal.Title>{t("footPrint.huellaExterna")}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p id="organizationsRow" >{t("footPrint.huellaExternaTxt")}</p><br />
                                            <div className='mt-2'>
                                                <Form onSubmit={(e) => handleHuellaExterna(e)} className='space-y-4 '>
                                                    <Row className='mb-2'>
                                                        <p className={`text-center ${highlight ? 'text-red-600 font-semibold' : ''}`}>{(highlight ? "⚠️" : "") + t("footPrint.seleccionaOrg")}</p>
                                                        <OrganizacionesComponent
                                                            clase={clasDiv}
                                                            numberOrg={numberOrg}
                                                            childData={childData}
                                                            childTotal={childTotal}
                                                            childSector={childSector}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <Col xs md="6" className='flex flex-col'>
                                                            <label className='ml-2 text-sm'>{t("footPrint.tipoCalculo")}</label>
                                                            <select required defaultValue={''} list="tipoCalc" className="" id="tipoHuellaExterna" name="tipoCalc">
                                                                <option className='' disabled="disabled" value={''}>{t("footPrint.selecciona")}</option>
                                                                {
                                                                    questionnaries?.data.data != undefined ?
                                                                        questionnaries?.data.data.map((x, y) => {

                                                                            return (
                                                                                <option key={y} value={x.id}>{x.name}</option>
                                                                            )
                                                                        })
                                                                        : ""}
                                                            </select>
                                                        </Col>

                                                        <Col xs md="6" className='flex flex-col'>

                                                            <label className='ml-2 text-sm'>{t("footPrint.yearCalculo")}</label>
                                                            <select required defaultValue={''} list="yearCalc" className="" id="añoHuellaExterna" name="yearCalc">
                                                                <option className='italic' disabled="disabled" value={''}>{t("footPrint.chooseYear")}</option>
                                                                {years?.map((x, y) =>
                                                                    <option key={y} value={x}>{x}</option>
                                                                )}
                                                            </select>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col xs md="6" className='flex flex-col'>
                                                            <label className='ml-2 text-sm'>{t("footPrint.entidadCalculadora")}</label>
                                                            <input type='text' id="entidadHuellaExterna" className='' required></input>
                                                        </Col>
                                                        <Col xs md="6" className='flex flex-col'>
                                                            <label className='ml-2 text-sm'>{t("footPrint.docAcredita")}</label>
                                                            <input className=' py-1.5 pl-2 text-sm' style={{ borderWidth: '1px', borderColor: '#64748b' }} type="file" id="subir_archivoHuellaExterna" required onChange={handleFileChange} title={t("footPrint.seleccionaArchivo")} />
                                                            <p className='italic text-red text-xs'>{fileError}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs md="6" className='flex flex-col'>
                                                            <label className='ml-2 text-sm'>{t("footPrint.empleados")}</label>
                                                            <input type='number' min="0" step="1" id="empleadosHuellaExterna" className='' required></input>
                                                        </Col>
                                                        <Col xs md="6" className='flex flex-col'>
                                                            <label className='ml-2 text-sm'>{t("footPrint.facturacionAnual")} (€)</label>
                                                            <input type='number' min="0" step="0.01" id="anualTurnoverHuellaExterna" className='' required></input>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs md="6" className='flex flex-col'>
                                                            <label className='ml-2 text-sm'>Alcance 1 - Total KgCO<sub>2</sub> </label>
                                                            <input type='number' id="co2Alcance1" className='' required min="0" step="0.01"></input>
                                                        </Col>
                                                        <Col xs md="6" className='flex flex-col'>
                                                            <label className='ml-2 text-sm'>Alcance 2 - Total KgCO<sub>2</sub></label>
                                                            <input type='number' id="co2Alcance2" className='' required min="0" step="0.01"></input>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs md="6" className='flex flex-col'>
                                                            <label className='ml-2 text-sm'> Alcance 3 -Total KgCO<sub>2</sub>e</label>
                                                            <input type='number' id="co2Alcance3" className='' required min="0" step="0.01"></input>
                                                        </Col>
                                                        <Col xs md="6" className='flex flex-col'>
                                                            <label className='ml-2 text-sm'>Total KgCO<sub>2</sub>e</label>
                                                            <input type='number' id="co2HuellaExterna" className='' required min="0" step="0.01"></input>
                                                        </Col>
                                                    </Row>

                                                    <div className='flex space-x-4 justify-center mt-4'>
                                                        {/* {idOrg == '' ?
                                                                    <span>Debes seleccionar una organizacion para continuar</span> : ""
                                                                } */}
                                                        <button type="button" className='btn-cancel rounded-lg p-2' onClick={() => { setModalHuellaExterna({ view: false, spinner: false }); updateIdOrg(''); setHighlight(false); }}>{t("footPrint.cancelar")}</button>
                                                        <div className={`btn-green rounded-lg p-2 `}>
                                                            <button type='submit'> {modalHuellaExterna.spinner ? <Spinner animation="border" variant="light" /> : t("footPrint.guardar")}</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </Modal.Body>
                                    </Modal>


                                    <div className="container-simple-table w-full">
                                        <table className="simple-table">
                                            <thead className='text-center  text-xl sec-green-background text-white'>
                                                <tr className='py-4'>
                                                    <th>
                                                        <button type="button" onClick={() => requestSort('year')} className={`${getClassNamesFor('year')} text-underline`}>

                                                            {t("footPrint.year")}
                                                        </button>
                                                    </th>
                                                    <th>
                                                        <button type="button" onClick={() => requestSort('questionnaire.name')} className={`${getClassNamesFor('questionnaire.name')} text-underline`}>
                                                            {t("footPrint.tipo")}
                                                        </button>
                                                    </th>
                                                    <th>
                                                        <button type="button" onClick={() => requestSort('organization.name')} className={`${getClassNamesFor('organization.name')} text-underline`}>
                                                            {t("footPrint.organizacion")}
                                                        </button>
                                                    </th>
                                                    <th>
                                                        <button type="button" onClick={() => requestSort('calculationStatus')} className={`${getClassNamesFor('calculationStatus')} text-underline`}>
                                                            {t("footPrint.estado")}
                                                        </button>
                                                    </th>
                                                    <th>
                                                        <button type="button" onClick={() => requestSort('totalKgCO2e')} className={`${getClassNamesFor('totalKgCO2e')} text-underline`}>
                                                            Total tCO<sub>2</sub>e
                                                        </button>
                                                    </th>
                                                    <th className='text-end pr-5' >
                                                        {t("marketplace.forestListMP_actions")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className='text-center'>
                                                {calculosUsuario.data.length > 0 ? <tr className='flex justify-center'> <Spinner animation="border" variant="dark" /> </tr> :
                                                    calculosUsuario.data.data != undefined && items.map((calculo) => {

                                                        return (
                                                            <tr key={calculo.id}>
                                                                <td>{calculo.year}</td>
                                                                <td>{calculo.questionnaire == undefined ? "" : calculo.questionnaire.name}</td>
                                                                <td>{calculo.organization == undefined ? "" : calculo.organization.name}</td>
                                                                <td>
                                                                    <div className='rounded-full' style={{ backgroundColor: statusColor(calculo.calculationStatus) }}>
                                                                        {Translations.transalteStatusCalculations(calculo.calculationStatus)}
                                                                    </div>
                                                                </td>
                                                                <td>{formatThousands(calculo.totalKgCO2e / 1000)}t</td>
                                                                <td>
                                                                    {!calculo.id &&
                                                                        <div className='flex space-x-2 justify-end mr-4 items-center'>
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                delay={{ show: 250, hide: 400 }}
                                                                                overlay={renderTooltipVerDetalle}
                                                                            >
                                                                                <Link className='link-social flex items-center gap-2' to={`report/year/${calculo.year}`}>
                                                                                    <span className='italic text-sm'>{t("footPrint.verDetalle")}</span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-report-search" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                        <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                                                                                        <path d="M18 12v-5a2 2 0 0 0 -2 -2h-2" />
                                                                                        <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                                                        <path d="M8 11h4" />
                                                                                        <path d="M8 15h3" />
                                                                                        <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                                                                        <path d="M18.5 19.5l2.5 2.5" />
                                                                                    </svg>
                                                                                </Link>

                                                                            </OverlayTrigger>
                                                                        </div>}

                                                                    {
                                                                        calculo.calculationStatus === "DRAFT" || calculo.calculationStatus === "CURRENTYEAR" ?
                                                                            <div className='flex space-x-2 justify-end mr-4 items-center'>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                    overlay={renderTooltipDatosinicio}
                                                                                >
                                                                                    <button onClick={(e) => handleShowData(calculo)}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-dollar" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                                                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h3" />
                                                                                            <path d="M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                                                                                            <path d="M19 21v1m0 -8v1" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </OverlayTrigger>

                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                    overlay={calculo.thirdPartyForm ? renderTooltipFormularioTerceros : renderTooltipFormularioTercerosNoDisponible}

                                                                                >
                                                                                    <button
                                                                                        disabled={!calculo.thirdPartyForm}
                                                                                        onClick={() => navigate(`/footprint/formContacts/${calculo.id}`, { state: { data: { year: calculo.year, orgName: calculo.organization.name, status: calculo.calculationStatus, thirdPartyForm: calculo.thirdPartyForm, userId: calculo.userId } } })}>

                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-list-numbers" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M11 6h9" />
                                                                                            <path d="M11 12h9" />
                                                                                            <path d="M12 18h8" />
                                                                                            <path d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4" />
                                                                                            <path d="M6 10v-6l-2 2" />
                                                                                        </svg>


                                                                                    </button>
                                                                                </OverlayTrigger>


                                                                                {(calculo.calculationStatus == "CURRENTYEAR" || (calculo.calculationStatus == 'DRAFT' && calculo.year >= new Date().getFullYear() - 1)) &&
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={renderTooltipVerDetalle}
                                                                                    >
                                                                                        <Link className='link-social flex  items-center gap-2' to={`report/${calculo.id}`}>
                                                                                            <span className='italic text-sm'>{t("footPrint.verDetalle")}</span>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-report-search" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                                                                                                <path d="M18 12v-5a2 2 0 0 0 -2 -2h-2" />
                                                                                                <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                                                                <path d="M8 11h4" />
                                                                                                <path d="M8 15h3" />
                                                                                                <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                                                                                <path d="M18.5 19.5l2.5 2.5" />
                                                                                            </svg>
                                                                                        </Link>
                                                                                    </OverlayTrigger>}
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                    overlay={renderTooltipEditar}
                                                                                >
                                                                                    <button onClick={() => handleEditCalculation(calculo)}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                                                            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                                                            <path d="M16 5l3 3" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </OverlayTrigger>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                    overlay={renderTooltipDropzone}
                                                                                >
                                                                                    <button onClick={() => navigate(`/footprint/uploaddocuments/${calculo.id}`, { state: { data: { year: calculo.year, orgName: calculo.organization.name }, dropzone: true, delete: true } })}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-upload" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                                                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                                                            <path d="M12 11v6" />
                                                                                            <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </OverlayTrigger>

                                                                                {calculo.calculationStatus !== "CURRENTYEAR" &&
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={renderTooltipFinalizar}
                                                                                    >
                                                                                        <button onClick={() => setModalFinalizarCalculo({ view: true, idCalculation: calculo.id, idOrganization: calculo.organization.id })}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-square-rounded-check" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                <path d="M9 12l2 2l4 -4" />
                                                                                                <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </OverlayTrigger>}
                                                                            </div>
                                                                            : (calculo.calculationStatus === "FINALIZED" ?
                                                                                <div className='flex space-x-2 justify-end mr-4 items-center'>

                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={renderTooltipVerDetalle}
                                                                                    >
                                                                                        <Link className='link-social flex items-center gap-2' to={`report/${calculo.id}`}>
                                                                                            <span className='italic text-sm'>{t("footPrint.verDetalle")}</span>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-report-search" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                                                                                                <path d="M18 12v-5a2 2 0 0 0 -2 -2h-2" />
                                                                                                <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                                                                <path d="M8 11h4" />
                                                                                                <path d="M8 15h3" />
                                                                                                <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                                                                                <path d="M18.5 19.5l2.5 2.5" />
                                                                                            </svg>
                                                                                        </Link>

                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={renderTooltipReducction}
                                                                                    >
                                                                                        <button className='items-center gap-2' onClick={() => navigate(`reductionCommitment/${calculo.id}`, { state: { data: { year: calculo.year, orgName: calculo.organization.name } } })}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-checklist" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                <path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8" />
                                                                                                <path d="M14 19l2 2l4 -4" />
                                                                                                <path d="M9 8h4" />
                                                                                                <path d="M9 12h2" />
                                                                                            </svg>
                                                                                        </button>

                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={calculo.thirdPartyForm ? renderTooltipFormularioTerceros : renderTooltipFormularioTercerosNoDisponible}


                                                                                    >
                                                                                        <button
                                                                                            disabled={!calculo.thirdPartyForm}

                                                                                            onClick={() => navigate(`/footprint/formContacts/${calculo.id}`, { state: { data: { year: calculo.year, orgName: calculo.organization.name, status: calculo.calculationStatus, thirdPartyForm: calculo.thirdPartyForm, userId: calculo.userId } } })}>

                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-list-numbers" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                <path d="M11 6h9" />
                                                                                                <path d="M11 12h9" />
                                                                                                <path d="M12 18h8" />
                                                                                                <path d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4" />
                                                                                                <path d="M6 10v-6l-2 2" />
                                                                                            </svg>


                                                                                        </button>
                                                                                    </OverlayTrigger>



                                                                                    {calculo.hasDocuments &&
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            delay={{ show: 250, hide: 400 }}
                                                                                            overlay={renderTooltipDocumentos}
                                                                                        >
                                                                                            <button onClick={() => navigate(`/footprint/uploaddocuments/${calculo.id}`, { state: { data: { year: calculo.year, orgName: calculo.organization.name }, dropzone: false, delete: false } })}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                                                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                                                                </svg>
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    }
                                                                                </div>

                                                                                : calculo.calculationStatus === "EXTERNAL" &&
                                                                                <div className='flex space-x-2 justify-end mr-4'>
                                                                                    {calculo.hasDocuments &&
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            delay={{ show: 250, hide: 400 }}
                                                                                            overlay={renderTooltipDocumentos}
                                                                                        >
                                                                                            <button onClick={() => navigate(`/footprint/uploaddocuments/${calculo.id}`, { state: { data: { year: calculo.year, orgName: calculo.organization.name }, dropzone: false, delete: false } })}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                                                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                                                                </svg>
                                                                                            </button>
                                                                                        </OverlayTrigger>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                    }
                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>






                                </div>
                                <div className=' flex justify-between mt-8 mb-4 flex-wrap gap-2'>
                                    <div className=''>

                                        <a className='btn flex gap-1  btn-banner-o rounded-lg px-4 py-2' href="/pdf/GuiaReduccionHuellaDeCarbono.pdf" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-square-rounded" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 9h.01" />
                                                <path d="M11 12h1v4h1" />
                                                <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                                            </svg>
                                            <span>{t("footPrint.reducirHuella")}</span>
                                        </a>
                                    </div>
                                    <div className=''>
                                        <button className='btn btn-green rounded py-2 px-4' onClick={() => setModalHuellaExterna({ view: true, spinner: false })}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-corner-down-right mr-2" width="16" height="16" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M6 6v6a3 3 0 0 0 3 3h10l-4 -4m0 8l4 -4" />
                                            </svg> {t("footPrint.addCalculoExterno")}
                                        </button>
                                    </div>


                                </div>
                                {(UserService.getPlan() !== "" && UserService.getPlan() !== undefined) &&
                                    numberFinalized >= 2 &&
                                    <div>
                                        <h1 className="text-2xl text-gray-800 font-bold mb-8">{t("footPrint.resumen")}</h1>
                                        <div className="grid grid-cols-12 gap-6">
                                            <FootprintYears data={calculosUsuario.data.data}></FootprintYears>
                                            <ByOrganization data={calculosUsuario.data.data}></ByOrganization>
                                        </div>
                                    </div>
                                }
                            </div>




                        ) : (

                            <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
                                <h1 className='h2 text-center '>{name},&nbsp;{t("footPrint.bienvenida")}👋</h1>
                                <div className='text-center mt-8 '>
                                    <p className='px-4 h5'>{t("footPrint.paso")}</p>
                                    <p className='px-6'>{t("footPrint.paso2")}</p>
                                    <p className='px-6'>{t("footPrint.paso3")}</p>
                                    <br />
                                    <div className='flex justify-center mb-2'>
                                        <button className='btn btn-banner welcome-page-step-0' onClick={handleButtonStartNow}>
                                            <span className='pl-2 text-sm text-white font-bold'>{t("dashboardExcel.start")}</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='float-right'>
                                    <img src="/arbol3.svg" />
                                </div>
                            </div>
                        )

                    )}
                </main>
            </div>
        </div>
    )
}
