import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { IdiomContext } from "../../../../../context/createcontext";
import { formatThousands, formatDate } from "../../../utils/Utils";
import ModalGoogleDistance from '../../ModalGoogleDistance';

export default function InputsQuestions({
    idExternal,
    questions,
    idQuestion,
    idContact,
    answerTableData,
    externalData,
    setQuestions,
    currentIndexQuestion,
    year, 
    lastAnswer
}) {

    const [lng, updateLng] = useContext(IdiomContext)
    const [currentInputIndex, setCurrentInputIndex] = useState(0);
    const [t, i18n] = useTranslation("global");
    const [show, setShow] = useState(false)

    const [selectedConceptLevel1, setSelectedConceptLevel1] = useState(null)
    const [selectedConceptLevel2, setSelectedConceptLevel2] = useState(null)
    const [selectedConceptLevel3, setSelectedConceptLevel3] = useState(null)
    const [selectOptionsConceptLevel2, setSelectOptionsConceptLevel2] = useState(null)
    const [selectOptionsConceptLevel3, setSelectOptionsConceptLevel3] = useState(null)
    const [answerTable, setAnswerTable] = useState(false)
    const [googleDistanceModal, setGoogleDistanceModal] = useState({ view: false, idInput: null, origenTerceros: null });
    const [kmModal, setkmModal] = useState('');
    const [days, setDays] = useState(5);
    const [weeks, setWeeks] = useState(48);
    const [isRoundTripChecked, setIsRoundTripChecked] = useState(true);
    const [colLength, setColLength] = useState();
    const [width, setWidth] = useState(window.innerWidth);
    const [mobileView, setMobileView] = useState();
    const [sliceProps1, setSliceProps1] = useState(0);
    const [sliceProps2, setSliceProps2] = useState(1);
    const sharedVehicleOptionTrue = ['Turismos (M1)', 'Ciclomotores (L1e, L2e)', 'Motocicletas (L3e, L4e, L5e, L6e, L7e)', 'Ciclomotores y motocicletas (L)'];

    const [savedAnswer, setSavedAnswer] = useState({
        data: [],
        isFetching: false,
    });

    const [dataQuestions, setDataQuestions] = useState({
        data: [],
        isFetching: false
    });

    const fetchDataQuestions = async () => {
        setDataQuestions({ data: dataQuestions.data, isFetching: true });
        try {
            if (idQuestion) {
                await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternal}/question/${idQuestion}?externalData=${externalData}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }).then(response => {
                    setDataQuestions({ data: response.data, isFetching: false });
                });
            }
        } catch (exception) {
            console.error(exception)
            setDataQuestions({ data: [], isFetching: false });
        }
    };

    useEffect(() => {
        // setFormSavingData({});
        fetchDataQuestions();
        setCurrentInputIndex(0);
    }, [questions, idQuestion])

    const [formSavingData, setFormSavingData] = useState({});


    const handleInputChange = (e) => {
        const { name, value, type } = e.target;

        let desiredObject0 = sortedInputData.find(obj => obj.input.inputOptions === 'QUANTITY0');
        if (selectedConceptLevel1 != null && selectedConceptLevel1.label.includes("Turismo")) { //ROAD DIST Y SUPP
            if (name.toLowerCase() === "quantity2") {
                if (value !== "") {
                    if (desiredObject0) { desiredObject0.required = true; }
                } else {
                    if (desiredObject0) { desiredObject0.required = false; }
                }
            }
        } else {
            if (desiredObject0) { desiredObject0.required = false; }
        }

        const isCheckbox = type === 'checkbox';
        setFormSavingData((prevState) => ({
            ...prevState,
            [name.toLowerCase()]: isCheckbox ? e.target.checked : value,
        }));
    }

    const handleSelectChange = (e, inputName) => {

        const { value } = e;

        setFormSavingData((prevState) => ({
            ...prevState,
            [inputName.toLowerCase()]: value,
        }));
    }

    const handleSelectChangeSecondSelect = (e, inputName, mainQuestionValue) => {
        const { value } = e;
        setFormSavingData((prevState) => ({
            ...prevState,
            [inputName.toLowerCase()]: value,
            mainQuestionId: mainQuestionValue,
        }));
    }

    const inputData = dataQuestions?.data?.data?.externalQuestionInputsDto
    let sortedInputData = dataQuestions?.data?.data?.externalQuestionInputsDto?.slice().sort((a, b) => a.position - b.position);

    if (sortedInputData != undefined && (idQuestion == 3 || idQuestion == 4)) {
        sortedInputData = sortedInputData?.filter((v) => v.input.inputType != "DATE")
    }

    if (sortedInputData != undefined && idQuestion == 6 && lastAnswer?.questionId == 47 && lastAnswer?.quantity3 != undefined ) {
        sortedInputData = sortedInputData?.filter((v) => v.input.inputOptions != "QUANTITY3")
    }

    useEffect(() => {
        if (sortedInputData != undefined) {
            // setColLength(sortedInputData.length > 4 ? 4 : 12 / sortedInputData.length);
            setColLength(4)
        }
    }, [sortedInputData])

    const handleMoveInput = (direction) => {
        if (direction != 'next' && direction != 'previous' && !mobileView) {
            setCurrentInputIndex(direction);
        } else {
            if (direction === 'next' && currentInputIndex < inputData.length - 1) {
                setCurrentInputIndex(currentInputIndex + 1);
            } else if (direction === 'previous' && currentInputIndex > 0) {
                setCurrentInputIndex(currentInputIndex - 1);
            }
        }
    };


    useEffect(() => {
        const objetoTransformado = { ...formSavingData };
        sortedInputData?.map(inputObj => {
            const input = inputObj.input;
            const options = input.options;
            if (options != undefined && options.length === 1) {
                if (objetoTransformado[input.inputOptions.toLowerCase()] === undefined || '') {
                    objetoTransformado[input.inputOptions.toLowerCase()] = options[0].id;
                }
            }
        });
        if (externalData) {
            objetoTransformado["mainQuestionId"] = dataQuestions.data?.data?.idQuestionToExternalData
        }

        setFormSavingData(objetoTransformado);
    }, [inputData, selectedConceptLevel3]);


    const dataAnswer = (savedData) => {
        // setAnswerTable(true);
        answerTableData(savedData);
    };

    const [savingSpinner, setSavingSpinner] = useState(false);
    const handlerSubmit = e => {
        e.preventDefault();


        formSavingData["contactId"] = parseInt(idContact);
        formSavingData.organizationBuildingId = formSavingData.sedes != undefined ? formSavingData.sedes : formSavingData.organizationBuildingId;
        delete formSavingData.sedes;
        formSavingData.registrationDate = formSavingData.registration_date;
        delete formSavingData.registration_date;


        const fetchSavedAnswer = async () => {
            setSavingSpinner(true)
            const url = `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternal}/answer/${idQuestion}`;
            try {
                setSavedAnswer({ savedAnswer: savedAnswer.data, isFetching: true });
                const response = await axios.post(url, [formSavingData], {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
                setSavedAnswer({ data: response.data, isFetching: false });
                if (response.status === 200) {
                    //Mostrar pantalla de resultados
                    dataAnswer(response.data)

                    const updatedQuestions = [...questions];
                    updatedQuestions[currentIndexQuestion].answersByContact += 1;
                    setQuestions(updatedQuestions)
                }
            } catch (exception) {
                setSavedAnswer({ data: [], isFetching: false });
            }
            setSavingSpinner(false)

        }
        fetchSavedAnswer();
    }

    const isInputAnswered = (inputObj) => {
        const input = inputObj.input;
        const inputRequired = inputObj.required;
        if (inputRequired) {
            const inputOptions = input.inputOptions.toLowerCase();
            const inputValue = formSavingData[inputOptions];
            return inputValue !== undefined && inputValue !== null && inputValue !== '';
        }
        return true;
    };

    const isInputAnsweredWithoutCheckingRequired = (inputObj) => {
        const input = inputObj.input;
        const inputOptions = input.inputOptions.toLowerCase();
        const inputValue = formSavingData[inputOptions];
        return inputValue !== undefined && inputValue !== null && inputValue !== '';
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid var(--airco2-green-color)' : provided.border,
            boxShadow: state.isFocused ? '0 0 0 1px var(--airco2-green-color)' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--airco2-green-color)' : provided.borderColor,
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--airco2-green-color)' : provided.backgroundColor,
            '&:hover': {
                backgroundColor: 'var(--airco2-green-color)',

            },
            color: state.isSelected ? 'var(--airco2-green-color)' : provided.color,
        }),
    };


    const currentInput =
        sortedInputData && currentInputIndex < sortedInputData.length
            ? sortedInputData[currentInputIndex]?.input
            : null;


    let subtitle, title;
    if (currentInput && currentInput.options) {
        if (Array.isArray(currentInput.options) && currentInput.options.length > 0) {
            if (currentInput.options[0].listGroupEmissions && currentInput.options[0].listGroupEmissions.length > 0) {
                subtitle = currentInput.options[0].listGroupEmissions[0]?.subtitle;
                title = currentInput.options[0].listGroupEmissions[0]?.title;
            }
        }
    }

    const initSecondariesSelectedConcept = (e) => {
        if (currentInput?.inputOptions.toLowerCase() === "concept") {
            let selected1 = undefined;
            if (e === undefined) {
                if (selectedConceptLevel1 !== null) {
                    selected1 = selectedConceptLevel1.label;
                }
                if (currentInput.options.length === 1) {
                    setSelectedConceptLevel1({ label: currentInput.options[0].name, value: currentInput.options[0].name })
                    selected1 = currentInput.options[0].name;
                }
            } else {
                setSelectedConceptLevel1(e)
                selected1 = e.label
            }
            if (selected1 !== undefined) {
                const optionsLevel2 = currentInput.options.filter(x => x.name === selected1)[0].listGroupEmissions;
                setSelectOptionsConceptLevel2(optionsLevel2)
                if (optionsLevel2.length === 1) {
                    //Si el segundo input solo tiene un valor, replicamos su onclick
                    setSelectedConceptLevel2({ label: optionsLevel2[0].name, value: optionsLevel2[0].mainQuestion });
                    //setSelectedConceptLevel3(null)
                    const optionsLevel3 = optionsLevel2[0].combustible
                    setSelectOptionsConceptLevel3(optionsLevel3)
                    if (optionsLevel3.length === 1) {
                        setSelectedConceptLevel3({ label: optionsLevel3[0].name, value: optionsLevel3[0].id });
                        handleSelectChangeSecondSelect({ label: optionsLevel3[0].name, value: optionsLevel3[0].id }, "concept", optionsLevel2[0].mainQuestion);
                    }
                } else { }
                //  setSelectedConceptLevel2(null);
            }
        }

        if (currentInput?.inputOptions.toLowerCase() === "concept") {
            setFormSavingData(prevState => {
                const newState = { ...prevState };
                let desiredObject2 = sortedInputData.find(obj => obj.input.inputOptions === 'QUANTITY2');
                let desiredObject0 = sortedInputData.find(obj => obj.input.inputOptions === 'QUANTITY0');

                if (e !== undefined) {
                    if (e?.label.includes("Turismo")) {
                        if (desiredObject2) { desiredObject2.required = false; }
                        if (newState.quantity2 !== undefined) {
                            if (desiredObject0) { desiredObject0.required = true; }
                        }

                    } else {
                        if (desiredObject2 && idQuestion !=6) { desiredObject2.required = true; }
                        if (desiredObject0) { desiredObject0.required = false; }
                        if ('quantity0' in newState) {
                            delete newState.quantity0;  // Elimina la clave 'quantity0' pq los que no son Turismo no tienen carga total
                        }

                    }
                }
                return newState;
            });
        }

    }

    useEffect(() => {
        initSecondariesSelectedConcept();
    }, [currentInput]);


    const handleModalDistanceCalculation = () => {
        setGoogleDistanceModal({ view: true, idInput: `quantity-distance-input` });


    }

    const handleModalDistanceItinere = () => {
        setGoogleDistanceModal({ view: true, idInput: `quantity-distance-input`, origenTerceros: true });
        setShow(false)

    }
    const handleModalCalculationItininere = () => {
        const inputElement = document.getElementById('quantity-distance-input');
        let distanceFinal = ((kmModal * days * weeks) * (isRoundTripChecked ? 2 : 1)).toFixed(2)
        if (inputElement) {
            inputElement.value = distanceFinal
        }

        setShow(false)
        if (idQuestion === 5) {
            setFormSavingData((prevState) => ({
                ...prevState,
                ["quantity"]: inputElement.value,
            }))
        }


    }

    const renderTooltipDistancia = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.calcularDistancia")}
        </Tooltip>
    );

    const handleDistanceFromGoogle = (data) => {

        if (data != undefined) {
            if (idQuestion === 5) {
                setkmModal(data.distance)
            }
            if (idQuestion !== 5) {
                setFormSavingData((prevState) => ({
                    ...prevState,
                    ["quantity"]: data.distance,
                }))
            };
        }
    }

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (width <= 760) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    }, [width]);

    useEffect(() => {
        if (sortedInputData != undefined) {
            if (mobileView) {
                setSliceProps1(currentInputIndex);
                setSliceProps2(currentInputIndex + 1);
            } else {
                setSliceProps1(0);
                setSliceProps2(sortedInputData.length);
            }
        }
    }, [mobileView, currentInputIndex, sortedInputData]);

    

    return (
        <div>
            <ModalGoogleDistance
                googleDistanceModal={googleDistanceModal}
                setGoogleDistanceModal={setGoogleDistanceModal}
                handleDistanceFromGoogle={handleDistanceFromGoogle}
                setShow={setShow}
            />



            <Modal show={show}>

                <ModalHeader className='sec-green-background'>
                    <Modal.Title className="text-white">{t("footPrint.calcularDistancia")}</Modal.Title>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <span>
                            {t("footPrint.explicacionDistancia")}
                        </span>
                    </div>
                    <div className='mt-4'>
                        <label className='flex items-end justify-between'>
                            {t("footPrint.km")}
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltipDistancia}
                            >
                                <button type='button' >
                                    <svg onClick={() => handleModalDistanceItinere()} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-google-maps" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M12 9.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                        <path d="M6.428 12.494l7.314 -9.252" />
                                        <path d="M10.002 7.935l-2.937 -2.545" />
                                        <path d="M17.693 6.593l-8.336 9.979" />
                                        <path d="M17.591 6.376c.472 .907 .715 1.914 .709 2.935a7.263 7.263 0 0 1 -.72 3.18a19.085 19.085 0 0 1 -2.089 3c-.784 .933 -1.49 1.93 -2.11 2.98c-.314 .62 -.568 1.27 -.757 1.938c-.121 .36 -.277 .591 -.622 .591c-.315 0 -.463 -.136 -.626 -.593a10.595 10.595 0 0 0 -.779 -1.978a18.18 18.18 0 0 0 -1.423 -2.091c-.877 -1.184 -2.179 -2.535 -2.853 -4.071a7.077 7.077 0 0 1 -.621 -2.967a6.226 6.226 0 0 1 1.476 -4.055a6.25 6.25 0 0 1 4.811 -2.245a6.462 6.462 0 0 1 1.918 .284a6.255 6.255 0 0 1 3.686 3.092z" />
                                    </svg>
                                </button>

                            </OverlayTrigger>


                        </label>
                        <input className='rounded-lg w-full' id={'quantity-distance-inputItininere'}
                            defaultValue={kmModal} type='number'
                            min={1} step={0.1} onChange={(e) => {
                                let roundedValue = parseFloat(e.target.value).toFixed(2);

                                setkmModal(e.target.value < 1 || e.target.value === '' ? 0 : roundedValue)
                            }} required />

                    </div>
                    <div className='flex  mt-4 gap-2'>
                        <div>
                            <label className=''>{t("footPrint.diasSemana")}

                            </label>
                            <input className='rounded-lg w-full' defaultValue={days === 1 ? "" : days} type='number' min={1} step={1} max={7}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setDays(value < 1 || value === '' ? 1 : value);
                                }}
                                required />

                        </div>
                        <div>
                            <label className=''>{t("footPrint.semanasYear")}

                            </label>
                            <input className='rounded-lg w-full' defaultValue={weeks === 1 ? "" : weeks} type='number' min={1} step={1}
                                onChange={(e) => {
                                    const value = e.target.value;

                                    setWeeks(value < 1 || value === '' ? 1 : value);
                                }}
                                required />

                        </div>



                    </div>
                    <div className='flex justify-between mx-1 items-center mt-4'>
                        <label className='flex items-center ' >

                            <input className='mr-1' type='checkbox' checked={isRoundTripChecked} onChange={() => { setIsRoundTripChecked(!isRoundTripChecked) }} />
                            <span> {t("footPrint.idaYvuelta")}</span>
                        </label>

                        <div className='text-center'>
                            <><h1>{t("footPrint.distancia")}:</h1>  <b>{formatThousands((kmModal * days * weeks) * (isRoundTripChecked ? 2 : 1))} km</b></>
                        </div>


                    </div>
                    <div className='mt-4 flex justify-center'>
                        <button className='btn btn-green rounded-sm' type="submit" onClick={() => { handleModalCalculationItininere() }} >{t("footPrint.guardarDistancia")}</button>
                    </div>
                </ModalBody>
                <ModalFooter className='flex justify-center'>
                    <button type='button' className="btn-sm btn-cancel rounded-lg" onClick={() => { setShow(false) }}>
                        {t("footPrint.cerrarCerrar")}
                    </button>
                </ModalFooter>

            </Modal>

            <div id="firstComponent flex justify-center" className=''>

                {sortedInputData === undefined &&
                    <div className='text-center'>
                        <Spinner animation="border" variant="dark" />
                        <p>{t("fpThird.getQuestions")}</p>
                    </div>
                }

                {((idQuestion == 3 || idQuestion == 4) && sortedInputData !== undefined) &&
                    <p className='text-sm mb-4'>Puedes subir la información viaje por viaje, rellenando todos estos datos y especificando la fecha de cada viaje, o utilizar un sumatorio. También puedes usar el campo de observaciones para indicarnos cualquier cuestión adicional que quieras comunicar sobre ese viaje, ya sea de forma total o parcial. </p>
                }


                {(sortedInputData !== undefined && mobileView) &&
                    <div className='my-2 justify-center custom-width-div mb-4'>
                        <label>{t("fpThird.indiceDatos")}</label>
                        <div className='grid-container'>
                            {sortedInputData?.map((v, i) => {
                                return (
                                    <div key={i} className={`grid-item relative ${currentInputIndex === i ? 'sec-green-background shadow' : 'bg-white'}`}>
                                        {isInputAnsweredWithoutCheckingRequired(v) &&
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: '1px', right: '1px' }} className="icon icon-tabler icon-tabler-circle-check-filled" width="18" height="18" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="var(--airco2-orange-color)" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" strokeWidth="0" />
                                            </svg>
                                        }
                                        <div className={`flex items-center text-xs text-center p-2  ${currentInputIndex === i && 'font-semibold text-white'}`}>
                                            <p>{v.input.title}{v.required && "*"}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }

                {sortedInputData !== undefined && <form id="footthird-form" className='' onSubmit={(e) => handlerSubmit(e)}>
                    <div className='row justify-start'>

                        {sortedInputData?.slice(sliceProps1, sliceProps2).map((inputObj, i) => {
                            const input = inputObj.input;
                            const options = input.options;
                            if (input.inputOptions.toLowerCase() === "shared" && !inputObj.required && !sharedVehicleOptionTrue.includes(selectedConceptLevel2?.label)) {
                                return <div className={`col-12 col-md-${colLength + 2} col-xxl-${colLength} mb-4 }`}>
                                    <label className={`custom-width-px font-bold`} >
                                        {input.title}
                                    </label>
                                    <select disabled className='custom-input-style' type='text' style={{ backgroundColor: 'rgb(240, 240, 240)' }}></select>
                                    <p className="text-xs italic">{t("fpThird.noNecesary")}</p>
                                </div>
                            }

                            if (input.inputOptions.toLowerCase() === "quantity0" && !inputObj.required) {
                                return <div className={`col-12 col-md-${colLength + 2} col-xxl-${colLength} mb-4 }`}>
                                    <label className={`custom-width-px font-bold`} >
                                        {input.title}
                                    </label>
                                    <input disabled className='custom-input-style' type='number' style={{ backgroundColor: 'rgb(240, 240, 240)' }}></input>
                                    <p className="text-xs italic">{t("fpThird.noNecesary")}</p>
                                </div>
                            }
                            return (
                                <>
                                    <div id="DIV PRINCIPAL" className={`col-12 col-md-${colLength + 2} col-xxl-${colLength} mb-4 ${input.inputOptions.toLowerCase() === "concept" && options.length === 1 && selectedConceptLevel2 !== null ? "hidden" : ""}`} key={input.id}>
                                        <label className={`custom-width-px flex items-center justify-between`} >
                                            <div className='flex flex-col justify-between'>
                                                <span className='font-bold'>{input.title}
                                                    {inputObj.required &&
                                                        <span className=" pl-1 text-red-500">*</span>
                                                    }
                                                </span>
                                                {mobileView && <p className='text-sm'>{inputObj.subtitle}</p>}
                                            </div>

                                            {input.id == 4 && idQuestion === 5 &&
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipDistancia}
                                                >
                                                    <button type='button' onClick={() => setShow(true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calculator" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M4 3m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                                            <path d="M8 7m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z" />
                                                            <path d="M8 14l0 .01" />
                                                            <path d="M12 14l0 .01" />
                                                            <path d="M16 14l0 .01" />
                                                            <path d="M8 17l0 .01" />
                                                            <path d="M12 17l0 .01" />
                                                            <path d="M16 17l0 .01" />
                                                        </svg>
                                                    </button>
                                                </OverlayTrigger>
                                            }


                                            {input.id == 4 && idQuestion !== 5 &&
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipDistancia}
                                                >
                                                    <button type={'button'} >
                                                        <svg onClick={() => handleModalDistanceCalculation()} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-google-maps" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M12 9.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                                            <path d="M6.428 12.494l7.314 -9.252" />
                                                            <path d="M10.002 7.935l-2.937 -2.545" />
                                                            <path d="M17.693 6.593l-8.336 9.979" />
                                                            <path d="M17.591 6.376c.472 .907 .715 1.914 .709 2.935a7.263 7.263 0 0 1 -.72 3.18a19.085 19.085 0 0 1 -2.089 3c-.784 .933 -1.49 1.93 -2.11 2.98c-.314 .62 -.568 1.27 -.757 1.938c-.121 .36 -.277 .591 -.622 .591c-.315 0 -.463 -.136 -.626 -.593a10.595 10.595 0 0 0 -.779 -1.978a18.18 18.18 0 0 0 -1.423 -2.091c-.877 -1.184 -2.179 -2.535 -2.853 -4.071a7.077 7.077 0 0 1 -.621 -2.967a6.226 6.226 0 0 1 1.476 -4.055a6.25 6.25 0 0 1 4.811 -2.245a6.462 6.462 0 0 1 1.918 .284a6.255 6.255 0 0 1 3.686 3.092z" />
                                                        </svg>
                                                    </button>
                                                </OverlayTrigger>
                                            }
                                        </label>

                                        {input.inputType === 'LIST' && input.inputOptions !== 'SHARED' &&



                                            <Select
                                                key={`input_${input.inputOptions}_${input.id}`}
                                                onMenuOpen={() => handleMoveInput(i)}
                                                name={`${input.inputOptions}`}
                                                className={`custom-width-px `}
                                                options={options.sort((a, b) => a.name.localeCompare(b.name))
                                                    .map(option => ({ label: option.name, value: (input.inputOptions.toLowerCase() === "concept" ? option.name : option.id), hijos: option.listGroupEmissions }))}
                                                styles={customStyles}
                                                placeholder={`${t('footPrint.selecciona')}...`}
                                                defaultValue={input.inputOptions.toLowerCase() === "concept" ?
                                                    (selectedConceptLevel1 !== null ?
                                                        selectedConceptLevel1
                                                        : (options.length === 1 ?
                                                            {
                                                                label: options[0].name,
                                                                value: options[0].name
                                                            }
                                                            : ""))
                                                    : (options.length === 1 ?
                                                        {
                                                            label: options[0].name,
                                                            value: options[0].name
                                                        }
                                                        : {
                                                            value: formSavingData[input.inputOptions.toLowerCase()],
                                                            label: options.filter(x => x.id === formSavingData[input.inputOptions.toLowerCase()])[0]?.name
                                                        })}
                                                onChange={(e) => {
                                                    if (input.inputOptions.toLowerCase() === "concept") {
                                                        setSelectedConceptLevel2(null)
                                                        setSelectedConceptLevel3(null)
                                                        initSecondariesSelectedConcept(e);

                                                    } else {
                                                        handleSelectChange(e, input.inputOptions)
                                                    }
                                                }}
                                                required={inputObj.required ? true : false}
                                            />

                                        }

                                        {(input.inputType === 'LIST' && input.inputOptions === 'SHARED' &&  sharedVehicleOptionTrue.includes(selectedConceptLevel2?.label))  &&
                                            <Select
                                                key={`input_${input.inputOptions}_${input.id}`}
                                                id="input-quantity2"
                                                name={`${input.inputOptions}`}
                                                className={`custom-width-px `}
                                                options={
                                                    selectedConceptLevel2?.label !== 'Turismos (M1)' ?
                                                    options.slice(0,2).sort((a, b) => a.id - b.id)
                                                    .map(option => ({ label: option.name, value: option.id, hijos: option.listGroupEmissions }))
                                                    : 
                                                    options.sort((a, b) => a.id - b.id)
                                                    .map(option => ({ label: option.name, value: option.id, hijos: option.listGroupEmissions }))
                                                }
                                                styles={customStyles}
                                                placeholder={`${t('footPrint.selecciona')}...`}

                                                defaultValue={formSavingData['quantity2'] ? {

                                                    label: options[formSavingData['quantity2'] - 1]?.name,
                                                    value: options[formSavingData['quantity2'] - 1]?.id
                                                } : {
                                                    label: options[0]?.name,
                                                    value: options[0]?.id
                                                }}
                                                onChange={(e) => handleSelectChange(e, 'quantity2', input.inputOptions)}

                                                required={inputObj.required ? true : false}
                                            />

                                        }

                                        {input.inputType === 'INT' &&
                                            <input key={`input_${input.inputOptions}_${input.id}`}
                                                name={`${input.inputOptions}`}
                                                onClick={() => handleMoveInput(i)}
                                                onChange={(e) => handleInputChange(e)}
                                                defaultValue={formSavingData[input.inputOptions.toLowerCase()] || ''} type="number" step="1" min="1"
                                                className='custom-input-style'
                                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                required={inputObj.required ? true : false}
                                            />
                                        }

                                        {input.inputType === 'DOUBLE' && input.inputOptions === "QUANTITY0" &&
                                            <input
                                                id="input-quantity0"
                                                key={`input_${input.inputOptions}_${input.id}`}
                                                onClick={() => handleMoveInput(i)}
                                                name={`${input.inputOptions}`}
                                                onChange={(e) => handleInputChange(e)}
                                                defaultValue={formSavingData[input.inputOptions.toLowerCase()] || ''}
                                                className={`custom-input-style`}
                                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                type="number" step="0.01" min="0.01"
                                                required={inputObj.required ? true : false}
                                            />
                                        }

                                        {input.inputType === 'DOUBLE' && input.inputOptions !== "QUANTITY0" &&
                                            <> <input key={`input_${input.inputOptions}_${input.id}`}
                                                id={`${input.id == 4 ? 'quantity-distance-input' : ''}`}
                                                name={`${input.inputOptions}`}
                                                onClick={() => handleMoveInput(i)}
                                                onChange={(e) => handleInputChange(e)}
                                                defaultValue={formSavingData[input.inputOptions.toLowerCase()] || ''}
                                                className='custom-input-style'
                                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                required={inputObj.required ? true : false}
                                                type="number" step="0.01" min="0.01" />
                                                {input.inputOptions.toLowerCase() === "quantity2" && !inputObj.required && mobileView &&

                                                    <span className='text-xs'>{t("fpThird.noNecesaryBut")}</span>
                                                }
                                            </>
                                        }

                                        {input.inputType === 'STRING' && <input
                                            key={`input_${input.inputOptions}_${input.id}`}
                                            name={`${input.inputOptions}`}
                                            onClick={() => handleMoveInput(i)}
                                            onChange={(e) => handleInputChange(e)}
                                            defaultValue={formSavingData[input.inputOptions.toLowerCase()] || ''}
                                            className='custom-input-style'
                                            style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                            required={inputObj.required ? true : false}
                                            type="text" />
                                        }


                                        {input.inputType === 'DATE' && <input
                                            key={`input_${input.inputOptions}_${input.id}`}
                                            name={`${input.inputOptions}`}
                                            onClick={() => handleMoveInput(i)}
                                            onChange={(e) => handleInputChange(e)}
                                            defaultValue={''}
                                            className='custom-input-style'
                                            style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                            type="date"
                                            required={inputObj.required ? true : false}
                                        />}

                                        {input.inputType === 'BOOL' &&
                                            <div className='flex flex-inline'>
                                                <input
                                                    key={`input_${input.inputOptions}_${input.id}`}
                                                    name={`${input.inputOptions}`}
                                                    onClick={() => handleMoveInput(i)}
                                                    onChange={(e) => handleInputChange(e)}
                                                    defaultValue={''}
                                                    className='custom-width-px'
                                                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                    type="checkbox" />
                                                <label className='ml-2 text-sm'>{inputObj.description}</label>
                                            </div>
                                        }
                                        {!mobileView && <p className='text-xs italic'>{inputObj.subtitle} {input.inputOptions.toLowerCase() === "quantity2" && !inputObj.required && idQuestion != 6 && t("fpThird.noNecesaryBut")}</p>}

                                    </div>

                                    {(i === 0 && !mobileView && selectedConceptLevel1 && selectOptionsConceptLevel2?.length > 1) &&
                                        <div id="SEGUNDO DESPLGEABLE" className={`col-12 col-md-${colLength + 2} col-xxl-${colLength} mb-4`}>
                                            <div className={`mb-4 custom-width-div`} key="select_nivel2">
                                                <label className={`custom-width-px flex items-center justify-between`} >
                                                    <div className='flex flex-col justify-between'>
                                                        <span className='font-bold'>
                                                            {selectOptionsConceptLevel2 && selectOptionsConceptLevel2[0]?.title ? selectOptionsConceptLevel2[0]?.title : title}
                                                            {inputObj.required &&
                                                                <span className=" pl-1 text-red-500">*</span>
                                                            }
                                                        </span>
                                                    </div>
                                                </label>
                                                <Select
                                                    key={`input_select_nivel2_${Date.now()}`}
                                                    name={`input_select_nivel2`}
                                                    className='custom-width-px'
                                                    options={selectOptionsConceptLevel2.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({ value: item.mainQuestion, label: item.name }))}
                                                    styles={customStyles}
                                                    placeholder={`${t('footPrint.selecciona')}...`}
                                                    defaultValue={selectedConceptLevel2 === null ?
                                                        (selectOptionsConceptLevel2.length === 1 ?
                                                            {
                                                                value: selectOptionsConceptLevel2[0].mainQuestion,
                                                                label: selectOptionsConceptLevel2[0].name
                                                            }
                                                            : "") : selectedConceptLevel2}
                                                    onChange={(e) => {
                                                        setSelectedConceptLevel2(e);
                                                        let optionsLevel3 = selectOptionsConceptLevel2.filter(x => x.name === e.label)[0].combustible;
                                                        setSelectOptionsConceptLevel3(optionsLevel3);
                                                        if (optionsLevel3.length == 1) {
                                                            setSelectedConceptLevel3(optionsLevel3)
                                                            setSelectedConceptLevel3({ label: optionsLevel3[0].name, value: optionsLevel3[0].id });
                                                            handleSelectChangeSecondSelect({ label: optionsLevel3[0].name, value: optionsLevel3[0].id }, "concept", e.value);
                                                        } else {
                                                            setSelectedConceptLevel3(null)
                                                        }

                                                    }}
                                                    required>
                                                </Select>
                                                <p className='text-xs italic'>Indica el {selectOptionsConceptLevel2[0]?.title.toLowerCase()} utilizado en tus viajes</p>
                                            </div>
                                        </div>
                                    }


                                    {(i === 0 && !mobileView && selectedConceptLevel2 !== null) &&
                                        <div id="ULTIMO DESPLGEABLE" className={`col-12 col-md-${colLength + 2} col-xxl-${colLength} mb-4`}>
                                            {/* <div className={`mb-4 custom-width-div `} key="select_nivel3"> */}
                                                <label className={`custom-width-px flex items-center justify-between`} >
                                                    <div className='flex flex-col justify-between'>
                                                        <span className='font-bold'>
                                                            {selectOptionsConceptLevel2 && selectOptionsConceptLevel2[0]?.subtitle ? selectOptionsConceptLevel2[0]?.subtitle : subtitle}
                                                            {inputObj.required &&
                                                                <span className=" pl-1 text-red-500">*</span>
                                                            }
                                                        </span>
                                                    </div>
                                                </label>
                                                <Select
                                                    key={`input_select_nivel3_${Date.now()}`}
                                                    name={`input_select_nivel3`}
                                                    className='custom-width-px'
                                                    options={selectOptionsConceptLevel3.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({ value: item.id, label: item.name }))}
                                                    styles={customStyles}
                                                    placeholder={`${t('footPrint.selecciona')}...`}
                                                    defaultValue={selectedConceptLevel3 === null ?
                                                        (selectOptionsConceptLevel3.length === 1 ?
                                                            {
                                                                value: selectOptionsConceptLevel3[0].id,
                                                                label: selectOptionsConceptLevel3[0].name
                                                            }
                                                            : "") : selectedConceptLevel3}
                                                    onChange={(e) => {
                                                        setSelectedConceptLevel3(e);
                                                        handleSelectChangeSecondSelect(e, "concept", selectedConceptLevel2.value);
                                                    }}
                                                    required
                                                />
                                                {(idQuestion != 7 && idQuestion != 8) &&
                                                    <p className='text-xs italic'>Indica el {selectOptionsConceptLevel2[0]?.subtitle.toLowerCase()} utilizado en tus viajes</p>}
                                            </div>
                                        // </div>
                                    }
                                </>
                            );
                        })}

                        {/* DESPLEGABLES SECUNDARIOS TIPO DE TRANSPORTE PARA MOVIL */}
                        {(currentInput?.inputOptions === 'CONCEPT' && mobileView && selectedConceptLevel1 && selectOptionsConceptLevel2?.length > 1) &&
                            <div className={`mb-4 custom-width-div`} key="select_nivel2">

                                <label className='custom-width-px'>
                                    {selectOptionsConceptLevel2 && selectOptionsConceptLevel2[0]?.title ? selectOptionsConceptLevel2[0]?.title : title}
                                    <span className=" pl-1 text-red-500">*</span>
                                </label>

                                <Select
                                    key={`input_select_nivel2_${Date.now()}`}
                                    name={`input_select_nivel2`}
                                    onClick={() => handleMoveInput(i)}
                                    className='custom-width-px'
                                    options={selectOptionsConceptLevel2.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({ value: item.mainQuestion, label: item.name }))}
                                    styles={customStyles}
                                    placeholder={`${t('footPrint.selecciona')}...`}
                                    defaultValue={selectedConceptLevel2 === null ?
                                        (selectOptionsConceptLevel2.length === 1 ?
                                            {
                                                value: selectOptionsConceptLevel2[0].mainQuestion,
                                                label: selectOptionsConceptLevel2[0].name
                                            }
                                            : "") : selectedConceptLevel2}
                                    onChange={(e) => {
                                        setSelectedConceptLevel2(e);
                                        let optionsLevel3 = selectOptionsConceptLevel2.filter(x => x.name === e.label)[0].combustible;
                                        setSelectOptionsConceptLevel3(optionsLevel3);
                                        if(optionsLevel3.length == 1){
                                            setSelectedConceptLevel3(optionsLevel3)
                                            setSelectedConceptLevel3({ label: optionsLevel3[0].name, value: optionsLevel3[0].id });
                                            handleSelectChangeSecondSelect({ label: optionsLevel3[0].name, value: optionsLevel3[0].id }, "concept", e.value);
                                        } else {
                                            setSelectedConceptLevel3(null)
                                        }
                                    }}
                                    required>
                                </Select>
                            </div>
                        }

                        {(currentInput?.inputOptions === 'CONCEPT' && mobileView && selectedConceptLevel2 !== null) &&

                            <div className={`mb-4 custom-width-div `}
                                // ${selectOptionsConceptLevel3?.some(elemento => elemento.combustible.length > 1) ? '' : ''}
                                key="select_nivel3">
                                <label className='custom-width-px'>
                                    {selectOptionsConceptLevel2 && selectOptionsConceptLevel2[0]?.subtitle ? selectOptionsConceptLevel2[0]?.subtitle : subtitle}
                                    <span className=" pl-1 text-red-500">*</span>
                                </label>
                                <Select
                                    key={`input_select_nivel3_${Date.now()}`}
                                    name={`input_select_nivel3`}
                                    onClick={() => handleMoveInput(i)}
                                    className='custom-width-px'
                                    options={selectOptionsConceptLevel3.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({ value: item.id, label: item.name }))}
                                    styles={customStyles}
                                    placeholder={`${t('footPrint.selecciona')}...`}
                                    defaultValue={selectedConceptLevel3 === null ?
                                        (selectOptionsConceptLevel3.length === 1 ?
                                            {
                                                value: selectOptionsConceptLevel3[0].id,
                                                label: selectOptionsConceptLevel3[0].name
                                            }
                                            : "") : selectedConceptLevel3}
                                    onChange={(e) => {
                                        setSelectedConceptLevel3(e);
                                        handleSelectChangeSecondSelect(e, "concept", selectedConceptLevel2.value);
                                    }}
                                    required
                                />
                            </div>
                        }

                        <div className='flex flex-wrap justify-between gap-2'>
                            <div>  {(currentInputIndex > 0 && mobileView) &&
                                <div className='btn-banner-o  rounded-lg px-2 py-2' onClick={() => handleMoveInput('previous')}
                                    disabled={currentInputIndex <= 0}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M15 6l-6 6l6 6" />
                                    </svg>
                                </div>
                            }
                            </div>

                            {mobileView && <div className='text-lg text-center flex items-center'>{currentInputIndex + 1}/{sortedInputData?.length}</div>}

                            {(sortedInputData?.length && currentInputIndex + 1 !== sortedInputData?.length && mobileView) ?
                                <div className={`rounded-lg px-2 py-2`}
                                    style={{
                                        backgroundColor:
                                            !sortedInputData[currentInputIndex]?.required ||
                                                (sortedInputData[currentInputIndex]?.required &&
                                                    isInputAnswered(sortedInputData[currentInputIndex]) &&
                                                    (
                                                        currentInput?.inputOptions !== 'CONCEPT' ||
                                                        (currentInput?.inputOptions === 'CONCEPT' &&
                                                            selectedConceptLevel1 && selectOptionsConceptLevel2.length > 0 && selectedConceptLevel3)
                                                    )) ?
                                                'var(--airco2-green-color)' :
                                                'var(--airco2-grey-color)'
                                    }}


                                    onClick={() => {

                                        if (!sortedInputData[currentInputIndex]?.required ||
                                            (sortedInputData[currentInputIndex]?.required &&
                                                isInputAnswered(sortedInputData[currentInputIndex]) &&
                                                (
                                                    currentInput?.inputOptions !== 'CONCEPT' ||
                                                    (currentInput?.inputOptions === 'CONCEPT' &&
                                                        selectedConceptLevel1 && selectOptionsConceptLevel2.length > 0 && selectedConceptLevel3)
                                                )
                                            )) {
                                            handleMoveInput('next');
                                        } else {
                                            ""
                                        }
                                    }}>

                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </div>
                                :
                                mobileView ?

                                    <button className='btn-green rounded-lg px-1 py-1 flex justify-center items-center' type='submit'
                                        disabled={
                                            sortedInputData === undefined || sortedInputData[currentInputIndex] === undefined ||
                                            ((isInputAnswered(sortedInputData[currentInputIndex])) ? false : true)
                                        }>
                                        {savingSpinner ? <Spinner animation="border" variant="light" /> :
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy" width="34" height="34" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                                                <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                                <path d="M14 4l0 4l-6 0l0 -4" />
                                            </svg>
                                        }
                                    </button>

                                    :

                                    <button className='btn-green rounded-lg p-2 text-white ' type='submit' disabled={formSavingData.sedes == undefined && formSavingData.organizationBuildingId == undefined}>
                                        {savingSpinner ? <Spinner animation="border" variant="light" /> : t("footPrint.Guardar")}
                                    </button>
                            }
                        </div>
                    </div>
                </form>
                }
            </div >
        </div >
    )
}
