import React from 'react'
import Header from '../../../partials/Header'
import Footer from '../../../partials/Footer'


import BloqueForm from './bloqueForm';
import BloqueCasos from './bloqueCasos';
import BloqueDocCalculo from './bloqueDocCalculo';
import BloqueDocServicios from './bloqueDocServicios';
import BloqueDocNeutraliza from './BloqueDocNeutraliza';
import BloqueDocOtros from './BloqueDocOtros';

export default function landingEventoNetZero() {

    return (
        <main>
            <Header></Header>
            <div className='' style={{ minHeight: 'calc(100vh - 94px)', paddingTop: '88px' }} >


                <BloqueForm></BloqueForm>
                <BloqueCasos></BloqueCasos>
                <h2 className=" h1 text-airco2-principal font-bold text-airco2-principal  text-center mt-2 " data-aos="fade-up" data-aos-delay="100" >
                    ¿Cómo conseguirlo?
                </h2>
                <BloqueDocCalculo></BloqueDocCalculo>
                <BloqueDocServicios></BloqueDocServicios>
                <BloqueDocOtros></BloqueDocOtros>
                <BloqueDocNeutraliza></BloqueDocNeutraliza>









            </div>

            <Footer></Footer>


        </main>
    )
}
