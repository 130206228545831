import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserService from "../../../../services/UserService";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import EvolutionChart from "./DecarbonizationChart";
import EvolutionTable from "./DecarbonizationTable";
import DecarbonizationChart from "./DecarbonizationChart";
import DecarbonizationTable from "./DecarbonizationTable";
import ScenarioCreateModal from "./ScenarioCreateModal";
import DecarbonizationScenario from "./DecarbonizationScenario";


export default function DecarbonizationMainPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);


    const navigate = useNavigate();
    const [t, i18n] = useTranslation("global");

    const [showModalCreate, setShowModalCreate] = useState(false);
    const [createScenario, setCreateScenario] = useState(false);

    const handleSetShowModalCreate = (show, createScenario) => {
        setShowModalCreate(show)

        setCreateScenario(createScenario)

    }




    return (

        <div className=" sec-neutro-background flex h-screen overflow-hidden">
            <ScenarioCreateModal showModal={showModalCreate} hanldeShow={handleSetShowModalCreate}></ScenarioCreateModal>

            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">
                        <div className="mb-8 flex justify-between">
                            <div>
                                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("decarbonization.title")}</h1>
                            </div>
                            <div>
                                <button className='btn-banner-o rounded-lg px-4 py-2' onClick={() => setShowModalCreate(true)}>
                                    {t("decarbonization.create")}
                                </button>
                            </div>
                        </div>
                        <DecarbonizationScenario
                            createScenario={createScenario} />
                    </div>


                    {/* <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                         <div className="max-w-2xl m-auto mt-16">
                             <div className="text-center px-4">
                                 <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 dark:from-gray-700 dark:to-gray-800 mb-4">

                                     <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-seeding" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                         <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" stroke-dasharray="40" stroke-dashoffset="40">
                                             <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" />
                                         </path>
                                         <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" stroke-dasharray="40" stroke-dashoffset="40">
                                             <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" begin="3s" />
                                         </path>
                                         <path d="M12 20l0 -10" stroke-dasharray="10" stroke-dashoffset="10">
                                             <animate attributeName="stroke-dashoffset" from="10" to="0" dur="3s" repeatCount="indefinite" begin="5s" />
                                         </path>
                                     </svg>
                                 </div>
                                 <h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-2">¡Reducir tu Huella de Carbono será más fácil!</h2>
                                 <div className="mb-6">
                                     <p>Estamos creando una herramienta para ayudarte a calcular y reducir tu impacto ambiental. </p>
                                     <p>¡Vuelve pronto para descubrir cómo puedes marcar la diferencia!</p>                                    </div>
                             </div>
                         </div>
                     </div> */}
                </main>
            </div>
        </div>
    )

}