import { useEffect,useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import UserService from './../../services/UserService';
import PropTypes from 'prop-types';



const RenderOnRole = ({ roles, children }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const handleLoginRedirect = () => {
      if (!UserService.isLoggedIn()) {
        const query = "?originUrl=" + window.location.pathname;
        UserService.doLogin(query);
      } else {
        setIsAuthenticated(true);
      }
    };

    handleLoginRedirect();
  }, []);

  const handleNavigation = () => {
    const previousPage = location.state?.from;

    if (previousPage) {
      return <Navigate to={previousPage} replace={true} />;
    }

    return <Navigate to="/404" replace={true} />;
  };
  if (!isAuthenticated) {
    return null; 
  }

  return (
    <>
      {isAuthenticated && (UserService.isLoggedIn() && UserService.hasRole(roles)) ? (
        children
      ) : (
        handleNavigation()
      )}
    </>
  );
};

RenderOnRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RenderOnRole;

