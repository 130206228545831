import axios from "axios";
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserService from "../../../services/UserService";
import { formatThousands } from '../utils/Utils';
import { IdiomContext } from "./../../../context/createcontext";
import Modal from 'react-bootstrap/Modal';

export default function SubscriptionPlans() {
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const [plans, setPlans] = useState({
    data: [],
    isFetching: true,
  });

  const [spinner, setSpinner] = useState({ id: 0, state: false, });
  const [currentPlan, setCurrentPlan] = useState(undefined);

  useEffect(() => {
    const fetchPlans = async () => {
      const url = `${process.env.REACT_APP_CH_PLANS}`;

      try {
        const response = await axios.get(`${url}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // 'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${UserService.getToken()}`,
          },
        });
        setPlans({ data: response.data.data, isFetching: false });
      } catch (exception) {
        console.error(exception);
        setPlans({ data: [], isFetching: false });
      }
    };
    fetchPlans();
  }, []);


  useEffect(() => {
    const fetchUserPlan = async () => {
      const url = `${process.env.REACT_APP_CH_PLAN}`;

      try {
        const response = await axios.get(`${url}`, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${UserService.getToken()}`,
          },
        });
        
        setCurrentPlan(response?.data?.data?.planId)
    
      } catch (exception) {
        console.error(exception);
      }
    };
    fetchUserPlan();
  }, []);


  const paySubscription = async (idPlan) => {
    setSpinner({ id: idPlan, state: true });
    const url = `${process.env.REACT_APP_CH_SUBSCRIPTION}`;

    try {
      const response = await axios.post(`${url}?plan=${idPlan}`, {}, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${UserService.getToken()}`,
        },
      });
      window.location.href = response.data.data;
    } catch (exception) {
      console.error(exception);
    }
    setSpinner({ id: 0, state: false });

  };
  const [sendAcess, setSendAcess] = useState(false)
  const [spinnerModal, setSpinnerModal] = useState(false);
  const handleEmailAccess = async (idPlan) => {
    setSpinner({ id: idPlan, state: true });
    setSpinnerModal(true)
    try {
      const response = await fetch(` ${process.env.REACT_APP_CH_ACCESS}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UserService.getToken()}`
        },
      });

      if (response.status === 200) {
        const access = await response.json();
        setSendAcess(true)
      }
    } catch (error) {
      console.error('Error al enviar el email:', error);
    } finally {
      setSpinner({ id: 0, state: false });
      setSpinnerModal(false)
    }

  };

  const [hover, setHover] = useState({ id: undefined });

  const handleMouseEnter = (idPlan) => {
    setHover({ id: idPlan });
  };

  const handleMouseLeave = () => {
    setHover({ id: undefined });
  };



  const [showReport, setShowReport] = useState(false);
  const [planId, setPlanId] = useState();
  const handleCloseReport = () => setShowReport(false);
  const handleShowReport = () => setShowReport(true);




  return (
    <>
      <Modal show={showReport} animation={false} data-aos="fade-up" >
        <Modal.Header className='sec-green-background'>
          <Modal.Title>{t("footPrint.planes.personalizado.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!sendAcess && <div className='p-4'>
            {t("footPrint.planes.personalizado.text")}          </div>}

          {sendAcess &&
            <>{t("footPrint.planes.encontacto")}</>
          }

        </Modal.Body>
        <Modal.Footer>
          <div className='flex space-x-4 justify-center mt-4'>
            {!sendAcess && <button onClick={() => handleEmailAccess(planId)} className={`sec-green-background rounded-lg p-2 ${spinnerModal && "disabled"}`}>
              {spinnerModal ? <Spinner variant="light" animation="border" /> : t("footPrint.planes.consultar")}
            </button>}
            <button onClick={() => handleCloseReport()} className='btn-cancel rounded-lg p-2'>{t("footPrint.cerrarCerrar")}</button>
          </div>
        </Modal.Footer>

      </Modal>
      <div className="flex justify-center flex-wrap gap-6">

        {plans?.data?.length > 0 ? (
          plans.data.map((plan, index) => {
            return (
              <div key={"plan" + plan.id} className="relative max-w-sm bg-white shadow-md rounded-sm border border-gray-200">
                <div className="absolute top-0 left-0 right-0 h-0.5" aria-hidden="true" style={{ backgroundColor: plan.color }}></div>
                <div className="px-3 pt-5 pb-6 border-b border-gray-200">
                  <header className="flex justify-center mb-2">
                    <div className="w-6 h-6 rounded-full shrink-0 bg-gradient-to-tr mr-3" style={{ backgroundColor: plan.color }}>
                      <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24" >
                        <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                      </svg>
                    </div>
                    <h3 className="text-lg text-gray-800 font-semibold">{plan.name}</h3>
                    
                  </header>

                  <div className="flex justify-center items-center mb-2"><img style={{ minHeight: "150px", maxHeight: "150px" }} src={plan.image} /></div>
                  <div className="text-sm mb-2 text-center">{plan.description}</div>
                  {/* Price */}

                  <div className="flex justify-center text-gray-800 font-bold mb-4">
                    {!plan.purchasable && <><span className="text-2xl pt-1">{t("footPrint.planes.atumedida")}</span></>}
                    {plan.purchasable && <><span className="text-2xl pt-1">{formatThousands(plan.price)}</span><span className="text-gray-500 font-medium text-sm pt-2">€/{t("planes.año")}</span></>}

                  </div>
                  {/* CTA */}

                  {plan.purchasable &&
                    <button
                      arial-label="btn-subscription"
                      className="btn hover:bg-indigo-600 text-white w-full"
                      style={{
                        backgroundColor: hover.id === plan.id ? 'var(--airco2-green-color)' : plan.color,
                      }}
                      onClick={() => { paySubscription(plan.id); setPlanId(plan.id) }}
                      onMouseEnter={() => handleMouseEnter(plan.id)}
                      onMouseLeave={() => handleMouseLeave()}
                      disabled={currentPlan === plan.id || !plan.active || (spinner.id === plan.id && spinner.state)}
                    >
                      {spinner.id === plan.id && spinner.state ?
                        <Spinner variant="light" animation="border" />
                        :
                        (currentPlan !== plan.id ?
                          (!plan.active && index === 0 && currentPlan === undefined ?
                            t("footPrint.planes.actual")
                            :
                            t("planes.contratar") + " " + t("planes.ahora"))
                          :
                          t("footPrint.planes.actual"))
                      }
                    </button>
                  }

                  {!plan.purchasable &&

                    <button
                      arial-label="btn-subscription"
                      className="btn hover:bg-indigo-600 text-white w-full"
                      style={{
                        backgroundColor: hover.id === plan.id ? 'var(--airco2-green-color)' : plan.color,
                      }}
                      onClick={() => { handleShowReport() }}
                      onMouseEnter={() => handleMouseEnter(plan.id)}
                      onMouseLeave={() => handleMouseLeave()}
                      disabled={currentPlan === plan.id || !plan.active || (spinner.id === plan.id && spinner.state)}
                    >
                      {spinner.id === plan.id && spinner.state ?
                        <Spinner variant="light" animation="border" />
                        :
                        (currentPlan !== plan.id ?
                          t("footPrint.planes.consultar")
                          :
                          t("footPrint.planes.actual")
                        )
                      }
                    </button>
                  }



                </div>
                <div className="px-5 pt-4 pb-5">
                  {/* List */}
                  <ul>
                    {plan.list.map((listPlan, index) => {
                      return (<>
                        <li key={"item-" + index} className="flex items-center py-1">
                          <svg className="w-3 h-3 mr-2" viewBox="0 0 24 24" stroke={plan.color} strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 10l-3.397 -3.44a2.104 2.104 0 0 1 0 -2.95a2.04 2.04 0 0 1 2.912 0l.485 .39l.485 -.39a2.04 2.04 0 0 1 2.912 0a2.104 2.104 0 0 1 0 2.95l-3.397 3.44z" />
                            <path d="M12 14l-3.397 3.44a2.104 2.104 0 0 0 0 2.95a2.04 2.04 0 0 0 2.912 0l.485 -.39l.485 .39a2.04 2.04 0 0 0 2.912 0a2.104 2.104 0 0 0 0 -2.95l-3.397 -3.44z" />
                            <path d="M14 12l3.44 -3.397a2.104 2.104 0 0 1 2.95 0a2.04 2.04 0 0 1 0 2.912l-.39 .485l.39 .485a2.04 2.04 0 0 1 0 2.912a2.104 2.104 0 0 1 -2.95 0l-3.44 -3.397z" />
                            <path d="M10 12l-3.44 -3.397a2.104 2.104 0 0 0 -2.95 0a2.04 2.04 0 0 0 0 2.912l.39 .485l-.39 .485a2.04 2.04 0 0 0 0 2.912a2.104 2.104 0 0 0 2.95 0l3.44 -3.397z" />
                          </svg>

                          <div className="text-sm">{listPlan}</div>
                        </li>
                      </>)
                    })}

                  </ul>
                </div>
              </div>
            )
          })
        ) : (
          <>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          </>
        )}
      </div >
    </>
  );
}