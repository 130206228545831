import React, { useState, useEffect, useContext } from "react";
import ReactExport from "react-export-excel";
import format from "date-fns/format";
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from "react-i18next";
import Translations from "../../../services/Translations";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function DownExcel({ transactions, filtroCategorias }) {
  let now = new Date();
  now = format(new Date(now), "dd/MM/yyyy");
  const emisions = transactions;

  //ARRAY CON LAS CATEGORIAS SELECCIONADAS

  const categoria = filtroCategorias.map((v) => {
    return v.category.name;
  });

  //ARRAY CON LAS CATEGORIAS UNICAS, SIN REPETIR VALORES
  const categoriaUnica = categoria.filter((item, index) => {
    return categoria.indexOf(item) === index;
  });

  //ARRAY VACIO PARA CREAR UN NUEVO ARRAY CON LOS CAMPOS QUE NECESITO DE EMISIONES
  const listTotal = [];
  emisions.map((v) => {
    listTotal.push({
      category: v.category.name,
      subcategory: v.subCategory.name,
      date: format(new Date(v.accountingDate), "dd/MM/yyyy"),
      h2o: v.h2o,
      co2: v.co2,
      ch4: v.ch4,
      n2o: v.n2o,
    });
  });

  // ARRAY CON LA LISTA FILTRADA POR LA CATEGORIA/CATEGORIAS O SI NO ESTA FILTRADO POR NADA TODOS LOS VALORES
  const listCategory =
    categoriaUnica.length === 0
      ? [...listTotal]
      : listTotal.filter((c) => categoriaUnica.includes(c.category));

  // cambiar el idioma
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  // formatear nombre del archivo con filtro
  const setFilterName = () => {
    if (categoriaUnica.length > 0) {
      var stringCategories = "";
      categoriaUnica.forEach((cat) => {
        stringCategories += "_" + t(Translations.translateCategories(cat));
      });

      return stringCategories.replaceAll(" ", "");
    }
    return "";
  };

  return (
    <ExcelFile
      element={
        <button
          arial-label="btn-excellEmissions"
          className=" btn justify-between  bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-file-download"
            width="33"
            height="33"
            viewBox="0 0 24 24"
            stroke="#64748B "
            fill="none"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
            <line x1="12" y1="11" x2="12" y2="17" />
            <polyline points="9 14 12 17 15 14" />
          </svg>{" "}
          <span className="pl-2">{t("excel.descargar")}</span>
        </button>
      }
      filename={`${t("sidebar.emisiones")}${setFilterName()}__${now}`}
    >
      <ExcelSheet data={listCategory} name={`Emisiones_${now}`}>
        <ExcelColumn
          label={t("excel.categoria")}
          value={(col) => t(Translations.translateCategories(col.category))}
        />
        <ExcelColumn
          label={t("excel.subcategoria")}
          value={(col) => t(Translations.translateCategories(col.subcategory))}
        />
        <ExcelColumn label={t("excel.fecha")} value="date" />
        <ExcelColumn label={t("excel.co2")} value="co2" />
        <ExcelColumn label={t("excel.ch4")} value="ch4" />
        <ExcelColumn label={t("excel.n2o")} value="n2o" />
        <ExcelColumn label={t("excel.h2o")} value="h2o" />
      </ExcelSheet>
    </ExcelFile>
  );
}
