import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Transition from './Transition';
import { IdiomContext } from '../../../context/createcontext';
import { useTranslation } from 'next-i18next';
import UserAvatar from '../../../images/user_avatar.png';
import UserService from '../../../services/UserService';
import { getLogout } from '../../../services/FunctionsDataWhiteLabel';

function DropdownProfile({
  align
}) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const userTypeTransalate = (props) => {
    switch (props) {
      case "Representante empresarial":
        return t("dash.corporate");
      case "Propietario forestal":
        return t("dash.forest");
    }
  }

  return (
    <div className="relative inline-flex">
      <button arial-label="btn-openFilter"
        ref={trigger}
        className="inline-flex justify-center items-center group  "
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        
        {UserService.getAvatar() ? 
       <div
       className="w-8 h-8 rounded-full flex-shrink-0 hover:bg-gray-200"
       style={{
         border:"1px solid var(--airco2-green-color)",
         backgroundImage: `url(${UserService.getAvatar()})`,
         backgroundSize: "contain",
         backgroundPosition: "center",
       }}
     ></div>
        // <img src={UserService.getAvatar()} alt="Avatar usuario" width="30" height="30" className="rounded-full object-cover" style={{border:"1px solid var(--airco2-green-color)"}}></img> 
        :
        <div className='w-8 h-8 hover:bg-gray-200 transition duration-150 rounded-full'>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 icon icon-tabler icon-tabler-user-circle" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
          <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
          <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
          </svg>
        </div>
        }
        <div className="flex items-center truncate">
          <span className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">{UserService.getUsername()}</span>
          <svg className="w-3 h-3 shrink-0 ml-1 fill-current text-gray-400" viewBox="0 0 12 12">
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
            <div className="font-medium text-gray-800">{UserService.getUsername()}</div>
            <div className="text-xs text-gray-500 italic"> {userTypeTransalate(UserService.getUserType())}</div>
          </div>
          <ul>
            <li>
              <Link
                className="font-medium text-sm text-airco2-principal  flex items-center py-1 px-3"
                to="/account/account"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                Settings
              </Link>
            </li>
            <li>
              <a href={UserService.getwhiteLabel() ? getLogout(UserService.getwhiteLabel()) : "/"}
                className="font-medium text-sm text-airco2-principal  flex items-center py-1 px-3"
                to={UserService.getwhiteLabel() ? getLogout(UserService.getwhiteLabel()) : "/"}

                onClick={() => UserService.logout()}
              >
                Sign Out
              </a>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default DropdownProfile;