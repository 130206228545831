
import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import UserService from '../../../services/UserService'
import DocumentsListTable from './DocumentsListTable'
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';

export default function Documents({ envD, url, enableDelete, dataTitle }) {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [documents, setDocuments] = useState({
    documentsData: [],
    isFetching: false,
  });
  useEffect(() => {
    const fetchDocumentsData = async () => {
      try {
        setDocuments({ documentsData: documents.documentsData, isFetching: true });
        const response = await axios.get(url, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        if(url.includes("footprintcalculator")){
          setDocuments({ documentsData: response.data.data, isFetching: false });
        }else{
          setDocuments({ documentsData: response.data, isFetching: false });
        }
      } catch (exception) {
        console.error(exception);
        setDocuments({ documentsData: documents.documentsData, isFetching: false });
      }

    };
    fetchDocumentsData();
  }, [envD]);



  return (
    <>
      {dataTitle && <p className="text-lg mb-4">{t("footPrint.docParaElAño")} <strong>{dataTitle.year}</strong> {t("footPrint.delaOrg")} <strong>{dataTitle.orgName}</strong>:</p>}
      {documents.documentsData.length === 0 ?
        // && documents.isFetching === false ?
        <>
          <p className="text-sm ml-2">{t("footPrint.noDoc")}.</p>
        </>
        :
        
        <DocumentsListTable
          documents={documents.documentsData}
          enableDelete={enableDelete}
        />
      }

    </>
  )
}
