import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function BannerTutoriales() {
    const [t, i18n] = useTranslation("global");
    

    return <>

    
       
            <div className=' sec-green-background mb-4'>
                <div className=" py-4 px-3" >
                    <div className=" flex flex-col lg:flex-row justify-between items-center">
                 

                        <div className="txt " style={{ flex: "2" }}>
                            <h3 className="ml-4  text-white mb-2 " >
                                <p className='mb-2'>{t("footPrint.certificaMiteco")}</p>
                                <p className='mb-2 text-xs'>{t("footPrint.certificaMiteco2")}</p>
                            </h3>
                        </div>

                        <div className="btn-sabermas" style={{ flex: "1" }}>
                        <Link style={{ textDecoration:'none'}}
                            to="/footprint/tutorials">
                            
                  

                            <button className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none align-items-center ">
                                <span className="h-100 btn sec-blue-background text-white hover:bg-white shadow"  >{t("hometitle.forestales-btn-dos")}</span>
                            </button>
                            </Link>
                        </div>

                     
                    </div>
                </div>
            </div>

        </>
}