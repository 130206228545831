import axios from "axios";
import format from 'date-fns/format';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { IdiomContext } from "../../../context/createcontext";
import UserService from "../../../services/UserService";
import Header from './../Header';
import Sidebar from './../Sidebar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';
import emailpass from '../../../images/emailpass.svg';
import email from '../../../images/mail.svg';
import pass from '../../../images/pass (2).svg';
import { formatThousands } from "../utils/Utils";
import { useNavigate } from 'react-router-dom';


function Admin() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-3">

              {/* Left: Title */}
              <div>
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Usuarios </h1>
                {/* <h2 className='text-xl md:text-2xl text-gray-800 font-bold pl-2'>$47,347.09</h2> */}
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

              </div>

            </div>
            <div className="mt">
              <div className="panel panel-default">

                <AdminComponent />

              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}



function AdminComponent() {
  const representanteEmpresarial = "Representante empresarial";
  const propietarioForestal = "Propietario Forestal";
  const calculadora = "Usuarios calculadora";

  const [usuarios, setUsuarios] = useState([]);
  const [usuariosFilter, setUsuariosFilter] = useState([]);
  const [bankFilter, setBankFilter] = useState([])

  const [showUser, setShowUser] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showProvider, setShowProvider] = useState(false);
  const [userId, setUserId] = useState("");
  const [forestId, setForestId] = useState("");
  const [action, setAction] = useState("")
  const [userDisabled, setUserDisabled] = useState([]);
  const [userDisabledModal, setUserDisabledModal] = useState({view:false, user: ''});


  let [uploadValue, setUploadValue] = useState("")

  let urlDoc = []
  let [upload, setUpload] = useState(false)
  const [userDoc, setUserDoc] = useState([])

  const [showempresas, setShowempresas] = useState(false);
  const [filterList, setFilterList] = useState('');

  const [loading, setLoading] = useState(true);

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  const renderTooltipMp = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Añadir forestal a Marketplace
    </Tooltip>
  );

  const renderTooltipMontes = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Ver/editar montes
    </Tooltip>
  );

  const renderTooltipDoc = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Ver/subir documentos
    </Tooltip>
  );
  const renderTooltipFicha = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Ver ficha completa
    </Tooltip>
  );
  const renderTooltipEmail = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Envíar validación de email
    </Tooltip>
  );
  const renderTooltipPass = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Envíar cambio de contraseña
    </Tooltip>
  );
  const renderTooltipBoth = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Envíar cambio de contraseña y validación de email
    </Tooltip>
  );

  const renderTooltipDisableUser = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Dar de baja
    </Tooltip>
  );

  const [usuariosObj, setUsuariosObj] = useState({ forestal: [], empresa: [] });

  useEffect(() => {
    const fetchAllUserData = async () => {
      setLoading(true)
      try {
        await axios.get(`${process.env.REACT_APP_ADMIN_USERS}`, {


          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          let data = response.data.filter(x => x.userType != undefined);
          setUsuarios(data);
          setUsuariosFilter(data)
          setUsuariosObj({
            forestal: data.filter(v => v.userType == "Propietario Forestal"),
            empresa: data.filter(v => v.userType == "Representante empresarial"),
            calculadora: data.filter(v => v.originData == "Formulario")

          })
        });


      } catch (exception) {

      }
      setLoading(false);

    };
    fetchAllUserData();
  }, []);



  
  const disableUser = async (userId) => {
    setLoading(true)
    try {
      await axios.put(` ${process.env.REACT_APP_K_UPDATEPROFILE}${userId}/disable`, {}, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
      }).then(response => {
        setUserDisabled(response.data);

        let userListUpdated = usuarios.map(item => {
          if (item.id === userId) {
            return { ...item, userEnabled: false };
          }
          return item;
        });

        setUsuarios(userListUpdated);

        setUsuariosFilter(
          usuariosFilter.map(item => {
            if (item.id === userId) {
              return { ...item, userEnabled: false };
            }
            return item;
          })
        )
        setUsuariosObj({
          forestal: userListUpdated.filter(v => v.userType == "Propietario Forestal"),
          empresa: userListUpdated.filter(v => v.userType == "Representante empresarial"),
          calculadora: userListUpdated.filter(v => v.originData == "Formulario")

        })

      });
    } catch (exception) {

    }
    setLoading(false);

  };

  const Onfilter = (e) => {
    let arrayNew = null;

    if (filterList == representanteEmpresarial) {
      arrayNew = usuariosObj.empresa.filter(v => v.email.toLowerCase().includes(e.target.value.toLowerCase()) || v.name.toLowerCase().includes(e.target.value.toLowerCase()))

    } else if (filterList == propietarioForestal) {
      arrayNew = usuariosObj.forestal.filter(v => v.email.toLowerCase().includes(e.target.value.toLowerCase()) || v.name.toLowerCase().includes(e.target.value.toLowerCase()))

    } else if (filterList == calculadora) {
      arrayNew = usuariosObj.calculadora.filter(v => v.email.toLowerCase().includes(e.target.value.toLowerCase()) || v.name.toLowerCase().includes(e.target.value.toLowerCase()))

    } else {
      arrayNew = usuarios.filter(v => v.email.toLowerCase().includes(e.target.value.toLowerCase()) || v.name.toLowerCase().includes(e.target.value.toLowerCase()))

    }
    setUsuariosFilter(arrayNew)
  }


  const [bank, setBank] = useState([])

  useEffect(() => {
    const fetchBankData = async () => {
      setLoading(true);
      try {
        setBank(bank);
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_FOREST}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setBank(response.data);
        setBankFilter(response.data)

      } catch (exception) {
        setBank(bank);
      }
      setLoading(false);
    };
    fetchBankData();
  }, []);


  const OnfilterProvince = (e) => {

    let arrayProvince = bank.filter(v => v.province?.toLowerCase().includes(e.target.value.toLowerCase()))

    setBankFilter(arrayProvince)

    let userIdProvince = arrayProvince.map(v => v.userId)

    const filteredUsers = usuariosFilter.filter(user => userIdProvince.some(userId => user.id.includes(userId)));

    setUsuariosFilter(filteredUsers)
  }


  const iconStyle = {
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  }

  const renderUsers = () => {

    return items
      .map((user, i) => {
        return (
          <>

            <tr key={"user-" + i} style={{ textDecoration: !user.userEnabled ? 'line-through' : 'none' }} >
              <td className={`px-2  first:pl-5 last:pr-5 py-3 `}>
                <span>{format(new Date(user.dateCreated), 'dd/MM/yyyy')}</span>
              </td>
              <td className={`px-2  first:pl-5 last:pr-5 py-3 `}>{user.name} </td>
              <td className={`px-2 first:pl-5 last:pr-5 py-3 `}>{user.phone}</td>
              <td className={`px-2 first:pl-5 last:pr-5 py-3 `}>{user.userType}</td>
              <td className={`px-2 first:pl-5 last:pr-5 py-3 `}>{user.province}</td>
              <td className={`px-2  first:pl-5 last:pr-5 py-3 `}>
                <a className={user.validatedEmail === false && ` text-red`} href={"mailto:" + user.email}> {user.email}</a>
              </td>
              <td className={`px-2  first:pl-5 last:pr-5 py-3`}>
                {/* <div className="flex space-x-2 justify-center"> */}
                <div style={{ display: 'flex', position: 'relative', width: '166px' }}>

                  {(user.userType.toLowerCase() === propietarioForestal.toLowerCase() && bank.find((v) => v.userId === user.id)) &&
                    <div style={{ ...iconStyle, left: '0px' }}>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipMontes}
                      >
                        <a href={`/forestUser?user=${encodeURIComponent(JSON.stringify(user))}`} target="_blank">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#80817f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9"></path>
                            <path d="M15 21l0 -3"></path>
                            <path d="M8 13l-2 -2"></path>
                            <path d="M8 12l2 -2"></path>
                            <path d="M8 21v-13"></path>
                            <path d="M5.824 16a3 3 0 0 1 -2.743 -3.69a3 3 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a3 3 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z"></path>
                          </svg>
                        </a>
                      </OverlayTrigger>
                    </div>
                  }
                  {(user.userType.toLowerCase() === propietarioForestal.toLowerCase()
                    && providerMP.data && Array.isArray(providerMP.data) && !providerMP.data.includes(user.id)
                  ) &&
                    <div style={{ ...iconStyle, left: '30px' }}>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipMp}
                      >
                        <button onClick={() => createProvider(user)} className="active" aria-pressed="true">
                          {/* <img className="icon-tables" src={addmarket} ></img> */}
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-cart-up" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#80817f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 19a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                            <path d="M12.5 17h-6.5v-14h-2" />
                            <path d="M6 5l14 1l-.854 5.977m-2.646 1.023h-10.5" />
                            <path d="M19 22v-6" />
                            <path d="M22 19l-3 -3l-3 3" />
                          </svg>
                        </button>
                      </OverlayTrigger>
                    </div>
                  }

                  <div style={{ ...iconStyle, left: '60px' }}>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipFicha}
                    >
                      <button onClick={() => handleShowUser()} className="active" aria-pressed="true">
                        <svg onClick={() => setUserId(user.id)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#80817f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                          <path d="M6 21v-2a4 4 0 0 1 4 -4h1.5" />
                          <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                          <path d="M20.2 20.2l1.8 1.8" />
                        </svg>
                      </button>
                    </OverlayTrigger>
                  </div>

                  {user.userType.toLowerCase() === propietarioForestal.toLowerCase() &&
                    <div style={{ ...iconStyle, left: '90px' }}>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipDoc}
                      >

                        <button onClick={() => setUserId(user.id)} className={`active`} aria-pressed="true">
                          <svg onClick={() => handleShowDoc()} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-files" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke={`${userDoc.includes(user.id) ? "#6366f1" : "#80817f"}`} fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                            <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                          </svg>
                        </button>
                      </OverlayTrigger>
                    </div>
                  }

                  {user.validatedEmail === true ?
                    <div style={{ ...iconStyle, left: '120px' }}>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipPass}
                      >
                        <button><img onClick={() => handleshowEmail(user.id, 'password')} alt='enviar password' src={pass} width={30}></img></button>
                      </OverlayTrigger>
                    </div>
                    :
                    <div style={{ ...iconStyle, left: '120px' }}>

                      {user.dateCreated.slice(0, 10) > '2022-11-01' ?
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipEmail}
                        >
                          <button><img onClick={() => handleshowEmail(user.id, 'email')} alt='enviar email' src={email} width={20}></img></button>
                        </OverlayTrigger>
                        :
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipBoth}
                        >
                          <button><img onClick={() => handleshowEmail(user.id, 'both')} alt='enviar email&Password' src={emailpass} width={19}></img></button>
                        </OverlayTrigger>}
                    </div>
                  }


                  {(user.userType.toLowerCase() === propietarioForestal.toLowerCase() && user.userEnabled )&&
                    <div style={{ ...iconStyle, left: '150px' }}>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipDisableUser}
                      >
                        <button onClick={()=>setUserDisabledModal({view: true, user: user})}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#80817f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M8.18 8.189a4.01 4.01 0 0 0 2.616 2.627m3.507 -.545a4 4 0 1 0 -5.59 -5.552" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4c.412 0 .81 .062 1.183 .178m2.633 2.618c.12 .38 .184 .785 .184 1.204v2" />
                            <path d="M3 3l18 18" />
                          </svg>
                        </button>
                      </OverlayTrigger>
                    </div>
                  }

                </div>
              </td>
            </tr>
          </>
        );
      })

  }

  const handleClose = () => {
    setUserId('');
    setShowUser(false);
    setShowDoc(false)
    setShowEmail(false);
    setShowProvider(false);
    seRespuestaEnvio("");
    setrespuesMP('')
    setUserDisabledModal({view: false, user: ''});
    setUserDisabled([]);
  }

  const handleShowUser = () => {
    setShowUser(!showUser)
  };

  const [documents, setDocuments] = useState({
    documentsData: [],
    isFetching: false,
  });
  useEffect(() => {

    const fetchDocumentsData = async () => {
      try {
        setDocuments({ documentsData: documents.documentsData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_FOREST_DOC}/${userId}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setDocuments({ documentsData: response.data, isFetching: false });

      } catch (exception) {
        setDocuments({ documentsData: documents.documentsData, isFetching: false });
      }
    };
    fetchDocumentsData()
  }, [userId]);



  const handleDeleteDoc = (docId, docName, docUserId) => {
    const fetchDeleteDoc = async () => {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_FOREST_DOC}/${docId}`, {}, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${UserService.getToken()}`
          }

        }).then(response => {
          let borrar = documents.documentsData.filter(item => {
            if (item.id !== docId)
              return item
          })
          setDocuments({ documentsData: borrar })
        });

      } catch (error) {
        console.error(error);
      }
    }
    fetchDeleteDoc();

    const fetchDeleteDocCloud = async () => {
      const data = [{
        name: `${docUserId}/${docName}`
      }];
      try {
        const response = await axios.delete(`${process.env.REACT_APP_CLOUDSTORAGE_DOC}`, {
          data,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${UserService.getToken()}`
          }
        });

      } catch (error) {
        console.error(error);
      }
    }
    fetchDeleteDocCloud();
  }

  const handleShowDoc = () => {
    setShowDoc(!showDoc)
  };

  const handleshowEmail = (id, action) => {
    setShowEmail(!showEmail);
    setUserId(id);
    setAction(action)
  }

  const handleShowProvider = () => {
    setShowProvider(!showProvider)
  }

  const createProvider = (user) => {
    handleShowProvider();
    setUserId(user.id,);
  }

  const [respuestaMP, setrespuesMP] = useState("")

  const handlerSubmitProvider = (e) => {
    e.preventDefault()
    let send = {
      "name": e.target.nameprovider.value,
      "externalId": userId,
      "email": e.target.emailprovider.value
    }

    axios.post(`${process.env.REACT_APP_MP_PRIVATE_PROVIDER}`, send,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${UserService.getToken()}`
        }
      })
      .then(response => {
        if (response.status == 200) {
          setrespuesMP("Provider dado de alta en markertplace correctamente")
        } else {
          setrespuesMP("Error al dar de alta.")
        }

        setproviderMP(prevState => ({
          data: [...prevState.data, userId],
          isFetching: true
        }));
      })
      .catch(error => {
        console.error(error);
      });
    ;


  }


  const [respuestaEnvio, seRespuestaEnvio] = useState("");
  const handleSend = (id, action) => {
    const data = {}

    const fetchSend = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_ADMIN_SEND}/${id}/${action}`, data, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          if (response.status == 200) {
            seRespuestaEnvio("Envío realizado correctamente.")
          } else {
            seRespuestaEnvio("Error al enviar.")
          }
        });
      } catch (error) {
        console.error(error);
      }
      //setShowEmail(false)  
    }
    fetchSend();
  }



  const [providerMP, setproviderMP] = useState({
    data: [],
    isFetching: false,
  });
  useEffect(() => {
    const fetchProviderMp = async () => {
      try {

        const response = await axios.get(`${process.env.REACT_APP_MP_EXTERNALID}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setproviderMP({ data: response.data, isFetching: true });

      } catch (exception) {

        setproviderMP({ data: [], isFetching: true });
      }
    };
    fetchProviderMp();
  }, []);


  const handleUploadDoc = async (e) => {
    setUpload(true)
    const archivos = uploadValue;
    const formData = new FormData();
    formData.append('files', archivos[0]);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CLOUDSTORAGE_DOC}${userId}`,
        formData
      );
      urlDoc = response.data[0].filePublicUrl

        ;
    } catch (error) {
      console.error(error);
    }
    const data = [{

      userId: `${userId}`,
      url: urlDoc,
      creationDate: new Date()

    }]
    try {
      const response = await axios.post(`${process.env.REACT_APP_FOREST_DOC}`, data, {

        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
      });
      setDocuments({
        ...documents,
        documentsData: [...documents.documentsData, { documentsData: data, isFetching: false }]
      });
      setUpload(false)
      setUploadValue("")
      // handlerUpdate(response.data)

    } catch (error) {
      console.error(error);
    }


  };

  useEffect(() => {
    const fetchAllUserDoc = async () => {
      setLoading(true)
      try {
        await axios.get(`${process.env.REACT_APP_ADMIN_DOC}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setUserDoc(response.data);
        });
      } catch (exception) {
        console.error(exception)
      }
      setLoading(false);
    };
    fetchAllUserDoc();
  }, []);

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          const collator = new Intl.Collator(undefined, { sensitivity: 'base' });

          if (a[sortConfig.key] === undefined && b[sortConfig.key] !== undefined) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          if (a[sortConfig.key] !== undefined && b[sortConfig.key] === undefined) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (!isNaN(a[sortConfig.key]) || moment(a[sortConfig.key]).isValid()) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
          } else {
            if (collator.compare(a[sortConfig.key], b[sortConfig.key]) < 0) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (collator.compare(a[sortConfig.key], b[sortConfig.key]) > 0) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
          }

          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(usuariosFilter);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };



  return (
    <div className=" sec-beige ">

      {/* modal ficha datos */}
      <Modal show={showUser} animation={false} >
        <Modal.Header className="sec-green-background">
          <Modal.Title className="text-white">Información usuario</Modal.Title>
          <div className="flex justify-end">
            <button onClick={() => handleClose()}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {usuariosFilter === undefined ? <h1>cargando</h1> :
            usuariosFilter.filter((x) => x.id === userId).map((y, j) =>
              <FormGroup key={j}>
                <Label for="name">Nombre</Label>
                <Input disabled defaultValue={y.name}></Input>
                <Label for="validationdate">Apellido</Label>
                <Input disabled defaultValue={y.surname}></Input>
                <Label for="email">Email</Label>
                <Input disabled defaultValue={y.email}></Input>
                <Label for="usertype">Tipo de usuario</Label>
                <Input disabled defaultValue={y.userType}></Input>
                <Label for="fecha creacion">Fecha de creacion</Label>
                <Input disabled defaultValue={format(new Date(y.dateCreated), 'dd/MM/yyyy')}></Input>
                <Label for="validateemail">Email Validado</Label>
                <Input disabled defaultValue={y.validatedEmail}></Input>
                <Label for="origen">Origen de datos</Label>
                <Input disabled defaultValue={y.originData}></Input>
                <Label for="origen">Nombre de la compañía</Label>
                <Input disabled defaultValue={y.companyName}></Input>
              </FormGroup>
            )
          }

        </Modal.Body>
        <Modal.Footer>
          <Button className=" text-xs btn-sm btn-cancel text-white ml-3 whitespace-nowrap" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>

        </Modal.Footer>

      </Modal>

      {/* //modal pasar provider a marketplace */}
      <Modal show={showProvider} animation={false} >
        <Modal.Header className="sec-green-background">
          <Modal.Title className="text-white">Información Provider</Modal.Title>
          <div className="flex justify-end">
            <button onClick={() => handleClose()}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>

          {respuestaMP === "" ?
            <Form onSubmit={(e) => handlerSubmitProvider(e)}>
              {usuariosFilter === undefined ? <h1>cargando</h1> :
                usuariosFilter.filter((x) => x.id === userId).map((y, j) =>
                  <FormGroup key={j}>
                    <Label for="nameprovider">Nombre</Label>
                    {y.surname ?
                      <Input name="nameprovider" id="nameprovider" disabled defaultValue={(y.name && y.surname)}></Input>
                      :
                      <Input name="nameprovider" id="nameprovider" disabled defaultValue={(y.name)}></Input>
                    }
                    <Label for="emailprovider">Email</Label>
                    <Input name="emailprovider" id="emailprovider" disabled defaultValue={y.email}></Input>
                    {/* <Label for="id">externalid</Label>
                  <Input disabled defaultValue={y.id}></Input> */}
                  </FormGroup>
                )
              }

              <Button className="text-xs btn-sm btn-green text-white ml-3 whitespace-nowrap" type="submit">Añadir a MP</Button>
            </Form>
            :

            <p>{respuestaMP}</p>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button className=" text-xs btn-sm btn-cancel text-white ml-3 whitespace-nowrap" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>

        </Modal.Footer>

      </Modal>

      {/* modal para documentos  */}
      <Modal show={showDoc} animation={false} >
        <Modal.Header className="sec-green-background">
          <Modal.Title className="text-white">Documentacion</Modal.Title>
          <div className="flex justify-end">
            <button onClick={() => handleClose()}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>

          {documents.documentsData === undefined || documents.documentsData.length === 0 ? <h1>Este usuario no tiene documentos</h1> :


            documents.documentsData.map((x, y) =>


              <div id={`doc-${x.id}`} key={y} className="flex">

                <Input className="mx-1" disabled value={x.url && x.url.split("/")[5] ? x.url.split("/")[5] : "N/A"}></Input>

                <a aria-label='descargar document' href={x.url} target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-download" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                    <polyline points="7 11 12 16 17 11" />
                    <line x1="12" y1="4" x2="12" y2="16" />
                  </svg>
                </a>
                <button aria-label='borrar documento'>
                  <svg onClick={() => handleDeleteDoc(x.id, (x.url.split("/")[5]), x.userId)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="4" y1="7" x2="20" y2="7" />
                    <line x1="10" y1="11" x2="10" y2="17" />
                    <line x1="14" y1="11" x2="14" y2="17" />
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                  </svg>
                </button>
                <div>
                </div>
              </div>
            )}
          <div className="mt-2 flex gap-2">

            <Input className='mt-1 px-2 text-sm border' type="file" id="subir_archivo" onChange={(e) => setUploadValue(e.target.files)} ></Input>
            <button onClick={(e) => handleUploadDoc(e)}  >
              {upload === false ?
                (uploadValue == "" || uploadValue == undefined ? "" :
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-cloud-upload" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00bfd8" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                    <polyline points="9 15 12 12 15 15" />
                    <line x1="12" y1="12" x2="12" y2="21" />
                  </svg>)
                : <Spinner animation="border" variant="dark" />

              }
            </button>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className=" text-xs btn-sm btn-cancel text-white ml-3 whitespace-nowrap" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>

        </Modal.Footer>

      </Modal>

      {/* modal para confirmar mandar correo-contraseña */}
      <Modal show={showEmail} style={{ width: '50vw', top: '30vh', left: '30vw' }} className="flex text-center" animation={false} >
        <Modal.Header className="sec-green-background">
          <Modal.Title className="text-white">Enviar email</Modal.Title>
          <div className="flex justify-end">
            <button onClick={() => handleClose()}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="mt-2"  >
          {respuestaEnvio === "" && <p> ¿Estás seguro de que deseas enviar este email?</p>}

          {respuestaEnvio !== "" && <p>{respuestaEnvio}</p>}
        </Modal.Body>

        <div className="flex mb-3 justify-center">
          {respuestaEnvio === "" && <Button className=" text-xs btn-sm btn-green text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => handleSend(userId, action)}>
            Enviar
          </Button>}
          <Button className=" text-xs btn-sm btn-cancel text-white ml-3 whitespace-nowrap" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </div>

      </Modal>



      {/* Modal dar de baja usuario */}
      <Modal show={userDisabledModal.view} animation={false} >
        <Modal.Header className="sec-green-background">
          <Modal.Title className="text-white">Dar de baja usuario</Modal.Title>
          <div className="flex justify-end">
            <button onClick={() => handleClose()}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {userDisabled.length == 0 && !loading ?
          <div className="mb-4">
            <p className="font semibold text-center">Estás seguro de que quieres dar de baja al usuario <strong>{userDisabledModal.user.email}</strong>?</p>
            <br/>
            <p>Todos sus montes pasaran a estado INACTIVO y todos sus productos de marketplace dejarán de estar disponible para la compra.</p>
          </div>
          :
          (
            loading ?
            <div className="flex justify-center mb-4 ">
              <Spinner animation="border" variant="dark" />
            </div>
            :
            <div>
              <p>El usuario <strong>{userDisabledModal.user.email}</strong> se ha dado de baja correctamente.</p>
              {userDisabled.forestsDisabled.length > 0 && 
              <div className="my-2">
                Se han desactivado {userDisabled.forestsDisabled.length} montes:
                <ul style={{listStyleType: 'square'}}>
                  
                {userDisabled.forestsDisabled.map((v)=>{
                  return(
                    <li className="ml-4">{v.name}</li>
                  )
                }) }
                </ul>
              </div>}

              {userDisabled.productDisabled.length > 0 && 
              <div className="my-2">
                Se han desactivado {userDisabled.productDisabled.length} productos:
                <ul style={{listStyleType: 'square'}}>
                {userDisabled.productDisabled.map((v)=>{
                  return(
                    <li className="ml-4">{v.name}</li>
                  )
                }) }
                </ul>
              </div>
              }
            </div>
          )
          }
          <div className="flex justify-center">
            <Button className=" text-xs btn-sm btn-green text-white ml-3 whitespace-nowrap" disabled={loading == true || userDisabled.length != 0 ? true : false } onClick={()=>disableUser(userDisabledModal.user.id)}>Aceptar</Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className=" text-xs btn-sm btn-cancel text-white ml-3 whitespace-nowrap" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>

        </Modal.Footer>

      </Modal>

      {/* Filtros */}
      <div>

        <div className="flex gap-1 mb-3">
          <Input className="rounded-lg  w-1/2" onChange={Onfilter} placeholder="busca por email o nombre" type="text" />
          <Input className="rounded-lg  w-1/2" onChange={OnfilterProvince} placeholder="busca por provincia" type="text" />
        </div>

        <ul className="flex flex-wrap justify-center mb-3">
          <li className="m-1">
            <button arial-label="btn-ageClick" onClick={() => { setUsuariosFilter(usuarios); setFilterList("") }} className={`text-sm font-semibold text-white px-2 bg-gris rounded-full ${filterList == "" ? "sec-green-background" : ""}`}>
              Todos
            </button>
          </li>
          <li className="m-1" >
            <button arial-label="btn-ageClick" onClick={() => { setUsuariosFilter(usuariosObj.forestal); setFilterList(propietarioForestal) }} className={`text-sm font-semibold text-white px-2 bg-gris rounded-full ${filterList == propietarioForestal ? "sec-green-background" : ""}`}>
              Propiertarios Forestales
            </button>
          </li>

          <li className="m-1">
            <button arial-label="btn-ageClick" onClick={() => { setUsuariosFilter(usuariosObj.empresa); setFilterList(representanteEmpresarial) }} className={`text-sm font-semibold text-white px-2 bg-gris rounded-full ${filterList == representanteEmpresarial ? "sec-green-background" : ""}`}>
              Representante empresarial
            </button>
          </li>
          <li className="m-1">
            <button arial-label="btn-ageClick" onClick={() => { setUsuariosFilter(usuariosObj.calculadora); setFilterList(calculadora) }} className={`text-sm font-semibold text-white px-2 bg-gris rounded-full ${filterList == calculadora ? "sec-green-background" : ""}`}>
              Usuarios calculadora
            </button>
          </li>
        </ul>
      </div>

      {/* {loading || bankFilter.length == 0 ? <> <span className="flex gap-2">Cargando usuarios <Spinner animation="border" variant="dark" /></span> </>: */}
      {loading || usuarios.length == 0 ? <> <span className="flex gap-2">Cargando usuarios <Spinner animation="border" variant="dark" /></span> </> :

        <>
          <div className='table-responsive rounded-lg'>

            <table id="usuarios" className="table-striped text-xs bg-white table-auto w-full">
              <thead className="bg-orange text-white text-md">
                <tr>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 ">
                    <button type="button" onClick={() => requestSort('dateCreated')}
                      className={`${getClassNamesFor('dateCreated')} text-underline`}>
                      Fecha creación
                    </button>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 ">
                    <button type="button" onClick={() => requestSort('name')}
                      className={`${getClassNamesFor('name')} text-underline`}>
                      Nombre
                    </button>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 ">
                    Teléfono
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 ">
                    Tipo de usuario
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3" >
                    <button type="button" onClick={() => requestSort('province')}
                      className={`${getClassNamesFor('province')} text-underline`}>
                      Provincia
                    </button>
                  </th>

                  <th className="px-2 first:pl-5 last:pr-5 py-3" >
                    <button type="button" onClick={() => requestSort('email')}
                      className={`${getClassNamesFor('email')} text-underline`}>
                      Email
                    </button>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 text-center" >Acciones</th>
                </tr>
              </thead>
              <tbody id="usuarios">{renderUsers()}</tbody>
            </table>
          </div>
        </>
      }


    </div>
  );

}



export default Admin;
