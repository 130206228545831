import React, { useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "./../../../context/createcontext"

import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';

import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt"
import "moment/locale/gl"

// Import utilities
import { formatThousands, tailwindConfig } from '../utils/Utils';

Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);

function BarChart04({
  data,
  width,
  height,
  unit,
  info
}) {

  const canvas = useRef(null);
  const legend = useRef(null);
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    switch (lng) {
      case "en":
        moment.locale("en");
        break;
      case "pt":
        moment.locale("pt");
        break;
      case "gl":
        moment.locale("gl");
        break;
      default:
        moment.locale("es");
        break;
    }
    const chart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        indexAxis: 'y',
        layout: {
          padding: {
            top: 12,
            bottom: 16,
            left: 15,
            right: 20,
          },
        },
        scales: {
          y: {
            barPercentage: 0.75,
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false
            },
            ticks: {
              fontColor: '#555759',
              fontFamily: 'Lato',
              fontSize: 11,
              callback: function (value, index, values) {

                // return data.labels[index];
                const label = data.labels[index];
                const mobil = 600;

                
                if (window.innerWidth < mobil) {
                 
                  const charLimit = 15;
            
                  if (label.length > charLimit) {
                    const words = label.split(' ');
                    let line = '';
                    let lines = [];
            
                    words.forEach(word => {
                      if ((line + word).length <= charLimit) {
                        line += (line.length > 0 ? ' ' : '') + word;
                      } else {
                        lines.push(line);
                        line = word;
                      }
                    });
            
                    lines.push(line);
                    return lines;
                  }
                }
            
               
                return label;
              }
            },
            type: 'category',

          },
          grid: {
            display: false,
            drawBorder: false,
          },

          x: {
            grid: {
              drawBorder: false,
            },
            ticks: {
              maxTicksLimit: 10,
              align: 'end',
              callback: (value) => formatThousands(value),
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatThousands(context.parsed.x),
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [{
        id: 'htmlLegend',
        afterUpdate(c, args, options) {
          const ul = legend.current
          if (!ul) return
          // Remove old legend items
          while (ul.firstChild) {
            ul.firstChild.remove()
          }
          // Reuse the built-in legendItems generator
          const items = c.options.plugins.legend.labels.generateLabels(c)
          items.forEach((item) => {
            const li = document.createElement('li')
            li.style.marginRight = tailwindConfig().theme.margin[4]
            // Button element
            const button = document.createElement('button')
            button.style.display = 'inline-flex'
            button.style.alignItems = 'center'
            button.style.opacity = item.hidden ? '.3' : ''
            button.onclick = () => {
              c.setDatasetVisibility(item.datasetIndex, !c.isDatasetVisible(item.datasetIndex))
              c.update()
            }
            // Color box
            const box = document.createElement('span')
            box.style.display = 'block'
            box.style.width = tailwindConfig().theme.width[3]
            box.style.height = tailwindConfig().theme.height[3]
            box.style.borderRadius = tailwindConfig().theme.borderRadius.full
            box.style.marginRight = tailwindConfig().theme.margin[2]
            box.style.borderWidth = '3px'
            box.style.borderColor = item.fillStyle
            box.style.pointerEvents = 'none'
            // Label
            const label = document.createElement('span')
            label.style.color = tailwindConfig().theme.colors.gray[500]
            label.style.fontSize = tailwindConfig().theme.fontSize.sm[0]
            label.style.lineHeight = tailwindConfig().theme.fontSize.sm[1].lineHeight
            const labelText = document.createTextNode(item.text)
            label.appendChild(labelText)
            li.appendChild(button)
            button.appendChild(box)
            button.appendChild(label)
            ul.appendChild(li)
          })
        },
      }],
    });
    return () => chart.destroy();
    // eslint-disable-next-line
  }, [lng]);

  return (
    <React.Fragment>
      <div className="px-5 py-4 flex flex-wrap justify-center">

        <ul ref={legend} className="flex flex-wrap justify-center" style={{ fontWeight: 'bold' }}></ul>
        {info &&
          <>
            **
          </>}
      </div>
      <div className="grow">
        <canvas ref={canvas} width={width} height={height}></canvas>
      </div>

    </React.Fragment>
  );
}

export default BarChart04;
