import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"
import Header from '../Header'
import Sidebar from '../Sidebar';
import axios from 'axios';
import { OverlayTrigger } from 'react-bootstrap';
import UserService from '../../../services/UserService';
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Spinner } from 'react-bootstrap';
import { Modal, Button, Col } from "react-bootstrap";
import { Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Banner from '../../../components/utils/Banner';
import format from 'date-fns/format';
import QRCodeGenerator from './QRCodeGenerator';
import { handleBack } from '../../../services/Functions';

export default function FormTerceros() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const pathParams = useParams();
    const idCalculation = pathParams.id;
    const [showNewEdit, setShowNewEdit] = useState(false)
    const [showQR, setShowQR] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false)

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [name, setName] = useState('');
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [questionsIds, setQuestionsIds] = useState('');
    const [externalId, setExternalId] = useState('');

    const [msg, setMsg] = useState({
        type: '',
        message: '',
        open: false,
    });

    const [externalQuest, setExternalQuest] = useState({
        data: [],
        isFetching: false
    });

    const [questions, setQuestions] = useState({
        data: [],
        isFetching: false
    });

    const fetchQuestions = async () => {
        setQuestions({ data: questions.data, isFetching: true });
        try {
            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questions?userId=${dataCalculation.userId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setQuestions({ data: response.data, isFetching: false });
        } catch (exception) {
            setQuestions({ data: [], isFetching: false });
        }
    };

    const fetchExternalQuest = async () => {
        setExternalQuest({ data: externalQuest.data, isFetching: true });
        try {
            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/${idCalculation}/questionnaires`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setExternalQuest({ data: response.data, isFetching: false });
        } catch (exception) {
            setExternalQuest({ data: [], isFetching: false });
        }
    };

    useEffect(() => {
        fetchExternalQuest();
        fetchQuestions();
    }, [])



    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            if (!items || items.length === 0) {
                return [];
            }
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    let valueA = a[sortConfig.key];
                    let valueB = b[sortConfig.key];

                    if (sortConfig.key.includes(".")) {
                        let params = sortConfig.key.split(".");
                        valueA = a[params[0]]?.[params[1]];
                        valueB = b[params[0]]?.[params[1]];
                    }

                    if (valueA === null || valueA === undefined || valueA === "") {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }

                    if (valueB === null || valueB === undefined || valueB === "") {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }

                    if (!isNaN(valueA) || moment(valueA).isValid()) {
                        if (valueA < valueB) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (valueA > valueB) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    } else {
                        if (
                            valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (
                            valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    }
                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };
        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(externalQuest.data.data);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const renderTooltipQuestion = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.question")}
        </Tooltip>
    );

    const renderTooltipAnswer = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.answers")}
        </Tooltip>
    );

    const renderTooltipsendEmail = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.sendEmail")}
        </Tooltip>
    );

    const renderTooltipQr = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.qr")}
        </Tooltip>
    );
    const renderTooltipnoHabilitada = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("fpThird.noHabilitada")}
        </Tooltip>
    );


    const handleClose = () => {
        setShowNewEdit(false)
        setName('')
        setExternalId('')
        setQuestionsIds('')
        setIsAnonymous(false)
        setMsg({
            type: '',
            message: '',
            open: false
        })
        setButtonDisabled(false)
    }

    const handleShowQuestion = (x) => {
        setShowNewEdit(true)
        setQuestionsIds(x.externalQuestionsDto.map((x) => (x.id)))
        setName(x.name)
        setIsAnonymous(x.isAnonymous)
        setExternalId(x.id)
    }

    const handleCheckboxChange = (e, questionId) => {
        const checked = e.target.checked;
        setQuestionsIds((prevQuestionIds) => {
            if (checked) {
                return [...prevQuestionIds, questionId];
            } else {
                return prevQuestionIds.filter((id) => id !== questionId);
            }
        });
    };

    const location = useLocation();
    const dataCalculation = location.state?.data;

    if (location.state == undefined) {
        window.location.href = "/footprint/myfootprint";
    }

    const handleSubmitNew = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);

        const url = `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/${idCalculation}/questionnaire`;
        const data = {
            "name": name,
            "externalQuestionIds": questionsIds,
            "isAnonymous": isAnonymous
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            await fetchExternalQuest()
            setMsg({ type: "success", message: t("fpThird.form-ok") });
        } catch (error) {
            console.error('Error:', error.message);
            setMsg({ type: "error", message: t("fpThird.form-ko") });
        }
    }

    const handleSubmitPut = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);

        const url = `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire`;
        const data = {
            "name": name,
            "externalQuestionIds": questionsIds,
            "id": externalId,
            "isAnonymous": isAnonymous
        };

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            await fetchExternalQuest()
            setMsg({ type: "success", message: t("fpThird.update-ok") });
        } catch (error) {
            console.error('Error:', error.message);
            setMsg({ type: "error", message: t("fpThird.update-ko") });
        }
    }

    const [urlQR, setUrlQR] = useState({
        data: [],
        isFetching: false
    });

    const [selectedExternalQuestionnaire, setSelectedExternalQuestionnaire] = useState(undefined);

    const fetchUrlQr = async (id) => {
        setUrlQR({ data: urlQR.data, isFetching: true });
        try {
            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${id}/url?organizationName=${dataCalculation.orgName}&calculationYear=${dataCalculation.year}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setUrlQR({ data: response.data, isFetching: false });
        } catch (exception) {
            setUrlQR({ data: [], isFetching: false });
        }
    };

    const handleShowQR = (x) => {
        setShowQR(true)
        setExternalId(x.id)
        fetchUrlQr(x.id);
        setSelectedExternalQuestionnaire(x)
    }

    const handleCloseQR = () => {
        setShowQR(false)
        setExternalId('')
        setSelectedExternalQuestionnaire(undefined)
    }

    const InstructionsText = () => {
        return <>
            <h2 className="text-2xl text-center text-gray-800 dark:text-gray-100 font-bold mb-2">
                {t("fpThird.instructions.title")}
            </h2>
            <div className="mb-6">
                <p className="mb-2">{t("fpThird.instructions.line1")}</p>
                <ol style={{ listStyle: "decimal", paddingLeft: "2rem", lineHeight: "2" }}>
                    <li><span dangerouslySetInnerHTML={{ __html: t("fpThird.instructions.line2") }}></span>
                        <ul style={{ listStyle: "circle", paddingLeft: "2rem" }}>
                            <li><span dangerouslySetInnerHTML={{ __html: t("fpThird.instructions.line3") }}></span></li>
                            <li><span dangerouslySetInnerHTML={{ __html: t("fpThird.instructions.line4") }}></span></li>
                            <li><span dangerouslySetInnerHTML={{ __html: t("fpThird.instructions.line5") }}></span></li>
                        </ul>
                    </li>
                    <li><span dangerouslySetInnerHTML={{ __html: t("fpThird.instructions.line6") }}></span>
                        <ul style={{ listStyle: "circle", paddingLeft: "2rem" }}>
                            <li><span dangerouslySetInnerHTML={{ __html: t("fpThird.instructions.line7") }}></span></li>
                            <li><span dangerouslySetInnerHTML={{ __html: t("fpThird.instructions.line8") }}></span></li>
                            <li><span dangerouslySetInnerHTML={{ __html: t("fpThird.instructions.line9") }}></span></li>

                        </ul>
                    </li>
                    <li><span dangerouslySetInnerHTML={{ __html: t("fpThird.instructions.line10") }}></span></li>
                </ol>
                <div className="mt-3 flex justify-center items-center" >
                    <div style={{ maxWidth: "550px" }}>
                        <div style={{ position: "relative", paddingBottom: "53.25443786982249%", height: "0" }}>

                        </div>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center", // Centra horizontalmente
                    alignItems: "center",      // Centra verticalmente
                    height: "40vh",           // Ocupa toda la altura de la ventana
                    overflow: "hidden",        // Evita desbordamientos
                }}>
                    <div style={{
                        maxWidth: "100%",        // Máximo ancho
                        position: "relative",
                        paddingBottom: "53.25%", // Mantiene la relación de aspecto
                        width: "50%"           // Puedes ajustar el ancho aquí
                    }}>
                        <iframe
                            src="https://www.loom.com/embed/e9fc46916ca34fcab2af2b68dd89e786?sid=fba0a274-0cd7-4171-b7f8-89ecb4c31e03"
                            frameBorder="0"
                            webkitAllowFullScreen
                            mozAllowFullScreen
                            allowFullScreen
                            style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                border: "none", // Elimina el borde
                            }}
                        />
                    </div>
                </div>
            </div >
        </>
    }



    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <button arial-label="btn-saveFom"
                    className="absolute ml-4 mt-2 text-base font-semibold flex flex-row items-center"
                    style={{ top: "60px", left: "-20px" }} type='onclick' onClick={() => window.history.back()}  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                    </svg>
                    <span>{t("footPrint.volver")}</span>
                </button>

                <Modal show={showNewEdit}>
                    <Modal.Header className="sec-green-background">
                        <Modal.Title className="text-white">{t("fpThird.formContacts")}</Modal.Title>
                        <div className="flex justify-end">
                            <button onClick={() => handleClose()}>
                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {msg.type !== "" && <Banner type={msg.type} open setOpen={true}>{msg.message}</Banner>}
                        <Form className='text-sm' onSubmit={(e) => { externalId ? handleSubmitPut(e) : handleSubmitNew(e) }}>
                            <Label className='font-bold' for="name">{t("fpThird.expFormTerceros")}</Label>

                            <div>
                                <FormGroup>
                                    <Input className='text-xs' type="text" name="name" id="name" defaultValue={name} onChange={(e) => { setName(e.target.value) }} required>
                                    </Input>
                                </FormGroup>
                            </div>

                            <div className="flex items-center mb-4 gap-1">
                                <Label className='font-bold text-xs m-0' for="name">Anónimo</Label>
                                <div
                                    onClick={() => setIsAnonymous(!isAnonymous)}
                                    className={`relative w-10 h-6 rounded-full cursor-pointer transition-colors ${isAnonymous ? 'bg-green-500' : 'bg-red-500'
                                        }`}
                                >
                                    <div
                                        className={`absolute w-6 h-6 bg-white rounded-full transition-transform transform `}
                                        style={{ transform: isAnonymous ? 'translateX(1rem)' : 'translateX(0rem)', borderWidth: '1.5px', borderColor: isAnonymous ? 'rgb(34 197 94)' : 'rgb(244 63 94)' }}
                                    ></div>
                                </div>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label className='font-bold text-xs' for="questionsType">{t("fpThird.question")}</Label>
                                    {questions.isFetching === false && questions?.data?.data?.length > 0 &&
                                        questions.data?.data
                                            ?.map((question) => (
                                                <div key={question.id} className="form-check">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="forestType"
                                                        id={`questionCheckbox_${question.id}`}
                                                        value={question.id}
                                                        defaultChecked={questionsIds.includes(question.id)}
                                                        onChange={(e) => handleCheckboxChange(e, question.id)}
                                                    />
                                                    <Label className="form-check-label text-xs" for={`questionCheckbox_${question.id}`}>
                                                        {question.name}
                                                    </Label>
                                                </div>
                                                // )
                                            ))}
                                </FormGroup>
                            </div>
                            <div className='text-right'>
                                <button className="btn-green rounded-lg px-4 py-2 text-xs" type="submit" disabled={buttonDisabled || questionsIds.length == 0}>
                                    {externalId ?
                                        t("fpThird.actualizar")
                                        :
                                        t("fpThird.guardar")
                                    }
                                </button>
                            </div>
                        </Form>
                        <div className='mt-4 text-sm text-center'>
                            <p>  {t("fpThird.help1")}</p>
                            <p>{t("fpThird.help2")}</p>
                            <div className='flex items-center justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                                    <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                </svg> <a className='text-airco2-principal' href="mailto:tech@airco2.earth">{t("fpThird.helpmail")}</a>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showQR}>
                    <Modal.Header className="sec-green-background">
                        <Modal.Title className="text-white">
                            {dataCalculation.orgName + " " + dataCalculation.year}
                        </Modal.Title>
                        <div className="flex justify-end">
                            <button onClick={() => handleCloseQR()}>
                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-2'>
                            <span className=''>
                                <b>{t("fpThird.formContacts")}</b>: {selectedExternalQuestionnaire?.name}
                            </span>
                        </div>
                        <div className='mb-4'>
                            <span className=''>
                                <b>{t("fpThird.question")}</b>: {selectedExternalQuestionnaire?.externalQuestionsDto.map(x => x.name).join(" ,")}
                            </span>
                        </div>

                        <QRCodeGenerator
                            url={urlQR.data.data}
                        ></QRCodeGenerator>
                    </Modal.Body>
                </Modal>

                <Modal show={showInstructions} size={"lg"}>
                    <Modal.Header className="sec-green-background">
                        <Modal.Title className="text-white">
                            {t("fpThird.instructions.instruction")}
                        </Modal.Title>
                        <div className="flex justify-end">
                            <button onClick={() => setShowInstructions(false)}>
                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <InstructionsText></InstructionsText>
                    </Modal.Body>
                </Modal>

                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                    {!dataCalculation.thirdPartyForm &&
                        <div>
                            <h1 className="text-2xl md:text-3xl font-bold">
                                {t("fpThird.formContacts")}
                            </h1>

                            <div className='m-4'>
                                <div className='flex flex-row items-center justify-center '>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-barrier-block" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M4 7m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v7a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
                                        <path d="M7 16v4" />
                                        <path d="M7.5 16l9 -9" />
                                        <path d="M13.5 16l6.5 -6.5" />
                                        <path d="M4 13.5l6.5 -6.5" />
                                        <path d="M17 16v4" />
                                        <path d="M5 20h4" />
                                        <path d="M15 20h4" />
                                        <path d="M17 7v-2" />
                                        <path d="M7 7v-2" />
                                    </svg>
                                    <h1 className="text-xl md:text-2xl font-bold text-center ">{t("fpThird.proximamente")}</h1>
                                </div>
                                <p className='text-center mb-4'>{t("fpThird.descripcionTerceros")}</p>
                                <div className='flex justify-center mt-4'>
                                    <button className='btn btn-green rounded-lg text-white' onClick={() => window.history.back()}>{t("footPrint.volver")}</button>
                                </div>
                            </div>
                        </div>
                    }

                    {externalQuest.isFetching && <Spinner animation="border" variant="dark" />}
                    {dataCalculation.thirdPartyForm && !externalQuest.isFetching && externalQuest?.data?.data?.length === 0 &&
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="max-w-2xl m-auto mt-16">
                                <div className=" px-4">
                                    <div className='text-right'>
                                        <button onClick={() => setShowNewEdit(true)} className='btn-green mr-4 rounded-lg px-4 py-2' >
                                            {t("fpThird.nuevoForm")}
                                        </button>
                                    </div>
                                    <div className='text-center'>
                                        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full text-center bg-gradient-to-t from-gray-200 to-gray-100 dark:from-gray-700 dark:to-gray-800 mb-4">

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-seeding" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" stroke-dasharray="40" stroke-dashoffset="40">
                                                    <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" />
                                                </path>
                                                <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" stroke-dasharray="40" stroke-dashoffset="40">
                                                    <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" begin="3s" />
                                                </path>
                                                <path d="M12 20l0 -10" stroke-dasharray="10" stroke-dashoffset="10">
                                                    <animate attributeName="stroke-dashoffset" from="10" to="0" dur="3s" repeatCount="indefinite" begin="5s" />
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                    <InstructionsText></InstructionsText>
                                    <div className='text-center'>
                                        <button onClick={() => setShowNewEdit(true)} className='btn-green mr-4 rounded-lg px-4 py-2' >
                                            {t("fpThird.nuevoForm")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {dataCalculation.thirdPartyForm && !externalQuest.isFetching && externalQuest?.data?.data?.length > 0 &&
                        <div>
                            <div className="mb-4 text-gray-800">
                                <p>{t("fpThird.formContacts")}                                </p>
                                <div className='flex justify-between flex-wrap'>
                                    <h1 className="text-2xl md:text-3xl font-bold">
                                        <span>{dataCalculation?.orgName}</span> -
                                        <span>{dataCalculation?.year}</span>
                                    </h1>
                                    <div className='flex  gap-2'>
                                        <button onClick={() => setShowInstructions(true)} className='flex items-center btn-banner-o mr-4 rounded-lg px-4 py-2' >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-square-rounded mr-2" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 9h.01" />
                                                <path d="M11 12h1v4h1" />
                                                <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                                            </svg>
                                            {t("fpThird.instructions.instruction")}
                                        </button>
                                        <button onClick={() => setShowNewEdit(true)} className='btn-green mr-4 rounded-lg px-4 py-2' >
                                            {t("fpThird.nuevoForm")}
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div className='text-lg flex gap-2 font-bold justify-center mb-4'>

                            </div>

                            <div className="container-simple-table w-full">
                                <table className="simple-table ">
                                    <thead className='text-center  text-xl sec-green-background text-white'>
                                        <tr className='py-4'>
                                            <th>
                                                <button type="button" onClick={() => requestSort('nombre')} className={`${getClassNamesFor('nombre')} text-underline`}>
                                                    {t("fpThird.name")}
                                                </button>
                                            </th>
                                            <th>
                                                <button type="button" onClick={() => requestSort('fechaCreacion')} className={`${getClassNamesFor('fechaCreacion')} text-underline`}>
                                                    {t("fpThird.fechaCreacion")}
                                                </button>
                                            </th>
                                            <th>
                                                <button type="button" onClick={() => requestSort('isAnonymous')} className={`${getClassNamesFor('isAnonymous')} text-underline`}>
                                                    Anónimo
                                                </button>
                                            </th>
                                            <th>
                                                <button type="button" onClick={() => requestSort('question')} className={`${getClassNamesFor('question')} text-underline`}>
                                                    {t("fpThird.question")}
                                                </button>
                                            </th>
                                            <th>
                                                <button type="button" onClick={() => requestSort('answers')} className={`${getClassNamesFor('answers')} text-underline`}>
                                                    {t("fpThird.answers")}
                                                </button>
                                            </th>
                                            <th> {t("fpThird.envios")}</th>
                                            <th> {t("fpThird.editar")}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center text-md'>
                                        {externalQuest.isFetching ? (
                                            <tr className='flex justify-center'>
                                                <td colSpan="6"><Spinner animation="border" variant="dark" /></td>
                                            </tr>
                                        ) : (
                                            externalQuest.data && externalQuest.data.data ? (
                                                externalQuest.data.data.length > 0 ? (
                                                    items.map((x) => (
                                                        <tr key={x.id}>
                                                            <td>{x.name}</td>
                                                            <td>{x.creationDate ? format(new Date(x.creationDate), 'dd/MM/yyyy') : ''}</td>
                                                            <td className='flex justify-center'> {x.isAnonymous ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M5 12l5 5l10 -10" />
                                                                </svg>
                                                                : <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-x" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                                                    <path d="M10 10l4 4m0 -4l-4 4" />
                                                                </svg>}</td>
                                                            <td>
                                                                <div className='flex flex-wrap gap-1  justify-center'>
                                                                    {x.externalQuestionsDto.map(x => { return <div className='py-2 px-2 btn-green text-xs rounded-full'>{x.name}</div> })}
                                                                    <div className='py-2 px-2'>({x.externalQuestionsDto.length})</div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='flex items-center justify-center gap-2'>
                                                                    {x.validatedAnswers + "/" + x.totalAnswers}     <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderTooltipAnswer}
                                                                    >
                                                                        <button onClick={() => navigate(`/footprint/externalanswers/${x.id}`, { state: { data: dataCalculation } })}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-dots" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
                                                                                <path d="M12 11l0 .01" />
                                                                                <path d="M8 11l0 .01" />
                                                                                <path d="M16 11l0 .01" />
                                                                            </svg>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className='flex items-center justify-center gap-2'>
                                                                    {!x.isAnonymous && <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={dataCalculation.status === 'FINALIZED' ? renderTooltipnoHabilitada : renderTooltipsendEmail}
                                                                    >
                                                                        <button disabled={dataCalculation.status === 'FINALIZED' ? true : false} onClick={() => navigate(`/footprint/sendForm/${x.id}`, { state: { data: dataCalculation } })}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail-forward" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
                                                                                <path d="M3 6l9 6l9 -6" />
                                                                                <path d="M15 18h6" />
                                                                                <path d="M18 15l3 3l-3 3" />
                                                                            </svg>
                                                                        </button>
                                                                    </OverlayTrigger>}
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={dataCalculation.status === 'FINALIZED' ? renderTooltipnoHabilitada : renderTooltipQr}
                                                                    >
                                                                        <button onClick={() => handleShowQR(x)} disabled={dataCalculation.status === 'FINALIZED' ? true : false}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-qrcode" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                                                                <path d="M7 17l0 .01" />
                                                                                <path d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                                                                <path d="M7 7l0 .01" />
                                                                                <path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                                                                <path d="M17 7l0 .01" />
                                                                                <path d="M14 14l3 0" />
                                                                                <path d="M20 14l0 .01" />
                                                                                <path d="M14 14l0 3" />
                                                                                <path d="M14 20l3 0" />
                                                                                <path d="M17 17l3 0" />
                                                                                <path d="M20 17l0 3" />
                                                                            </svg>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='flex items-center justify-center gap-2'>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={renderTooltipQuestion}
                                                                    >
                                                                        <button onClick={() => handleShowQuestion(x)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-question" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                                                                                <path d="M13.5 6.5l4 4" />
                                                                                <path d="M16 19h6" />
                                                                                <path d="M19 16v6" />
                                                                            </svg>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6">{t("fpThird.noHayFormsCreados")}</td>
                                                    </tr>
                                                )
                                            ) : null
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>


        </div>
    )
}
