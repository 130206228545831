import { default as React, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../../context/createcontext";
import DoughnutChart from '../../charts/DoughnutChart';
import { formatThousands } from '../../utils/Utils';


// Import utilities
import { tailwindConfig } from '../../utils/Utils';

export default function Scope({ report }) {

    // cambiar idioma
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [chartData, setChartData] = useState();

    useEffect(() => {
        const sumsByScope = {
            1: 0,
            2: 0,
            3: 0,
        };

        report?.forEach(item => {
            sumsByScope[item.scope] += item.totalKgCO2e;
        });


        let scope1fixed = sumsByScope[1].toFixed(2)
        let scope2fixed = sumsByScope[2].toFixed(2)
        let scope3fixed = sumsByScope[3].toFixed(2)

        const arrayAlcance = [
            `${formatThousands(scope1fixed)} ${t("dash.alcance1")}`,
            `${formatThousands(scope2fixed)} ${t("dash.alcance2")}`,
            `${formatThousands(scope3fixed)} ${t("dash.alcance3")}`
        ];

        const newArrayScope = new Array(arrayAlcance.length).fill(0);
        newArrayScope[0] = sumsByScope[1];
        newArrayScope[1] = sumsByScope[2];
        newArrayScope[2] = sumsByScope[3];

        setChartData({
            labels:
                arrayAlcance,

            datasets: [
                {
                    label: 'Toneladas CO2 por actividad',
                    data: newArrayScope,
                    backgroundColor: [
                        tailwindConfig().theme.colors.yellow[400],
                        tailwindConfig().theme.colors.indigo[500],
                        tailwindConfig().theme.colors.orange[400],
                        tailwindConfig().theme.colors.gray[500],
                    ],
                    hoverBackgroundColor: [
                        tailwindConfig().theme.colors.yellow[500],
                        tailwindConfig().theme.colors.indigo[600],
                        tailwindConfig().theme.colors.orange[500],
                        tailwindConfig().theme.colors.gray[600],
                    ],
                    hoverBorderColor: tailwindConfig().theme.colors.white,
                },
            ],
        })

    }, [report])




    return (
        <div id='galcance' className="flex flex-col col-span-full sm:col-span-6 bg-white  rounded-sm border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800">{t("footPrint.totalByAlcance")}</h2>
            </header>
            {!report &&
                <div className="text-center"><Spinner className='' /></div>
            }

            {report !== undefined && report.length == 0 &&
                <div className='mt-4 mb-4'>{t("footPrint.noDataToGraphic")}</div>}

            {report !== undefined && report.length > 0 &&
                <DoughnutChart data={chartData} width={389} height={260} unit={" kg"} />
            }
        </div>
    );

}

