import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Spinner } from 'reactstrap';
import mano from "../../../images/mano.png";
import manobajo from "../../../images/manobajo.png";
import UserService from "../../../services/UserService";
import Header from "../Header"
import Sidebar from "../Sidebar";

export default function Compensateco2() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const url = window.location.href;
  const word = "session_id";
  const payOk = url.includes(word) ? "true" : "false";
  const param = url.split("?")[1];
  const [Idsession, Updatesession] = useState("");

  // const [Idsession, UpdateSession] = useState({
  //   IdsessionData: [],
  //   isFetching: false
  // });

  payOk === "true"
    ? useEffect(() => {
      fetch(`${process.env.REACT_APP_COMPENSATE}${param}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${UserService.getToken()}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((Idsession) => {
          if (!Idsession.error) {
            Updatesession(Idsession);
          }
        });
    }, [])
    : <>
    </>;

  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content */}
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="mt-5 ml-3 d-flex flex-column">
              {/* <div className='div-logo'>
          <Image src="https://www.airco2.earth/logo2.svg"></Image>
        </div> */}
              <div className="container-card align-self-center">
                {payOk === "true" ? (
                  <div>
                    <img alt="mano ok" src={mano}></img>
                  </div>
                ) : (
                  <div>
                    <img alt="mano ko" src={manobajo}></img>
                  </div>
                )}

                {payOk === "true" ? (
                  <div>
                    <h1
                      className=" font-bold"
                      style={{ fontSize: "1.5rem", color: "#56596e" }}
                    >
                      {" "}
                      Tu pago ha sido un éxito!
                    </h1>
                    {Idsession.length === 0 ? <Spinner />
                      :
                      (<div className="ticket" style={{ color: "#808080" }}>
                        <p className="text-sm text-center">
                          Ya has compensado un total de{" "}
                          <b>{(Idsession.co2TonsCompensated).toFixed(2)}</b> toneladas de CO₂!
                        </p>
                        <hr></hr>
                        <b className="ml-2 text-center">Resumen de la transacción:</b>
                        <Row className="mt-2 text-sm">
                          <Row>
                            <Col className="col-8">CO₂ compensado</Col>
                            <Col className="col-4">
                              <b className="text-base text-right">
                                {Idsession.co2Tons}t
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-8">Cantidad</Col>
                            <Col className="col-4">
                              <b className="text-base text-right">
                                {Idsession.amount}€
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-8">Precio CO₂</Col>
                            <Col className="col-4">
                              <b className="text-base text-right">
                                {Idsession.co2Price}€
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-8">Descuento</Col>
                            <Col className="col-4">
                              <b className="text-base text-right">
                                {Idsession.descuento * 100}%
                              </b>
                            </Col>
                          </Row>
                        </Row>
                      </div>)}
                    <Link className="text-decoration-none" to="/company/dashboardintranet">
                      <h3 className="font-bold mt-3 text-center " style={{ color: "#808080" }}>{" "}Volver</h3>
                    </Link>
                  </div>
                ) : (
                  <div>
                    <h1 className=" font-bold" style={{ fontSize: "1.5rem", color: "#56596e" }}>{" "}Error,inténtelo más tarde</h1>
                    <Link className="text-decoration-none" to="/company/dashboardintranet">
                      <h3 className="font-bold mt-3 text-center " style={{ color: "#808080" }}>{" "}Volver</h3>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
