import React, { useEffect, useContext } from "react";
import format from "date-fns/format";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import UserService from "../../../services/UserService";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from "react-i18next";

function DocumentListTableItem({
  documents,
  handleUpdateDocuments,
  enableDelete,
  idCalculation,
  idCuestionAnswer,
  getFileUrl,
}) {
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const pathParams = useParams();
  if (idCalculation === undefined) {
    idCalculation = pathParams.id;
  }
  const [documentsList, setDocumentsList] = useState(documents);
  const [spinner, setSpinner] = useState(
    new Array(documentsList.length).fill(false)
  );

  useEffect(() => {
    setDocumentsList(documents);
  }, [documents]);

  const handleOpenPdf = (doc, i) => {
    if (doc.url) {
      window.open(doc.url);
    } else {
      let updateSpinners = [...spinner];
      updateSpinners[i] = true;
      setSpinner(updateSpinners);
      fetchDocumentsData(doc, i);
    }
  };

  const fetchDocumentsData = async (doc, index) => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      let url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/documentation/${doc.id}`;
      if (getFileUrl === "external") {
        url = `${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idCalculation}/documentation/${doc.id}/answer/${doc.externalId}?contactId=${doc.contactId}`;
      } else {
        headers.Authorization = `Bearer ${UserService.getToken()}`;
      }
      const response = await axios.get(url, {
        headers: headers,
      });
      window.open(response.data.data);
    } catch (exception) {
      console.error(exception);
    }

    let updateSpinners = [...spinner];
    updateSpinners[index] = false;
    setSpinner(updateSpinners);
  };

  const deleteDocument = async (docId) => {
    setModalConfirmDelete({
      view: modalConfirmDelete.view,
      id: modalConfirmDelete.id,
      spinner: true,
    });
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/documentation/${docId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`,
          },
        }
      );
      if (response.status == "200") {
        let updatedDocumentList = documentsList.filter(
          (obj) => obj.id !== docId
        );
        setDocumentsList(updatedDocumentList);
        handleUpdateDocuments(idCuestionAnswer, updatedDocumentList);
      }
    } catch (exception) {
      console.error(exception);
    }
    setModalConfirmDelete({ view: false, id: null, spinner: false });
  };
  const [modalConfirmDelete, setModalConfirmDelete] = useState({
    view: false,
    id: null,
    spinner: false,
  });

  // const openModal = () => {

  //   setShowModal(true);
  // };
  return (
    <>
      <Modal show={modalConfirmDelete.view}>
        <ModalHeader className="sec-green-background text-white text-lg">
          {t("footPrint.deleteDoc")}
        </ModalHeader>
        <ModalBody>
          <p className="text-center">{t("footPrint.seguroDeleteDoc")}</p>
        </ModalBody>
        <div className="flex justify-center space-x-4 mb-4">
          <button
            className="btn-sm btn-cancel"
            onClick={() =>
              setModalConfirmDelete({ view: false, id: null, spinner: false })
            }
          >
            {t("footPrint.cancelar")}
          </button>
          <button
            className="btn-sm btn-green"
            onClick={() => deleteDocument(modalConfirmDelete.id)}
          >
            {modalConfirmDelete.spinner ? (
              <Spinner variant="light" animation="border" />
            ) : (
              t("footPrint.eliminar")
            )}
          </button>
        </div>
      </Modal>
      <tbody className="text-sm">
        {/* Row */}
        {documentsList.map((v, i) => {
          return (
            <tr key={v.id}>
              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div>{format(new Date(v.creationDate), "dd/MM/yyyy")}</div>
              </td>
              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                {v.name
                  ? v.name
                  : v.url && v.url.split("/")[5]
                  ? v.url.split("/")[5]
                  : "N/A"}
                <div className="text-center"></div>
              </td>
              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="flex justify-center space-x-2">
                  <button
                    className="text-center"
                    onClick={() => handleOpenPdf(v, i)}
                    title={t("footPrint.abrirDoc")}
                  >
                    {spinner[i] ? (
                      <Spinner variant="dark" animation="border" />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-files"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="var(--airco2-green-color)"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                        <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                        <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                      </svg>
                    )}
                  </button>
                  {enableDelete && (
                    // <button className="text-center" onClick={()=>handleDeletePdf(v,i)} title='Eliminar documento'>
                    <button
                      className="text-center"
                      onClick={() =>
                        setModalConfirmDelete({
                          view: true,
                          id: v.id,
                          spinner: false,
                        })
                      }
                      title={t("footPrint.eliminarDoc")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-trash"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="var(--airco2-green-color)"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 7l16 0" />
                        <path d="M10 11l0 6" />
                        <path d="M14 11l0 6" />
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg>
                    </button>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
}

export default DocumentListTableItem;
