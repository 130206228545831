import axios from "axios";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import UserService from "../../../services/UserService";
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

export default function ModalRecalculate({ modalRecalculate, setModalRecalculate, recalculating, setRecalculating, updateFromRecalculate }) {
    const [t, i18n] = useTranslation("global");
    const [recalculateResult, setRecalculateResult] = useState(undefined);

    const handleCloseReport = () => {
        setModalRecalculate({ view: false, id: null });
        setRecalculateResult(undefined)
    }


    const handleRecalculate = async () => {
        setRecalculating({ id: modalRecalculate.id, isFetching: true });

        await axios
            .get(`${process.env.REACT_APP_CH_RECALCULATE}/${modalRecalculate.id}`, {
                headers: {
                    // "Access-Control-Allow-Origin": "*",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${UserService.getToken()}`,
                },
            })
            .then((response) => {
                setRecalculateResult(response.data.data);
                setModalRecalculate({ view: true, id: modalRecalculate.id });
                updateFromRecalculate(modalRecalculate.id)

            })
            .catch((error) => {
                console.error(error);
            });
        setRecalculating({ id: 0, isFetching: false });

    }

    return (<Modal show={modalRecalculate.view} animation={false} data-aos="fade-up" >
        <Modal.Header className='sec-green-background'>
            <Modal.Title>{t("footPrint.recalculate.title")} {modalRecalculate.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {recalculating.isFetching
                && recalculating.id != 0 && <div className="text-center">
                    {t("footPrint.recalculate.recalculatingC", [modalRecalculate.id])}
                    <div className="mt-3"><Spinner
                        animation="border"
                        variant="dark"
                    /></div>
                </div>}
            {
                recalculateResult != undefined && !recalculating.isFetching && <div className="text-center">
                    {t("footPrint.recalculate.update", [recalculateResult.hasErrors ? t("footPrint.recalculate.wError") : t("footPrint.recalculate.nError")])}<br /> {recalculateResult.updatedAnswers}/{recalculateResult.totalAnswers}</div>
            }
            {recalculateResult == undefined && !recalculating.isFetching && <>
                <div className='p-4'>
                    {t("footPrint.recalculate.text", [modalRecalculate.id])}
                </div>
                <div className='flex space-x-4 justify-center mt-4'>

                    <button onClick={() => handleRecalculate()} className='btn-cancel rounded-lg p-2'>{recalculating.isFetching &&
                        recalculating.id === modalRecalculate.id ? (
                        <Spinner
                            animation="border"
                            variant="light"
                        />) :
                        t("footPrint.recalculate.recalculate")
                    }
                    </button>
                </div>
            </>}

        </Modal.Body>
        <Modal.Footer>
            <button onClick={() => handleCloseReport()} className='btn-cancel rounded-lg p-2'>{t("footPrint.cerrarCerrar")}</button>

        </Modal.Footer>
    </Modal >)
}