import Footer from "../../../partials/Footer";
import Header from "../../../partials/Header";
import BannerDemo from "../componentsHome/BannerDemo";
import Terms from "./Terms";
import { useEffect } from "react";

export default function TermsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (<>
    <Header></Header>
    <Terms></Terms>
    <BannerDemo></BannerDemo>
    <Footer></Footer>
  </>)
}