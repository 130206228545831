import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatThousands } from './../utils/Utils';

function GetTransactions03({ finalDataToProcess, columns, updateStep, coinscrapAccount, periodoCoinscrap }) {
  const [t, i18n] = useTranslation("global");


  return (
    <main>

      <div className="min-h-screen h-full flex flex-col after:flex-1">


        <div className="py-8">
          <div className="max-w-6xl mx-auto">

            <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("exceltransactions.title04")} ✨</h1>
            <h3 className="text-gray-800 mb-12">{t("exceltransactions.subtitle04")} <br />
              {t("exceltransactions.subtitle04fechas")}
              {coinscrapAccount && t("exceltransactions.subtitle04coinscrap").replace("[date]", periodoCoinscrap.split("-")[2] + "/" + periodoCoinscrap.split("-")[1] + "/" + periodoCoinscrap.split("-")[0])}.   </h3>

          </div>
          {/* Form */}
          <div className="max-w-6xl mx-auto">
            <form>
              <div className="space-y-3 mb-8">
                <div className='table-responsive'>
                  <table className="table-bordered  table-auto w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        {columns.map((c, index) => (
                          <th className="px-2 text-center first:pl-5 last:pr-5 py-3 " key={index}>{t("exceltransactions." + c)}  </th>))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {finalDataToProcess.slice(1, 5).map((item, index) => (
                        // {finalDataToProcess.map((item, index) => (
                        <tr key={`tr${index}`}>
                          {item.slice(0, columns.length).map((element, i) =>
                            <td className={`px-2 text-center first:pl-5 last:pr-5 py-3 `} key={`td${i}`}>
                              {isNaN(element) ? element : formatThousands(element)}
                            </td>)}
                        </tr>
                      ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <a className="btn sec-grey-background text-sm text-white  hover:no-underline" onClick={() => { updateStep(2) }}>{t("exceltransactions.previous")}</a>
                <a className="btn sec-green-background hover:sec-green-background text-white " onClick={() => { updateStep(4) }}>{t("exceltransactions.send")}</a>
              </div>
            </form>
          </div>
        </div>

      </div>





    </main>
  );
}

export default GetTransactions03;