import React from 'react'
import Header from '../../../partials/Header'
import Footer from '../Footer'
import BloqueBanner from './BloqueBanner'
import BloqueVideo from './BloqueVideo'
import BloqueIconos from './BloqueIconos'
import BloqueCasos from './BloqueCasos'
import BloqueTxtImg from './BloqueTxtImg'
import BloqueImg from './BloqueImg'
import BloqueForm from './BloqueForm'

export default function Concellos() {
    return (
        <main className=''>
            <div className='bg-concellos'>
                <Header></Header>
                <div className='bloqueBanner-concellos' style={{ minHeight: 'calc(100vh - 94px)', paddingTop: '20px' }}>
                    <BloqueBanner></BloqueBanner>
                </div>

            </div>

            <div>
                <BloqueVideo></BloqueVideo>
                <BloqueIconos></BloqueIconos>
                <BloqueCasos></BloqueCasos>
                <BloqueTxtImg></BloqueTxtImg>
                <BloqueImg></BloqueImg>
                <BloqueForm></BloqueForm>


            </div>



            {/* Footer */}
            <Footer></Footer>


        </main>
    )
}
