import React, { useState, useContext, useEffect } from 'react';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Translations from '../../../services/Translations';

function ForestListTableItem(props) {

  const statusColor = (props) => {
    if (props.active && props.validated) {
      return 'bg-green-100 text-green-600';
    } else {
      switch (props.validated) {
        case true:
          return 'bg-gray-100 text-yellow-600';
        case false:
          return 'bg-gray-100 text-gray-500';
      }
    }
  };

  const statusName = (props) => {
    if (props.active && props.validated) {
      return t("forestList.sActive");
    } else {
      switch (props.validated) {
        case true:
          return t("forestList.sPorValidar");
        case false:
          return t("forestList.sCreated");
      }
    }
  };


  const renderTooltipEditarMonte = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("marketplace.forestListMP_edit")}
    </Tooltip>
  );
  const renderTooltipImages = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("marketplace.forestListMP_uploadEditImgs")}
    </Tooltip>
  );
  const renderTooltipMP = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("marketplace.forestListMP_marketplaceUrl")}
    </Tooltip>
  );

  // const handleClickImages = (props) => {
  //   // e.preventDefault();
  //   let productWimages = {
  //     "origen": "marketplace",
  //     "idProduct": props.id,
  //     "name": props.name,
  //     "images": props.productImageDtoList
  //   }
  //   const urlImages = `http://localhost:3000/forest/imagesMarketplace?data=` + encodeURIComponent(JSON.stringify(productWimages));
  //   window.open(urlImages, '_blank');
  // }

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  return (
    <tbody className="text-sm">
      {/* Row */}
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center flex items-center">
            {props.name}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-right">{props.cadastre}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{props.price} €/t</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{t(Translations.translateForestTypeName(props.forestType))}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className={`rounded-full py-0.5  text-center ${statusColor(props)}`}>
            {statusName(props)}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-right">{props?.stockCredits?.toFixed(2)}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-right">{props?.totalCredits?.toFixed(2)}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{props.city + ", " + props.state}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{format(new Date(props.updatedDate), 'dd/MM/yyyy')}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex justify-center space-x-2">
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipEditarMonte}>
              <Link to="/forest/formMarketplace?origen=mp" state={props.allData}>
                <button>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                    <line x1="16" y1="5" x2="19" y2="8" />
                  </svg>
                </button>
              </Link>
            </OverlayTrigger>
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipImages}>
              <Link to="/forest/imagesMarketplace?origen=mp" state={props.allData}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="15" y1="8" x2="15.01" y2="8" />
                  <rect x="4" y="4" width="16" height="16" rx="3" />
                  <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                  <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
                </svg>
              </Link>
            </OverlayTrigger>
            {props.active && props.validated ?
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipMP}>
                <a href={`${process.env.REACT_APP_CATALOGUE_MP}/${props.id}`} target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="2" />
                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                  </svg>
                </a>
              </OverlayTrigger>

              :
              ""
            }
          </div>
        </td>
      </tr>
    </tbody>


  );
}

export default ForestListTableItem;