import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "./../../../context/createcontext";

import {
  Chart, Filler, LinearScale, LineController, LineElement, PointElement, TimeScale, Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt"
import "moment/locale/gl"


// Import utilities
import { formatThousands, tailwindConfig } from '../utils/Utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

function LineChart01({
  data,
  width,
  height,
  unit
}) {

  const canvas = useRef(null);
  // cambiar idioma
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    switch (lng) {
      case "en":
        moment.locale("en");
        break;
      case "pt":
        moment.locale("pt");
        break;
      case "gl":
        moment.locale("gl");
        break;
      default:
        moment.locale("es");
        break;
    }
    const chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        chartArea: {
          backgroundColor: tailwindConfig().theme.colors.gray[50],
        },
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            display: true,
            beginAtZero: true,
          },
          x: {
            type: 'time',
            time: {
              parser: 'MM-DD-YYYY',
              unit: 'month',
              displayFormats: {
                month: 'MMM',
              },
            },
            display: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatThousands(context.parsed.y) + (unit != undefined ? unit : ""),
            },
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
    });
    return () => chart.destroy();
  }, [lng]);

  return (
    <canvas ref={canvas} width={width} height={height}></canvas>
  );
}

export default LineChart01;