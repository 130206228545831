import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Header from '../Header'
import { IdiomContext } from "../../../context/createcontext";
import UserService from '../../../services/UserService';

export default function DashboardCoinscrap() {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [srcCoinscrap, setSrcCoinscrap] = useState("");

  const [name, setName] = useState(UserService.getUsername() == undefined ? "" : UserService.getUsername())


  const dataToken = {
    "id": name,
    "email": name,
    "cif": "",
    "cne": "",
    "dso": ""
  }


  let [Usertoken, UpdateToken] = useState([])

  // useEffect para conseguir el token del usuario

  useEffect(() => {
    UserService.setShowCoinscrap(false);
    fetch(process.env.REACT_APP_TOKEN,

      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        },
        body: JSON.stringify(dataToken)
      }).then(j => j.json())
      .then(data => {
        let genToken = data.token
        UpdateToken(data.token)

        setSrcCoinscrap(`${process.env.REACT_APP_TOKEN_RES}${genToken}`);
        //window.open(srcCoinscrap, '_blank').focus();
      }
      )

  }, []

  )
  return (
    <>

      <Header />
      <div className='sec-neutro-background'>
        <div className='container text-center '><Link to="/company/dashboardintranet" className="nav-link sec-grey-text font-medium">Volver al área interna</Link></div>
        <div className=''>
          <iframe title="airco2" src={srcCoinscrap}   className="i-coinscrap" height="calc(100vh -104px)" width="100%" frameBorder="0"></iframe> 
        </div>
      </div>


    </>
  )
}
