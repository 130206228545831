import Dropzone from "../utils/Dropzone";
import { React, useState, useContext } from 'react';
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from 'react-i18next';
import Sidebar from "../Sidebar";
import Header from '../Header';
import Billing from "./Billing"


export default function AppDrop() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* <main className="box-drag"> */}
        <div className="body-content">  
      
    
        {/* <div className="content">
          <Dropzone actualizar={actualizar} url={urlDropzone} validExtension={[]} size={maxSize} />
        </div> */}
        {/* <div className="table-drop">
            <Billing/>
        </div> */}

{/*   
    </main> */}
    </div>
    </div>
    </div>
)
    

}
