import React, { useState, useContext } from 'react';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';

function Stats() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <section className='sec-green-background'>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

      
          <div className="grid md:grid-cols-3 divide-y md:divide-y-0 md:divide-x divide-gray-700 px-6 md:px-0 md:py-8 text-center sec-green-background ">
            {/* 1st item */}
            <div className="py-6 md:py-0 md:px-8" >
              <div className="text30 leading-tight tracking-tighter sec-blue-text mb-2" data-aos="fade-up" >15 M</div>
              <div className="text14 sec-white-text" data-aos="fade-up" >{t("forespoints.hectareas")}</div>
            </div>
            {/* 2nd item */}
            <div className="py-6 md:py-0 md:px-8">
              <div className="text30 leading-tight tracking-tighter sec-blue-text mb-2" data-aos="fade-up" >+ 80.000</div>
              <div className="text14 sec-white-text" data-aos="fade-up" >{t("forespoints.hectareasparte")}</div>
            </div>
            {/* 3rd item */}
            <div className="py-6 md:py-0 md:px-8">
              <div className="text30 leading-tight tracking-tighter sec-blue-text mb-2" data-aos="fade-up" >+ 2 M</div>
              <div className="text14 sec-white-text" data-aos="fade-up" data-aos-delay="200" >{t("forespoints.millones")}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;