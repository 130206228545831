import React from 'react';
import { useTranslation } from 'react-i18next';


function GetTransactions01({ handleFile, updateStep, fileName, fileFormat, fileAttributes }) {
  const [t, i18n] = useTranslation("global");

  return (
    <main>

      <div className="min-h-screen h-full flex flex-col after:flex-1">


        <div className=" py-8">
          <div className="max-w-6xl mx-auto">

            <h1 className="text-3xl text-gray-800 font-bold mb-6">{ t("exceltransactions.title02")} ✨</h1>
            <h3 className="text-gray-800 mb-12">{fileAttributes.name == "Otra" ? t("exceltransactions.subtitle02b") : t("exceltransactions.subtitle02")} </h3>

            {/* Form */}
            <form>
              <div className="space-y-3 mb-8">
                <input className='form-control form-input grey-input ' type="file" accept={`.${fileFormat}`} onChange={(e) => handleFile(e)} />
              </div>



              <div className="flex items-center justify-between mt-5">
                <a className="btn sec-grey-background text-sm text-white  hover:no-underline" onClick={() => { updateStep(0) }}>{t("exceltransactions.previous")}</a>
                <a className="btn sec-green-background hover:sec-green-background text-white ml-auto" onClick={() => { updateStep(2) }}>{t("exceltransactions.next")}</a>
              </div>
            </form>

          </div>
        </div>

      </div>





    </main>
  );
}

export default GetTransactions01;