import { Helmet } from "react-helmet";
import metatag_image from "./../images/bussines.webp";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

export default function PageMetatags({ tags, image }) {
  const { t } = useTranslation("global");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setDescription] = useState("");

  useEffect(() => {
    if (tags.description === undefined || tags.description === "") {
      setMetaTitle(t("meta.title"));
    } else {
      setMetaTitle(tags.title);
    }

    if (metaDescription === undefined || metaDescription === "") {
      setDescription(t("meta.description"));
    } else {
      setDescription(tags.description);
    }

    if (image === undefined || image === "") {
      image = metatag_image;
    }
  }, [tags]);

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={window.location.href} />

      {tags.robotsIndex ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}

      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={window.location.href} />

      <meta name="twitter:card" content="photo" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={window.location.href} />
    </Helmet>
  );
}
