import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext } from 'react'

export default function BloqueImg() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div>
            <div className='bloqueImg-txt text-white sec-blue-background p-4'>
                <div className='px-4 text-center py-5'>

                    <p className='text-2xl text-airco2-titles-bloques  font-bold'>
                        {(t("landingConcellos.contrato"))}
                    </p>

                    <p className='text-subtitle-bloques'>
                        <span className='font-bold text-airco2-principal'>  {(t("landingConcellos.ayuntamientoLidera"))}</span>
                        <span className='font-bold'> airCO2</span>
                        <span>  {(t("landingConcellos.ejecuta"))}</span>

                    </p>
                </div>


            </div>

            <div className='bloqueImg-img text-2xl text-airco2-title-concellos text-white flex flex-col justify-center items-center'>
                <div className='flex flex-wrap justify-center text-center'>
                    <span className=' px-2'>{(t("landingConcellos.empresasLimpias"))}</span>
                    <span className=' px-2'>{(t("landingConcellos.montes"))}  <span className='font-bold' >{(t("landingConcellos.limpios"))}</span></span>

                </div>

                <div>
                    <p className='text-center w-full font-bold px-2'>{(t("landingConcellos.juntos"))}</p>

                </div>






            </div>



        </div>
    )
}
