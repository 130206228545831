import React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BarChart from '../../charts/BarChart04';
import { IdiomContext } from "../../../../context/createcontext";
import { Spinner } from 'reactstrap';
// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function EmissionsByQuestion({ data }) {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const arrayLabels = [' kgCO₂e'];



  const groupedData = data?.reduce((acc, item) => {
    const existingItem = acc.find((groupedItem) => groupedItem.question === item.question);
    if (existingItem) {
      existingItem.totalKgCO2e += item.totalKgCO2e;
      // existingItem.gch4 += item.gch4;
      // existingItem.gn2O += item.gn2O;
    } else {
      acc.push({
        question: item.question,
        totalKgCO2e: item.totalKgCO2e,
        // gch4: item.gch4,
        // gn2O: item.gn2O,
      });
    }
    return acc;
  }, []);


  const chartData = {
    labels: groupedData?.map((item) => item.question),
    datasets: [
      {
        label: arrayLabels[0],
        data: groupedData?.map((item) => item.totalKgCO2e),
        backgroundColor: [tailwindConfig().theme.colors.lime[400]],
        hoverBackgroundColor: [tailwindConfig().theme.colors.lime[500]],
        categoryPercentage: 0.66,
      }
    ]
  };


  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">{t("footPrint.emissionsByQuestion")}</h2>
      </header>


      {groupedData === undefined &&
        <div className="text-center"><Spinner className='' /></div>
      }

      {groupedData !== undefined && groupedData.length == 0 &&
        <div className='mt-4 mb-4'>{t("footPrint.noDataToGraphic")}</div>}

      {groupedData !== undefined && groupedData.length > 0 &&
        <BarChart data={chartData} height={498} unit={" kg"} />
      }

    </div>
  );
}


export default EmissionsByQuestion;