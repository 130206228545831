import React, { useState, useEffect, useContext } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from 'axios';
import { useParams } from "react-router-dom";
import UserService from '../../../services/UserService'
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';


const ModifyReportPdf = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const pathParams = useParams();
  const idCalculation = pathParams.id;
  const reportType = pathParams?.reportType == "alcances" ? "" : pathParams?.reportType;

  const [pdfContent, setPdfContent] = useState({ data: undefined, isFetching: false });
  const [pdfContentModified, setPdfContentModified] = useState({ data: undefined, isFetching: false });
  const [copySuccess, setCopySuccess] = useState(false);
  const [extractedContent, setExtractedContent] = useState('');


  useEffect(() => {
    const fetchPdfContent = async () => {
      setPdfContent({ data: undefined, isFetching: true });
      try {
        const response = await axios.get(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/report/pdf/content?reportTableType=${reportType}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setPdfContent({ data: response.data.data, isFetching: false });
      } catch (exception) {
        console.error(exception);
      }
    };
    fetchPdfContent();
  }, [idCalculation]);

  const createPdf = async (e) => {
    e.preventDefault();
    setPdfContentModified({ data: undefined, isFetching: true });

    let modifiedContent = document.getElementById("modifiedContent").value;
    let parts = modifiedContent.split(/<\/p>/).slice(2);
    let updatedContent = parts.join('</p>').trim(); 

    let data = extractedContent + updatedContent + "</body></html>";
    try {
      const response = await axios.post(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/report/pdf?reportTableType=${reportType}`, { htmlCode :  data }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
      });
      let responseData = response.data.data;
      setPdfContentModified({ data: responseData, isFetching: false });

      switch (reportType) {
        case "ISO":
          window.open(responseData.urlReportISO, '_blank')
          break;
        case "GHG":
          window.open(responseData.urlReportGHG, '_blank')
          break;  
        case "":
          window.open(responseData.urlReport, '_blank')
          break;
      }
        
    } catch (exception) {
      console.error(exception);
    }
  };


  useEffect(() => {
    if(pdfContent.data != undefined){
      const doctypeIndex = pdfContent.data.indexOf('<!DOCTYPE html>');
      const bodyIndex = pdfContent.data.indexOf('<body>');

      if (doctypeIndex !== -1 && bodyIndex !== -1) {
        const extracted = pdfContent.data.substring(doctypeIndex, bodyIndex);
        setExtractedContent(extracted + "<body>");
      }
    }
  }, [pdfContent.data]);

  const copyHtmlCode = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(pdfContent.data)
        .then(() => {
          setCopySuccess(true);
          setTimeout(() => {
            setCopySuccess(false);
          }, 1500);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }
  };



  return (
    <div className="sec-beige-background flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="sec-beige-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <div><h2 className='h3'>Editar reporte {reportType} del cálculo {idCalculation}</h2></div>
            <button className="ml-4 mt-2 text-base font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
              </svg>
              <span>{t("footPrint.volver")}</span>
            </button>

            <div className='my-4'>
              {pdfContent.isFetching ?
              <div className='flex justify-center items center gap-2'>
                <span>Cargando datos</span><Spinner animation="border" /> 
              </div>
              :
              <div className='flex justify-between gap-2 '>
                <div className='col-4 flex flex-col'>
                  <span className='text-center mb-2'>Copia el HTML y pégalo en el <b>HTML code editor</b> para poder editar el reporte</span>
                  <div className='flex justify-center'>
                    <button className='btn-sm btn-green rounded-lg p-2' onClick={copyHtmlCode}>Copiar HTML</button>
                  </div>  
                  {copySuccess && <div className="font-bold text-base text-center">HTML copiado 👍</div>}
                </div>  
                <div className='col-8 '>
                  <p className='text-center'>Copia el código con los cambios desde el <b>HTML code editor</b> y pégalo en el texarea para generar el PDF</p>
                  <form className='row gap-2 ' onSubmit={(e) => createPdf(e)}>
                    <textarea id="modifiedContent" required className='w-full' />
                    <div className='flex justify-center'>
                      <button className='btn-sm btn-banner-o rounded-lg p-2' type="submit" disabled={pdfContentModified.isFetching}>
                        {pdfContentModified.isFetching ? <span className='flex justify-center items-center gap-2'><Spinner animation="border" /> Generando</span> : "Generar reporte"}
                      </button> 
                    </div>
                  </form>
                </div>
              </div>
              }
            </div>

            <div className="bg-white border">
              <iframe src="https://editorhtmlonline.com/" width="100%" height="700px"></iframe>
            </div>

          </div>
        </main>
      </div>
    </div>
  );

};

export default ModifyReportPdf;