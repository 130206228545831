import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { IdiomContext } from "../../../context/createcontext";
import LineChart from './../charts/LineChartDays';
import Progress from './../charts/Progress';
import { formatThousands } from './../utils/Utils';




// Import utilities
import { hexToRGB, tailwindConfig } from './../utils/Utils';

function GraficaHuellaCompensada(props) {

  const transactions = props.data

  const generada = props.dataGenerada
  // cambiar idioma  
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  const fecha = new Date();
  const mesActual = fecha.getMonth() + 1;
  const year = fecha.getFullYear()



  let sumCo2Generado = generada.filter(v => v.category === null).reduce(function (s, a) {
    return s + a.co2;
  }, 0);



  //Ordenar por fecha co2 generado

  const sortedGenerated = generada.sort(
    (objA, objB) => Number(objA.month) - Number(objB.month),
  );

  let Co2Generado = sortedGenerated.filter(v => v.category === null).map((v) => {
    return v.co2

  }

  )
  let mesesGenerado = sortedGenerated.filter(v => v.category === null).map((v) => {
    return v

  }
  )

  // evalua si cada mes, hasta el actual, tiene o no emisiones para poner su valor o 0
  const array = []

  for (let i = 1; i < mesActual + 1; i++) {
    const co2 = mesesGenerado.filter((mes) => mes.month === i)?.[0]?.co2 || 0;
    array.push(co2);
  }


  // funcion para un nuevo array con el valor acumulado del anterior
  const sumaAGenerado = (sum => value => sum += (value / 1000))(0);

  // nuevo array con el co2 acumulado generado 
  const co2AcumuladoGenerado = array.map(sumaAGenerado);


  //Ordernar por fecha transacciones

  const sortedTransactionsListed = transactions.sort(
    (objA, objB) => Number(objA.date) - Number(objB.date),
  );


 


  const arrayCompensado = []
  for (let i = 1; i <= mesActual; i++) {
    const gr = sortedTransactionsListed
      .filter(item => {
        return Number(item.date.split("-")[1]) === i
      })

    const gr2 = gr.reduce((previoius, current) => {
      return previoius + current["co2Amount"]
    },
      0);

    
    arrayCompensado.push(gr2);
  }






  // const dates = sortedTransactionsListed.map((v) => {
  //   return format(new Date(v.date), 'dd/MM/yyyy');
  // })



  // const co2Amount = sortedTransactionsListed.map((v) => {
  //   return v.co2Amount
  // }
  // )
  


  let sumco2 = sortedTransactionsListed.reduce(function (s, a) {
    return s + a.co2Amount;
  }, 0);

  // nuevo array con el co2 acumulado compensado 
  const sumaAcumulada = (sum => value => sum += value)(0);

 
  const co2Acumulada = arrayCompensado.map(sumaAcumulada);



  const meses = [
    `01-01-${year}`, `02-01-${year}`,
    `03-01-${year}`, `04-01-${year}`, `05-01-${year}`,
    `06-01-${year}`, `07-01-${year}`, `08-01-${year}`,
    `09-01-${year}`, `10-01-${year}`, `11-01-${year}`,
    `12-01-${year}`,]

  const chartData = {
    labels: meses,
    datasets: [
      // Gray line
      {
        label: t("dash.acumuladagenerada"),
        data: co2AcumuladoGenerado,
        borderColor: tailwindConfig().theme.colors.gray[300],
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[300])}, 1)`,
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
        clip: 20,
      },
      // Indigo line
      {
        label: t("dash.acumuladacompensada"),
        data: co2Acumulada,
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.5)`,
        color: 'red',
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        clip: 20,
      },


    ],
  };

  return (

    <div id="ghuellacompensada" className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white rounded-sm border border-gray-200">
      <div className="px-5 pt-5">


        {/* <header className="flex justify-between items-start mb-2">
           Icon
          <img src={Icon} width="32" height="32" alt="Icon 02" />
        </header> */}
        <h2 className="text-lg font-semibold text-gray-800 mb-2">{t("dash.huellacumulada")}{new Date().getFullYear()}</h2>
        <div className="flex items-end">
          <div className="text-3xl font-bold text-gray-800 mr-2">{formatThousands(sumco2)} t</div>
          <div className="text-sm font-semibold text-gray-400"> CO<sub>2</sub></div>
          {/* <div className="text-sm font-semibold text-white px-1.5 bg-yellow-500 rounded-full">-14%</div> */}
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        {generada.length === 0 ? <Spinner className='flex justify-center' /> : <LineChart data={chartData} width={389} height={128} unit={" t"} />}

      </div>
      <Progress generado={sumCo2Generado / 1000} compensado={sumco2} />
    </div>
  );
}

export default GraficaHuellaCompensada;
