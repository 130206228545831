import { useState } from "react";

export default function QuestionIntro({ question, updateShowQuestionAndExternalData, updatedSelectedFormat, formatToAnswer, t, updatedShowAnswerTable }) {

    const [externalData, setExternalData] = useState(false);
    const [selectedFormat, setSelectedFormat] = useState(formatToAnswer);
   
    const setShowAnswerTable = () => {
        updatedShowAnswerTable(true)
    }

    const handleUpdateShowQuestionInputs = () => {
        updateShowQuestionAndExternalData(externalData);
        updatedSelectedFormat(selectedFormat);
    };

    return <div className=" text-gray-800  px-1  flex flex-col">
        <div className=''>

            <h1 className=' text-center text-xl font-bold mb-4 mt-1 flex gap-2'>
                <span> {/* {t("fpThird.preg")}  */}
                    {question.currentIndex + 1}. </span><span>{question.title}</span>
            </h1>

            <div className="text-md md:text-xl mb-4">
                <p className='mb-1 font-semibold'>
                    {t("fpThird.descripcion")}:
                </p>
                <div dangerouslySetInnerHTML={{ __html: question.description }} />
            </div>

            <div className="text-md md:text-xl">
                <p className='mb-1 font-semibold'>
                    {t("fpThird.instrucciones")}:
                </p>
                <div dangerouslySetInnerHTML={{ __html: question.instructions }} />
            </div>
        </div>
        <div className='flex flex-col items-center'>
            {(question.id != 5 && question.id != 6) &&  //!question.showIntro && 
                <div className='mt-4'>
                    <div className='my-2 font-semibold'>
                        <p>{t("fpThird.selectOption")}</p>
                    </div>
                    <div className='flex flex-row items-center space-x-2'>
                        <input type="radio" name="answerFormat" value="inputs" id="inputs" onChange={() => { setSelectedFormat("inputs"); setExternalData(externalData) }} checked={selectedFormat == "inputs" ? true : false} />
                        <span htmlFor="inputs">{t("fpThird.useForm")}</span>
                    </div>
                    {question.idQuestionToExternalData && <div className='flex flex-row items-center space-x-2'>
                        <input type="radio" name="answerFormat" value="calculada" id="calculada" onChange={() => { setSelectedFormat("calculada"); setExternalData(true) }} checked={selectedFormat == "calculada" ? true : false} />
                        <span htmlFor="calculada">{t("fpThird.knowdata")}</span>
                    </div>}
                    {!question.idQuestionToExternalData && question.id != 5 && <div className='flex flex-row items-center space-x-2'>
                        <input type="radio" name="answerFormat" value="excel" id="excel" onChange={() => setSelectedFormat("excel")} checked={selectedFormat == "excel" ? true : false} />
                        <span htmlFor="excel">{t("fpThird.uploadFromFile")}</span>
                    </div>}
                </div>
            }
        </div>

        <div className={`flex items-center justify-${question.answers > 0 ? "between" : "center"} gap-4 flex-wrap`} style={{ marginTop: '3rem' }}>
            {question.answers > 0 && <button className="btn-green text-md  rounded-lg px-4 py-2" type="submit" onClick={() => setShowAnswerTable(true)}>
                {t("fpThird.verRespuestas")} ({question.answers})</button>}
            <button className="btn-green text-md  rounded-lg px-8 py-2" type="submit" onClick={() => handleUpdateShowQuestionInputs()}>{t("fpThird.responder")}</button>
        </div>
    </div>
}