import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext.js";

function DropdownFootprintFilter({
  uniqueValues,
  applyFilter,
  nombreFiltro

}) {

  // cambiar el idioma
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [openDropDown, setOpenDropDown] = useState(false);

  const [data, setData] = useState([]);
  useEffect(() => {
    setData(uniqueValues);
  }, [uniqueValues]);


  const handleClickOption = (event, valor) => {
    const newState = data.map(obj => {
      if (valor === obj.id) {
        return { ...obj, check: event.target.checked };
      }
      return obj;
    });
    applyFilter(newState);
    setData(newState);
  };

  // Borrar filtros seleccionados
  const handleClear = () => {
    setOpenDropDown(false);

    const newState = data.map(obj => {
      return { ...obj, check: false };
    });
    applyFilter(newState);
    setData(newState);
  };

  // close on click outside
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Función para manejar el clic fuera del dropdown
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setOpenDropDown(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);


  function limpiarString(str) {
    return str
      .toLowerCase() // Convertir a minúsculas
      .normalize("NFD") // Normalizar para separar acentos de caracteres
      .replace(/[\u0300-\u036f]/g, "") // Eliminar caracteres de acento
      .replace(/\s/g, ''); // Eliminar espacios en blanco
  }


  return (
    <div id={`${limpiarString(nombreFiltro)}-filter`}
      ref={dropdownRef}
      className="relative inline-block text-left mr-2"
      >

      <button
        onClick={() => setOpenDropDown(!openDropDown)}
        className="text-xs py-1 px-2  inline-flex items-center h-full"
        style={{ border: '1px solid hsl(0, 0%, 80%)', alignSelf:"center", padding:"1.5px 10px"}}>
        <span>{nombreFiltro}
          {data.filter(f => f.check).length > 0 && " (" + data.filter(f => f.check).length + ")"}
        </span>
        <svg
          className={`fill-current h-4 w-4 ml-2 ${openDropDown ? 'transform rotate-180' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </button>

      {openDropDown && (
        <div className={`origin-top-right absolute  left-1/2 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`} style={{maxHeight: '350px', overflowY: 'auto'}}>
          <ul className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {data.map((opcion) => (
              <li key={opcion.id}>
                <div className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100">
                  <input
                    type="checkbox"
                    className="h-4 w-4"
                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                    checked={opcion.check}
                    onChange={(event) => handleClickOption(event, opcion.id)}
                  />
                  <label htmlFor={opcion.name} className="ml-2 mb-0 block text-gray-700 text-xs">
                    {opcion.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div className="py-2 px-3 border-t border-gray-200 bg-gray-50">
            <ul className="flex items-center justify-center min-w-44">
              <li>
                <button arial-label="btn-cleanFilter" className="btn-sm btn-cancel text-xs text-center"
                  onClick={handleClear} >
                  {t("sidebar.limpiar")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}

    </div >
  );
}

export default DropdownFootprintFilter;
