
import React from 'react';

const GreatPackagingQrRedirect = () => {
  const targetUrl = `${process.env.REACT_APP_URL}/airco2gpcencosud`;
  window.location.href = targetUrl;
  
  return null;
};

export default GreatPackagingQrRedirect;