import { useState, useEffect } from "react";
import { IdiomContext } from "./createcontext";

function IdiomProvider({ children }) {

    const idioma = localStorage.getItem('idioma')
    const browserLanguage = window.navigator.language.substring(0,2);
    const availableLanguages = ["es", "en", "gl", "pt"];
    const txtnav = browserLanguage != null && availableLanguages.some(v => v === browserLanguage) ? browserLanguage : 'es';
    const idiomState = useState( idioma !=  null ? idioma : txtnav)
  
    return (
       
            <IdiomContext.Provider value={idiomState}>
                {children}
            </IdiomContext.Provider>
      
    )


}

export default IdiomProvider