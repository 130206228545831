import React, { useContext, useEffect, useState, useMemo } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import UserService from '../../../services/UserService';
import { Spinner } from 'react-bootstrap';
import ForestListTableItemLine from '../forestales/ForestListTableItemLine';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';
import { formatDate } from '../utils/Utils';
import ForestActionsModal from './ForestActionsModal';
import ForestTable from './ForestTable';

export default function ForestryUser() {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const [updateForest, setUpdateForest] = useState({
    user: null, 
    monte: null, 
    action: null
  });

  const action = (props) => {
    setUpdateForest(props);
  }

  const [montes, setMontes] = useState({
    montesData: [],
    isFetching: false,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const dataString = urlParams.get('user');
  const userObject = JSON.parse(decodeURIComponent(dataString));

  if(userObject == null){
    window.location.href = '/adminusuarios';
  }



  const updateTable = (props) => {
    switch (updateForest.action) {

      case "create":
        setMontes({ montesData: [props, ...montes.montesData], isFetching: false });
      break;

      case "edit":
        const updatedForestArray = montes.montesData.map((item) => {
          if (item.id === props.id) {
            return props;
          }
          return item;
        });
        setMontes({ montesData: updatedForestArray, isFetching: false });
      break;
      
      case "delete":
        let montesNew = montes.montesData.filter((x) => x.id !== props);
        setMontes({montesData: montesNew, isFetching:false});
      break;
    }
  }




  useEffect(() => {
    const fetchMontesData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_FOREST}${userObject.id}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setMontes({ montesData: response.data, isFetching: false });
      } catch (exception) {
        console.error(exception);
        setMontes({ montesData: montes.montesData, isFetching: false });
      }
    };
    fetchMontesData();
  }, []);
    

    const useSortableData = (items, config = null) => {
      const [sortConfig, setSortConfig] = useState(config);
      const sortedItems = useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (!isNaN(a[sortConfig.key]) || moment(a[sortConfig.key]).isValid()) {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                } else {
                    if (a[sortConfig.key].toString().toLowerCase() < b[sortConfig.key].toString().toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key].toString().toLowerCase() > b[sortConfig.key].toString().toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                }

                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(montes.montesData);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
        return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-3">
              {/* Left: Title */}
              <div>
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Montes de <a href="mailto:" style={{color: '#270376'}}>{userObject.email}</a></h1>
              </div>
            </div>

            <ForestActionsModal props={updateForest} updateTable={updateTable}/>

            <div>
              <div className='flex justify-between mb-4 items-end'>
                <div>
                  <ul>
                    <li>Nombre: {userObject.name}</li>
                    <li>UserId: {userObject.id}</li>
                    <li>Fecha alta: {formatDate(userObject.dateCreated)}</li>
                    <li>Teléfono: {userObject.phone}</li>
                    <li>DNI: {userObject.dni}</li>
                  </ul>
                </div>
                <div>
                  <button className='btn btn-banner-o' type="button" onClick={() => setUpdateForest({user: userObject, monte: null, action: "create" })}>Añadir monte</button>
                </div>
              </div>

              {montes.montesData.length == 0 ? "Este usuario todavía no ha subido ningún monte" : 
                <div className="table-container rounded-lg" style={{ height: '800px', overflowY: 'scroll' }}>
                  <table className="table-hover bg-white text-xs table-auto w-full ">
                      <thead className="sticky top-0 sec-green-background z-20 text-white">
                          <tr>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                              <button type="button" onClick={() => requestSort('name')}
                                className={`${getClassNamesFor('name')} text-underline`}>
                                Nombre
                            </button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" > 
                              <button type="button" onClick={() => requestSort('state')}
                                  className={`${getClassNamesFor('state')} text-underline`}>
                                  Estado
                              </button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                            <button type="button" onClick={() => requestSort('activationDate')}
                                  className={`${getClassNamesFor('activationDate')} text-underline`}>
                                  Fecha Activación
                              </button>
                              </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                            <button type="button" onClick={() => requestSort('province')}
                                  className={`${getClassNamesFor('province')} text-underline`}>
                                  Localización
                              </button>
                              </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                            <button type="button" onClick={() => requestSort('size')}
                                  className={`${getClassNamesFor('size')} text-underline`}>
                                  Tamaño (ha)
                              </button>
                              </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                            <button type="button" onClick={() => requestSort('cadastre')}
                                  className={`${getClassNamesFor('cadastre')} text-underline`}>
                                  Ref. Catastral
                              </button>
                              </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                            <button type="button" onClick={() => requestSort('forestType')}
                                  className={`${getClassNamesFor('forestType')} text-underline`}>
                                  Especie pred.
                              </button>
                              </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                              <button type="button" onClick={() => requestSort('anualCO2')}
                                  className={`${getClassNamesFor('anualCO2')} text-underline`}>
                                  CO₂ (t)
                              </button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >Acciones</th>
                          </tr>
                      </thead>
                      <ForestTable items={items} action={action} userObject={userObject} />

                  </table>
                </div>
              }
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
