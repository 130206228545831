import axios from "axios";
import format from 'date-fns/format';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Input } from "reactstrap";
import { IdiomContext } from "../../../context/createcontext";
import { getFont } from '../../../services/FunctionsDataWhiteLabel';
import UserService from "../../../services/UserService";
import Banner from '../../../components/utils/Banner';
import Header from '../Header';
import Sidebar from '../Sidebar';
import moment from 'moment';


export default function AnswersValidate() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const pathParams = useParams();
  const idExternalQuestionnaire = pathParams.id;
  const [checkedAnswerList, setCheckedAnswerList] = useState([]);
  const [allAnswerIds, setAllAnswerIds] = useState([]);
  const [allAnswerChecked, setAllAnswerChecked] = useState(false);
  const [externalAnswersFilter, setExternalAnswersFilter] = useState();
  const [validateAnswerModal, setValidateAnswerModal] = useState({ view: false, button: true, spinner: false });
  const [msg, setMsg] = useState({
    type: '',
    message: '',
    open: false,
  });

  const [externalAnswers, setExternalAnswers] = useState({
    data: [],
    isFetching: false
  });

  const [validatedAnswers, setValidatedAnswers] = useState({
    data: [],
    isFetching: false
  });

  const [transferedAnswers, setTransferedAnswers] = useState({
    data: [],
    isFetching: false
  });


  useEffect(() => {
    const fetchExternalAnswers = async () => {
      setExternalAnswers({ data: externalAnswers.data, isFetching: true });
      let responseData = [];
      try {
        const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternalQuestionnaire}/answers/all`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        responseData = response.data;
        setExternalAnswers({ data: response.data, isFetching: false });
        setExternalAnswersFilter(response.data.data);
        setAllAnswerIds(response.data.data.map((v) => { if (!v.inAnswers) { return Number(v.id) } }).filter(id => id !== undefined))
      } catch (exception) {
        setExternalAnswers({ data: responseData, isFetching: false });
      }
    };
    fetchExternalAnswers();
  }, [])

  const updateValidationList = (event) => {
    let checkboxId = Number(event.target.id);
    let isChecked = event.target.checked;
    let updatedIdsArray;
    if (isChecked) {
      updatedIdsArray = [...checkedAnswerList, checkboxId];
      setCheckedAnswerList(updatedIdsArray);
    } else {
      updatedIdsArray = checkedAnswerList.filter(id => id !== checkboxId);
      setCheckedAnswerList(updatedIdsArray);
    }

    if (updatedIdsArray.length == allAnswerIds.length) {
      allAnswerChecked ? "" : setAllAnswerChecked(true);
    } else if (updatedIdsArray.length < allAnswerIds.length) {
      allAnswerChecked ? setAllAnswerChecked(false) : "";
    }
  };

  const updateAllAnswers = (event) => {
    let isChecked = event.target.checked;

    if (isChecked) {
      setCheckedAnswerList(allAnswerIds);
      setAllAnswerChecked(true);
    } else {
      setCheckedAnswerList([]);
      setAllAnswerChecked(false);
    }
  };

  const handleValidateAnswers = () => {
    setValidateAnswerModal({ view: true, button: true, spinner: true });

    const sendValidatedAnswersToCalculationAnswers = async () => {
      setTransferedAnswers({ data: transferedAnswers.data, isFetching: true });
      try {
        const response = await axios.post(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternalQuestionnaire}/transfer`, {}, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setTransferedAnswers({ data: response.data, isFetching: false });
        setMsg({ type: "success", message: t("fpThird.validarRespuestaOK") });
      } catch (exception) {
        setTransferedAnswers({ data: [], isFetching: false });
        console.error('Error:', error.message);
        setMsg({ type: "error", message: t("fpThird.validarRespuestaKO") });
      }
      setValidateAnswerModal({ view: true, button: true, spinner: false });
    };

    const validateAnswers = async () => {
      setValidatedAnswers({ data: validatedAnswers.data, isFetching: true });
      try {
        const response = await axios.put(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/${idExternalQuestionnaire}/answers/validate`, checkedAnswerList, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setValidatedAnswers({ data: response.data, isFetching: false });
        sendValidatedAnswersToCalculationAnswers();
      } catch (exception) {
        setValidatedAnswers({ data: [], isFetching: false });
      }
    };

    if (checkedAnswerList.length > 0) {
      validateAnswers();
    }
  }


  useEffect(() => {
    if (transferedAnswers.data?.data?.length > 0) {
      let updatedExternalAnswers = externalAnswers.data?.data?.map(item => {
        if (checkedAnswerList.includes(item.id)) {
          return { ...item, inAnswers: true };
        } else {
          return { ...item };
        }
      });

      setExternalAnswers({ data: { data: updatedExternalAnswers }, isFetching: false });
      setExternalAnswersFilter(updatedExternalAnswers);
      setAllAnswerIds(updatedExternalAnswers.map((v) => { if (!v.inAnswers) { return Number(v.id) } }).filter(id => id !== undefined))
    }
  }, [transferedAnswers.data])



  const [filtros, setFiltros] = useState({
    email: "",
    fe: "",
  });

  const OnFilterEmail = (filter, filteredData) => {
    return filteredData.filter((v) =>
      v.contactEmail.toLowerCase().includes(filter.toLowerCase())
    );

  };

  const OnFilterFe = (filter, filteredData) => {
    return filteredData.filter((v) =>
      v.factorName.toLowerCase().includes(filter.toLowerCase())
    );
  };


  useEffect(() => {
    const handleInputChange = () => {

      let filteredData = externalAnswers.data?.data;

      if (filtros.email) {
        filteredData = OnFilterEmail(filtros.email, filteredData);
      }

      if (filtros.fe) {
        filteredData = OnFilterFe(filtros.fe, filteredData);
      }
      setExternalAnswersFilter(filteredData);
    };

    handleInputChange();
  }, [filtros]);

  const handleClose = () => {
    setMsg({
      type: '',
      message: '',
      open: false
    });
    setValidateAnswerModal({ view: false, button: true, spinner: false });
    setCheckedAnswerList([]);
  }

  const useSortableData = (items, config = null) => {

    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      if (!items || items.length === 0) {
        return [];
      }
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];

          if (sortConfig.key.includes(".")) {
            let params = sortConfig.key.split(".");
            valueA = a[params[0]]?.[params[1]];
            valueB = b[params[0]]?.[params[1]];
          }

          if (valueA === null || valueA === undefined || valueA === "") {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (valueB === null || valueB === undefined || valueB === "") {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }

          if (!isNaN(valueA) || moment(valueA).isValid()) {
            if (valueA < valueB) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (valueA > valueB) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (
              valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (
              valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }

          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(externalAnswersFilter);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <div className={`sec-neutro-background ${getFont(UserService.getwhiteLabel())} flex h-screen overflow-hidden`}>

      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <button arial-label="btn-saveFom" className="ml-4 mt-2 text-base text-gray-800 font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
          </svg>
          <span>{t("footPrint.volver")}</span>
        </button>


        <Modal show={validateAnswerModal.view}>
          <Modal.Header className="sec-green-background">
            <Modal.Title className="text-white">{t("fpThird.validarRespuestas")}</Modal.Title>
            <div className="flex justify-end">
              <button onClick={handleClose}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">
              {msg.type !== "" && <Banner type={msg.type} open setOpen={true}>{msg.message}</Banner>}
            </div>
            <div>
              <h6 className="h6">{t("fpThird.modalValidar1")}</h6>
              <p className="mt-2">{t("fpThird.modalValidar2")}</p>
            </div>

            <div className='mt-4 flex justify-center'>
              <Button className="btn-cancel mr-4 rounded-lg px-4 py-2" type="button" onClick={handleClose}>{t("footPrint.cerrarCerrar")}</Button>
              <Button className="btn-green mr-4 rounded-lg px-4 py-2" type="button" onClick={handleValidateAnswers} disabled={validateAnswerModal.button}>{validateAnswerModal.spinner ? <Spinner animation="border" variant="light" /> : t("fpThird.validar")}</Button>
            </div>

          </Modal.Body>
        </Modal>

        <main>

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-4 md:mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("fpThird.titleRespuestas")}</h1>
            </div>

            <div>

              <div className="flex flex-col gap-2 mb-3 text-xs sm:flex-row">
                <Input
                  value={filtros.email}
                  className=" form-input w-full rounded-lg  bg-white border-gray-200 hover:border-gray-300 "
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      email: e.target.value,
                    }))
                  }
                  placeholder={t("fpThird.buscaEmail")}
                  type="text"
                />
                <Input
                  value={filtros.fe}
                  className="form-input w-full rounded-lg  bg-white border-gray-200 hover:border-gray-300 "
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      fe: e.target.value,
                    }))
                  }
                  placeholder={t("fpThird.buscaFactor")}
                  type="text"
                />
                <div className='flex justify-center w-full md:w-1/2 '>
                  <button className='btn-banner-o rounded-lg px-4 py-2 text-base' disabled={checkedAnswerList.length > 0 ? false : true} onClick={() => setValidateAnswerModal({ view: true, button: false, spinner: false })}>{t("fpThird.validarRespuestas")}</button>
                </div>
              </div>

              <div className="overflow-x-auto w-full mt-4">
                {externalAnswers.isFetching ?
                  <div className='flex justify-center items-cnter space-x-2 font-semibold text-gray-800'>
                    <span>{t("fpThird.cargandoRespuestas")}</span>
                    <Spinner animation="border" variant="dark" />
                  </div>
                  :
                  (
                    <table className="table-striped table-bordered table-auto w-full bg-white ">
                      <thead className='text-center  text-xl sec-green-background text-white'>
                        <tr className='py-4'>
                          <th>
                            <input className='mx-2 border-2' type="checkbox" id="selectAllAnswers" onChange={updateAllAnswers} checked={allAnswerChecked} style={{ borderColor: 'rgb(255 255 255)' }} />
                          </th>
                          <th>
                            <button type="button" onClick={() => requestSort('creationDate')} className={`${getClassNamesFor('creationDate')} text-underline`}>{t("fpThird.fecha")}</button>
                          </th>
                          <th>
                            <button type="button" onClick={() => requestSort('organizationBuildingName')} className={`${getClassNamesFor('organizationBuildingName')} text-underline`}>{t("footPrint.sede")}</button>
                          </th>
                          <th>
                            <button type="button" onClick={() => requestSort('contactEmail')} className={`${getClassNamesFor('contactEmail')} text-underline`}>{t("fpThird.contact")}</button>
                          </th>
                          <th>
                            <button type="button" onClick={() => requestSort('questionSubtitle')} className={`${getClassNamesFor('questionSubtitle')} text-underline`}>{t("fpThird.question")}</button>
                          </th>
                          <th>
                            <button type="button" onClick={() => requestSort('questionType')} className={`${getClassNamesFor('questionType')} text-underline`}>{t("fpThird.preg")}</button>
                          </th>
                          <th>
                            <button type="button" onClick={() => requestSort('factorName')} className={`${getClassNamesFor('factorName')} text-underline`}>{t("footPrint.factor")}</button>
                          </th>
                          <th>
                            <button type="button" onClick={() => requestSort('quantity')} className={`${getClassNamesFor('quantity')} text-underline`}>{t("footPrint.cantidad")}</button>
                          </th>
                          <th>
                            <button type="button" onClick={() => requestSort('inAnswers')} className={`${getClassNamesFor('inAnswers')} text-underline`}>{t("fpThird.validada")}</button>
                          </th>
                          <th>
                            <button type="button" onClick={() => requestSort('finishedByContact')} className={`${getClassNamesFor('finishedByContact')} text-underline`}>{t("fpThird.externoFinalizado")}</button>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='text-center text-md'>
                        {externalAnswersFilter && externalAnswersFilter.length > 0 ? (
                          items.map((x) => (
                            <tr key={x.id}>
                              <td>{x.inAnswers || x.questionType === "EMPLOYEES_PERSONAL_MOBILITY" ? "" : <input className='mx-2' type="checkbox" checked={checkedAnswerList.includes(x.id)} id={x.id} onChange={updateValidationList} />}</td>
                              <td>{x.creationDate ? format(new Date(x.creationDate), 'dd/MM/yyyy') : ''}</td>
                              <td>{x.organizationBuildingName}</td>
                              <td>{x.contactEmail}</td>
                              <td>{x.questionSubtitle}</td>
                              <td>{t("footPrint." + x.questionType)}</td>
                              <td>{x.factorName}</td>
                              <td>{x.quantity + " " + x.quantityUnit + (x.quantity2 != undefined ? (", " + x.quantity2 + " " + x.quantity2Unit) : "")}</td>
                              <td>{x.inAnswers ? "Si" : (x.questionType === "EMPLOYEES_PERSONAL_MOBILITY" ? <span className="text-xs">Este registro no afecta tu huella de carbono, porque que no genera emisiones.</span> : "No")}</td>
                              <td>{x.finishedByContact ? "Si" : "No"}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className='' colSpan="10">{externalAnswers.data?.message?.includes("no puede acceder a la información solicitada") ? t("fpThird.formularioNoExiste") : t("fpThird.noRespuestas")}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )
                }
              </div>

            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
