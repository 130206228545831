import React from 'react';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, WMSTileLayer, Polygon, useMapEvents, LayerGroup, LayersControl, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";

function mapa_catastro({dataParcela,sendDataToParent,booleanClick}){

  const customMarker = new L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40]
  });

  //Guarda las coordenadas de la parcela recibidas con de la referencia catastral
  const [positionc, setPositionc] = useState();
  //Guarda la info de la parcela seleccionada con click en el mapa
  const [infoParcela, setInfoParcela] = useState(null);
  //Coordenadas MaxBounds
  const outer = [
    [26.43122806450644, -23.728142674836377],
    [49.55372551347579, 14.108771387663646]
  ]
  const limeOptions = { color: 'lime' }


  useEffect(() => {
    if(dataParcela != undefined){
      let index = dataParcela.parcelCoords.indexOf(",");
      let lng = dataParcela.parcelCoords.substr(0, index);
      let lat = dataParcela.parcelCoords.substr(index + 1);

      if (dataParcela != infoParcela){
        setPositionc({
          lat: parseFloat(lat) ,
          lng: parseFloat(lng),
        });
      }
    }
  }, [dataParcela]);

  function getInfo(position,bbox){
      fetch(`${process.env.REACT_APP_CATASTRO_BBOX_COORDS}`+bbox+"/Coordenada_X="+position.lng+"&Coordenada_Y="+position.lat, {
      method: "GET",
      headers: {
        "Accept": "*",
        "Content-Type": "application/json",
        "Allow": "*",
        "Access-Control-Allow-Origin": "*",
        // Authorization: `Bearer ${UserService.getToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((infoParcela) => {
        if (!infoParcela.error) {
          setInfoParcela(infoParcela);
          sendDataToParent(infoParcela);
        }
      });
  }

  if( infoParcela != null){
    var arraycoords=(infoParcela.polygonCoordinates).replaceAll(" ", ",");
    var string = arraycoords.split(',');
    // Create array of float for each pair of coordinate
    var a = string.length; 
    for (let i = 0; i < a; i++) {
        string[i] = parseFloat(string[i]);
    }
    // Initialize an array to store the new values
    var b = string.length / 2;
    var array = [];
    for (let x = 0; x < b; x++) {
            array[x] = [0, 0];
    }
    // Create an array of array of coordinates
    var k = 0;
    for (let i = 0; i < b; i++) {
        for (let j = 0; j < 2; j++) {
            array[i][j] = string[k];
            k++;
        }
    }
  } 
  else if( dataParcela != null){
    var arraycoords=(dataParcela.polygonCoordinates).replaceAll(" ", ",");
    var string = arraycoords.split(',');
    // Create array of float for each pair of coordinate
    var a = string.length; 
    for (let i = 0; i < a; i++) {
        string[i] = parseFloat(string[i]);
    }
    // Initialize an array to store the new values
    var b = string.length / 2;
    var array = [];
    for (let x = 0; x < b; x++) {
            array[x] = [0, 0];
    }
    // Create an array of array of coordinates
    var k = 0;
    for (let i = 0; i < b; i++) {
        for (let j = 0; j < 2; j++) {
            array[i][j] = string[k];
            k++;
        }
    }
  }

  //Componente: e onClick recibe las coordenadas y llama al endpoint que devuelve info del poligono
  const MapEvents = () => {
    useMapEvents({
      click(e) {
        var position={
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        };
        let x1 = position.lat - 0.00025;
        let y1 = position.lng - 0.00025;
        let x2 = position.lat + 0.00025;
        let y2 = position.lng + 0.00025;
        var bbox= x1+","+y1+","+x2+","+y2;
        getInfo(position,bbox);
      },
    });
    return false;
}

//Componente que hace el zoom a las coordenadas del poligono de la parcela y lo pinta
function Test({ location }) {
  const map = useMap();
  if (location) map.flyTo(location, 16); 
    if(infoParcela !=null || dataParcela !=null ){
    return(<Polygon pathOptions={limeOptions} positions={array} />)
    }else{
      return null;
    }
}

  return(
    <>
    <MapContainer
        center={{ lat: 40.4035, lng: -3.7283 }}
        zoom={6}
        zoomControl={true}
        minZoom="5"
        maxBounds={outer}
        maxZoom="20"
        className="z-0 mb-4 map">

      <LayersControl position="topright">
        <LayersControl.BaseLayer name="Callejero" >
          <LayerGroup>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom="20"
            />

            <WMSTileLayer
              url="https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx"
              format='image/png'
              transparent="false"
              minZoom="14"
              maxZoom="20"
              opacity="0.4"
            />
          </LayerGroup>
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satélite" checked>
          <LayerGroup>
            <TileLayer
              attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
              url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
              maxZoom="20"
            />

            <WMSTileLayer
              url="https://www1.sedecatastro.gob.es/Cartografia/GeneraMapa.aspx"
              format='image/png'
              transparent="false"
              minZoom="14"
              maxZoom="20"
              opacity="0.4"
            />
          </LayerGroup>
        </LayersControl.BaseLayer>
      </LayersControl>

      {booleanClick ?
      <MapEvents /> : <></>
      }
      <Test location={positionc}/>

    </MapContainer>
    </>
  )
} 
export default mapa_catastro;