import React, { useState, useEffect, useContext } from 'react';
import DocumentListTableItem from './DocumentListTableItem';
import { IdiomContext } from '../../../context/createcontext';
import { useTranslation } from 'react-i18next';


function DocumentListTable({
  documents, handleUpdateDocuments, enableDelete, idCalculation, idCuestionAnswer, getFileUrl
}) {





  // cambiar el idioma

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full divide-y divide-gray-200">
        {/* Table header */}
        <thead className="text-xs  text-gray-500  border-t border-gray-200">
          <tr className=''>
            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-wrap">
              <div className="font-semibold">{t("forestList.fecha")}</div>
            </th>
            <th className="px-2 ml-4 first:pl-5 last:pr-5 py-3 whitespace-wrap">
              <div className="font-semibold ">{t("forestList.NombreDoc")}</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-wrap">
              <div className="font-semibold text-center">{t("marketplace.forestListMP_actions")}</div>
            </th>

          </tr>
        </thead>
        {/* Table body */}

        <DocumentListTableItem
          documents={documents}
          enableDelete={enableDelete}
          idCalculation={idCalculation}
          handleUpdateDocuments={handleUpdateDocuments}
          idCuestionAnswer={idCuestionAnswer}
          getFileUrl={getFileUrl}
        />
      </table>


    </div>

  );
}

export default DocumentListTable;
