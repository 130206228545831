import { useState } from "react";
import Footer from "../../../partials/Footer";
import Header from "../../../partials/Header";
import BannerDemo from "../componentsHome/BannerDemo";
import Term from "./Term";

export default function TermPage() {

    const[name, setName] =useState("");

    const handleUpdateName = (name)=>{
        setName(name)
    }
    return (<>
        <Header name={name}></Header>
        <Term handleUpdateName={handleUpdateName}></Term>
        <BannerDemo></BannerDemo>
        <Footer></Footer>
    </>
    )
}