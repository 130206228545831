import React, { useState, useEffect, useContext } from 'react';
import ForestListTableItem from './ForestListTableItem';
import { IdiomContext } from '../../../context/createcontext';
import { useTranslation } from 'react-i18next';


function ForestListTable({
  selectedItems, montes, currentpage, idsExternosProductsMP,setEditMontes
}) {

  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(montes);
  }, [currentpage]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map(li => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };


  useEffect(() => {
    selectedItems(isCheck);
  }, [isCheck]);
 
  const editData = (data) => {
   
    setEditMontes(() => {
      const updatedMontes = [...montes].map((monte) =>
        monte.id === data.id ? data : monte
      );
     
      return updatedMontes;
    });

  };


  // cambiar el idioma

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");


  return (
    <div className="sec-neutro-background relative">
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full divide-y divide-gray-200">
            {/* Table header */}
            <thead className="text-xs  text-gray-500  border-t border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.nombre")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.catastro")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.especie")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.hectareas")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.estado")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.co2Total")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.co2Disponible")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("formMonte.provincia")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.fechaCrea")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">{t("forestList.fechaValida")}</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            {
              montes.map(monte => {
                let activoMP = false;
                idsExternosProductsMP.map((v) => {
                  if (monte.id == v) {
                    activoMP = true;
                  }
                })

                return (
                  <ForestListTableItem
                    key={monte.id}
                    props={monte}
                    updateTable={editData}

                  />
                )
              })
            }
          </table>


        </div>
      </div>
    </div>
  );
}

export default ForestListTable;