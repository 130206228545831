import axios from "axios";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { IdiomContext } from "../../../context/createcontext";
import pagoKo from '../../../images/pagoKo.svg';
import pago from '../../../images/pagoOk.svg';
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { formatDateTime, formatThousands } from '../utils/Utils'
import Spinner from 'react-bootstrap/Spinner';

export default function PaymentResponse() {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const url = window.location.href;
  const word = process.env.REACT_APP_STRIPE_PREFIX;

  const navigate = useNavigate();
  const handleButtonStartNow = () => {
    navigate('/footprint/calculationform', { state: {tour : true, idUser : null} });
};

  let parametro = ''
  const paramIndex = url.indexOf("?");
  if (paramIndex !== -1) {
    const param = url.substring(paramIndex + 1);
    parametro = param.split('=')[1];
  }

  const payOK = url.includes(word) ? true : false
  const [pay, setPay] = useState({
    payData: [],
    isFetching: false,
    error: false,
  });

  useEffect(() => {

    const fetchPay = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CH_SUBSCRIPTION}?checkoutSessionId=${parametro}`,
          {
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${UserService.getToken()}`,
            },
          });
        setPay({ payData: response.data.data, isFetching: true });

      } catch (exception) {
        console.error(exception)
        setPay({ payData: pay.payData, isFetching: true, error: true });
      }
    };
    if (payOK) { fetchPay(); }

  }, [payOK])


  const [urlStripeCustomer, setUrlStripeCustomer] = useState();

  useEffect(() => {
    const fetchPlans = async () => {
      const url = `${process.env.REACT_APP_CH_STRIPE_CUSTOMER_PORTAL}`;
      try {
        const response = await axios.get(`${url}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setUrlStripeCustomer(response.data.data);
      } catch (exception) {
        console.error(exception)
      }
    };
    fetchPlans();
  }, [])



  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              <h1 className="text-2xl md:text-3xl  font-bold">{t("footPrint.resultadoPago")}</h1>
            </div>

            {/* Content */}
            <div className="">
              {payOK &&
                <>
                  {pay.isFetching && pay.payData != undefined &&
                    <>
                      <div className="text-center">
                        <h1 className="text-xl md:text-2xl  font-bold mb-6">{t("footPrint.pagoOk")}✨</h1>
                        <div className='container' >

                          <img alt='pago ok' className="hidden md:flex" width={'25%'} src={pago} />


                          <table className="table-auto table-bordered table-striped  text-md  w-full divide-y divide-x divide-gray-200">

                            <tr>
                              <th className="p-2">{t("footPrint.susbscripcion")}</th>
                              <td className="px-5"> {pay.payData.planName}</td>
                            </tr>
                            <tr>
                              <th className="p-2">{t("footPrint.precio")}</th>
                              <td className="px-5"> {formatThousands(pay.payData.price)}€</td>
                            </tr>
                            <tr>
                              <th className="p-2">{t("footPrint.precioIva")}</th>
                              <td className="px-5">  {formatThousands(pay.payData.priceWTax)}€</td>
                            </tr>
                            <tr>
                              <th className="p-2">{t("footPrint.desde")}</th>
                              <td className="px-5">   {formatDateTime(pay.payData.creationDate)}</td>
                            </tr>
                            <tr>
                              <th className="p-2">{t("footPrint.hasta")}</th>
                              <td className="px-5"> {formatDateTime(pay.payData.expirationDate)}</td>
                            </tr>
                          </table>
                          <div className="text-sm">

                            {t("footPrint.infoSubscripcion1")}
                            <a target="_blank" href={urlStripeCustomer}>{t("footPrint.aqui")}</a>
                            {t("footPrint.infoSubscripcion2")}
                          </div>


                          <h2 className="text-lg md:text-xl  mt-4 italic">{t("footPrint.primerCalculo")}</h2>

                        </div>
                        <button className='btn btn-green rounded-lllg mt-4' onClick={handleButtonStartNow}>{t("footPrint.nuevoCalculo")}</button>

                      </div>
                    </>
                  }
                  {!pay.isFetching && <div className='flex justify-center'>
                    <Spinner className='mt-4' animation="border" variant="dark" />
                  </div>
                  }
                </>
              }


              {!payOK &&

                <div className='  container flex flex-row text-center justify-center items-center'>

                  <div className=' pt-1 pb-4 flex flex-column items-center'>
                    <h1 className="text-xl md:text-2xl  font-bold mb-6">{t("footPrint.pagoKo")}</h1>

                    <img alt='notFound' src={pagoKo} width={'380'} />


                    <div className='px-2 flex text-center mt-1 text-xl gap-2 flex-wrap flex-col justify-center italic'>
                      <div>{t("footPrint.infoFail1")} </div>
                      <div>{t("footPrint.infoFail2")}
                        <Link to={`/footprint/plans`}>
                          <span style={{ color: 'var(--airco2-green-color)', fontWeight: 'bold' }}>{t("footPrint.aqui")}</span>
                        </Link>
                      </div>
                    </div>

                    <div className="text-sm mt-5">

                      {t("footPrint.infoSubscripcion1")}
                      <a target="_blank" href={urlStripeCustomer}>{t("footPrint.aqui")}</a> 
                      {t("footPrint.infoSubscripcion2")}
                    </div>
                  </div>

                </div>
              }





            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
