import React, { useState, useEffect,useContext } from 'react';
import EmissionsTableItem from './EmissionsTableItem';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';



function EmissionsTable({
  selectedItems,
   transactions,
   currentpage, 

}) {
  
  const emisions = transactions;
  const emisionswId = emisions.map((element) => {
    if (element.id != null ){
      return element;
    }
  })
  const emisionFinal = emisionswId.filter((element) =>{
    return element !== undefined;
  })

  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  
  useEffect(() => {
    setList(transactions);
  }, [currentpage]); 

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map(li => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
   
  }, [isCheck]);
  
 // cambiar el idioma

 const [lng, updateLng] = useContext(IdiomContext)
 const [t, i18n] = useTranslation("global");

  return (
    <div className="sec-neutro-background relative">
      {/* shadow-lg rounded-sm border border-gray-200  */}
      {/* <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">All Orders <span className="text-gray-400 font-medium">442</span></h2>
      </header> */}
      <div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full divide-y divide-gray-200">
            {/* Table header */}
            <thead className="text-xs  text-gray-500  border-t border-gray-200">
              <tr>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select all</span>
                      <input className="form-checkbox" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                    </label>
                  </div>
                </th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("sidebar.ecategoria")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("sidebar.esubcategoria")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("sidebar.tfecha")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-right">CO₂ (t)</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-right">CH₄ (t)</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-right">N₂O (t)</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-right">H₂O (L)</div>
                </th>              
              </tr>
            </thead>
            {/* Table body */}
            {
              emisionFinal.map(transaction => {

                return (
                  <EmissionsTableItem
                    key={transaction.id}
                    id={transaction.id}
                    idcategory={transaction.category.id}
                    category={transaction.category.name}
                    idsubcategory={transaction.subCategory.id}
                    subcategory={transaction.subCategory.name}
                    accountingDate={transaction.accountingDate}
                    co2={transaction.co2}
                    n2o={transaction.n2o}
                    h2o={transaction.h2o}
                    ch4={transaction.ch4}
                    handleClick={handleClick}
                    isChecked={isCheck.includes(transaction.id)}
                  />
                )
              })
            }
          </table>
         

        </div>
      </div>
    </div>
  );
}

export default EmissionsTable;
