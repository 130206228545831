import axios from 'axios';
import React, { useEffect } from 'react';
import { Spinner } from "react-bootstrap";

export default function Anonymous({ externalQuestionnaire, handleSetContactData, t }) {
    const cleanText = (text) => {
        return text
            .toLocaleLowerCase('es-ES')
            .replace(/[^a-z0-9-]/g, '-')
            .replace(/-+/g, '-')
            .trim();
    }

    const cookieName = `carbonmanager-${externalQuestionnaire?.idExternal}-${cleanText(externalQuestionnaire?.organization)}`;

    useEffect(() => {
        const getExistingToken = async () => {
            let savedToken = localStorage.getItem(cookieName);

            if (savedToken) {
                const url = `${process.env.REACT_APP_CH_CONTACTS}/external/contact/validate-token/external-questionnaire/${externalQuestionnaire?.idExternal}?token=${savedToken}`;
                axios.get(url, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then(respuesta => {
                        const nuevoToken = respuesta.data?.data;
                        if (nuevoToken) {
                            const updatedTokenData = { ...nuevoToken, validated: true };

                            localStorage.setItem(cookieName, updatedTokenData.token);
                            handleSetContactData(updatedTokenData);
                        } else {
                            console.error("Token not found in response");
                        }
                    })
                    .catch(error => {
                        console.error('Error al obtener el token:', error);
                        generateToken();

                    });
                return;
            }

            try {
                generateToken();
            } catch (error) {
                console.error('Error al obtener el token:', error);
            }
        };

        getExistingToken();
    }, []);


    const generateToken = () => {
        const url = `${process.env.REACT_APP_CH_CONTACTS}/external/contact/generate-token/external-questionnaire/${externalQuestionnaire?.idExternal}`;

        axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(respuesta => {
                const nuevoToken = respuesta.data?.data;
                if (nuevoToken) {
                    const updatedTokenData = { ...nuevoToken, validated: true };

                    localStorage.setItem(cookieName, updatedTokenData.token);
                    handleSetContactData(updatedTokenData);
                } else {
                    console.error("Token not found in response");
                }
            })
            .catch(error => {
                console.error('Error al obtener el token:', error);
            });
    }

    return (<>
        <div className="text-center">
            <Spinner animation="border" variant="dark" ></Spinner>
            <div> {t("fpThird.getUserInfo")}</div>
        </div>
    </>)

}