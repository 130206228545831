import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";

function CardServices({ servicios }) {
  const [show, setShow] = useState(false);
  const [serviceId, setServiceId] = useState('')
  const [t, i18n] = useTranslation("global");
  const handleClose = () => setShow(false);
  const handleShow = (v) => {
    setShow(true);
    setServiceId(v)

  }


  const service = servicios.find((v) => v.id === serviceId)
 

  return (
    <>
      {servicios.map((v, i) => {
        return (

          <Col xs={12} md={6} lg={4} key={i} className="bg-white shadow-lg rounded-sm border border-gray-200 overflow-hidden" style={{ maxWidth: '380px', minWidth: '340px' }}>
            <div className="flex flex-col h-full">

              <div className='w-full mt-3' style={{ backgroundImage: `url(${v.imagen})`, height: '250px', backgroundPosition: "center", backgroundSize: "cover" }} ></div>
              {/* Card Content */}
              <div className="grow flex flex-col p-2">
                {/* Card body */}
                <div className="grow">
                  {/* Header */}
                  <header className="">
                    <h3 className="text-lg text-gray-800 font-semibold">{v.servicio}</h3>
                  </header>
                  {/* Rating and price */}
                  <div className="flex flex-wrap justify-end items-center">


                    {/* Price */}
                    <div>
                      <div className="inline-flex text-sm font-medium bg-green-100 text-green-600 rounded-full text-center px-2 py-0.5">{v.precio},00€</div>
                    </div>
                  </div>
                  {/* Features list */}
                  <ul className="text-sm space-y-2 mb-3">
                    <li className="flex items-center">
                      <svg className="w-4 h-4 fill-current text-gray-400 shrink-0 mr-3" viewBox="0 0 16 16">
                        <path d="M15.686 5.695L10.291.3c-.4-.4-.999-.4-1.399 0s-.4.999 0 1.399l.6.599-6.794 3.697-1-1c-.4-.399-.999-.399-1.398 0-.4.4-.4 1 0 1.4l1.498 1.498 2.398 2.398L.6 13.988 2 15.387l3.696-3.697 3.997 3.996c.5.5 1.199.2 1.398 0 .4-.4.4-.999 0-1.398l-.999-1 3.697-6.694.6.6c.599.6 1.199.2 1.398 0 .3-.4.3-1.1-.1-1.499zM8.493 11.79L4.196 7.494l6.695-3.697 1.298 1.299-3.696 6.694z" />
                      </svg>
                      <div>{v.ubicacion}</div>
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 fill-current text-gray-400 shrink-0 mr-3" viewBox="0 0 16 16">
                        <path d="M15 15V5l-5-5H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h12c.6 0 1-.4 1-1zM3 2h6v4h4v8H3V2z" />
                      </svg>
                      <div>{v.categoria}</div>
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 fill-current text-gray-400 shrink-0 mr-3" viewBox="0 0 16 16">
                        <path d="M13 7h2v6a1 1 0 01-1 1H4v2l-4-3 4-3v2h9V7zM3 9H1V3a1 1 0 011-1h10V0l4 3-4 3V4H3v5z" />
                      </svg>
                      <div>{v.profesional}</div>
                    </li>
                    <li className="flex items-center">
                      <svg className="w-4 h-4 fill-current text-gray-400 shrink-0 mr-3" viewBox="0 0 16 16">
                        <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zm0 6c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zm-7-5c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                      </svg>
                      <div>{v.contacto}</div>
                    </li>
                  </ul>
                </div>
                {/* Card footer */}
                <div>
                <button arial-label="btn-info" className={`btn btn-intranet ${show && v.id ===serviceId ?"bg-gray-400":"bg-indigo-500"}  text-white w-full whitespace-nowrap`}  onClick={() => handleShow(v.id)} >{t("forestryService.masInfo")}</button>

                </div>
              </div>
            </div>
          </Col>
        )
      })}

      {/* menu detalle */}
      {show &&
        < div className="relative z-50 text-sm">
          <div className=" fixed inset-0 bg-gray-800 opacity-25">
          </div>
          <div className='flex justify-center'>
            <div className=" fixed top-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto" style={{ minWidth: '40vw', right: '1rem' }}>
              <div className="flex items-center mb-8">
                <button className="" data-id="close-menu-button"
                  onClick={(e) => handleClose(e)}>
                  <svg className="h-6 w-6  cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" pointerEvents="none">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div>
                <p className='font-bold'>{service.servicio}</p>
                <div className='w-full mt-3' style={{ backgroundImage: `url(${service.imagen})`, height: '300px', backgroundPosition: "center", backgroundSize: "cover" }} ></div>
                <p className='mt-3'>{service.desServicio}</p>
                <ul className='flex justify-between mt-3'>

                  <li className='flex items-center'>
                    <svg className="w-4 h-4 fill-current text-gray-400 shrink-0 mr-1 mb-1 " viewBox="0 0 16 16">
                      <path d="M15.686 5.695L10.291.3c-.4-.4-.999-.4-1.399 0s-.4.999 0 1.399l.6.599-6.794 3.697-1-1c-.4-.399-.999-.399-1.398 0-.4.4-.4 1 0 1.4l1.498 1.498 2.398 2.398L.6 13.988 2 15.387l3.696-3.697 3.997 3.996c.5.5 1.199.2 1.398 0 .4-.4.4-.999 0-1.398l-.999-1 3.697-6.694.6.6c.599.6 1.199.2 1.398 0 .3-.4.3-1.1-.1-1.499zM8.493 11.79L4.196 7.494l6.695-3.697 1.298 1.299-3.696 6.694z" />
                    </svg>
                    {service.ubicacion}
                  </li>
                  <li className='flex items-center'>
                    <svg className="w-4 h-4 fill-current text-gray-400 shrink-0 mr-1 mb-1 " viewBox="0 0 16 16">
                      <path d="M15 15V5l-5-5H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h12c.6 0 1-.4 1-1zM3 2h6v4h4v8H3V2z" />
                    </svg>
                    {service.categoria}
                  </li>

                  <li className='flex items-center'>

                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-coin-euro" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#94A3B8" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                      <path d="M14.401 8c-.669 -.628 -1.5 -1 -2.401 -1c-2.21 0 -4 2.239 -4 5s1.79 5 4 5c.9 0 1.731 -.372 2.4 -1" />
                      <path d="M7 10.5h4" />
                      <path d="M7 13.5h4" />
                    </svg>
                    {service.precio},00€</li>

                </ul>
                <p className='mt-3 font-bold'>{service.profesional}</p>
                <p className='mt-3'>{service.desProfesional}</p>
                <ul className='mt-3'>
                  <li className='flex items-center'><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-2-share mr-1 mb-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#94A3B8" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 9h8" />
                    <path d="M8 13h6" />
                    <path d="M12 21l-3 -3h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6" />
                    <path d="M16 22l5 -5" />
                    <path d="M21 21.5v-4.5h-4.5" />
                  </svg>
                    {service.contacto}
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div >
      }


    </>


  );
}

export default CardServices;