import React from 'react'
import { useState, useEffect,useContext } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useParams } from 'react-router';
import axios from 'axios';
import UserService from '../../../services/UserService';
import { useLocation } from 'react-router-dom';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from 'draft-js-import-html';
import Banner from '../../../components/utils/Banner'
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from 'react-i18next';



export default function ContFormAdmin() {
  const pathParams = useParams();
  const idProduct = pathParams.id
  const location = useLocation();
  const producto = location.state;
 
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const [formInfoMessage, setFormInfoMessage] = useState({
    type: '',
    message: ''
  })

  const editorCount = 6;
  const titulos = ['Contexto', 'Caracterización de la zona', 'Gestión', 'Cálculo', 'Monitoreo', 'Co-beneficios']
  const [editors, setEditors] = useState([
    producto.context
      ? EditorState.createWithContent(stateFromHTML(producto.context))
      : EditorState.createEmpty(),
    producto.zone
      ? EditorState.createWithContent(stateFromHTML(producto.zone))
      : EditorState.createEmpty(),
    producto.management
      ? EditorState.createWithContent(stateFromHTML(producto.management))
      : EditorState.createEmpty(),
    producto.calculation
      ? EditorState.createWithContent(stateFromHTML(producto.calculation))
      : EditorState.createEmpty(),
    producto.monitoring
      ? EditorState.createWithContent(stateFromHTML(producto.monitoring))
      : EditorState.createEmpty(),
    producto.benefits
      ? EditorState.createWithContent(stateFromHTML(producto.benefits))
      : EditorState.createEmpty(),

  ]);





  const fieldNames = ["context", "zone", "management", "calculation", "monitoring", "benefits"]

  const [texts, setTexts] = useState({
    context: "",
    zone: "",
    benefits: "",
    calculation: "",
    management: "",
    monitoring: "",
  });

  const handleEditorStateChange = (index, editorState) => {
    const newEditors = [...editors];
    newEditors[index] = editorState;
    setEditors(newEditors);

    const newTexsts = editors.map((editorState, index) => {
      const contentState = editorState.getCurrentContent();
      const rawContent = convertToRaw(contentState);
      return draftToHtml(rawContent);
    });

    const newTextsObj = fieldNames.reduce((acc, fieldName, i) => {
      acc[fieldName] = newTexsts[i] === "<p></p>\n" ? "" : newTexsts[i];
      return acc;
    }, {});

    setTexts(newTextsObj);

  };




  const handlesubmit = e => {
    e.preventDefault();

    let sendFormExtra = {
      "context": texts.context === '<p></p>\n' ? "" : texts.context,
      "zone": texts.zone === '<p></p>\n' ? "" : texts.zone,
      "benefits": texts.benefits === '<p></p>\n' ? "" : texts.benefits,
      "calculation": texts.calculation === '<p></p>\n' ? "" : texts.calculation,
      "management": texts.management === '<p></p>\n' ? "" : texts.management,
      "monitoring": texts.monitoring === '<p></p>\n' ? "" : texts.monitoring
    };
    return axios.post(`${process.env.REACT_APP_MP_PRIVATE_PRODUCT}/${idProduct}/info-extra`,
      sendFormExtra, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${UserService.getToken()}`
      }
    })
      .then((response) => {
        if (response.status === 200) {
         
          setFormInfoMessage({ ...formInfoMessage, type: "success", message: t("account.actualizacion-ok") });

        }
        else {
          setFormInfoMessage({ ...formInfoMessage, type: "error", message: t("account.actualizacion-ko") })

        }


      },

      )


  };

  const renderEditors = () => {
    return editors.map((editorState, index) => (
      <div key={index} className="pt-2 pb-2">
        <p className="text-md font-semibold p-1">{titulos[index]}</p>
        <div className="bg-white border">
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={editorState}
            onEditorStateChange={(newEditorState) => handleEditorStateChange(index, newEditorState)}
          />
        </div>


      </div>
    ));
  };






  return <div className="container">

{formInfoMessage.type !== "" && 
<div className=''>
<Banner className='my-5' type={formInfoMessage.type} open setOpen={true}>{formInfoMessage.message}</Banner>
  <div className='flex justify-center space-x-4'>
   <button className='btn sec-green-background text-white'> <a href="/marketplaceAdmin">Volver</a></button>
   <button className='btn sec-green-background text-white'><a href={`${process.env.REACT_APP_DATAILPRODUCT_MP}/${producto.id}`} target="_blank">Ver en Marketplace</a></button>
  </div>
 </div>}
{formInfoMessage.type === "" &&
 <>
 <div className="mb-3">
   <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Formulario Información complementaria de {producto?.name}</h1>
 </div>
 {renderEditors()}
 <div className=''>
   <button className='btn sec-green-background text-white' onClick={handlesubmit}>Guardar</button>
 </div>
</>
}
   












  </div >;
}