import React, { createContext, useContext, useState } from 'react';

const TourContext = createContext();

export const useTour = () => useContext(TourContext);

export const TourProvider = ({ children }) => {
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const startTour = () => setIsTourRunning(true);
  const stopTour = () => setIsTourRunning(false);
  const setStepIndex = (index) => setCurrentStepIndex(index);

  return (
    <TourContext.Provider value={{ isTourRunning, startTour, stopTour, currentStepIndex, setStepIndex }}>
      {children}
    </TourContext.Provider>
  );
};
