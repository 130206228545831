import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


export default function ModalDescriptionInstrucctions({ show,handleClose, descriptionQuestion, instructionsQuestion }) {
    const [t, i18n] = useTranslation("global");

    return <Modal show={show}>
        <Modal.Header className="sec-green-background">
            <Modal.Title className="text-white">{t("fpThird.descripcionInstrucciones")}</Modal.Title>
            <div className="flex justify-end">
                <button onClick={() => handleClose()}>
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                </button>
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className='mb-2'>
                <p className='font-bold'>{t("fpThird.descripcion")}</p>
                <div dangerouslySetInnerHTML={{ __html: descriptionQuestion }} />

            </div>
            <div className='mb-2'>
                <p className='font-bold'>{t("fpThird.instrucciones")}</p>
                <div dangerouslySetInnerHTML={{ __html: instructionsQuestion }} />
            </div>
        </Modal.Body>
    </Modal>
}