import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Banner from '../../../components/utils/Banner';
import { IdiomContext } from "../../../context/createcontext"
import UserService from '../../../services/UserService';
import ReactTooltip from "react-tooltip";
import provincias from "./../data/provincias.json";
import Modal from 'react-bootstrap/Modal';
import Translations from '../../../services/Translations';

export default function FormMontesMap({ newForest, dataParcela }) {

  const monteCreado = newForest;

  const infoParcelaSelec = dataParcela;

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [showButtons, setShowButtons] = useState("");
  const [showNew, setShowNew] = useState("hidden");
  const [showForm, setShowForm] = useState("");
  const [modal, setModal] = useState(false);
  const [inputSpecies, setInputSpecies] = useState("");
  const [modalMixto, setModalMixto] = useState(false);
  const toggle = () => setModal(!modal);

  //Búsqueda de provincia según la referencia catastral
  const provincia = provincias.filter((v) => {
    if (infoParcelaSelec != null) {
      if (infoParcelaSelec.cadastralReference.slice(0, 2) == v.cod) {
        return v.name;
      }
    }
  })


  //Estado con todas las especies de BBDD
  const [forestType, setForestType] = useState({
    forestTypeData: [],
    isFetching: false,
  });

  useEffect(() => {
    const fetchForestType = async () => {
      try {
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_FORESTTYPE}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setForestType({ forestTypeData: response.data.sort((x, y) => x.commonName.localeCompare(y.commonName)), isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        console.error(exception);
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: false });
      }
    };
    fetchForestType();
  }, []);

  //Estado para los mensajes de los banners
  const [formMontesMessage, setFormMontesMessage] = useState({
    type: '',
    message: ''
  })

  //Estado que guarda el objeto del monte con los nuevos valores
  const [forestUpdated, setForestUpdated] = useState({
    forestUpdatedData: [],
    isFetching: false,
  });

  //Estado que guarda el objeto con las url al subir gml al GC
  const [gmlUpload, setGmlUpload] = useState({
    gmlUploadData: [],
    isFetching: false,
  });


  //Hadler del Submit del formulario
  const handlerSubmit = e => {
    e.preventDefault();
    if ((infoParcelaSelec.polygonSurface / 10000).toFixed(2) >= 2) {
      setDisableButton(true);
      setFormMontesMessage({ ...formMontesMessage, type: "warning", message: "Actualizando datos..." });
      document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });

      let monte = {
        "id": monteCreado.id,
        "owner": monteCreado.owner,
        "size": e.target.hectareas.value,
        "cadastre": e.target.catastral.value,
        "forestType": e.target.especie.value,
        "age": e.target.edad.value,
        "name": monteCreado.name.trim(),
        "creationDate": new Date(),
        "userId": UserService.getUserId(),
        "province": e.target.provincia.value,
        "state": "CREATED",
        "urlGml": "",
        "hubspot_negocioId": monteCreado.hubspot_negocioId
      }

      //Endpoint guadado gml
      fetchGmlUpload(monte);

      e.target.reset()
      setShowButtons("hidden");
      setShowForm("hidden");
      setShowNew("block");
    } else {
      setModal(true);
    }
    //setDisableButton(false);
  }

  //Endpoint que guarda el Gml en GC y llama al endpoint que actualiza el monte con la url del cloud storage
  const fetchGmlUpload = async (monte) => {
    const fileData = infoParcelaSelec.gmlString;
    const blob = new Blob([fileData], { type: "text/xml" });

    const formData = new FormData();
    formData.append("files", blob, monte.cadastre + ".gml");
    try {
      setGmlUpload({ gmlUploadData: gmlUpload.gmlUploadData, isFetching: true });
      await axios.post(`${process.env.REACT_APP_CLOUDSTORAGE_GML}` + UserService.getUserId(), formData, {
        headers: {
          // 'Accept': 'application/json',
          // 'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
        // body:JSON.stringify(monte)
      }).then(response => {
        if (response.status === 200) {
          //Completa el atributo urlGml con la url que acaba de darnos el GC storage
          monte.urlGml = response.data[0].filePublicUrl;
          fetchForestUpdated(monte);
        } else {
          fetchForestUpdated(monte);
        }
        setGmlUpload({ gmlUploadData: response.data, isFetching: false });
        // setItems(response.data);
      });
    } catch (exception) {
      console.error(exception);
      fetchForestUpdated(monte);
      setGmlUpload({ gmlUploadData: gmlUpload.gmlUploadData, isFetching: false });
    }
  };

  //Llammada al endpoint que guarda los nuevos datos del monte
  const fetchForestUpdated = async (monte) => {
    try {
      setForestUpdated({ forestUpdatedData: forestUpdated.forestUpdatedData, isFetching: true });
      await axios.post(`${process.env.REACT_APP_FOREST}?email=${UserService.getUsername()}`, monte, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
        // body:JSON.stringify(monte)
      }).then(response => {
        if (response.status === 200) {
          setFormMontesMessage({ ...formMontesMessage, type: "success", message: t("account.actualizacion-ok") });
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        } else {
          setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        }
        setForestUpdated({ forestUpdatedData: response.data, isFetching: false });
      });
    } catch (exception) {
      console.error(exception);
      setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
      document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
      setForestUpdated({ forestUpdatedData: forestUpdated.forestUpdatedData, isFetching: false });
    }
  };


  const reload = () => {
    setShowButtons("block")
    setShowNew("hidden")
    setShowForm("block")
    setFormMontesMessage({ ...formMontesMessage, type: "", message: "" })
  }

  const goList = () => { window.location.href = "/forest/forestlist" }

  function refreshPageDelete() {
    const fetchForestType = async () => {
      try {
        await axios.delete(`${process.env.REACT_APP_DELETE_FOREST}${monteCreado.id}`, {}, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          window.location.reload(false);
        });
      } catch (exception) {
        window.location.reload(false);
      }
    };
    fetchForestType();
  }
  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    if (inputSpecies === "Mixto") {
      setModalMixto(true);
    }
  }, [inputSpecies]);
  return (
    <React.Fragment>

      <Modal show={modalMixto}>
        <Modal.Header className='sec-green-background text-white text-lg'><b>{t("formMonte.alert_mixto_title")}</b></Modal.Header>
        <Modal.Body className='text-left'>
          {t("formMonte.alert_mixto0")}<a href="mailto:forestal@airco2.earth" className='font-semibold' >{t("formMonte.alert_mixto1")}</a>{t("formMonte.alert_mixto2")}<br />{t("formMonte.alert_mixto3")}
        </Modal.Body>
        <div className='flex justify-center pb-2'>
          <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => setModalMixto(false)}>
            {t("formMonte.alert_cerrar")}
          </Button>
        </div>
      </Modal>
      <Modal show={modal} toggle={toggle}>
        <Modal.Header toggle={toggle}>{t("formMonte.alert_error")}</Modal.Header>
        <Modal.Body className='text-center'>
          {t("formMonte.ha_insuficientes")}
        </Modal.Body>
        <div className='flex justify-center pb-2'>
          <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={toggle}>
            {t("formMonte.alert_cerrar")}
          </Button>
        </div>
      </Modal>

      <div className="max-w-sm marginx_formMontes px-4" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>

        {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>}

        {/* Form */}
        <Form className={`sec-neutro-background ${showForm}`} style={{ width: '100%' }} onSubmit={handlerSubmit}>
          <div className="space-y-4">
            <div>
              <div className='flex'>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="32" height="32" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" fill="none">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                  <line x1="15" y1="21" x2="15" y2="18" />
                  <path d="M8 13l-2 -2" />
                  <path d="M8 12l2 -2" />
                  <path d="M8 21v-13" />
                  <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                </svg>
                <label className="block h4 underline ml-2" htmlFor="nombre">{monteCreado == undefined ? "" : monteCreado.name}</label>
              </div>
              <label className="block text-sm font-medium mb-1" htmlFor="titular">{t("formMonte.titular")}: {monteCreado == undefined ? "" : monteCreado.owner}</label>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="especie">{t("formMonte.catastral")} <span className="text-red-500">* </span></label>
              <input id="catastral" name="catastral" className="form-input w-full" type="text" required
                value={infoParcelaSelec == null ? "" : infoParcelaSelec.cadastralReference}
                minlength="14" maxLength="14"
                disabled
              />
              <label className='text-xs'>{t("formMonte.selecionaparcela")}</label>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="hectareas">{t("forestList.hectareas")} <span className="text-red-500">*</span></label>
              <input id="hectareas" name="hectareas" data-tip data-for="registerTip" className="form-input w-full" type='number' min="2" step=".001"
                max={infoParcelaSelec == null ? "" : (infoParcelaSelec.polygonSurface / 10000).toFixed(2)}
                value={infoParcelaSelec == null ? "" : (infoParcelaSelec.polygonSurface / 10000).toFixed(2)}
                required />
              <ReactTooltip id="registerTip" place="top" effect="solid">{t("formMonte.minimo2ha1")}<br />{t("formMonte.minimo2ha2")}</ReactTooltip>
            </div>

            <div className=''>
              <label className="block text-sm font-medium mb-1" htmlFor="especie">{t("formMonte.provincia")} <span className="text-red-500">*</span></label>
              {infoParcelaSelec != null && provincia[0] == undefined ?
              <select defaultValue="" id="provincia" name="provincia" data-tip data-for="provinciaTip" className="form-input w-full" type="text" required >
              <option name="Selecciona" disabled className='w-full' value="">Selecciona provincia</option>
              {
                provincias.map((v) => {
                  return (
                    <option key={v.cod} name={v.name} className='w-full' value={v.name}>{v.name}</option>
                  );
                })
              }
            </select>
              :
              <input id="provincia" name="provincia" className="form-input w-full" type="text" required
                value={provincia[0] != undefined ? provincia[0].name : ""}
              />}
            </div>

            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="especie">{t("formMonte.especie")} <span className="text-red-500">*</span></label>
              <select defaultValue="" id="especie" name="especie" data-tip data-for="especiesTip" className="form-input w-full" type="text" onChange={e => setInputSpecies(e.target.value)} required >
                <option name="Selecciona" disabled className='w-full' value="">{t("formMonte.selectespecie")}</option>
                {
                  forestType.forestTypeData.map((v) => {
                    return (
                      <option key={v.id} name={v.commonName} className='w-full' value={v.commonName}>{t(Translations.translateForestTypeName(v.commonName)) + " (" + v.scientificName + ")"}</option>
                    );
                  })
                }
              </select>
              <ReactTooltip id="especiesTip" place="top" effect="solid">{t("formMonte.tooltip_mixto")}<br />{t("formMonte.alert_mixto3")}</ReactTooltip>
            </div>

            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="edad">{t("formMonte.edadplan")} <span className="text-red-500">*</span></label>
              <input id="edad" placeholder='Edad aproximada' name="edad" className="form-input w-full" type='number' min="1" step=".5" required />
            </div>
            <div className='inline-flex text-center pl-4 pr-4'>
              <input id="checkbox" name="checkbox" className="form-input " type='checkbox' required />
              <label className="block text-xs font-medium mb-1" htmlFor="marco">{t("formMonte.checkbox")}<span className="text-red-500">*</span></label>
            </div>
          </div>

          {/* botones del formulario  */}
          <div className="flex items-center justify-center mt-6">
            <Button arial-label="btn-cancelar" type="reset" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap ${showButtons}`} onClick={refreshPageDelete}>
              {t("formMonte.cancelar")}
            </Button>

            <Button arial-label="btn-regristrar" type="submit" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showButtons}`} disabled={disableButton}>
              {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
              {t("formMonte.registrar")}
            </Button>

          </div>
        </Form>

        <div className="flex flex-col " style={{ height: '350px' }}>
          {
            forestUpdated.forestUpdatedData.length == 0 ? <></> :
              <div>
                <div className='flex'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="32" height="32" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" fill="none">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                    <line x1="15" y1="21" x2="15" y2="18" />
                    <path d="M8 13l-2 -2" />
                    <path d="M8 12l2 -2" />
                    <path d="M8 21v-13" />
                    <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                  </svg>
                  <label className="block h4 underline ml-2" htmlFor="nombre">{forestUpdated.forestUpdatedData.length == 0 ? "" : forestUpdated.forestUpdatedData.name}</label>
                </div>
                <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.titular")}:</b> {forestUpdated.forestUpdatedData.owner}</label>
                <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.catastral")}:</b> {forestUpdated.forestUpdatedData.cadastre}</label>
                <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.provincia")}:</b> {forestUpdated.forestUpdatedData.province}</label>
                <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.especie")}:</b> {forestUpdated.forestUpdatedData.forestType}</label>
                <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.superficie")}:</b> {forestUpdated.forestUpdatedData.size} {t("forestList.hectareas")}</label>
                <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.marco")}:</b> {forestUpdated.forestUpdatedData.marco != null ? forestUpdated.forestUpdatedData.marco + t("forestList.marco") : ""}</label>
                <label className="block text-base ml-2" htmlFor="nombre"><b>{t("formMonte.edadplan")}:</b> {forestUpdated.forestUpdatedData.age} {t("proyectos.años")}</label>
              </div>}

        </div>

        <div className='flex items-center justify-center '>
          <Button arial-label="btn-nuevo" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap ${showNew}`} onClick={refreshPage}>
            {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
            {t("forestList.newMonte")}
          </Button>
          <Button arial-label="btn-nuevo" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showNew}`} onClick={() => goList()}>
            {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
            {t("forestList.mis-montes")}
          </Button>
        </div>
        <div className="pt-4 mt-6 border-t border-gray-200">
          <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/forest/dashboardmontes">{t("formMonte.volver")}</Link>
        </div>
      </div >

    </React.Fragment >
  );
}
