import React, { useContext } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { formatThousands } from '../utils/Utils';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "./../../../context/createcontext"

export default function ProgressBM(props) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const anual = props.anualCO2
  const available = props.availableCO2
  const porcentaje = (anual - available) / anual * 100
  const porCifra = porcentaje.toFixed(2)
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("dash.llevascompensado")} {formatThousands(porCifra)} % {t("dash.deltotal")}
    </Tooltip>
  );
  function Baremo(porcentaje) {
    if (porcentaje <= 30) {
      return ' #f9e79f'

    } else if (porcentaje > 30 && porcentaje <= 70) {
      return ' #F5B041'

    } else if (porcentaje > 70) {
      return '  #82e0aa '
    }
  }

  const color = Baremo(porcentaje)

  return (
    <div>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >

        <div className="w-full bg-gray-200 rounded-md dark:bg-gray-700 mt-1">
          <div className="text-xs font-medium rounded-md text-center p-0.8 leading-none " style={{ width: `${porcentaje}%`, background: `${color}`, color: '#816954', fontWeight: 'bold' }}>
            {`${formatThousands(porCifra)}%`}
          </div>
        </div>
      </OverlayTrigger>
    </div>
  )
}
