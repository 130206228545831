import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import data from '../data/FormExtra.json';
import Banner from '../../../components/utils/Banner'
import { IdiomContext } from "../../../context/createcontext";
import UserService from '../../../services/UserService';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function FormInfoExtra() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const [formulario, setFormulario] = useState({});
    const [respuestas, setRespuestas] = useState([]);
    const [formularioEnviado, setFormularioEnviado] = useState(false);
    const [checkboxState, setCheckboxState] = useState({});
    const location = useLocation();
    const infoMonte = location.state;


    const [loading, setLoading] = useState(true);
    const pathParams = useParams();
    const iddetail = pathParams.id

    const [formInfoMessage, setFormInfoMessage] = useState({
        type: '',
        message: ''
    })



    const handlerCheck = (e) => {
        setFormInfoMessage({ ...formInfoMessage, type: "", message: "" });
        const value = e.target.value;
        const isChecked = e.target.checked;
        let newRespuestas = [...respuestas];
        const { name, checked } = e.target;

        if (isChecked) {

            if (value === 'Sí' && newRespuestas.includes('No')) {
                newRespuestas = newRespuestas.filter((v) => v !== 'No');
            }

            if (value === 'No' && newRespuestas.includes('Sí')) {
                newRespuestas = newRespuestas.filter((v) => v !== 'Sí');
            }

            newRespuestas.push(value);
        } else {

            newRespuestas = newRespuestas.filter((v) => v !== value);
        }

        setRespuestas(newRespuestas);
        setCheckboxState({ ...checkboxState, [name]: checked });
    }




    const handlerSubmit = async (e) => {
        e.preventDefault();
        const atLeastOneCheckboxSelected = Object.values(checkboxState).some(Boolean);
        const newFormulario = {};
        let hasCheckboxError = false;

        data.forEach((v, i) => {
            const input = e.target.elements[v.preguntaTxt];

            if (input && input.value && v.tipo !== 'checkbox') {
                newFormulario[v.preguntaTxt] = input.value;
            } else if (v.tipo === 'checkbox') {
                const checkboxes = e.target.elements[v.preguntaTxt];

                const selected = [];
                checkboxes.forEach((cb) => {
                    if (cb.checked) {
                        selected.push(cb.value);
                    }
                });


                if (selected.length === 0) {
                    hasCheckboxError = true;
                    setFormInfoMessage({ ...formInfoMessage, type: "error", message: t("account.actualizacion-obligatorio") });
                }

                newFormulario[v.preguntaTxt] = selected.join(",");
            }
        });


        if (!hasCheckboxError && atLeastOneCheckboxSelected) {
            setFormulario(newFormulario);
        } else {
            
            // window.scrollTo(0, 0);
            setFormInfoMessage({ ...formInfoMessage, type: "error", message: t("account.actualizacion-obligatorio") });

        }
    };



    useEffect(() => {
        if (Object.keys(formulario).length > 0) {
            setFormularioEnviado(true);
           
            const postFormulario = async () => {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_FOREST_EMAIL}${iddetail}`, formulario, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${UserService.getToken()}`
                        }
                    });
                   
                    if (response.status === 200) {
                        setFormInfoMessage({ ...formInfoMessage, type: "success", message: t("account.actualizacion-ok") });

                    }
                    else {
                        setFormularioEnviado(false);
                        setFormInfoMessage({ ...formInfoMessage, type: "error", message: t("account.actualizacion-ko") })

                    }
                    ;
                } catch (error) {
                    setFormularioEnviado(false);
                    console.error(error);
                }
            };
            postFormulario();
        }
    }, [formulario]);




    return (


        <div>

            <div className='container px-5 py-5'>
                <p>{t("formExtra.explicacion")}</p>
                <br></br>
                <p><span className='font-bold'>{UserService.getName()}</span> , {t("formExtra.datos")} <span className='font-bold'> {infoMonte} </span> <span>{t("formExtra.equipo")}</span> </p>

                {formInfoMessage.type === "success" && <>
                    <Banner className='my-5' type={formInfoMessage.type} open setOpen={true}>{formInfoMessage.message}</Banner>
                    <Button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">
                        <Link style={{color:'white', textDecoration:'none'}}
                            to="/forest/forestlist"

                        >
                            {t("forestList.mis-montes")}
                        </Link>

                    </Button>

                </>
                }

                {/* {formInfoMessage.type === "error" && <Banner className='my-5' type={formInfoMessage.type} open setOpen={true}>{formInfoMessage.message}</Banner>} */}

                {formInfoMessage.type !== "success" && <Form id="sectionForm" className='' style={{ width: '100%' }} onSubmit={handlerSubmit}>
                    {data.map((v, i) => {
                        return (
                            <div key={i} className='mt-3'>
                                {(v.opcional === false || (v.opcional === true && v.opcion && respuestas.includes(v.opcion))) &&
                                    <>
                                        <label className="block text-sm mb-1" htmlFor={v.pregunta}>
                                            <span className='font-semibold'>{t(`${v.pregunta}`)}</span> <span className="text-red-500">*</span>
                                            {(v.tipo === 'checkbox') &&
                                                <span className='italic  text-xs'>({t("formExtra.respuestaMultiple")})</span>
                                            }
                                        </label>

                                        {v.tipo === 'radio' || v.tipo === 'checkbox' ?
                                            Array.isArray(v.respuestas) && v.respuestas.map((x, j) => (
                                                <div key={j} >
                                                    <input
                                                        type={v.tipo}
                                                        name={v.preguntaTxt}
                                                        id={v.preguntaTxt + j}
                                                        value={x.txt}
                                                        label={t(`${x.trd}`)}
                                                        onChange={(e) => handlerCheck(e)}

                                                        required={v.opcional === false && v.tipo === 'radio' ? true : false}
                                                    />
                                                    <span className="ml-2 text-sm">{t(`${x.trd}`)}</span>
                                                </div>
                                            ))
                                            :
                                            v.tipo === 'textarea' ?
                                                <textarea id={v.preguntaTxt} name={v.preguntaTxt} className={`form-input w-full`} required={v.opcional === false ? true : false}></textarea>
                                                :
                                                <input id={v.preguntaTxt} name={v.preguntaTxt} className={`form-input ${v.tipo === 'text' ? 'w-full' : 'w-20'}`} type={v.tipo} required={v.opcional === false ? true : false} />
                                        }
                                    </>
                                }
                            </div>
                        );
                    })}

                    <div className='mt-3 flex justify-center'>
                        <Button
                            disabled={formularioEnviado}
                            aria-label="btn-registrar"
                            type="submit"
                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap"
                        >
                            {t("formExtra.enviar")}

                        </Button>


                        <Button
                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap"
                            onClick={() => window.history.back()}
                        >
                            {t("formExtra.cancelar")}
                        </Button>
                    </div>
                </Form>
                }

                {formInfoMessage.type === "error" && <Banner className='my-5' type={formInfoMessage.type} open setOpen={true}>{formInfoMessage.message}</Banner>}
            </div>
        </div>







    )
}
