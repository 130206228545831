import React, { useContext } from 'react';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import Translations from '../../../services/Translations'

function ForestListTableItemLine({props, admin, getForestIdDelete}) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  return (
    <>
      {/* Row */}
      <tr id={"line-" + props.id}>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-right">{props.cadastre}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{t(Translations.translateForestTypeName(props.forestType)) }</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{props.size}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{props.age}</div>
        </td>
        {admin != undefined ?
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap flex justify-center">
          <button  onClick={() => getForestIdDelete(props.id)} className="active" aria-pressed="true">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.25" stroke="red" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M4 7l16 0" />
              <path d="M10 11l0 6" />
              <path d="M14 11l0 6" />
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
            </svg>
          </button>
        </td>
        :""
        }
      </tr>
    </>
  );
}

export default ForestListTableItemLine;